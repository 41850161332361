import StyleSheet from "react-native-media-query";
import { mediaQuery } from "@utils/misc";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: "100%",
  },
  progressOuter: {
    width: "100%",
    height: 4,
    backgroundColor: "#8C9196",
    borderRadius: 4,
  },
  progressInner: {
    height: "100%",
    borderRadius: 4,
    backgroundColor: "#2C6ECB",
    alignItems: "flex-end",
  },
  circleWrap: {
    height: 30,
    width: 30,
    borderRadius: 15,
    padding: 8,
    borderWidth: 4,
    borderColor: "#2C6ECB",
    justifyContent: "center",
    alignItems: "center",
    marginTop: -13,
  },
  innerCircle: {
    height: 18,
    width: 18,
    borderRadius: 10,
    backgroundColor: "#2C6ECB",
  },
  completedWrap: {
    position: "absolute",
    bottom: 26,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 8,
    paddingRight: 8,
    backgroundColor: "#FFFFFF",
    borderRadius: 4,
    shadowColor: "rgba(0, 0, 0, 0.15)",
    shadowRadius: 4,
    shadowOffset: { height: 4, width: 0 },
    [mediaQuery.forMobile]: {
      display: "none",
    },
  },
  completionText: {
    color: "#202223",
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
    whiteSpace: "nowrap",
  },
});
