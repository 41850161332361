import * as Yup from "yup";

export const validationSchema = Yup.object({
  title: Yup.string().when("status", {
    is: "Publish",
    then: Yup.string().required(
      "A title is required to publish this training."
    ),
    otherwise: Yup.string(),
  }),
  shortDescription: Yup.string().when("status", {
    is: "Publish",
    then: Yup.string().required(
      "A short description is required to publish this training."
    ),
    otherwise: Yup.string(),
  }),
  learningObjectives: Yup.array().when("status", {
    is: "Publish",
    then: Yup.array()
      .of(
        Yup.string().required(
          "You must add at least 4 learning objectives to publish this training."
        )
      )
      .min(
        4,
        "You must add at least 4 learning objectives to publish this training."
      ),
    otherwise: Yup.array(),
  }),
  overview: Yup.string().when("status", {
    is: "Publish",
    then: Yup.string().required(
      "An overview is required to publish this training."
    ),
    otherwise: Yup.string(),
  }),
  requirements: Yup.string().when("status", {
    is: "Publish",
    then: Yup.string().required(
      "Course requirements are missing to publish this training."
    ),
    otherwise: Yup.string(),
  }),
  whoIsCourseFor: Yup.string().when("status", {
    is: "Publish",
    then: Yup.string().required(
      "Who this course is for is required to publish this training."
    ),
    otherwise: Yup.string(),
  }),
  coverImage: Yup.object().when("status", {
    is: "Publish",
    then: Yup.object()
      .required("This field is required")
      .typeError("A cover image is required to publish this training."),
    otherwise: Yup.object().notRequired().nullable(),
  }),
  categories: Yup.array()
    .min(1, "You must add at least one category.")
    .required("Categories are required."),
  modules: Yup.array().when("status", {
    is: "Publish",
    then: Yup.array(
      Yup.object({
        moduleTitle: Yup.string().required("Module title is required."),
        moduleDescription: Yup.string().required(
          "Module description is required."
        ),
        lessons: Yup.array(
          Yup.object({
            lessonTitle: Yup.string().required("Lesson title is required."),
            lessonDescription: Yup.string().required(
              "Lesson description is required."
            ),
            lessonContent: Yup.string().required("Lesson content is required."),
            isPreviewLesson: Yup.boolean(),
            userType: Yup.array().nullable(),
            lessonType: Yup.string()
              .required("Lesson type is required.")
              .typeError("Lesson type is required."),
          })
        )
          .min(1, "You must add at least one lesson.")
          .required("You must add at least one lesson"),
      })
    )
      .min(1, "You must add at least one module")
      .required("You must add at least one module"),
    otherwise: Yup.array().notRequired().nullable(),
  }),
  instructors: Yup.array().when("status", {
    is: "Publish",
    then: Yup.array(
      Yup.object({
        id: Yup.string().required("Instructor required."),
      })
    )
      .min(1, "You must add an instructor.")
      .required("You must add an instructor."),
    otherwise: Yup.array().notRequired().nullable(),
  }),
});
