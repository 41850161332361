import { ActivityIndicator, View } from "react-native";
import { useNavigate } from "react-router-native";

import { NonEmptyArray } from "@shopify/polaris/build/ts/latest/src/types";
import { IndexTableHeading } from "@shopify/polaris/build/ts/latest/src/components/IndexTable";
import { PlanContainer } from "@components/back-office/roadmap/plan-container";
import dayjs from "dayjs";
import { useAdminGetAllRoadmapsQuery } from "@gql/generated/generated";
import { GridPage } from "@components/back-office/grid/GridPage";
import { useQueryFilters } from "@components/back-office/grid/useGridFilters";

const activeFilters = ["search"];

export const RoadmapBackOffice = () => {
  const { page, filters } = useQueryFilters(activeFilters);

  const { data, loading } = useAdminGetAllRoadmapsQuery({
    variables: {
      filters,
      page,
      limit: 10,
    },
  });

  const navigate = useNavigate();

  const tableHeadings: NonEmptyArray<IndexTableHeading> = [
    { title: "Organization" },
    { title: "North Star" },
    { title: "Goal" },
    { title: "Pulse (10 points)" },
    { title: "" },
  ];

  const total = data?.adminGetAllRoadmaps.total;
  const hasNextPage = data?.adminGetAllRoadmaps.hasNextPage;

  const rows = data?.adminGetAllRoadmaps.items
    ?.map(({ id, companyName, northStar, goal, pulse }) => {
      if (!id) return null;
      return {
        id,
        title: companyName,
        north: (
          <PlanContainer
            id={id}
            percentage={(northStar?.percentageComplete || "0") + "%"}
            date={
              Number(northStar?.lastUpdated)
                ? dayjs(new Date(Number(northStar.lastUpdated))).format(
                    "DD/MM/YYYY"
                  )
                : null
            }
            onAction={(id: string) =>
              navigate(`/Roadmap/${id}/${"north-star"}`)
            }
          />
        ),
        Goal: (
          <PlanContainer
            id={id}
            percentage={(goal?.percentageComplete || "0") + "%"}
            date={
              Number(goal?.lastUpdated)
                ? dayjs(new Date(Number(goal.lastUpdated))).format("DD/MM/YYYY")
                : null
            }
            onAction={(id: string) => navigate(`/Roadmap/${id}/${"goal"}`)}
          />
        ),
        Pulse: (
          <PlanContainer
            id={id}
            percentage={`${pulse?.averageScore ?? 0}%`}
            date={
              Number(pulse?.lastUpdated)
                ? dayjs(new Date(Number(pulse?.lastUpdated))).format(
                    "DD/MM/YYYY"
                  )
                : null
            }
            onAction={(id: string) => navigate(`/Roadmap/${id}/${"pulse"}`)}
          />
        ),
      };
    })
    .filter(Boolean) as {
    id: string;
    title: string | null | undefined;
    north: JSX.Element;
    Goal: JSX.Element;
    Pulse: JSX.Element;
  }[];

  const handleMenuClick = async (itemId: string, optionID: string) => {
    switch (optionID) {
      case "View":
        return navigate(`/Roadmap/${itemId}`);

      case "Delete":
        // TODO: check the mutation to delete organization.
        // await deleteOrganization({ variables: { id, } });
        // await refetch();
        break;
      default:
        break; // TODO: fallthrough
    }
  };

  return (
    <>
      <GridPage
        title="Roadmap"
        menuItems={[
          { id: "View", content: "View" },
          { id: "Delete", content: "Delete" },
        ]}
        activeFilters={activeFilters}
        rows={rows || []}
        loading={loading}
        tableHeadings={tableHeadings}
        onMenuItem={handleMenuClick}
        pagination={{
          perPage: 10,
          page,
          totalItems: total,
          hasNextPage,
        }}
      />
    </>
  );
};
