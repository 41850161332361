import React, { useState } from "react";
import { Text, View } from "react-native";
import { TableDropdown } from "@components/back-office/tables/table-dropdown";
import { ContentBox } from "@components/general/layouts/content-box";
import { QuestionAnswerTable } from "@components/back-office/roadmap/question-answer-table";
import { StatisticCardsContainer } from "@components/back-office/roadmap/statistic-cards-container";
import { Goal } from "@gql/generated/generated";
import dayjs from "dayjs";
import { getQuarterDetails } from "@pages/roadmap/utils";
import { urlToImageSource } from "@utils/urlToImageSource";
import { IRoadMap } from "@components/back-office/roadmap/roadmap-models";
import { roadmapSectionToSubmodule } from "@utils/roadmapSectionToSubmodule";
import { isNotNull } from "@utils/isNotNull";

interface Props {
  goalData: Goal[];
}

export const GoalView = ({ goalData }: Props) => {
  // TODO: This could all live in a hook since it's shared by NorthStarView and some of PulseView
  // TODO: The year/quarter should default to the newest one, not the first in the array
  const [selectedQuarter, setSelectedQuarter] = useState(goalData[0].quarter);
  const [selectedYear, setSelectedYear] = useState(goalData[0].year);

  const selectedGoalData = goalData?.find(
    (el) => el.year === selectedYear && el.quarter === selectedQuarter
  );

  const currentYearGoalData = goalData?.filter(
    (el) => el.year === selectedYear
  );

  const lastUpdatedDate = dayjs(
    new Date(Number(selectedGoalData?.lastUpdated))
  ).format("DD/MM/YY");

  const { nextQuarter, nextQuarterStartDate } = getQuarterDetails();

  const cards: {
    title: string;
    mainData: string;
    secondaryData: string;
  }[] = [
    {
      title: "Completion",
      mainData: `Q${selectedQuarter} - ${
        selectedGoalData?.percentageComplete || 0
      }%`,
      secondaryData: selectedGoalData?.lastUpdated ? lastUpdatedDate : "-",
    },
    {
      title: "Last Check",
      mainData: selectedGoalData?.lastUpdated ? lastUpdatedDate : "-",
      secondaryData: dayjs(nextQuarterStartDate).format("DD/MM/YY"),
    },
    {
      title: "Next reminder",
      mainData: selectedGoalData?.lastUpdated
        ? nextQuarterStartDate.toString()
        : "-",
      secondaryData: `Q${nextQuarter}`,
    },
  ];

  const tableData: IRoadMap[] = [
    {
      id: selectedGoalData?.id ?? "",
      name: "North Star",
      submodules:
        selectedGoalData?.sections
          .map(roadmapSectionToSubmodule)
          .filter(isNotNull) ?? [],
    },
  ];

  return (
    <ContentBox style={{ padding: 16 }}>
      <StatisticCardsContainer
        items={cards}
        name={selectedGoalData?.lastUpdatedByUserName}
        image={urlToImageSource(
          selectedGoalData?.lastUpdatedByUserProfilePhoto
        )}
      />
      <ContentBox>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 20,
          }}
        >
          <Text
            style={{
              fontFamily: "OpenSans_600SemiBold",
              fontSize: 20,
              lineHeight: 28,
            }}
          >
            Goal
          </Text>
          <View style={{ flexDirection: "row" }}>
            <TableDropdown
              items={currentYearGoalData.map(({ quarter }) => ({
                id: quarter ?? "",
                content: quarter,
              }))}
              placeholder={selectedQuarter}
              onSelectItem={(id) => setSelectedQuarter(id)}
            />
            <TableDropdown
              items={goalData.map(({ year }) => ({
                id: year ?? "",
                content: year,
              }))}
              placeholder={selectedYear}
              onSelectItem={(id) => setSelectedYear(id)}
            />
          </View>
        </View>
        {}
        <QuestionAnswerTable data={tableData} />
      </ContentBox>
    </ContentBox>
  );
};
