import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  contents: {
    maxWidth: 587,
    padding: 20,
    paddingBottom: 0,
    gap: 10,
    marginBottom: 10,
    flexDirection: "column",
    alignItems: "flex-start",
  },
  text: {
    color: "#0D1738",
    fontSize: 16,
    fontFamily: "OpenSans_400Regular",
  },
  link: {
    color: "#2C6ECB",
    textDecorationLine: "underline",
  },
});
