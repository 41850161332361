import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const PointerArrowLeft = (props: SvgProps) => (
  <Svg width={16} height={12} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 5H3.414l3.293-3.293A.999.999 0 1 0 5.293.293l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L3.414 7H15a1 1 0 1 0 0-2Z"
      fill="#0D1738"
    />
  </Svg>
);

export { PointerArrowLeft };
