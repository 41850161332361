import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { Pill } from "@components/general/pill";
import { styles } from "./style";

interface Props {
  title: string;
  active?: boolean;
  onPress?: () => void;
  pill?: string;
}

const TabTitle = ({ title, active, onPress, pill }: Props) => (
  <TouchableOpacity style={styles.tabItem} onPress={onPress}>
    <View style={styles.textWrap}>
      <Text style={[styles.tabTitle, active && styles.tabTitleActive]}>
        {title}
      </Text>
      {pill ? (
        <View style={styles.pillWrap}>
          <Pill text={pill} />
        </View>
      ) : null}
    </View>
    {active && <View style={styles.tabActiveBottom} />}
  </TouchableOpacity>
);

export { TabTitle };
