import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
  },
  button: {
    marginRight: 10,
  },
});
