import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  mainTitle: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 14,
    lineHeight: 20,
    color: "#6D7175",
  },
  imageContainer: {
    width: 42,
    height: 42,
    borderRadius: 100,
    marginRight: 8,
  },
  image: {
    width: 40,
    height: 40,
    borderRadius: 50,
  },
  nameText: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 14,
    lineHeight: 20,
    color: "#0D1738",
  },
});
