import { Image, Modal, ScrollView, Text, View } from "react-native";
import { styles } from "./styles";
import { GenericModalLayout } from "@components/org/saved/modals/generic-modal-layout/GenericModalLayout";
import { ModalContainer } from "@components/org/saved/modals/ModalContainer";
import DirectDebitLogo from "../../../../../../assets/img/go-cardless/direct-debit-landscape.png";

type DirectDebitModalProps = {
  visible: boolean;
  closeModal: () => void;
};

export const DirectDebitGuaranteeModal = ({
  visible,
  closeModal,
}: DirectDebitModalProps) => (
  <Modal visible={visible} transparent>
    <ModalContainer>
      <GenericModalLayout
        title="Direct Debit Guarantee"
        closeModal={closeModal}
        hasButtonLayout
        buttonName="Close"
        onActionButtonPress={closeModal}
        withCancel={false}
        footerContainerStyle={{
          borderTopWidth: 0,
        }}
        actionButtonStyle={{
          paddingHorizontal: 35,
          paddingVertical: 5,
          borderRadius: 35,
        }}
      >
        <DirectDebitGuarantee />
      </GenericModalLayout>
    </ModalContainer>
  </Modal>
);

const companyName = "GC re RecruitmentNetwork";
const DirectDebitGuarantee = () => {
  return (
    <View style={styles.contents}>
      <ScrollView>
        <View style={styles.ddGuarantee}>
          <View style={styles.ddGuaranteeHeader}>
            <Image source={DirectDebitLogo} style={styles.directDebitLogo} />
          </View>
          <Text>
            This Guarantee is offered by all banks and building societies that
            accept instructions to pay Direct Debits.
            {"\n\n"}If there are any changes to the amount, date or frequency of
            your Direct Debit {companyName} will notify you 5 working days in
            advance of your account being debited or as otherwise agreed. If you
            request {companyName} to collect a payment, confirmation of the
            amount and date will be given to you at the time of the request.
            {"\n\n"}If an error is made in the payment of your Direct Debit, by{" "}
            {companyName} or your bank or building society, you are entitled to
            a full and immediate refund of the amount paid from your bank or
            building society – if you receive a refund you are not entitled to,
            you must pay it back when {companyName} asks you to.
            {"\n\n"}You can cancel a Direct Debit at any time by simply
            contacting your bank or building society. Written confirmation may
            be required. Please also notify {companyName}.
          </Text>
        </View>
      </ScrollView>
    </View>
  );
};
