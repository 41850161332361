import { useGetFile } from "@hooks/useGetFileUrl";
import { ImageStyle, Image as ReactNativeImage, StyleProp } from "react-native";

export const Image = ({
  path,
  style,
  imageUrl,
}: {
  path?: string | null;
  imageUrl?: string | null;
  style: StyleProp<ImageStyle>;
}) => {
  const { fileUrl } = useGetFile(path);

  return (
    <ReactNativeImage source={{ uri: imageUrl ?? fileUrl }} style={style} />
  );
};
