import React, { useState } from "react";
import {
  ImageSourcePropType,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { CommentPhoto } from "@components/home/post/comment/comment-photo";
import { LikeIcon, ReplyIcon } from "@components/general/icons/feed-icons";
import { styles } from "./style";
import { Rating } from "@components/general/rating";

interface CommentActionTouchProps {
  action: string;
  icon: React.ReactNode;
  count?: number;
  onPress?: () => void;
  isBorderVisible?: boolean;
  hoverBackground?: string;
  likedByCurrentUser?: boolean;
}

const CommentActionTouch = ({
  action,
  icon,
  count,
  onPress,
  isBorderVisible,
  hoverBackground,
  likedByCurrentUser,
}: CommentActionTouchProps) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <TouchableOpacity
      style={[styles.actionTouch, isBorderVisible && styles.rightBorder]}
      onPress={onPress}
      // @ts-ignore
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Text
        style={[
          styles.actionText,
          likedByCurrentUser && { backgroundColor: "#E5EFFD" },
          isHovered &&
            !!hoverBackground && { backgroundColor: hoverBackground },
        ]}
      >
        {action}
      </Text>
      {icon}
      {count ? <Text style={styles.actionCount}>{count}</Text> : null}
    </TouchableOpacity>
  );
};

interface CommentActionProps {
  totalLikes: number;
  likedByCurrentUser?: boolean;
  isReplyVisible?: boolean;
  totalReplies?: number;
  onPressLike?: () => void;
  onPressReply?: () => void;
}

const CommentAction = ({
  totalLikes,
  isReplyVisible,
  totalReplies,
  onPressLike,
  onPressReply,
  likedByCurrentUser,
}: CommentActionProps) => (
  <View style={styles.actionWrap}>
    <CommentActionTouch
      isBorderVisible={isReplyVisible}
      action="Like"
      icon={<LikeIcon />}
      count={totalLikes}
      onPress={onPressLike}
      hoverBackground="#EDEEEF"
      likedByCurrentUser={likedByCurrentUser}
    />
    {isReplyVisible && (
      <CommentActionTouch
        action="Reply"
        icon={<ReplyIcon />}
        count={totalReplies}
        onPress={onPressReply}
      />
    )}
  </View>
);

interface CommentBoxProps extends CommentActionProps {
  userName: string;
  avatar: ImageSourcePropType;
  userJobTitle: string;
  text: string;
  rating?: number;
  ratingDate?: string;
  commentIndex?: number;
  likedByCurrentUser?: boolean;
}

const CommentBox = ({
  userName,
  avatar,
  userJobTitle,
  text,
  totalLikes,
  totalReplies,
  isReplyVisible,
  onPressReply,
  onPressLike,
  rating,
  ratingDate,
  commentIndex,
  likedByCurrentUser,
}: CommentBoxProps) => {
  const nameArr = userName?.split(" ");
  const firstName = nameArr?.[0];
  const lastName = nameArr?.[nameArr?.length - 1];

  return (
    <View>
      <View style={styles.wrap}>
        <View style={styles.userWrap}>
          <CommentPhoto
            firstName={firstName}
            lastName={lastName}
            avatar={avatar}
            commentIndex={commentIndex}
          />
          <View style={styles.nameWrap}>
            <Text style={styles.nameText}>
              {firstName} {lastName}
            </Text>
            <Text style={styles.professionText}>{userJobTitle}</Text>
          </View>
        </View>
        {rating ? (
          <View style={styles.ratingWrap}>
            <View style={styles.ratingBox}>
              <Rating rating={rating} />
            </View>
            <Text style={styles.ratingDate}>on {ratingDate}</Text>
          </View>
        ) : null}
        <Text style={styles.comment}>{text}</Text>
      </View>
      <CommentAction
        totalLikes={totalLikes}
        totalReplies={totalReplies}
        isReplyVisible={isReplyVisible}
        onPressLike={onPressLike}
        onPressReply={onPressReply}
        likedByCurrentUser={likedByCurrentUser}
      />
    </View>
  );
};

export { CommentBox };
