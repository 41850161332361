import { useEffect, useState } from "react";
import { isEqual } from "lodash";

export const SubmitListener = (props: any) => {
  const [lastValues, updateState] = useState(props.formik.values);

  useEffect(() => {
    const valuesEqualLastValues = isEqual(lastValues, props.formik.values);
    const valuesEqualInitialValues =
      props.formik.values === props.formik.initialValues;

    if (!valuesEqualLastValues) {
      updateState(props.formik.values);
    }

    if (!valuesEqualLastValues && !valuesEqualInitialValues) {
      props.formik.submitForm();
    }
  }, [props.formik.values, props.formik.initialValues]);

  return null;
};
