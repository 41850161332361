import React from "react";
import { Text, TouchableOpacity } from "react-native";
import { PointerArrowLeft } from "@components/general/icons/account-icons/PointerArrowLeft";
import { styles } from "./style";

interface Props {
  onPress?: () => void;
  title: string;
}

const BackNav = ({ onPress, title }: Props) => (
  <TouchableOpacity style={styles.wrap} onPress={onPress}>
    <PointerArrowLeft />
    <Text style={styles.text}>{title}</Text>
  </TouchableOpacity>
);

export { BackNav };
