import { ActionList, Button, IconSource, Popover } from "@shopify/polaris";
import {
  JSXElementConstructor,
  ReactElement,
  useCallback,
  useState,
} from "react";
import { StyleProp, Switch, View, ViewStyle } from "react-native";

interface SwitchButtonProps {
  title: string;
  Icon?:
    | ReactElement<any, string | JSXElementConstructor<any>>
    | IconSource
    | undefined;
  isActive?: boolean;
  setIsActive?: any;
  needDisclosure?: boolean;
  containerStyles?: StyleProp<ViewStyle>;
}

const SwitchButton = ({
  title,
  Icon,
  isActive,
  setIsActive,
  needDisclosure = true,
  containerStyles
}: SwitchButtonProps) => {
  const [popoverActive, setPopoverActive] = useState(false);
  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    []
  );

  const Activator = (
    <Button
      icon={Icon ? Icon : undefined}
      onClick={togglePopoverActive}
      textAlign="end"
      disclosure={needDisclosure ? false : popoverActive ? "up" : "down"}
    >
      {title}
    </Button>
  );

  return (
    <View
    style={[
      {
        marginRight: 8,
      },
      containerStyles,
    ]}
    >
      <Popover
        active={popoverActive}
        activator={Activator}
        autofocusTarget="first-node"
        onClose={togglePopoverActive}
        fixed={false}
        fluidContent
        preferredAlignment="left"
      >
        <ActionList
          items={[
            {
              prefix: isActive ? "ON" : "OFF",
              helpText: (
                <Switch
                  value={isActive}
                  trackColor={{ true: "#2C6ECB", false: "#4A4A4A" }}
                  thumbColor="#fff"
                  onValueChange={() => setIsActive(!isActive)}
                />
              ),
              onAction: () => setIsActive(!isActive),
            },
          ]}
        />
      </Popover>
    </View>
  );
};

export { SwitchButton };
