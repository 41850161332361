import { createContext, FC, useContext, useReducer } from "react";
import { Toast } from "@components/general/toast";
import { nanoid } from "nanoid";

interface IToast {
  id: string;
  message: string;
  type: "success" | "error";
  duration?: number;
}

const initialState: { toasts: IToast[] } = {
  toasts: [],
};

export const toastReducer = (state: any, action: any) => {
  switch (action.type) {
    case "ADD_TOAST":
      return {
        ...state,
        toasts: [...state.toasts, action.payload],
      };
    case "DELETE_TOAST":
      return {
        ...state,
        toasts: state.toasts.filter(
          (toast: IToast) => toast.id !== action.payload
        ),
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export const ToastContext = createContext({});

export type ToastContextType = {
  addToast: (type: IToast["type"], message: string, duration?: number) => void;
};

export const useToast = () => {
  const { addToast } = useContext(ToastContext) as ToastContextType;
  return { addToast };
};

export const ToastContextProvider: FC<any> = ({ children }) => {
  const [state, dispatch] = useReducer(toastReducer, initialState);

  const addToast = (
    type: IToast["type"],
    message: string,
    duration?: number
  ) => {
    const id = nanoid();
    dispatch({ type: "ADD_TOAST", payload: { id, message, type, duration } });
  };

  const remove = (id: string) => {
    dispatch({ type: "DELETE_TOAST", payload: id });
  };

  const value = { addToast, remove };

  return (
    <ToastContext.Provider value={value}>
      {children}
      {state.toasts.map((toast: IToast, i: number) => (
        <Toast
          key={i}
          text={toast.message}
          active
          duration={toast.duration ?? 3000}
          onDismiss={() => remove(toast.id)}
          error={toast.type === "error"}
        />
      ))}
    </ToastContext.Provider>
  );
};
