import { Platform } from "react-native";
import { MarketplaceIndividualView as MarketplaceIndividualViewWeb } from "./MarketplaceIndividualView";
import { MarketplaceIndividualViewNative } from "./MarketplaceIndividualView.native";

const MarketplaceIndividualView = Platform.select({
  native: () => MarketplaceIndividualViewNative,
  default: () => MarketplaceIndividualViewWeb,
})();

export { MarketplaceIndividualView };
