import {
  IFieldTypes,
  IQuestion,
  ISubmodule,
} from "@components/back-office/roadmap/roadmap-models";
import { Section } from "@gql/generated/generated";
import {
  IQuestion as GeneralQuestion,
  ISubmodule as GeneralSubmodule,
} from "@utils/models";
import { isNotNull } from "@utils/isNotNull";

export const roadmapSectionToSubmodule = (
  section: Section
): ISubmodule | null => {
  return section.id
    ? {
      id: section.id,
      name: section.name ?? "",
      questions: section.questions.map(
        (question): IQuestion => ({
          id: question.id,
          question: question.question,
          subcategory: question?.subcategory ?? '',
          description: question?.description ?? '',
          answer:
            question.answer.hasAnswered && question.answer.value
              ? question.answer.value
              : "",
          type: question.type as IFieldTypes,
          options: question.options ?? [],
          placeholder: question.placeholder ?? undefined,
        })
      ),
    }
    : null;
};

export const roadmapSubmoduleToGeneralSubmodule = (
  submodule: ISubmodule | null
): GeneralSubmodule | null => {
  return submodule
    ? {
      ...submodule,
      questions: submodule.questions
        .map(
          (question): GeneralQuestion => ({
            ...question,
          })
        )
        .filter(isNotNull),
    }
    : null;
};
