import { percentageChange } from "@components/org/analytics/helpers";
import {
  useGetAdminEventBoxesQuery,
  useGetAdminEventRowsQuery,
  useGetAdminEventStatsQuery,
} from "@gql/generated/generated";

import { useMemo } from "react";

export const useEventAnalytics = () => {
  const { data: analyticsData, refetch } = useGetAdminEventStatsQuery();
  const { data: rowsData, refetch: refetchContentRows } =
    useGetAdminEventRowsQuery();
  const { data: boxData } = useGetAdminEventBoxesQuery();

  const stats = boxData?.adminGetMemberStats.events;
  const boxTitles = [
    "No. of Registrations",
    "Share event",
    "Average Time Spent",
  ];

  const boxItems = useMemo(() => {
    if (!stats)
      return boxTitles.map((item) => ({
        title: item,
      }));
    return [
      {
        title: "No. of Registrations",
        count: stats?.numRegistrations.curr ?? 0,
        countInfo: "times",
        percent: percentageChange(
          stats?.numRegistrations.prev,
          stats?.numRegistrations.curr
        ),
        percentInfo: "than last month",
      },
      {
        title: "Share event",
        count: stats?.numShares.curr ?? 0,
        countInfo: "times",
        percent: percentageChange(stats?.numShares.prev, stats?.numShares.curr),
        percentInfo: "than last month",
      },
      {
        title: "Average Time Spent",
        count: stats?.avgTimeMins.curr ?? 0,
        countInfo: "min/day/user",
        percent: percentageChange(
          stats?.avgTimeMins.prev,
          stats?.avgTimeMins.curr
        ),
        percentInfo: "than last month",
      },
    ];
  }, [stats]);

  const dataForExport = useMemo(() => {
    if (!rowsData || !analyticsData) return [];
    return [
      {
        columns: [
          { label: "Title", value: "title" },
          { label: "Avg. time (mins/member)", value: "time" },
          { label: "Total registration clicks", value: "registrationClicks" },
          { label: "Type", value: "type" },
          { label: "Visitors", value: "visitors" },
          { label: "Rating", value: "rating" },
          { label: "Shares", value: "shares" },
        ],
        data: rowsData?.adminGetMemberStats.events?.eventAnalytics.map(
          (item) => {
            return {
              title: item.title,
              time: item.avgTimeMins,
              registrationClicks: item.numRegistrations ?? 0,
              type: item.eventType ?? "-",
              visitors: item.numVisitors,
              rating: item.avgRating ?? "-",
              shares: item.numShares,
            };
          }
        ),
        name: "Event analytics",
      },
      {
        columns: [
          { label: "Date", value: "date" },
          {
            label: "Name",
            value: "name",
          },
          {
            label: "Message",
            value: "message",
          },
        ],
        data: analyticsData?.adminGetMemberStats.events?.requests.map(
          (request) => {
            return {
              date: request.date,
              name: request.user.name ?? "-",
              message: request.message,
            };
          }
        ),
        name: "Event requests",
      },
    ];
  }, [rowsData, analyticsData]);

  return {
    boxItems,
    analyticsData,
    refetch,
    refetchContentRows,
    dataForExport,
    eventAnalytics: rowsData?.adminGetMemberStats.events?.eventAnalytics,
  };
};
