import React, { useState } from "react";
import { Text, View } from "react-native";
import { styles } from "./style";

interface Props {
  progressPercentage: number;
  progressInTraining: string;
}

const LessonProgress = ({ progressPercentage, progressInTraining }: Props) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <View style={styles.wrap}>
      <View style={styles.progressOuter}>
        <View style={[styles.progressInner, { width: `${progressPercentage}%` }]}>
          {isHovered ? (
            <View
              style={[styles.completedWrap, progressPercentage <= 10 && { left: "10px" }]}
            >
              <Text style={styles.completionText}>Lesson {progressInTraining}</Text>
            </View>
          ) : null}
          <View
            style={[styles.circleWrap, !progressPercentage ? { marginRight: -20 } : null]}
            // @ts-ignore
            onMouseEnter={() => setIsHovered(true)}
            // @ts-ignore
            onMouseLeave={() => setIsHovered(false)}
          >
            <View style={styles.innerCircle} />
          </View>
        </View>
      </View>
    </View>
  );
};

export { LessonProgress };
