import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    borderTopColor: "#EDEEEF",
    borderTopWidth: 1,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 20,
    marginBottom: 16,
  },
  formLabel: {
    fontFamily: "OpenSans_500Medium",
    fontSize: 17,
    color: "#202223",
    marginBottom: 8,
  },
  formInput: {
    borderColor: "#BABFC3",
    borderWidth: 1,
    borderRadius: 4,
    paddingHorizontal: 12,
    paddingVertical: 8,
    flex: 1,
    marginRight: 8,
    marginBottom: 8,
    flexDirection: "row",
    alignItems: "center",
  },
});
