import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const SortIcon = (props: SvgProps) => (
  <Svg width={21} height={12} fill="none" {...props}>
    <Path
      fill="#5C5F62"
      d="M.98 1a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2h-18a1 1 0 0 1-1-1ZM3.98 6a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2h-12a1 1 0 0 1-1-1ZM8.98 10a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-4Z"
    />
  </Svg>
);

export { SortIcon };
