import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  trainingProgressContainer: {
    borderBottomColor: "#EDEEEF",
    borderBottomWidth: 1,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 15,
    paddingBottom: 15,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  trainingTitle: {
    width: 333,
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 13,
    lineHeight: 20,
  },
  viewCourse: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    textDecorationLine: "underline",
    color: "#2C6ECB",
    width: 164,
  },
  viewCourseIcon: {
    marginLeft: 5,
    marginTop: 5,
  },
});
