import React, { useState } from "react";
import { View, Text, Image, TouchableOpacity } from "react-native";
import { useNavigate, useParams } from "react-router-native";
import {
  ButtonGeneral,
  ButtonVariant,
} from "@components/general/button-general";
import { LeftArrow } from "@components/general/icons";
import { ContentBox } from "@components/general/layouts/content-box";
import { SelectOption } from "@components/general/form/select-option";
import { styles, ids } from "./style";
import { dateFromMillis } from "@utils/misc";
import {
  ReadState,
  useAdminGetUserRequestQuery,
  useUpdateRequestReadStateMutation,
} from "@gql/generated/generated";

const AnalyticsViewRequest = () => {
  const navigate = useNavigate();
  const { type, id } = useParams();
  const [readState, setReadState] = useState(ReadState.UNREAD);

  const { data } = useAdminGetUserRequestQuery({
    variables: { id: id! },
    onCompleted(data) {
      if (data?.adminGetUserRequest.readState)
        setReadState(data?.adminGetUserRequest.readState);
    },
    skip: !id,
  });

  const [updateStatus] = useUpdateRequestReadStateMutation();

  return (
    <View>
      <View style={styles.head}>
        <ButtonGeneral
          variant={ButtonVariant.Secondary}
          onPress={() => navigate(-1)}
        >
          <LeftArrow />
        </ButtonGeneral>
        <Text style={styles.headerTitle}>
          Requested {type === "events" ? "Event" : "Content"}
        </Text>
      </View>
      <View style={styles.split} dataSet={{ media: ids.split }}>
        <View style={styles.leftContent}>
          <ContentBox style={styles.contentBox}>
            <Text style={styles.text}>Sent from:</Text>
            <View style={styles.userWrap}>
              <View style={styles.userBox}>
                <View>
                  <Image
                    style={styles.userImage}
                    source={{ uri: data?.adminGetUserRequest.user.img || "" }}
                  />
                  <View
                    style={[
                      styles.presenceCircle,
                      {
                        backgroundColor: data?.adminGetUserRequest.user
                          ?.isOnline
                          ? "#AEE9D1"
                          : "#FED3D1",
                      },
                    ]}
                  />
                </View>
                <Text style={styles.userName}>
                  {data?.adminGetUserRequest.user?.name || ""}
                </Text>
              </View>
              <TouchableOpacity
                onPress={() =>
                  navigate(
                    `/user-editor/${data?.adminGetUserRequest.user.userId}`
                  )
                }
              >
                <Text style={styles.viewUser}>View User</Text>
              </TouchableOpacity>
            </View>
            <Text style={styles.text}>Date:</Text>
            <Text style={styles.bottomText}>
              {dateFromMillis(Number(data?.adminGetUserRequest?.createdAt))}
            </Text>
            <Text style={styles.text}>Type:</Text>
            <Text style={styles.bottomText}>
              {type === "events" ? "Event" : "Content"}
            </Text>
            <Text style={styles.text}>Request details:</Text>
            <Text style={styles.detailsText}>
              {data?.adminGetUserRequest.subject || ""}
            </Text>
            <Text style={styles.finalText}>
              {data?.adminGetUserRequest.description || ""}
            </Text>
          </ContentBox>
        </View>
        <View style={styles.rightContent}>
          <ContentBox style={styles.contentBox}>
            <Text style={styles.actionText}>Action</Text>
            <SelectOption
              onChange={(value) => {
                if (data?.adminGetUserRequest.id) {
                  setReadState(value as ReadState);
                  updateStatus({
                    variables: {
                      id: data?.adminGetUserRequest.id,
                      state: value as ReadState,
                    },
                  });
                }
              }}
              value={readState}
              options={[
                { label: "Do nothing", value: ReadState.UNREAD },
                { label: "Mark as read", value: ReadState.READ },
              ]}
            />
          </ContentBox>
        </View>
      </View>
    </View>
  );
};

export { AnalyticsViewRequest };
