import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  dotsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: 30,
    height: 30,
    borderRadius: 30,
  },
  dotsContainerHover: {
    backgroundColor: "#EBEDF0",
  },
  dot: {
    backgroundColor: "#5C5F62",
    width: 3,
    height: 3,
    borderRadius: 3,
  },
});
