import { Text, View } from "react-native";
import { useFormikContext } from "formik";
import { DropMenuFormik } from "@pages/account/profile/form-elements/drop-menu-formik/DropMenuFormik";
import { FormikAutocompleteSelect } from "@components/back-office/editor/formik-autocomplete-select";
import { textStyles } from "@styles/text";
import { AccountType, useGetJobTitlesQuery } from "@gql/generated/generated";
import { SelectOption } from "@components/general/form/select-option";
import { UserOrganizationComponentProps } from "./shared";
import { InitialValuesProps } from "@pages/back-office/users-back-office/users-editor/userInitialValues";

export const UserOrganizationForm = ({
  organisationsData,
}: UserOrganizationComponentProps) => {
  const { data } = useGetJobTitlesQuery();
  const { values, setFieldValue, errors, touched } =
    useFormikContext<InitialValuesProps>();

  const jobTitles = data?.getJobTitles?.jobTitles;
  const jobs = jobTitles?.map((job) => ({ value: job.name }));

  const organisationsOptions = organisationsData?.map((org) => {
    return { value: org.id, label: org.organisationInfo.companyName };
  });

  const selectedOrganisation = values.organisationId
    ? organisationsData?.find(
        ({ id }: { id: string }) => id === values.organisationId
      )
    : null;

  return (
    <>
      <View style={{ marginBottom: 20 }}>
        {jobs && jobs.length ? (
          <>
            <DropMenuFormik
              formikKey="jobPosition"
              placeholder="Job Role"
              data={jobs}
              withoutVerticalMargin
            />
            {errors["jobPosition"] && touched["jobPosition"] ? (
              <Text style={textStyles.error}>
                {errors["jobPosition"]?.toString()}
              </Text>
            ) : null}
          </>
        ) : null}
      </View>
      <View style={{ marginBottom: 20 }}>
        <Text style={[textStyles.semiBoldSmall, { marginBottom: 8 }]}>
          Account Type
        </Text>
        <SelectOption
          onChange={(accountType) => {
            setFieldValue("accountType", accountType);
          }}
          value={values.accountType ?? AccountType.EMPLOYEE}
          options={Object.values(AccountType).map((type) => ({
            label: type,
            value: type,
          }))}
        />
      </View>
      {organisationsOptions && organisationsOptions.length > 0 ? (
        <>
          <View>
            <>
              {selectedOrganisation ? (
                <Text style={[textStyles.semiBoldSmall, { marginBottom: 4 }]}>
                  Organisation
                  {/* Org change is not supported by the backend yet */}
                  {/* (click to edit) */}
                </Text>
              ) : (
                <Text style={[textStyles.semiBoldSmall, { marginBottom: 4 }]}>
                  Search for an Organisation
                </Text>
              )}
              <FormikAutocompleteSelect
                options={organisationsOptions}
                label=""
                fieldName="organisationId"
                multiSelect={false}
              />
              {selectedOrganisation ? (
                <Text
                  style={[textStyles.subduedTextMedium, { marginVertical: 4 }]}
                >
                  Number of Employees: {selectedOrganisation.members?.length}
                </Text>
              ) : null}
            </>
            {(errors["organisationId"] && touched["organisationId"]) ||
            (errors["companyName"] && touched["companyName"]) ? (
              <Text style={textStyles.error}>
                {errors["organisationId"]?.toString() ||
                  errors["companyName"]?.toString()}
              </Text>
            ) : null}
          </View>
        </>
      ) : null}
    </>
  );
};
