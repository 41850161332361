import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  selectWrap: {
    width: 160,
  },
  titleWrap: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 16,
  },
  title: {
    color: "#0D1738",
    fontSize: 26,
    fontFamily: "OpenSans_600SemiBold",
  },
  contentBox: {
    padding: 20,
    marginBottom: 16,
  },
  contentInner: {
    width: "100%",
    height: 200,
    justifyContent: "center",
    alignItems: "center",
  },
  chart: {
    flex: 1,
  },
});
