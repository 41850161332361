import { styles } from "./styles";
import { ContentBox } from "@components/general/layouts/content-box";
import { TitleBox } from "@components/account/title-box";
import { ListItem } from "@components/account/list-item";
import { useNavigate } from "react-router-native";

const OrganizationSettings = () => {
  const navigate = useNavigate();
  return (
    <ContentBox>
      <TitleBox title="Settings" />
      <ListItem
        title="Contact Info"
        subtitle="View and edit your contact info"
        onPress={() => navigate("contact-info")}
        titleStyle={styles.title}
        subtitleStyle={styles.subtitle}
        containerStyles={{
          paddingBottom: 0,
        }}
      />
      <ListItem
        title="Business Info"
        subtitle="View and complete your business info to upgrade the plan"
        onPress={() => navigate("business-info")}
        titleStyle={styles.title}
        subtitleStyle={styles.subtitle}
      />
    </ContentBox>
  );
};

export { OrganizationSettings };
