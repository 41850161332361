import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

interface Props extends SvgProps {
  color?: string;
}

const AlignLeftIcon = (props: SvgProps) => (
  <Svg width={17} height={15} fill="none" {...props}>
    <Path
      d="M1.5.225h14a1 1 0 1 1 0 2h-14a1 1 0 0 1 0-2Zm0 4h10a1 1 0 1 1 0 2h-10a1 1 0 0 1 0-2Zm0 4h14a1 1 0 1 1 0 2h-14a1 1 0 1 1 0-2Zm0 4h10a1 1 0 0 1 0 2h-10a1 1 0 1 1 0-2Z"
      fill={props.color || "#5C5F62"}
    />
  </Svg>
);

export { AlignLeftIcon };
