import React from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import { styles } from "./style";

interface Props {
  children: React.ReactNode;
  disableShadow?: boolean;
  style?: StyleProp<ViewStyle>;
  dataSetMedia?: string;
}

const ContentBox = ({
  children,
  disableShadow,
  style,
  dataSetMedia,
}: Props) => (
  <View
    style={[styles.wrap, !disableShadow && styles.shadow, style && style]}
    dataSet={{ media: dataSetMedia || "" }}
  >
    {children}
  </View>
);

export { ContentBox };
