import React from "react";
import { Text, TouchableOpacity } from "react-native";
import { ListModal } from "@components/general/modals/list-modal";
import { styles } from "./style";

interface CalendarItemProps {
  title: string;
  onPress?: () => void;
}

const CalendarItem = ({ title, onPress }: CalendarItemProps) => (
  <TouchableOpacity style={styles.calendarModalItem} onPress={onPress}>
    <Text style={styles.calendarModalItemText}>{title}</Text>
  </TouchableOpacity>
);

interface Props {
  isVisible: boolean;
  onClose: () => void;
}

const AddToCalendarModal = ({ isVisible, onClose }: Props) => (
  <ListModal isVisible={isVisible} onClose={onClose}>
    <Text style={styles.calendarModalTitle}>Add to Calendar</Text>
    <CalendarItem title="Apple" />
    <CalendarItem title="Google" />
    <CalendarItem title="Outlook" />
  </ListModal>
);

export { AddToCalendarModal };
