import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "rgba(13, 23, 56, 0.87))",
    alignItems: "center",
    justifyContent: "center",
  },
});
