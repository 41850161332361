import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  filterButton: {
    borderWidth: 0.2,
    borderColor: "#0D1738",
    borderStyle: "solid",
    paddingVertical: 10,
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
  },
  buttonText: {
    fontSize: 14,
    fontFamily: "OpenSans_600SemiBold",
    marginLeft: 8,
  },
  resetButton: {
    color: "#2C6ECB",
    textDecorationLine: "underline",
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
  },
});
