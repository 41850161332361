import { View } from "react-native";
import { styles } from "./style";
import { Dropdown } from "react-native-element-dropdown";
import { SetStateAction, useState } from "react";
import { PageVariant } from "@utils/models";

interface TabModuleProps {
  children: React.ReactNode;
  withTimeDropDown: boolean;
  variant?: PageVariant;
  setFilters?: React.Dispatch<SetStateAction<string>>;
}

const TabModule = ({
  children,
  withTimeDropDown,
  variant = PageVariant.assignment,
  setFilters,
}: TabModuleProps) => {
  const [selectedTimeframe, setSelectedTimeframe] = useState("This week");
  const dropdownTimeOption = [
    {
      value: "thisWeek",
      label: "This week",
    },
    {
      value: "nextWeek",
      label: "Next week",
    },
    {
      value: "nextMonth",
      label: "Next 30 days",
    },
  ];
  return (
    <View
      style={
        withTimeDropDown
          ? [
              styles.wrap,
              styles.wrapWithDropdown,
              variant !== PageVariant.member ? styles.wrapAssigmentPage : null,
            ]
          : null
      }
    >
      <View style={[!withTimeDropDown ? styles.wrap : null]}>
        <View style={styles.tabWrap}>{children}</View>
      </View>
      {withTimeDropDown ? (
        <View style={styles.dropDownWrap}>
          <Dropdown
            data={dropdownTimeOption}
            labelField="label"
            valueField="value"
            onChange={(e) => {
              setSelectedTimeframe(e.value);
              setFilters && setFilters(e.value);
            }}
            value={selectedTimeframe}
            style={styles.dropDown}
            selectedTextStyle={styles.dropDownSelected}
            iconStyle={styles.dropDownIcon}
            containerStyle={styles.dropDownContainerMenu}
            itemTextStyle={styles.dropDownItem}
            itemContainerStyle={styles.dropDownItemActive}
          />
        </View>
      ) : null}
    </View>
  );
};

export { TabModule };
