import React, { useMemo, useState } from "react";
import {
  ActivityIndicator,
  Image,
  ImageSourcePropType,
  Linking,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { APP_URL } from "@env";
import { pluralize } from "@utils/misc";
import { CaretDown } from "@components/general/icons/account-icons/CaretDown";
import { PlusIcon } from "@components/general/icons/feed-icons";
import { LinkIcon } from "@components/general/icons/org-icons";
import { Curriculum } from "@components/training/curriculum";
import { styles } from "./style";
import {
  EmployerUserOverview,
  Training,
  UserTrainingProgress,
  useGetAllTrainingsForAnalyticsLazyQuery,
} from "@gql/generated/generated";
import {
  fromSecondsToHoursOrMins,
  fromSecondsToNearestHalfHour,
} from "@utils/trainings";
import { useUserContext } from "@context/UserContext";
import { userProfileImage } from "@utils/userProfileImage";

interface ItemRowProps {
  name: string;
  image: ImageSourcePropType;
  jobTitle?: string;
  coursesEnrolled: number;
  isAdmin?: boolean;
  onExpand: () => void;
  isOnline: boolean;
  cumulativeLearningTime: number;
}

const ItemRow = ({
  name,
  image,
  jobTitle,
  coursesEnrolled,
  isAdmin,
  isOnline,
  onExpand,
  cumulativeLearningTime,
}: ItemRowProps) => (
  <View style={styles.itemRow}>
    <View style={styles.firstItemCell}>
      <View>
        <Image style={styles.userImage} source={image} />
        <View
          style={[
            styles.presenceCircle,
            { backgroundColor: isOnline ? "#AEE9D1" : "#FED3D1" },
          ]}
        />
      </View>
      <Text style={styles.userName}>{name}</Text>
      {isAdmin && (
        <View style={styles.adminItem}>
          <Text style={styles.adminItemText}>Admin</Text>
        </View>
      )}
    </View>
    {jobTitle ? (
      <View style={styles.itemCellTwo}>
        <Text style={styles.cellText} ellipsizeMode="tail" numberOfLines={1}>
          {jobTitle}
        </Text>
      </View>
    ) : null}
    <View style={styles.itemCell}>
      <Text style={styles.cellText}>
        {fromSecondsToNearestHalfHour(cumulativeLearningTime)}
      </Text>
    </View>
    <View style={styles.itemCell}>
      <Text style={styles.cellText}>{coursesEnrolled}</Text>
    </View>
    {coursesEnrolled > 0 ? (
      <View style={styles.lastItemCell}>
        <TouchableOpacity style={styles.touchBox} onPress={onExpand}>
          <CaretDown />
        </TouchableOpacity>
      </View>
    ) : null}
  </View>
);

interface TrainingWithCompletion
  extends Omit<Training, "comments" | "modules"> {
  stats: {
    totalLessons: number;
    completedLessons: string[];
    percentCompleted: number;
    enrolledDate?: number | null;
    totalModules: number;
  };
}

interface TrainingItemProps {
  training: TrainingWithCompletion;
  percentCompleted: number;
  isExpanded: boolean;
  onExpand: () => void;
  enrolledDate?: number | null;
}

const TrainingItem = ({
  training,
  percentCompleted,
  isExpanded,
  onExpand,
  enrolledDate,
}: TrainingItemProps) => {
  const numOfModules = training?.stats.totalModules ?? 0;
  const numOfLessons = training?.stats.totalLessons ?? 0;
  return (
    <View style={styles.trainingWrap}>
      <View style={styles.trainingInner}>
        <View style={styles.trainingCellOne}>
          <Text style={styles.trainingTitle}>{training.title}</Text>
        </View>
        <View style={styles.trainingCellTwo}>
          <Text style={styles.trainingPercentText}>{percentCompleted}%</Text>
          <View style={styles.progressWrap}>
            <View
              style={[styles.progressInner, { width: `${percentCompleted}%` }]}
            />
          </View>
        </View>
        <View style={styles.trainingCellThree}>
          <TouchableOpacity
            style={styles.viewCourseTouch}
            onPress={() =>
              Linking.openURL(`${APP_URL}/training/${training.id}`)
            }
          >
            <Text style={styles.viewCourseText}>View Course</Text>
            <LinkIcon />
          </TouchableOpacity>
        </View>
        <View style={styles.trainingCellFour}>
          <TouchableOpacity style={styles.touchBox} onPress={onExpand}>
            <PlusIcon />
          </TouchableOpacity>
        </View>
      </View>
      {isExpanded && (
        <View style={styles.trainingInfoWrap}>
          <Text style={styles.trainingInfoText}>
            {training && numOfModules} {pluralize(numOfModules, "Module")} •{" "}
            {fromSecondsToHoursOrMins(training.durationInSeconds ?? 0)} total •{" "}
            {training && numOfLessons} {pluralize(numOfLessons, "lesson")}
            <Text style={styles.trainingInfoSubText}>
              {enrolledDate
                ? ` started on ${new Date(enrolledDate).toDateString()}`
                : null}
            </Text>
          </Text>
          <Curriculum
            training={training}
            isTitleHidden
            completedLessons={training.stats.completedLessons ?? []}
          />
        </View>
      )}
    </View>
  );
};

interface Props extends EmployerUserOverview {
  isExpanded: boolean;
  onExpand: () => void;
}

const AnalyticsTrainingItem = (props: Props) => {
  const [expandedIndex, setExpandedIndex] = useState<number>();

  const { accountInfo, statistics, id } = props;

  const { currentUser } = useUserContext();

  const courseIds = props.trainingsProgress?.map((course) => course.trainingId);

  const [getTrainings, { data: trainingData, loading }] =
    useGetAllTrainingsForAnalyticsLazyQuery();

  const fetchTrainingsForUser = async () => {
    props.onExpand();
    return getTrainings({
      variables: {
        page: 1,
        limit: 20,
        filters: {
          documentIds: courseIds,
        },
      },
    });
  };

  const handleExpand = (index: number) => {
    setExpandedIndex(expandedIndex === index ? undefined : index);
  };

  const coursesWithCompletionStats = useMemo(() => {
    const courses = trainingData?.getAllTrainings.trainings.map((course) => {
      const progressForCourse = props.trainingsProgress?.filter((training) => {
        return training.trainingId === course.id;
      })?.[0];

      const totalLessons = course.numLessons ?? 0;

      const enrolledDate = progressForCourse?.dateEnrolled;

      return {
        ...course,
        stats: {
          totalLessons,
          totalModules: course.numModules ?? 0,
          completedLessons: progressForCourse?.completedLessons ?? [],
          percentCompleted:
            progressForCourse?.trainingCompletionPercentage ?? 0,
          enrolledDate,
        },
      };
    });
    return courses;
  }, [trainingData, props.trainingsProgress]);

  return (
    <View style={styles.wrap}>
      <ItemRow
        name={`${accountInfo?.firstName} ${accountInfo?.lastName}`}
        image={userProfileImage(currentUser)}
        coursesEnrolled={courseIds?.length ?? 0}
        // Sessions are only started 1 minute after a user isOnline
        // Since we do not poll the users online status, the signed in user could be shown as offline
        // This ensures that the current user is shown as online immediately
        isOnline={props.status?.isOnline || currentUser?.id === id}
        jobTitle={props.accountInfo?.companyInfo.jobPosition}
        onExpand={fetchTrainingsForUser}
        isAdmin={props.role?.isEmployer}
        cumulativeLearningTime={statistics?.cumulativeTrainingInSeconds || 0}
      />
      {props.isExpanded && (
        <>
          {loading && !coursesWithCompletionStats?.length ? (
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ActivityIndicator size="large" color="#213470" />
            </View>
          ) : null}
          {coursesWithCompletionStats?.map((training, index) => (
            <TrainingItem
              training={training}
              percentCompleted={training.stats.percentCompleted}
              isExpanded={expandedIndex === index}
              onExpand={() => handleExpand(index)}
              enrolledDate={training.stats.enrolledDate}
            />
          ))}
        </>
      )}
    </View>
  );
};

export { AnalyticsTrainingItem };
