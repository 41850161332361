import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  modalContainer: {
    backgroundColor: "rgba(13, 23, 56, 0.78);",
    height: "100%",
    width: "100%",
    position: "absolute",
    bottom: 0,
  },
  largeModalContainer: {
    paddingTop: 110,
  },
  contentContainer: {
    backgroundColor: "white",
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    width: "100%",
    position: "absolute",
    bottom: 0,
    paddingHorizontal: 24,
    paddingTop: 16,
    paddingBottom: 40,
  },
  largeContentContainer: {
    height: "100%",
  },
  modalHeader: {
    fontSize: 16,
    fontFamily: "OpenSans_600SemiBold",
    color: "#0D1738",
  },
});
