import React, { useState } from "react";
import { View } from "react-native";
import { useNavigate } from "react-router-native";
import { Wrapper } from "@components/general/layouts/wrapper/Wrapper";
import { IndexPageHeader } from "@components/general/index-page-header";
import { NotificationSwitch } from "@components/account/notification-switch";
import { ButtonGeneral } from "@components/general/button-general";
import { styles } from "./style";

const Assignment = () => {
  const navigate = useNavigate();
  const [notificationValues, setNotificationValues] = useState({
    newAssignment: false,
    pendingAssignment: false,
  });

  return (
    <Wrapper>
      <View style={styles.wrap}>
        <View>
          <IndexPageHeader title="Feed" onPressBack={() => navigate(-1)} />
          <NotificationSwitch
            title="New assignment"
            isActive={notificationValues.newAssignment}
            onChange={() =>
              setNotificationValues({
                ...notificationValues,
                newAssignment: !notificationValues.newAssignment,
              })
            }
          />
          <NotificationSwitch
            title="Pending assignment"
            isActive={notificationValues.pendingAssignment}
            onChange={() =>
              setNotificationValues({
                ...notificationValues,
                pendingAssignment: !notificationValues.pendingAssignment,
              })
            }
          />
        </View>
        <View style={styles.buttonWrap}>
          <ButtonGeneral label="Save" />
        </View>
      </View>
    </Wrapper>
  );
};

export { Assignment };
