import { Text, View } from "react-native";
import React from "react";
import { MenuContainer } from "../menu-container/MenuContainer";
import { styles } from "./styles";
import { Maybe } from "@gql/generated/generated";

interface PaymentActivityProps {
  isMenuOpen: boolean;
  setIsMenuOpen: (isMenuOpen: boolean) => void;
  payments: { chargeDate?: Maybe<string> }[];
  containerStyle?: any;
}

interface PaymentRowProps {
  date: string;
  status: string;
  paymentMethod: string;
}

const PaymentRow = ({ date, status, paymentMethod }: PaymentRowProps) => {
  const color =
    status === "Pending"
      ? "#B98900"
      : status === "Failed"
      ? "#D72C0D"
      : "#21A366";
  return (
    <View
      style={{
        flex: 1,
        borderBottomWidth: 1,
        borderBottomColor: "#E1E3E5",
        flexDirection: "row",
      }}
    >
      <Text style={[styles.dataText, styles.cellContainer]}>{date}</Text>
      <Text style={[styles.dataText, styles.cellContainer, { color }]}>
        {status}
      </Text>
      <Text style={[styles.dataText, styles.cellContainer]}>
        {paymentMethod}
      </Text>
    </View>
  );
};

const PaymentActivity = ({
  isMenuOpen,
  setIsMenuOpen,
  payments,
  containerStyle,
}: PaymentActivityProps) => {
  return (
    <MenuContainer
      title="Payment Activity"
      onPress={() => setIsMenuOpen(!isMenuOpen)}
      isMenuOpen={isMenuOpen}
      containerStyle={[
        {
          borderBottomWidth: 0,
        },
        containerStyle,
      ]}
    >
      <View
        style={{
          borderBottomWidth: 1,
          borderBottomColor: "#E1E3E5",
          flexDirection: "row",
          height: 36,
          alignItems: "center",
        }}
      >
        <Text style={[styles.headerText]}>Date</Text>
        <Text style={[styles.headerText]}>Status</Text>
        <Text style={[styles.headerText]}>Payment Method</Text>
      </View>
      {payments?.length > 0
        ? payments?.map((payment: any, index: number) => (
            <PaymentRow
              date={payment.chargeDate}
              status="Pending"
              paymentMethod="GoCardless"
              key={index}
            />
          ))
        : null}
    </MenuContainer>
  );
};

export { PaymentActivity };
