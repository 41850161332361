import { ActivityIndicator, View } from "react-native";
import { AllMemberCard } from "../all-member-card/AllMemberCard";
import { styles } from "./styles";
import { useState } from "react";
import { Row } from "@components/general/row";
import {
  ButtonGeneral,
  ButtonVariant,
} from "@components/general/button-general";
import { LeftArrow, RightArrow } from "@components/general/icons";
import { useGetUsersQuery } from "@gql/generated/generated";

const AllMemberCards = () => {
  const [page, setPage] = useState(1);

  const {
    data: { getUsers } = {},
    loading,
    refetch,
  } = useGetUsersQuery({
    variables: {
      input: {
        page,
        limit: 10,
      },
    },
  });

  const { users, hasNextPage } = getUsers || {};

  const handleMemberAction = () => {
    refetch();
  };

  if (loading || !users) {
    return (
      <ActivityIndicator
        size="large"
        color="#213470"
        style={{ paddingVertical: 20 }}
      />
    );
  }

  return (
    <View style={styles.cardsWrapper}>
      <>
        {users
          .filter(({ id }) => id)
          .map((user) => (
            <AllMemberCard
              onAction={handleMemberAction}
              key={user.id}
              id={user.id}
              profileURL={user.profilePhotoUrl}
              firstName={user.accountInfo?.firstName}
              lastName={user.accountInfo?.lastName}
              role={user.role}
              jobPosition={user.accountInfo?.companyInfo.jobPosition}
              lastLoggedInDate={
                user.lastLoggedInDate ? new Date(user.lastLoggedInDate) : null
              }
              registrationDate={
                user.registrationDate ? new Date(user.registrationDate) : null
              }
              status={user.status}
            />
          ))}
      </>
      <Row
        style={{ justifyContent: "flex-end", marginTop: 20, marginRight: 40 }}
      >
        <ButtonGeneral
          variant={ButtonVariant.Secondary}
          onPress={() => setPage(page - 1)}
          disabled={page === 1}
          style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
        >
          <LeftArrow />
        </ButtonGeneral>
        <ButtonGeneral
          variant={ButtonVariant.Secondary}
          disabled={!hasNextPage}
          onPress={() => setPage(page + 1)}
          style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
        >
          <RightArrow />
        </ButtonGeneral>
      </Row>
    </View>
  );
};

export { AllMemberCards };
