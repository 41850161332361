import React from "react";
import { Text, View } from "react-native";
import { ListModal } from "@components/general/modals/list-modal";
import { GreenCircleCheckIcon } from "@components/general/icons/event-icons";
import { styles } from "./style";

interface Props {
  onClose?: () => void;
  isVisible: boolean;
  title: string;
  children?: React.ReactNode;
  description?: string;
}

const SuccessModal = ({
  isVisible,
  onClose,
  title,
  description,
  children,
}: Props) => (
  <ListModal isVisible={isVisible} onClose={onClose}>
    <View style={styles.titleWrap}>
      <GreenCircleCheckIcon />
      <Text style={styles.title}>{title}</Text>
    </View>
    {children ? (
      children
    ) : (
      <Text style={styles.description}>{description}</Text>
    )}
  </ListModal>
);

export { SuccessModal };
