import { Text, TouchableOpacity, View } from "react-native";
import { useState } from "react";
import { DownArrow } from "@components/general/icons";
import { styles } from "./styles";

interface AccordionMenuProps {
  title: string;
  children: React.ReactNode;
  index: number;
  lastIndex: number;
  blockedAccessCatLength: number;
}

const AccordionMenu = ({
  title,
  children,
  index,
  lastIndex,
  blockedAccessCatLength,
}: AccordionMenuProps) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <View
      style={[
        styles.wrap,
        index === 0 ? styles.wrapTopBorder : null,
        index === lastIndex ? styles.wrapBottomBorder : null,
      ]}
    >
      <TouchableOpacity
        onPress={() => setExpanded(!expanded)}
        style={[styles.menuButtonWrap]}
      >
        <Text style={styles.menuButtonTitle}>{title}</Text>
        <View
          style={{
            flexDirection: "row",
          }}
        >
          <Text
            style={[
              styles.menuButtonText,
              {
                color: blockedAccessCatLength > 0 ? "#D72C0D" : "#6D7175",
              },
            ]}
          >
            {blockedAccessCatLength} Blocked
          </Text>
          <DownArrow
            color="#0D1738"
            style={expanded ? { transform: [{ rotate: "180deg" }] } : null}
          />
        </View>
      </TouchableOpacity>
      {expanded && children}
    </View>
  );
};

export { AccordionMenu };
