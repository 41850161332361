import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  headers: {
    display: "flex",
    flexDirection: "row",
    borderColor: "#F2F2F2",
    borderBottomWidth: 1,
    paddingBottom: 16,
    height: 52,
  },
  dataTable: {
    width: 1177,
    height: 1000,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#F2F2F2",
    marginTop: -15,
  },
  titleText: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 10.5,
    lineHeight: 10.5,
    color: "#6E757C",
    marginLeft: 16,
    marginTop: 16,
    width: 79,
  },
  authorText: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 10.5,
    lineHeight: 10.5,
    color: "#6E757C",
    marginLeft: 290,
    marginTop: 16,
    width: 79,
  },
  categoryText: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 10.5,
    lineHeight: 10.5,
    color: "#6E757C",
    marginLeft: 110,
    marginTop: 16,
    width: 47,
  },
  dateText: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 10.5,
    lineHeight: 10.5,
    color: "#6E757C",
    marginLeft: 135,
    marginTop: 16,
    width: 47,
  },
  userTypeText: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 10.5,
    lineHeight: 10.5,
    color: "#6E757C",
    marginTop: 16,
    width: 72,
  },
  eventTypeText: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 10.5,
    lineHeight: 10.5,
    color: "#6E757C",
    marginLeft: 70,
    marginTop: 16,
    width: 72,
  },
  table: {
    height: 1100,
    width: 1177,
    marginLeft: 36,
    marginTop: 5,
  },
  separation: {
    borderTopColor: "#F2F2F2",
    borderTopWidth: 0.5,
    marginLeft: -37,
    width: 1177,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 25,
    paddingBottom: 25,
  },
  rowTitle: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 12,
    lineHeight: 14.52,
    color: "#0D1738",
    marginLeft: -16,
    marginRight: 111,
    width: 253,
    height: 29,
    overflow: "hidden",
  },
  rowAuthor: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 12,
    lineHeight: 14.52,
    color: "#0D1738",
    width: 79,
    overflow: "hidden",
    height: 29,
  },
  rowCategory: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 12,
    lineHeight: 14.52,
    color: "#0D1738",
    marginLeft: 110,
    width: 103,
    overflow: "hidden",
    height: 29,
  },
  rowDate: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 12,
    lineHeight: 14.52,
    color: "#0D1738",
    marginLeft: 79,
    width: 103,
  },
  rowUserType: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 12,
    lineHeight: 14.52,
    color: "#0D1738",
    marginLeft: 95,
    width: 103,
  },
  rowEventType: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 12,
    lineHeight: 14.52,
    color: "#0D1738",
    marginLeft: 13,
  },
  options: {
    marginLeft: 55,
    width: 44,
    height: 36,
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 23,
    lineHeight: 14.52,
    color: "#0D1738",
    marginTop: -10,
    paddingTop: 3,
    display: "flex",
    flexDirection: "row",
    borderWidth: 2,
    borderColor: "#E3E5E7",
    borderRadius: 4,
    justifyContent: "center",
  },
  popup: {
    backgroundColor: "#FFFFFF",
    position: "absolute",
    marginLeft: 1060,
    borderRadius: 8,
    shadowColor: "rgba(0, 0, 0, 0.15)",
    shadowOffset: {
      width: 1,
      height: 1,
    },
    borderColor: "rgba(0, 0, 0, 0.15)",
    borderWidth: 1,
    width: 94,
    height: 92,
  },
  optionWrapper: {
    display: "flex",
    flexDirection: "row",
    marginTop: 3.5,
  },
  viewIcn: {
    width: 16,
    height: 9.14,
    marginLeft: 15,
    marginTop: 9,
  },
  optionPopup: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 12,
    lineHeight: 26,
    color: "#6E757C",
    marginLeft: 12,
  },
  writeIcn: {
    width: 14,
    height: 14,
    marginLeft: 16,
    marginTop: 4,
  },
  copyIcn: {
    width: 15,
    height: 15,
    marginLeft: 16,
    marginTop: 4,
  },
  deleteIcn: {
    width: 12,
    height: 15,
    marginLeft: 18,
    marginTop: 4,
  },
  optionPopupDelete: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 12,
    lineHeight: 26,
    color: "#EB001B",
    marginLeft: 13,
  },
  checkbox: {
    borderRadius: 20,
    marginRight: 0,
    paddingRight: 0,
    borderColor: "white",
    width: 7,
    height: 7,
    marginLeft: 1,
    marginTop: 1,
  },
  borderCheckbox: {
    borderColor: "#6E757C",
    borderWidth: 1,
    width: 11,
    height: 11,
    borderRadius: 20,
    marginRight: 0,
    paddingRight: 0,
    marginLeft: 33,
    marginTop: 1,
  },
  toggledRow: {
    display: "flex",
    flexDirection: "row",
    marginBottom: -5,
    marginTop: -5,
  },
  subTitle: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 10.5,
    lineHeight: 10.5,
    color: "#6E757C",
    borderRightColor: "#6E757C",
    borderRightWidth: 1,
    paddingHorizontal: 5,
  },
  subTitleRed: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 10.5,
    lineHeight: 10.5,
    color: "#EB001B",
    paddingLeft: 5,
  },
  onlinePoll: {
    width: 8,
    height: 8,
    backgroundColor: "#2AC670",
    borderRadius: 15,
    marginTop: 3,
  },
  offlinePoll: {
    width: 8,
    height: 8,
    backgroundColor: "#EB001B",
    borderRadius: 15,
    marginTop: 3,
  },
  memberSinceText: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 10.5,
    lineHeight: 10.5,
    color: "#6E757C",
    marginLeft: 70,
    width: 72,
  },
  rowStatus: {
    backgroundColor: "#2AC670",
    width: 8,
    height: 8,
    borderRadius: 15,
    marginLeft: 70,
    marginTop: 5,
  },
  rowMemberSince: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 12,
    lineHeight: 14.52,
    color: "#0D1738",
    marginLeft: 10,
    width: 103,
  },
  avatarUser: {
    width: 35,
    height: 35,
    marginTop: -5,
    marginLeft: 35,
  },
  flexCreate: {
    display: "flex",
    flexDirection: "row",
    marginTop: -10,
  },
  createBtn: {
    fontFamily: "OpenSans_700Bold",
    fontSize: 12,
    lineHeight: 15,
    color: "#0D1738",
    backgroundColor: "#D9D9D9",
    borderRadius: 15,
    width: 17,
    height: 17,
    paddingHorizontal: 4,
    marginRight: 5,
    marginLeft: 40,
  },
  createBtnText: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 10.5,
    lineHeight: 10.5,
    color: "#6E757C",
    marginTop: 5,
  },
  footerTableText: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 14,
    lineHeight: 20,
    color: "#6D7175",
    textAlign: "center",
    marginTop: -9,
  },
  footerTable: {
    width: 1177,
    height: 69,
    backgroundColor: "#FAFBFB",
    marginLeft: -36,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    justifyContent: "center",
  },
  filterText: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 14,
    lineHeight: 20,
    color: "#202223",
    marginTop: 2,
  },
});
