import React from "react";
import { Text, View } from "react-native";
import { getInitials, pluralize } from "@utils/misc";
import { ReplyIcon, StarIcon } from "@components/general/icons/feed-icons";
import {
  CoursesIcon,
  UserIcon,
} from "@components/general/icons/training-icons";
import { ids, styles } from "./style";
import { SocialButtons } from "@components/general/icons";
import { Instructor as InstructorType } from "@gql/generated/generated";
import { Image } from "@components/general/image-from-storage";

interface IconItemProps {
  icon: React.ReactNode;
  title: string;
}
const IconItem = ({ icon, title }: IconItemProps) => (
  <View style={styles.iconWrap}>
    <View
      style={{
        width: 20,
        alignItems: "center",
      }}
    >
      {icon}
    </View>
    <Text style={styles.iconText}>{title}</Text>
  </View>
);

interface Props {
  instructor?: InstructorType | null;
  trainingId?: string | null;
}

const Instructor = ({ instructor, trainingId }: Props) => {
  const {
    photo,
    name,
    description,
    allCoursesAverageStars,
    totalReviewCount,
    totalStudentCount,
    socials,
  } = instructor || {};

  return (
    <View style={styles.wrap}>
      <View style={styles.instructorInfoWrap}>
        {photo ? (
          <Image path={photo.storagePath} style={styles.instructorPhoto} />
        ) : (
          <View style={styles.instructorPhoto}>
            <Text style={styles.initials}>{name && getInitials(name)}</Text>
          </View>
        )}
        <View>
          <Text style={styles.aboutText}>About the instructor</Text>
          <Text style={styles.instructorName}>{name}</Text>
        </View>
      </View>
      <View
        style={styles.descriptionWrap}
        dataSet={{ media: ids.descriptionWrap }}
      >
        <Text
          style={[styles.regularText, styles.description]}
          dataSet={{ media: ids.description }}
        >
          {description}
        </Text>
        <View>
          <IconItem
            icon={<StarIcon fillColor="#0D1738" strokeColor="#0D1738" />}
            title={`Instructor Rating ${allCoursesAverageStars}`}
          />
          <IconItem
            icon={<ReplyIcon fillColor="#0D1738" />}
            title={`${totalReviewCount} ${pluralize(
              totalReviewCount ?? 0,
              "Review"
            )}`}
          />
          <IconItem
            icon={<UserIcon />}
            title={`${totalStudentCount} Students`}
          />
          <IconItem icon={<CoursesIcon />} title="3 Courses" />
        </View>
      </View>
      <View style={styles.socialsWrap}>
        <SocialButtons
          twitterLink={socials?.twitter}
          facebookLink={socials?.facebook}
          instagramLink={socials?.instagram}
          linkedInLink={socials?.linkedin}
          id={trainingId}
          contentType="Trainings"
        />
      </View>
    </View>
  );
};

export { Instructor };
