import StyleSheet from "react-native-media-query";
import { Platform } from "react-native";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    flex: 1,
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 40,
    justifyContent: "space-between",
  },
  logo: {
    width: 158,
    height: 26,
  },
  text: {
    fontSize: 20,
    fontFamily: "OpenSans_600SemiBold",
    lineHeight: 28,
    marginTop: 20,
    marginBottom: 40,
    width: "90%",
  },
  registerWrap: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 16,
  },
  registerText: {
    color: "#202223",
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
  },
  registerButton: {
    color: "#2C6ECB",
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
  },
  wrapInner: {
    width: "100%",
    alignItems: "center",
  },
  buttonWrap: {
    width: "100%",
  },
  submitButton: {
    ...(Platform.OS === "web" && { height: 36 }),
  },
});
