export interface ReasonsInterface {
  selected: boolean;
  value: string;
}

export interface ProfileInterface {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  houseNumber: string;
  street: string;
  address: string;
  postcode: string;
  city: string;
  country: string;
  biggestFocus: string[];
  reasonsForJoining: {
    strategy: ReasonsInterface;
    community: ReasonsInterface;
    business: ReasonsInterface;
    account: ReasonsInterface;
    ideas: ReasonsInterface;
    leadership: ReasonsInterface;
    team: ReasonsInterface;
  };
  reasonArray: string;
  orgLogoUrl: string;
}

export const defaultReasonsForJoining = {
  strategy: {
    selected: false,
    value: "To access experts and advisors for input into my strategy",
  },
  community: {
    selected: false,
    value:
      "To see what others are doing and to benchmark my business and my approach against others",
  },
  business: { selected: false, value: "To help scale my business" },
  account: { selected: false, value: "To hold me to account" },
  ideas: { selected: false, value: "To access different ideas" },
  leadership: {
    selected: false,
    value: "To develop myself as a recruitment business leader",
  },
  team: { selected: false, value: "To develop my team" },
};

export const initialValuesProfile = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  houseNumber: "",
  street: "",
  address: "",
  postcode: "",
  city: "",
  country: "",
  biggestFocus: [],
  reasonsForJoining: defaultReasonsForJoining,
  reasonArray: "",
  profilePhotoUrl: "",
};

export interface JobRoleInterface {
  jobPosition: string;
  companyName: string;
  yearsInCompany: string;
}

export const initialValuesJobRole = {
  jobPosition: "",
  companyName: "",
  yearsInCompany: "",
};
