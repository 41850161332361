import StyleSheet from "react-native-media-query";
import { mediaQuery } from "@utils/misc";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 10,
    [mediaQuery.forMobile]: {
      marginBottom: 4,
    },
  },
  label: {
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
    color: "#202223",
  },
  optional: {
    fontSize: 12,
    fontFamily: "OpenSans_400Regular",
    color: "#202223",
  },
});
