import React, { useMemo, useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import {
  ArrowDown,
  ArrowUp,
  CheckIcon,
  FileIcon,
  LockIcon,
  PlayIcon,
} from "@components/general/icons/training-icons";
import { CaretUp } from "@components/general/icons/account-icons/CaretUp";
import { CaretDown } from "@components/general/icons/account-icons/CaretDown";
import { LessonType } from "@utils/models";
import { getModuleLessons } from "@utils/misc";
import { ids, styles } from "./style";
import { Lesson as L, Training, UserLessonAccess } from "@gql/generated/generated";
import { convertSecondsToDisplay } from "@utils/TIme";
import { useNavigate } from "react-router-native";
import { InfoIcon } from "@components/general/icons/roadmap";
import { Tooltip, TooltipWithChild } from "@components/general/tooltip";
import { WebIcon } from "@components/general/icons/social-icons";
import { Toast } from "@components/general/toast";
import RenderHtml from "react-native-render-html";

interface ModuleProps {
  lessons: L[];
  moduleTitle: string;
  moduleDuration: number;
  moduleDescription: string;
  completedLessons?: string[] | undefined;
  moduleId: string;
  trainingId?: string;
  isEnrolled?: boolean;
  userLessonsAccess?: UserLessonAccess[]
}

const Module = ({
  lessons,
  moduleTitle,
  moduleDescription,
  moduleDuration,
  completedLessons,
  moduleId,
  trainingId,
  isEnrolled,
  userLessonsAccess,
}: ModuleProps) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <View style={styles.accordionWrap}>
      <TouchableOpacity onPress={() => setIsExpanded(!isExpanded)}>
        <View
          style={styles.accordionHeader}
          dataSet={{ media: ids.accordionHeader }}
        >
          <View style={styles.leftHeader}>
            <View>
              {isExpanded ? (
                <ArrowUp style={styles.arrowIcon} />
              ) : (
                <ArrowDown style={styles.arrowIcon} />
              )}
            </View>

            <View style={{ flex: 3 }}>
              <Tooltip text={moduleTitle} toolTipText={moduleTitle} />
            </View>
            <TooltipWithChild toolTipText={moduleDescription}>
              <View style={{ padding: 4 }}>
                <InfoIcon />
              </View>
            </TooltipWithChild>
            <View style={{ flex: 3, alignItems: "flex-end" }}>
              <Text style={styles.headerText}>
                {lessons.length} lesson{lessons.length === 1 ? "" : "s"} •{" "}
                {convertSecondsToDisplay(moduleDuration)}
              </Text>
            </View>
          </View>
        </View>
      </TouchableOpacity>
      {isExpanded && (
        <View style={styles.accordionContent}>
          {lessons.map(
            (
              {
                id,
                lessonType,
                lessonTitle,
                lessonDescription,
                videoFile,
                durationInSeconds,
              },
              index
            ) => {
              const hasVideo =
                lessonType?.includes(LessonType.video) && videoFile;

              const minutes = Math.floor((durationInSeconds ?? 0) / 60);
              const seconds = (durationInSeconds ?? 0) - minutes * 60;

              const readTime = (durationInSeconds ?? 0) / 60;

              const userHasLessonAccess = userLessonsAccess?.find(({id: accessId}) => id === accessId )?.hasAccess ?? false;

              return (
                <Lesson
                  key={id}
                  moduleId={moduleId}
                  lessonId={id}
                  trainingId={trainingId}
                  lessonType={hasVideo ? LessonType.video : LessonType.document}
                  isBottomMarginDisabled={index + 1 === lessons.length}
                  lessonTitle={lessonTitle}
                  lessonDescription={lessonDescription}
                  lessonDuration={
                    hasVideo
                      ? `${minutes}:${seconds}`
                      : `${readTime} minute read`
                  }
                  isLessonCompleted={!!completedLessons?.includes(id)}
                  isEnrolled={isEnrolled}
                  userHasAccess={userHasLessonAccess}
                />
              );
            }
          )}
        </View>
      )}
    </View>
  );
};

interface LessonProps {
  lessonType: LessonType;
  isBottomMarginDisabled?: boolean;
  lessonTitle: string;
  lessonDescription: string;
  lessonDuration: string;
  lessonId: string;
  moduleId: string;
  trainingId?: string;
  isLessonCompleted: boolean;
  isEnrolled?: boolean;
  userHasAccess: boolean;
}

const Lesson = ({
  lessonType,
  isBottomMarginDisabled,
  lessonTitle,
  lessonDescription,
  lessonDuration,
  lessonId,
  moduleId,
  trainingId,
  isLessonCompleted,
  isEnrolled,
  userHasAccess
}: LessonProps) => {
  const navigate = useNavigate();

  const [isExpanded, setIsExpanded] = useState(false);

  const [showToast, setShowToast] = useState(false);

  const lessonStatus = useMemo(() => {
    return (
      <>
        {userHasAccess && isEnrolled 
          ? <TouchableOpacity
            onPress={() =>
              navigate(`/training/${trainingId}/lesson/${moduleId}/${lessonId}`)
            }
          >
            <Text style={{
              ...styles.previewText,
              ...(!isLessonCompleted ? {marginRight: 18} : {})
            }}>
              {
                lessonType === LessonType.document ? 'View' : 'Play'
              }
              {
                isLessonCompleted ? ' again' : ''
              }
              { isLessonCompleted ? <CheckIcon color={styles.success.color} style={{marginLeft: 10}} /> : ''}
            </Text>
          </TouchableOpacity>
          : 
          <TooltipWithChild toolTipText={`Please ${userHasAccess ? 'enrol' : 'upgrade'} to access this lesson`}>
            <View style={{flexDirection: 'row'}}>
              {!userHasAccess && <RenderHtml source={{html:`<a href="https://therecruitmentnetwork.com/membership/">Upgrade</a>`}}/>}
              <LockIcon style={{marginLeft: 10}}/>
            </View>
          </TooltipWithChild>
        }
      </>
    );
  }, [
    isLessonCompleted,
    isEnrolled,
    userHasAccess,
    navigate,
    trainingId,
    moduleId,
    lessonId,
    lessonType,
  ]);

  return (
    <View
      style={[
        styles.lessonWrap,
        isBottomMarginDisabled && styles.disableLessonMargin,
      ]}
    >
      <View style={styles.lessonHeader} dataSet={{ media: ids.lessonHeader }}>
        <TouchableOpacity
          style={{ ...styles.lessonTitleWrap, flex: 1 }}
          onPress={() => setIsExpanded(!isExpanded)}
        >
          <View style={{ marginRight: 10 }}>
            {lessonType === LessonType.document ? <FileIcon /> : <PlayIcon />}
          </View>
          <View style={{ flex: 1 }}>
            <Tooltip
              text={lessonTitle}
              toolTipText={lessonTitle}
              textStyles={styles.readText}
            />
          </View>

          {isExpanded ? <CaretUp /> : <CaretDown />}
          <View
            style={{
              flex: 1,
              alignItems: "flex-end",
              flexDirection: "row",
              gap: 6,
            }}
          >
            <View
              style={styles.previewWrap}
              dataSet={{ media: ids.previewWrap }}
            >
              {lessonStatus}
            </View>
            <View style={{ flex: 1 }}>
              <Text style={[styles.readText, { textAlign: "right" }]}>
                {lessonDuration}
              </Text>
            </View>
            <TouchableOpacity 
              style={{marginRight: 10}}
              onPress={() => {
                navigator.clipboard.writeText(`${window.location.href}/lesson/${moduleId}/${lessonId}`);
                setShowToast(true);
              }}
            >
              <WebIcon />
            </TouchableOpacity>
          </View>
        </TouchableOpacity>
      </View>
      {isExpanded ? (
        <Tooltip
          textStyles={styles.lessonDescription}
          toolTipText={lessonDescription.trim()}
          text={lessonDescription.trim()}
        />
      ) : null}
      {showToast && <Toast
          text="Link copied!"
          active={showToast}
          onDismiss={() => setShowToast(false)}
          duration={4500}
        />}
  </View>
  );
};

interface Props {
  training?: Partial<Training> | null;
  isTitleHidden?: boolean;
  completedLessons: string[];
}

const Curriculum = ({
  training,
  isTitleHidden,
  completedLessons,
}: Props) => {
  return (
    <View style={styles.wrap}>
      {isTitleHidden ? null : (
        <>
          <Text style={styles.title}>Curriculum</Text>
          <Text style={styles.subtitle}>
            {training && training.modules?.length} Modules •{" "}
            {convertSecondsToDisplay(training?.durationInSeconds || 0)} total •{" "}
            {training && getModuleLessons(training?.modules)?.length} lessons
          </Text>
        </>
      )}
      {training?.modules?.map(
        ({
          lessons,
          moduleTitle,
          id,
          durationInSeconds,
          moduleDescription,
        }) => (
          <Module
            key={id}
            lessons={lessons}
            moduleId={id}
            moduleTitle={moduleTitle}
            moduleDescription={moduleDescription}
            moduleDuration={durationInSeconds ?? 0}
            completedLessons={completedLessons}
            trainingId={training.id}
            isEnrolled={training.isCurrentUserEnrolled}
            userLessonsAccess={training.userLessonsAccess ?? []}
          />
        )
      )}
    </View>
  );
};

export { Curriculum };
