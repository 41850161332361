import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  tabContent: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 30,
    paddingBottom: 30,
  },
  wrap: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  headWrap: {
    paddingTop: 40,
    paddingLeft: 40,
    paddingRight: 40,
    borderBottomColor: "#EDEEEF",
    borderBottomWidth: 1,
  },
  titleWrap: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  title: {
    color: "#0D1738",
    fontSize: 20,
    fontFamily: "OpenSans_400Regular",
  },
  titleBold: {
    fontFamily: "OpenSans_600SemiBold",
  },
  backText: {
    color: "#2C6ECB",
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
  },
});
