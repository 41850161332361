import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  tabItem: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 16,
    paddingBottom: 16,
    position: "relative",
  },
  tabActiveBottom: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: 3,
    borderTopRightRadius: 16,
    borderTopLeftRadius: 16,
    backgroundColor: "#2C6ECB",
  },
  tabTitle: {
    fontSize: 14,
    color: "#0D1738",
    fontFamily: "OpenSans_400Regular",
  },
  tabTitleActive: {
    color: "#2C6ECB",
  },
  textWrap: {
    flexDirection: "row",
    alignItems: "center",
  },
  pillWrap: {
    marginLeft: 4,
  },
});
