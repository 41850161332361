import "@utils/sentry";
import { ActivityIndicator, /* Image */ Platform, View, Text, Linking } from "react-native";
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useRoutes,
} from "react-router-native";
import AsyncStorage from "@react-native-async-storage/async-storage";

// import LogoWhite from "../assets/img/LogoWhite.png";
import { PageVariant } from "@utils/models";

// Popups
import { styles } from "@styles/Popup/mainPopup";

// Fonts
import {
  OpenSans_400Regular,
  OpenSans_500Medium,
  OpenSans_600SemiBold,
  OpenSans_700Bold,
  useFonts,
} from "@expo-google-fonts/open-sans";

// Components imports
import { Navbar as NavbarComponent } from "@components/navbar";
import { WelcomePopup } from "@components/popup/WelcomePopup";
import { MobileNavbar } from "@components/general/mobile-navbar";

// Pages imports
import { Home } from "@pages/home";
import { ContentView } from "@pages/content/content-view";
import { Login } from "@pages/login";
import { ForgotPassword } from "@pages/forgot-password";
import { EventNative, EventWeb } from "@pages/events/event-view";
import { BackOffice } from "@pages/back-office";
import { Account } from "@pages/account/account";
import { Help } from "@pages/account/help";
import { Settings } from "@pages/account/settings";
import { NotificationSettings } from "@pages/account/notification-settings";
import { HelpRequest } from "@pages/account/help-request";
import { ContactSupport } from "@pages/account/contact-support";
import { TrainingDetail } from "@pages/training/training-detail";
import { Lesson } from "@pages/training/lesson";
import { Saved } from "@pages/org/saved";
import { IndexPageLayout } from "@pages/events/index-page-layout";
import { navbarRoutes } from "@utils/misc";
import { Assignment } from "@pages/org/assignment";
import { Profile } from "@pages/account/profile";
import { JobRole } from "@pages/account/job-role";
import { Password } from "@pages/account/password";
import { Members } from "@pages/org/members";
import { Welcome } from "@pages/sign-up/welcome";
import { SignUp } from "@pages/sign-up";
import { EmailVerified } from "@pages/sign-up/email-verified";
import { VerifyEmail } from "@pages/sign-up/verify-email";
import { Content as ContentNotificationSettings } from "@pages/account/notification-settings/content";
import { Events as EventsNotificationSettings } from "@pages/account/notification-settings/events";
import { Training as TrainingNotificationSettings } from "@pages/account/notification-settings/training";
import { Assignment as AssignmentNotificationSettings } from "@pages/account/notification-settings/assignment";
import { Feed as FeedNotificationSettings } from "@pages/account/notification-settings/feed";
import { SavedItems as SavedItemsNotificationSettings } from "@pages/account/notification-settings/saved-items";
import { SearchResults } from "@pages/search/search-results";
import { MemberView } from "@pages/org/members/member-view";
import { AllMembers } from "@pages/org/members/all-members/AllMembers";
import { AddNewMember } from "@pages/org/members/add-new-member/AddNewMember";
import { SavedTypeNative } from "@pages/org/saved-type-native";
import { SavedListNative } from "@pages/org/saved-list-native";
import { AssignmentListNative } from "@pages/org/assignment-list-native";
import { EditProfile } from "@pages/account/profile/edit-profile/EditProfile";
import { PersonalInfo } from "@pages/account/profile/personal-info";
import { OrganizationSettings } from "@pages/org/organization-settings/OrganizationSettings";
import { ContactInfo } from "@pages/org/contact-info/ContactInfo";
import { BusinessInfo } from "@pages/org/business-info/BusinessInfo";
import { RoadmapIntro } from "@pages/roadmap/roadmap-intro";
import { NorthStarIndex } from "@pages/roadmap/north-star/north-star-index";
import { GoalIndex } from "@pages/roadmap/goal/goal-index";
import { PulseIndex } from "@pages/roadmap/pulse/pulse-index";
import { PulseResultPlan } from "@pages/roadmap/pulse/pulse-result-plan";
import { PulseContent } from "@pages/roadmap/pulse/pulse-content";
import { Priorities } from "@pages/roadmap/priorities";
import { Host } from "react-native-portalize";
import { SelectedUsersAssignModalContextProvider } from "@context/SelectedUsersAssignModalContext";
import { Marketplace } from "@pages/marketplace";
import { MarketplaceIndividualView } from "@pages/marketplace-individual-view";
import { AnalyticsIndex } from "@pages/org/analytics/analytics-index";
import { AnalyticsMembers } from "@pages/org/analytics/analytics-members";
import { AnalyticsTrnWorld } from "@pages/org/analytics/analytics-trn-world";
import { SubscriptionPlan } from "@pages/account/subscription-plan/SubscriptionPlan";
import { Payment } from "@pages/account/payment/Payment";
import { ResetPassword } from "@pages/reset-password";
import { Helmet } from "react-helmet";
import { AllNotifications } from "@pages/account/notification-settings/all-notifications";
import { ManageNotifications } from "@pages/account/notification-settings/manage-notifications/ManageNotifications";
import { DeleteAccount } from "@pages/account/delete-account";
import { auth } from "./firebase";
import { UpdateContext } from "@utils/UpdateContext";
import ExpoConstants from "expo-constants";
import { User } from "firebase/auth";
import { TrainingProgress } from "@pages/org/members/training-progress";
import { NotFound } from "@pages/not-found";
import { RouteFeatureFlag } from "./RouteFeatureFlag";
import { FEATURE_FLAGS } from "@utils/featureFlags";
import { ToastContextProvider } from "@context/ToastContext";
import { useHubSpotPageTracking } from "@hooks/useHubSpotTracking";
import { useUserContext } from "@context/UserContext";
import { HilightBanner } from "@components/general/hilight-page-banner/HilightBanner";

const userRole: "admin" | "user" = ExpoConstants.expoConfig!.extra!.userRole as
  | "admin"
  | "user";
console.assert(
  ["admin", "user"].includes(userRole),
  "Expected USER_ROLE to be set to user or admin"
);

const { Frame, /*ContextualSaveBar, Button*/ } = Platform.select({
  native: () => ({
    ContextualSaveBar: null,
    Frame: null,
    Button: null,
  }),
  default: () => ({
    ContextualSaveBar: require("@shopify/polaris").ContextualSaveBar,
    Frame: require("@shopify/polaris").Frame,
    Button: require("@shopify/polaris").Button,
  }),
})();

const storeData = async (value: string) => {
  const result = await AsyncStorage.setItem("@authTRNworld", value);

  return result;
};

const getData = async () => {
  const result = await AsyncStorage.getItem("@authTRNworld");

  if (result !== null) return result;
  else return "";
};

const NativeNavbar = ({isSignedIn, isVerified }: {isSignedIn: boolean, isVerified: boolean }) => useRoutes(
  navbarRoutes.map((path) => ({
    path,
    element: (
      <NavbarComponent
        userRole={userRole}
        isSignedIn={isSignedIn}
        isVerified={isVerified}
      />
    ),
  }))
);

const NativeMobileNavbar = () => useRoutes(
  navbarRoutes.slice(0, 4).map((path) => ({
    path,
    element: <MobileNavbar />,
  }))
);

const NavBarWrapper = ({isShowingBanner, children}:{isShowingBanner: boolean, children: ReactElement}) => {
  return <View style={
    {
      position: "fixed", 
      zIndex: 1,
      top: isShowingBanner ? 56 : 0,
    }
  }>
    { children }
  </View>
}

const RedirectToLogin = () => {
  const { pathname } = useLocation();
  const to = pathname ? `/login?redirect=${pathname}` : `/login`;
  return <Navigate to={to} replace />;
};

export const Index = () => {
  const { pathname } = useLocation();
  const navigation = useNavigate();
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [hasUpdated, setHasUpdated] = useState(0);
  const [isPopUp, setIsPopUp] = useState(false);
  const [atAuth, setAtAuth] = useState(true);
  const [currentUser, setCurrentUser] = useState<User | null>(null); // this is the Firebase Auth User object
  const [hasTriedAuth, setHasTriedAuth] = useState(false);

  const { currentUser: currentContextUser } = useUserContext(); // this is the Firestore User collection document object

  // hubspot page tracking
  useHubSpotPageTracking();

  // this effect shows a WelcomePopup to the user when they visit for the first time
  // however the `setAtAuth(false);` (on L170) is disabling it, probably because the WelcomePopup is not wired up to do anything
  useEffect(() => {
    // WelcomePopup
    if (isSignedIn && atAuth) {
      getData()
        .then((e) => {
          setIsPopUp(Platform.OS === "web" && !e);
          !e && storeData("true");
        })
        .catch(() => setIsPopUp(false));
    }
    setAtAuth(false);
  }, [atAuth, isSignedIn]);

  useEffect(() => {
    // Session Persistence
    return auth.onAuthStateChanged((user) => {
      if (user && user.emailVerified) {
        setIsSignedIn(true);
        setCurrentUser(user);
      } else if (user && !user.emailVerified) {
        setIsSignedIn(true);
        setCurrentUser(user);
        if (!pathname.includes("email-verified")) {
          navigation("/verify-email");
        }
      } else {
        setIsSignedIn(false);
      }
      setHasTriedAuth(true);
    });
  }, [navigation, pathname]);

  // useEffect(() => {
  //   if (
  //     hasTriedAuth &&
  //     !isSignedIn &&
  //     Platform.OS === "web" &&
  //     !redirectPaths.includes(pathname)
  //   ) {
  //     navigation(`/?redirect=${pathname}`);
  //   }
  // }, [hasTriedAuth]);

  const [fontsLoaded] = useFonts({
    OpenSans_400Regular,
    OpenSans_500Medium,
    OpenSans_600SemiBold,
    OpenSans_700Bold,
  });

  const isVerified = !!currentUser?.emailVerified;
  const isFreeMember = currentContextUser?.subscription?.plan?.toLowerCase() === 'free';

  const AuthStack = useCallback(() => {
    if (userRole === "user") {
      return (
        <View
          style={{
            ...(Platform.OS === "web" && { marginTop: isFreeMember ? 11 : -45 }),
            height: "100%",
          }}
        >
          <ToastContextProvider>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route
                path="/content"
                element={<IndexPageLayout variant={PageVariant.content} />}
              />

              <Route path="/content/:id" element={<ContentView />} />
              <Route path="/marketplace" element={<Marketplace />} />
              <Route
                path="/marketplace/:id"
                element={<MarketplaceIndividualView />}
              />
              <Route path="/verify-email" element={<VerifyEmail />} />

              <Route
                path="/events"
                element={<IndexPageLayout variant={PageVariant.event} />}
              />
              <Route
                path="/events/:id"
                element={Platform.OS === "web" ? <EventWeb /> : <EventNative />}
              />
              <Route path="/account" element={<Account withHelp isUserAccount />}>
                <Route path="/account/help" element={<Help />} />
                <Route path="/account/help/request" element={<HelpRequest />} />
                <Route
                  path="/account/help/contact-support"
                  element={<ContactSupport />}
                />
                <Route path="/account/settings" element={<Settings />} />
                <Route
                  path="/account/settings/subscription-plan"
                  element={<SubscriptionPlan />}
                />
                <Route path="/account/settings/payment" element={<Payment />} />
                <Route
                  path="/account/notification-settings"
                  element={<NotificationSettings />}
                />
                <Route
                  path="/account/notification-settings/content"
                  element={<ContentNotificationSettings />}
                />
                <Route
                  path="/account/notification-settings/events"
                  element={<EventsNotificationSettings />}
                />
                <Route
                  path="/account/notification-settings/training"
                  element={<TrainingNotificationSettings />}
                />
                <Route
                  path="/account/notification-settings/assignment"
                  element={<AssignmentNotificationSettings />}
                />
                <Route
                  path="/account/notification-settings/feed"
                  element={<FeedNotificationSettings />}
                />
                <Route
                  path="/account/notification-settings/saved-items"
                  element={<SavedItemsNotificationSettings />}
                />
                <Route
                  path="/account/all-notifications"
                  element={<AllNotifications />}
                />
                <Route
                  path="/account/manage-notifications"
                  element={<ManageNotifications />}
                />
                <Route path="/account/settings/profile" element={<Profile />} />
                <Route
                  path="/account/settings/profile/delete"
                  element={<DeleteAccount />}
                />
                <Route
                  path="/account/settings/profile/edit"
                  element={<EditProfile />}
                />
                <Route
                  path="/account/settings/profile/edit/personal-info"
                  element={<PersonalInfo />}
                />
                <Route path="/account/settings/job-role" element={<JobRole />} />
                <Route path="/account/settings/password" element={<Password />} />
              </Route>

              <Route path="/org/saved" element={<Saved />} />
              <Route path="/org/saved/:type" element={<SavedTypeNative />} />
              <Route path="/org/saved/list" element={<SavedListNative />} />
              <Route
                path="/org/assignment/list"
                element={<AssignmentListNative />}
              />
              <Route
                path="/org/assignment/native"
                element={<Assignment variant={PageVariant.assignment} />}
              />
              <Route
                path="/org"
                element={<Account isUserAccount={false} withHelp={false} />}
              >
                <Route path="/org/analytics" element={<AnalyticsIndex />} />
                <Route
                  path="/org/analytics/members"
                  element={<AnalyticsMembers />}
                />
                <Route
                  path="/org/analytics/trn-world"
                  element={<AnalyticsTrnWorld />}
                />
                <Route
                  path="/org/assignment"
                  element={<Assignment variant={PageVariant.assignment} />}
                />
                <Route path="/org/settings" element={<OrganizationSettings />} />
                <Route
                  path="/org/settings/contact-info"
                  element={<ContactInfo />}
                />
                <Route
                  path="/org/settings/business-info"
                  element={<BusinessInfo />}
                />
                <Route path="/org/members" element={<Members />} />
                <Route
                  path="/org/members/training-progress"
                  element={<TrainingProgress />}
                />
                <Route path="/org/members/all-members" element={<AllMembers />} />
                <Route path="/org/members/add-new" element={<AddNewMember />} />
                <Route path="/org/members/:id" element={<MemberView />} />
              </Route>
              <Route
                path="/training"
                element={<IndexPageLayout variant={PageVariant.training} />}
              />
              <Route path="/training/:id" element={<TrainingDetail />} />
              <Route
                path="/training/:id/lesson/:moduleId/:lessonId"
                element={<Lesson />}
              />
              <Route path="/search/results/:query" element={<SearchResults />} />
              <Route path="/search/suggestions" element={<SearchResults />} />
              <Route path="/email-verified" element={<EmailVerified />} />

              <Route path="/roadmap" element={<RouteFeatureFlag featureFlag={FEATURE_FLAGS.ROADMAP} component={<RoadmapIntro />} />}/>
              <Route path="/roadmap/north-star" element={<RouteFeatureFlag featureFlag={FEATURE_FLAGS.ROADMAP_NORTH_STAR} component={<NorthStarIndex />} />} />
              <Route path="/roadmap/goal" element={<RouteFeatureFlag featureFlag={FEATURE_FLAGS.ROADMAP_GOAL} component={<GoalIndex />} />} />
              <Route path="/roadmap/pulse" element={<RouteFeatureFlag featureFlag={FEATURE_FLAGS.ROADMAP_PULSE} component={<PulseIndex />} />} />
              <Route path="/roadmap/pulse/result" element={<RouteFeatureFlag featureFlag={FEATURE_FLAGS.ROADMAP_PULSE} component={<PulseResultPlan />} />} />
              <Route
                path="/roadmap/pulse/result/:sectionId"
                element={<RouteFeatureFlag featureFlag={FEATURE_FLAGS.ROADMAP_PULSE} component={<PulseResultPlan />} />}
              />
              <Route
                path="/roadmap/pulse/result/:categoryId/:subcategoryId/content"
                element={<RouteFeatureFlag featureFlag={FEATURE_FLAGS.ROADMAP_PULSE} component={<PulseContent />} />}
              />
              <Route
                path="/roadmap/priorities"
                element={<RouteFeatureFlag featureFlag={FEATURE_FLAGS.ROADMAP_PRIORITIES} component={<Priorities />} />}
              />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/login" element={<Navigate to="/" />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </ToastContextProvider>
          {Platform.OS !== "web" && <NativeMobileNavbar/>}
        </View>
      );
    } else if (userRole === "admin") return <BackOffice />;
  }, [isFreeMember]);

  const UnVerifiedStack = useMemo(
    () => <Routes>
      <Route path="/verify-email" element={<VerifyEmail />} />
      <Route path="/email-verified" element={<EmailVerified />} />
      <Route path="*" element={<NotFound />} />
    </Routes>, []
  );

  const UnAuthStack = useMemo(() => 
    <Routes>
      <Route path="/" element={<Welcome />} />
      <Route path="/login" element={<Login setIsSignedIn={setIsSignedIn} />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/signup/:stage" element={<SignUp />} />
      <Route path="/email-verified" element={<EmailVerified />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="*" element={<RedirectToLogin />} />
    </Routes>, []
  );

  const stack = useMemo(() => {
    if (isSignedIn) {
      if (!isVerified) {
        return UnVerifiedStack;
      }
      return AuthStack();
    }
    return UnAuthStack;
  }, [AuthStack, UnAuthStack, UnVerifiedStack, isSignedIn, isVerified]);

  // const handleDiscardAction = () => {
  //   setHasUpdated(0);
  //   navigation("/content");
  // };

  if (!fontsLoaded || !hasTriedAuth) {
    return (
      <View
        style={{
          height: Platform.OS === "web" ? "100vh" : "100%",
          width: Platform.OS === "web" ? "100vw" : "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ActivityIndicator size="large" color="#213470" />
      </View>
    );
  } else
    return (
      <>
        <Helmet>
          <title>{userRole === "user" ? "TRN" : "TRN Back Office"}</title>
        </Helmet>
        <SelectedUsersAssignModalContextProvider>
          <Frame>
            <Host>
              <View
                style={[
                  styles.popupBackGround,
                  {
                    ...(isPopUp &&
                      isSignedIn && {
                        overflow: "hidden",
                        zIndex: -1,
                      }),
                    },
                ]}
                >
                {/* Welcome Popup */}
                <View
                  style={[
                    styles.popupBackGround,
                    {
                      ...(isPopUp &&
                        isSignedIn &&
                        userRole === "user" && {
                          alignItems: "center",
                          backgroundColor: "rgba(112, 116, 121, 0.78)",
                          zIndex: 1,
                        }),
                      },
                  ]}
                  >
                  {isPopUp && isSignedIn && userRole === "user" ? (
                    <WelcomePopup
                    props={{
                      setIsPopUp,
                      isPopUp,
                      isSignedIn,
                    }}
                    />
                  ) : null}
                </View>
                <UpdateContext.Provider
                  value={{
                    state: hasUpdated,
                    update: setHasUpdated,
                  }}
                  >
                    {/* TODO - commented out as unclear why we have this */}
                    {/* {Platform.OS === "web" && !!hasUpdated && (
                      <div style={{ height: 0, overflow: "hidden" }}>
                        <Image
                          source={LogoWhite}
                          style={{
                            width: 165,
                            height: 27,
                            position: "absolute",
                            zIndex: 1000,
                            marginTop: 15,
                            marginLeft: 46,
                          }}
                        />
                        <Frame>
                          <ContextualSaveBar
                            fullWidth
                            message="Unsaved changes"
                            saveAction={{
                              onAction: () => setHasUpdated(2),
                              loading: false,
                              disabled: false,
                            }}
                            discardAction={{
                              onAction: handleDiscardAction,
                            }}
                          />
                        </Frame>
                        <Button
                          style={{
                            width: 64,
                            height: 36,
                            position: "absolute",
                            zIndex: 10000,
                          }}
                        >
                          Save
                        </Button>
                      </div>
                    )} */}
                    { 
                      isFreeMember && (
                        <HilightBanner>
                          <Text>You are exploring TRN World as a FREE member. <Text onPress={() => Linking.openURL('https://therecruitmentnetwork.com/membership/')} style={{textDecorationLine: 'underline'}}>Upgrade</Text> to a premium membership for exclusive content, insights, and access to our network of recruitment leaders.</Text>
                        </HilightBanner>
                      )
                    }
                    <NavBarWrapper isShowingBanner={isFreeMember}>
                      <>
                        {Platform.OS !== "web" && <NativeNavbar isSignedIn={isSignedIn} isVerified={isVerified} />}
                        {Platform.OS === "web" && (
                          <>
                            <NavbarComponent
                              userRole={userRole}
                              isSignedIn={isSignedIn}
                              isVerified={isVerified}
                            />
                            {isSignedIn && <MobileNavbar />}
                          </>
                        )}
                      </>
                    </NavBarWrapper>
                    {stack}
                </UpdateContext.Provider>
              </View>
            </Host>
          </Frame>
        </SelectedUsersAssignModalContextProvider>
      </>
    );
};
