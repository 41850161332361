import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    flexDirection: "row",
    paddingHorizontal: 40,
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#E1E3E5",
  },
  rowContainer: {
    flexDirection: "row",
    flexGrow: 2,
    flexShrink: 1,
    flexBasis: 310,
  },
  avatarContainer: {
    position: "relative",
    width: 40,
    height: 40,
    marginRight: 16,
  },
  avatar: {
    width: 40,
    height: 40,
    borderRadius: 50,
  },
  status: {
    position: "absolute",
    width: 12,
    height: 12,
    bottom: 0,
    right: 0,
    borderRadius: 50,
    borderWidth: 1,
    borderColor: "#fff",
  },
  displayNone: {
    display: "none",
  },
  online: {
    backgroundColor: "#AEE9D1",
  },
  away: {
    backgroundColor: "#FED3D1",
  },
  nameContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  textContainer: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 140,
    alignSelf: "center",
  },
  textContainerName: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 14,
    color: "#0D1738",
    lineHeight: 20,
  },
  textContainerInfo: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 14,
    color: "#0D1738",
    lineHeight: 20,
  },
  tagContainer: {
    backgroundColor: "#D3E5FE",
    borderRadius: 15,
    marginLeft: 8,
  },
  tagTitle: {
    fontSize: 12,
    paddingVertical: 2,
    paddingHorizontal: 8,
    fontFamily: "OpenSans_400Regular",
    lineHeight: 16,
    color: "#0D1738",
  },
  buttonContainer: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 30,
    alignSelf: "center",
  },
  button: {
    padding: 2,
    borderWidth: 1,
    borderColor: "#D2D5D8",
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
  },
  dotButton: {
    borderWidth: 1,
    borderColor: "#D2D5D8",
    borderRadius: 4,
    alignSelf: "center",
    paddingHorizontal: 6,
    paddingVertical: 6,
  },
});
