import React from "react";
import { View, ViewProps } from "react-native";
import { styles } from "./style";

interface FormSectionProps extends ViewProps {
  children: React.ReactNode;
}

const FormSection = ({ children, style, ...rest }: FormSectionProps) => (
  <View style={[styles.container, style]} {...rest}>
    {children}
  </View>
);

export { FormSection };
