import StyleSheet from "react-native-media-query";
import { mediaQuery } from "@utils/misc";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    paddingTop: 16,
    paddingBottom: 16,
    [mediaQuery.forMobile]: {
      paddingTop: 0,
      flexDirection: "column-reverse",
    },
  },
  wrapper: {
    paddingTop: 16,
    height: "100%",
    [mediaQuery.forMobile]: {
      paddingTop: 0,
    },
  },
  inner: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 40,
    paddingBottom: 20,
    paddingLeft: 80,
    paddingRight: 80,
    [mediaQuery.forTabletDown]: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 0,
      flexDirection: "column-reverse",
    },
    [mediaQuery.forTabletOnly]: {
      padding: 20,
    },
  },
  scrollContent: {
    paddingBottom: 150,
  },
  mobileInner: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  leftBox: {
    width: "59%",
    [mediaQuery.forTabletDown]: {
      width: "100%",
    },
  },
  rightBox: {
    width: "33.5%",
    [mediaQuery.forTabletDown]: {
      width: "100%",
      marginBottom: 20,
    },
  },
  enrollBottom: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
    backgroundColor: "#FFFFFF",
    padding: 12,
    paddingTop: 4,
    paddingBottom: 20,
  },
});
