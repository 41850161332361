import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  tag: {
    paddingVertical: 4,
    paddingHorizontal: 8,
    maxWidth: "fit-content",
    borderRadius: 12,
  },
});
