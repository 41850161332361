import { useCallback, useEffect, useMemo, useState } from "react";
import algoliaClient from "algoliasearch/lite";
import { debounce } from "lodash";
import { ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY } from "@env";
import {
  Content,
  Event,
  Training,
  useUpdateUserMutation,
} from "@gql/generated/generated";
import { useUserContext } from "@context/UserContext";
import { formatAsAlgoliaFilters, getIncludedSubscriptionLevels } from "./utils";

export type AlgoliaProps = {
  path: string;
  objectID: string;
};

const algolia = algoliaClient(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY);

const querySuggestionsIndex = algolia.initIndex(
  "all-data-index-query-suggestions"
);

const getQuerySuggestions = (query: any, hitsPerPage: number, params = {}) =>
  querySuggestionsIndex.search(query, {
    hitsPerPage,
    ...params,
  });

type THits =
  | (Event & AlgoliaProps)
  | (Training & AlgoliaProps)
  | (Content & AlgoliaProps);

type Props = {
  query?: string;
  hitsPerPage?: number;
  indexName?: string;
  filterResultsByUserSubscriptionPlan?: boolean;
};

const useAlgoliaSearch = ({
  query,
  hitsPerPage = 6,
  indexName = "all-data-index",
  filterResultsByUserSubscriptionPlan = true,
}: Props) => {
  const { currentUser: user } = useUserContext();
  const userSubscriptionLevel = user?.subscription?.plan;

  const [results, setResults] = useState<
    (
      | (Event & AlgoliaProps)
      | (Training & AlgoliaProps)
      | (Content & AlgoliaProps)
    )[]
  >([]);
  const [querySuggestions, setQuerySuggestions] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [updateUser] = useUpdateUserMutation();

  const updateRecentSearches = useCallback(
    (queryString: string) => {
      updateUser({
        variables: {
          input: {
            user: {
              recentSearch: queryString,
            },
          },
        },
      }).catch(console.error);
    },
    [updateUser]
  );

  const updateResults = useMemo(
    () =>
      debounce(async (query) => {
        if (filterResultsByUserSubscriptionPlan && !userSubscriptionLevel) {
          return;
        }

        try {
          setIsLoading(true);
          const index = algolia.initIndex(indexName);
          const { hits } = await index.search<THits>(query, {
            ...(filterResultsByUserSubscriptionPlan && {
              filters: formatAsAlgoliaFilters(
                getIncludedSubscriptionLevels(userSubscriptionLevel || "")
              ),
            }),
            hitsPerPage,
          });
          setResults(hits);
        } catch (err) {
          console.log({ err });
        } finally {
          updateRecentSearches(query);
          setIsLoading(false);
        }
      }, 350),
    [
      filterResultsByUserSubscriptionPlan,
      hitsPerPage,
      indexName,
      updateRecentSearches,
      userSubscriptionLevel,
    ]
  );

  useEffect(() => {
    if (query) {
      updateResults(query);
    }
  }, [query, updateResults]);

  useEffect(() => {
    getQuerySuggestions(query, hitsPerPage)
      .then(({ hits }: any) => {
        setQuerySuggestions(hits);
      })
      .catch(() => {
        // ignore
      });
  }, [hitsPerPage, query]);

  const eventsResults = results.filter((result) =>
    result.path?.includes("Events") || false
  ) as (Event & AlgoliaProps)[];
  const contentResults = results.filter((result) =>
    result.path?.includes("Content") || false
  ) as (Content & AlgoliaProps)[];
  const trainingsResults = results.filter((result) =>
    result.path?.includes("Training") || false
  ) as (Training & AlgoliaProps)[];

  return {
    results,
    eventsResults,
    contentResults,
    trainingsResults,
    querySuggestions,
    isLoading,
  };
};

export { useAlgoliaSearch };
