import React, { useState } from "react";
import { Text, View } from "react-native";
import { useNavigate } from "react-router-native";
import { getRoadmapProgressColor } from "@utils/misc";
import {
  ButtonGeneral,
  ButtonType,
  ButtonVariant,
} from "@components/general/button-general";
import { PulseScoreCircle } from "@components/roadmap/pulse-score-circle";
import { CompleteModal } from "@components/general/modals/complete-modal";
import { ids, styles } from "./style";

interface Props {
  sectionId: string;
  title: string;
  score: number;
  percent: number;
  contentPieces: number;
  trainingCourses: number;
  feedbackTitle?: string;
  feedbackDescription?: string;
  scoreColor: string;
  maxScore: number;
}

const PulseResultCategoryBlockPlan = ({
  sectionId,
  title,
  score,
  percent,
  contentPieces,
  trainingCourses,
  feedbackTitle,
  feedbackDescription,
  scoreColor,
  maxScore,
}: Props) => {
  const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(false);
  const navigate = useNavigate();

  const canHaveRecommendations =
    contentPieces !== undefined && trainingCourses !== undefined;

  return (
    <View
      style={[
        styles.wrap,
        { borderTopColor: getRoadmapProgressColor((score / 10) * 100).color },
      ]}
    >
      <View style={styles.topWrap}>
        <View style={styles.circularWrap} dataSet={{ media: ids.circularWrap }}>
          <PulseScoreCircle
            score={score}
            maxScore={maxScore}
            scoreColor={scoreColor}
          />
        </View>
        <View style={styles.contentWrap} dataSet={{ media: ids.contentWrap }}>
          <Text style={styles.percentageText}>{title}</Text>
          {/* <View style={styles.progressWrap}>
            <View style={[styles.progressInner, { width: `${percent}%` }]}>
              <Text style={styles.progressPercentText}>{percent}%</Text>
            </View>
          </View> */}

          <Text style={styles.doneText}>
            {canHaveRecommendations
              ? `${contentPieces} content pieces • ${trainingCourses} training courses`
              : " "}
          </Text>

          <View style={styles.btnWrap}>
            <View style={styles.eachButtonWrap}>
              {feedbackDescription ? (
                <ButtonGeneral
                  label="Feedback"
                  type={ButtonType.user}
                  variant={ButtonVariant.Secondary}
                  onPress={() => setIsFeedbackModalVisible(true)}
                />
              ) : null}
            </View>
            <ButtonGeneral
              label="Content"
              type={ButtonType.user}
              onPress={() => navigate(`${sectionId}/content`)}
            />
          </View>
        </View>
      </View>
      <CompleteModal
        isVisible={isFeedbackModalVisible}
        onClose={() => setIsFeedbackModalVisible(false)}
        title={feedbackTitle || ""}
        htmlString={feedbackDescription}
      />
    </View>
  );
};

export { PulseResultCategoryBlockPlan };
