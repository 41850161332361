import React, { useRef, useState } from "react";
import {
  ImageBackground,
  Linking,
  Platform,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { ContentBox } from "@components/general/layouts/content-box";
import {
  CheckIcon,
  CoursesIcon,
  FileIcon,
  LargePlayIcon,
  LockIcon,
} from "@components/general/icons/training-icons";
import { ButtonGeneral, ButtonType } from "@components/general/button-general";
import { ClockIcon } from "@components/general/icons/training-icons/ClockIcon";
import { SocialButtons } from "@components/general/icons/social-buttons/SocialButtons";
import { getModuleLessons, getShareURLs, pluralize } from "@utils/misc";
import { useLocation } from "react-router-native";
import { AVPlaybackStatusSuccess, ResizeMode, Video } from "expo-av";
import { ids, styles } from "./style";
import { useVideo } from "@hooks/useVideo";
import { GreenCircleCheckIcon } from "@components/general/icons/event-icons";
import { SmileyHappyMajor } from "@components/general/icons/SmileyHappyMajor";
import { Training } from "@gql/generated/generated";
import { useGetFile } from "@hooks/useGetFileUrl";
import { SaveMenu } from "@components/general/save-menu";
import { convertSecondsToDisplay } from "@utils/TIme";

interface IconItemProps {
  icon?: React.ReactNode;
  title: string;
}

type CurrentLesson = {
  moduleId: string;
  moduleNumber: number;
  lessonId: string;
  lessonNumber: number;
}

const IconItem = ({ icon, title }: IconItemProps) => (
  <View style={styles.iconItemWrap}>
    {icon}
    <Text style={styles.iconItemText}>{title}</Text>
  </View>
);

interface Props {
  training?: Training | null;
  currentLesson?: CurrentLesson | null; 
  currentLessonTitle: string;
  color?: string;
  onEnroll: () => void;
  enrollingUser: boolean;
}

const upgradeButtonClickHandler = () => Linking.openURL('https://therecruitmentnetwork.com/membership/');

const EnrollBox = ({
  training,
  currentLesson,
  currentLessonTitle,
  color = "#0D1738",
  onEnroll,
  enrollingUser,
}: Props) => {
  const { pathname } = useLocation();

  const video = useRef<Video>(null);
  const [status, setStatus] = useState<AVPlaybackStatusSuccess>();
  const { handleVideoAspectRatio, videoRatio } = useVideo();

  const { fileUrl } = useGetFile(training?.coverImage?.storagePath);
  const { fileUrl: promoVideo } = useGetFile(
    training?.promotionalVideo?.storagePath
  );

  const userHasAccessToCurrentLesson = training?.userLessonsAccess?.find(({id}) => currentLesson?.lessonId === id)?.hasAccess ?? false;
  const totalTrainingLessons = training?.modules?.reduce((curr, {lessons}) => curr + lessons.length, 0) ?? 0;
  const totalLessonsWithAccess = training?.userLessonsAccess?.filter(({hasAccess}) => !!hasAccess).length;
  const userHasAccessToAllLessons = totalTrainingLessons === totalLessonsWithAccess;
  const userHasAccess = userHasAccessToAllLessons || userHasAccessToCurrentLesson

  return (
    <View style={styles.wrap}>
      <ContentBox>
        <View style={styles.videoContainer}>
          {training?.promotionalVideo ? (
            <Video
              posterSource={{ uri: fileUrl }}
              posterStyle={{
                backgroundColor: "black",
              }}
              usePoster
              ref={video}
              videoStyle={styles.innerVideo}
              style={[
                styles.outerVideo,
                Platform.OS === "web" && styles.webVideo,
                Platform.OS !== "web" && { aspectRatio: videoRatio },
              ]}
              source={{
                uri: promoVideo || "",
              }}
              dataSet={{ media: ids.outerVideo }}
              useNativeControls={!!status?.isPlaying}
              resizeMode={ResizeMode.CONTAIN}
              onPlaybackStatusUpdate={(status) =>
                setStatus(() => status as AVPlaybackStatusSuccess)
              }
              onReadyForDisplay={handleVideoAspectRatio}
            />
          ) : (
            <ImageBackground
              source={{
                uri: fileUrl,
              }}
              style={styles.thumbnail}
              imageStyle={styles.thumbnail}
            />
          )}
          {training?.promotionalVideo && !status?.isPlaying ? (
            <TouchableOpacity
              style={styles.playButton}
              onPress={() => {
                if (status?.isPlaying) {
                  video.current?.pauseAsync();
                } else {
                  video.current?.playAsync();
                }
              }}
            >
              <LargePlayIcon />
            </TouchableOpacity>
          ) : null}
        </View>

        <View style={styles.infoSection}>
          <View style={styles.infoInner}>
            <View>
              <IconItem
                title={convertSecondsToDisplay(
                  training?.durationInSeconds || 0
                )}
                icon={<ClockIcon />}
              />
              <IconItem
                title={
                  training
                    ? `${
                        (training.modules?.length &&
                          getModuleLessons(training.modules)?.length)! > 1
                          ? `${
                              getModuleLessons(training!.modules)?.length
                            } Lessons`
                          : `${
                              getModuleLessons(training!.modules)?.length
                            } Lesson`
                      }`
                    : ""
                }
                icon={<FileIcon />}
              />
              <IconItem
                title={
                  training
                    ? `${
                        training.modules?.length && training.modules.length > 1
                          ? `${training.modules.length} Modules`
                          : `${training.modules?.length} Module`
                      }`
                    : ""
                }
                icon={<CoursesIcon />}
              />
              <IconItem title="Full Lifetime Access" icon={<CheckIcon />} />
            </View>
            {Platform.OS === "web" && training?.id ? (
              <SaveMenu
                id={training.id}
                type="Training"
                coverImage={fileUrl}
                title={training.title}
                additionalDetail={[
                  `${training.numRatings ?? 0} ${pluralize(
                    training.numRatings ?? 0,
                    "review"
                  )}`,
                  `${training.shareCount ?? 0} ${pluralize(
                    training.shareCount ?? 0,
                    "share"
                  )}`,
                ]}
                categories={training.categories ?? []}
              />
            ) : null}
          </View>
          {training?.isCurrentUserEnrolled &&
            (currentLessonTitle === "Start Again" ? (
              <View style={styles.courseCompletedBanner}>
                <SmileyHappyMajor />
                <Text style={styles.courseCompletedText}>
                  You&apos;ve completed the course
                </Text>
              </View>
            ) : (
              <View style={styles.courseEnrolledBanner}>
                <GreenCircleCheckIcon color="#2C6ECB" />
                <Text style={styles.courseEnrolledText}>
                  You&apos;re currently enrolled in this course
                </Text>
              </View>
            ))}
          <ButtonGeneral
            label={userHasAccess ? currentLessonTitle : `Upgrade to ${training?.isCurrentUserEnrolled ? 'continue' : 'enrol'}`}
            icon={!userHasAccess ? <LockIcon color="#ffffff"/> : undefined}
            type={ButtonType.user}
            onPress={userHasAccess ? onEnroll : upgradeButtonClickHandler}
            disabled={enrollingUser}
            isLoading={enrollingUser}
            style={{
              backgroundColor: color,
              borderColor: color,
            }}
          />
        </View>
      </ContentBox>
      <View style={styles.shareWrap}>
        <Text style={styles.shareText}>Share:</Text>
        <SocialButtons
          twitterLink={getShareURLs(pathname, true).twitter}
          facebookLink={getShareURLs(pathname, true).facebook}
          linkedInLink={getShareURLs(pathname, true).linkedin}
          id={training?.id}
          contentType="Trainings"
        />
      </View>
    </View>
  );
};

export { EnrollBox };
