import React, { useState } from "react";
import { Linking, Text, View } from "react-native";
import { useNavigate } from "react-router-native";
import { Formik } from "formik";
import { AuthError, createUserWithEmailAndPassword } from "firebase/auth";
import * as Yup from "yup";
import { APP_URL } from "@env";
import { Input } from "@components/general/form/input";
import { ButtonGeneral, ButtonType } from "@components/general/button-general";
import { AuthWrapper } from "@components/sign-up/auth-wrapper";
import { getErrorMessage } from "@utils/misc";
import { EN_COUNTRIES } from "@pages/back-office/analytics-back-office/world";
import { ErrorBox } from "@components/sign-up/error-box";
import { SelectOption } from "@components/general/form/select-option";
import { ids, styles } from "./style";
import { auth } from "../../../firebase";
import {
  AccountType,
  useCreateUserMutation,
  useGetJobTitlesQuery,
  useGetOrganisationByDomainQuery,
  useSendVerificationEmailMutation,
} from "@gql/generated/generated";
import { FormValues } from "../SignUp";

interface Props {
  prevValues: FormValues;
  onPressBack: () => void;
}

export interface MoreDetailsForm {
  companyName: string;
  jobPosition: string;
  country: string;
}

const MoreDetails = ({ prevValues, onPressBack }: Props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const countries = EN_COUNTRIES;

  const { data: jobTitlesData } = useGetJobTitlesQuery();

  const jobTitles = jobTitlesData?.getJobTitles?.jobTitles?.map((jobTitle) => ({
    label: jobTitle.name,
    value: jobTitle.name,
  }));

  const [createUser] = useCreateUserMutation();
  const [sendVerificationEmail] = useSendVerificationEmailMutation();
  const { data: organisationData } = useGetOrganisationByDomainQuery({
    variables: { email: prevValues.email || "" },
    skip: prevValues.accountType === AccountType.EMPLOYER,
  });

  const onSubmit = async (formikValues: MoreDetailsForm) => {
    setIsLoading(true);
    try {
      const response = await createUserWithEmailAndPassword(
        auth,
        prevValues.email,
        prevValues.password
      );

      const { accountType, email, phoneNumber, firstName, lastName } =
        prevValues;

      await createUser({
        variables: {
          input: {
            id: response.user.uid,
            accountType,
            email,
            phoneNumber,
            firstName,
            lastName,
            organisationId: organisationData?.getOrganisationByDomain?.id,
            ...formikValues,
          },
        },
      });

      // TODO Posthog tracking here for user creation

      setIsLoading(false);
      navigate("/verify-email");
    } catch (error) {
      const authError = error as AuthError;
      setErrorMsg(getErrorMessage(authError));

      setIsLoading(false);
    }
  };

  const handleTermsAndPrivacy = (type: "terms" | "privacy") => {
    Linking.openURL(`${APP_URL}/${type === "terms" ? "terms" : "privacy"}`);
  };

  return (
    <AuthWrapper
      title="Tell us more about your business"
      subtitle="Last step to go!"
      onPressBack={onPressBack}
    >
      <View>
        <Formik
          initialValues={{
            companyName:
              organisationData?.getOrganisationByDomain?.companyName || "",
            jobPosition: "",
            country: "",
          }}
          enableReinitialize
          onSubmit={onSubmit}
          validationSchema={Yup.object().shape({
            companyName: Yup.string().required("This field is required"),
            jobPosition: Yup.string().required("This field is required"),
            country: Yup.string().required("This field is required"),
          })}
        >
          {({
            values: val,
            handleChange,
            handleSubmit,
            touched,
            errors,
            setFieldValue,
          }) => (
            <View>
              {errorMsg !== "" ? <ErrorBox error={errorMsg} /> : null}
              <View style={styles.inputWrap}>
                <Input
                  placeholder="Company Name"
                  value={val.companyName}
                  editable={!organisationData?.getOrganisationByDomain?.id}
                  selectTextOnFocus={
                    !organisationData?.getOrganisationByDomain?.id
                  }
                  onChangeText={handleChange("companyName")}
                  error={
                    touched.companyName && errors.companyName
                      ? (errors.companyName as string)
                      : undefined
                  }
                />
              </View>
              <View style={styles.inputWrap}>
                <SelectOption
                  placeholder="Job Title"
                  options={jobTitles || []}
                  value={val.jobPosition}
                  onChange={(selected) =>
                    setFieldValue("jobPosition", selected)
                  }
                  error={
                    touched.jobPosition && errors.jobPosition
                      ? (errors.jobPosition as string)
                      : undefined
                  }
                />
              </View>
              <View style={styles.inputWrap}>
                <SelectOption
                  placeholder="Country"
                  options={countries || []}
                  value={val.country}
                  onChange={(selected) => setFieldValue("country", selected)}
                  error={
                    touched.country && errors.country
                      ? (errors.country as string)
                      : undefined
                  }
                />
              </View>
              <ButtonGeneral
                bold
                label="Sign Up"
                isLoading={isLoading}
                onPress={handleSubmit}
                type={ButtonType.user}
                style={styles.submitButton}
              />
            </View>
          )}
        </Formik>
        <View style={styles.register} dataSet={{ media: ids.register }}>
          <Text style={styles.noAccount}>
            By signing up, I agree to
            <Text
              style={styles.registerLink}
              onPress={() => handleTermsAndPrivacy("terms")}
            >
              &nbsp;Terms and conditions&nbsp;
            </Text>
            and
            <Text
              style={styles.registerLink}
              onPress={() => handleTermsAndPrivacy("privacy")}
            >
              &nbsp;Privacy Policy
            </Text>
          </Text>
        </View>
      </View>
    </AuthWrapper>
  );
};

export { MoreDetails };
