import * as React from "react";
import Svg, { Path } from "react-native-svg";

const LeftArrow = () => (
  <Svg width={8} height={12} fill="none">
    <Path
      fill="#5C5F62"
      d="M6.5 12a.997.997 0 0 1-.707-.293l-5-5a.999.999 0 0 1 0-1.414l5-5a.999.999 0 1 1 1.414 1.414L2.914 6l4.293 4.293A.999.999 0 0 1 6.5 12z"
    />
  </Svg>
);

export { LeftArrow };
