import {
  ActivityIndicator,
  Platform,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import React, { useContext } from "react";
import { Formik } from "formik";
import { useNavigate, useParams } from "react-router-native";
import { Curriculum } from "@components/back-office/editor/curriculum";
import { FormikInput } from "@components/back-office/editor/formik-input";
import { FormikTextArea } from "@components/back-office/editor/formik-text-area/FormikTextArea";
import { FormikRichText } from "@components/back-office/editor/formik-rich-text";
import { AddSpeakerOrInstructor } from "@components/back-office/editor/add-people";
import { FormikList } from "@components/back-office/editor/formik-list";
import { styles } from "./style";
import { FormikPublishTools } from "@components/back-office/editor/formik-publish-tools/FormikPublishTools";
import { FormikSaveBar } from "@components/back-office/editor/formik-save-bar";
import { customFirestoreId } from "@utils/misc";
import { FormSection } from "@components/back-office/editor/form-section";
import { Icon } from "@shopify/polaris";
import { ChevronLeftMinor } from "@shopify/polaris-icons";
import {
  CollectionName,
  Module,
  Status,
  UserType,
  useAdminGetAllTrainingsQuery,
  useGetTrainingQuery,
  useUpsertTrainingMutation,
} from "@gql/generated/generated";
import { UpdateContext } from "@utils/UpdateContext";
import { FileUploaderWithTitle } from "@components/back-office/editor/uploader";
import { useToast } from "@context/ToastContext";
import { validationSchema } from "./validation";
import { FormikHighlightBanner } from "@components/back-office/editor/formik-highlight-banner";

const getUserTypesFromStringArr = (userTypes: string[]) => {
  return userTypes.map((type) => {
    if (type === "All") return UserType.ALL;
    if (type === "Club") return UserType.CLUB;
    if (type === "Plus") return UserType.PLUS;
    else return UserType.FREE;
  });
};

const TrainingsEditor = () => {
  const { addToast } = useToast();

  const navigation = useNavigate();
  const UpdateStatus = useContext(UpdateContext);

  const { data: highlightData } = useAdminGetAllTrainingsQuery({
    variables: {
      page: 1,
      limit: 10,
      filters: {
        isHighlighted: true,
      },
    },
  });

  const { id: existingId } = useParams() as { id: string };

  const [upsertTraining] = useUpsertTrainingMutation();

  const { data, loading: loadingExistingTraining } = useGetTrainingQuery({
    variables: { id: existingId },
    skip: !existingId,
    fetchPolicy: "cache-and-network",
  });
  const training =
    data?.getTraining.__typename === "Training" ? data.getTraining : null;

  const trainingId = existingId || customFirestoreId();

  const hasVideoTypeWithoutVideoFile = (modules: Module[]) => {
    return modules.some((module) =>
      module.lessons.some((lesson) => {
        if (lesson?.lessonType?.includes("video")) {
          return !lesson.videoFile;
        }
      })
    );
  };

  const hasDocumentTypeWithoutDocumentFile = (modules: Module[]) =>
    modules.some((module) =>
      module.lessons.some(
        (lesson) =>
          lesson?.lessonType?.includes("document") && !lesson.documentFile
      )
    );

  const getEmbeddedVideoIssues = (modules: Module[]) => {
    const messages: string[] = [];
    modules.forEach((module, i) =>
      module.lessons.forEach((lesson, j) => {
        if (lesson.videoFile?.__typename === "EmbeddedVimeo") {
          if (!lesson.videoFile.duration) {
            messages.push(`Module ${i + 1}: Lesson ${j + 1} missing duration`);
          } else if (
            lesson.videoFile.duration < 0 ||
            !Number.isInteger(Number(lesson.videoFile.duration))
          ) {
            messages.push(
              `Module ${i + 1}: Lesson ${j + 1} has invalid duration`
            );
          } else if (
            !lesson.videoFile.content.includes("player.vimeo.com/api/player.js")
          ) {
            messages.push(
              `Module ${i + 1}: Lesson ${j + 1} is not valid vimeo embed code`
            );
          }
        }
      })
    );
    if (!messages.length) return null;
    return `Embedded videos have invalid durations: ${messages.join(", ")}`;
  };

  const checkForCorrectContent = (modules: Module[]) => {
    if (hasVideoTypeWithoutVideoFile(modules)) {
      throw new Error(
        "The selected Lesson Type option includes video, but there's no video file uploaded yet"
      );
    }
    const vimeoLessonsIssues = getEmbeddedVideoIssues(modules);
    if (vimeoLessonsIssues) {
      throw new Error(vimeoLessonsIssues);
    }
    if (hasDocumentTypeWithoutDocumentFile(modules)) {
      throw new Error(
        "The selected Lesson Type option includes document, but there's no document file uploaded yet"
      );
    }
  };

  if (loadingExistingTraining) {
    return (
      <View
        style={{
          height: Platform.OS === "web" ? "100vh" : "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ActivityIndicator size="large" color="#213470" />
      </View>
    );
  }

  return (
    <View
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Formik
        enableReinitialize={!!existingId}
        initialValues={{
          id: trainingId,
          title: training?.title ?? "",
          shortDescription: training?.shortDescription ?? "",
          learningObjectives: training?.learningObjectives ?? [],
          overview: training?.overview ?? "",
          requirements: training?.requirements ?? "",
          whoIsCourseFor: training?.whoIsCourseFor ?? "",
          status: training?.status ?? Status.DRAFT,
          coverImage: training?.coverImage ?? null,
          modules: training?.modules ?? [],
          categories: training?.categories ?? [],
          jobRoles: training?.jobRoles ?? [],
          userType: training?.userType ?? [UserType.FREE],
          instructors: training?.instructors ?? [],
          isHighlighted: training?.isHighlighted ?? false,
          highlightedRank: training?.highlightedRank ?? null,
        }}
        validationSchema={validationSchema}
        validateOnChange
        onSubmit={async (values) => {
          try {
            if (values.status === "Publish") {
              checkForCorrectContent(values.modules);
            }
            const { instructors, userType, coverImage, modules, ...rest } =
              values;

            const input = {
              ...rest,
              userType: getUserTypesFromStringArr(userType),
              instructors: instructors
                ? instructors.map((instructor) => instructor.id)
                : [],
              modules: modules.map((module) => {
                const { lessons, id, moduleDescription, moduleTitle } = module;
                return {
                  id,
                  moduleDescription,
                  moduleTitle,
                  lessons: lessons.map((lesson) => ({
                    id: lesson.id,
                    isPreviewLesson: lesson.isPreviewLesson,
                    lessonContent: lesson.lessonContent,
                    lessonDescription: lesson.lessonDescription,
                    lessonTitle: lesson.lessonTitle,
                    lessonType: lesson.lessonType,
                    ...(lesson.videoFile?.__typename === "EmbeddedVimeo"
                      ? {
                          videoFile: {
                            content: lesson.videoFile.content,
                            duration: Number(lesson.videoFile.duration),
                          },
                        }
                      : {}),
                    userType: lesson.userType,
                  })),
                };
              }),
            };

            await upsertTraining({
              variables: {
                input,
              },
            });

            if (existingId) {
              addToast("success", "Training successfully updated");
            } else {
              addToast("success", "Training successfully added");
            }
            navigation("/training");
          } catch (error: any) {
            addToast(
              "error",
              `Please ensure that you have completed all fields. ${
                error.message ? error.message : ""
              }`,
              5000
            );
          }
        }}
      >
        {({ values }) => {
          return (
            <>
              <FormikSaveBar />
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 12,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    UpdateStatus.update(0);
                    navigation(-1);
                  }}
                  style={{
                    borderWidth: 1,
                    borderColor: "#BABFC3",
                    borderRadius: 4,
                    width: 36,
                    height: 36,
                    marginRight: 8,
                  }}
                >
                  <Icon source={ChevronLeftMinor} />
                </TouchableOpacity>
                <Text style={styles.title}>
                  {existingId ? "Update" : "Add New"} Training
                </Text>
              </View>
              <View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <View
                    style={{
                      flex: 1,
                    }}
                  >
                    <FormSection>
                      <FormikInput
                        fieldName="title"
                        label="Title"
                        placeholder="Enter course name"
                      />
                      <View style={{ marginBottom: 16 }}>
                        <FormikTextArea
                          fieldName="shortDescription"
                          label="Short Description"
                          maxWordCount={300}
                          placeholder="Add a short description here"
                        />
                      </View>
                      <View style={{ marginBottom: 16 }}>
                        <FormikList
                          title="What will students learn on the course"
                          subtitle="You must enter at least 4 learning objectives or outcomes that learners can expect to achieve after completing your course."
                          fieldName="learningObjectives"
                          initialNumberOfItems={4}
                          placeholder="An objective or outcome"
                        />
                      </View>
                      <FormikRichText
                        fieldName="overview"
                        title="Overview"
                        placeholder="Write an overview of your course"
                      />
                      <FormikTextArea
                        fieldName="requirements"
                        title="Requirements"
                        label="List the required skills, experience, tools or equipment learners should have prior to taking your course. If there are no
                        requirements, use this space as an opportunity to lower the barrier for beginners."
                        placeholder="Explain the requirements of your course"
                      />
                      <FormikTextArea
                        fieldName="whoIsCourseFor"
                        title="Who is this course for?"
                        label="Write a clear description of the intended learners for your course who will find your course content valuable.   This will help
                        you attract the right learners to your course."
                        placeholder="Describe who this course is aimed at"
                      />
                    </FormSection>
                    <FormSection>
                      <Curriculum
                        modules={values?.modules || []}
                        trainingId={values.id}
                      />
                    </FormSection>
                    <FormSection>
                      <AddSpeakerOrInstructor
                        personType="Instructor"
                        formikFieldBase="instructors"
                      />
                    </FormSection>
                  </View>
                  <View style={{ marginLeft: 20 }}>
                    <FormikPublishTools />
                    <FileUploaderWithTitle
                      subtitle="This is the image that will be shown in the overview card"
                      title="Cover image"
                      initialAsset={training?.coverImage ?? null}
                      assetInstruction={{
                        instructionType: "one-to-one",
                        collectionId: CollectionName.TRAININGS,
                        documentId: trainingId,
                        key: "coverImage",
                      }}
                      formikFieldname="coverImage"
                      validFileTypes={["image/jpeg", "image/png"]}
                    />

                    <FormikHighlightBanner
                      highlights={
                        highlightData?.adminGetAllTrainings.trainings || []
                      }
                    />
                  </View>
                </View>
              </View>
            </>
          );
        }}
      </Formik>
    </View>
  );
};

export { TrainingsEditor };
