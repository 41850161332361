import React from "react";
import { ActivityIndicator, Platform, View } from "react-native";
import { Wrapper } from "@components/general/layouts/wrapper/Wrapper";
import { ContentBox } from "@components/general/layouts/content-box";
import { TrainingDetailHead } from "@components/training/training-detail-head";
import { TabTitle, TrainingTab } from "@components/training/training-tab";
import { Overview } from "@components/training/overview";
import { Curriculum } from "@components/training/curriculum";
import { Instructor } from "@components/training/instructor";
import { RelatedCourses } from "@components/training/related-courses";
import { EnrollBox } from "@components/training/enroll-box";
import { useTrainingDetail } from "./useTrainingDetail";
import { CommentsSection } from "@components/general/comments-section/CommentsSection";
import { ids, styles } from "./style";
import { useAddPageInfoToSession } from "@hooks/useSessionStorage";
import { CollectionName, ContentType } from "@gql/generated/generated";
import { Frame } from "@shopify/polaris";

interface ContentWrapProps {
  children: React.ReactNode;
  id: string;
}

const ContentWrap = ({ children, id }: ContentWrapProps) => {
  if (Platform.OS === "web") {
    return <div id={id}>{children}</div>;
  } else {
    return <>{children}</>;
  }
};

const TrainingDetail = () => {
  const {
    id,
    training,
    loading,
    relatedTrainings,
    handlePressEnroll,
    enrollingUser,
    currentLesson,
    currentLessonTitle,
    userProgressForTraining,
  } = useTrainingDetail();

  useAddPageInfoToSession({
    title: training?.title,
    type: "training",
    categories: training?.categories,
  });

  return (
    <Frame>
      <Wrapper style={styles.wrap}>
        {loading ? (
          <View
            style={{
              height: "100%",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ActivityIndicator size="large" color="#213470" />
          </View>
        ) : (
          <ContentBox style={styles.inner} dataSetMedia={ids.inner}>
            <>
              <View style={styles.leftBox} dataSet={{ media: ids.leftBox }}>
                <TrainingDetailHead {...training} />
                <TrainingTab />
                <ContentWrap id={TabTitle.overview}>
                  <Overview {...training} />
                </ContentWrap>
                <ContentWrap id={TabTitle.curriculum}>
                  <Curriculum
                    training={training}
                    completedLessons={
                      userProgressForTraining?.completedLessons ?? []
                    }
                  />
                </ContentWrap>
                <ContentWrap id={TabTitle.instructor}>
                  {training?.instructors?.map((instructor) => {
                    return (
                      <Instructor
                        instructor={instructor}
                        trainingId={training.id}
                        key={training.id}
                      />
                    );
                  })}
                </ContentWrap>
                <ContentWrap id={TabTitle.reviews}>
                  {id ? (
                    <View style={{ marginTop: 40 }}>
                      <CommentsSection
                        commentedItemId={id}
                        commentsData={training?.comments}
                        commentedItemType={ContentType.TRAINING}
                        collectionName={CollectionName.TRAININGS}
                        averageStars={training?.averageStars ?? 0}
                        reviewCount={training?.numRatings ?? 0}
                        userHasRated={!!training?.userHasCommented}
                        totalComments={training?.commentCount}
                      />
                    </View>
                  ) : null}
                </ContentWrap>

                <ContentWrap id={TabTitle.relatedCourses}>
                  <RelatedCourses trainings={relatedTrainings ?? []} />
                </ContentWrap>
              </View>
              <View style={styles.rightBox} dataSet={{ media: ids.rightBox }}>
                <EnrollBox
                  training={training}
                  currentLesson={currentLesson}
                  currentLessonTitle={currentLessonTitle.message}
                  color={currentLessonTitle.color}
                  onEnroll={handlePressEnroll}
                  enrollingUser={enrollingUser}
                />
              </View>
            </>
          </ContentBox>
        )}
      </Wrapper>
    </Frame>
  );
};

export { TrainingDetail };
