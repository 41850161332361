import React from "react";
import { Text, TouchableOpacity, View } from "react-native";

import { styles } from "./style";

export enum Size {
  Small,
  Large,
}

export enum CirclePosition {
  Left,
  Right,
}

export enum Color {
  Blue,
  Grey,
  LightBlue,
}

interface RadioButtonProps {
  selected: boolean;
  label: string;
  onPress: () => void;
  size?: Size;
  circlePosition?: CirclePosition;
  color?: Color;
  isFullWidth?: boolean;
}

interface CircleProps {
  selected: boolean;
  size?: Size;
  color?: Color;
  isFullWidth?: boolean;
}

const Circle = ({ selected, size, color, isFullWidth }: CircleProps) => (
  <View
    style={[
      styles.circleContainer,
      size === Size.Large && styles.largeCircleContainer,
      color === Color.Blue && styles.blueBorder,
      color === Color.LightBlue && styles.lightBlueBorder,
      isFullWidth && styles.noMargins,
    ]}
  >
    {selected ? (
      <View
        style={[
          styles.circle,
          size === Size.Large && styles.largeCircle,
          color === Color.Blue && styles.blueBackground,
          color === Color.LightBlue && styles.lightBlueBackground,
        ]}
      />
    ) : null}
  </View>
);

const Label = ({ text }: { text: string }) => (
  <Text style={styles.labelText}>{text}</Text>
);

const RadioButton = ({
  label,
  onPress,
  selected,
  size = Size.Small,
  circlePosition = CirclePosition.Left,
  color = Color.Grey,
  isFullWidth,
}: RadioButtonProps) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={[styles.container, isFullWidth && styles.fullWidthContainer]}
    >
      {circlePosition === CirclePosition.Left ? (
        <Circle
          selected={selected}
          size={size}
          color={color}
          isFullWidth={isFullWidth}
        />
      ) : (
        <Label text={label} />
      )}
      {circlePosition === CirclePosition.Left ? (
        <Label text={label} />
      ) : (
        <Circle
          selected={selected}
          size={size}
          color={color}
          isFullWidth={isFullWidth}
        />
      )}
    </TouchableOpacity>
  );
};

export { RadioButton };
