import { ActivityIndicator, View } from "react-native";

export const Loader = () => (
  <View
    style={{
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    }}
  >
    <ActivityIndicator size="large" color="#213470" />
  </View>
);
