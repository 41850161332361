import { useState } from "react";
import { useNavigate, useParams } from "react-router-native";
import { Text, View } from "react-native";
import { PulseContentWrapper } from "@components/roadmap/pulse-content-wrapper";
import { ContentBox } from "@components/general/layouts/content-box";
import { TabTitle } from "@components/general/tab-title";
import { PulseContentItem } from "@components/roadmap/pulse-content-item";
import { useUserContext } from "@context/UserContext";
import { truncateString } from "@utils/misc";
import { styles } from "./style";
import { getCurrentRoadmapItemsFromData } from "../../utils";
import { StarIcon } from "@components/general/icons/feed-icons";
import {
  getRecommendationsPercentageComplete,
  isActionedRecommendationComplete,
} from "@utils/getRecommendationsPercentageComplete";
import { isNotNull } from "@utils/isNotNull";
import {
  RecommendedContentOrTraining,
  useEmployerGetRoadmapQuery,
  useGetRecommendationDetailsQuery,
  useUpdateRoadmapMutation,
} from "@gql/generated/generated";
import { SubcategoryDropdown } from "@components/roadmap/pulse-content-wrapper/SubcategoryDropdown";
import { Loader } from "./Loader";

const PulseContent = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(1);

  type Params = {
    categoryId: string;
    subcategoryId: string;
  };

  const { categoryId, subcategoryId } = useParams() as Params;

  const { currentUser, loadingCurrentUser } = useUserContext();
  const orgId = currentUser?.accountInfo?.companyInfo?.id;

  const { data: employerGetRoadmapData, loading: loadingRoadmap } =
    useEmployerGetRoadmapQuery({
      skip: !orgId,
    });

  const [updateRoadmap] = useUpdateRoadmapMutation();

  const { currentPulse } =
    (employerGetRoadmapData?.employerGetRoadmap &&
      getCurrentRoadmapItemsFromData(
        employerGetRoadmapData?.employerGetRoadmap
      )) ||
    {};

  const categoryData = currentPulse?.sections.find(
    (section) => section.id === categoryId
  );

  const subcategoryData = categoryData?.questions.find(
    (question) => question.id === subcategoryId
  );

  const score = subcategoryData?.answer.currentScore || 1;

  const {
    data: recommendationDetailsData,
    loading: loadingRecommendationDetails,
  } = useGetRecommendationDetailsQuery({
    variables: { sectionId: `${categoryId}/${subcategoryId}/${score}` },
    skip: !score,
  });

  const recommendationsForScore: RecommendedContentOrTraining[] =
    recommendationDetailsData?.getRecommendationDetails.recommendations || [];

  const [trainingRecommendations, contentRecommendations] = [
    recommendationsForScore?.filter((el) => el?.type === "trainings"),
    recommendationsForScore?.filter((el) => el?.type === "content"),
  ];

  const feedbackForScore =
    recommendationDetailsData?.getRecommendationDetails?.feedback?.[0];

  const recommendations =
    activeTab === 1 ? contentRecommendations : trainingRecommendations;

  const actionedRecommendations = currentPulse?.actionedRecommendations;

  const percentageComplete = categoryId
    ? getRecommendationsPercentageComplete(
        categoryId,
        recommendationsForScore?.filter(isNotNull) ?? [],
        actionedRecommendations ?? []
      )
    : 0;

  const buildSubcategoryDropdown = () => {
    const onChange = (subcategoryId: string) => {
      navigate(`/roadmap/pulse/result/${categoryId}/${subcategoryId}/content`, {
        replace: true,
      });
    };

    return (
      <SubcategoryDropdown {...{ categoryData, subcategoryId, onChange }} />
    );
  };

  if (loadingCurrentUser || loadingRoadmap || loadingRecommendationDetails) {
    return <Loader />;
  }

  const handlePressComplete = async (
    itemId: string,
    type: string,
    isComplete: boolean
  ) => {
    await updateRoadmap({
      variables: {
        input: {
          actionedRecommendation: {
            id: itemId,
            status: isComplete ? "Todo" : "Complete",
            sectionId: categoryId || "",
            scoreColor: "incomplete",
            type,
          },
        },
      },
    });
  };

  return (
    <PulseContentWrapper
      title={categoryData?.name || ""}
      percent={percentageComplete}
      feedbackText={feedbackForScore?.text}
      feedbackTitle={feedbackForScore?.title}
      subcategoryDropdown={buildSubcategoryDropdown()}
    >
      <ContentBox style={styles.contentBox}>
        <View style={styles.tabHead}>
          <View style={styles.tabInner}>
            <TabTitle
              title="Content"
              active={activeTab === 1}
              onPress={() => setActiveTab(1)}
            />
            <TabTitle
              title="Training"
              active={activeTab === 2}
              onPress={() => setActiveTab(2)}
            />
          </View>
        </View>
        {recommendations
          ? recommendations?.map((recommendation: any, index: number) => {
              const {
                itemId,
                title,
                description,
                coverImage,
                type,
                shareCount,
                averageStars,
                commentCount,
              } = recommendation;
              const formattedContentType =
                type === "content" ? "Content" : "Training";

              const isComplete = categoryId
                ? isActionedRecommendationComplete(
                    categoryId,
                    recommendation,
                    currentPulse?.actionedRecommendations ?? []
                  )
                : false;

              return (
                <PulseContentItem
                  key={`${type}-${index}`}
                  title={title}
                  description={truncateString(description, 60)}
                  image={coverImage}
                  type={formattedContentType}
                  isComplete={isComplete}
                  subtitle={
                    <Text
                      style={{
                        color: "#6D7175",
                        fontSize: 12,
                        fontFamily: "OpenSans_400Regular",
                      }}
                    >
                      {formattedContentType} • {averageStars || 0} <StarIcon />{" "}
                      ({commentCount || 0} reviews) •{" "}
                      {shareCount ? shareCount : 0} shares
                    </Text>
                  }
                  id={itemId}
                  onPressComplete={() =>
                    handlePressComplete(itemId, type, isComplete)
                  }
                />
              );
            })
          : null}
      </ContentBox>
    </PulseContentWrapper>
  );
};

export { PulseContent };
