import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    backgroundColor: "#FFFFFF",
    borderRadius: 8,
    width: "100%",
  },
  shadow: {
    shadowColor: "rgba(0, 0, 0, 0.15)",
    shadowRadius: 4,
    shadowOffset: { height: 0.5, width: 0 },
  },
});
