import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    height: "100%",
  },
  infoWrap: {
    padding: 20,
  },
  infoTitle: {
    fontSize: 16,
    color: "#0D1738",
    fontFamily: "OpenSans_600SemiBold",
    marginBottom: 10,
  },
  subtitle: {
    color: "#6D7175",
    fontFamily: "OpenSans_400Regular",
    fontSize: 16,
    marginBottom: 18,
  },
  buttonWrap: {
    position: "absolute",
    bottom: 40,
    left: 12,
    right: 12,
  },
});
