import * as React from "react";
import { Alert, Linking, TouchableOpacity, View } from "react-native";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  TwitterIcon,
} from "../social-icons";
import { styles } from "./style";
import { useIncrementShareCountMutation } from "@gql/generated/generated";

interface SocialButtonsProps {
  twitterLink?: string | null;
  facebookLink?: string | null;
  linkedInLink?: string | null;
  instagramLink?: string | null;
  id?: string | null;
  contentType?: string;
}

interface SocialButtonProps {
  children: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  link: string;
  id?: string | null;
  contentType?: string;
}

const SocialButton = ({
  children,
  link,
  id,
  contentType,
}: SocialButtonProps) => {
  const [incrementShareCount] = useIncrementShareCountMutation();

  const [isHovered, setIsHovered] = React.useState(false);

  const handlePress = React.useCallback(async (url: string) => {
    // Checking if the link is supported for links with custom URL scheme.
    const supported = await Linking.canOpenURL(url);

    if (supported) {
      if (id && contentType) {
        incrementShareCount({
          variables: { id, contentType },
        });
      }

      // Opening the link with some app, if the URL scheme is "http" the web link should be opened
      // by some browser in the mobile
      await Linking.openURL(url);
    } else {
      Alert.alert(`Don't know how to open this URL: ${url}`);
    }
  }, []);

  return (
    <TouchableOpacity
      onPress={() => handlePress(link)}
      style={styles.button}
      // Hover states only applicable to web so we can ignore ts error below
      // @ts-ignore
      onMouseEnter={() => setIsHovered(true)}
      // @ts-ignore
      onMouseLeave={() => setIsHovered(false)}
    >
      {React.cloneElement(children, {
        color: isHovered ? "#3c5fd1" : "#0D1738",
      })}
    </TouchableOpacity>
  );
};

const SocialButtons = ({
  twitterLink,
  facebookLink,
  linkedInLink,
  instagramLink,
  id,
  contentType,
}: SocialButtonsProps) => {
  return (
    <View style={styles.container}>
      {facebookLink ? (
        <SocialButton link={facebookLink} contentType={contentType} id={id}>
          <FacebookIcon />
        </SocialButton>
      ) : null}
      {instagramLink ? (
        <SocialButton link={instagramLink} contentType={contentType} id={id}>
          <InstagramIcon />
        </SocialButton>
      ) : null}
      {twitterLink ? (
        <SocialButton link={twitterLink} contentType={contentType} id={id}>
          <TwitterIcon />
        </SocialButton>
      ) : null}
      {linkedInLink ? (
        <SocialButton link={linkedInLink} contentType={contentType} id={id}>
          <LinkedInIcon />
        </SocialButton>
      ) : null}
    </View>
  );
};

export { SocialButtons };
