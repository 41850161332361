import { AssetType } from "@gql/generated/generated";
import { Thumbnail } from "@shopify/polaris";
import { MutableRefObject, useMemo, useRef } from "react";
import { NoteMinor } from "@shopify/polaris-icons";

export const CurrentAssetThumbnail = ({
  assetType,
  url,
  id,
  videoRef,
  handleLoadedMetadata,
  assetName,
}: {
  assetType: AssetType;
  url: string;
  id: string;
  videoRef?: MutableRefObject<HTMLVideoElement | null>;
  handleLoadedMetadata?: () => void;
  assetName: string;
}) => {
  const hasLoadedRef = useRef(false);
  const thumbnail = useMemo(() => {
    switch (assetType) {
      case AssetType.IMAGE:
        return <Thumbnail source={url} alt={assetName} size="large" />;
      case AssetType.VIDEO:
        return (
          <video
            src={url}
            controls
            // Height of dropzone
            style={{ height: "7.5rem" }}
            key={id}
            ref={videoRef}
            onLoadedMetadata={() => {
              if (handleLoadedMetadata && hasLoadedRef.current === false) {
                handleLoadedMetadata();
                hasLoadedRef.current = true;
              }
            }}
          />
        );
      default:
        return (
          <Thumbnail source={NoteMinor} size="large" alt="Small document" />
        );
    }
  }, [assetName, assetType, handleLoadedMetadata, id, url, videoRef]);

  return (
    <a href={url} target="_blank">
      <div
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          display: "flex",
        }}
      >
        {thumbnail}
      </div>
    </a>
  );
};
