import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { PointerArrowLeft } from "@components/general/icons/account-icons/PointerArrowLeft";
import { ids, styles } from "./style";
import { useNavigate } from "react-router-native";
import { ExcelSheetDownload } from "./ExcelSheetDownload";
import { Sheet } from "@components/org/analytics/analytics-header/ExcelSheetDownloadTypes";

interface Props {
  title: string;
  excelData: Sheet[];
}

const AnalyticsHeader = ({ title, excelData }: Props) => {
  const navigate = useNavigate();

  return (
    <View style={styles.wrap} dataSet={{ media: ids.wrap }}>
      <TouchableOpacity
        style={styles.backTouch}
        onPress={() => navigate("/org/analytics")}
      >
        <PointerArrowLeft />
        <Text style={styles.backText}>Back to Analytics</Text>
      </TouchableOpacity>
      <View style={styles.titleWrap}>
        <Text style={styles.title}>{title}</Text>
        {/* Hidden button triggered by general button */}
        <ExcelSheetDownload sheets={excelData} />
      </View>
    </View>
  );
};

export { AnalyticsHeader };
