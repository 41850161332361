import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    paddingLeft: 24,
    paddingRight: 24,
    height: 80,
    paddingBottom: 21,
    zIndex: 999,
  },
  navItem: {
    height: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    minWidth: 55,
  },
  borderView: {
    height: 3,
    width: "100%",
    backgroundColor: "transparent",
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
  },
  activeBorder: {
    backgroundColor: "#0D1738",
  },
  navItemText: {
    fontSize: 14,
    color: "#5C5F62",
    fontFamily: "OpenSans_600SemiBold",
  },
  navItemTextActive: {
    color: "#0D1738",
  },
});
