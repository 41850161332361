import React, { useState } from "react";
import { useLocation } from "react-router-native";
import { IContent, IEvent, ITraining, PageVariant } from "@utils/models";
import { OrgMobileLayout } from "@components/org/org-mobile-layout";
import { ItemType } from "@components/org/org-modal-layout";
import {
  AssignmentsItemDetails,
  useGetAllEventsQuery,
} from "@gql/generated/generated";

type ILocationState = {
  variant: PageVariant;
};

const SavedListNative = () => {
  const { state } = useLocation();
  const { variant = PageVariant.saved } = (state as ILocationState) || {};
  const [isSuccessVisible, setIsSuccessVisible] = useState(false);
  const [successInfo, setSuccessInfo] = useState({
    title: "",
    message: "",
  });
  const [selectedItem, setSelectedItem] = useState<{
    item?: IContent | ITraining | IEvent;
    type?: ItemType;
  }>();

  const { data: eventData } = useGetAllEventsQuery({
    variables: {
      page: 1,
      limit: 9,
    },
  });
  const events = eventData?.getAllEvents?.events;

  const getItemTypes = () => {
    switch (variant) {
      case PageVariant.content:
        return [
          ItemType.view,
          ItemType.markAsDone,
          ItemType.moveToOrganization,
          ItemType.removeFromSavedItems,
        ];
      case PageVariant.event:
        return [
          ItemType.view,
          ItemType.deregister,
          ItemType.markAsDone,
          ItemType.moveToOrganization,
          ItemType.removeFromSavedItems,
        ];
      case PageVariant.training:
        return [
          ItemType.start,
          ItemType.markAsDone,
          ItemType.moveToOrganization,
          ItemType.removeFromSavedItems,
        ];
      default:
        return [];
    }
  };

  const handleItemPress = (item: AssignmentsItemDetails, type: ItemType) => {
    // @ts-ignore FIXME: bad type
    setSelectedItem({ item, type });
    setIsSuccessVisible(true);
  };

  const handleUndoSuccess = () => {
    if (selectedItem?.item && selectedItem?.type) {
      // TODO: Undo action
    }
  };

  return (
    <OrgMobileLayout
      title={variant}
      pageVariant={variant}
      itemTypes={getItemTypes()}
      onPressItem={handleItemPress}
      onUndoSuccess={handleUndoSuccess}
      isUndoSuccessVisible
      isSuccessVisible={isSuccessVisible}
      trainings={[]}
      events={events}
      contents={[]}
      successInfo={successInfo}
    />
  );
};

export { SavedListNative };
