import StyleSheet from "react-native-media-query";
import { mediaQuery } from "@utils/misc";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: "100%",
    borderBottomColor: "#EDEEEF",
    borderBottomWidth: 1,
    flexDirection: "row",
    [mediaQuery.forMobile]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
});
