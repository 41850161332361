import StyleSheet from "react-native-media-query";
import { Platform } from "react-native";
import { mediaQuery } from "@utils/misc";

export const { ids, styles } = StyleSheet.create({
  titleWrap: {
    padding: 20,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 0,
  },
  title: {
    color: "#0D1738",
    fontSize: 20,
    fontFamily: "OpenSans_600SemiBold",
  },
  selectWrap: {
    width: 160,
  },
  tabWrap: {
    paddingLeft: 40,
    borderBottomWidth: 1,
    borderBottomColor: "#EDEEEF",
    flexDirection: "row",
    alignItems: "center",
    [mediaQuery.forMobile]: {
      paddingLeft: 20,
    },
  },
  tableWrap: {
    overflow: "scroll",
    marginTop: 20,
  },
  tableInner: {
    minWidth: 800,
  },
  cellWrap: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: 8,
    paddingTop: 8,
    paddingLeft: 40,
    paddingRight: 40,
  },
  cellContentWrap: {
    borderTopWidth: 1,
    borderTopColor: "#EDEEEF",
  },
  rowOne: {
    width: "5%",
  },
  rowTwo: {
    width: "25%",
  },
  rowThree: {
    width: "12.5%",
  },
  rowFour: {
    width: "12.5%",
  },
  rowFive: {
    width: "15%",
  },
  rowSix: {
    width: "30%",
    paddingLeft: "5%",
  },
  boldText: {
    fontSize: 14,
    color: "#0D1738",
    fontFamily: "OpenSans_600SemiBold",
  },
  regularText: {
    fontSize: 14,
    color: "#0D1738",
    fontFamily: "OpenSans_400Regular",
  },
  tableTitleText: {
    fontSize: 12,
    color: "#6D7175",
    fontFamily: "OpenSans_600SemiBold",
  },
  imagesWrap: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    marginTop: 8,
    ...(Platform.OS === "web" && { gap: 8 }),
  },
  image: {
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  countWrap: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#EDEEEF",
  },
  countText: {
    color: "#6D7175",
    fontSize: 14,
    fontFamily: "OpenSans_600SemiBold",
  },
  tabItemWrap: {
    flexDirection: "row",
  },
});
