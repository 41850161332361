import React, { useState } from "react";
import { FlatList } from "react-native";
import { FilterWrapper } from "@components/filter-mobile/filter-wrapper";
import { FilterCheckbox } from "@components/filter-mobile/filter-checkbox";

export enum EventTypeOptions {
  inPerson = "In-Person",
  virtual = "Virtual",
  hybrid = "Hybrid",
}

const eventTypeOptions = [
  EventTypeOptions.inPerson,
  EventTypeOptions.virtual,
  EventTypeOptions.hybrid,
];

interface Props {
  isVisible: boolean;
  onClose: () => void;
  onSave: (eventTypes: EventTypeOptions[]) => void;
}

const EventType = ({ isVisible, onClose, onSave }: Props) => {
  const [eventTypes, setEventTypes] = useState<EventTypeOptions[]>([]);

  const handleSelect = (eventType: EventTypeOptions) => {
    if (eventTypes.includes(eventType)) {
      const index = eventTypes.indexOf(eventType);
      const eventTypesCopy: EventTypeOptions[] = [...eventTypes];
      eventTypesCopy.splice(index, 1);
      setEventTypes(eventTypesCopy);
    } else {
      setEventTypes([...eventTypes, eventType]);
    }
  };

  const renderItem = ({ item }: { item: EventTypeOptions }) => (
    <FilterCheckbox
      title={item}
      isSelected={eventTypes.includes(item)}
      onToggle={() => handleSelect(item)}
    />
  );

  return (
    <FilterWrapper
      headerTitle="Event Type"
      isVisible={isVisible}
      onClose={onClose}
      buttonTitle="Save"
      isDoneButtonDisabled={!eventTypes.length}
      onDone={() => eventTypes.length && onSave(eventTypes)}
    >
      <FlatList
        data={eventTypeOptions}
        renderItem={renderItem}
        keyExtractor={(item) => item}
      />
    </FilterWrapper>
  );
};

export { EventType };
