import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

interface Props extends SvgProps {
  color?: string;
}

const MarketplaceIcon = (props: Props) => (
  <Svg width={18} height={19} fill="none" {...props}>
    <Path
      d="M.775 1.478.178 5.06a1 1 0 0 0 .986 1.165h.893a1.5 1.5 0 0 0 1.341-.83l.586-1.17.585 1.17a1.5 1.5 0 0 0 1.342.83h1.146a1.5 1.5 0 0 0 1.341-.83l.586-1.17.585 1.17a1.5 1.5 0 0 0 1.342.83h1.146a1.5 1.5 0 0 0 1.341-.83l.586-1.17.585 1.17a1.5 1.5 0 0 0 1.342.83h.892a1 1 0 0 0 .987-1.165l-.597-3.582a1.5 1.5 0 0 0-1.48-1.253H2.255a1.5 1.5 0 0 0-1.48 1.253ZM16.984 8.225h-16v8.5a1.5 1.5 0 0 0 1.5 1.5h3.5v-7h6v7h3.5a1.5 1.5 0 0 0 1.5-1.5v-8.5Z"
      fill={props.color || "#5C5F62"}
    />
  </Svg>
);

export { MarketplaceIcon };
