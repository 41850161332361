import React, { MutableRefObject, useState } from "react";
import { Platform, ScrollView, View } from "react-native";
import { useLocation } from "react-router-native";
import { Wrapper } from "@components/general/layouts/wrapper/Wrapper";
import { ContentBox } from "@components/general/layouts/content-box";
import { TrainingDetailHead } from "@components/training/training-detail-head";
import {
  TabContentPosition,
  TabTitle,
  TrainingTab,
} from "@components/training/training-tab";
import { Overview } from "@components/training/overview";
import { Curriculum } from "@components/training/curriculum";
import { Instructor } from "@components/training/instructor";
import { RelatedCourses } from "@components/training/related-courses";
import { EnrollBox } from "@components/training/enroll-box";
import { ButtonGeneral } from "@components/general/button-general";
import { useTrainingDetail } from "@pages/training/training-detail/useTrainingDetail";
import { IndexPageHeader } from "@components/general/index-page-header";
import { useIndexPageDetail } from "@hooks/useIndexPageDetail";
import { ModalContent } from "@utils/models";
import { ListModal } from "@components/general/modals/list-modal";
import { CommentsSection } from "@components/general/comments-section/CommentsSection";
import { ids, styles } from "./style";
import { CollectionName, ContentType } from "@gql/generated/generated";

interface ContentWrapProps {
  children: React.ReactNode;
  id: string;
  onLayout?: (y: number) => void;
}

const ContentWrap = ({ children, id, onLayout }: ContentWrapProps) => {
  if (Platform.OS === "web") {
    return <div id={id}>{children}</div>;
  } else {
    return (
      <View
        onLayout={(event) => onLayout && onLayout(event.nativeEvent.layout.y)}
      >
        {children}
      </View>
    );
  }
};

const TrainingDetail = () => {
  const {
    id,

    scrollRef,
    training,

    navigate,
    relatedTrainings,
    loading,
    enrollingUser,
    handlePressEnroll,
    currentLessonTitle,
    userProgressForTraining,
  } = useTrainingDetail();

  const {
    listModalType,
    setListModalType,
    handleItemsSelect,
    handleListModalItems,
  } = useIndexPageDetail();

  const [contentPosition, setContentPosition] = useState<TabContentPosition>({
    overview: 0,
    curriculum: 0,
    instructor: 0,
    reviews: 0,
    relatedCourses: 0,
  });

  const { pathname } = useLocation();

  return (
    <Wrapper style={styles.wrapper} dataSetMedia={ids.wrapper}>
      <IndexPageHeader
        title={loading ? "Please wait.." : "Training"}
        onPressBack={() => navigate(-1)}
        onSave={() => setListModalType(ModalContent.save)}
        onShare={() => setListModalType(ModalContent.share)}
      />
      <ContentBox>
        <ScrollView
          ref={scrollRef as MutableRefObject<ScrollView>}
          stickyHeaderIndices={[0]}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={styles.scrollContent}
        >
          <TrainingTab
            scrollRef={scrollRef as MutableRefObject<ScrollView>}
            contentPosition={contentPosition}
          />
          <View style={styles.mobileInner}>
            <View style={styles.rightBox} dataSet={{ media: ids.rightBox }}>
              <EnrollBox
                training={training}
                currentLessonTitle={currentLessonTitle?.message}
                onEnroll={handlePressEnroll}
                enrollingUser={enrollingUser || loading}
              />
            </View>
            <TrainingDetailHead {...training} />
            <ContentWrap
              id={TabTitle.overview}
              onLayout={(overview) =>
                setContentPosition({ ...contentPosition, overview })
              }
            >
              <Overview {...training} />
            </ContentWrap>
            <ContentWrap
              id={TabTitle.curriculum}
              onLayout={(curriculum) =>
                setContentPosition({ ...contentPosition, curriculum })
              }
            >
              <Curriculum
                training={training}
                completedLessons={
                  userProgressForTraining?.completedLessons ?? []
                }
              />
            </ContentWrap>
            <ContentWrap
              id={TabTitle.instructor}
              onLayout={(instructor) =>
                setContentPosition({ ...contentPosition, instructor })
              }
            >
              {training?.instructors
                ? training?.instructors?.map((instructor) => (
                    <Instructor
                      instructor={instructor}
                      trainingId={training.id}
                    />
                  ))
                : null}
            </ContentWrap>
            <ContentWrap
              id={TabTitle.reviews}
              onLayout={(reviews) =>
                setContentPosition({ ...contentPosition, reviews })
              }
            >
              {id ? (
                <View style={{ marginTop: 40 }}>
                  <CommentsSection
                    commentedItemId={id}
                    commentsData={training?.comments}
                    commentedItemType={ContentType.TRAINING}
                    collectionName={CollectionName.TRAININGS}
                    averageStars={training?.averageStars || 0}
                    reviewCount={training?.numRatings}
                    totalComments={training?.commentCount}
                    userHasRated={!!training?.userHasCommented}
                  />
                </View>
              ) : null}
            </ContentWrap>
            <ContentWrap
              id={TabTitle.relatedCourses}
              onLayout={(relatedCourses) =>
                setContentPosition({ ...contentPosition, relatedCourses })
              }
            >
              <RelatedCourses trainings={relatedTrainings} />
            </ContentWrap>
          </View>
        </ScrollView>
      </ContentBox>
      <View style={styles.enrollBottom} dataSet={{ media: ids.enrollBottom }}>
        <ButtonGeneral
          label={currentLessonTitle?.message}
          onPress={handlePressEnroll}
          isLoading={enrollingUser || loading}
        />
      </View>

      <ListModal
        isVisible={listModalType !== ModalContent.closed}
        items={handleListModalItems(pathname)}
        onClose={() => setListModalType(ModalContent.closed)}
        onSelectItem={handleItemsSelect}
      />
    </Wrapper>
  );
};

export { TrainingDetail };
