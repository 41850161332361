import { useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { CaretUp } from "@components/general/icons/account-icons/CaretUp";
import { CaretDown } from "@components/general/icons/account-icons/CaretDown";
import { TrashIcon } from "@components/general/icons/account-icons/TrashIcon";
import { styles } from "./style";
import { Request } from "@gql/generated/generated";

interface Props extends Omit<Request, "userId" | "id" | "createdAt" | "type"> {
  onDelete?: () => void;
  createdAt: string;
  type: string;
}

const RequestItem = ({
  subject,
  type,
  createdAt,
  description,
  onDelete,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <TouchableOpacity style={styles.wrap} onPress={() => setIsOpen(!isOpen)}>
      <View style={styles.titleWrap}>
        <Text style={styles.title}>{subject}</Text>
        {isOpen ? <CaretUp /> : <CaretDown />}
      </View>
      {isOpen && (
        <>
          <View style={styles.dateWrap}>
            <Text style={styles.requestType}>{type}</Text>
            <View style={styles.dot} />
            <Text style={styles.date}>{createdAt}</Text>
          </View>
          <Text style={styles.description}>{description}</Text>
          <TouchableOpacity style={styles.deleteTouch} onPress={onDelete}>
            <TrashIcon />
            <Text style={styles.deleteText}>Delete Request</Text>
          </TouchableOpacity>
        </>
      )}
    </TouchableOpacity>
  );
};

export { RequestItem };
