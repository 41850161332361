import { useQueryFilters } from "../useGridFilters";
import { SwitchButton } from "@components/marketplace/marketplace-head-menu/switch-button/SwitchButton";

export const IsHighlightedFilter = () => {
  const { filters, setFilterValue } = useQueryFilters(["isHighlighted"]);

  return (
    <SwitchButton
      title="Highlighted"
      isActive={filters.isHighlighted}
      setIsActive={(val: boolean) => setFilterValue("isHighlighted", val)}
      needDisclosure={false}
    />
  );
};
