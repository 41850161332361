import StyleSheet from "react-native-media-query";
import { Platform } from "react-native";

export const { ids, styles } = StyleSheet.create({
  publishTools: {
    width: 315,
    ...(Platform.OS === "web" && { height: "max-content" }),
    paddingHorizontal: 20,
    borderColor: "#ECEEF6",
    borderRadius: 8,
    borderWidth: 2,
    backgroundColor: "#FFFFFF",
    // marginLeft: 40,
    paddingBottom: 22,
    marginBottom: 20,
  },
  subTitle: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 14,
    lineHeight: 20,
    color: "#202223",
    marginTop: 20,
    marginBottom: 4,
  },
  text: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 14,
    lineHeight: 20,
    color: "#202223",
  },
  tag: {
    paddingVertical: 4,
    paddingHorizontal: 8,
    maxWidth: "fit-content",
    borderRadius: 12,
  },
});
