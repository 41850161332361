import {
  StyleProp,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";
import { DownArrow } from "@components/general/icons";
import { styles } from "./styles";

interface MenuContainerProps {
  title: string;
  onPress: () => void;
  children: React.ReactNode;
  isMenuOpen: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  hasCompletedBillingAddress?: boolean;
}

const MenuContainer = ({
  children,
  title,
  onPress,
  isMenuOpen,
  containerStyle,
  hasCompletedBillingAddress = true,
}: MenuContainerProps) => {
  return (
    <View style={[styles.container, containerStyle]}>
      <TouchableOpacity
        style={styles.innerContainer}
        onPress={onPress}
        disabled={!hasCompletedBillingAddress}
      >
        <Text
          style={[
            styles.title,
            isMenuOpen ? { marginBottom: 20 } : null,
            !hasCompletedBillingAddress ? { color: "#8B9197" } : null,
          ]}
        >
          {title}
        </Text>
        <DownArrow
          color={hasCompletedBillingAddress ? "#5C5F62" : "#8B9197"}
          style={
            isMenuOpen && hasCompletedBillingAddress
              ? { transform: [{ rotate: "180deg" }] }
              : null
          }
        />
      </TouchableOpacity>
      {isMenuOpen && hasCompletedBillingAddress ? children : null}
    </View>
  );
};

export { MenuContainer };
