import React from "react";
import { View } from "react-native";
import { AnalyticsCountBox } from "@components/org/analytics/analytics-count-box";
import { ids, styles } from "./style";

interface Props {
  newMembers?: {
    count: number;
    percentageChange: number;
  } | null;
  activeMembersToday?: {
    count: number;
    percentageChange: number;
  } | null;
  averageTimeSpentToday?: {
    count: number;
    percentageChange: number;
  } | null;
}

const AnalyticsMemberCount = ({
  newMembers,
  activeMembersToday,
  averageTimeSpentToday,
}: Props) => (
  <View style={styles.wrap} dataSet={{ media: ids.wrap }}>
    <AnalyticsCountBox
      title="Active Members"
      count={activeMembersToday?.count}
      percent={activeMembersToday?.percentageChange}
      percentInfo="than yesterday"
    />
    <AnalyticsCountBox
      title="New Members"
      count={newMembers?.count}
      percent={newMembers?.percentageChange}
      percentInfo="than yesterday"
    />
    <AnalyticsCountBox
      title="Average Time Spent"
      count={averageTimeSpentToday?.count}
      percent={averageTimeSpentToday?.percentageChange}
      percentInfo="than yesterday"
      countInfo="min/member"
    />
  </View>
);

export { AnalyticsMemberCount };
