import { useContext } from "react";
import { Portal } from "react-native-portalize";
import {
  ModalButtons,
  ModalHeader,
} from "@components/general/modals/complete-modal/CompleteModal";
import { selectedUsersAssignModalContext } from "@context/SelectedUsersAssignModalContext";
import { ModalWrapper } from "../modal-wrapper";
import { View, Image, Text } from "react-native";
import { ModalDropx } from "@components/org/saved/modals/prompt-modal/modal-input-dropbox/ModalDropx";
import { styles } from "./styles";

interface Props {
  title?: string | null;
  subtitle?: string | null;
  image?: string | null;
  modalVisible: boolean;
  setModalVisible: (value: boolean) => void;
  updateUsersAssignmentsHandler: () => void;
  categories: string[];
  contentType?: string | null;
  additionalDetail: string[];
}

const PortalAssignModal = ({
  title,
  subtitle,
  image,
  modalVisible,
  setModalVisible,
  updateUsersAssignmentsHandler,
  categories,
  contentType,
  additionalDetail,
}: Props) => {
  const { setSelectedUsers } = useContext(selectedUsersAssignModalContext);
  return (
    <Portal>
      <ModalWrapper isVisible={modalVisible}>
        <ModalHeader
          title="Assign to your members"
          onClose={() => setModalVisible(false)}
        />
        <View style={styles.flexContainer}>
          <View style={styles.imageContainer}>
            <Image source={{ uri: image || "" }} style={styles.image} />
          </View>
          <View style={{ flex: 1 }}>
            <Text style={styles.title} numberOfLines={1}>
              {title}
            </Text>
            <Text numberOfLines={2} style={styles.subtitle}>
              {subtitle}
            </Text>
            {additionalDetail ? (
              <Text numberOfLines={1} style={styles.details}>
                {additionalDetail.join(" • ")}
              </Text>
            ) : null}
          </View>
        </View>
        <ModalDropx categories={categories} contentType={contentType} />
        <ModalButtons
          primaryButtonTitle="Assign"
          onPrimaryButtonPress={updateUsersAssignmentsHandler}
          secondaryButtonTitle="Cancel"
          onSecondaryButtonPress={() => {
            setSelectedUsers([]);
            setModalVisible(false);
          }}
        />
      </ModalWrapper>
    </Portal>
  );
};

export { PortalAssignModal };
