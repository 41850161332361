import { Text, View } from "react-native";
import React from "react";
import { MenuContainer } from "../menu-container/MenuContainer";
import { styles } from "./styles";
import { BillingAddressEditMode } from "./BillingAddressEditMode";
import { ButtonLayout } from "@pages/account/profile/button-layout/ButtonLayout";
import { useFormikContext } from "formik";
import { BillingAddress as BillingAddressType } from "@gql/generated/generated";

interface BillingAddressProps {
  isMenuOpen: boolean;
  setIsMenuOpen: (isMenuOpen: boolean) => void;
  billingAddress?: BillingAddressType | null;
  showEditMode: boolean;
  setShowEditMode: (showEditMode: boolean) => void;
}

const BillingAddress = ({
  isMenuOpen,
  setIsMenuOpen,
  billingAddress,
  showEditMode,
  setShowEditMode,
}: BillingAddressProps) => {
  const { handleSubmit, isSubmitting } = useFormikContext();

  const { houseNumber, addressOne, addressTwo, postCode, city, country } =
    billingAddress || {};

  return (
    <MenuContainer
      title="Billing Address"
      onPress={() => setIsMenuOpen(!isMenuOpen)}
      isMenuOpen={isMenuOpen}
    >
      {!showEditMode ? (
        <View style={styles.container}>
          <View>
            <Text style={styles.billingAddressText}>
              {houseNumber ? `${houseNumber}` : ""}{" "}
              {addressOne ? `${addressOne},` : ""}
            </Text>
            {addressTwo ? (
              <Text style={styles.billingAddressText}>
                {addressTwo ? `${addressTwo},` : ""}{" "}
              </Text>
            ) : null}
            <Text style={styles.billingAddressText}>{postCode}</Text>
            <Text style={styles.billingAddressText}>
              {city ? `${city},` : ""} {country || ""}
            </Text>
          </View>
          <Text onPress={() => setShowEditMode(true)} style={styles.editButton}>
            Edit
          </Text>
        </View>
      ) : (
        <View
          style={{
            justifyContent: "space-between",
          }}
        >
          <BillingAddressEditMode />
          <ButtonLayout
            closeEditMode={() => setShowEditMode(false)}
            submitCloseHander={handleSubmit}
            isSubmitting={isSubmitting}
            labelActionButton="Save"
            containerButtonStyle={{
              justifyContent: "flex-start",
              marginTop: 16,
            }}
          />
        </View>
      )}
    </MenuContainer>
  );
};

export { BillingAddress };
