import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  cardWrapper: {
    flex: 1,
    padding: 20,
    borderRadius: 8,
    justifyContent: "space-between",
    shadowColor: "rgba(0, 0, 0, 0.15)",
    shadowRadius: 4,
    shadowOffset: { height: 0.5, width: 0 },
  },
  cardTitle: {
    marginBottom: 20,
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 14,
    lineHeight: 20,
    color: "#202223",
  },
  cardRow: {
    marginBottom: 20,
    flexDirection: "row",
    alignItems: "center",
  },
  cardNumber: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 26,
    lineHeight: 32,
    color: "#202223",
  },
  cardPercentage: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 14,
    lineHeight: 20,
    color: "#202223",
  },
  cardPercentageLabel: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 14,
    lineHeight: 20,
    color: "#202223",
    marginLeft: 8,
  },
});
