import { DatePicker, OptionList, Popover } from "@shopify/polaris";
import { Platform, Text, View } from "react-native";
import { ids, styles } from "./style";
import { useCallback, useEffect, useState } from "react";
import { FilterAction } from "@hooks/useFilters";
import { camelize } from "@utils/misc";
import { ButtonGeneral, ButtonVariant } from "../button-general";
import { FilterIcon } from "@components/general/icons/training-icons";
import {
  BriefcaseFillIcon,
  CalendarIcon,
  CategoryFilterIcon,
  FileIcon,
  LengthIcon,
  MapPinFill,
  SortIcon,
  UserFillIcon,
} from "@components/general/icons/filter-icons";
import { StarIcon } from "@components/general/icons/feed-icons";
import { CategoryButton } from "@components/back-office/tables/category-button";
import { useUserContext } from "@context/UserContext";
import { UserTypes } from "@components/back-office/editor/formik-publish-tools";
import { SortBy, TrainingSortBy } from "../../../gql/generated/generated";
import { CONTENT_TYPES } from "./constants";
import ResetButton from "@components/back-office/tables/table-header/ResetButton";

interface FiltersProps {
  filters: any;
  setFilters: any;
  isTraining?: boolean;
  isEvent?: boolean;
  isContent?: boolean;
}
enum FilterTypes {
  Category = "Category",
  Date = "Date",
  Popularity = "Popularity",
  MemberType = "Member Type",
  Length = "Duration",
  JobRole = "Job Role",
  ContentType = "Content Type",
  Duration = "Duration",
  AddFilter = "Add Filter",
  EventType = "Event Type",
  SortBy = "Sort By",
}

const formatFilterValue = (value: any, filterName: string) => {
  if (filterName === FilterTypes.JobRole) {
    if (Array.isArray(value)) {
      return value.length === 0
        ? FilterTypes.JobRole
        : value.length === 1
        ? "1 Job Selected"
        : `${value.length} Jobs Selected`;
    }
  } else if (filterName === FilterTypes.Popularity) {
    if (value) {
      return `${value} Stars & up`;
    }
  } else if (
    filterName === FilterTypes.MemberType ||
    filterName === FilterTypes.EventType
  ) {
    if (Array.isArray(value) && value.length === 0) {
      return FilterTypes.MemberType;
    } else if (Array.isArray(value)) {
      return value.join(", ");
    }
  } else if (filterName === FilterTypes.Date) {
    if (value) {
      const { start, end } = value;
      const formattedEnd = new Intl.DateTimeFormat("default", {
        day: "2-digit",
        month: "long",
        year: "2-digit",
      }).format(end);
      const formattedStart = new Intl.DateTimeFormat("default", {
        day: "2-digit",
        month: "long",
        year: "2-digit",
      }).format(start);
      return `${formattedStart} - ${formattedEnd}`;
    }
    return FilterTypes.Date;
  } else if (filterName === FilterTypes.SortBy) {
    return value;
  }

  return "";
};

const Reset = () => {
  return <Text style={{ color: "red" }}>Reset</Text>;
};

const resetOption = {
  label: "Reset",
  value: "",
};

const PopularityLabel = ({ value }: { value: number }) => {
  const stars = Array.from({ length: 5 }, (_, i) => (
    <StarIcon
      key={i}
      fillColor={value > i ? "#5C5F62" : "none"}
      strokeColor="#5C5F62"
    />
  ));

  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        ...(Platform.OS === "web" &&
          ({ gap: "6px", paddingRight: "20px" } as any)),
      }}
    >
      {stars} & up
    </View>
  );
};

const membershipTiers = {
  Free: [UserTypes.Free],
  Plus: [UserTypes.Free, UserTypes.Plus],
  Club: [UserTypes.Free, UserTypes.Plus, UserTypes.Club],
} as { [key: string]: string[] };

const Filters = ({
  filters,
  setFilters,
  isTraining,
  isEvent,
  isContent,
}: FiltersProps) => {
  const { currentUser } = useUserContext();
  const [visibleFilters, setVisibleFilters] = useState([FilterTypes.Category]);
  const [displayFilter, setDisplayFilter] = useState<FilterTypes>();
  const [selectedFilterValues, setSelectedFilterValues] = useState<{
    [key: string]: any;
  }>({});

  const [{ month, year }, setDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });
  const [selectedDates, setSelectedDates] = useState({
    start: new Date(),
    end: new Date(Date.now() + 86400000 * 7),
  });

  const handleMonthChange = useCallback(
    (month: number, year: number) => setDate({ month, year }),
    []
  );

  useEffect(() => {
    setVisibleFilters([FilterTypes.Category]);
    setSelectedFilterValues({});
    setDisplayFilter(undefined);
  }, [isEvent, isTraining, isContent]);

  const FILTERS = [
    {
      name: FilterTypes.Category,
      image: <CategoryFilterIcon />,
      optionsTitle: "Filter By",
      options: [],
      allowMultiple: true,
      action: FilterAction.SET_CATEGORY,
      defaultValue: [],
    },

    {
      name: FilterTypes.Date,
      image: <CalendarIcon />,
      optionsTitle: "Choose Dates",
      options: [],
      allowMultiple: false,
      action: FilterAction.SET_DATE,
      defaultValue: [],
    },
    {
      name: FilterTypes.Popularity,
      image: <StarIcon strokeColor="#5C5F62" fillColor="none" />,
      optionsTitle: "Popularity",
      options: [
        {
          label: <PopularityLabel value={4} />,
          value: "4",
        },
        {
          label: <PopularityLabel value={3} />,
          value: "3",
        },
        {
          label: <PopularityLabel value={2} />,
          value: "2",
        },
        {
          label: <PopularityLabel value={1} />,
          value: "1",
        },
      ],
      allowMultiple: false,
      action: FilterAction.SET_POPULARITY,
      defaultValue: [],
    },
    {
      name: FilterTypes.MemberType,
      image: <UserFillIcon />,
      optionsTitle: "Member Type",
      options: membershipTiers[currentUser?.subscription?.plan || "Free"].map(
        (tier) => ({
          label: tier,
          value: tier,
        })
      ),
      allowMultiple: true,
      action: FilterAction.SET_MEMBER_TYPE,
      defaultValue: [],
    },
    {
      name: FilterTypes.Length,
      image: <LengthIcon />,
      optionsTitle: "Length",
      options: [
        {
          label: "1 to 5 minutes",
          value: "1 to 5 minutes",
        },
        {
          label: "5 to 10 minutes",
          value: "5 to 10 minutes",
        },
        {
          label: "+10 minutes",
          value: "+10 minutes",
        },
      ],
      allowMultiple: true,
      action: FilterAction.SET_LENGTH,
      defaultValue: [],
    },
    {
      name: FilterTypes.JobRole,
      image: <BriefcaseFillIcon />,
      optionsTitle: "Job Role",
      options: [
        {
          label: "Director/Owner",
          value: "Director/Owner",
        },
        {
          label: "Senior Manager",
          value: "Senior Manager",
        },
        {
          label: "Billing Manager/Team Leader",
          value: "Billing Manager/Team Leader",
        },
        {
          label: "Account Manager",
          value: "Account Manager",
        },
        {
          label: "Marketing - Heads of/Manager/Executive",
          value: "Marketing - Heads of/Manager/Executive",
        },
        {
          label: "Finance - Heads of/Manager/Executive",
          value: "Finance - Heads of/Manager/Executive",
        },
        {
          label: "Operations - Heads of/Manager/Executive",
          value: "Operations - Heads of/Manager/Executive",
        },
        {
          label: "Talent and L&D - Heads of/Manager/Executive",
          value: "Talent and L&D - Heads of/Manager/Executive",
        },
        {
          label: "HR - Heads of/Manager/Executive",
          value: "HR - Heads of/Manager/Executive",
        },
        {
          label: "Consultant",
          value: "Consultant",
        },
        {
          label: "Resourcer",
          value: "Resourcer",
        },
      ],
      allowMultiple: true,
      action: FilterAction.SET_JOB_ROLE,
      defaultValue: [],
    },
    {
      name: FilterTypes.ContentType,
      image: <FileIcon />,
      optionsTitle: "Content Type",
      options: CONTENT_TYPES,
      allowMultiple: true,
      action: FilterAction.SET_CONTENT_TYPE,
      defaultValue: [],
    },
    {
      name: FilterTypes.EventType,
      image: <MapPinFill />,
      optionsTitle: "Event type",
      options: [
        { label: "In-person", value: "In-person" },
        { label: "Virtual", value: "Virtual" },
        { label: "Hybrid", value: "Hybrid" },
      ],
      allowMultiple: true,
      action: FilterAction.SET_EVENT_TYPE,
      defaultValue: [],
    },
    {
      name: FilterTypes.SortBy,
      image: <SortIcon />,
      optionsTitle: "Sort By",
      options: isTraining
        ? [
            { label: "Newest First", value: "Newest First" },
            { label: "Most Popular", value: "Most Popular" },
            { label: "Enrolled", value: "Enrolled" },
          ]
        : [
            { label: "Newest First", value: "Newest First" },
            { label: "Oldest First", value: "Oldest First" },
            { label: "Most Popular", value: "Most Popular" },
          ],
      allowMultiple: false,
      /**
       * Map the value from the options to the value that will be used in the payload
       */
      payloadMapper: (values: string[]) => {
        const value = values[0];

        switch (value) {
          case "Newest First":
            return [SortBy.MOST_RECENT];
          case "Oldest First":
            return [SortBy.OLDEST_FIRST];
          case "Most Popular":
            return [SortBy.POPULARITY];
          case "Enrolled":
            return [TrainingSortBy.ENROLLED];
          default:
            return [];
        }
      },
      action: FilterAction.SET_SORT_BY,
      defaultValue: [SortBy.MOST_RECENT],
    },
  ];

  const AddFilterButton = (
    <View
      style={styles.addFilterButtonWrap}
      dataSet={{ media: ids.addFilterButtonWrap }}
    >
      <ButtonGeneral
        variant={ButtonVariant.Secondary}
        onPress={() => setDisplayFilter(FilterTypes.AddFilter)}
      >
        <FilterIcon />
        <Text style={styles.addFilterText}>Add Filter</Text>
      </ButtonGeneral>
    </View>
  );

  let filterOptions;

  if (isTraining) {
    filterOptions = [
      { value: FilterTypes.Category, label: FilterTypes.Category },
      { value: FilterTypes.Date, label: FilterTypes.Date },
      { value: FilterTypes.Popularity, label: FilterTypes.Popularity },
      { value: FilterTypes.MemberType, label: FilterTypes.MemberType },
      { value: FilterTypes.JobRole, label: FilterTypes.JobRole },
      { value: FilterTypes.SortBy, label: FilterTypes.SortBy },
    ];
  } else if (isEvent) {
    filterOptions = [
      { value: FilterTypes.Category, label: FilterTypes.Category },
      { value: FilterTypes.Date, label: FilterTypes.Date },
      { value: FilterTypes.MemberType, label: FilterTypes.MemberType },
      { value: FilterTypes.JobRole, label: FilterTypes.JobRole },
      { value: FilterTypes.EventType, label: FilterTypes.EventType },
      { value: FilterTypes.SortBy, label: FilterTypes.SortBy },
    ];
  } else if (isContent) {
    filterOptions = [
      { value: FilterTypes.Category, label: FilterTypes.Category },
      { value: FilterTypes.Date, label: FilterTypes.Date },
      { value: FilterTypes.Popularity, label: FilterTypes.Popularity },
      { value: FilterTypes.MemberType, label: FilterTypes.MemberType },
      { value: FilterTypes.Length, label: FilterTypes.Length },
      { value: FilterTypes.JobRole, label: FilterTypes.JobRole },
      { value: FilterTypes.ContentType, label: FilterTypes.ContentType },
      { value: FilterTypes.SortBy, label: FilterTypes.SortBy },
    ];
  }

  const mappedFilterValues = {
    [FilterTypes.Category]: {
      action: FilterAction.SET_CATEGORY,
      defaultValue: [],
    },
    [FilterTypes.Date]: {
      action: FilterAction.SET_DATE,
      defaultValue: { end: null, start: null },
    },
    [FilterTypes.Popularity]: {
      action: FilterAction.SET_POPULARITY,
      defaultValue: [],
    },
    [FilterTypes.MemberType]: {
      action: FilterAction.SET_MEMBER_TYPE,
      defaultValue: [],
    },
    [FilterTypes.Length]: {
      action: FilterAction.SET_LENGTH,
      defaultValue: [],
    },
    [FilterTypes.JobRole]: {
      action: FilterAction.SET_JOB_ROLE,
      defaultValue: [],
    },
    [FilterTypes.ContentType]: {
      action: FilterAction.SET_CONTENT_TYPE,
      defaultValue: [],
    },
    [FilterTypes.SortBy]: {
      action: FilterAction.SET_SORT_BY,
      defaultValue: [],
    },
    [FilterTypes.EventType]: {
      action: FilterAction.SET_EVENT_TYPE,
      defaultValue: [],
    },
  };

  const removeFilterValues = (newValues: string[]) => {
    // over-complicated way to find the removed value 
    // relies on passing the updated values from sub-component OptionList 
    // and comparing that with the stale list of values in the parent component
    const removedValue = visibleFilters.find(
      (item) => !newValues.includes(item)
    ) as keyof typeof mappedFilterValues;

    const newSelected = selectedFilterValues;

    if (removedValue) {
      delete newSelected[removedValue];
      // this goes through a prop-drilled chain of components and hooks before calling the dispatch function 
      // in the useReducer hook in the useFilters custom hook
      setFilters({
        type: mappedFilterValues[removedValue].action,
        payload: mappedFilterValues[removedValue].defaultValue,
      });
    }
    // this sets the local state of filters in this component
    setSelectedFilterValues(newSelected);
  };

  return (
    <View style={styles.filterRow} dataSet={{ media: ids.filterRow }}>
      {/* This iterates through all the possible filters  */}
      {FILTERS.map(
        ({
          options,
          optionsTitle,
          image,
          name,
          allowMultiple,
          action,
          payloadMapper,
          defaultValue,
        }) => {
          if (!visibleFilters.includes(name)) return null;

          // if the filter type is Category, render a specific activator/options
          if (name === FilterTypes.Category) {
            return (
              <CategoryButton
                key={name}
                selectedCat={selectedFilterValues[name]}
                withSlimButton
                isEditor={false}
                placeholderText="Category"
                setSelectedCat={(value) => {
                  setSelectedFilterValues((prevValues) => ({
                    ...prevValues,
                    [name]: value,
                  }));
                  setFilters({
                    type: action,
                    payload: value,
                  });
                }}
                displayCategoryModal={displayFilter === FilterTypes.Category}
                setDisplayCategoryModal={() =>
                  setDisplayFilter(displayFilter === name ? undefined : name)
                }
              />
            );
          }

          // if the filter type is something other than Category, render a different activator/options
          // NB this still isn't the 'Add Filter' activator/options (see further down)
          const Activator = (
            <View
              key={name}
              style={styles.activatorItemWrap}
              dataSet={{ media: ids.activatorItemWrap }}
            >
              <ButtonGeneral
                variant={ButtonVariant.Secondary}
                onPress={() =>
                  setDisplayFilter(displayFilter === name ? undefined : name)
                }
              >
                {image}
                <Text style={styles.addFilterText}>
                  {(filters[camelize(name)]?.length &&
                    selectedFilterValues[name]) ||
                  name === FilterTypes.Date
                    ? formatFilterValue(selectedFilterValues[name], name)
                    : name}
                </Text>
              </ButtonGeneral>
            </View>
          );

          return (
            <Popover
              active={displayFilter === name}
              activator={Activator}
              onClose={() => setDisplayFilter(undefined)}
              preferredAlignment="left"
              key={name}
            >
              <View
                style={
                  name === FilterTypes.Date
                    ? styles.optionsContainerDatePicker
                    : styles.optionsContainer
                }
              >
                {/* this button resets to default the options checkboxes for a given filter type */}
                <ResetButton
                  onReset={() => {
                    setSelectedFilterValues((prevValues) => ({
                      ...prevValues,
                      [name]: null,
                    }));
                    setFilters({
                      type: action,
                      payload: defaultValue,
                    });
                  }}
                />

                {/* if the filter is the date picker then render that rather than an options list */}
                {name === FilterTypes.Date ? (
                  <DatePicker
                    allowRange
                    disableDatesBefore={
                      isEvent
                        ? new Date(new Date().setHours(0, 0, 0, 0))
                        : undefined
                    }
                    month={month}
                    year={year}
                    onChange={({ start, end }) => {
                      setSelectedFilterValues((prevValues) => ({
                        ...prevValues,
                        [name]: { start, end },
                      }));
                      setSelectedDates({ start, end });
                      setFilters({
                        type: action,
                        payload: { start: start.getTime(), end: end.getTime() },
                      });
                    }}
                    onMonthChange={handleMonthChange}
                    selected={selectedDates}
                  />
                ) : (
                  // this is the options list for selectable filters other than the date picker
                  <OptionList
                    title={optionsTitle}
                    onChange={(value) => {
                      setSelectedFilterValues((prevValues) => ({
                        ...prevValues,
                        [name]: value,
                      }));
                      setFilters({
                        type: action,
                        payload: payloadMapper ? payloadMapper(value) : value,
                      });
                    }}
                    options={options}
                    // @ts-ignore
                    selected={filters[camelize(name)]}
                    allowMultiple={allowMultiple}
                  />
                )}
              </View>
            </Popover>
          );
        }
      )}
      {/* this is the add filter activator/options which renders to the right of the Category filter activator/options and any other selected filter activator/options */}
      <Popover
        active={displayFilter === FilterTypes.AddFilter}
        activator={AddFilterButton}
        onClose={() => setDisplayFilter(undefined)}
        preferredAlignment="left"
      >
        <View style={styles.optionsContainer}>
          <OptionList
            title="Add Filter"
            onChange={(value) => {
              if (visibleFilters.length > value.length) {
                removeFilterValues(value);
              }
              setVisibleFilters(value as FilterTypes[]);
            }}
            options={filterOptions}
            selected={visibleFilters}
            allowMultiple
          />
        </View>
      </Popover>
    </View>
  );
};

export { Filters };
