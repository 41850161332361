import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { ISvgProps } from "../../../types";

const CalendarIcon = ({ color = "#5C5F62", ...rest }: ISvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={16}
    fill="none"
    {...rest}
  >
    <Path
      fill={color}
      d="M4 .95a1 1 0 0 1 1 1v1h4v-1a1 1 0 1 1 2 0v1h1a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-9a2 2 0 0 1 2-2h1v-1a1 1 0 0 1 1-1zm-2 6v7h10v-7H2z"
    />
  </Svg>
);

export { CalendarIcon };
