import StyleSheet from "react-native-media-query";
import { mediaQuery } from "@utils/misc";

export const { ids, styles } = StyleSheet.create({
  image: {
    minHeight: 365,
    padding: 18,
    marginBottom: 25,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  description: {
    color: "#6E757C",
    marginTop: 9,
  },
  dateNumber: {
    fontSize: 45,
    fontFamily: "OpenSans_600SemiBold",
  },
  titleText: {
    fontSize: 22,
    fontFamily: "OpenSans_600SemiBold",
  },
  timeText: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 12,
  },
  leftSideBox: {
    borderRightWidth: 1,
    borderRightColor: "#0D1738",
    paddingRight: 10,
  },
  rightSideBox: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: 16,
    flex: 1,
    flexWrap: "wrap",
  },
  infoCard: {
    backgroundColor: "#FFFFFF",
    paddingVertical: 16,
    paddingHorizontal: 20,
    borderRadius: 8,
    width: "50%",
    opacity: 0.8,
    maxWidth: 450,
    [mediaQuery.forMobile]: {
      width: "80%",
    },
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  roundedImage: {
    borderRadius: 15,
  },
  dotsContainer: {
    position: "absolute",
    alignSelf: "flex-start",
    display: "flex",
    flexDirection: "row",
  },
  dot: {
    height: 5,
    width: 5,
    borderRadius: 200,
    backgroundColor: "#FFFF",
    opacity: 0.45,
    marginRight: 3,
  },
  selectedDot: {
    height: 5,
    width: 5,
    borderRadius: 200,
    backgroundColor: "#FFFF",
    opacity: 1,
  },
  lessonsText: {
    color: "#0D1738",
    fontSize: 14,
    fontFamily: "OpenSans_600SemiBold",
  },
  trainingDescription: {
    color: "#0D1738",
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
    marginTop: 4,
    marginBottom: 4,
  },
});
