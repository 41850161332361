const getBackgroundColorFromStringValue = (value: string) => {
  switch (value) {
    case "Active":
    case "Complete":
      return "#AEE9D1";
    case "Inactive":
    case "Suspend":
      return "#FED3D1";
    case "Disable":
      return "#C9CCD0";
    case "Special Offer":
      return "#D72C0D";
    case "Delete":
      return "#fa5043";
    default:
      return null;
  }
};

export { getBackgroundColorFromStringValue };

export const calculateContainerMinHeight = (windowHeight = window.innerHeight): string => {
  return `${windowHeight - 280}px`;
};
