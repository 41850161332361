import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
    marginBottom: 12,
  },
  headerMainTitle: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 26,
    lineHeight: 32,
    color: "#0D1738",
    marginBottom: 16,
    marginRight: 16,
  },
  headerBackBtn: {
    borderWidth: 1,
    borderColor: "#BABFC3",
    borderRadius: 4,
    width: 36,
    height: 36,
    marginRight: 8,
    justifyContent: "center",
    alignItems: "center",
  },
  headerArrowLeft: {
    width: 7,
    height: 12,
  },
  exportBtn: {
    flexDirection: "row",
    alignItems: "center",
    height: 36,
    paddingHorizontal: 16,
    backgroundColor: "#0D1738",
    borderRadius: 4,
  },
  exportBtnIcon: {
    marginRight: 6,
  },
  exportBtnText: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 14,
    lineHeight: 20,
    color: "#fff",
  },
});
