import { View } from "react-native";
import dayjs from "dayjs";
import { useParams } from "react-router-native";
import { PulseResultWrapper } from "@components/roadmap/pulse-result-wrapper";
import { ids, styles } from "./style";
import { getCurrentRoadmapItemsFromData } from "@pages/roadmap/utils";
import {
  Recommendations,
  useEmployerGetRoadmapQuery,
  useGetAllRecommendationsQuery,
} from "@gql/generated/generated";
import { QuestionBlocks } from "./QuestionBlocks";
import { CategoryBlocks } from "./CategoryBlocks";
import { Loader } from "./Loader";

export const PulseResultPlan = () => {
  const { sectionId } = useParams();

  const {
    data: { employerGetRoadmap: roadmapData } = {},
    loading: loadingRoadmap,
  } = useEmployerGetRoadmapQuery();

  const { currentPulse } =
    (roadmapData && getCurrentRoadmapItemsFromData(roadmapData)) || {};

  const { data: allRecommendations, loading: loadingRecommendations } =
    useGetAllRecommendationsQuery();

  if (loadingRoadmap || loadingRecommendations) {
    return <Loader />;
  }

  const recommendations =
    (allRecommendations?.getAllRecommendations as Recommendations)
      ?.recommendations || [];

  const lastUpdatedDate = currentPulse?.lastUpdated
    ? dayjs(new Date(Number(currentPulse.lastUpdated))).format("DD MMMM YYYY")
    : null;

  if (!currentPulse) {
    throw new Error("Missing currentPulse");
  }

  const title = currentPulse?.sections?.find(
    (section) => section.id === sectionId
  )?.name;

  return (
    <PulseResultWrapper
      title={title}
      date={lastUpdatedDate}
      scoreOutOf100={currentPulse?.averageScore ?? 0}
      countdown="34d : 12h : 31m : 11s" // TODO: why is this hardcoded
    >
      <View style={styles.itemsWrap} dataSet={{ media: ids.itemsWrap }}>
        {sectionId ? (
          <QuestionBlocks {...{ currentPulse, sectionId, recommendations }} />
        ) : (
          <CategoryBlocks {...{ currentPulse, recommendations }} />
        )}
      </View>
    </PulseResultWrapper>
  );
};
