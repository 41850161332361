import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const UploadIcon = (props: SvgProps) => (
  <Svg width={16} height={16} fill="none" {...props}>
    <Path
      d="M8.707.293a.999.999 0 0 0-1.414 0l-3 3a.999.999 0 1 0 1.414 1.414L7 3.414V11a1 1 0 1 0 2 0V3.414l1.293 1.293a.999.999 0 1 0 1.414-1.414l-3-3ZM1 14a1 1 0 1 0 0 2h14a1 1 0 1 0 0-2H1Z"
      fill={props.fill || "#5C5F62"}
    />
  </Svg>
);

export { UploadIcon };
