import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { ISvgProps } from "../../../../types";

const UserAvatarIcon = ({ color = "#fff", ...props }: ISvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={19}
    fill="none"
    {...props}
  >
    <Path
      fill={color}
      d="M8 8.097a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm-8 7a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3c0-1.657-1.5-5-8-5s-8 3.343-8 5z"
    />
  </Svg>
);

export { UserAvatarIcon };
