import Svg, { Path } from "react-native-svg";

const ChevronIcon = () => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    stroke-width="2"
    stroke="currentColor"
    fill="none"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <Path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <Path d="M6 9l6 6l6 -6" />
  </Svg>
);

export { ChevronIcon };
