import StyleSheet from "react-native-media-query";
import { mediaQuery } from "@utils/misc";

export const { ids, styles } = StyleSheet.create({
  head: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 20,
    [mediaQuery.forMobile]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  title: {
    color: "#0D1738",
    fontSize: 20,
    fontFamily: "OpenSans_600SemiBold",
    [mediaQuery.forMobile]: {
      marginBottom: 8,
    },
  },
  selectWrap: {
    flexDirection: "row",
    alignItems: "center",
    [mediaQuery.forMobile]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  selectItem: {
    width: 160,
    marginLeft: 8,
    [mediaQuery.forMobile]: {
      marginBottom: 8,
      marginLeft: 0,
    },
  },
  contentBox: {
    padding: 20,
  },
  contentInner: {
    width: "100%",
  },
  chart: {
    flex: 1,
  },
});
