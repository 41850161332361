import { ImageSourcePropType, View } from "react-native";
import { styles } from "./styles";
import { StatisticCard } from "@components/back-office/roadmap/statistic-card";
import { StatisticUserCard } from "@components/back-office/roadmap/statistic-user-card";

interface Item {
  title: string;
  mainData: string;
  secondaryData: string;
}
interface Props {
  items: Item[];
  image?: ImageSourcePropType;
  name?: string | null;
}

export const StatisticCardsContainer = ({ items, image, name }: Props) => {
  return (
    <View style={styles.cardsWrapper}>
      {items.map((card: any, index: number) => (
        <StatisticCard
          key={index}
          title={card.title}
          statisticData={card.mainData}
          footerTextCard={card.secondaryData}
          isCompletion={card.title === "Completion"}
          isLastCheck={card.title === "Last Check"}
          isNextReminder={card.title === "Next reminder"}
          isNorthStartRemainder={card.title === "Next reminder"}
          isCurrentScore={card.title === "Current Scores"}
        />
      ))}
      <StatisticUserCard image={image} name={name ?? ""} />
    </View>
  );
};
