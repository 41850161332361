import StylesSheet from "react-native-media-query";

export const { ids, styles } = StylesSheet.create({
  wrap: {
    marginHorizontal: 40,
    borderWidth: 1,
    borderColor: "#EDEEEF",
  },
  wrapTopBorder: {
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
  },
  wrapBottomBorder: {
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
  },
  menuButtonWrap: {
    paddingHorizontal: 16,
    paddingVertical: 10,
    justifyContent: "space-between",
    flexDirection: "row",
  },
  menuButtonTitle: {
    fontSize: 14,
    color: "#0D1738",
    fontFamily: "OpenSans_600SemiBold",
  },
  menuButtonText: {
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
    marginRight: 20,
  },
});
