import {
  ActivityIndicator,
  Platform,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { Formik } from "formik";
import { useNavigate, useParams } from "react-router-native";
import * as Yup from "yup";
import { FormikInput } from "@components/back-office/editor/formik-input";
import { FormikTextArea } from "@components/back-office/editor/formik-text-area/FormikTextArea";
import { FormikRichText } from "@components/back-office/editor/formik-rich-text";
import { styles } from "./style";
import { FormikPublishTools } from "@components/back-office/editor/formik-publish-tools/FormikPublishTools";
import { FormikSaveBar } from "@components/back-office/editor/formik-save-bar";
import { FormSection } from "@components/back-office/editor/form-section";
import { Icon } from "@shopify/polaris";
import { ChevronLeftMinor } from "@shopify/polaris-icons";
import {
  CollectionName,
  Status,
  useGetContentQuery,
  useUpsertContentMutation,
} from "@gql/generated/generated";
import { customFirestoreId } from "@utils/misc";
import { FileUploaderWithTitle } from "@components/back-office/editor/uploader/index";
import { AddAttachments } from "@components/back-office/editor/add-attachments";
import { useToast } from "@context/ToastContext";

const ContentEditor = () => {
  const { addToast } = useToast();

  const navigation = useNavigate();
  const [upsertContent] = useUpsertContentMutation();

  const { id: existingId } = useParams() as { id: string };

  const { data, loading } = useGetContentQuery({
    variables: { getContentId: existingId },
    skip: !existingId,
    fetchPolicy: "network-only"
  });

  const content =
    data?.getContent.__typename === "Content" ? data.getContent : null;
  const contentId = existingId || customFirestoreId();

  if (loading) {
    return (
      <View
        style={{
          height: Platform.OS === "web" ? "100vh" : "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ActivityIndicator size="large" color="#213470" />
      </View>
    );
  }

  return (
    <View
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Formik
        initialValues={{
          title: content?.title ?? "",
          description: content?.description ?? "",
          summary: content?.summary ?? "",
          contentTypes: content?.contentTypes ?? [],
          coverImage: content?.coverImage ?? null,
          jobRoles: content?.jobRoles ?? [],
          userType: content?.userType ?? [],
          files: content?.files ?? [],
          categories: content?.categories ?? [],
          status: content?.status ?? Status.DRAFT,
        }}
        enableReinitialize
        validationSchema={Yup.object().shape({
          title: Yup.string().when("status", {
            is: "Publish",
            then: Yup.string().required("Title is required"),
            otherwise: Yup.string(),
          }),
          description: Yup.string().when("status", {
            is: "Publish",
            then: Yup.string().required("Description is required"),
            otherwise: Yup.string(),
          }),
          summary: Yup.string().when("status", {
            is: "Publish",
            then: Yup.string().required("Summary is required"),
            otherwise: Yup.string(),
          }),
          coverImage: Yup.object().when("status", {
            is: "Publish",
            then: Yup.object()
              .required("Cover image is required")
              .typeError(
                (input) => "Invalid cover image type, must be JPG or PNG"
              ),
            otherwise: Yup.object().notRequired().nullable(),
          }),
          userType: Yup.array().when("status", {
            is: "Publish",
            then: Yup.array()
              .required("User type is required")
              .min(1, "Select minimum 1 user type."),
            otherwise: Yup.array(),
          }),
          files: Yup.array(),
          status: Yup.string().required(),
        })}
        onSubmit={async (values) => {
          // create a search-indexable description without html tags
          const searchIndexableDescription = values.description
            ?.replace(/(<([^>]+)>)/ig, '' )
            .replace(/(\r\n|\r|\n)/g, ' ')
            .replace(/&nbsp;/g, '')
            .split(' ').map(s => s.trim()).filter(s => s.length > 0).join(' ');
          const {
            title,
            categories,
            contentTypes,
            description,
            jobRoles,
            status,
            summary,
            userType,
          } = values;
          if (content) {
            await upsertContent({
              variables: {
                input: {
                  id: contentId,
                  status,
                  title,
                  categories,
                  contentTypes,
                  description,
                  searchIndexableDescription,
                  jobRoles,
                  summary,
                  userType,
                },
              },
            });
            addToast("success", "Content updated!");
          } else {
            const result = await upsertContent({
              variables: {
                input: {
                  id: contentId,
                  status: values.status || "Draft",
                  title,
                  categories,
                  contentTypes,
                  description,
                  searchIndexableDescription,
                  jobRoles,
                  summary,
                  userType,
                },
              },
            });

            if (result.data?.upsertContent) {
              addToast("success", "Content Successfully Created!");
              setTimeout(() => navigation(`/content`), 1500);
            }
          }
        }}
      >
        {() => {
          return (
            <>
              <FormikSaveBar />
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 12,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    navigation(-1);
                  }}
                  style={{
                    borderWidth: 1,
                    borderColor: "#BABFC3",
                    borderRadius: 4,
                    width: 36,
                    height: 36,
                    marginRight: 8,
                  }}
                >
                  <Icon source={ChevronLeftMinor} />
                </TouchableOpacity>
                <Text style={styles.title}>
                  {existingId ? "Update" : "Add New"} Content
                </Text>
              </View>
              <View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <View
                    style={{
                      flex: 1,
                    }}
                  >
                    <FormSection style={{ marginBottom: 20 }}>
                      <View style={{ marginBottom: 16 }}>
                        <FormikInput
                          fieldName="title"
                          label="Title"
                          placeholder="Enter content title"
                        />
                      </View>
                      <FormikRichText
                        fieldName="description"
                        title="Content"
                        placeholder="Add content here"
                      />
                      <FormikTextArea
                        fieldName="summary"
                        label="Summary Text"
                        maxWordCount={50}
                        placeholder="Add content summary"
                      />
                    </FormSection>
                    <FormSection style={{ marginBottom: 20 }}>
                      <AddAttachments
                        initialExistingFiles={content?.files ?? []}
                        assetInstruction={{
                          instructionType: "one-to-many",
                          collectionId: CollectionName.CONTENT,
                          documentId: contentId,
                          key: "files",
                        }}
                      />
                    </FormSection>
                  </View>
                  <View style={{ marginLeft: 20 }}>
                    <FormikPublishTools withContentType />
                    <FileUploaderWithTitle
                      subtitle="This is the image that will be shown in the overview card"
                      title="Cover image"
                      initialAsset={content?.coverImage ?? null}
                      assetInstruction={{
                        instructionType: "one-to-one",
                        collectionId: CollectionName.CONTENT,
                        documentId: contentId,
                        key: "coverImage",
                      }}
                      formikFieldname="coverImage"
                      validFileTypes={["image/jpeg", "image/png"]}
                    />
                  </View>
                </View>
              </View>
            </>
          );
        }}
      </Formik>
    </View>
  );
};

export { ContentEditor };
