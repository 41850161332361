import { View } from "react-native";
import React from "react";
import { useFormikContext } from "formik";
import { InputFormikContainer } from "@pages/account/profile/form-elements/input-formik/InputFormikContainer";
import { InputFormik } from "@pages/account/profile/form-elements/input-formik/InputFormik";
import { ButtonLayout } from "@pages/account/profile/button-layout/ButtonLayout";

interface FormProps {
  closeEditMode: () => void;
}

const WebsiteForm = ({ closeEditMode }: FormProps) => {
  const { handleSubmit } = useFormikContext();

  const submitCloseHandler = () => {
    handleSubmit();
    closeEditMode();
  };
  return (
    <>
      <InputFormikContainer label="Website">
        <View
          style={{
            flex: 1,
            flexDirection: "row",
          }}
        >
          <InputFormik
            placeholder="Website"
            formikKey="website"
            styleInput={{ flex: 1 / 2 }}
          />
        </View>
      </InputFormikContainer>
      <ButtonLayout
        closeEditMode={closeEditMode}
        submitCloseHander={submitCloseHandler}
      />
    </>
  );
};

export { WebsiteForm };
