import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    backgroundColor: "#fff",
    borderRadius: 8,
    maxHeight: "95%",
    overflow: "scroll",
  },
  upperView: {
    paddingLeft: 16,
    paddingRight: 20,
    paddingVertical: 20,
    flexDirection: "row",
    alignItems: "center",
    borderBottomColor: "#E1E3E5",
    borderBottomWidth: 1,
    justifyContent: "space-between",
  },
  upperViewText: {
    color: "#0D1738",
    fontSize: 20,
    fontFamily: "OpenSans_600SemiBold",
    width: 524,
  },
  footerWrap: {
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 16,
    gap: 10,
    borderTopColor: "#E1E3E5",
    borderTopWidth: 1,
  },
  footerButton: {
    alignItems: "center",
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderRadius: 4,
  },
  footerButtonCancel: {
    borderColor: "#BABFC3",
    borderWidth: 1,
  },
  footerButtonConfirm: {
    backgroundColor: "#0D1738",
  },
  footerButtonText: {
    fontSize: 14,
    fontFamily: "OpenSans_600SemiBold",
    lineHeight: 20,
  },
  footerButtonTextCancel: {
    color: "#202223",
  },
  footerButtonTextConfirm: {
    color: "#fff",
  },
});
