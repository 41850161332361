const subscriptionHierarchy: { [key: string]: string[] } = {
  Free: ["Free", "All"],
  Plus: ["Free", "Plus", "All"],
  Club: ["Free", "Plus", "Club", "All"],
};

export const getIncludedSubscriptionLevels = (userSubscriptionLevel: string) =>
  subscriptionHierarchy[userSubscriptionLevel] || ["Free", "All"];

export const formatAsAlgoliaFilters = (subscriptionLevels: string[]) =>
  subscriptionLevels.map((level) => `userType:${level}`).join(" OR ");
