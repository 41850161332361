import React, { useState } from "react";
import parse from "html-react-parser";
import {
  ImageBackground,
  ImageStyle,
  Platform,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import dayjs from "dayjs";
import { Row } from "@components/general/row";
import { Col } from "@components/general/col";
import {
  INITIALS_CIRCLE_BACKGROUND_COLORS,
  InitialsCircle,
} from "@components/general/initials-circle";
import {
  getInitialsFromName,
  getUniqueCategories,
  pluralize,
} from "@utils/misc";
import { Pill, PillVariant } from "@components/general/pill";
import { StarIcon } from "@components/general/icons/feed-icons";
import { textStyles } from "@styles/text";
import { PageVariant } from "@utils/models";
import { JobRolesWithTooltip } from "../../job-roles-with-tooltip";
import { Tooltip, TooltipWithChild } from "../../tooltip";
import { ids, styles } from "./style";
import { DocumentAsset, Instructor, Speaker } from "@gql/generated/generated";
import { useGetFile } from "@hooks/useGetFileUrl";
import { Image } from "@components/general/image-from-storage";
import { SaveMenu } from "@components/general/save-menu";
import { convertSecondsToDisplay } from "@utils/TIme";
import { TouchableOpacityLink } from "@components/general/touchable-opacity-link";

interface Props {
  onPress: () => void;
  coverImage?: DocumentAsset | null;
  id: string;
  title?: string | null;
  categories: string[];
  date?: number | null;
  speakers?: Speaker[] | Instructor[] | null;
  description?: string | null;
  location?: string | null;
  jobRoles?: string[] | null;
  userType?: string[] | null;
  variant: PageVariant;
  lessons?: number;
  shares?: number | null;
  views?: number | null;
  isCurrentUserEnrolled?: boolean;
  averageStars?: number | null;
  shareCount?: number | null;
  commentCount?: number;
  durationInSeconds?: number | null;
  publishedAt?: number | null;
  type?: string | null;
}

const getIndexPageCardLinkPath = (id: string, variant: PageVariant) => {
  switch (variant) {
    case PageVariant.content:
      return `/content/${id}`;
    case PageVariant.event:
      return `/events/${id}`;
    case PageVariant.training:
      return `/training/${id}`;
    default:
      return "/";
  }
};

export const IndexPageCard = ({
  onPress,
  id,
  title,
  categories,
  date,
  publishedAt,
  location,
  coverImage,
  jobRoles,
  description,
  speakers,
  userType,
  variant,
  lessons,
  shares,
  views,
  isCurrentUserEnrolled,
  averageStars,
  commentCount,
  shareCount,
  durationInSeconds,
  type,
}: Props) => {
  const [isHovered, setIsHovered] = useState(false);
  const userTypeText = userType?.join(" & ");

  const hasSpeakers = speakers && speakers?.length > 0; 
  const hasMultipleSpeakers = hasSpeakers && speakers?.length > 1; 
  const firstSpeaker = hasSpeakers ? speakers?.[0] : null;
  const amountOfSpeakers = speakers?.length ?? 0;
  const speakerText = hasSpeakers ? `${firstSpeaker?.name} ${amountOfSpeakers > 1 ? `+${amountOfSpeakers - 1}` : ""}` : '';
  const [_, ...restSpeakers] = speakers || [];
  const speakerNames = hasSpeakers ? restSpeakers.map((s) => s?.name).join(", ") : '';

  const { fileUrl: coverImageUrl } = useGetFile(coverImage?.storagePath);

  const formattedCategories = getUniqueCategories(categories);

  return (
    <>
      <View
        style={[styles.container, isHovered && styles.hoverShadow]}
        dataSet={{ media: ids.container }}
      >
        <TouchableOpacityLink
          onPress={onPress}
          style={styles.touchableArea}
          // @ts-ignore
          onMouseEnter={() => setIsHovered(true)}
          // @ts-ignore
          onMouseLeave={() => setIsHovered(false)}
          href={getIndexPageCardLinkPath(id, variant)}
        >
          <View style={{ flex: 1 }}>
            <ImageBackground
              source={{
                uri: coverImageUrl,
              }}
              resizeMode="cover"
              style={styles.image}
              imageStyle={styles.roundedImage as ImageStyle}
            >
              <View style={styles.infoSectionTop}>
                <View style={[styles.flexRow, { alignItems: "flex-start" }]}>
                  {formattedCategories ? (
                    <View style={{ marginBottom: 4, marginRight: 4 }}>
                      <Pill text={formattedCategories[0]} />
                    </View>
                  ) : null}
                  {formattedCategories.length > 1 && (
                    <TooltipWithChild
                      toolTipText={formattedCategories.slice(1).join(", ")}
                    >
                      <Pill
                        variant={PillVariant.Light}
                        text={`+ ${formattedCategories.length - 1}`}
                        withMarginRight
                      />
                    </TooltipWithChild>
                  )}
                </View>
                {/* TODO: High fidelity designs no longer have a 'registered' Pill,
                but do show the status of the event (updated/cancelled) */}
                {/* {status ? (
                <View>
                  <Pill text={status} variant={PillVariant.Gold} />
                </View>
              ) : null} */}
                {variant === PageVariant.training && isCurrentUserEnrolled ? (
                  <View>
                    <Pill text="Enrolled" variant={PillVariant.Green} />
                  </View>
                ) : null}
              </View>
              {isHovered ? (
                <View style={styles.viewContainer}>
                  <Text>View</Text>
                </View>
              ) : null}
            </ImageBackground>
            <View style={styles.infoCard}>
              <Col
                style={{
                  flex: 1,
                  justifyContent: "space-between",
                }}
              >
                <View>
                  <View style={[styles.flexRow, styles.topRow]}>
                    {variant !== PageVariant.content ? (
                      <View style={styles.innerRow}>
                        {hasSpeakers && (
                          <>
                            <View
                              style={{
                                position: "absolute",
                              }}
                            >
                              {firstSpeaker!.photo ? (
                                <Image
                                  path={firstSpeaker!.photo.storagePath}
                                  style={{
                                    width: 24,
                                    height: 24,
                                    borderRadius: 20,
                                  }}
                                />
                              ) : (
                                <InitialsCircle
                                  initials={getInitialsFromName(
                                    firstSpeaker?.name || ""
                                  )}
                                  backgroundColor={
                                    INITIALS_CIRCLE_BACKGROUND_COLORS[4]
                                  }
                                  size={24}
                                  fontSize={12}
                                />
                              )}
                            </View>
                            <Tooltip
                              text={speakerText}
                              toolTipText={speakerNames}
                              showTooltip={hasMultipleSpeakers as boolean}
                              textStyles={{
                                ...styles.speakerText,
                                ...textStyles.subduedTextSmall,
                                ...{ marginLeft: 30 },
                              }}
                            />
                          </>
                        )}
                      </View>
                    ) : null}
                    {variant === PageVariant.content ? (
                      <Text style={styles.contentDate}>
                        {dayjs(publishedAt).format("D MMMM YY")}
                      </Text>
                    ) : null}
                    <TouchableOpacity activeOpacity={1}>
                      <SaveMenu
                        activator="Dots"
                        id={id}
                        type={
                          variant === PageVariant.content
                            ? "Content"
                            : variant === PageVariant.event
                            ? "Event"
                            : "Training"
                        }
                        categories={categories}
                        coverImage={coverImageUrl}
                        title={title}
                        additionalDetail={[
                          ...(views
                            ? [`${views} ${pluralize(views, "view")}`]
                            : []),
                          ...(commentCount
                            ? [
                                `${commentCount} ${pluralize(
                                  commentCount,
                                  "comment"
                                )}`,
                              ]
                            : []),
                          ...(shareCount
                            ? [
                                `${shareCount} ${pluralize(
                                  shareCount,
                                  "share"
                                )}`,
                              ]
                            : []),
                        ]}
                      />
                    </TouchableOpacity>
                  </View>
                  <View style={[styles.flexRow, styles.titleBox]}>
                    <Text style={styles.title} numberOfLines={1}>
                      {title}
                    </Text>
                  </View>
                  <Text style={styles.description} numberOfLines={3}>
                    {description && parse(description)}
                  </Text>
                </View>
                <View style={{ display: "flex" }}>
                  {variant === PageVariant.event ? (
                    <>
                      <Text style={styles.dateAndLocationText}>
                        Date & Location:
                      </Text>
                      <Text style={styles.date}>
                        {date
                          ? dayjs(new Date(date)).format("DD MMMM YYYY, h:mma")
                          : ""}
                      </Text>
                    </>
                  ) : null}
                  {variant === PageVariant.training ? (
                    <Text style={styles.date}>
                      {convertSecondsToDisplay(durationInSeconds || 0)}
                      {" • "}
                      {lessons! > 1
                        ? `${lessons} lessons`
                        : `${lessons} lesson`}
                    </Text>
                  ) : null}
                  <Text style={[styles.subduedText, { marginBottom: 20 }]}>
                    {[type, location]
                      .filter((component) => component)
                      .join(" • ")}
                  </Text>
                  {Platform.OS === "web" && jobRoles?.length ? (
                    <JobRolesWithTooltip jobRoles={jobRoles} noMargin />
                  ) : null}
                </View>
              </Col>
            </View>
          </View>
          <View style={styles.bottomContainer}>
            <Row style={styles.centeredRow}>
              {variant === PageVariant.event ? (
                <Text style={styles.statsText}>
                  {views || "0"} {pluralize(views || 0, "view")} •{" "}
                  {shares || "0"} {pluralize(shares || 0, "share")}
                </Text>
              ) : (
                <Row>
                  <StarIcon />
                  <Text style={styles.reviewsText}>
                    {averageStars || 0} ({commentCount ? commentCount : 0}{" "}
                    {pluralize(commentCount || 0, "review")}) •{" "}
                    {shareCount || 0} {pluralize(shareCount || 0, "share")}
                  </Text>
                </Row>
              )}
              {userType?.length ? (
                <Pill variant={PillVariant.LightBlue} text={userTypeText} />
              ) : null}
            </Row>
          </View>
        </TouchableOpacityLink>
      </View>
    </>
  );
};
