import { useAutocompleteLocation } from "@gql/useAutocompleteLocation";
import { useDebounce } from "./useDebounce";

const useGooglePlacesAutocomplete = (options: {
  query: string;
  type: string;
}) => {
  const { query, type } = options;
  const [getAutocomplete, { data }] = useAutocompleteLocation();

  useDebounce(
    () => {
      if (query) {
        getAutocomplete({ variables: { query, type } });
      }
    },
    500,
    [options.query, query]
  );

  return data;
};

export { useGooglePlacesAutocomplete };
