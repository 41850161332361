import { StyleProp, View, ViewStyle } from "react-native";
import React from "react";
import { FormikValues, useFormikContext } from "formik";
import { TextField } from "@shopify/polaris";
import { styles } from "./styles";

interface FormikTextFieldProps {
  formikFieldBase: string;
  formikNestedField: string;
  Icon: any;
  placeholder: string;
  style?: StyleProp<ViewStyle>;
}

const FormikTextField = ({
  formikFieldBase,
  formikNestedField,
  Icon,
  placeholder,
  style,
}: FormikTextFieldProps) => {
  const { setFieldValue, values }: FormikValues = useFormikContext();
  const currentValue = values[formikFieldBase][formikNestedField];
  return (
    <View style={[styles.flexOne, style]}>
      <TextField
        label={`${formikNestedField} Link`}
        labelHidden
        value={currentValue}
        onChange={(value) =>
          setFieldValue(`${formikFieldBase}[${formikNestedField}]`, value)
        }
        placeholder={placeholder}
        autoComplete="off"
        prefix={
          <View>
            <Icon />
          </View>
        }
      />
    </View>
  );
};

export { FormikTextField };
