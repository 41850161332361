import {
  ActivityIndicator,
  Dimensions,
  Image,
  Platform,
  ScrollView,
  Text,
  View,
} from "react-native";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-native";
import { Frame } from "@shopify/polaris";
import RenderHtml from "react-native-render-html";
import dayjs from "dayjs";
import { Row } from "@components/general/row";
import { InfoRow } from "@components/general/info-row";
import { SideListBox } from "@components/general/side-list-box";
import { Pill, PillVariant } from "@components/general/pill";

import { MainBoxWithSidepanel } from "@components/general/layouts/main-box-with-sidepanel/MainBoxWithSidepanel";
import { DownloadIcon, ShareIcon } from "@components/general/icons";
import { Wrapper } from "@components/general/layouts/wrapper/Wrapper";
import { CommentIcon, StarIcon } from "@components/general/icons/feed-icons";
import { ListModal } from "@components/general/modals/list-modal";
import { IndexPageHeader } from "@components/general/index-page-header";
import { HtmlParser } from "@components/general/html-parser/HtmlParser";
import { ModalContent } from "@utils/models";
import { useIndexPageDetail } from "@hooks/useIndexPageDetail";
import { useUserContext } from "@context/UserContext";
import { ids, styles } from "./style";
import { CommentsSection } from "@components/general/comments-section/CommentsSection";
import {
  CollectionName,
  ContentType,
  DocumentAsset,
  ItemType,
  useAddRecentlyInteractedItemMutation,
  useGetContentQuery,
  useGetRelatedContentQuery,
  useIncrementViewCountMutation,
  useUpdateDownloadAssetStatsMutation,
} from "@gql/generated/generated";
import { useAddPageInfoToSession } from "@hooks/useSessionStorage";
import { TooltipWithChild } from "@components/general/tooltip";
import { useGetFile } from "@hooks/useGetFileUrl";
import { useGetFiles } from "@hooks/useGetFiles";
import { formatFileTitle, pluralize } from "@utils/misc";
import { SaveMenu } from "@components/general/save-menu";
import { DownloadFilesButton } from "@components/general/download-files-button";

const Wrap = ({ children }: { children: React.ReactNode }) => {
  if (Platform.OS === "web") {
    return <>{children}</>;
  } else {
    return (
      <ScrollView contentContainerStyle={styles.scrollContent}>
        {children}
      </ScrollView>
    );
  }
};

interface ContentStatsProps {
  icon: React.ReactNode;
  stats: string;
  title: string;
}

const ContentStats = ({ icon, stats, title }: ContentStatsProps) => (
  <View style={styles.contentStatsWrap}>
    <View style={styles.statIconWrap}>
      {icon}
      <Text style={styles.statCount}>{stats}</Text>
    </View>
    <Text style={styles.statTitle}>{title}</Text>
  </View>
);

export const ContentView = () => {
  const { pathname, state } = useLocation();
  const navigation = useNavigate();
  const { id } = useParams() as { id: string };

  const [downloadableFiles, setDownloadableFiles] = useState<
    {
      title: string;
      icon: JSX.Element;
      file: DocumentAsset;
    }[]
  >([]);

  const { currentUser } = useUserContext();
  const navigate = useNavigate();

  const [updateDownloadStats] = useUpdateDownloadAssetStatsMutation();

  const { data, loading: isLoading } = useGetContentQuery({
    variables: { getContentId: id },
    onCompleted: (data) => {
      if (data.getContent.__typename === "ForbiddenError") {
        navigate("/content", { state: "ForbiddenError" });
      }
    },
  });

  const [addRecentlyInteractedItem] = useAddRecentlyInteractedItemMutation();

  const {
    listModalType,
    setListModalType,
    handleItemsSelect,
    handleListModalItems,
  } = useIndexPageDetail();

  const contentData =
    data?.getContent.__typename === "Content" ? data?.getContent : null;

  useAddPageInfoToSession({
    title: contentData?.title,
    type: "content",
    categories: contentData?.categories,
  });

  const [incrementViewCount] = useIncrementViewCountMutation();

  const { data: relatedContent } = useGetRelatedContentQuery({
    variables: {
      filters: { excludeIds: [id], categories: contentData?.categories },
      limit: 6,
    },
    skip: !contentData?.categories,
  });

  const { filesWithUrl } = useGetFiles(contentData?.files);

  useEffect(() => {
    if (currentUser && contentData?.title && id) {
      incrementViewCount({
        variables: { id: state?.id || id, contentType: "Content" },
      });
      addRecentlyInteractedItem({
        variables: {
          input: {
            itemId: id,
            itemType: ItemType.CONTENT,
            itemTitle: contentData.title,
          },
        },
      });
    }
  }, [
    currentUser,
    contentData,
    id,
    incrementViewCount,
    state?.id,
    addRecentlyInteractedItem,
  ]);

  useEffect(() => {
    if (contentData?.files?.length) {
      const newFiles = contentData.files.map((file) => ({
        title: formatFileTitle(file.name, file.size),
        icon: <DownloadIcon />,
        file,
      }));
      setDownloadableFiles(newFiles);
    }
  }, [contentData?.files]);

  const handleDownloadItems = () => {
    switch (listModalType) {
      case ModalContent.download:
        return downloadableFiles;
      default:
        return handleListModalItems(pathname);
    }
  };

  const totalReplies = contentData?.comments?.reduce((acc, item) => {
    if (item.replies?.length) {
      return acc + item.replies?.length;
    }
    return acc;
  }, 0);

  const commentsPlusRepliesLength =
    (contentData?.comments?.length || 0) + (totalReplies || 0);

  const date = contentData?.publishedAt
    ? dayjs(new Date(contentData?.publishedAt)).format("D MMM YYYY")
    : "";

  const { fileUrl } = useGetFile(contentData?.coverImage?.storagePath);

  return (
    <Frame>
      <Wrapper style={styles.wrapper} dataSetMedia={ids.wrapper}>
        {Platform.OS !== "web" ? (
          <IndexPageHeader
            title="Content"
            onPressBack={() => navigation(-1)}
            onSave={() => setListModalType(ModalContent.save)}
            onShare={() => setListModalType(ModalContent.share)}
          />
        ) : null}
        <Wrap>
          <MainBoxWithSidepanel
            mainContent={
              isLoading ? (
                <Row style={styles.loadingContainer}>
                  <ActivityIndicator size="large" color="#202223" />
                </Row>
              ) : (
                <>
                  <Row style={styles.spaceBetween}>
                    <View style={styles.titleWrap}>
                      <Text
                        numberOfLines={5}
                        style={[
                          styles.title,
                          { width: Platform.OS === "web" ? "35vw" : "35%" },
                        ]}
                      >
                        {contentData?.title}
                      </Text>
                      <Text style={styles.subdued}>
                        {date} • {contentData?.readLength} min read
                      </Text>
                    </View>
                    {Platform.OS === "web" ? (
                      <View>
                        <SaveMenu
                          id={id}
                          type="Content"
                          coverImage={fileUrl}
                          title={contentData?.title}
                          additionalDetail={[
                            `${contentData?.numRatings ?? 0} ${pluralize(
                              contentData?.numRatings ?? 0,
                              "review"
                            )}`,
                            `${contentData?.shareCount ?? 0} ${pluralize(
                              contentData?.shareCount ?? 0,
                              "share"
                            )}`,
                          ]}
                          categories={contentData?.categories ?? []}
                        />
                      </View>
                    ) : null}
                  </Row>
                  <View style={styles.ImgContainer}>
                    <Image
                      source={{
                        uri: fileUrl,
                      }}
                      style={styles.image}
                    />
                  </View>
                  <View style={styles.mobileTitleWrap}>
                    <Text numberOfLines={5} style={styles.title}>
                      {contentData?.title}
                    </Text>
                    <Text style={styles.subdued}>
                      {date} • {contentData?.readLength} min read
                    </Text>
                  </View>
                  {Platform.OS === "web" ? (
                    <InfoRow
                      isContent
                      averageRating={contentData?.averageStars}
                      reviewCount={contentData?.numRatings || 0}
                      viewCount={contentData?.views}
                      commentCount={contentData?.commentCount || 0}
                      shareCount={contentData?.shareCount}
                      jobRoles={contentData?.jobRoles}
                      id={contentData?.id}
                      contentType={ContentType.CONTENT}
                      categories={contentData?.categories}
                    />
                  ) : null}
                  {contentData?.description ? (
                    <View
                      style={{
                        marginBottom: 20,
                      }}
                    >
                      {Platform.OS === "web" ? (
                        <HtmlParser htmlString={contentData?.description} htmlStyleWhitelist={['textAlign']} />
                      ) : (
                        // TODO: Test mobile html for parsing video/iframe
                        <RenderHtml
                          source={{ html: contentData?.description }}
                          contentWidth={Dimensions.get("window").width}
                        />
                      )}
                    </View>
                  ) : null}
                  {filesWithUrl && Platform.OS === "web" ? (
                    <View style={{ marginVertical: 40, zIndex: 10 }}>
                      <DownloadFilesButton
                        filesWithUrl={filesWithUrl}
                        onDownload={(file) =>
                          updateDownloadStats({
                            variables: {
                              input: {
                                contentId: id,
                                contentType: "Content",
                                fileUrl: file.downloadUrl,
                                fileTitle: file.name,
                              },
                            },
                          })
                        }
                      />
                    </View>
                  ) : null}
                  {Platform.OS !== "web" ? (
                    <InfoRow
                      averageRating={contentData?.averageStars}
                      reviewCount={contentData?.numRatings ?? 0}
                      viewCount={contentData?.views}
                      commentCount={contentData?.commentCount ?? 0}
                      shareCount={contentData?.shareCount}
                      jobRoles={contentData?.jobRoles}
                      id={contentData?.id}
                      contentType={ContentType.CONTENT}
                    />
                  ) : null}
                  {Platform.OS !== "web" ? (
                    <View style={{ marginVertical: 40, zIndex: 10 }}>
                      <DownloadFilesButton
                        filesWithUrl={filesWithUrl}
                        onDownload={(file) =>
                          updateDownloadStats({
                            variables: {
                              input: {
                                contentId: id,
                                contentType: "Content",
                                fileUrl: file.downloadUrl,
                                fileTitle: file.name,
                              },
                            },
                          })
                        }
                      />
                    </View>
                  ) : null}
                  <CommentsSection
                    commentedItemId={state?.id || id}
                    commentsData={contentData?.comments}
                    commentedItemType={ContentType.CONTENT}
                    collectionName={CollectionName.CONTENT}
                    userHasRated={!!contentData?.userHasCommented}
                    totalComments={contentData?.commentCount}
                  />
                  {Platform.OS !== "web" &&
                  contentData?.comments &&
                  contentData?.comments?.length ? (
                    <View style={styles.separation} />
                  ) : null}
                </>
              )
            }
            sidePanelContent={
              <>
                {relatedContent?.getRelatedContent.length ? (
                  <SideListBox
                    withTime={false}
                    items={relatedContent?.getRelatedContent.map((content) => ({
                      image: content?.coverImage,
                      title: content?.title,
                      date: content?.publishedAt,
                      linkUrl: `/content/${content?.id}`,
                      onPress: () =>
                        navigation(`/content/${content?.id}`, {
                          state: {
                            id: content?.id,
                          },
                        }),
                      bottomRow: (
                        <View style={styles.pillWrap}>
                          {content?.categories
                            ?.slice(0, 1)
                            .map((item: string) => {
                              return (
                                <TooltipWithChild toolTipText={item} key={item}>
                                  <Pill
                                    variant={PillVariant.Slim}
                                    withMarginRight
                                    isCategory
                                  >
                                    <Text numberOfLines={1}>{item}</Text>
                                  </Pill>
                                </TooltipWithChild>
                              );
                            })}
                          {content?.categories &&
                          content.categories.length - 1 !== 0 ? (
                            <TooltipWithChild
                              toolTipText={content?.categories
                                .slice(1)
                                .join(", ")}
                            >
                              <Pill variant={PillVariant.Slim}>
                                <Text>{`+${
                                  content.categories.length - 1
                                }`}</Text>
                              </Pill>
                            </TooltipWithChild>
                          ) : null}
                        </View>
                      ),
                    }))}
                    title="Related Content"
                    linkText="View all Content"
                    linkLocation="/content"
                  />
                ) : null}
              </>
            }
          />
        </Wrap>
        {Platform.OS !== "web" ? (
          <View style={styles.stickyFooter}>
            <ContentStats
              icon={<StarIcon strokeColor="#FFC132" fillColor="#FFC132" />}
              stats={`${
                contentData?.averageStars ? contentData?.averageStars : ""
              }`}
              title={`${contentData?.comments?.length} Reviews`}
            />
            <ContentStats
              icon={<CommentIcon />}
              stats={`${commentsPlusRepliesLength}`}
              title="Comments"
            />
            <ContentStats
              icon={<ShareIcon />}
              stats={(contentData?.shareCount || 0).toString()}
              title="Shares"
            />
          </View>
        ) : null}
        <ListModal
          isVisible={listModalType !== ModalContent.closed}
          items={handleDownloadItems()}
          onClose={() => setListModalType(ModalContent.closed)}
          onSelectItem={handleItemsSelect}
        />
      </Wrapper>
    </Frame>
  );
};
