import { ReactNode, useState } from "react";
import { Linking, Platform, Text, TouchableOpacity, View } from "react-native";
import {
  CommentIcon,
  PostShareIcon,
  StarIcon,
} from "@components/general/icons/feed-icons";
import {
  FacebookIcon,
  LinkedInIcon,
  TwitterIcon,
  WebIcon,
} from "@components/general/icons/social-icons";
import { Rating } from "@components/general/rating";
import { styles } from "./style";
import {
  CollectionName,
  ContentType,
  useCreateRatingMutation,
  useIncrementShareCountMutation,
} from "@gql/generated/generated";
import { calculateNewRoundedAverage, pluralize } from "@utils/misc";
import { useUserContext } from "@context/UserContext";
import { Toast } from "../../../general/toast";

interface RatingProps {
  rating?: number | null;
  onRate: (rate: number) => void;
}

const RatingBox = ({ rating, onRate }: RatingProps) => {
  return (
    <View style={{ ...styles.hiddenBox, width: 132 }}>
      <Rating rating={rating} onRate={onRate} canRate={!rating} />
    </View>
  );
};

interface ShareOptionsProps {
  itemId: string;
  itemType: ContentType;
  onClose: () => void;
  onCopyLink: () => void;
  totalShares: number;
  textToShare: string;
}

const ShareOptions = ({
  itemId,
  itemType,
  onClose,
  onCopyLink,
  totalShares,
  textToShare,
}: ShareOptionsProps) => {
  const [incrementShareCount] = useIncrementShareCountMutation({
    update: (store) => {
      store.modify({
        id: `${ContentType.POST}:${itemId}`,
        fields: {
          shareCount() {
            return totalShares + 1;
          },
        },
      });
    },
  });

  const handlePress = async (url: string) => {
    if (Platform.OS === "web") {
      window.open(url, "_blank");
    } else {
      await Linking.openURL(url);
    }
    if (itemType === ContentType.POST) {
      incrementShareCount({
        variables: { id: itemId, contentType: CollectionName.POSTS },
      });
    }
  };

  const currentLocation = window.location.href;
  const url = `${currentLocation}post/${itemId}`;

  return (
    <View style={{ ...styles.hiddenBox, width: 148, right: 4 }}>
      <TouchableOpacity
        onPress={() => {
          onClose();
          onCopyLink();
          navigator.clipboard.writeText(url);
        }}
      >
        <WebIcon />
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() =>
          handlePress(
            `https://twitter.com/intent/tweet?text=${encodeURIComponent(
              textToShare
            )}`
          )
        }
      >
        <TwitterIcon />
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() =>
          handlePress(
            `https://www.facebook.com/dialog/share?app_id=195007143606310&display=popup&href=${url}`
          )
        }
      >
        <FacebookIcon />
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() =>
          handlePress(
            `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
              url
            )}`
          )
        }
      >
        <LinkedInIcon />
      </TouchableOpacity>
    </View>
  );
};

interface ActionTextProps {
  isActive?: boolean;
  onPress?: () => void;
  icon: ReactNode;
  text: string;
}

const ActionText = ({ isActive, onPress, icon, text }: ActionTextProps) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <TouchableOpacity
      style={[
        styles.actionTouch,
        isActive && styles.ratedActionTouch,
        isHovered && styles.hoverActionTouch,
      ]}
      onPress={onPress}
      // @ts-ignore
      onMouseEnter={() => setIsHovered(true)}
      // @ts-ignore
      onMouseLeave={() => setIsHovered(false)}
    >
      {icon}
      <Text style={styles.actionText}>{text}</Text>
    </TouchableOpacity>
  );
};

export interface Props {
  totalRatings: number;
  postRating: number;
  onPressComment: () => void;
  itemType: ContentType;
  itemId: string;
  totalComments?: number;
  totalShares?: number;
  voteCount?: number;
  timeLeft?: string;
  isRated?: boolean;
  userRating?: number | null;
  collectionName: CollectionName;
  isCommented?: boolean;
  postCaption?: string;
}

const PostFooter = ({
  totalRatings,
  postRating,
  onPressComment,
  itemType,
  itemId,
  totalComments,
  totalShares,
  voteCount,
  timeLeft,
  isRated,
  userRating,
  collectionName,
  isCommented,
  postCaption,
}: Props) => {
  const [isRateVisible, setIsRateVisible] = useState(false);
  const [isShareVisible, setIsShareVisible] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [rating, setRating] = useState(userRating);
  const { currentUser } = useUserContext();

  const [createRating] = useCreateRatingMutation({
    update: (store, { data: rating }) => {
      const newRating = rating?.createRating;
      if (!newRating?.rating) return;
      store.modify({
        id: `${itemType}:${newRating.contentId}`,
        fields: {
          averageStars() {
            return calculateNewRoundedAverage({
              x: totalRatings + 1,
              oldAverage: postRating,
              additionalScore: newRating.rating || 0,
            });
          },
          numRatings() {
            return totalRatings + 1;
          },
          userRating() {
            return newRating.rating || null;
          },
        },
      });
    },
  });
  const handleSubmitRating = async (rate: number) => {
    setRating(rate);
    setTimeout(() => setIsRateVisible(false), 500);
    await createRating({
      variables: {
        input: {
          itemId,
          itemType,
          itemCollectionName: collectionName,
          rating: rate,
        },
      },
      optimisticResponse: (vars) => {
        return {
          createRating: {
            __typename: "Rating",
            id: `${currentUser?.id}-${vars.input.itemId}`,
            rating: vars.input.rating,
            createdAt: Date.now(),
            userId: currentUser?.id || "",
            contentId: vars.input.itemId,
            contentType: vars.input.itemType,
            collectionName: vars.input.itemCollectionName,
          },
        };
      },
    });
  };

  const currentLocation = window.location.href;
  const url = `${currentLocation}post/${itemId}`;

  return (
    <View>
      <Toast
        text="Link copied!"
        active={showToast}
        onDismiss={() => setShowToast(false)}
        duration={4500}
      />
      <View style={styles.postStats}>
        <View style={styles.leftBox}>
          <StarIcon style={styles.yellowStarIcon} />
          <Text style={styles.statsText}>
            {postRating} ({totalRatings} {pluralize(totalRatings, "review")})
          </Text>
        </View>
        <View style={styles.statsWrap}>
          <Text style={styles.statsText} onPress={onPressComment}>
            {totalComments} {pluralize(totalComments ?? 0, "comment")}
          </Text>
          <View style={styles.dot} />

          <Text style={styles.statsText}>
            {totalShares ?? 0} {pluralize(totalShares ?? 0, "share")}
          </Text>

          {voteCount ? (
            <>
              <View style={styles.dot} />
              <Text style={styles.statsText}>
                {voteCount} {pluralize(voteCount, "vote")}
              </Text>
            </>
          ) : null}

          {timeLeft ? (
            <>
              <View style={styles.dot} />
              <Text style={styles.statsText}>{timeLeft}</Text>
            </>
          ) : null}
        </View>
      </View>
      <View style={styles.actionWrap}>
        <View>
          <ActionText
            icon={
              <StarIcon
                strokeColor={isRated ? "#FFC132" : "#6D7175"}
                fillColor={isRated ? "#FFC132" : "transparent"}
              />
            }
            text={isRated ? "Rated" : "Rate"}
            isActive={isRated}
            onPress={() => setIsRateVisible(!isRateVisible)}
          />
          {isRateVisible ? (
            <RatingBox rating={rating} onRate={handleSubmitRating} />
          ) : null}
        </View>
        <ActionText
          icon={<CommentIcon />}
          text="Comment"
          onPress={onPressComment}
          isActive={isCommented}
        />
        <View>
          <ActionText
            icon={<PostShareIcon />}
            text="Share"
            onPress={() => setIsShareVisible(!isShareVisible)}
          />
          {isShareVisible ? (
            <ShareOptions
              itemId={itemId}
              onCopyLink={() => setShowToast(true)}
              onClose={() => setIsShareVisible(false)}
              itemType={itemType}
              totalShares={totalShares || 0}
              textToShare={postCaption + " " + url || ""}
            />
          ) : null}
        </View>
      </View>
    </View>
  );
};

export { PostFooter };
