import { useState } from "react";
import { useNavigate } from "react-router-native";
import dayjs from "dayjs";

import { GenericTableRowItem } from "@components/back-office/tables/generic-table";
import { NonEmptyArray } from "@shopify/polaris/build/ts/latest/src/types";
import { IndexTableHeading } from "@shopify/polaris/build/ts/latest/src/components/IndexTable";

import { isWithinLast30Days } from "@utils/misc";
import {
  useAdminDeleteUserMutation,
  useAdminGetUsersQuery,
  useBulkUpdateMutation,
  useDeleteUserMutation,
} from "@gql/generated/generated";
import {
  StatusTag,
  TagStatus,
} from "@components/back-office/editor/status-tag";
import { AvatarWithName } from "@components/back-office/editor/avatar-with-name";
import { GridPage } from "@components/back-office/grid/GridPage";
import { useQueryFilters } from "@components/back-office/grid/useGridFilters";
import { useToast } from "@context/ToastContext";
import { View } from "react-native";
import { CrossIcon, UserAvatarIcon } from "@components/general/icons";
import { CheckIcon } from "@components/general/icons/training-icons";

const activeFilters = [
  "search",
  "accountStatus",
  "role",
  "userTypes",
  "memberSince",
];

export const UsersBackOffice = () => {
  const [adminDeleteUser] = useAdminDeleteUserMutation();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const { page, filters } = useQueryFilters(activeFilters);

  const {
    data: users,
    loading,
    refetch,
  } = useAdminGetUsersQuery({
    variables: {
      //@ts-ignore
      filters,
      page,
      limit: 10,
    },
  });

  const userData = users?.adminGetUsers;
  const total = users?.adminGetUsers.total;
  const hasNextPage = users?.adminGetUsers.hasNextPage;

  const tableHeadings: NonEmptyArray<IndexTableHeading> = [
    { title: "Name" },
    { title: "Type" },
    { title: "Organization" },
    { title: "Status" },
    { title: "Last Logged in" },
    { title: "Member Type" },
    { title: "Member Since" },
    { title: "" },
  ];

  const rows: GenericTableRowItem[] =
    userData?.users?.map((user, i) => {
      const firstName = user?.accountInfo?.firstName ?? "";
      const lastName = user?.accountInfo?.lastName ?? "";
      const name = `${firstName} ${lastName}`;
      const organization = user?.accountInfo?.companyInfo.name;
      const userType =
        user?.subscriptionDetails?.subscriptionPlanAsText || "Free";

      const getAccountStatus = (status?: string | null) => {
        if (status !== "Active") {
          return {
            status: TagStatus.Default,
            text: status,
          };
        }

        return user?.lastLoggedInDate &&
          isWithinLast30Days(user.lastLoggedInDate)
          ? { status: TagStatus.Active, text: "Active" }
          : { status: TagStatus.Inactive, text: "Inactive" };
      };

      const getUserType = (user: any) => {
        if (user.role.isAdmin) return "Admin";
        if (user.role.isEmployer) return "Employer";
        if (user.role.isUser) return "Employee";
        return "unknown";
      };

      return {
        id: user?.id ?? "",
        title: (
          <AvatarWithName name={name} imageSrc={user?.profilePhotoUrl} i={i} />
        ),
        isAdmin: <View>{getUserType(user)}</View>,
        organization,
        status: (
          <StatusTag
            status={getAccountStatus(user?.status?.accountStatus).status}
          >
            {getAccountStatus(user?.status?.accountStatus).text}
          </StatusTag>
        ),
        lastLogged: dayjs(user?.lastLoggedInDate).format("DD/MM/YYYY"),
        userType,
        memberSince: dayjs(user?.registrationDate).format("DD/MM/YYYY"),
      };
    }) ?? [];

  const handleMenuClick = async (itemId: string, optionID: string) => {
    switch (optionID) {
      case "View": {
        navigate(`/user-editor/${itemId}`);
        break;
      }
      case "Edit": {
        navigate(`/user-editor/${itemId}`);
        break;
      }
      case "Delete":
        await adminDeleteUser({ variables: { id: itemId } });
        addToast("success", "User successfully deleted");
        setTimeout(async () => {
          await refetch();
        }, 2000);
        break;
      default:
        break;
    }
  };

  return (
    <GridPage
      isUsersTable
      title="Users"
      menuItems={[
        { id: "View", content: "View" },
        { id: "Edit", content: "Edit" },
        { id: "Delete", content: "Delete" },
      ]}
      newLink={{
        url: "/user-editor",
        title: "Add New User",
        label: "Add User",
      }}
      activeFilters={activeFilters}
      rows={rows || []}
      loading={loading}
      tableHeadings={tableHeadings}
      onBulkAction={() => refetch()}
      onMenuItem={handleMenuClick}
      pagination={{
        perPage: 10,
        page,
        totalItems: total,
        hasNextPage,
      }}
    />
  );
};
