import {
  ActivityIndicator,
  StyleProp,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";
import { styles } from "./styles";

interface buttonLayoutProps {
  closeEditMode?: () => void;
  submitCloseHander: () => void;
  containerButtonStyle?: StyleProp<ViewStyle>;
  labelCancelButton?: string;
  labelActionButton?: string;
  isSubmitting?: boolean;
}

interface buttonProps {
  label: string;
  onPress: () => void;
  containerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  isSubmitting?: boolean;
}

const GenericButton = ({
  label,
  onPress,
  containerStyle,
  textStyle,
  isSubmitting,
}: buttonProps) => {
  return (
    <TouchableOpacity onPress={onPress} style={containerStyle}>
      {isSubmitting ? (
        <View style={{ paddingHorizontal: 40, paddingVertical: 4 }}>
          <ActivityIndicator color="#ffffff" />
        </View>
      ) : (
        <Text style={textStyle}>{label}</Text>
      )}
    </TouchableOpacity>
  );
};

const ButtonLayout = ({
  closeEditMode,
  submitCloseHander,
  containerButtonStyle,
  labelCancelButton = "Cancel",
  labelActionButton = "Save Changes",
  isSubmitting,
}: buttonLayoutProps) => {
  return (
    <View style={[styles.wrap, containerButtonStyle]}>
      <GenericButton
        label={labelCancelButton}
        onPress={closeEditMode ?? (() => {})}
        textStyle={styles.cancelButton}
        containerStyle={styles.cancelContainer}
      />
      <GenericButton
        label={labelActionButton}
        onPress={submitCloseHander}
        textStyle={styles.saveButton}
        containerStyle={styles.saveContainer}
        isSubmitting={isSubmitting}
      />
    </View>
  );
};

export { ButtonLayout };
