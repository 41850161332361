import { Image, Switch, Text, View } from "react-native";
import { useState } from "react";
import { LegacyStack, RadioButton, Select, TextField } from "@shopify/polaris";
import { Form, useFormikContext } from "formik";

import { styles } from "@styles/BackOffice/editor";
import Pound from "../../../../../assets/img/Pound.png";
import { Row } from "@components/general/row";
import { LocationAutocomplete } from "@components/general/location-autocomplete";
import { textStyles } from "@styles/text";
import { FormikDatePickerDropdown } from "../formik-date-picker-dropdown/FormikDatePickerDropdown";

const hoursOfDay: string[] = [];
const midnight = new Date(new Date().setHours(0, 0, 0, 0)).getHours();

// Loop from current hour number to 23
for (let i = midnight; i < 24; i++) {
  const hours = i < 10 ? "0" + i : i;
  hoursOfDay.push(hours + ":00");
  hoursOfDay.push(hours + ":30");
}

export const FormikEventDetails = () => {
  const { setFieldValue, values, errors, touched } = useFormikContext<any>();
  const [isTicketToggled, setIsTicketToggled] = useState(!!values.ticketPrice);

  const [usingCalendly, setUsingCalendly] = useState(!!values.calendlyLink);
  const [usingGoogleForms, setUsingGoogleForms] = useState(
    !!values.googleFormLink
  );
  const [usingOtherLink, setUsingOtherLink] = useState(!!values.otherLink);
  const [usingInstructions, setUsingInstructions] = useState(
    !!values.instructions
  );

  const activateCalendly = () => {
    setFieldValue("googleFormLink", "");
    setFieldValue("otherLink", "");
    setFieldValue("instructions", "");

    setUsingGoogleForms(false);
    setUsingOtherLink(false);
    setUsingInstructions(false);
    setUsingCalendly(true);
  };

  const activateGoogleForms = () => {
    setFieldValue("calendlyLink", "");
    setFieldValue("otherLink", "");
    setFieldValue("instructions", "");

    setUsingGoogleForms(true);
    setUsingOtherLink(false);
    setUsingInstructions(false);
    setUsingCalendly(false);
  };

  const activateOtherLink = () => {
    setFieldValue("googleFormLink", "");
    setFieldValue("calendlyLink", "");
    setFieldValue("instructions", "");

    setUsingGoogleForms(false);
    setUsingOtherLink(true);
    setUsingInstructions(false);
    setUsingCalendly(false);
  };

  const activateInstructions = () => {
    setFieldValue("googleFormLink", "");
    setFieldValue("otherLink", "");
    setFieldValue("calendlyLink", "");

    setUsingGoogleForms(false);
    setUsingOtherLink(false);
    setUsingInstructions(true);
    setUsingCalendly(false);
  };

  return (
    <View
      style={{
        width: 315,
        borderRadius: 8,
        backgroundColor: "#FFFFFF",
        marginBottom: 20,
        paddingHorizontal: 20,
        paddingBottom: 20,
      }}
    >
      <Form style={styles.EventDetails}>
        <Text
          style={{
            fontFamily: "OpenSans_600SemiBold",
            fontSize: 16,
            lineHeight: 20,
          }}
        >
          Event Details
        </Text>
        <View style={{ marginVertical: 20 }}>
          <Select
            label="Event Type"
            labelHidden
            placeholder="Event type"
            options={[
              {
                label: "Virtual",
                value: "Virtual",
              },
              {
                label: "In-person",
                value: "In-person",
              },
              {
                label: "Hybrid",
                value: "Hybrid",
              },
            ]}
            onChange={(v: string) => setFieldValue("type", v)}
            value={values.type}
          />
          {errors["type"] && touched["type"] && (
            <Text style={textStyles.error}>{errors["type"].toString()}</Text>
          )}
        </View>
        <>
          <View>
            <LegacyStack vertical>
              <RadioButton
                label="This event uses Calendly for registration"
                checked={usingCalendly}
                onChange={(newValue, _) => {
                  if (newValue === true) activateCalendly();
                }}
                id="calendlyRadio"
                name="registrationType"
              />
              <RadioButton
                label="This event uses a Google Form for registration"
                checked={usingGoogleForms}
                onChange={(newValue, _) => {
                  if (newValue === true) activateGoogleForms();
                }}
                id="googleFormRadio"
                name="registrationType"
              />
              <RadioButton
                label="This event uses another link for registration"
                checked={usingOtherLink}
                onChange={(newValue, _) => {
                  if (newValue === true) activateOtherLink();
                }}
                id="otherLinkRadio"
                name="registrationType"
              />
              <RadioButton
                label="This event uses manual instructions for registration"
                checked={usingInstructions}
                onChange={(newValue, _) => {
                  if (newValue === true) activateInstructions();
                }}
                id="instructionsRadio"
                name="registrationType"
              />
            </LegacyStack>
          </View>
          {usingCalendly && (
            <View>
              <Text style={styles.formLabelTextRegistration}>
                Calendly Link
              </Text>
              <TextField
                label="Calendly Link"
                autoComplete="off"
                labelHidden
                value={values.calendlyLink}
                onChange={(value) => setFieldValue("calendlyLink", value)}
              />
            </View>
          )}
          {usingGoogleForms && (
            <View>
              <Text style={styles.formLabelTextRegistration}>
                Google Forms Link
              </Text>
              <TextField
                label="Google Forms Link"
                autoComplete="off"
                labelHidden
                value={values.googleFormLink}
                onChange={(value) => setFieldValue("googleFormLink", value)}
              />
            </View>
          )}
          {usingOtherLink && (
            <View>
              <Text style={styles.formLabelTextRegistration}>
                External Link
              </Text>
              <TextField
                label="Link (will open in a new tab)"
                autoComplete="off"
                labelHidden
                value={values.otherLink}
                onChange={(value) => setFieldValue("otherLink", value)}
              />
            </View>
          )}
          {usingInstructions && (
            <View>
              <Text style={styles.formLabelTextRegistration}>Instructions</Text>
              <TextField
                label="Instructions"
                autoComplete="off"
                labelHidden
                multiline={10}
                value={values.instructions}
                onChange={(value) => setFieldValue("instructions", value)}
              />
            </View>
          )}
        </>
        {values.type === "In-person" || values.type === "Hybrid" ? (
          <>
            <Text style={styles.formLabelText}>Address</Text>
            <View style={{ marginBottom: 12 }}>
              <LocationAutocomplete
                location={values.location}
                setLocation={(v) => setFieldValue("location", v)}
                type="address"
                placeholder="Add address"
              />
            </View>
          </>
        ) : null}
        <View
          style={{
            flexDirection: "column",
            marginBottom: errors["ticketPrice"] ? 0 : 16,
          }}
        >
          <View style={{ ...styles.EventRow, marginBottom: 0 }}>
            <Row style={{ alignItems: "center" }}>
              <Text
                style={{
                  fontFamily: "OpenSans_400Regular",
                  fontSize: 14,
                  lineHeight: 16,
                }}
              >
                Ticket
              </Text>
            </Row>
            <Row style={{ alignItems: "center" }}>
              <Switch
                style={styles.switchBulk}
                trackColor={{
                  false: "#8B9197",
                  true: "#2C6ECB",
                }}
                //@ts-expect-error type
                activeThumbColor="#FFFFFF"
                onValueChange={() => {
                  if (isTicketToggled) {
                    setFieldValue("ticketPrice", "");
                  }
                  setIsTicketToggled(!isTicketToggled);
                }}
                value={isTicketToggled}
              />

              {isTicketToggled ? (
                <View style={{ width: 100, marginLeft: 8 }}>
                  <TextField
                    label="Ticket Price"
                    autoComplete="off"
                    labelHidden
                    prefix={
                      <Image source={Pound} style={{ width: 20, height: 20 }} />
                    }
                    value={values.ticketPrice}
                    onChange={(value) => setFieldValue("ticketPrice", value)}
                  />
                </View>
              ) : null}
            </Row>
          </View>
          {errors["ticketPrice"] && (
            <View style={{ marginBottom: 16 }}>
              <Text style={textStyles.error}>
                {errors["ticketPrice"].toString()}
              </Text>
            </View>
          )}
        </View>

        <View>
          <Text style={styles.formLabelText}>Date</Text>
          <Row style={{ justifyContent: "space-between", marginBottom: 16 }}>
            <View style={{ width: "48%" }}>
              <FormikDatePickerDropdown
                dateValue={values.startDate}
                placeholderText="Start"
                onChange={(v) => setFieldValue("startDate", v.start.getTime())}
              />
              {errors["startDate"] && touched["startDate"] && (
                <Text style={textStyles.error}>
                  {errors["startDate"].toString()}
                </Text>
              )}
            </View>
            <View style={{ width: "48%" }}>
              <FormikDatePickerDropdown
                dateValue={values.endDate}
                placeholderText="End"
                onChange={(v) => setFieldValue("endDate", v.end.getTime())}
              />
              {errors["endDate"] && touched["endDate"] && (
                <Text style={textStyles.error}>
                  {errors["endDate"].toString()}
                </Text>
              )}
            </View>
          </Row>
          <Text style={styles.formLabelText}>Time</Text>
          <Row style={{ marginBottom: 16, justifyContent: "space-between" }}>
            <View style={{ width: "48%" }}>
              <Select
                label="Start"
                labelHidden
                options={hoursOfDay}
                onChange={(v) => setFieldValue("startTime", v)}
                value={values.startTime}
                placeholder="Start"
              />
              {errors["startTime"] && touched["startTime"] && (
                <Text style={textStyles.error}>
                  {errors["startTime"].toString()}
                </Text>
              )}
            </View>
            <View style={{ width: "48%" }}>
              <Select
                label="End"
                labelHidden
                options={hoursOfDay}
                onChange={(v) => setFieldValue("endTime", v)}
                value={values.endTime}
                placeholder="End"
              />
              {errors["endTime"] && touched["endTime"] && (
                <Text style={textStyles.error}>
                  {errors["endTime"].toString()}
                </Text>
              )}
            </View>
          </Row>

          <Text style={styles.formLabelText}>City</Text>
          <View style={{ marginBottom: 12 }}>
            <LocationAutocomplete
              location={values.city}
              setLocation={(v) => setFieldValue("city", v)}
              type="(cities)"
              placeholder="Enter city name"
            />
          </View>
        </View>
      </Form>
    </View>
  );
};
