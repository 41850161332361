import { View } from "react-native";
import { useFormikContext } from "formik";
import { ButtonLayout } from "../../../profile/button-layout/ButtonLayout";
import { InputFormik } from "@pages/account/profile/form-elements/input-formik/InputFormik";
import { InputFormikContainer } from "../../../profile/form-elements/input-formik/InputFormikContainer";

interface formProps {
  closeEditMode: () => void;
}

const CompanyForm = ({ closeEditMode }: formProps) => {
  const { handleSubmit } = useFormikContext();

  const submitCloseHander = () => {
    handleSubmit();
    closeEditMode();
  };

  return (
    <>
      <View
        style={{
          flexDirection: "row",
          flex: 1,
        }}
      >
        <InputFormikContainer
          label="Company Name"
          styleContainer={{
            flex: 1,
            paddingRight: 0,
          }}
        >
          <InputFormik
            formikKey="companyName"
            placeholder="Company"
            styleInput={{
              paddingBottom: 8,
            }}
          />
        </InputFormikContainer>
        <InputFormikContainer
          label="Years in Company"
          styleContainer={{
            flex: 1,
            paddingLeft: 0,
          }}
        >
          <InputFormik
            formikKey="yearsInCompany"
            placeholder="Please enter a number"
          />
        </InputFormikContainer>
      </View>
      <ButtonLayout
        closeEditMode={closeEditMode}
        submitCloseHander={submitCloseHander}
      />
    </>
  );
};

export { CompanyForm };
