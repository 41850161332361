import React, { useState } from "react";
import {
  ActivityIndicator,
  Image,
  Platform,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import Modal from "react-native-modal";
import { Rating } from "@components/general/rating";
import { StarIcon } from "@components/general/icons/feed-icons";
import { Row } from "@components/general/row";
import { useUserContext } from "@context/UserContext";
import {
  ButtonGeneral,
  ButtonVariant,
} from "@components/general/button-general";
import { ids, styles } from "./style";
import { userProfileImage } from "@utils/userProfileImage";
import { CollectionName, Comment, ContentType } from "@gql/generated/generated";
import { pluralize } from "@utils/misc";
import { useComments } from "@hooks/useComments";
import { CommentItem } from "@components/home/post/comment/comment-item";

interface CommentsSectionProps {
  commentsData?: Comment[] | null;
  commentedItemId: string;
  commentedItemType: ContentType;
  collectionName: CollectionName;
  averageStars?: number | null;
  reviewCount?: number;
  userHasRated: boolean;
  totalComments?: number;
}

const CommentsSection = ({
  commentsData,
  commentedItemId,
  commentedItemType,
  collectionName,
  averageStars,
  reviewCount,
  userHasRated,
  totalComments = 0,
}: CommentsSectionProps) => {
  const { currentUser } = useUserContext();
  const [isRateMenuVisible, setIsRateMenuVisible] = useState(false);
  const [comment, setComment] = useState("");
  const [isSubmitted, setSubmitted] = useState(false);
  const [rating, setRating] = useState<number>();

  const userFullName =
    currentUser?.accountInfo?.firstName +
    " " +
    currentUser?.accountInfo?.lastName;

  const {
    createComment,
    fetchMoreComments,
    loadingMoreComments,
    submittingComment,
  } = useComments({
    totalComments,
    commentedItemId,
    commentedItemType,
  });

  const handleSubmitComment = async () => {
    await createComment({
      variables: {
        input: {
          commentedItemId,
          commentedItemType,
          collectionName,
          text: comment,
          rating,
        },
      },
    });

    setComment("");
    setSubmitted(!isSubmitted);
    setIsRateMenuVisible(!isRateMenuVisible);
  };

  const RateCommentBtn = () => (
    <ButtonGeneral
      style={[styles.buttonStyle, styles.fullButton]}
      variant={ButtonVariant.Secondary}
      onPress={() => {
        setIsRateMenuVisible(!isRateMenuVisible);
      }}
      disabled={userHasRated}
    >
      <StarIcon
        strokeColor={isSubmitted ? "#FFC132" : "#202223"}
        fillColor={isSubmitted ? "#FFC132" : "#FFFFFF"}
      />
      <Text style={styles.saveButtonText}>Rate & Comment</Text>
    </ButtonGeneral>
  );

  return (
    <>
      <View
        style={styles.rateButtonWrap}
        dataSet={{ media: ids.rateButtonWrap }}
      >
        {averageStars || reviewCount ? (
          <Row>
            {averageStars ? (
              <Text style={styles.statsText}>{averageStars} Rating</Text>
            ) : null}
            {averageStars && reviewCount ? (
              <Text style={styles.statsText}> • </Text>
            ) : null}
            {reviewCount ? (
              <Text style={styles.statsText}>
                {reviewCount} {pluralize(reviewCount, "Review")}
              </Text>
            ) : null}
          </Row>
        ) : null}

        <RateCommentBtn />
      </View>
      <Modal isVisible={isRateMenuVisible} backdropColor="#0D1738">
        <View
          style={styles.commentModalWrap}
          dataSet={{ media: ids.commentModalWrap }}
        >
          <View
            style={styles.commentModal}
            dataSet={{ media: ids.commentModal }}
          >
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Image
                source={userProfileImage(currentUser)}
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: 50,
                  ...(Platform.OS === "web" && { objectFit: "cover" }),
                }}
              />
              <View style={{ marginLeft: 8, marginBottom: 4 }}>
                <Text style={styles.name}>{userFullName}</Text>
                <Text style={styles.jobTitle}>
                  {currentUser?.accountInfo?.companyInfo?.jobPosition}
                </Text>
              </View>
            </View>
            <View style={styles.ratingContainer}>
              <Rating
                onRate={setRating}
                rating={rating}
                canRate={!userHasRated}
              />
            </View>
            <TextInput
              multiline
              numberOfLines={6}
              placeholder="Add a comment"
              style={styles.commentInput}
              dataSet={{ media: ids.commentInput }}
              value={comment}
              onChangeText={(text) => setComment(text)}
            />
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <ButtonGeneral
                style={{ marginRight: 8 }}
                onPress={() => {
                  setIsRateMenuVisible(!isRateMenuVisible);
                }}
                label="Cancel"
                variant={ButtonVariant.Secondary}
              />
              <ButtonGeneral
                label="Submit"
                variant={ButtonVariant.Primary}
                onPress={handleSubmitComment}
                isLoading={submittingComment}
              />
            </View>
          </View>
        </View>
      </Modal>
      <View style={styles.separation} />
      <View
        style={styles.commentSection}
        dataSet={{ media: ids.commentSection }}
      >
        {commentsData ? <CommentItem comments={commentsData} /> : null}

        {loadingMoreComments ? (
          <ActivityIndicator size="large" color="#213470" />
        ) : null}
        {commentsData &&
        commentsData.length < totalComments &&
        !loadingMoreComments ? (
          <TouchableOpacity
            style={styles.loadMoreTouch}
            onPress={() =>
              fetchMoreComments({
                variables: {
                  input: {
                    collectionName,
                    commentedItemId,
                    limit: 4,
                    offsetId: [...commentsData].pop()?.id,
                  },
                },
              })
            }
          >
            <Text style={styles.loadMore}>Load more comments</Text>
          </TouchableOpacity>
        ) : null}
      </View>
    </>
  );
};

export { CommentsSection };
