import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  rightSideContainer: {
    flexDirection: "row",
    flex: 1,
  },
  image: {
    marginRight: 40,
  },
  paymentInfoContainer: {
    flexDirection: "row",
    marginTop: 2,
  },
  paymentInfo: {
    marginRight: 20,
  },
  paymentInfoTitle: {
    fontSize: 14,
    fontFamily: "OpenSans_600SemiBold",
    color: "#202223",
    lineHeight: 20,
  },
  paymentInfoValue: {
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
    color: "#202223",
    lineHeight: 20,
  },
  editButton: {
    color: "#2C6ECB",
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
  },
});
