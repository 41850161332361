import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  contentBox: {
    marginTop: 20,
  },
  tabHead: {
    padding: 20,
    paddingBottom: 0,
  },
  tabInner: {
    width: "100%",
    borderBottomColor: "#E1E3E5",
    borderBottomWidth: 1,
    flexDirection: "row",
  },
});
