import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const UserFillIcon = (props: SvgProps) => (
  <Svg width={13} height={14} fill="none" {...props}>
    <Path
      fill="#5C5F62"
      d="M6.98 6a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM3.48 14a2.5 2.5 0 0 1-1.768-4.268C2.303 9.155 4.18 8 6.98 8s4.679 1.155 5.268 1.732A2.5 2.5 0 0 1 10.48 14h-7Z"
    />
  </Svg>
);

export { UserFillIcon };
