import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    marginTop: 20,
  },
  wrapPopover: {
    paddingHorizontal: 40,
  },
  popoverTitle: {
    fontFamily: "OpenSans_500Medium",
    fontSize: 17,
    color: "#202223",
  },
});
