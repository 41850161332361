import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  background: {
    backgroundColor: "#FFFFFF",
    height: 1002,
    width: 1158,
    marginTop: 73,
    marginLeft: 36,
    borderRadius: 15,
  },
  mainTitle: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 22,
    lineHeight: 28,
    color: "#0D1738",
    marginTop: 29,
    marginLeft: 29,
    marginBottom: 35,
  },
  title: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 14,
    lineHeight: 24,
    color: "#3B424A",
  },
  headerBtnWrap: {
    display: "flex",
    flexDirection: "row",
    borderColor: "#A3A9B0",
    borderBottomWidth: 1,
    width: 695,
    marginLeft: 29,
  },
  headerBtn: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 12,
    lineHeight: 24,
    color: "#6E757C",
    paddingHorizontal: 18,
    marginRight: 167,
    width: 120,
    textAlign: "center",
  },
  flexWrap: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: 695,
  },
  personalInfo: {
    height: 796,
    width: 695,
    marginTop: 50,
    borderColor: "#ECEEF6",
    borderWidth: 2,
    borderRadius: 20,
    marginLeft: 29,
    paddingTop: 30,
    paddingHorizontal: 33,
  },
  subTitle: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 12,
    lineHeight: 24,
    color: "#6E757C",
    marginBottom: 25,
  },
  separation: {
    borderColor: "#F1F2F8",
    width: 628,
    borderTopWidth: 0.5,
    marginVertical: 25,
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  avatar: {
    width: 99,
    height: 99,
    marginRight: 27,
  },
  coverUpload: {
    backgroundColor: "#F0F2F4",
    borderRadius: 5,
    width: 502,
    height: 87,
    marginTop: 7,
    justifyContent: "center",
    alignItems: "center",
  },
  uploadImgCover: {
    width: 24,
    height: 24,
    marginBottom: 7,
  },
  formatFile: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 9.4,
    lineHeight: 15,
    color: "#6E757C",
  },
  flexRowEnd: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  actionBtn: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 12,
    lineHeight: 15,
    color: "#FFFFFF",
    width: 160,
    height: 38,
    textAlign: "center",
    paddingTop: 12,
    marginTop: 47,
    marginBottom: 45,
    marginLeft: 10,
    backgroundColor: "#8497AD",
    borderRadius: 8,
  },
  flexBetweenRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  confirmBtn: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 10.5,
    lineHeight: 15,
    color: "#FFFFFF",
    backgroundColor: "#AEB5BC",
    borderRadius: 5,
    width: 113,
    height: 25,
    textAlign: "center",
    paddingVertical: 5,
    marginTop: -3,
  },
  site: {
    height: 796,
    width: 391,
    borderColor: "#ECEEF6",
    borderWidth: 2,
    borderRadius: 15,
    marginTop: 50,
    marginLeft: 14,
    paddingVertical: 30,
    paddingHorizontal: 28,
  },
  borderCheckbox: {
    borderColor: "#6E757C",
    borderWidth: 1,
    width: 11,
    height: 11,
    borderRadius: 20,
    marginRight: 3,
    paddingRight: 0,
    marginTop: 2,
  },
  checkbox: {
    borderRadius: 20,
    marginRight: 0,
    paddingRight: 0,
    borderColor: "white",
    width: 7,
    height: 7,
    marginLeft: 1,
    marginTop: 1,
  },
  wrapperCheckbox: {
    display: "flex",
    flexDirection: "row",
    marginLeft: 3,
    marginTop: -2,
  },
  labelCheckbox: {
    fontFamily: "OpenSans_500Medium",
    fontSize: 12,
    lineHeight: 10.5,
    color: "#0D1738",
  },
  paginationWrapper: {
    display: "flex",
    flexDirection: "row",
    marginLeft: 1000,
    position: "absolute",
  },
  paginationLeft: {
    fontFamily: "OpenSans_700Bold",
    fontSize: 12,
    lineHeight: 24,
    color: "#6E757C",
    backgroundColor: "#EDEDED",
    marginRight: 4,
    width: 21,
    height: 22,
    borderRadius: 5,
    textAlign: "center",
  },
  paginationCenter: {
    fontFamily: "OpenSans_700Bold",
    fontSize: 12,
    lineHeight: 24,
    color: "#6E757C",
    width: 21,
    height: 22,
    borderRadius: 5,
    paddingLeft: 7,
  },
  paginationRight: {
    fontFamily: "OpenSans_700Bold",
    fontSize: 12,
    lineHeight: 24,
    color: "#6E757C",
    backgroundColor: "#D9D9D9",
    marginLeft: 4,
    width: 21,
    height: 22,
    borderRadius: 5,
    textAlign: "center",
  },
  avatarBig: {
    width: 61,
    height: 61,
  },
  titleCreate: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 22,
    lineHeight: 28,
    color: "#0D1738",
    marginTop: 15,
    marginLeft: 15,
  },
  detailTitle: {
    fontFamily: "OpenSans_500Medium",
    fontSize: 15,
    lineHeight: 18.15,
    color: "#0D1738",
    marginBottom: 25,
  },
  userRoleImg: {
    width: 12,
    height: 12,
    marginTop: 5,
    marginRight: 6,
  },
  mainBtn: {
    width: 160,
    height: 38,
    backgroundColor: "#8497AD",
    borderRadius: 8,
    marginHorizontal: 5,
    textAlign: "center",
    paddingTop: 12,
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 12,
    lineHeight: 15,
    color: "#FFFFFF",
  },
  rolePill: {
    fontFamily: "OpenSans_500Medium",
    fontSize: 12,
    lineHeight: 21,
    color: "#6E757C",
    backgroundColor: "#F1F1F1",
    borderRadius: 25,
    width: 86,
    height: 21,
    textAlign: "center",
  },
  deleteUserImg: {
    width: 24,
    height: 24,
  },
  deleteText: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 12,
    lineHeight: 15,
    color: "#6E757C",
    marginLeft: 6,
    marginTop: 5,
  },
  generatedPassword: {
    width: 300,
    height: 50,
    borderColor: "#6E757C",
    borderWidth: 1,
    borderBottomWidth: 24,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    fontFamily: "OpenSans_400Regular",
    fontSize: 12,
    lineHeight: 15,
    color: "#6E757C",
    paddingHorizontal: 15,
    paddingVertical: 11,
    marginLeft: 15,
    marginBottom: 25,
  },
  hidePsw: {
    width: 14,
    height: 14,
    position: "absolute",
    marginTop: -68,
    marginLeft: 290,
  },
  pswStrength: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 12,
    lineHeight: 15,
    color: "#FFFFFF",
    position: "absolute",
    marginTop: 62,
    marginLeft: 146,
  },
});
