import * as React from "react";
import Svg, { Path } from "react-native-svg";

const CompleteIcon = () => {
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8ZM12.16 6.56C12.2755 6.40594 12.3316 6.21538 12.318 6.02329C12.3043 5.8312 12.2219 5.65048 12.0857 5.51431C11.9495 5.37815 11.7688 5.29566 11.5767 5.28201C11.3846 5.26836 11.1941 5.32446 11.04 5.44L7.2 9.28L5.36 7.44C5.20594 7.32446 5.01538 7.26836 4.82329 7.28201C4.6312 7.29566 4.45048 7.37815 4.31431 7.51431C4.17815 7.65048 4.09566 7.8312 4.08201 8.02329C4.06836 8.21538 4.12446 8.40594 4.24 8.56L6.64 10.96C6.96 11.28 7.44 11.28 7.76 10.96L12.16 6.56Z"
        fill="#188E77"
      />
    </Svg>
  );
};

export { CompleteIcon };
