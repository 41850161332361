import { useEffect, useMemo, useRef } from "react";
import { ScrollView } from "react-native";
import { useNavigate, useParams } from "react-router-native";
import { useUserContext } from "@context/UserContext";
import {
  ItemType,
  useAddRecentlyInteractedItemMutation,
  useEnrollUserInTrainingMutation,
  useGetAllTrainingsQuery,
  useGetTrainingQuery,
} from "@gql/generated/generated";

const useTrainingDetail = () => {
  const navigate = useNavigate();
  const { currentUser } = useUserContext();

  const scrollRef = useRef<ScrollView>();
  const { id } = useParams() as { id: string };

  const { data, refetch, loading } = useGetTrainingQuery({
    variables: { id },
    onCompleted: (data) => {
      if (data.getTraining.__typename === "ForbiddenError") {
        navigate("/training", { state: "ForbiddenError" });
      }
    },
    fetchPolicy: "cache-and-network",
  });

  const [addRecentlyInteractedItem] = useAddRecentlyInteractedItemMutation();

  const { data: relatedTrainingsData } = useGetAllTrainingsQuery({
    variables: {
      page: 1,
      limit: 3,
      filters: {
        excludeIds: [id],
        categories:
          data?.getTraining.__typename === "Training"
            ? data.getTraining.categories
            : [],
      },
    },
    skip: data?.getTraining.__typename !== "Training",
  });

  const training =
    data?.getTraining.__typename === "Training" ? data?.getTraining : null;

  useEffect(() => {
    if (currentUser && training?.title) {
      addRecentlyInteractedItem({
        variables: {
          input: {
            itemId: id,
            itemType: ItemType.TRAININGS,
            itemTitle: training?.title,
          },
        },
      }).catch(console.error);
    }
  }, [addRecentlyInteractedItem, currentUser, id, training?.title]);

  const relatedTrainings = relatedTrainingsData?.getAllTrainings?.trainings;

  const [enrollUserInTraining, { loading: enrollingUser }] =
    useEnrollUserInTrainingMutation();

  // legacy code to calculate the current lesson in a sequence on the Training page (NOT the Lesson page)
  const currentLesson = useMemo(() => {
    if (!training?.isCurrentUserEnrolled) return null;
    const lessons = training?.modules
      ?.map((module, i) =>
        module.lessons.map((lesson, j) => ({
          moduleId: module.id,
          moduleNumber: i + 1,
          lessonId: lesson.id,
          lessonNumber: j + 1,
        }))
      )
      .flat();

    return lessons?.find(
      ({ lessonId }) =>
        !training?.userProgress?.completedLessons.includes(lessonId)
    );
  }, [
    training?.isCurrentUserEnrolled,
    training?.modules,
    training?.userProgress?.completedLessons,
  ]);

  // calculate the enrol button message - TODO should rename currentLessonTitle to something more representative
  const currentLessonTitle = useMemo(() => {
    if (training?.isCurrentUserEnrolled) {
      if (!training.userProgress?.completedLessons.length) {
        return { message: "Start Course", color: "#1F5199" };
      }
      if (currentLesson) {
        return {
          message: `Continue Module ${currentLesson?.moduleNumber}: Lesson ${currentLesson?.lessonNumber}`,
          color: "#2C6ECB",
        };
      }
      if (!currentLesson) {
        return { message: "Start Again", color: "#0D1738" };
      }
    }
    return { message: "Enrol", color: "#0D1738" };
  }, [
    currentLesson,
    training?.isCurrentUserEnrolled,
    training?.userProgress?.completedLessons.length,
  ]);

  const handlePressEnroll = async () => {
    if (!training?.id) return;

    if (training?.isCurrentUserEnrolled) {
      if (currentLesson) {
        navigate(
          `/training/${training?.id}/lesson/${currentLesson.moduleId}/${currentLesson.lessonId}`
        );
      } else {
        navigate(
          `/training/${training?.id}/lesson/${training.modules?.[0].id}/${training.modules?.[0].lessons?.[0].id}`
        );
      }
    } else {
      await enrollUserInTraining({
        variables: {
          trainingId: training?.id,
        },
      });
    }
  };

  return {
    navigate,
    scrollRef,
    id,
    training,
    refetch,
    loading,
    relatedTrainings,
    handlePressEnroll,
    enrollingUser,
    currentLessonTitle,
    currentLesson,
    userProgressForTraining: training?.userProgress,
  };
};

export { useTrainingDetail };
