import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const TrashIcon = (props: SvgProps) => (
  <Svg width={14} height={15} fill="none" {...props}>
    <Path
      d="M5 1.994C5 .893 5.895 0 7 0s2 .893 2 1.994h4c.552 0 1 .446 1 .997 0 .55-.448.997-1 .997H1c-.552 0-1-.447-1-.997s.448-.997 1-.997h4ZM2 12.508V6h2v6.508a.5.5 0 0 0 .5.498H6V6h2v7.006h1.5a.5.5 0 0 0 .5-.498V6h2v6.508A2.496 2.496 0 0 1 9.5 15h-5C3.12 15 2 13.884 2 12.508Z"
      fill={props.fill}
    />
  </Svg>
);

export { TrashIcon };
