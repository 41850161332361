import { StyleProp, Text, TextStyle, View, ViewStyle } from "react-native";
import { styles } from "./styles";

interface containerProps {
  label?: string;
  styleContainer?: StyleProp<ViewStyle>;
  styleLabel?: StyleProp<TextStyle>;
  children: React.ReactNode;
}

const InputFormikContainer = ({
  styleContainer,
  styleLabel,
  children,
  label,
}: containerProps) => {
  return (
    <View style={[styles.wrap, styleContainer]}>
      {label ? (
        <Text style={[styles.formLabel, styleLabel]}>{label}</Text>
      ) : null}
      {children}
    </View>
  );
};

export { InputFormikContainer };
