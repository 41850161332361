import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { ISvgProps } from "../../../types";

const FacebookIcon = (props: ISvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <Path
      fill={props.color || "#AEB5BC"}
      d="M3 12c0 4.45 3.25 8.15 7.5 8.9l.05-.04-.05-.01V14.5H8.25V12h2.25v-2c0-2.25 1.45-3.5 3.5-3.5.65 0 1.35.1 2 .2V9h-1.15c-1.1 0-1.35.55-1.35 1.25V12h2.4l-.4 2.5h-2v6.35l-.05.01.05.04c4.25-.75 7.5-4.45 7.5-8.9 0-4.95-4.05-9-9-9s-9 4.05-9 9z"
    />
  </Svg>
);

export { FacebookIcon };
