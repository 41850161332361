import React from "react";
import { ActivityIndicator, View } from "react-native";
import { ContentBox } from "@components/general/layouts/content-box";
import { AnalyticsCountBoxes } from "@components/back-office/analytics/analytics-count-boxes";
import { styles } from "./style";
import { BackOfficeAnalyticsTrainingItem } from "@components/back-office/analytics/back-office-analytics-training-item";
import { AnalyticsHeader } from "@components/back-office/analytics/analytics-header";
import { useTrainingAnalytics } from "../hooks/use-training-analytics";

const AnalyticsTraining = () => {
  const { trainingData, trainingLoading, boxItems, dataForExport } =
    useTrainingAnalytics();

  return (
    <>
      <AnalyticsHeader excelData={dataForExport} path="training" />
      <ContentBox>
        <View style={styles.countBoxesWrap}>
          <AnalyticsCountBoxes items={boxItems} />
        </View>
        <View style={styles.wrap}>
          {!trainingData && trainingLoading ? (
            <ActivityIndicator size="large" color="#213470" />
          ) : (
            <BackOfficeAnalyticsTrainingItem training={trainingData} />
          )}
        </View>
      </ContentBox>
    </>
  );
};

export { AnalyticsTraining };
