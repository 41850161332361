import { ButtonVariant } from "@components/general/button-general";

export const variants = {
  primary: {
    variant: ButtonVariant.Primary,
    iconFillColor: "#FFF",
    color: "#FFF",
  },
  secondary: {
    variant: ButtonVariant.Secondary,
    iconFillColor: "#5C5F62",
    color: "#0D1738",
  },
};
export interface ExcelData {
  sheets: Sheet[];
  variant?: keyof typeof variants;
}

export interface Sheet {
  columns: { label: string; value: string }[];
  data: { [key: string]: CellData }[] | undefined;
  name: string;
}

export type CellData = number | string | undefined;
