import { useState } from "react";
import { Linking } from "react-native";

import { useNavigate } from "react-router-native";

import { NonEmptyArray } from "@shopify/polaris/build/ts/latest/src/types";
import { IndexTableHeading } from "@shopify/polaris/build/ts/latest/src/components/IndexTable";
import { getUniqueCategories, truncateString } from "@utils/misc";
import { APP_URL } from "@env";
import {
  useDeletePartnerMutation,
  useGetAllPartnersQuery,
} from "@gql/generated/generated";
import { DeleteConfirmationModal } from "../../../components/back-office/editor/delete-confirmation-modal";
import { AvatarWithName } from "@components/back-office/editor/avatar-with-name";
import { GridPage } from "@components/back-office/grid/GridPage";
import { useQueryFilters } from "@components/back-office/grid/useGridFilters";
import {
  StatusTag,
  TagStatus,
} from "@components/back-office/editor/status-tag";

const activeFilters = [
  "search",
  "status",
  "categories",
  "memberSince",
  "partnerType",
  "specialOffer",
];
const tableHeadings: NonEmptyArray<IndexTableHeading> = [
  { title: "Name" },
  { title: "Category" },
  { title: "Status" },
  { title: "Tier" },
  { title: "" },
];

export const MarketplaceBackOffice = () => {
  const [partnerIdToBeDeleted, setPartnerIdToBeDeleted] = useState<
    string | undefined
  >();
  const navigate = useNavigate();
  const { page, filters } = useQueryFilters(activeFilters);

  const [deletePartner] = useDeletePartnerMutation({
    update: (store, _result, options) => {
      const id = options?.variables?.deletePartnerId;
      if (id) {
        const normalizedId = store.identify({ id, __typename: "Partner" });
        store.evict({ id: normalizedId });
        store.gc();
      }
    },
  });

  const { categories, memberSince, partnerType, search, specialOffer, status } =
    filters;

  const { data, loading, refetch } = useGetAllPartnersQuery({
    variables: {
      page,
      limit: 10,
      filters: {
        categories,
        date: memberSince,
        partnerType,
        search,
        specialOffer,
        status,
      },
    },
  });

  const allPartners = data?.getAllPartners;

  const { partners, hasNextPage, total } = allPartners || {};

  const rowItems = partners || [];

  const rows = rowItems?.map(
    ({ id, logo, title, categories, specialOffer, partnerType }, i) => {
      const specialOfferValue =
        specialOffer?.details !== "" ? (
          <StatusTag status={TagStatus.Critical}>Special Offer</StatusTag>
        ) : (
          ""
        );
      return {
        id,
        title: (
          <AvatarWithName
            imagePath={logo?.storagePath}
            i={i}
            name={title ?? ""}
          />
        ),
        category: truncateString(
          getUniqueCategories(categories).join(", "),
          20
        ),
        specialOffer: specialOfferValue,
        partnerType: partnerType ?? "Free",
      };
    }
  );

  const handleMenuClick = async (itemId: string, optionID: string) => {
    switch (optionID) {
      case "View":
        return Linking.openURL(`${APP_URL}/marketplace/${itemId}` as string);
      case "Edit":
        return navigate(`/marketplace/${itemId}`);
      case "Delete":
        setPartnerIdToBeDeleted(itemId);
        break;
      default:
        break;
    }
  };

  const handlePressDelete = async () => {
    if (partnerIdToBeDeleted) {
      await deletePartner({
        variables: { deletePartnerId: partnerIdToBeDeleted },
      });
    }
  };

  return (
    <>
      <DeleteConfirmationModal
        title="Are you sure you want to delete this partner?"
        onClose={() => setPartnerIdToBeDeleted(undefined)}
        onDeleteConfirm={async () => {
          await handlePressDelete();
          setPartnerIdToBeDeleted(undefined);
        }}
        visible={!!partnerIdToBeDeleted}
      />
      <GridPage
        title="Marketplace"
        menuItems={[
          { id: "View", content: "View" },
          { id: "Edit", content: "Edit" },
          { id: "Delete", content: "Delete" },
        ]}
        newLink={{
          url: "/marketplace/add-partner",
          title: "Add New Partner",
          label: "Add Partner",
        }}
        activeFilters={activeFilters}
        rows={rows || []}
        loading={loading}
        tableHeadings={tableHeadings}
        onBulkAction={() => refetch()}
        onMenuItem={handleMenuClick}
        pagination={{
          perPage: 10,
          page,
          totalItems: total,
          hasNextPage,
        }}
      />
    </>
  );
};
