import { Image, Text, TouchableOpacity, View } from "react-native";
import { styles } from "./style";
import Check from "../../../assets/img/check.png";

export const SettingsCreatedPopup = ({ props }: any) => {
  const {
    setIsBackSettings,
    isPopUpBackSettings,
    isSignedIn,
    isPopUpBackPassword,
    setIsBackPassword,
  } = props;

  return (
    <>
      <TouchableOpacity
        onPress={() => {
          if (isPopUpBackSettings) setIsBackSettings(false);
          if (isPopUpBackPassword) setIsBackPassword(false);
        }}
        style={[
          styles.popupBackGround,
          {
            ...((isPopUpBackSettings || isPopUpBackPassword) &&
              isSignedIn && { overflow: "hidden" }),
          },
        ]}
      />
      <View style={[styles.popup, { alignItems: "center" }]}>
        <Image
          source={Check}
          style={{
            width: 39,
            height: 39,
            marginTop: 50,
            marginBottom: 21,
          }}
        />
        <Text style={[styles.title, { marginTop: 0 }]}>
          {isPopUpBackSettings
            ? "New User has been created!"
            : "Your new password is set!"}
        </Text>
        <Text style={[styles.subTitle, { width: 345 }]}>
          We have sent the new User an email notification with the generated
          password
        </Text>
        <TouchableOpacity
          style={{ marginTop: 75 }}
          onPress={() => {
            if (isPopUpBackSettings) setIsBackSettings(false);
            if (isPopUpBackPassword) setIsBackPassword(false);
          }}
        >
          <Text style={styles.closeBtn}>Close</Text>
        </TouchableOpacity>
      </View>
    </>
  );
};
