import { ActivityIndicator, Text, View } from "react-native";
import { Wrapper } from "@components/general/layouts/wrapper/Wrapper";
import { MainBoxWithSidepanel } from "@components/general/layouts/main-box-with-sidepanel";
import { SideListBox } from "@components/general/side-list-box";
import { MarketplaceIndividualItem } from "@components/market-individual/marketplace-individual-item/MarketplaceIndividualItem";
// import { Pill, PillVariant } from "@components/general/pill";
import { useNavigate, useParams } from "react-router-native";
import { getUniqueCategories } from "@utils/misc";
import { HtmlParser } from "@components/general/html-parser/HtmlParser";
import { styles } from "./styles";
import {
  Partner,
  useGetAllPartnersQuery,
  useGetPartnerQuery,
} from "@gql/generated/generated";

const MarketplaceIndividualView = () => {
  const { id } = useParams() as { id: string };

  const navigate = useNavigate();

  const { data, loading: gettingPartner } = useGetPartnerQuery({
    variables: { getPartnerId: id },
    skip: !id,
  });

  const { data: allPartnersData, loading: loadingPartners } =
    useGetAllPartnersQuery({
      variables: {
        page: 1,
        limit: 5,
        filters: {
          categories: (data?.getPartner as Partner)?.categories || [],
          omitId: id,
        },
      },
      skip: !id,
    });

  const partners =
    allPartnersData?.getAllPartners.__typename === "Partners"
      ? allPartnersData?.getAllPartners.partners
      : [];

  const partner =
    data?.getPartner.__typename === "Partner" ? data.getPartner : null;

  const { links, title, logo, categories, content } = partner || {};

  const { facebook, instagram, linkedin, twitter, website } = links || {};

  if (loadingPartners || gettingPartner) {
    return (
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
        }}
      >
        <ActivityIndicator size="large" color="#213470" />
      </View>
    );
  }

  return (
    <Wrapper
      style={{
        marginTop: 20,
      }}
    >
      <MainBoxWithSidepanel
        mainContent={
          <View>
            <View style={styles.upperSectionContainer}>
              {partner ? (
                <MarketplaceIndividualItem
                  title={title}
                  image={logo}
                  categories={categories}
                  partnerType={partner.partnerType}
                  socialLinks={{
                    facebook,
                    instagram,
                    linkedin,
                    twitter,
                    website,
                  }}
                />
              ) : null}
            </View>
            {content ? <HtmlParser htmlString={content} /> : null}
          </View>
        }
        sidePanelContent={
          partners ? (
            <SideListBox
              title="Related Partners"
              items={partners?.map((item) => ({
                title: item.title,
                image: item.logo,
                hasSpecialOffer: item.specialOffer?.details !== "",
                id: item.id,
                onPress: () => navigate(`/marketplace/${item.id}`),
                bottomRow: (
                  <View style={styles.sidePanelContainer}>
                    {/* // TODO Temp disabling partner types see https://trello.com/c/fSds74pG/311-hide-the-gold-silver-bronze-partnership-tags  */}
                    {/* {item.partnerType && item.partnerType.length > 0 ? (
                      <Pill
                        variant={PillVariant.DarkBlue}
                        text={item.partnerType}
                        withMarginRight
                      />
                    ) : null} */}
                    <Text style={styles.sidePanelText}>
                      {getUniqueCategories(item.categories)[0]}
                    </Text>
                  </View>
                ),
              }))}
              linkLocation="/marketplace"
              linkText="View all Partners"
            />
          ) : null
        }
      />
    </Wrapper>
  );
};

export { MarketplaceIndividualView };
