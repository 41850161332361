import React from "react";
import { Wrapper } from "@components/general/layouts/wrapper/Wrapper";
import { IndexPageHeader } from "@components/general/index-page-header";
import { useNavigate } from "react-router-native";
import { FilterListItem } from "@components/filter-mobile/filter-list-item";
import { Pill, PillVariant } from "@components/general/pill";

const SavedNative = () => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <IndexPageHeader title="Saved" onPressBack={() => navigate(-1)} />
      <FilterListItem
        title="Saved to me"
        onPress={() => navigate("/org/saved/user")}
        rightContent={<Pill text="3" variant={PillVariant.LightBlue} />}
      />
      <FilterListItem
        title="Saved to my organisation"
        onPress={() => navigate("/org/saved/org")}
        rightContent={<Pill text="15" variant={PillVariant.LightBlue} />}
      />
    </Wrapper>
  );
};

export { SavedNative };
