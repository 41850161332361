import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  wrapMedia: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 8,
  },
  mediaText: {
    marginLeft: 8,
    color: "#0D1738",
    lineHeight: 20,
  },
});
