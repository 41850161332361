import React from "react";
import { Switch } from "react-native";
import { FilterListItem } from "@components/filter-mobile/filter-list-item";

interface Props {
  title: string;
  isActive: boolean;
  onChange?: () => void;
  onValueChange?: (value: boolean) => void;
}

const NotificationSwitch = ({
  title,
  isActive,
  onChange,
  onValueChange,
}: Props) => (
  <FilterListItem
    isTextRegular
    title={title}
    rightContent={
      <Switch
        onChange={onChange}
        onValueChange={onValueChange}
        value={isActive}
        trackColor={{ true: "#2C6ECB" }}
      />
    }
  />
);

export { NotificationSwitch };
