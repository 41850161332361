import { useEffect } from "react";

export const useOutsideClick = (callback: () => void) => {
  useEffect(() => {
    const handleClick = () => {
      callback();
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [callback]);
};
