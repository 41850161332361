import { View } from "react-native";
import React from "react";
import { InputFormikContainer } from "@pages/account/profile/form-elements/input-formik/InputFormikContainer";
import { InputFormik } from "@pages/account/profile/form-elements/input-formik/InputFormik";
import { ButtonLayout } from "@pages/account/profile/button-layout/ButtonLayout";
import { useFormikContext } from "formik";

interface FormProps {
  closeEditMode: () => void;
}

const CompanyNameForm = ({ closeEditMode }: FormProps) => {
  const { handleSubmit } = useFormikContext();

  const submitCloseHandler = () => {
    handleSubmit();
    closeEditMode();
  };

  return (
    <>
      <InputFormikContainer label="Company Name">
        <View
          style={{
            flex: 1,
            flexDirection: "row",
          }}
        >
          <InputFormik
            placeholder="Company name"
            formikKey="companyName"
            styleInput={{ flex: 1 / 2 }}
          />
        </View>
      </InputFormikContainer>
      <ButtonLayout
        closeEditMode={closeEditMode}
        submitCloseHander={submitCloseHandler}
      />
    </>
  );
};

export { CompanyNameForm };
