import { ActivityIndicator, View } from "react-native";
import React, { useEffect, useState } from "react";
import { ContentBox } from "@components/general/layouts/content-box";
import { TitleBox } from "@components/account/title-box";
import { useNavigate } from "react-router-native";
import { BusinessInfoDetails } from "@components/org/business-info/BusinessInfoDetails";
import { BusinessInfoEditMode } from "@components/org/business-info/BusinessInfoEditMode";
import { Formik, FormikValues } from "formik";
import { useUserContext } from "@context/UserContext";
import {
  useEmployerGetOrganisationQuery,
  useUpdateOrganisationMutation,
} from "@gql/generated/generated";

const BusinessInfo = () => {
  const navigate = useNavigate();
  const [openEditMode, setOpenEditMode] = useState(false);
  const { currentUser } = useUserContext();
  const initialValues = {
    businessField: [] as string[],
    recruitmentType: [] as string[],
    noOfEmployees: "",
    annualRevenue: "",
    geographyServiced: [] as string[],
    headOfficeArea: "",
    headOfficeCity: "",
    crm: "",
    additionalTechnologies: [] as string[],
    contractorManagementSupport: "",
    marketingResourceOrExpertise: "",
    ninetyDayChallenge: "",
    reasonsForJoining: [] as string[],
  };

  const [updateOrganisation] = useUpdateOrganisationMutation();

  const organisationId = currentUser?.accountInfo?.companyInfo.id;

  const { data: organisationData, loading: fetchingOrganisation } =
    useEmployerGetOrganisationQuery({
      skip: !organisationId,
    });

  const { organisationInfo } = organisationData?.employerGetOrganisation || {};

  const updateOrganisationHandler = async (values: FormikValues) => {
    try {
      const removeIfEmptyArray = (arr: any[]) =>
        arr && arr.length > 0 ? arr : null;

      await updateOrganisation({
        variables: {
          input: {
            ...values,
            // We do not want to update the following fields if they are empty
            businessField: removeIfEmptyArray(values.businessField),
            recruitmentType: removeIfEmptyArray(values.recruitmentType),
            geographyServiced: removeIfEmptyArray(values.geographyServiced),
            additionalTechnologies: removeIfEmptyArray(
              values.additionalTechnologies
            ),
            reasonsForJoining: removeIfEmptyArray(values.reasonsForJoining),
          },
        },
      });
    } catch (error) {
      console.log({ error });
    }
  };

  const onSubmit = async (values: FormikValues) => {
    await updateOrganisationHandler(values);
    setOpenEditMode(false);
  };

  if (fetchingOrganisation) {
    return (
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ActivityIndicator size="large" color="#213470" />
      </View>
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {(props) => {
        if (organisationData) {
          // FIXME: Probably shouldn't do it like this
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            const {
              businessField,
              recruitmentType,
              noOfEmployees,
              annualRevenue,
              geographyServiced,
              headOfficeArea,
              headOfficeCity,
              crm,
              additionalTechnologies,
              contractorManagementSupport,
              marketingResourceOrExpertise,
              ninetyDayChallenge,
              reasonsForJoining,
            } = organisationData.employerGetOrganisation.organisationInfo || {};

            // TODO: fix initialValues types

            props.setValues((prev) => ({
              ...prev,
              ...(businessField && { businessField }),
              ...(recruitmentType && { recruitmentType }),
              ...(noOfEmployees && { noOfEmployees }),
              ...(annualRevenue && { annualRevenue }),
              ...(geographyServiced && { geographyServiced }),
              ...(headOfficeArea && { headOfficeArea }),
              ...(headOfficeCity && { headOfficeCity }),
              ...(crm && { crm }),
              ...(additionalTechnologies && { additionalTechnologies }),
              ...(contractorManagementSupport && {
                contractorManagementSupport,
              }),
              ...(marketingResourceOrExpertise && {
                marketingResourceOrExpertise,
              }),
              ...(ninetyDayChallenge && { ninetyDayChallenge }),
              reasonsForJoining: reasonsForJoining || [],
            }));
          }, [organisationData]);
        }

        return (
          <ContentBox style={{ marginBottom: 20 }}>
            <TitleBox
              title="Business Info"
              backTitle="Back To Settings"
              onPressBack={() => navigate(-1)}
              hasBottomBorder
            />
            {organisationInfo ? (
              <>
                {!openEditMode ? (
                  <BusinessInfoDetails
                    onPress={() => setOpenEditMode(true)}
                    organisationInfo={organisationInfo}
                  />
                ) : (
                  <BusinessInfoEditMode
                    closeEditMode={() => setOpenEditMode(false)}
                  />
                )}
              </>
            ) : null}
          </ContentBox>
        );
      }}
    </Formik>
  );
};

export { BusinessInfo };
