import { View } from "react-native";
import { useFormikContext } from "formik";
import { ButtonLayout } from "../../button-layout/ButtonLayout";
import { InputFormik } from "../../form-elements/input-formik/InputFormik";
import { InputFormikContainer } from "../../form-elements/input-formik/InputFormikContainer";

interface formProps {
  closeEditMode: () => void;
}

const NameForm = ({ closeEditMode }: formProps) => {
  const { handleSubmit } = useFormikContext();

  const submitCloseHander = () => {
    handleSubmit();
    closeEditMode();
  };

  return (
    <>
      <InputFormikContainer label="Name">
        <View
          style={{
            flexDirection: "row",
          }}
        >
          <InputFormik formikKey="firstName" placeholder="First Name" />
          <InputFormik formikKey="lastName" placeholder="Last Name" />
        </View>
      </InputFormikContainer>
      <ButtonLayout
        closeEditMode={closeEditMode}
        submitCloseHander={submitCloseHander}
      />
    </>
  );
};

export { NameForm };
