import { Checkbox } from "@shopify/polaris";

export enum PartnerTypes {
  Gold = "Gold",
  Silver = "Silver",
  Bronze = "Bronze",
  Media = "Media",
  Industry = "Industry",
}

export const isPartnerType = (
  partnerType: string
): partnerType is keyof typeof PartnerTypes => partnerType in PartnerTypes;

export const filterValidPartnerTypes = (
  partnerTypes: string[]
): (keyof typeof PartnerTypes)[] => partnerTypes.filter(isPartnerType);

export const partnerTypeFilterCheckboxList = (
  partnerTypeMultiSelectFilter?: (keyof typeof PartnerTypes)[],
  onChange?: (partnerType: PartnerTypes) => void
) =>
  Object.entries(PartnerTypes).map(([key, value]) => ({
    id: key as keyof typeof PartnerTypes,
    content: value,
    prefix: (
      <Checkbox
        label={value}
        labelHidden
        checked={
          isPartnerType(key) && partnerTypeMultiSelectFilter?.includes(key)
        }
        onChange={() => onChange?.(value)}
      />
    ),
  }));
