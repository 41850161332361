import { useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { FormikCategoriesDropdown } from "./FormikCategoriesDropdown";
import { styles } from "./style";

export type Tab = "All" | "Content" | "Events" | "Training";

const tabs: Tab[] = ["All", "Content", "Events", "Training"];

const FormikCategoriesAvailable = () => {
  const [tabActive, setTabActive] = useState<Tab>("All");

  const handlePress = (tab: Tab) => {
    setTabActive(tab);
  };

  return (
    // main container
    <View>
      <Text style={styles.headerSectionTitle}>Categories Available</Text>
      {/* header */}
      <View style={styles.tabsContainer}>
        {tabs.map((tab) => (
          <TouchableOpacity
            key={tab}
            style={styles.tabButton}
            onPress={() => handlePress(tab)}
          >
            <Text
              style={
                tabActive === tab
                  ? styles.tabButtonTextActive
                  : styles.tabButtonText
              }
            >
              {tab}
            </Text>
            {tabActive === tab ? <View style={styles.tabButtonActive} /> : null}
          </TouchableOpacity>
        ))}
      </View>
      {/* content */}
      <View style={{ margin: 20 }}>
        <FormikCategoriesDropdown tabActive={tabActive} />
      </View>
    </View>
  );
};

export { FormikCategoriesAvailable };
