import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  profilePhoto: {
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    flex: 1,
    marginBottom: 16,
    backgroundColor: "#FFFFFF",
  },
});
