import StyleSheet from "react-native-media-query";
import { Platform } from "react-native";
import { mediaQuery } from "@utils/misc";

export const { ids, styles } = StyleSheet.create({
  countBoxesWrap: {
    padding: 16,
    paddingBottom: 0,
  },
  split: {
    width: "100%",
    flexDirection: "row",
    ...(Platform.OS === "web" && { gap: 16, overflowX: "scroll" }),
    padding: 16,
    [mediaQuery.forTabletDown]: {
      flexDirection: "column",
    },
  },
  leftItem: {
    width: "100%",
  },
  topBox: {
    marginBottom: 16,
  },
});
