import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const LikeIcon = (props: SvgProps) => (
  <Svg width={16} height={16} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.198 14H8.302L6 12.465V6.277l2.563-4.273A.502.502 0 0 1 9 2.5V6a1 1 0 0 0 1 1h3.753l-1.555 7Zm3.582-8.625A1 1 0 0 0 15 5h-4V2.5C11 1.122 9.879 0 8.5 0H8a1 1 0 0 0-.858.485L4.433 5H1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h3.697l2.748 1.832c.164.109.357.168.555.168h5a1 1 0 0 0 .976-.783l2-9a1 1 0 0 0-.196-.842Z"
      fill="#6D7175"
    />
  </Svg>
);

export { LikeIcon };
