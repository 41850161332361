import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  subtitle: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 14,
    color: "#6E757C",
  },
  registerLink: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 16,
    color: "#2C6ECB",
  },
  loginTouch: {
    marginTop: 16,
  },
});
