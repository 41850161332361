import { ActivityIndicator, View } from "react-native";
import { RoadmapWrapper } from "@components/roadmap/roadmap-wrapper";
import { PlanBlock } from "@components/roadmap/plan-block";
import { FormModal } from "@pages/roadmap/form-modal";
import { useRoadmapSubmodules } from "@pages/roadmap/hooks/useRoadmapSubmodules";
import { useUserContext } from "@context/UserContext";
import { ids, styles } from "./style";
import {
  getCurrentRoadmapItemsFromData,
  getNonEmptyAnswersPercentage,
  getQuestionAndAnswerCount,
} from "../../utils";
import { useEmployerGetRoadmapQuery } from "@gql/generated/generated";
import { roadmapSectionToSubmodule } from "@utils/roadmapSectionToSubmodule";

const PulseIndex = () => {
  const {
    submodule,
    isVisible,
    handlePlay,
    setIsVisible,
  } = useRoadmapSubmodules();

  const { currentUser, loadingCurrentUser } = useUserContext();

  const orgId = currentUser?.accountInfo?.companyInfo?.id;
  const { data, loading } = useEmployerGetRoadmapQuery({
    skip: !orgId,
  });

  const { pulseSections, currentPulse } =
    (data?.employerGetRoadmap &&
      getCurrentRoadmapItemsFromData(data?.employerGetRoadmap)) ||
    {};

  const pulseSectionsData =
    pulseSections && getQuestionAndAnswerCount(pulseSections);

  if (loadingCurrentUser || loading) {
    return (
      <View
        style={{
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ActivityIndicator size="large" color="#213470" />
      </View>
    );
  }

  return (
    <RoadmapWrapper
      title="Pulse"
      totalAnswered={pulseSectionsData?.answerCount ?? 0}
      totalQuestion={pulseSectionsData?.questionCount ?? 0}
      hasSeeResultsButton={currentPulse?.percentageComplete === "100"}
    >
      <View style={styles.wrap} dataSet={{ media: ids.wrap }}>
        {pulseSections &&
          pulseSections.map((section) => {
            const percentageComplete = getNonEmptyAnswersPercentage(
              section.questions
            );

            return (
              <PlanBlock
                key={section.id}
                title={section.name ?? ""}
                percent={Number(percentageComplete)}
                totalQuestion={section.questions.length}
                onPlay={() => {
                  const submodule = roadmapSectionToSubmodule(section);
                  if (submodule) {
                    handlePlay(submodule);
                  }
                }}
              />
            );
          })}
      </View>
      {submodule && data?.employerGetRoadmap?.id && (
        <FormModal
          isVisible={isVisible}
          onClose={() => setIsVisible(false)}
          submodule={submodule}
          roadmapSection="pulse"
        />
      )}
    </RoadmapWrapper>
  );
};

export { PulseIndex };
