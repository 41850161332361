import React, { useState } from "react";
import { Linking, Modal, Text, View } from "react-native";
import { styles } from "./style";
import { DotsSaveMenu } from "@components/general/dots-save-menu";
import { StarIcon } from "@components/general/icons/feed-icons";
import { ModalContainer } from "@components/org/saved/modals/ModalContainer";
import { GenericModalLayout } from "@components/org/saved/modals/generic-modal-layout/GenericModalLayout";
import { ModalChildren } from "./ModalChildren";
import { truncateString } from "../../../../utils/misc";
import { PillRowWithTooltip } from "../../../general/pill-row-with-tooltip";
import { APP_URL } from "@env";
import { TooltipWithChild } from "@components/general/tooltip";
import { Image } from "@components/general/image-from-storage";
import { DocumentAsset } from "@gql/generated/generated";

interface Props {
  coverImage?: DocumentAsset;
  category: string[];
  title: string;
  description: string;
  duration?: string;
  instructor: string;
  jobRoles: string;
  statistic: string;
  userType: string;
  type: string;
  id: string;
  sectionId: string;
  score: string;
  handlePressRemove: (options: any) => void;
}

export const ContentCard = ({
  coverImage,
  category,
  title,
  description,
  duration,
  instructor,
  jobRoles,
  statistic,
  userType,
  type,
  id,
  sectionId,
  score,
  handlePressRemove,
}: Props) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Modal visible={showModal} transparent>
        <ModalContainer>
          <GenericModalLayout
            title="Are you sure you want to remove this content?"
            closeModal={() => setShowModal(false)}
            hasButtonLayout
            buttonName="Remove"
            onActionButtonPress={() => {
              handlePressRemove({
                variables: {
                  input: {
                    sectionId,
                    recommendations: [{ itemId: id, type, score: +score }],
                  },
                },
              });
              setShowModal(false);
            }}
            withCancel
          >
            <ModalChildren
              coverImage={coverImage}
              title={title}
              description={description}
              furtherInfo={statistic}
            />
          </GenericModalLayout>
        </ModalContainer>
      </Modal>
      <View style={{ marginTop: 16 }}>
        <View style={styles.cardContainer}>
          {/* image */}
          <View style={styles.imageContainer}>
            <Image path={coverImage?.storagePath} style={styles.image} />

            <View style={styles.imageTagContainer}>
              <PillRowWithTooltip
                shortenedItems={category
                  ?.slice(0, 2)
                  .map((el) => el.split(":")[0])}
                items={category}
              />
            </View>
          </View>
          {/* content-card */}
          <View style={styles.contentContainer}>
            <View style={styles.contentContainerInfo}>
              <View>
                <Text style={[styles.textBoldDark, { marginBottom: 4 }]}>
                  {title}
                </Text>
                <Text style={styles.textRegularGraySmall} numberOfLines={2}>
                  {description}
                </Text>
              </View>
              {duration && (
                <Text style={styles.textBoldDarkSmallBlue}>{duration}</Text>
              )}
              {/* job role */}
              <View>
                <View style={styles.jobInfoContainer}>
                  <View
                    style={{
                      flexDirection: "row",
                      marginRight: 8,
                      alignItems: "center",
                    }}
                  >
                    <View style={styles.initialsContainer}>
                      <Text style={styles.textBoldDarkSmallWhite}>P</Text>
                      <Text style={styles.textBoldDarkSmallWhite}>I</Text>
                    </View>
                    <Text style={styles.textRegularGraySmall}>
                      {instructor}
                    </Text>
                  </View>
                  <View>
                    <Text style={styles.textBoldDarkSmall}>
                      <TooltipWithChild toolTipText={`${jobRoles}`}>
                        <Text numberOfLines={2}>
                          Job Role: {truncateString(jobRoles, 35)}
                        </Text>
                      </TooltipWithChild>
                    </Text>
                  </View>
                </View>
                {/* start icon line */}
                <View style={styles.statisticContainer}>
                  <StarIcon />
                  <Text style={styles.textRegularGraySmall}>{statistic}</Text>
                  <Text
                    style={[
                      styles.textTag,
                      {
                        color: "#FFFFFF",
                        marginLeft: 8,
                        backgroundColor: "#2C6ECB",
                      },
                    ]}
                  >
                    {userType}
                  </Text>
                </View>
              </View>
            </View>

            {/* button */}

            <DotsSaveMenu
              actionItems={[
                {
                  content: "View",
                  onAction: () =>
                    Linking.openURL(
                      `${APP_URL}/${
                        type === "content" ? "content" : "training"
                      }/${id}`
                    ),
                },
                {
                  content: "Remove",
                  destructive: true,
                  onAction: () => setShowModal(true),
                },
              ]}
            />
          </View>
        </View>
      </View>
    </>
  );
};
