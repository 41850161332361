import StyleSheet from "react-native-media-query";
import { Platform } from "react-native";
import { mediaQuery } from "@utils/misc";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    ...(Platform.OS === "web" && { gap: 20 }),
    [mediaQuery.forTabletDown]: {
      justifyContent: "center",
    },
  },
});
