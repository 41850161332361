import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    borderBottomWidth: 1,
    borderBottomColor: "#EDEEEF",
  },
  wrapAssigmentPage: {
    borderTopWidth: 1,
    borderTopColor: "#EDEEEF",
  },
  wrapWithDropdown: {
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
  },
  tabWrap: {
    flexDirection: "row",
    marginLeft: 40,
  },

  tabItem: {
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    padding: 16,
    gap: 4,
  },
  tabItemActive: {
    backgroundColor: "#2C6ECB",
    height: 3,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  tabTitleWrap: {
    flexDirection: "row",
    gap: 4,
    justifyContent: "space-between",
    alignItems: "center",
  },
  tabItemTitle: {
    color: "#0D1738",
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
    height: 22,
  },
  tabItemNotificationWrap: {
    backgroundColor: "#E4E5E7",
    paddingVertical: 2,
    paddingHorizontal: 8,
    borderRadius: 10,
  },
  tabItemNotificationText: {
    color: "#0D1738",
    fontSize: 13,
    fontFamily: "OpenSans_400Regular",
  },
  dropDownWrap: {
    paddingHorizontal: 40,
  },
  dropDown: {
    paddingHorizontal: 12,
    borderWidth: 1,
    borderColor: "#BABFC3",
    borderRadius: 4,
    height: 36,
  },
  dropDownSelected: {
    color: "#0D1738",
    fontFamily: "OpenSans_400Regular",
    fontSize: 14,
    width: 120,
  },
  dropDownIcon: {
    marginLeft: 8,
    width: 20,
  },
  dropDownContainerMenu: {
    borderRadius: 8,
    paddingHorizontal: 4,
    paddingVertical: 4,
  },
  dropDownItem: {
    color: "#0D1738",
    fontFamily: "OpenSans_400Regular",
    fontSize: 14,
    padding: 0,
  },
  dropDownItemActive: {
    borderRadius: 8,
  },
});
