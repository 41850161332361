import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

interface Props extends SvgProps {
  strokeColor?: string;
  fillColor?: string;
}

const StarIcon = (props: Props) => {
  const svgProps = { ...props };
  delete svgProps.strokeColor;
  delete svgProps.fillColor;
  return (
    <Svg width={16} height={16} fill="none" {...svgProps}>
      <Path
        d="M8.409 13.162 4.057 15.42a.119.119 0 0 1-.171-.125l.802-4.836a.198.198 0 0 0-.056-.173L1.14 6.845a.119.119 0 0 1 .066-.202l4.847-.732a.198.198 0 0 0 .147-.107L8.394 1.42a.119.119 0 0 1 .212 0L10.8 5.804a.198.198 0 0 0 .147.107l4.848.732a.119.119 0 0 1 .065.202l-3.492 3.44a.198.198 0 0 0-.056.174l.803 4.836a.119.119 0 0 1-.172.125L8.59 13.162a.198.198 0 0 0-.182 0Z"
        fill={props.fillColor || "#FFC132"}
        stroke={props.strokeColor || "#FFC132"}
        strokeWidth={1.5}
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export { StarIcon };
