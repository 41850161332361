import { TableDropdown } from "@components/back-office/tables/table-dropdown";
import { pastTensify } from "@utils/misc";
import { useQueryFilters } from "../useGridFilters";

const statusItems = [
  { id: "Publish", content: "Published" },
  { id: "Draft", content: "Drafted" },
  { id: "Archive", content: "Archived" },
];

export const StatusFilter = () => {
  const { filters, setFilterValue } = useQueryFilters(["status"]);

  const status = filters?.status;

  return (
    <TableDropdown
      placeholder={status ? pastTensify(status) : "Status"}
      items={statusItems}
      onSelectItem={(id) => setFilterValue("status", id)}
      onReset={() => setFilterValue("status", undefined)}
      isSelected={!!filters.status}
    />
  );
};
