import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const ArrowDown = (props: SvgProps) => (
  <Svg width={12} height={7} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 7a.997.997 0 0 1-.707-.293l-5-5A.999.999 0 1 1 1.707.293L6 4.586 10.293.293a.999.999 0 1 1 1.414 1.414l-5 5A.997.997 0 0 1 6 7Z"
      fill="#0D1738"
    />
  </Svg>
);

export { ArrowDown };
