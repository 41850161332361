import StyleSheet from "react-native-media-query";
import { Platform } from "react-native";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    flexDirection: "row",
    alignItems: "center",
  },
  checkbox: {
    alignItems: "center",
    justifyContent: "center",
    width: 18,
    height: 18,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#8C9196",
  },
  checked: {
    borderColor: "#2C6ECB",
    backgroundColor: "#2C6ECB",
  },
  label: {
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
    color: "#202223",
    marginLeft: 9,
  },
  multipleCheckboxes: {
    flexDirection: "row",
    flexWrap: "wrap",
    ...(Platform.OS === "web" && { gap: 12 }),
  },
});
