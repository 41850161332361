import StyleSheet from "react-native-media-query";
import { Platform } from "react-native";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: "100%",
    ...(Platform.OS === "web" && { position: "sticky", top: "90px" }),
  },
  thumbnail: {
    width: "100%",
    height: 195,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    alignItems: "center",
    justifyContent: "center",
  },
  videoContainer: {
    width: "100%",
    backgroundColor: "#000",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  outerVideo: {
    width: "100%",
    position: "relative",
  },
  playButton: {
    height: 45,
    width: 45,
    borderRadius: 22.5,
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderColor: "#B5B5B5",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    left: "43%",
    top: "40%",
  },
  playIconWrap: {
    height: 45,
    width: 45,
    borderRadius: 22.5,
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderColor: "#B5B5B5",
    alignItems: "center",
    justifyContent: "center",
  },
  infoSection: {
    backgroundColor: "#F6F6F7",
    padding: 16,
  },
  infoInner: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  iconItemWrap: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 16,
  },
  iconItemText: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 14,
    color: "#0D1738",
    marginLeft: 20,
  },
  shareWrap: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 20,
  },
  shareText: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 12,
    color: "#0D1738",
    marginRight: 10,
  },
  shareItem: {
    marginLeft: 8,
  },
  webVideo: {
    height: "100%",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
  },
  innerVideo: {
    position: "relative",
    backgroundColor: "black",
    width: "100%",
  },
  saveBtnPlusImg: {
    width: 12,
    height: 12,
    marginRight: 8,
  },
  saveBtnTextBtn: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 14,
    color: "#202223",
    lineHeight: 20,
  },
  courseEnrolledBanner: {
    backgroundColor: "#8ACBC873",
    borderRadius: 35,
    paddingHorizontal: 12,
    paddingVertical: 10,
    marginBottom: 12,
    flexDirection: "row",
    alignItems: "center",
  },
  courseEnrolledText: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 14,
    lineHeight: 16,
    color: "#2C6ECB",
    marginLeft: 4,
  },
  courseCompletedBanner: {
    backgroundColor: "#E5EFFD",
    borderRadius: 35,
    paddingHorizontal: 12,
    paddingVertical: 10,
    marginBottom: 12,
    flexDirection: "row",
    alignItems: "center",
  },
  courseCompletedText: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 14,
    lineHeight: 16,
    color: "#33A5A1",
    marginLeft: 4,
  },
  savedText: {
    color: "#1653CE",
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 14,
    lineHeight: 20,
  },
});
