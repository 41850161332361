import { Platform } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

export const useNativeSafeAreaInsets = Platform.select({
  native: useSafeAreaInsets,
  default: () => ({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  }),
});
