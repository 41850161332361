import React, { useCallback, useEffect } from "react";
import { Frame } from "@shopify/polaris";
import {
  ActivityIndicator,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
  View,
} from "react-native";
import { RightSidePanel } from "@components/home/right-side-panel";
import { Feed } from "@components/home/feed";
import { LeftSidePanel } from "@components/home/left-side-panel";
import { Wrapper } from "@components/general/layouts/wrapper/Wrapper";
import { ids, styles } from "./style";
import { useGetFeedQuery } from "@gql/generated/generated";

const LIMIT = 10;

export const Home = () => {
  const {
    data: feedData,
    loading: loadingFeed,
    fetchMore,
  } = useGetFeedQuery({
    variables: { limit: LIMIT },
    notifyOnNetworkStatusChange: true,
  });

  const feedItems = feedData?.getFeed.feedItems.items || [];
  const totalFeedItems = feedData?.getFeed.feedItems.count ?? 0;

  const lastFeedItem = [...feedItems].pop();

  const triggerPagination = useCallback(() => {
    if (loadingFeed) return;
    fetchMore({
      variables: {
        lastFeedId: lastFeedItem?.id,
        limit: LIMIT,
      },
    });
  }, [fetchMore, lastFeedItem?.id, loadingFeed]);

  const handleScroll = useCallback(() => {
    if (
      Math.ceil(document.documentElement.scrollTop +
        document.documentElement.offsetHeight) >=
        document.documentElement.scrollHeight &&
      totalFeedItems > feedItems.length
    ) {
      triggerPagination();
    }
  }, [feedItems.length, totalFeedItems, triggerPagination]);

  const onFeedFlatListScroll = (
    event: NativeSyntheticEvent<NativeScrollEvent>
  ) => {
    const contentHeight = event.nativeEvent.contentSize.height;
    const contentScrollY =
      event.nativeEvent.contentOffset.y +
      event.nativeEvent.layoutMeasurement.height;
    if (contentScrollY >= contentHeight) {
      triggerPagination();
    }
  };

  useEffect(() => {
    if (Platform.OS !== "web") return;
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const upcomingEvents = feedData?.getFeed?.upcomingEvents;
  const newsItems = feedData?.getFeed?.newsItems;
  const marketplaceItems = feedData?.getFeed?.marketplaceItems;

  if (loadingFeed && feedItems?.length === 0) {
    return (
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
        }}
      >
        <ActivityIndicator size="large" color="#213470" />
      </View>
    );
  }
  return (
    <Frame>
      <Wrapper style={styles.container}>
        <View style={styles.leftWrap} dataSet={{ media: ids.leftWrap }}>
          <LeftSidePanel marketplaceItems={marketplaceItems || []} />
        </View>
        <Feed
          onFlatListScroll={onFeedFlatListScroll}
          feedItems={feedItems || []}
          loadingMoreFeedItems={loadingFeed && feedItems?.length > 0}
        />
        <View style={styles.rightWrap} dataSet={{ media: ids.rightWrap }}>
          <RightSidePanel upcomingEvents={upcomingEvents} newsItems={newsItems} />
        </View>
      </Wrapper>
    </Frame>
  );
};
