import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  content: {
    width: 263,
  },
  head: {
    width: "100%",
    padding: 20,
    flexDirection: "row",
    alignItems: "center",
  },
  image: {
    height: 60,
    width: 60,
    borderRadius: 51,
    borderWidth: 1,
    borderColor: "#DFE4EA",
    marginRight: 16,
  },
  memberBox: {
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
    backgroundColor: "#2C6ECB",
    borderRadius: 15,
    flexDirection: "row",
    alignItems: "flex-start",
    alignSelf: "flex-start",
  },
  memberText: {
    color: "#FFFFFF",
  },
  boldText: {
    fontSize: 14,
    fontFamily: "OpenSans_600SemiBold",
    color: "#202223",
  },
  boldLink: {
    fontSize: 12,
    fontFamily: "OpenSans_600SemiBold",
  },
  normalText: {
    fontSize: 12,
    fontFamily: "OpenSans_400Regular",
    color: "#6D7175",
  },
  name: {
    marginBottom: 4,
  },
  menuItemWrap: {
    borderTopWidth: 1,
    borderTopColor: "#EDEEEF",
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 20,
    paddingRight: 20,
  },
  menuTitleWrap: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 4,
  },
  linkText: {
    color: "#2C6ECB",
  },
  underlineLink: {
    textDecorationLine: "underline",
  },
  navItem: {
    borderTopWidth: 1,
    borderTopColor: "#EDEEEF",
    padding: 20,
    flexDirection: "row",
    alignItems: "center",
  },
  navItemTitle: {
    fontSize: 14,
    color: "#202223",
    fontFamily: "OpenSans_400Regular",
    marginLeft: 10,
  },
  occupationText: {
    marginBottom: 4,
  },
  assignmentIcon: {
    marginLeft: -2,
    marginRight: -2,
  },
});
