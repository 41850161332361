import React, { useState } from "react";
import { FilterWrapper } from "@components/filter-mobile/filter-wrapper";
import { FilterDatePicker } from "@components/filter-mobile/filter-date-picker/FilterDatePicker";

interface Props {
  isVisible: boolean;
  onClose: () => void;
  onSave: (dateValue: { from?: Date; to?: Date }) => void;
}

const Date = ({ isVisible, onClose, onSave }: Props) => {
  const [from, setFrom] = useState<Date>();
  const [to, setTo] = useState<Date>();
  return (
    <FilterWrapper
      headerTitle="Date"
      isVisible={isVisible}
      onClose={onClose}
      buttonTitle="Save"
      isDoneButtonDisabled={!from || !to}
      onDone={() => onSave({ from, to })}
    >
      <FilterDatePicker
        label="From"
        placeholder="Choose a date"
        value={from}
        onChange={(date) => setFrom(date)}
      />
      <FilterDatePicker
        label="To"
        placeholder="Choose a date"
        value={to}
        onChange={(date) => setTo(date)}
      />
    </FilterWrapper>
  );
};

export { Date };
