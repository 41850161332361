import { Tooltip as PolarisTooltip } from "@shopify/polaris";
import { useState } from "react";
import { Platform, View, Text } from "react-native";

export const TooltipWithChild = ({
  toolTipText,
  width = 200,
  children,
}: {
  toolTipText?: string | null;
  width?: number;
  children: React.ReactNode;
}) => {
  if (!toolTipText) return null;
  if (Platform.OS === "web") {
    return (
      <View>
        <PolarisTooltip
          content={
            <View style={{ width }}>
              <Text>{toolTipText}</Text>
            </View>
          }
        >
          {children}
        </PolarisTooltip>
      </View>
    );
  }
  return <View>{children}</View>;
};

const Tooltip = ({
  text,
  toolTipText = text,
  width = 200,
  showTooltip,
  textStyles,
}: {
  text?: string | null;
  textStyles?: any;
  toolTipText?: string | null;
  width?: number;
  showTooltip?: boolean;
}) => {
  const [textWidth, setTextWidth] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);

  if (!text) return null;
  if (Platform.OS === "web") {
    if (textWidth >= containerWidth || showTooltip) {
      return (
        <PolarisTooltip
          content={
            <View style={{ width }}>
              <Text>{toolTipText}</Text>
            </View>
          }
        >
          <View
            style={{ flex: 1 }}
            onLayout={(e) => setContainerWidth(e.nativeEvent.layout.width)}
          >
            <Text
              style={{ maxWidth: "fit-content", ...textStyles }}
              numberOfLines={1}
              onLayout={(e) => setTextWidth(e.nativeEvent.layout.width)}
            >
              {text}
            </Text>
          </View>
        </PolarisTooltip>
      );
    }
    return (
      <View style={{ flex: 1 }}>
        <Text
          style={{ maxWidth: "fit-content", ...textStyles }}
          numberOfLines={1}
        >
          {text}
        </Text>
      </View>
    );
  }
  return <Text numberOfLines={1}>{text}</Text>;
};

export { Tooltip };
