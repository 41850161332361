import React from "react";
import { IndexTable, LegacyCard } from "@shopify/polaris";
import { TableDropdown } from "@components/back-office/tables/table-dropdown";
import { NonEmptyArray } from "@shopify/polaris/build/ts/latest/src/types";
import { IndexTableHeading } from "@shopify/polaris/build/ts/latest/src/components/IndexTable";
import { SelectionType } from "@shopify/polaris/build/ts/latest/src/utilities/index-provider";
import { Text as NativeText, View } from "react-native";

import { styles } from "./style";

export interface GenericTableRowItem {
  id: string;
  [key: string]: React.ReactNode;
}

interface MenuItem {
  id: string;
  content: string;
}

interface Props {
  headings: NonEmptyArray<IndexTableHeading>;
  rows: GenericTableRowItem[];
  resourceName:
    | {
        singular: string;
        plural: string;
      }
    | undefined;
  handleMenuClick?: (id: string, optionId: string) => void;
  selectedResources?: string[];
  allResourcesSelected?: boolean;
  handleSelectionChange?: (
    selectionType: SelectionType,
    isSelecting: boolean,
    selection?: string | any | undefined
  ) => void;
  isBulk?: boolean;
  withCheckbox?: boolean;
  withButton?: boolean;
  currentPage?: number;
  itemsPerPage?: number;
  totalItems?: number;
  customMenuItems?: MenuItem[];
}

export const defaultMenuItems = [
  { id: "View", content: "View" },
  { id: "Edit", content: "Edit" },
  { id: "Delete", content: "Delete" },
];

const GenericTable = ({
  headings,
  rows,
  resourceName,
  handleMenuClick,
  selectedResources,
  allResourcesSelected,
  currentPage,
  handleSelectionChange,
  itemsPerPage = 11,
  totalItems,
  withCheckbox = true,
  withButton = true,
  customMenuItems,
}: Props) => {
  const firstItemOnPage = currentPage
    ? (currentPage - 1) * itemsPerPage + 1
    : 1;
  const lastItemOnPage = currentPage
    ? Math.min((currentPage - 1) * itemsPerPage + itemsPerPage, totalItems ?? 0)
    : 0;

  const rowMarkup = rows.map(({ id, ...data }, index) => {
    return (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources?.includes(id)}
        position={index}
        onClick={() => {
          // close any active popovers
          document.body.click();
        }}
      >
        {Object.values(data).map((cell, i) => (
          <IndexTable.Cell key={`${i}-${id}`}>{cell}</IndexTable.Cell>
        ))}
        {withButton ? (
          <IndexTable.Cell>
            <TableDropdown
              disableDisclosure
              items={customMenuItems ? customMenuItems : defaultMenuItems}
              onSelectItem={(optionID) => handleMenuClick!(id, optionID)}
              paddingHorizontal={20}
              ellipsisIcon
            />
          </IndexTable.Cell>
        ) : null}
      </IndexTable.Row>
    );
  });

  return (
    <LegacyCard>
      <IndexTable
        resourceName={resourceName}
        itemCount={rows.length}
        selectedItemsCount={
          allResourcesSelected ? "All" : selectedResources?.length
        }
        onSelectionChange={(...props) => {
          // close any active popovers
          document.body.click();
          handleSelectionChange && handleSelectionChange(...props);
        }}
        headings={headings}
        selectable={withCheckbox}
      >
        {rowMarkup}
      </IndexTable>
      {currentPage && totalItems && (
        <View style={styles.resultsFooter}>
          <NativeText style={styles.subduedText}>
            Showing {firstItemOnPage} - {lastItemOnPage} of {totalItems} results
          </NativeText>
        </View>
      )}
    </LegacyCard>
  );
};

export { GenericTable };
