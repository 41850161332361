import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  textBoldGray: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 12,
    lineHeight: 20,
    color: "#6D7175",
  },
  textBoldDark: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 14,
    lineHeight: 20,
    color: "#0D1738",
  },
  textRegularDark: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 14,
    lineHeight: 20,
    color: "#0D1738",
  },
  tableWrapper: {
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderTopColor: "#F6F6F7",
    borderLeftColor: "#F6F6F7",
    borderRightColor: "#F6F6F7",
    borderRadius: 8,
    marginBottom: 16,
    shadowColor: "#000000",
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.1,
    shadowRadius: 8,
    elevation: 8,
  },
  moduleRowContainer: {
    paddingHorizontal: 20,
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#E1E3E5",
  },

  scrollView: {
    maxHeight: 420,
  },
  answersRowContainer: {
    paddingHorizontal: 20,
    paddingVertical: 10,
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 8,
  },
  answerQuestionField: {
    flexBasis: 400,
    alignSelf: "flex-start",
    flexDirection: "row",
    marginRight: 20,
    marginBottom: 8,
  },
  btnContainer: {
    paddingVertical: 16,
    width: 120,
  },
  btnStyle: {
    flex: 1,
    flexDirection: "row",
    borderRadius: 4,
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderWidth: 1,
    borderColor: "#8C9196",
  },
});
