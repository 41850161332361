import React, { useRef } from "react";
import { Field, FieldProps } from "formik";
import { Platform, Text, View } from "react-native";
import { REACT_APP_TINY_API_KEY } from "@env";
import { textStyles } from "@styles/text";

interface FormikRichTextProps {
  fieldName: string;
  title: string;
  placeholder: string;
  subtitle?: string;
  toolbar?: string[];
}

let Editor: any;

Platform.select({
  native: () => (Editor = <></>),
  default: () => (Editor = require("@tinymce/tinymce-react").Editor),
})();

const FormikRichText = ({
  fieldName,
  title,
  subtitle,
  placeholder,
  toolbar = [
    "undo redo | casechange blocks | bold italic underline backcolor | ",
    "alignleft aligncenter alignright alignjustify | ",
    "link image media | ",
    "table bullist numlist checklist outdent indent | removeformat | a11ycheck code help",
  ],
}: FormikRichTextProps) => {
  const editorRef = useRef(null);
  return (
    <>
      <Text style={[textStyles.semiBoldSmall, { marginBottom: 4 }]}>
        {title}
      </Text>
      {subtitle ? (
        <Text style={[textStyles.subduedTextMedium, { marginBottom: 4 }]}>
          {subtitle}
        </Text>
      ) : null}
      <View
        style={{
          marginBottom: 16,
        }}
      >
        <Field name={fieldName}>
          {({
            field,
            form: { setFieldValue, errors, touched, setFieldTouched },
          }: FieldProps) => {
            return (
              <>
                <Editor
                  apiKey={REACT_APP_TINY_API_KEY}
                  onInit={(evt: any, editor: any) =>
                    (editorRef.current = editor)
                  }
                  value={field.value}
                  onEditorChange={(e: any) => {
                    setFieldValue(fieldName, e);
                  }}
                  onFocus={() => setFieldTouched(fieldName, true)}
                  init={{
                    min_height: 645,
                    menubar: false,
                    plugins: [
                      "advlist",
                      "autolink",
                      "lists",
                      "link",
                      "image",
                      "charmap",
                      "preview",
                      "anchor",
                      "searchreplace",
                      "visualblocks",
                      "fullscreen",
                      "insertdatetime",
                      "media",
                      "table",
                      "help",
                      "wordcount",
                    ],
                    toolbar: toolbar.join(" "),
                    content_style:
                      "body {font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    placeholder,
                  }}
                />
                {errors[fieldName] && touched[fieldName] && (
                  <Text style={textStyles.error}>
                    {errors[fieldName]?.toString()}
                  </Text>
                )}
              </>
            );
          }}
        </Field>
      </View>
    </>
  );
};

export { FormikRichText };
