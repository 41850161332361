import { Image, Platform, Text, TouchableOpacity, View } from "react-native";
import { useState } from "react";
import { CheckBox } from "@components/checkbox";

import { styles } from "./style";
import { DropdownTopLabel } from "../../general/dropdown-top-label";
import { FieldTopLabel } from "../../general/field-top-label";
import Avatar from "../../../../assets/img/Avatar.png";
import uploadImg from "../../../../assets/img/Upload.png";
import { DropdownCountryList } from "@components/general/dropdown-country-list";

let FileUploader: any;

Platform.select({
  native: () => (FileUploader = <></>),
  default: () => (FileUploader = require("react-drag-drop-files").FileUploader),
})();

const dateFormats: string[] = [
  "Month Date, yyyy",
  "dd/mm/yyyy",
  "mm/dd/yyyy",
  "yyyy/mm/dd",
];

const timeFormats: string[] = ["09:23 pm", "09:23 PM", "21:23 pm"];

const weekDays = [
  {
    label: "Monday",
    value: "Monday",
  },
  {
    label: "Tuesday",
    value: "Tuesday",
  },
  {
    label: "Wednesday",
    value: "Wednesday",
  },
  {
    label: "Thursday",
    value: "Thursday",
  },
  {
    label: "Friday",
    value: "Friday",
  },
  {
    label: "Saturday",
    value: "Saturday",
  },
  {
    label: "Sunday",
    value: "Sunday",
  },
];

export const ProfileSettings = ({ fromEditor }: any) => {
  const [country, setCountry] = useState<string>("");
  const [file, setFile] = useState(null);
  const handleChange = (file: any) => {
    setFile(file);
  };

  return (
    <View style={styles.flexRow}>
      <View style={styles.personalInfo}>
        <Text style={styles.title}>Personal Info</Text>
        <Text style={styles.subTitle}>Update your personal details here</Text>
        <View style={styles.flexWrap}>
          <FieldTopLabel label="First Name *" size={306.5} />
          <FieldTopLabel label="Last Name *" size={306.5} />
          <FieldTopLabel label="Email Address *" size={306.5} />
          <FieldTopLabel label="Phone number" size={306.5} />
          <FieldTopLabel label="House no." size={306.5} />
          <FieldTopLabel label="Address 1" size={306.5} />
          <FieldTopLabel label="Address 2 (optional)" size={306.5} />
          <FieldTopLabel label="Postcode" size={306.5} />
          <FieldTopLabel label="City *" size={306.5} />
          <DropdownCountryList
            label="Country *"
            size={306.5}
            value={country}
            onChange={(c: string) => setCountry(c)}
          />
        </View>
        <View style={styles.separation} />
        <Text style={styles.title}>Your photo</Text>
        <Text style={styles.subTitle}>
          This will be displayed on your profile
        </Text>
        <View style={styles.flexRow}>
          <Image source={Avatar} style={styles.avatar} />
          <FileUploader
            handleChange={handleChange}
            types={["svg", "jpg", "png"]}
            children={
              <View style={styles.coverUpload}>
                <Image source={uploadImg} style={styles.uploadImgCover} />
                <Text style={styles.formatFile}>
                  Click here to upload, or drag and drop
                </Text>
                <Text style={styles.formatFile}>
                  SVG, JPG or PNG (max. 400px x 400px)
                </Text>
              </View>
            }
          />
        </View>
        <View style={styles.flexRowEnd}>
          <TouchableOpacity>
            <Text style={styles.actionBtn}>Cancel</Text>
          </TouchableOpacity>
          <TouchableOpacity>
            <Text style={styles.actionBtn}>Save</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.separation} />
        <Text style={styles.title}>Delete account</Text>
        <View style={styles.flexBetweenRow}>
          <Text style={styles.subTitle}>
            If you want to delete your account as an admin, please assign your
            role to another user.
          </Text>
          <TouchableOpacity>
            <Text style={styles.confirmBtn}>Confirm</Text>
          </TouchableOpacity>
        </View>
      </View>
      <View style={styles.site}>
        <Text style={styles.title}>Site</Text>
        <Text style={styles.subTitle}>Update your website details here</Text>
        <FieldTopLabel label="Site Title *" size={328} />
        <FieldTopLabel label="Tagline" size={328} multiline={2} />
        <FieldTopLabel label="Site Address *" size={328} />
        <FieldTopLabel label="Time Zone" size={83} />
        <View style={[styles.separation, { width: 332 }]} />
        <Text style={styles.title}>Format</Text>
        <Text style={[styles.subTitle, { marginTop: 10, marginBottom: 7 }]}>
          Date Format
        </Text>
        {dateFormats.map((date: string) => {
          return (
            <View style={[styles.wrapperCheckbox, { marginBottom: 15 }]}>
              <View style={styles.borderCheckbox}>
                {/* @ts-ignore FIXME: bad component */}
                <CheckBox
                  boxStyle={styles.checkbox}
                  colorActive="#6E757C"
                  value
                  onChangeValue={() => console.error("Not implemented")}
                />
              </View>
              <Text
                style={[styles.labelCheckbox, { marginTop: 3, marginLeft: 4 }]}
              >
                {date}
              </Text>
            </View>
          );
        })}
        <Text style={[styles.subTitle, { marginBottom: 10 }]}>Time Format</Text>
        {timeFormats.map((time: string) => {
          return (
            <View style={[styles.wrapperCheckbox, { marginBottom: 15 }]}>
              <View style={styles.borderCheckbox}>
                {/* @ts-ignore FIXME: bad component */}
                <CheckBox
                  boxStyle={styles.checkbox}
                  colorActive="#6E757C"
                  value
                  onChangeValue={() => console.error("Not implemented")}
                />
              </View>
              <Text
                style={[styles.labelCheckbox, { marginTop: 3, marginLeft: 4 }]}
              >
                {time}
              </Text>
            </View>
          );
        })}
        <View style={styles.flexRow}>
          <Text style={[styles.labelCheckbox, { marginTop: 20 }]}>
            {`Week starts on:   `}
          </Text>
          <View style={{ marginTop: 7 }}>
            <DropdownTopLabel
              label="="
              size={136}
              options={weekDays}
              heightContainer={100}
            />
          </View>
        </View>
        <View
          style={[styles.flexRow, { justifyContent: "center", marginTop: -10 }]}
        >
          <TouchableOpacity>
            <Text style={styles.actionBtn}>Cancel</Text>
          </TouchableOpacity>
          <TouchableOpacity>
            <Text style={styles.actionBtn}>Save</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};
