import { Text, View } from "react-native";
import { styles } from "./style";

export const SideCardWithTitle = ({
  title,
  subtitle,
  children,
}: {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
}) => {
  return (
    <View style={styles.container}>
      <Text style={[styles.subTitle]}>{title}</Text>
      {subtitle ? <Text style={[styles.text]}>{subtitle}</Text> : null}
      {children}
    </View>
  );
};
