import { Text, TouchableOpacity, View } from "react-native";
import { InputError } from "@components/general/form/input-error";
import { CheckIcon } from "@components/general/icons/account-icons/CheckIcon";
import { styles } from "./style";

export interface Props {
  value: boolean | string;
  onChange: (value?: string) => void;
  label?: string;
  error?: string;
  options?: { label: string; value: string }[];
}

interface CheckBoxItemProps {
  value: boolean;
  onChange: () => void;
  label?: string;
}

const CheckBoxItem = ({ value, label, onChange }: CheckBoxItemProps) => (
  <TouchableOpacity style={styles.wrap} onPress={onChange}>
    <View style={[styles.checkbox, value && styles.checked]}>
      {value && <CheckIcon />}
    </View>
    {label ? <Text style={styles.label}>{label}</Text> : null}
  </TouchableOpacity>
);

interface MultipleCheckboxProps {
  options: { label: string; value: string }[];
  value: string;
  onChange: (value: string) => void;
}

const MultipleCheckbox = ({
  options,
  value,
  onChange,
}: MultipleCheckboxProps) => {
  const values = value.split(",");

  const handleChange = (val: string) => {
    const index = values.indexOf(val);
    if (index !== -1) {
      const newValues = [...values];
      newValues.splice(index, 1);
      onChange(newValues.toString());
    } else {
      const newValues = [...values, val];
      onChange(newValues.toString());
    }
  };
  return (
    <View style={styles.multipleCheckboxes}>
      {options.map((item) => (
        <CheckBoxItem
          value={values.includes(item.value)}
          onChange={() => handleChange(item.value)}
          label={item.label}
        />
      ))}
    </View>
  );
};

const Checkbox = ({ value, onChange, label, error, options }: Props) => (
  <View>
    {options?.length ? (
      <MultipleCheckbox
        options={options}
        value={value as string}
        onChange={(value) => onChange(value)}
      />
    ) : (
      <CheckBoxItem value={Boolean(value)} onChange={onChange} label={label} />
    )}
    {error ? <InputError error={error} /> : null}
  </View>
);

export { Checkbox };
