import React, { useState } from "react";
import { Text, View } from "react-native";
import { ContentBox } from "@components/general/layouts/content-box";
import { TitleBox } from "@components/account/title-box";
import { useNavigate } from "react-router-native";
import {
  ButtonGeneral,
  ButtonVariant,
} from "@components/general/button-general";
import { CompleteModal } from "@components/general/modals/complete-modal";
import { styles } from "./style";
import { NewRequest } from "@components/account/new-request/NewRequest";

const ContactSupport = () => {
  const navigate = useNavigate();
  const [isSubscribed] = useState(true);
  const [isCompleteModalVisible, setIsCompleteModalVisible] = useState(false);

  // FIXME: Not implemented
  const _onSubmit = () => {
    setIsCompleteModalVisible(true);
  };

  const handleBackToHelp = () => {
    setIsCompleteModalVisible(false);
    navigate(-1);
  };

  return (
    <View style={styles.wrap}>
      <ContentBox>
        <TitleBox
          hasBottomBorder
          title="Contact our support team"
          backTitle="Back to Help"
          onPressBack={() => navigate(-1)}
        />
        <>
          {isSubscribed ? (
            <NewRequest isRequest={false} />
          ) : (
            <View style={styles.content}>
              <Text style={styles.description}>
                Become a full member of The Recruitment Network Club and get
                access to our full support.
              </Text>
              <Text style={styles.description}>
                For any questions please contact
                enquiries@thereceruitmentnetwork.com Or call us at at
                800.232.8284
              </Text>
              <View style={styles.buttonWrap}>
                <ButtonGeneral
                  label="Subscription Plan"
                  variant={ButtonVariant.Secondary}
                />
              </View>
            </View>
          )}
        </>
      </ContentBox>
      <CompleteModal
        isVisible={isCompleteModalVisible}
        onClose={() => setIsCompleteModalVisible(false)}
        title="Your request has been successfully submitted!"
        description="Our experts will work on your issue and get back to you within 24 hours."
        secondaryButtonTitle="Back to Help"
        onSecondaryButtonPress={handleBackToHelp}
        primaryButtonTitle="Go to Dashboard"
        onPrimaryButtonPress={() => setIsCompleteModalVisible(false)}
      />
    </View>
  );
};

export { ContactSupport };
