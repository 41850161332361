import React, { useState } from "react";
import { Image, Text, View } from "react-native";
import { SelectOption } from "@components/general/form/select-option";
import { ContentBox } from "@components/general/layouts/content-box";
import { ids, styles } from "./style";
import { PageStat } from "@gql/generated/generated";
import { fromSecondsToHoursOrMins } from "@utils/trainings";
import { capitalizeFirstLetter } from "@utils/misc";

const options = [
  { label: "This Week", value: "8" },
  { label: "Last 15 days", value: "16" },
];

const TableHead = () => (
  <View style={styles.cellWrap}>
    <View style={styles.rowOne} />
    <View style={styles.rowTwo}>
      <Text style={styles.tableTitleText}>Title</Text>
    </View>
    <View style={styles.rowThree}>
      <Text style={styles.tableTitleText}>Avg. Time</Text>
    </View>
    <View style={styles.rowFour}>
      <Text style={styles.tableTitleText}>Type</Text>
    </View>
    <View style={styles.rowFive}>
      <Text style={styles.tableTitleText}>Category</Text>
    </View>
    <View style={styles.rowSix}>
      <Text style={styles.tableTitleText}>Members</Text>
    </View>
  </View>
);

interface TableContentProps {
  index: number;
  title: string;
  avgTime: string;
  type: string;
  category: string;
  userImages: string[];
}

const TableContent = ({
  index,
  title,
  avgTime,
  type,
  category,
  userImages,
}: TableContentProps) => (
  <View style={[styles.cellWrap, styles.cellContentWrap]}>
    <View style={styles.rowOne}>
      <Text style={styles.boldText}>{index}.</Text>
    </View>
    <View style={styles.rowTwo}>
      <Text style={styles.tableTitleText}>{title}</Text>
    </View>
    <View style={styles.rowThree}>
      <Text style={styles.regularText}>{avgTime}</Text>
    </View>
    <View style={styles.rowFour}>
      <Text style={styles.regularText}>{type}</Text>
    </View>
    <View style={styles.rowFive}>
      <Text style={styles.regularText} ellipsizeMode="tail" numberOfLines={1}>
        {category}
      </Text>
    </View>
    <View style={styles.rowSix}>
      <View style={styles.imagesWrap}>
        {userImages.slice(0, 3).map((image) => (
          <Image style={styles.image} source={{ uri: image }} />
        ))}
        {userImages.length > 3 && (
          <View style={[styles.countWrap, styles.image]}>
            <Text style={styles.countText}>{userImages.length - 3}+</Text>
          </View>
        )}
      </View>
    </View>
  </View>
);

const AnalyticsMostVisitedPage = ({
  refetch,
  data,
}: {
  data?: PageStat[];
  refetch: (value: { numDays?: number }) => void;
}) => {
  const [activeTab, setActiveTab] = useState(options[0].value);
  return (
    <ContentBox>
      <View style={styles.titleWrap} dataSet={{ media: ids.titleWrap }}>
        <Text style={styles.title}>Content Hub</Text>
        <View style={styles.selectWrap}>
          <SelectOption
            onChange={(value) => {
              setActiveTab(value);
              refetch({ numDays: Number(value) });
            }}
            value={activeTab}
            options={options}
          />
        </View>
      </View>
      <View style={styles.tableWrap}>
        <View style={styles.tableInner}>
          <TableHead />
          {data?.map((item, index) => (
            <TableContent
              key={item.route}
              index={index + 1}
              title={item.title}
              avgTime={fromSecondsToHoursOrMins(
                item.averageTimePerMemberMins * 60
              )}
              type={capitalizeFirstLetter(item.type)}
              category={item.categories?.[0] || ""}
              userImages={item.users.map((user) => user.img || "")}
            />
          ))}
        </View>
      </View>
    </ContentBox>
  );
};

export { AnalyticsMostVisitedPage };
