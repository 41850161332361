import React, { useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { InfoIcon } from "@components/general/icons/roadmap";
import { PlayIcon } from "@components/general/icons/org-icons";
import { CircularProgress } from "@components/roadmap/circular-progress";
import { styles } from "./style";
import { pluralize } from "@utils/misc";

interface ProgressTextProps {
  count: number;
  text: string;
}

const ProgressText = ({ count, text }: ProgressTextProps) => (
  <Text style={styles.progressText}>
    <Text style={styles.progressTextBold}>{count}&nbsp;</Text>
    {text}
  </Text>
);

const getPercent = (answeredQuestions?: number, questions?: number) => {
  if (!answeredQuestions || !questions) return 0;
  return Math.round((answeredQuestions / questions) * 100);
};

interface Props {
  title: string;
  subtitle: string;
  sectionsTotal: number;
  questionsTotal: number;
  minuteTotal: number;
  totalQuestions: number;
  totalAnsweredQuestions: number;
  onPlay?: () => void;
  onPressInfo: () => void;
}

const RoadmapBlock = ({
  title,
  subtitle,
  sectionsTotal,
  questionsTotal,
  minuteTotal,
  totalQuestions,
  totalAnsweredQuestions,
  onPlay,
  onPressInfo,
}: Props) => {
  const [hovered, setHovered] = useState(false);
  return (
    <View style={styles.wrap}>
      <View style={styles.titleWrap}>
        <Text style={styles.title}>{title}</Text>
        <TouchableOpacity
          onPress={onPressInfo}
          // @ts-ignore
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <InfoIcon scale={1.5} fill={hovered ? "darkgray" : "#BABEC3"} />
        </TouchableOpacity>
      </View>
      <Text style={styles.subtitle}>{subtitle}</Text>
      <View style={styles.progressWrap}>
        <View style={styles.progressBox}>
          <CircularProgress
            isTitleVisible
            percent={getPercent(totalAnsweredQuestions, totalQuestions)}
          />
        </View>
        <View>
          <ProgressText
            count={sectionsTotal}
            text={pluralize(sectionsTotal, "Section")}
          />
          <ProgressText
            count={questionsTotal}
            text={pluralize(questionsTotal, "Question")}
          />
          <ProgressText
            count={minuteTotal}
            text={pluralize(minuteTotal, "Minute")}
          />
        </View>
      </View>
      <Text style={styles.questionAnsweredText}>
        {totalAnsweredQuestions}/{totalQuestions} Questions answered
      </Text>
      <View style={styles.playWrap}>
        <View style={styles.playProgressWrap}>
          <View
            style={[
              styles.playProgressInner,
              {
                width: `${getPercent(totalAnsweredQuestions, totalQuestions)}%`,
              },
            ]}
          >
            <Text style={styles.playProgressCount}>
              {totalAnsweredQuestions}
            </Text>
          </View>
        </View>
        <TouchableOpacity style={styles.playTouch} onPress={onPlay}>
          <PlayIcon color="#FFFFFF" />
        </TouchableOpacity>
      </View>
    </View>
  );
};

export { RoadmapBlock };
