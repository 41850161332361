import React from "react";
import { ActivityIndicator, Text, TouchableOpacity, View } from "react-native";
import { useNavigate } from "react-router-native";
import dayjs from "dayjs";

import { SearchItem } from "@components/search/search-item";
import { styles } from "./style";
import type { AlgoliaProps } from "../useAlgoliaSearch";
import { Content, Training, Event } from "@gql/generated/generated";
import {
  formatSubtitle,
  getLessonCount,
  roundToHours,
} from "../shared/SearchItemHelpers";

interface WrapperProps {
  title: string;
  children: React.ReactNode;
  isBottomBorderVisible?: boolean;
}

const Wrapper = ({ title, children, isBottomBorderVisible }: WrapperProps) => (
  <View style={[styles.wrapper, isBottomBorderVisible && styles.wrapperBorder]}>
    <View style={styles.titleWrap}>
      <Text style={styles.title}>{title}</Text>
    </View>
    {children}
  </View>
);

interface Props {
  query: string;
  onClose: () => void;
  eventsResults?: (Event & AlgoliaProps)[];
  trainingsResults?: (Training & AlgoliaProps)[];
  contentResults?: (Content & AlgoliaProps)[];
  isLoading: boolean;
}

const ModuleSearch = ({
  query,
  onClose,
  eventsResults,
  trainingsResults,
  contentResults,
  isLoading,
}: Props) => {
  const navigate = useNavigate();

  const handlePressSearchItem = (url: string) => {
    navigate(url);
    onClose();
  };

  return (
    <View>
      {isLoading ? (
        <View style={{ padding: 12 }}>
          <ActivityIndicator size="large" color="#213470" />
        </View>
      ) : contentResults && contentResults?.length > 0 ? (
        <Wrapper title="Content" isBottomBorderVisible>
          {contentResults?.map(
            ({
              coverImage,
              objectID,
              title,
              publishedAt,
              categories,
              description,
            }) => {
              const formattedDate = dayjs(publishedAt).format("DD MMM YY");
              return (
                <SearchItem
                  key={objectID}
                  hasPadding
                  asset={coverImage}
                  title={title || ""}
                  date={formattedDate}
                  categories={categories?.map(
                    (cat: string) => cat.split(":")[0]
                  )}
                  onPress={() => handlePressSearchItem(`content/${objectID}`)}
                  {...{ query, description }}
                />
              );
            }
          )}
        </Wrapper>
      ) : null}
      {eventsResults && eventsResults.length > 0 ? (
        <Wrapper title="Events" isBottomBorderVisible>
          {eventsResults.map(
            ({ dateFrom, objectID, coverImage, title, type, description }) => {
              const formattedDate = dayjs(dateFrom).format("DD MMM YY • HH:mm");

              return (
                <SearchItem
                  key={objectID}
                  hasPadding
                  asset={coverImage}
                  title={title || ""}
                  date={formattedDate}
                  subtitle={type || ""}
                  onPress={() => handlePressSearchItem(`events/${objectID}`)}
                  {...{ query, description }}
                />
              );
            }
          )}
        </Wrapper>
      ) : null}

      {trainingsResults && trainingsResults.length > 0 ? (
        <Wrapper title="Training">
          {trainingsResults.map(
            ({
              objectID,
              coverImage,
              title,
              averageStars,
              comments,
              shareCount,
              durationInSeconds,
              modules,
              overview: description,
            }) => {
              return (
                <SearchItem
                  key={objectID}
                  hasPadding
                  asset={coverImage}
                  title={title}
                  date={`${roundToHours(
                    durationInSeconds
                  )} hrs total • ${getLessonCount(modules)} lessons`}
                  isRating
                  subtitle={formatSubtitle({
                    averageStars,
                    comments,
                    shareCount,
                  })}
                  onPress={() => handlePressSearchItem(`training/${objectID}`)}
                  {...{ query, description }}
                />
              );
            }
          )}
        </Wrapper>
      ) : null}
      <View style={styles.seeAllWrap}>
        <TouchableOpacity
          onPress={() => handlePressSearchItem(`/search/results/${query}`)}
        >
          <Text style={styles.seeAllText}>
            See results for <Text style={styles.seeAllTextBold}>{query}</Text>
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export { ModuleSearch };
