import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    padding: 16,
    backgroundColor: "#FAFBFB",
    borderRadius: 8,
    width: "100%",
    maxWidth: 352,
  },
  titleWrap: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 20,
  },
  typeBox: {
    paddingLeft: 6,
    paddingRight: 6,
    paddingTop: 2,
    paddingBottom: 2,
    borderRadius: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  typeBoxCircle: {
    height: 8,
    width: 8,
    borderRadius: 4,
    borderWidth: 2,
    justifyContent: "flex-end",
  },
  typeBoxCircleInner: {
    height: "50%",
    width: "100%",
  },
  typeBoxText: {
    marginLeft: 4,
    fontSize: 14,
    fontFamily: "OpenSans_600SemiBold",
  },
  countText: {
    color: "#6D7175",
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
    marginLeft: 8,
  },
});
