import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { ISvgProps } from "../../../types";

const TwitterIcon = (props: ISvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <Path
      fill={props.color || "#AEB5BC"}
      d="M19.773 8.089c.007.175.01.35.01.524 0 5.303-3.73 11.424-10.551 11.424-2.095 0-4.044-.668-5.685-1.805.29.032.585.056.884.056 1.737 0 3.337-.644 4.606-1.725-1.622-.024-2.993-1.193-3.465-2.783.227.048.46.072.7.072.336 0 .663-.048.977-.143-1.698-.366-2.977-1.987-2.977-3.935v-.048c.5.294 1.073.477 1.681.501-.996-.723-1.651-1.955-1.651-3.347 0-.73.182-1.423.503-2.019 1.829 2.433 4.562 4.03 7.644 4.198a4.37 4.37 0 0 1-.096-.915c0-2.217 1.66-4.014 3.71-4.014 1.066 0 2.029.485 2.705 1.264a6.96 6.96 0 0 0 2.356-.97c-.278.938-.865 1.725-1.632 2.218a6.955 6.955 0 0 0 2.13-.628 7.924 7.924 0 0 1-1.849 2.075z"
    />
  </Svg>
);

export { TwitterIcon };
