import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    borderTopColor: "#EDEEEF",
    borderTopWidth: 1,
    width: "100%",
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 20,
    paddingBottom: 20,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  wrapEdit: {
    alignItems: "flex-start",
  },
  leftWrap: {
    width: "90%",
  },
  title: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 14,
    color: "#0D1738",
  },
  subtitle: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 16,
    lineHeight: 24,
    color: "##0D1738",
    marginTop: 12,
    marginBottom: 20,
  },
  editText: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 14,
    color: "#2C6ECB",
  },
});
