import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: "100%",
  },
  wrapInnerNative: {
    padding: 20,
  },
  titleText: {
    fontSize: 16,
    color: "#0D1738",
    fontFamily: "OpenSans_600SemiBold",
    marginBottom: 14,
  },
  description: {
    fontSize: 14,
    color: "#6D7175",
    fontFamily: "OpenSans_400Regular",
    marginBottom: 12,
  },
  inputWrap: {
    marginBottom: 12,
  },
  buttonWrap: {
    marginTop: 20,
  },
  successTitleWrap: {
    flexDirection: "row",
    alignItems: "center",
  },
  successTitle: {
    fontSize: 16,
    color: "#0D1738",
    fontFamily: "OpenSans_600SemiBold",
    marginLeft: 12,
  },
  successDescription: {
    fontSize: 14,
    color: "#202223",
    fontFamily: "OpenSans_400Regular",
    marginTop: 16,
    marginBottom: 28,
  },
  deleteAccountWrap: {
    marginTop: 40,
    padding: 20,
  },
  deleteButton: {
    backgroundColor: "#EC0000",
    borderColor: "#EC0000",
    width: "100%",
  },
  title: {
    color: "#202223",
    fontSize: 17,
    lineHeight: 28,
  },
  subtitle: {
    color: "#202223",
    marginTop: 0,
    lineHeight: 24,
    fontFamily: "OpenSans_400Regular",
  },
});
