import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  memberRequestHeaderTitle: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 26,
    lineHeight: 32,
    color: "#0D1738",
  },
});
