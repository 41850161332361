import React from "react";
import {
  Platform,
  Pressable,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import {
  ModalPosition,
  ModalWrapper,
} from "@components/general/modals/modal-wrapper";
import { styles } from "./style";

export interface IListModalItem {
  title: string;
  icon?: React.ReactNode;
  [key: string]: unknown;
}

interface ItemProps extends IListModalItem {
  onPress?: () => void;
}

const Item = ({ onPress, title, icon }: ItemProps) => (
  <TouchableOpacity style={styles.itemTouch} onPress={onPress}>
    {icon ? <View style={styles.iconWrap}>{icon}</View> : null}
    <Text style={styles.itemText}>{title}</Text>
  </TouchableOpacity>
);

interface Props {
  onClose?: () => void;
  isVisible: boolean;
  items?: IListModalItem[];
  onSelectItem?: (item: IListModalItem, index: number) => void;
  children?: React.ReactNode;
}

const ListModal = ({
  onClose,
  isVisible,
  items,
  onSelectItem,
  children,
}: Props) => (
  <ModalWrapper
    onClose={onClose}
    isVisible={isVisible}
    position={Platform.OS !== "web" ? ModalPosition.bottom : undefined}
    contentStyle={Platform.OS !== "web" ? styles.modalContent : undefined}
  >
    <Pressable style={styles.modalInner}>
      <View style={styles.dashWrap}>
        <View style={styles.dash} />
      </View>
      {items?.length
        ? items.map((item, index) => (
            <Item
              key={item.title}
              title={item.title}
              icon={item.icon}
              onPress={() => onSelectItem && onSelectItem(item, index)}
            />
          ))
        : null}
      {children}
    </Pressable>
  </ModalWrapper>
);

export { ListModal };
