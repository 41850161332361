import { editPageStyles } from "@styles/BackOffice/editPageStyles";
import { useFormikContext } from "formik";
import { Text, View } from "react-native";
import { UserOrganizationComponentProps } from "./shared";
import { InitialValuesProps } from "@pages/back-office/users-back-office/users-editor/userInitialValues";

export const UserOrganization = ({
  organisationsData,
}: UserOrganizationComponentProps) => {
  const { values } = useFormikContext<InitialValuesProps>();

  const selectedOrganisation = values.organisationId
    ? organisationsData?.find(
        ({ id }: { id: string }) => id === values.organisationId
      )
    : null;

  return (
    <View>
      <Text style={editPageStyles.userTextInfo}>{values.jobPosition}</Text>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <Text style={editPageStyles.userTextInfo}>At </Text>
        <Text style={editPageStyles.userTextInfoEmphasis}>
          {selectedOrganisation?.organisationInfo.companyName}
        </Text>
      </View>
      <Text style={editPageStyles.userTextInfo}>
        {selectedOrganisation && selectedOrganisation.members?.length} Employees
      </Text>
    </View>
  );
};
