import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { styles as sharedStyles } from "@styles/BackOffice/shared";
import { CloseIcon } from "@components/general/icons/CloseIcon";
import {
  ButtonGeneral,
  ButtonVariant,
} from "@components/general/button-general";
import { ModalWrapper } from "@components/general/modals/modal-wrapper";
import { PromptModalContent } from "@components/org/saved/modals/prompt-modal/PromptModalContent";
import { styles } from "./style";
import { HtmlParser } from "../../html-parser/HtmlParser";

interface Props {
  isVisible: boolean;
  onClose: () => void;
  title: string;
  description?: string;
  primaryButtonTitle?: string;
  onPrimaryButtonPress?: () => void;
  secondaryButtonTitle?: string;
  onSecondaryButtonPress?: () => void;
  animationType?: "none" | "slide" | "fade";
  children?: React.ReactNode;
  promptTitle?: string | null;
  image?: string | null;
  elementReviews?: string[];
  htmlString?: string;
  categories?: string[];
}

export const ModalHeader = ({
  onClose,
  title,
}: {
  onClose: () => void;
  title: string;
}) => {
  return (
    <View style={styles.header}>
      <Text style={sharedStyles.contentBoxTitle}>{title}</Text>
      <TouchableOpacity onPress={onClose}>
        <CloseIcon />
      </TouchableOpacity>
    </View>
  );
};

export const ModalButtons = ({
  secondaryButtonTitle,
  onSecondaryButtonPress,
  primaryButtonTitle,
  onPrimaryButtonPress,
}: {
  secondaryButtonTitle?: string;
  onSecondaryButtonPress?: () => void;
  primaryButtonTitle?: string;
  onPrimaryButtonPress?: () => void;
}) => {
  return (
    <View style={styles.footer}>
      {secondaryButtonTitle ? (
        <ButtonGeneral
          withMarginRight
          variant={ButtonVariant.Secondary}
          label={secondaryButtonTitle}
          onPress={onSecondaryButtonPress}
        />
      ) : null}
      {primaryButtonTitle ? (
        <ButtonGeneral
          label={primaryButtonTitle || ""}
          onPress={onPrimaryButtonPress}
        />
      ) : null}
    </View>
  );
};

const CompleteModal = ({
  isVisible,
  onClose,
  title,
  description,
  primaryButtonTitle,
  secondaryButtonTitle,
  onPrimaryButtonPress,
  onSecondaryButtonPress,
  children,
  animationType,
  promptTitle,
  image,
  categories,
  elementReviews,
  htmlString,
}: Props) => (
  <ModalWrapper isVisible={isVisible}>
    <ModalHeader title={title} onClose={onClose} />
    {children || null}
    {description ? (
      <View style={styles.body}>
        <Text style={styles.bodyDescription}>{description}</Text>
      </View>
    ) : null}
    {htmlString ? (
      <View style={styles.body}>
        <HtmlParser htmlString={htmlString} />
      </View>
    ) : null}
    {!description && promptTitle ? (
      <PromptModalContent
        input
        title={promptTitle}
        image={image}
        reviews={elementReviews?.join(" • ")}
        categories={categories}
      />
    ) : null}
    <ModalButtons
      primaryButtonTitle={primaryButtonTitle}
      onPrimaryButtonPress={onPrimaryButtonPress}
      secondaryButtonTitle={secondaryButtonTitle}
      onSecondaryButtonPress={onSecondaryButtonPress}
    />
  </ModalWrapper>
);

export { CompleteModal };
