import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: 20,
    marginBottom: 40,
  },
  button: {
    backgroundColor: "#0D1738",
    paddingVertical: 5,
    paddingHorizontal: 24,
    marginRight: 40,
    borderRadius: 35,
  },
  buttonText: {
    color: "#fff",
    fontSize: 14,
    fontFamily: "Opensans_600SemiBold",
  },
});
