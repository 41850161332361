import * as React from "react";
import Svg, { Circle, Path, SvgProps } from "react-native-svg";

const DiscountIcon = (props: SvgProps) => (
  <Svg width={20} height={20} fill="none" {...props}>
    <Circle cx={10} cy={10} r={10} fill="#D72C0D" />
    <Path
      fill="#fff"
      d="M10.943 4.39a1.333 1.333 0 0 0-1.886 0l-.357.357a2 2 0 0 1-1.414.586h-.62c-.736 0-1.333.597-1.333 1.333v.62A2 2 0 0 1 4.748 8.7l-.357.357a1.333 1.333 0 0 0 0 1.886l.357.357a2 2 0 0 1 .585 1.414v.62c0 .736.597 1.332 1.334 1.332h.619a2 2 0 0 1 1.414.586l.357.357c.52.521 1.365.521 1.886 0l.357-.357a2 2 0 0 1 1.414-.586h.62c.736 0 1.333-.597 1.333-1.333v-.619a2 2 0 0 1 .585-1.414l.357-.357a1.333 1.333 0 0 0 0-1.886l-.357-.357a2 2 0 0 1-.585-1.414v-.62c0-.736-.597-1.333-1.334-1.333h-.62a2 2 0 0 1-1.413-.586l-.357-.356zm.945 3.98a.667.667 0 1 0-1.11-.74l-2.666 4a.667.667 0 1 0 1.11.74l2.666-4zm-4.221.963a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm5.666 2.334a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
    />
  </Svg>
);

export { DiscountIcon };
