import React from "react";
import { Alert, ScrollView, Text, TouchableOpacity, View } from "react-native";
import * as Yup from "yup";
import { Formik, FormikValues } from "formik";
import { Wrapper } from "@components/general/layouts/wrapper/Wrapper";
import { IndexPageHeader } from "@components/general/index-page-header";
import { SelectOption } from "@components/general/form/select-option";
import { Input } from "@components/general/form/input";
import { ButtonGeneral } from "@components/general/button-general";
import { PhoneInput } from "@components/general/form/phone-input";
import { RightArrow } from "@components/general/icons";
import { useAuth } from "@hooks/useAuth";
import { filterUnwantedInputKeysForSubmission } from "@pages/account/settings/settingsUtils";
import { styles } from "./style";
import { useUpdateUserMutation } from "@gql/generated/generated";

interface QuestionItemProps {
  question: string;
  onPress?: () => void;
}

const QuestionItem = ({ question, onPress }: QuestionItemProps) => (
  <TouchableOpacity style={styles.infoWrap} onPress={onPress}>
    <Text style={[styles.infoTitle, styles.inputWrap]}>{question}</Text>
    <Text style={styles.subtitle}>Tick any 2:</Text>
    <View style={styles.selectWrap}>
      <Text style={styles.infoTitle}>Please select</Text>
      <RightArrow />
    </View>
  </TouchableOpacity>
);

const EditProfile = () => {
  const { navigate, user, refetch } = useAuth();
  const [updateUser, { error }] = useUpdateUserMutation();

  const onSubmit = (values: FormikValues) => {
    if (user) {
      updateUser({
        variables: {
          input: {
            user: filterUnwantedInputKeysForSubmission(values, [
              "firstName",
              "lastName",
              "email",
              "phoneNumber",
              "addressOne",
              "addressTwo",
              "postCode",
              "city",
              "country",
              "companyName",
              "jobPosition",
            ]),
          },
        },
      }).then(() => {
        if (error) {
          Alert.alert("Unsuccessful", error.message || "");
        } else {
          navigate(-1);
        }
        if (refetch) {
          refetch();
        }
      });
    }
  };

  return (
    <Wrapper style={styles.wrap}>
      <IndexPageHeader title="Edit Profile" onPressBack={() => navigate(-1)} />
      <ScrollView>
        <Formik
          initialValues={{
            firstName: user?.accountInfo?.firstName || "",
            lastName: user?.accountInfo?.lastName || "",
            email: user?.accountInfo?.email || "",
            phoneNumber: user?.accountInfo?.phoneNumber || "",
            addressOne: user?.accountInfo?.addressOne || "",
            addressTwo: user?.accountInfo?.addressTwo || "",
            postCode: user?.accountInfo?.postCode || "",
            city: user?.accountInfo?.city || "",
            country: user?.accountInfo?.country || "",
            companyName: user?.accountInfo?.companyInfo.name || "",
            jobPosition: user?.accountInfo?.companyInfo.jobPosition || "",
          }}
          onSubmit={onSubmit}
          validationSchema={Yup.object().shape({
            firstName: Yup.string().required("This field is required"),
            lastName: Yup.string().required("This field is required"),
            email: Yup.string().email().required("This field is required"),
          })}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            touched,
            errors,
            setFieldValue,
          }) => (
            <>
              <View style={styles.infoWrap}>
                <Text style={[styles.infoTitle, styles.titleMargin]}>
                  Personal Info
                </Text>
                <View style={styles.inputWrap}>
                  <Input
                    label="First name"
                    value={values.firstName}
                    onChangeText={handleChange("firstName")}
                    error={
                      touched.firstName && errors.firstName
                        ? errors.firstName
                        : undefined
                    }
                  />
                </View>
                <View style={styles.inputWrap}>
                  <Input
                    label="Last name"
                    value={values.lastName}
                    onChangeText={handleChange("lastName")}
                    error={
                      touched.lastName && errors.lastName
                        ? errors.lastName
                        : undefined
                    }
                  />
                </View>
                <View style={styles.inputWrap}>
                  <Input
                    label="Email"
                    value={values.email}
                    keyboardType="email-address"
                    autoCapitalize="none"
                    onChangeText={handleChange("email")}
                    error={
                      touched.email && errors.email ? errors.email : undefined
                    }
                  />
                </View>
                <View style={styles.inputWrap}>
                  <PhoneInput
                    label="Phone number"
                    value={values.phoneNumber}
                    onChangeText={handleChange}
                    error={
                      touched.phoneNumber && errors.phoneNumber
                        ? errors.phoneNumber
                        : undefined
                    }
                  />
                </View>
                <View style={styles.inputWrap}>
                  <Input
                    label="Street name"
                    value={values.addressOne}
                    onChangeText={handleChange("addressOne")}
                    error={
                      touched.addressOne && errors.addressOne
                        ? errors.addressOne
                        : undefined
                    }
                  />
                </View>
                <View style={styles.inputWrap}>
                  <Input
                    label="Address"
                    value={values.addressTwo}
                    onChangeText={handleChange("addressTwo")}
                    error={
                      touched.addressTwo && errors.addressTwo
                        ? errors.addressTwo
                        : undefined
                    }
                  />
                </View>
                <View style={styles.inputWrap}>
                  <Input
                    label="Postcode"
                    value={values.postCode}
                    onChangeText={handleChange("postCode")}
                    error={
                      touched.postCode && errors.postCode
                        ? errors.postCode
                        : undefined
                    }
                  />
                </View>
                <View style={styles.inputWrap}>
                  <SelectOption
                    label="City"
                    placeholder="Please select"
                    value={values.city}
                    options={[]}
                    onChange={(value) => setFieldValue("city", value)}
                    error={
                      touched.city && errors.city ? errors.city : undefined
                    }
                  />
                </View>
                <View style={styles.inputWrap}>
                  <SelectOption
                    label="Country"
                    placeholder="Please select"
                    value={values.country}
                    options={[]}
                    onChange={(value) => setFieldValue("country", value)}
                    error={
                      touched.country && errors.country
                        ? errors.country
                        : undefined
                    }
                  />
                </View>
              </View>
              <View style={styles.infoWrap}>
                <Text style={[styles.infoTitle, styles.titleMargin]}>
                  Personal Info
                </Text>
                <View style={styles.inputWrap}>
                  <Input
                    label="Company name"
                    value={values.companyName}
                    onChangeText={handleChange("companyName")}
                    error={
                      touched.companyName && errors.companyName
                        ? errors.companyName
                        : undefined
                    }
                  />
                </View>
                <View style={styles.inputWrap}>
                  <SelectOption
                    label="Job position"
                    placeholder="Please select"
                    value={values.jobPosition}
                    options={[]}
                    onChange={(value) => setFieldValue("jobPosition", value)}
                    error={
                      touched.jobPosition && errors.jobPosition
                        ? errors.jobPosition
                        : undefined
                    }
                  />
                </View>
              </View>
              <QuestionItem
                question="What's your biggest focus in the next 90 days?"
                onPress={() =>
                  navigate("/account/settings/profile/edit/personal-info")
                }
              />
              <QuestionItem
                question="What are the 3 main reasons you joined TRN?"
                onPress={() =>
                  navigate("/account/settings/profile/edit/personal-info")
                }
              />
              <View style={styles.buttonWrap}>
                <ButtonGeneral onPress={handleSubmit} label="Save" />
              </View>
            </>
          )}
        </Formik>
      </ScrollView>
    </Wrapper>
  );
};

export { EditProfile };
