export const industryOptions = [
  { value: "Accounting and Finance", label: "Accounting and Finance" },
  {
    value: "Banking & Financial Services",
    label: "Banking & Financial Services",
  },
  {
    value: "Commercial & Office Support",
    label: "Commercial & Office Support",
  },
  { value: "Construction & Property", label: "Construction & Property" },
  { value: "Executive Search", label: "Executive Search" },
  { value: "Healthcare & Medical", label: "Healthcare & Medical" },
  { value: "HR", label: "HR" },
  { value: "Hospitality", label: "Hospitality" },
  { value: "Interim Recruitment", label: "Interim Recruitment" },
  { value: "IT & Telecoms", label: "IT & Telecoms" },
  { value: "Legal", label: "Legal" },
  { value: "Manufacturing", label: "Manufacturing" },
  { value: "Marketing", label: "Marketing" },
  { value: "Media & Creative/Digital", label: "Media & Creative/Digital" },
  { value: "Pharmaceutical", label: "Pharmaceutical" },
  { value: "Public Sector", label: "Public Sector" },
  {
    value: "Recruitment Process Outsourcing/Managed Services",
    label: "Recruitment Process Outsourcing/Managed Services",
  },
  { value: "Sales", label: "Sales" },
  { value: "Transport & Logistics", label: "Transport & Logistics" },
  { value: "Utilities", label: "Utilities" },
  { value: "Other", label: "Other" },
];

export const recruitmentTypeOptions = [
  { value: "Perm", label: "Perm" },
  { value: "Contract", label: "Contract" },
  { value: "Temp", label: "Temp" },
  { value: "Interim", label: "Interim" },
  { value: "Search", label: "Search" },
  { value: "RPO", label: "RPO" },
  { value: "Other Talent Solutions", label: "Other Talent Solutions" },
];

export const numEmployeeOptions = [
  { value: "1-5", label: "1-5" },
  { value: "6-15", label: "6-15" },
  { value: "16-30", label: "16-30" },
  { value: "31-49", label: "31-49" },
  { value: "50-99", label: "50-99" },
  { value: "100 +", label: "100 +" },
];

export const annualRevenueOptions = [
  { value: "Less than 100k", label: "Less than 100k" },
  { value: "100k - 500k", label: "100k - 500k" },
  { value: "500k - 1M", label: "500k - 1M" },
  { value: "1M - 6M", label: "1M - 6M" },
  { value: "6M - 10M", label: "6M - 10M" },
  { value: "More than 10M", label: "More than 10M" },
];

export const geographyServicedOptions = [
  { value: "UK", label: "UK" },
  { value: "Europe", label: "Europe" },
  { value: "Asia", label: "Asia" },
  { value: "US", label: "US" },
  { value: "Other", label: "Other" },
];

export const headOfficeAreaOptions = [
  { value: "North (England)", label: "North (England)" },
  { value: "Midlands (England)", label: "Midlands (England)" },
  { value: "South West (England)", label: "South West (England)" },
  { value: "South East (England)", label: "South East (England)" },
  { value: "Wales", label: "Wales" },
  { value: "Scotland", label: "Scotland" },
  { value: "Ireland", label: "Ireland" },
  { value: "Europe", label: "Europe" },
  { value: "US", label: "US" },
  { value: "Asia", label: "Asia" },
  { value: "Other", label: "Other" },
];

export const crmOptions = [
  { value: "Access", label: "Access" },
  { value: "Bullhorn", label: "Bullhorn" },
  { value: "Job Adder", label: "Job Adder" },
  { value: "Mercury", label: "Mercury" },
  { value: "TargetRecruit", label: "TargetRecruit" },
  { value: "Vincere", label: "Vincere" },
  { value: "Other", label: "Other" },
];

export const additionalTechnologyOptions = [
  { value: "Broadbean", label: "Broadbean" },
  { value: "Sourcebreaker", label: "Sourcebreaker" },
  {
    value: "Cloudcall (Computer telephony)",
    label: "Cloudcall (Computer telephony)",
  },
  {
    value: "Recruiter Insider ((candidate, client and contractor experience)",
    label: "Recruiter Insider ((candidate, client and contractor experience)",
  },
  { value: "Mustard (automations)", label: "Mustard (automations)" },
  { value: "Cube 19", label: "Cube 19" },
  { value: "Northstar", label: "Northstar" },
  { value: "Hinterview", label: "Hinterview" },
  { value: "Odro", label: "Odro" },
  { value: "Other", label: "Other" },
  { value: "None", label: "None" },
];

export const contractorManagementSupportOptions = [
  { value: "Expedo", label: "Expedo" },
  { value: "Brookson", label: "Brookson" },
  { value: "6 Cats", label: "6 Cats" },
  { value: "Other", label: "Other" },
  { value: "None", label: "None" },
];

export const marketingResourceExpertOptions = [
  { value: "None particularly", label: "None particularly" },
  {
    value: "None – we have in house marketing resource",
    label: "None – we have in house marketing resource",
  },
  { value: "Addictivity", label: "Addictivity" },
  { value: "Redknows", label: "Redknows" },
  { value: "HOXO", label: "HOXO" },
  { value: "Other", label: "Other" },
];

export const reasonsForJoiningOptions = [
  {
    value: "To access experts and advisors for input into my strategy",
    label: "To access experts and advisors for input into my strategy",
  },
  {
    value:
      "To see what others are doing and to benchmark my business and my approach against others",
    label:
      "To see what others are doing and to benchmark my business and my approach against others",
  },
  { value: "To help scale my business", label: "To help scale my business" },
  { value: "To hold me to account", label: "To hold me to account" },
  { value: "To access different ideas", label: "To access different ideas" },
  {
    value: "To develop myself as a recruitment business leader",
    label: "To develop myself as a recruitment business leader",
  },
  { value: "To develop my team", label: "To develop my team" },
];
