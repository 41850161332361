import { DownArrow } from "@components/general/icons";
import { useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { ISubmodule } from "../roadmap-models";
import { styles } from "./style";

interface Props {
  submodule: ISubmodule;
}

export const QuestionAnswerRow = ({ submodule }: Props) => {
  const [expand, setExpand] = useState(false);
  return (
    <TouchableOpacity
      style={styles.tableWrapper}
      onPress={() => setExpand(!expand)}
    >
      <View style={styles.moduleRowContainer}>
        <Text style={styles.textBoldDark}>{submodule.name}</Text>

        <View style={styles.btnContainer}>
          <TouchableOpacity
            onPress={() => setExpand(!expand)}
            style={[styles.btnStyle]}
          >
            {expand ? (
              <DownArrow
                style={{
                  marginRight: 10,
                  transform: [{ rotate: "180deg" }],
                }}
                color="#5C5F62"
              />
            ) : (
              <DownArrow color="#5C5F62" style={{ marginRight: 10 }} />
            )}
            <Text style={[styles.textBoldDark]}>
              {expand ? "Close" : "Expand"}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      <View>
        {expand && (
          <>
            {submodule.questions?.map((question, index) => (
              <View style={styles.answersRowContainer} key={index}>
                <View style={styles.answerQuestionField}>
                  <View style={{ width: "100%" }}>
                    <Text style={[styles.textBoldDark]}>
                      {question.question}
                    </Text>
                    <Text style={styles.textRegularDark}>{question.title}</Text>
                    <Text style={styles.textRegularDark}>
                      {question.description}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    flex: 1,
                    alignSelf: "flex-start",
                  }}
                >
                  {typeof question.answer === "string" && (
                    <View>
                      <Text
                        style={[styles.textRegularDark, { marginBottom: 8 }]}
                      >
                        {question.answer}
                      </Text>
                    </View>
                  )}
                  {Array.isArray(question.answer) &&
                    question.answer?.map((answer, index) => (
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "flex-start",
                        }}
                        key={index}
                      >
                        <Text
                          style={[styles.textRegularDark, { marginRight: 8 }]}
                        >
                          &#x25CF;
                        </Text>
                        <Text
                          style={[styles.textRegularDark, { marginBottom: 8 }]}
                        >
                          {answer}
                        </Text>
                      </View>
                    ))}
                </View>
              </View>
            ))}
          </>
        )}
      </View>
    </TouchableOpacity>
  );
};
