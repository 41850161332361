import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  innerContainer: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    width: "100%",
    top: 0,
    backgroundColor: "rgba(0,0,0,0.25)",
  },
  crossButton: { position: "absolute", top: 10, right: 10 },
  deleteText: {
    fontFamily: "OpenSans_400Regular",
    color: "#6D7175",
    fontSize: 14,
    lineHeight: 20,
    marginLeft: 6,
  },
  label: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 14,
    color: "#202223",
    marginBottom: 4,
  },
  inputText: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 12,
    color: "#0D1738",
    borderWidth: 1,
    borderRadius: 4,
    borderColor: "#C9CCCF",
    backgroundColor: "#FFFFFF",
    padding: 10,
  },
});
