import StyleSheet from "react-native-media-query";
import { Platform } from "react-native";
import { mediaQuery } from "@utils/misc";

export const { ids, styles } = StyleSheet.create({
  head: {
    flexDirection: "row",
    alignItems: "center",
  },
  headerTitle: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 26,
    color: "#0D1738",
    marginLeft: 8,
  },
  split: {
    flexDirection: "row",
    ...(Platform.OS === "web" && { gap: 20 }),
    marginTop: 20,
    [mediaQuery.forTabletDown]: {
      flexDirection: "column",
    },
  },
  leftContent: {
    width: "80%",
    maxWidth: 874,
  },
  rightContent: {
    width: "19%",
    maxWidth: 315,
  },
  contentBox: {
    padding: 20,
  },
  text: {
    color: "#202223",
    fontFamily: "OpenSans_400Regular",
    fontSize: 14,
    marginBottom: 4,
  },
  userWrap: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 16,
  },
  userBox: {
    flexDirection: "row",
    alignItems: "center",
  },
  userImage: {
    height: 40,
    width: 40,
    borderRadius: 20,
  },
  presenceCircle: {
    height: 12,
    width: 12,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: "#FFFFFF",
    position: "absolute",
    bottom: 0,
    left: 30,
  },
  userName: {
    marginLeft: 16,
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 14,
    color: "#0D1738",
  },
  viewUser: {
    color: "#2C6ECB",
    fontFamily: "OpenSans_400Regular",
    fontSize: 14,
  },
  bottomText: {
    color: "#6D7175",
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 14,
    marginBottom: 16,
  },
  detailsText: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 14,
    color: "#0D1738",
  },
  finalText: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 14,
    color: "#0D1738",
  },
  actionText: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 16,
    color: "#0D1738",
    marginBottom: 20,
  },
});
