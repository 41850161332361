import { Row } from "@components/general/row";
import { Text, View } from "react-native";
import { textStyles } from "@styles/text";
import {
  ButtonGeneral,
  ButtonVariant,
} from "@components/general/button-general";
import { FileUploader } from "../uploader";
import { DocumentAsset } from "@gql/generated/generated";
import { AssetInstruction, MimeType } from "../uploader/GenericDropzoneTypes";
import { useState } from "react";

export const useFilesUploader = ({
  initialExistingFiles,
}: {
  initialExistingFiles: DocumentAsset[];
}) => {
  const [existingFiles, setExistingFiles] = useState(initialExistingFiles);
  const [newFiles, setNewFiles] = useState<number[]>([]);
  const [deleted, setDeleted] = useState<number[]>([]);

  return {
    existingFiles,
    setExistingFiles,
    newFiles,
    setNewFiles,
    deleted,
    setDeleted,
  };
};

export const AddAttachments = ({
  initialExistingFiles,
  assetInstruction,
  validFileTypes,
  actionHint,
  title = "Attachments",
}: {
  initialExistingFiles: DocumentAsset[] | null;
  assetInstruction: AssetInstruction;
  validFileTypes?: MimeType[];
  actionHint?: string;
  title?: string;
}) => {
  const {
    existingFiles,
    setExistingFiles,
    newFiles,
    setNewFiles,
    deleted,
    setDeleted,
  } = useFilesUploader({ initialExistingFiles: initialExistingFiles ?? [] });

  return (
    <>
      <Row style={{ justifyContent: "space-between" }}>
        <Text style={[textStyles.semiBoldSmall]}>{title}</Text>
        <ButtonGeneral
          variant={ButtonVariant.Primary}
          style={{ paddingVertical: 6 }}
          label="Add attachment"
          onPress={() => {
            setNewFiles([...newFiles, newFiles.length]);
          }}
        />
      </Row>
      {existingFiles?.map((file, i) => {
        return (
          <View key={file.id} style={{ marginBottom: 16 }}>
            <FileUploader
              initialAsset={file}
              assetInstruction={assetInstruction}
              removeFromUI={() => {
                setExistingFiles(
                  [...existingFiles].filter((_file, j) => j !== i)
                );
              }}
              fileName={{ title: "Title", placeholder: "Enter content name" }}
            />
          </View>
        );
      })}
      {newFiles?.map((_file, i) => {
        if (deleted.includes(i)) return null;
        return (
          <View key={i} style={{ marginBottom: 16 }}>
            <FileUploader
              initialAsset={null}
              assetInstruction={assetInstruction}
              actionHint={actionHint}
              validFileTypes={validFileTypes}
              removeFromUI={() => {
                setDeleted([...deleted, i]);
              }}
              fileName={{ title: "Title", placeholder: "Enter content name" }}
            />
          </View>
        );
      })}
    </>
  );
};
