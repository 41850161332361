import React, { useState } from "react";
import {
  ActivityIndicator,
  Modal,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { SearchHeader } from "@components/search/search-header";
import { SearchTab, SearchTabItem } from "@components/search/search-tab";
import { SearchItem } from "@components/search/search-item";
import { CloseIconSmall } from "@components/general/icons/search/CloseIconSmall";
import { useAlgoliaSearch } from "@pages/search/useAlgoliaSearch";
import { useUserContext } from "@context/UserContext";
import { SearchItemType } from "@pages/search/search-item-type";
import { styles } from "./style";

interface Props {
  isVisible: boolean;
  onClose?: () => void;
}

const SearchModal = ({ isVisible, onClose }: Props) => {
  const [activeTab, setActiveTab] = useState(SearchTabItem.all);
  const [query, setQuery] = useState("");
  const { currentUser } = useUserContext();
  const [isMobileRecentSearchVisible] = useState(false);
  const recentSearches = currentUser?.recentSearches?.slice(0, 3) || [];
  const {
    results,
    eventsResults,
    contentResults,
    trainingsResults,
    isLoading,
  } = useAlgoliaSearch({ query });

  const getResultsFromActiveTab = () => {
    switch (activeTab) {
      case SearchTabItem.all:
        return results;
      case SearchTabItem.content:
        return contentResults;
      case SearchTabItem.training:
        return trainingsResults;
      case SearchTabItem.events:
        return eventsResults;
      default:
        return results;
    }
  };

  return (
    <Modal visible={isVisible}>
      <View style={styles.wrap}>
        <SearchHeader
          value={query}
          onChange={(value) => setQuery(value)}
          onPressBack={onClose}
        />
        {!(query.length > 2) && (
          <View style={styles.titleWrap}>
            <Text style={styles.title}>Recent Searches</Text>
            {recentSearches && recentSearches.length ? (
              recentSearches.map((searchItem) => (
                <Text key={searchItem} style={styles.subtitle}>
                  {searchItem}
                </Text>
              ))
            ) : (
              <Text style={styles.subtitle}>You have no recent searches</Text>
            )}
          </View>
        )}
        {query.length > 2 && (
          <SearchTab
            active={activeTab}
            onTabPress={(tab: SearchTabItem) => setActiveTab(tab)}
            contentCount={contentResults.length}
            eventsCount={eventsResults.length}
            trainingsCount={trainingsResults.length}
          />
        )}
        {isMobileRecentSearchVisible && (
          <View style={styles.tabContent}>
            <View style={styles.recentItemWrap}>
              <View style={styles.recentItemLeft}>
                <SearchItem
                  imageUrl="https://kaboompics.com/cache/8/3/1/b/0/831b0c16ef67c93e573b26d72e3fb7189c9151e1.jpeg"
                  title="The art of negotiation"
                  date="12 Aug 22"
                  time="12:34"
                  categories={["Marketing"]}
                  query={query}
                />
              </View>
              <TouchableOpacity style={styles.cancelTouch}>
                <CloseIconSmall />
              </TouchableOpacity>
            </View>
          </View>
        )}
        {query.length > 2 && (
          <View style={styles.tabContent}>
            {isLoading ? (
              <View style={{ padding: 12 }}>
                <ActivityIndicator size="large" color="#213470" />
              </View>
            ) : null}
            {results.length > 0
              ? getResultsFromActiveTab().map((searchResult) => {
                  return (
                    <View style={{ marginBottom: 20 }}>
                      <SearchItemType
                        searchResult={searchResult}
                        query={query}
                      />
                    </View>
                  );
                })
              : null}
          </View>
        )}
      </View>
    </Modal>
  );
};

export { SearchModal };
