import { Text, View } from "react-native";
import dayjs from "dayjs";
import { editPageStyles } from "@styles/BackOffice/editPageStyles";

interface Props {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  houseNumber?: string | null;
  addressOne?: string | null;
  addressTwo?: string | null;
  postCode?: string | null;
  city?: string | null;
  registrationDate?: Date;
}
export const UserPersonalInfo = ({
  firstName,
  lastName,
  email,
  phoneNumber,
  houseNumber,
  addressOne,
  addressTwo,
  postCode,
  city,
  registrationDate,
}: Props) => {
  return (
    <>
      <View style={{ marginBottom: 20 }}>
        <Text
          style={editPageStyles.userTextInfo}
        >{`${firstName} ${lastName}`}</Text>
        <Text style={editPageStyles.userTextInfo}>{email}</Text>
        <Text style={editPageStyles.userTextInfo}>{phoneNumber}</Text>
      </View>
      <View style={{ marginBottom: 20 }}>
        <Text style={editPageStyles.userTextInfoTitle}>Address</Text>
        <Text style={editPageStyles.userTextInfo}>
          {houseNumber ? houseNumber + " " : ""}
          {addressOne}
        </Text>
        <Text style={editPageStyles.userTextInfo}>{addressTwo}</Text>
        <Text style={editPageStyles.userTextInfo}>{postCode}</Text>
        <Text style={editPageStyles.userTextInfo}>{city}</Text>
      </View>
      <View>
        <Text style={editPageStyles.userTextInfoTitle}>Member Since</Text>
        <Text style={editPageStyles.userTextInfo}>
          {dayjs(registrationDate).format("DD/MM/YYYY")}
        </Text>
      </View>
    </>
  );
};
