import {
  ActivityIndicator,
  Platform,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useContext, useMemo, useState } from "react";
import { Formik } from "formik";
import { useNavigate, useParams } from "react-router-native";
import * as Yup from "yup";

import { FormikInput } from "@components/back-office/editor/formik-input";
import { FormikTextArea } from "@components/back-office/editor/formik-text-area/FormikTextArea";
import { FormikRichText } from "@components/back-office/editor/formik-rich-text";
import { AddSpeakerOrInstructor } from "@components/back-office/editor/add-people";

import { styles } from "./style";
import { FormikPublishTools } from "@components/back-office/editor/formik-publish-tools/FormikPublishTools";
import { FormikSaveBar } from "@components/back-office/editor/formik-save-bar";
import { customFirestoreId } from "@utils/misc";
import { FormikEventDetails } from "@components/back-office/editor/formik-event-details";

import dayjs from "dayjs";
import { FormikHighlightBanner } from "@components/back-office/editor/formik-highlight-banner";
import { FormSection } from "@components/back-office/editor/form-section";

import { UpdateContext } from "@utils/UpdateContext";
import { Icon } from "@shopify/polaris";
import { ChevronLeftMinor } from "@shopify/polaris-icons";
import {
  CollectionName,
  useAdminGetAllEventsQuery,
  useGetEventQuery,
  useUpsertEventMutation,
} from "@gql/generated/generated";
import isCurrency from "validator/lib/isCurrency";
import { FileUploaderWithTitle } from "@components/back-office/editor/uploader/index";
import { AddAttachments } from "@components/back-office/editor/add-attachments";
import { useToast } from "@context/ToastContext";
import { has } from "lodash";

const EventsEditor = () => {
  const { addToast } = useToast();

  const dateFrom = useMemo(() => {
    return Date.now();
  }, []);

  const navigation = useNavigate();
  const UpdateStatus = useContext(UpdateContext);

  const [upsertEvent] = useUpsertEventMutation();

  const { data: highlightData } = useAdminGetAllEventsQuery({
    variables: {
      page: 1,
      limit: 10,
      filters: {
        isHighlighted: true,
      },
    },
  });

  const { id: existingId } = useParams() as { id: string };

  const { data: existingEventData, loading: loadingExistingEvent } =
    useGetEventQuery({
      variables: { id: existingId },
      skip: !existingId,
      fetchPolicy: "no-cache",
    });

  const eventId = existingId || customFirestoreId();

  const existingEvent =
    existingEventData?.getEvent.__typename === "Event"
      ? existingEventData.getEvent
      : null;

  if (loadingExistingEvent) {
    return (
      <View
        style={{
          height: Platform.OS === "web" ? "100vh" : "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ActivityIndicator size="large" color="#213470" />
      </View>
    );
  }

  return (
    <View
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Formik
        initialValues={{
          id: eventId,
          title: existingEvent?.title ?? "",
          type: existingEvent?.type,
          status: existingEvent?.status ?? "Draft",
          userType: existingEvent?.userType ?? [],
          description: existingEvent?.description ?? "",
          location: existingEvent?.location,
          categories: existingEvent?.categories ?? [],
          jobRoles: existingEvent?.jobRoles ?? [],
          summary: existingEvent?.summary ?? "",
          calendlyLink: existingEvent?.calendlyLink ?? "",
          googleFormLink: existingEvent?.googleFormLink ?? "",
          otherLink: existingEvent?.otherLink ?? "",
          instructions: existingEvent?.instructions ?? "",
          speakers: existingEvent?.speakers ?? [],
          startDate: existingEvent?.dateFrom ?? dateFrom,
          endDate: existingEvent?.dateTo,
          startTime: existingEvent
            ? dayjs(existingEvent.dateFrom).format("HH:mm")
            : "",
          endTime: existingEvent
            ? dayjs(existingEvent.dateTo).format("HH:mm")
            : "",
          city: existingEvent?.city ?? "",
          isHighlighted: existingEvent?.isHighlighted ?? false,
          highlightedRank: existingEvent?.highlightedRank ?? undefined,
          ticketPrice: existingEvent?.ticketPrice
            ? existingEvent.ticketPrice / 100
            : undefined,
          files: existingEvent?.files ?? [],
          coverImage: !!existingEvent?.coverImage ?? null,
        }}
        enableReinitialize={!!existingId}
        validationSchema={Yup.object()
          .shape({
            title: Yup.string().when("status", {
              is: "Publish",
              then: Yup.string().required("A title is required"),
              otherwise: Yup.string(),
            }),
            description: Yup.string(),
            summary: Yup.string().when("status", {
              is: "Publish",
              then: Yup.string().required("A summary is required"),
              otherwise: Yup.string(),
            }),
            coverImage: Yup.object().when("status", {
              is: "Publish",
              then: Yup.object()
                .required("A cover image is required to publish this event")
                .typeError("A cover image is required to publish this event"),
              otherwise: Yup.object().notRequired().nullable(),
            }),
            categories: Yup.array().when("status", {
              is: "Publish",
              then: Yup.array()
                .min(1, "You must add at least one category")
                .required("You must add at least one category."),
              otherwise: Yup.array().notRequired().nullable(),
            }),
            type: Yup.string().when("status", {
              is: "Publish",
              then: Yup.string().required("The event type is required"),
              otherwise: Yup.string().notRequired().nullable(),
            }),
            calendlyLink: Yup.string(),
            googleFormLink: Yup.string(),
            otherLink: Yup.string(),
            instructions: Yup.string(),
            startDate: Yup.number().when("status", {
              is: "Publish",
              then: Yup.number().required("An event start date is required"),
              otherwise: Yup.number().notRequired().nullable(),
            }),
            endDate: Yup.number().when("status", {
              is: "Publish",
              then: Yup.number().required("An event end date is required"),
              otherwise: Yup.number().notRequired().nullable(),
            }),
            startTime: Yup.string().when("status", {
              is: "Publish",
              then: Yup.string().required("An event start time is required"),
              otherwise: Yup.string().notRequired().nullable(),
            }),
            endTime: Yup.string().when("status", {
              is: "Publish",
              then: Yup.string().required("An event end time is required"),
              otherwise: Yup.string().notRequired().nullable(),
            }),
            userType: Yup.array().when("status", {
              is: "Publish",
              then: Yup.array()
                .required("User type is required")
                .min(1, "User type is required"),
              otherwise: Yup.array(),
            }),
            speakers: Yup.array(),
            city: Yup.string(),
            files: Yup.array(),
            ticketPrice: Yup.string().test(
              "is-currency",
              "Must be a valid price",
              (value) => {
                if (value) {
                  return isCurrency(value, { allow_negatives: false });
                }
                return true;
              }
            ),
          })
          .test(
            "instructions are present",
            "Must provide link or instructions to join the event",
            (value) =>
              (has(value, "calendlyLink") && !!value.calendlyLink?.length) ||
              (has(value, "googleFormLink") &&
                !!value.googleFormLink?.length) ||
              (has(value, "otherLink") && !!value.otherLink?.length) ||
              (has(value, "instructions") && !!value.instructions?.length) ||
              value.type !== "Virtual"
          )}
        onSubmit={async (values) => {
          const {
            categories,
            startDate,
            endDate,
            startTime,
            endTime,
            speakers,
            ticketPrice,
            jobRoles,
            location,
            status,
            calendlyLink,
            googleFormLink,
            otherLink,
            instructions,
            city,
            description,
            highlightedRank,
            isHighlighted,
            summary,
            title,
            type,
            userType,
          } = values;
          const startDateAsDate = startDate ? new Date(startDate) : new Date();
          const startDateWithTime = startDateAsDate.setHours(
            Number(startTime.split(":")[0]),
            Number(startTime.split(":")[1])
          );
          const endDateAsDate = endDate ? new Date(endDate) : new Date();
          const endDateWithTime = endDateAsDate.setHours(
            Number(endTime.split(":")[0]),
            Number(endTime.split(":")[1])
          );

          const eventVariables = {
            id: eventId,
            categories,
            dateFrom: startDateWithTime,
            dateTo: endDateWithTime,
            jobRoles,
            location,
            status,
            calendlyLink,
            googleFormLink,
            otherLink,
            instructions,
            city,
            description,
            highlightedRank,
            isHighlighted,
            speakers: speakers
              .filter((speakers) => !!speakers?.id)
              .map(({ id }) => id),
            summary,
            ticketPrice: ticketPrice ? Number(ticketPrice) * 100 : null,
            title,
            type,
            userType,
          };

          await upsertEvent({
            variables: {
              input: {
                ...eventVariables,
              },
            },
          });

          if (existingEvent) {
            addToast("success", "Event Successfully Created!");
          } else {
            addToast("success", "Event Updated!");
          }
          navigation(`/events`);
        }}
      >
        {({ values }) => {
          return (
            <>
              <FormikSaveBar />
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 12,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    UpdateStatus.update(0);
                    navigation(-1);
                  }}
                  style={{
                    borderWidth: 1,
                    borderColor: "#BABFC3",
                    borderRadius: 4,
                    width: 36,
                    height: 36,
                    marginRight: 8,
                  }}
                >
                  <Icon source={ChevronLeftMinor} />
                </TouchableOpacity>
                <Text style={styles.title}>
                  {existingId ? "Update" : "Add New"} Event
                </Text>
              </View>
              <View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <View
                    style={{
                      flex: 1,
                    }}
                  >
                    <FormSection style={{ marginBottom: 20 }}>
                      <View style={{ marginBottom: 16 }}>
                        <FormikInput
                          fieldName="title"
                          label="Title"
                          placeholder="Enter Event name"
                        />
                      </View>
                      <FormikRichText
                        fieldName="description"
                        title="Content"
                        placeholder="Add event details here"
                      />
                      <FormikTextArea
                        fieldName="summary"
                        label="Summary Text"
                        maxWordCount={50}
                        placeholder="Add event summary"
                      />
                    </FormSection>

                    <FormSection style={{ marginBottom: 20 }}>
                      <AddSpeakerOrInstructor
                        personType="Speaker"
                        formikFieldBase="speakers"
                      />
                    </FormSection>
                    <FormSection style={{ marginBottom: 20 }}>
                      <AddAttachments
                        initialExistingFiles={existingEvent?.files ?? []}
                        assetInstruction={{
                          instructionType: "one-to-many",
                          collectionId: CollectionName.EVENTS,
                          documentId: eventId,
                          key: "files",
                        }}
                      />
                    </FormSection>
                  </View>
                  <View style={{ marginLeft: 20 }}>
                    <FormikPublishTools />
                    <FileUploaderWithTitle
                      subtitle="This is the image that will be shown in the overview card"
                      title="Cover image"
                      initialAsset={existingEvent?.coverImage ?? null}
                      assetInstruction={{
                        instructionType: "one-to-one",
                        collectionId: CollectionName.EVENTS,
                        documentId: eventId,
                        key: "coverImage",
                      }}
                      formikFieldname="coverImage"
                      validFileTypes={["image/jpeg", "image/png"]}
                    />
                    <FormikEventDetails />

                    <FormikHighlightBanner
                      highlights={
                        highlightData?.adminGetAllEvents?.events || []
                      }
                    />
                  </View>
                </View>
              </View>
            </>
          );
        }}
      </Formik>
    </View>
  );
};

export { EventsEditor };
