import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const CalendarIcon = (props: SvgProps) => (
  <Svg width={15} height={15} fill="none" {...props}>
    <Path
      fill="#5C5F62"
      fillRule="evenodd"
      d="M4.98 0a1 1 0 0 1 1 1v1h4V1a1 1 0 0 1 2 0v1h1a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h1V1a1 1 0 0 1 1-1Zm-2 6v7h10V6h-10Z"
      clipRule="evenodd"
    />
  </Svg>
);

export { CalendarIcon };
