import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const ArrowUp = (props: SvgProps) => (
  <Svg width={12} height={7} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 7a.997.997 0 0 1-.707-.293L6 2.414 1.707 6.707A.999.999 0 1 1 .293 5.293l5-5a.999.999 0 0 1 1.414 0l5 5A.999.999 0 0 1 11 7Z"
      fill="#0D1738"
    />
  </Svg>
);

export { ArrowUp };
