import { TitleBox } from "@components/account/title-box";
import { ContentBox } from "@components/general/layouts/content-box";
import { Text, View } from "react-native";
import { useNavigate } from "react-router-native";
import { styles } from "./style";
import { Formik } from "formik";
import { DeleteAccountReasons } from "@components/account/delete-account-reasons/DeleteAccountReasons";

const DeleteAccount = () => {
  const navigate = useNavigate();
  const initialValues = {
    reasons: [],
    otherReason: "",
  };
  const onSubmit = () => {
    console.error("Not implemented");
  };
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      <ContentBox>
        <TitleBox
          title="Delete Account"
          hasBottomBorder
          backTitle="Back to Profile"
          onPressBack={() => navigate(-1)}
        />
        <View style={styles.container}>
          <View style={styles.infoTextContainer}>
            <Text style={styles.infoText}>
              Are you sure that you want to delete your TRN account?
            </Text>
            <Text style={styles.infoText}>
              Please note that you will lose all of your contents, events and
              courses.
            </Text>
          </View>
          <View style={styles.reasonTitleContainer}>
            <Text style={styles.reasonTitle}>
              Before you disable your account, can you tell us why you are
              leaving?
            </Text>
          </View>
          <DeleteAccountReasons />
        </View>
      </ContentBox>
    </Formik>
  );
};

export { DeleteAccount };
