import { ActivityIndicator, ScrollView, Text, View } from "react-native";
import { styles } from "./style";
import { RoadmapDataTableRow } from "./RoadmapDataTableRow";
import { Category } from "@components/back-office/roadmap/data-table/types";

interface Header {
  status: string;
  subcategory: string;
}
interface Row {
  title: string;
  content: string;
}
interface Props {
  headers: Header[];
  rows?: Row[];
  categories?: Category[];
  isScoreTable?: boolean;
}

export const RoadmapDataTable = ({
  headers,
  rows,
  categories,
  isScoreTable,
}: Props) => {
  return (
    <>
      {!isScoreTable ? (
        <View style={styles.tableWrapper}>
          {/* header */}
          {headers.map((header, index) => (
            <View style={styles.tableHeader} key={index}>
              <View style={[styles.rowItem, { flexBasis: 160 }]}>
                <Text style={styles.headerTitle}>{header.status}</Text>
              </View>

              <View style={[styles.rowItem, { flex: 1 }]}>
                <Text style={styles.headerTitle}>{header.subcategory}</Text>
              </View>
            </View>
          ))}

          {/* rows */}
          <ScrollView style={styles.scrollView}>
            {rows?.map((row, index) => (
              <View style={styles.rowsContainer} key={index}>
                <View style={[styles.rowItem, { flexBasis: 160 }]}>
                  <Text style={styles.rowText}>{row.title}</Text>
                </View>

                <View style={[styles.rowItem, { flex: 1 }]}>
                  <Text style={styles.rowText}>{row.content}</Text>
                </View>
              </View>
            ))}
          </ScrollView>
        </View>
      ) : (
        <View style={styles.tableWrapper}>
          {/* header */}

          {headers.map((header, index) => (
            <View style={styles.tableHeader} key={index}>
              <View style={[styles.rowItem, { flex: 1 }]}>
                <Text style={styles.headerTitle}>{header.status}</Text>
              </View>

              <View style={[styles.rowItem, { flexBasis: 160 }]}>
                <Text style={styles.headerTitle}>{header.subcategory}</Text>
              </View>
              <View style={[styles.rowItem, { flexBasis: 60 }]} />
            </View>
          ))}

          {/* rows */}
          <ScrollView style={styles.scrollView}>
            <View>
              {/* main category */}
              {categories && categories?.length > 0 ? (
                categories?.map((category, index) => (
                  <RoadmapDataTableRow key={index} category={category} />
                ))
              ) : (
                <ActivityIndicator size="large" color="#213470" />
              )}
            </View>
          </ScrollView>
        </View>
      )}
    </>
  );
};
