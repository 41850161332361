import React, { useEffect, useState } from "react";
import { Image, Linking, Platform, Text, View } from "react-native";
import { useLocation, useNavigate, useParams } from "react-router-native";
import { RenderHTML } from "react-native-render-html";
import dayjs from "dayjs";
import { CalendarIcon, DownloadIcon } from "@components/general/icons";
import {
  ButtonGeneral,
  ButtonVariant,
} from "@components/general/button-general";
import { InfoRow } from "@components/general/info-row";
import { Row } from "@components/general/row";
import { OpenURLButton } from "@components/general/open-url-button";
import { JoinEventBox } from "@components/events/join-event-box";
import { SpeakerSection } from "@components/events/speaker-section";
import { SideListBox } from "@components/general/side-list-box";
import { truncateString } from "@utils/misc";
import { MainBoxWithSidepanel } from "@components/general/layouts/main-box-with-sidepanel/MainBoxWithSidepanel";
import { GoogleMap } from "@components/general/google-map/GoogleMap.web";
import { HeaderRow } from "@components/general/header-row";
import { Wrapper } from "@components/general/layouts/wrapper/Wrapper";
import { ids, styles } from "./style";
import { ActionList, Popover, Frame } from "@shopify/polaris";
import { APP_URL } from "@env";
import { useUserContext } from "@context/UserContext";
import {
  ContentType,
  ItemType,
  useAddRecentlyInteractedItemMutation,
  useGetEventQuery,
  useGetRelatedEventsQuery,
  useIncrementViewCountMutation,
  useUpdateDownloadAssetStatsMutation,
} from "@gql/generated/generated";
import { useAddPageInfoToSession } from "@hooks/useSessionStorage";
import { useGetFile } from "@hooks/useGetFileUrl";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../../firebase";

export const EventWeb = () => {
  const navigate = useNavigate();

  const [showCalendar, setShowCalendar] = useState(false);
  const [showDownloads, setShowDownloads] = useState(false);
  const { id } = useParams() as { id: string };

  const { state } = useLocation() as {
    state: { id: string; categories?: string[] };
  };

  const { currentUser } = useUserContext();
  const [addRecentlyInteractedItem] = useAddRecentlyInteractedItemMutation();

  const [incrementViewCount] = useIncrementViewCountMutation();

  const { data } = useGetEventQuery({
    variables: { id },
    onCompleted: (data) => {
      if (data.getEvent.__typename === "ForbiddenError") {
        navigate("/events", { state: "ForbiddenError" });
      }
    },
  });

  const [updateDownloadStats] = useUpdateDownloadAssetStatsMutation();

  const eventData =
    data?.getEvent.__typename === "Event" ? data.getEvent : null;

  const { data: relatedEvents } = useGetRelatedEventsQuery({
    variables: {
      limit: 6,
      filters: {
        categories: state?.categories || eventData?.categories,
        excludeIds: [id],
      },
    },
    skip: !state?.categories && !eventData?.categories,
  });

  const events = relatedEvents?.getRelatedEvents;

  useAddPageInfoToSession({
    title: eventData?.title,
    type: "event",
    categories: eventData?.categories,
  });

  useEffect(() => {
    if (currentUser && eventData?.title) {
      incrementViewCount({
        variables: { id, contentType: "Events" },
      });
      addRecentlyInteractedItem({
        variables: {
          input: {
            itemId: id,
            itemType: ItemType.EVENTS,
            itemTitle: eventData.title,
          },
        },
      });
    }
  }, [
    addRecentlyInteractedItem,
    currentUser,
    eventData,
    id,
    incrementViewCount,
  ]);

  const { fileUrl: coverImage } = useGetFile(
    eventData?.coverImage?.storagePath
  );

  if (!eventData) {
    return <Text>No Event Found</Text>;
  }

  const {
    id: eventId,
    categories,
    jobRoles,
    title,
    location,
    dateFrom,
    description,
    speakers,
    calendlyLink,
    googleFormLink,
    instructions,
    otherLink,
    type,
    views,
    shareCount,
    files,
  } = eventData;

  const formattedEventDate = dateFrom
    ? dayjs(new Date(dateFrom)).format("DD MMMM YYYY, h:mma")
    : "";

  const CalendarButton = (
    <>
      <ButtonGeneral
        variant={ButtonVariant.Secondary}
        onPress={() => setShowCalendar(!showCalendar)}
      >
        <CalendarIcon />
        <Text style={styles.buttonText}>Add to Calendar</Text>
      </ButtonGeneral>
    </>
  );

  const DownloadButton = (
    <ButtonGeneral
      variant={ButtonVariant.Secondary}
      onPress={() => setShowDownloads((showDownloads) => !showDownloads)}
      disabled={!files || files.length === 0}
    >
      <DownloadIcon />
      <Text style={styles.buttonText}>Download</Text>
    </ButtonGeneral>
  );

  return (
    <Frame>
      <Wrapper style={styles.wrapper} dataSetMedia={ids.wrapper}>
        <MainBoxWithSidepanel
          mainContent={
            <>
              <HeaderRow
                eventId={id}
                title={title}
                coverImage={coverImage}
                eventDate={formattedEventDate}
                address={location}
                eventType={type}
                views={views}
                shareCount={shareCount}
                categories={categories ?? []}
              />
              <View
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: 20,
                }}
              >
                <Image
                  source={{ uri: coverImage || "" }}
                  style={{
                    height: 365,
                    borderRadius: 8,
                    width: "100%",
                  }}
                />
              </View>
              {/* <FeaturedEvents data={[eventData]} infoCardWidth="60%" /> */}
              <InfoRow
                viewCount={views}
                shareCount={shareCount || 0}
                jobRoles={jobRoles}
                hideCommentCount
                id={id}
                contentType={ContentType.EVENT}
                categories={categories}
                isContent
              />
              {/* TODO: Ensure photos can be added and add styling for nested elements */}
              <View style={styles.descriptionContainer}>
                <RenderHTML
                  defaultTextProps={{ selectable: true }}
                  source={{ html: description || "" }}
                />
              </View>
              {location ? (
                <>
                  <Text style={styles.largeBoldText}>Location</Text>
                  <OpenURLButton
                    text={location}
                    url={`https://www.google.com/maps/place/${location}`}
                  />
                  {Platform.OS === "web" && (
                    <View style={styles.mapContainer}>
                      <GoogleMap location={location} />
                    </View>
                  )}
                </>
              ) : null}
              <Row style={styles.buttonsContainer}>
                <Popover
                  active={showDownloads}
                  activator={DownloadButton}
                  onClose={() => setShowDownloads(false)}
                  preferredAlignment="left"
                >
                  {files?.length ? (
                    <ActionList
                      actionRole="menuitem"
                      items={files
                        .map((file) => {
                          return {
                            content: file.name || "",
                            onAction: async () => {
                              const downloadUrl = await getDownloadURL(ref(storage, file.storagePath));
                              updateDownloadStats({
                                variables: {
                                  input: {
                                    contentId: id,
                                    contentType: "Events",
                                    fileUrl: file.storagePath,
                                    fileTitle: file.name,
                                  },
                                },
                              });
                              Linking.openURL(downloadUrl);
                            },
                          };
                        })
                        .filter(Boolean)}
                    />
                  ) : null}
                </Popover>
                <Popover
                  active={showCalendar}
                  activator={CalendarButton}
                  onClose={() => setShowCalendar(false)}
                  preferredAlignment="left"
                >
                  <ActionList
                    actionRole="menuitem"
                    items={[
                      // TODO: Apple calendar link generation solution
                      // {
                      //   content: "Apple",
                      //   onAction: () => {
                      //     Linking.openURL(
                      //       `https://outlook.live.com/calendar/0/deeplink/compose?allday=false&enddt=2023-03-02T21%3A45%3A00%2B00%3A00&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2023-03-02T21%3A15%3A00%2B00%3A00`
                      //     );
                      //   },
                      // },
                      {
                        content: "Google",
                        // TODO: google calendar dates are not correct
                        onAction: () => {
                          if (!title) return null;
                          Linking.openURL(
                            `https://www.google.com/calendar/render?action=TEMPLATE&text=${title
                              .split(" ")
                              .join(
                                "+"
                              )}&dates=20140127T224000Z/20140320T221500Z&details=For+details,+link+here:+${APP_URL}/events/${id}&location=${location}&sf=true&output=xml`
                          );
                        },
                      },
                      {
                        content: "Outlook",
                        onAction: () => {
                          Linking.openURL(
                            `https://outlook.live.com/calendar/0/deeplink/compose?allday=false&enddt=2023-03-02T21%3A45%3A00%2B00%3A00&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2023-03-02T21%3A15%3A00%2B00%3A00`
                          );
                        },
                      },
                    ]}
                  />
                </Popover>
              </Row>
              {speakers?.map((speaker: any, index: number) => {
                return (
                  <SpeakerSection
                    speaker={speaker}
                    key={speaker.name}
                    index={index}
                  />
                );
              })}
            </>
          }
          sidePanelContent={
            <>
              <JoinEventBox
                calendlyLink={calendlyLink}
                googleFormLink={googleFormLink}
                otherLink={otherLink}
                instructions={instructions}
                isOnline={type === "Virtual" || type === "Hybrid"}
                eventId={eventId}
                eventType={type}
              />
              {events ? (
                <SideListBox
                  marginTop={16}
                  items={events.map((event) => ({
                    image: event?.coverImage,
                    onPress: () =>
                      navigate(`/events/${event?.id}`, {
                        state: { id: event?.id },
                      }),
                    linkUrl: `/events/${event?.id}`,
                    title: event?.title || "Untitled event",
                    date: dateFrom,
                    bottomRow: (
                      <Text style={styles.subduedText}>
                        {event?.location
                          ? truncateString(event.location, 35)
                          : ""}
                      </Text>
                    ),
                  }))}
                  title="Related Events"
                  linkText="View all Events"
                  linkLocation="/events"
                />
              ) : null}
            </>
          }
        />
      </Wrapper>
    </Frame>
  );
};
