import React, { useMemo, useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";

import {
  TrainingProgressLesson,
  TrainingProgressModule,
  TrainingProgress as TTrainingProgress,
} from "@gql/generated/generated";
import { TouchableOpacityLink } from "@components/general/touchable-opacity-link";
import { styles } from "./style";
import { ExternalSmallMinor } from "@components/general/icons/back-office-icons/ExternalSmallMinor";
import { ChevronUpIcon } from "@components/general/icons";
import { FileIcon, PlayIcon } from "@components/general/icons/training-icons";
import { CaretUp } from "@components/general/icons/account-icons/CaretUp";
import { CaretDown } from "@components/general/icons/account-icons/CaretDown";

function CourseProgress({
  trainingProgress,
}: {
  trainingProgress: TTrainingProgress;
}) {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: 325,
      }}
    >
      <Text style={{ width: 100, marginRight: 5, fontSize: 12 }}>
        {trainingProgress.completionPercentage}% complete
      </Text>
      <View
        style={{
          width: 100,
          height: 8,
          backgroundColor: "#E4E5E7",
          borderRadius: 4,
        }}
      >
        <View
          style={{
            width: trainingProgress.completionPercentage,
            height: 8,
            backgroundColor: "#2C6ECB",
            borderRadius: 4,
          }}
        />
      </View>
    </View>
  );
}

function ViewCourse({ to }: { to: string }) {
  return (
    <TouchableOpacityLink
      style={[styles.viewCourse]}
      onPress={() => window.open(to)}
      href={to}
    >
      View course
      <ExternalSmallMinor style={[styles.viewCourseIcon]} />
    </TouchableOpacityLink>
  );
}

function DropdownButton({
  isHidden,
  toggleHideModules,
}: {
  isHidden: boolean;
  toggleHideModules: () => void;
}) {
  return (
    <TouchableOpacity
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#fff",
        borderRadius: 8,
        width: 36,
        height: 36,
        borderWidth: 1,
        borderColor: '#c5c5c5',
      }}
      onPress={toggleHideModules}
    >
      <Text>
        {isHidden ? "+" : "-"}
      </Text>
    </TouchableOpacity>
  );
}

function CourseHeader({
  amountOfModules,
  amountOfLessons,
}: {
  amountOfModules: number;
  amountOfLessons: number;
}) {
  return (
    <Text style={{ marginBottom: 10 }}>
      {amountOfModules} Modules •{" "}
      {amountOfLessons} lessons
    </Text>
  );
}

function CourseLessonIconType({ lessonType }: { lessonType: TrainingProgressLesson['lessonType'] }) {
  return (
    <View style={{ width: 20, height: 20, marginRight: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {lessonType === 'video' && <PlayIcon />}
      {lessonType === 'document' && <FileIcon />}
    </View>
  )
};

function CourseLesson({ lesson }: { lesson: TrainingProgressLesson }) {
  const [isHidden, setIsHidden] = useState(true);
  
  function toggleHidden() {
    setIsHidden((prevState) => !prevState);
  }
  
  return (
    <TouchableOpacity
      style={{
        marginBottom: 20
      }}
      onPress={toggleHidden}
    >
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center', 
        }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <CourseLessonIconType lessonType={lesson.lessonType} />
          <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Text style={{ fontFamily: 'OpenSans_400Regular', lineHeight: 20, fontSize: 14, color: '#202223', maxWidth: 300, marginRight: 10 }}>{lesson.lessonTitle}</Text>
            {/* {!isHidden ? <CaretUp /> : <CaretDown />} */}
          </View>
        </View>
        <Text
          style={{
            backgroundColor: lesson.lessonCompleted ? '#2C6ECB' : '#E4E5E7',
            color: lesson.lessonCompleted ? 'white' : 'black',
            paddingHorizontal: 8,
            paddingVertical: 2,
            fontSize: 12,
            lineHeight: 16,
            borderRadius: 15,
          }}
        >{lesson.lessonCompleted ? "Completed" : "Incomplete"}</Text>
      </View>
    </TouchableOpacity>
  );
}

function CourseModule({ module }: { module: TrainingProgressModule }) {
  const [isHidden, setIsHidden] = useState(true);
  const amountOfLessons = module?.lessons?.length ?? 0;
  const sortedLessons = useMemo(() => {
    return [...module.lessons].sort((a, b) => a.rank - b.rank);
  }, [module?.lessons]);

  function toggleHidden() {
    setIsHidden((prevState) => !prevState);
  }

  return (
    <View>
      <TouchableOpacity
        style={{
          backgroundColor: "#F6F6F7",
          borderWidth: 1,
          borderColor: '#EDEEEF',
          padding: 10,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        onPress={toggleHidden}
      >
        <View style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}>
          <ChevronUpIcon
            // @ts-ignore
            style={{
              marginLeft: 5,
              marginRight: 10,
              transform: isHidden ? [{ rotate: "180deg" }] : "unset",
            }}
          />
          <Text style={{ fontFamily: "OpenSans_600SemiBold", color: "#0D1738", lineHeight: 20, fontSize: 14, width: 600 }}>{module.moduleTitle}</Text>
        </View>
        <Text style={{ fontFamily: "OpenSans_600SemiBold", color: "#0D1738", lineHeight: 20, fontSize: 14 }}>{amountOfLessons} lessons</Text>
      </TouchableOpacity>
      {!isHidden && (
        <View
          style={{
            backgroundColor: "#fff",
            borderWidth: 1, 
            borderColor: '#EDEEEF',
            paddingTop: 20,
            paddingRight: 20,
            paddingLeft: 20,
          }}
        >
          {sortedLessons.map(l => <CourseLesson key={l.lessonId} lesson={l} />)}
        </View>
      )}
    </View>
  );
}

function Course({
  trainingProgress,
}: {
  trainingProgress: TTrainingProgress;
}) {
  const clonedModules = trainingProgress?.modules ?? [];
  const amountOfModules = clonedModules.length ?? 0;
  const amountOfLessons = clonedModules.reduce((acc, module) => acc + module.lessons.length, 0) ?? 0;
  
  const sortedModules = useMemo(() => {
    return [...clonedModules].sort((a, b) => a.rank - b.rank);
  }, [clonedModules]);

  return (
    <View style={{ paddingHorizontal: 40, paddingVertical: 20 }}>
      <CourseHeader amountOfModules={amountOfModules} amountOfLessons={amountOfLessons} />
      {amountOfModules > 0 && (
        sortedModules.map(m => <CourseModule key={m.moduleId} module={m} />)
      )}
    </View>
  );
}

export function TrainingProgressCourse({
  trainingProgress,
}: {
  trainingProgress: TTrainingProgress;
}) {
  const [hideModules, setHideModules] = useState(true);

  function toggleHideModules() {
    setHideModules((prevState) => !prevState);
  }

  return (
    <>
      <View style={styles.trainingProgressContainer}>
        <Text style={styles.trainingTitle}>
          {trainingProgress.trainingTitle}
        </Text>
        <CourseProgress trainingProgress={trainingProgress} />
        <ViewCourse to={`/training/${trainingProgress.trainingId}`} />
        <DropdownButton
          isHidden={hideModules}
          toggleHideModules={toggleHideModules}
        />
      </View>
      {!hideModules && <Course trainingProgress={trainingProgress} />}
    </>
  );
}
