import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  inputWrap: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    height: "100%",
  },
  inputItem: {
    width: "48%",
  },
});
