import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    borderRadius: 15,
    alignItems: "center",
    "@media (max-width: 1200px)": {
      display: "none",
    },
  },
  itemWrap: {
    marginBottom: 20,
  },
  itemTitle: {
    color: "#6D7175",
    fontSize: 12,
    fontFamily: "OpenSans_400Regular",
  },
});
