import { useState } from "react";
import { useUserContext } from "../../../context/UserContext";
import { View } from "react-native";
import { Formik, FormikValues } from "formik";
import { ContentBox } from "@components/general/layouts/content-box";
import { TitleBox } from "@components/account/title-box";
import { ListItem } from "@components/account/list-item";
import { useNavigate } from "react-router-native";
import { styles } from "../settings/style";
import { JobForm } from "./forms/job-form/JobForm";
import { CompanyForm } from "./forms/company-form/CompanyForm";
import { initialValuesJobRole } from "../settings/initialValues";
import {
  clearFormikState,
  filterUnwantedInputKeysForSubmission,
} from "../settings/settingsUtils";
import { useUpdateUserMutation } from "@gql/generated/generated";

const JobRole = () => {
  const { currentUser } = useUserContext();
  const [openEditMode, setOpenEditMode] = useState({
    jobTitle: false,
    companyInfo: false,
    educationInfo: false,
  });
  const navigate = useNavigate();

  const inputKeysToBeSubmitted = [
    ...(!openEditMode.jobTitle ? ["jobPosition"] : []),
    ...(!openEditMode.companyInfo ? ["companyName", "yearsInCompany"] : []),
  ];

  const handleSubmit = (values: FormikValues) => {
    const { yearsInCompany } = values;
    const modifyValues = {
      ...values,
      yearsInCompany: yearsInCompany !== "" ? +values.yearsInCompany : "",
    };

    if (!currentUser) return;
    updateUser({
      variables: {
        input: {
          user: filterUnwantedInputKeysForSubmission(
            modifyValues,
            inputKeysToBeSubmitted
          ),
        },
      },
    });
    clearFormikState(values, initialValuesJobRole);
  };

  const closeEditMode = (key: string) => {
    setOpenEditMode({ ...openEditMode, [key]: false });
  };

  const [updateUser] = useUpdateUserMutation();

  const userInitialValues = {
    ...initialValuesJobRole,
    jobPosition: currentUser?.accountInfo?.companyInfo?.jobPosition,
    companyName: currentUser?.accountInfo?.companyInfo?.name,
  };

  return (
    <View style={styles.wrap}>
      <Formik
        initialValues={userInitialValues}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        <ContentBox
          style={{
            marginBottom: 20,
          }}
        >
          <TitleBox
            title="Job Role"
            backTitle="Back to Settings"
            onPressBack={() => navigate("/account/settings")}
          />
          {!openEditMode.jobTitle ? (
            <ListItem
              title="Job Title"
              subtitle={`${
                currentUser?.accountInfo?.companyInfo?.jobPosition
                  ? currentUser?.accountInfo?.companyInfo?.jobPosition
                  : "Resourer"
              }`}
              edit="Edit"
              onPress={() =>
                setOpenEditMode({ ...openEditMode, jobTitle: true })
              }
            />
          ) : (
            <JobForm closeEditMode={() => closeEditMode("jobTitle")} />
          )}
          {!openEditMode.companyInfo ? (
            <ListItem
              title="Company Name"
              subtitle={`${
                currentUser?.accountInfo?.companyInfo?.name
                  ? currentUser?.accountInfo?.companyInfo?.name
                  : "Silver Swan Recruitment"
              }`}
              secondaryTitle="Years in Company"
              secondarySubtitle={`${
                currentUser?.accountInfo?.companyInfo?.yearsInCompany
                  ? currentUser?.accountInfo?.companyInfo?.yearsInCompany
                  : "7"
              } years`}
              edit="Edit"
              onPress={() =>
                setOpenEditMode({ ...openEditMode, companyInfo: true })
              }
            />
          ) : (
            <CompanyForm closeEditMode={() => closeEditMode("companyInfo")} />
          )}
        </ContentBox>
      </Formik>
    </View>
  );
};

export { JobRole };
