import React, { useEffect, useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { useLocation, useNavigate } from "react-router-native";
import {
  AlignLeftIcon,
  EventIcon,
  HomeIcon,
  TrainingIcon,
} from "@components/general/icons";
import { styles } from "./style";
import { MOBILE_SCREEN_WIDTH } from "@utils/misc";

interface NavItemProps {
  active: boolean;
  icon: React.ReactNode;
  title: string;
  onPress: () => void;
}

const NavItem = ({ active, icon, title, onPress }: NavItemProps) => (
  <TouchableOpacity style={styles.navItem} onPress={onPress}>
    <View style={[styles.borderView, active && styles.activeBorder]} />
    {icon}
    <Text style={[styles.navItemText, active && styles.navItemTextActive]}>
      {title}
    </Text>
  </TouchableOpacity>
);

const MobileNavbar = () => {
  const [isMobileNavEnabled, setIsMobileNavEnabled] = useState(
    window.innerWidth <= MOBILE_SCREEN_WIDTH
  );
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleNav = (path: string) => {
    navigate(path);
  };

  const updateIsMobileNavEnabled = () => {
    setIsMobileNavEnabled(window.innerWidth <= MOBILE_SCREEN_WIDTH);
  };

  useEffect(() => {
    window.addEventListener("resize", updateIsMobileNavEnabled);
    return () => {
      window.removeEventListener("resize", updateIsMobileNavEnabled);
    };
  });

  const getColorForCurrentPath = (itemPath: string, isEqual?: boolean) => {
    if (isEqual) {
      return pathname === itemPath ? "#0D1738" : "#5C5F62";
    } else {
      return pathname.startsWith(itemPath) ? "#0D1738" : "#5C5F62";
    }
  };

  return (
    <>
      {isMobileNavEnabled && (
        <View style={styles.wrap}>
          <NavItem
            title="Feed"
            active={pathname === "/"}
            icon={<HomeIcon color={getColorForCurrentPath("/", true)} />}
            onPress={() => handleNav("/")}
          />
          <NavItem
            title="Content"
            active={pathname.startsWith("/content")}
            icon={<AlignLeftIcon color={getColorForCurrentPath("/content")} />}
            onPress={() => handleNav("/content")}
          />
          <NavItem
            title="Events"
            active={pathname.startsWith("/events")}
            icon={<EventIcon color={getColorForCurrentPath("/events")} />}
            onPress={() => handleNav("/events")}
          />
          <NavItem
            title="Training"
            active={pathname.startsWith("/training")}
            icon={<TrainingIcon color={getColorForCurrentPath("/training")} />}
            onPress={() => handleNav("/training")}
          />
        </View>
      )}
    </>
  );
};

export { MobileNavbar };
