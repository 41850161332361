import React, { useState } from "react";
import { FlatList } from "react-native";
import { FilterWrapper } from "@components/filter-mobile/filter-wrapper";
import { FilterCheckbox } from "@components/filter-mobile/filter-checkbox";

export enum JobRoleOptions {
  directorOwner = "Director/Owner",
  seniorManager = "Senior Manager",
  billingManagerTeamLeader = "Billing Manager/Team Leader",
  accountManager = "Account Manager",
  managerExecutive = "Marketing - Heads of/Manager/Executive",
  financeExecutive = "Finance - Heads of/Manager/Executive",
  operationsExecutive = "Operations - Heads of/Manager/Executive",
  talentExecutive = "Talent and L&D - Heads of/Manager/Executive",
  hrExecutive = "HR - Heads of/Manager/Executive",
  consultant = "Consultant",
  resourcer = "Resourcer",
}

const jobRoleOptions = [
  JobRoleOptions.directorOwner,
  JobRoleOptions.seniorManager,
  JobRoleOptions.billingManagerTeamLeader,
  JobRoleOptions.accountManager,
  JobRoleOptions.managerExecutive,
  JobRoleOptions.financeExecutive,
  JobRoleOptions.operationsExecutive,
  JobRoleOptions.talentExecutive,
  JobRoleOptions.hrExecutive,
  JobRoleOptions.consultant,
  JobRoleOptions.resourcer,
];

interface Props {
  isVisible: boolean;
  onClose: () => void;
  onSave: (jobRoles: JobRoleOptions[]) => void;
}

const JobRole = ({ isVisible, onClose, onSave }: Props) => {
  const [jobRoles, setJobRoles] = useState<JobRoleOptions[]>([]);

  const handleSelect = (jobRole: JobRoleOptions) => {
    if (jobRoles.includes(jobRole)) {
      const index = jobRoles.indexOf(jobRole);
      const jobRolesCopy = [...jobRoles];
      jobRolesCopy.splice(index, 1);
      setJobRoles(jobRolesCopy);
    } else {
      setJobRoles([...jobRoles, jobRole]);
    }
  };

  const renderItem = ({ item }: { item: JobRoleOptions }) => (
    <FilterCheckbox
      title={item}
      isSelected={jobRoles.includes(item)}
      onToggle={() => handleSelect(item)}
    />
  );

  return (
    <FilterWrapper
      headerTitle="Job Role"
      isVisible={isVisible}
      onClose={onClose}
      buttonTitle="Save"
      isDoneButtonDisabled={!jobRoles.length}
      onDone={() => jobRoles.length && onSave(jobRoles)}
    >
      <FlatList
        data={jobRoleOptions}
        renderItem={renderItem}
        keyExtractor={(item) => item}
      />
    </FilterWrapper>
  );
};

export { JobRole };
