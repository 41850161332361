import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  wrapper: {
    padding: 20,
    paddingBottom: 40,
  },
  titleWrap: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  title: {
    color: "#0D1738",
    fontSize: 16,
    fontFamily: "OpenSans_600SemiBold",
    marginBottom: 4,
  },
  subtitle: {
    color: "#6D7175",
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
  },
  video: {
    width: "100%",
    position: "relative",
    maxHeight: 587,
  },
  playIconWrap: {
    display: "none",
    height: 45,
    width: 45,
    borderRadius: 22.5,
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderColor: "#B5B5B5",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    left: "50%",
    top: "40%",
  },
  videoWrap: {
    alignItems: "center",
    justifyContent: "center",
  },
});
