import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { ISvgProps } from "../../../types";

const CirclePlus = ({ color = "#5C5F62", ...rest }: ISvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    {...rest}
  >
    <Path
      fill={color}
      fillRule="evenodd"
      d="M13 8a1 1 0 01-1 1H9v3a1 1 0 11-2 0V9H4a1 1 0 110-2h3V4a1 1 0 012 0v3h3a1 1 0 011 1zM8 0a8 8 0 100 16A8 8 0 008 0z"
      clipRule="evenodd"
    />
  </Svg>
);

export { CirclePlus };
