import {
  ActivityIndicator,
  Modal,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import React, { useContext, useState } from "react";
import { ContentBox } from "@components/general/layouts/content-box";
import { TitleBox } from "@components/account/title-box";
import { styles } from "./styles";
import { GoCardLessIcon } from "@components/general/icons/account-icons/GoCardLessIcon";
import { ModalContainer } from "@components/org/saved/modals/ModalContainer";
import { GenericModalLayout } from "@components/org/saved/modals/generic-modal-layout/GenericModalLayout";
import { ConfirmationModalContent } from "@components/org/saved/modals/confirmation-modal/ConfirmationModalContent";
import { Formik } from "formik";
import { BillingAddressEditMode } from "@components/account/subscription-plan/billing-address/BillingAddressEditMode";
import { ButtonLayout } from "../profile/button-layout/ButtonLayout";
import { MenuContainer } from "@components/account/subscription-plan/menu-container/MenuContainer";
import { subscriptionPlanContext } from "@context/SubscriptionPlanProvider";
import { useUserContext } from "@context/UserContext";
import { useNavigate } from "react-router-native";
import {
  useCreateOrganisationBillingAddressMutation,
  useEmployerGetGoCardlessCustomerQuery,
  useSubscribeGoCardlessCustomerMutation,
} from "@gql/generated/generated";

interface PayButtonProps {
  onPress?: () => void;
  disable: boolean;
  text?: string;
  isLoading?: boolean;
}

// TODO: Move to own file
export const PayButton = ({
  onPress,
  disable,
  text,
  isLoading,
}: PayButtonProps) => {
  return (
    <TouchableOpacity
      style={[styles.payButton, disable && { backgroundColor: "#F1F1F1" }]}
      onPress={onPress}
      disabled={disable}
    >
      {isLoading ? (
        <View style={{ paddingVertical: 10, paddingHorizontal: 50 }}>
          <ActivityIndicator color="#ffffff" />
        </View>
      ) : (
        <Text style={[styles.payButtonText, disable && { color: "#8B9197" }]}>
          {text}
        </Text>
      )}
    </TouchableOpacity>
  );
};

const Payment = () => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [billInfoEdit, setBillInfoEdit] = useState(false);
  const { selectedPlan } = useContext(subscriptionPlanContext);
  const { currentUser } = useUserContext();
  const navigate = useNavigate();
  const [subscribeGoCardlessCustomer] =
    useSubscribeGoCardlessCustomerMutation();
  const [createOrganisationBillingAddress] =
    useCreateOrganisationBillingAddressMutation();
  const { data: goCardLessCustomer } = useEmployerGetGoCardlessCustomerQuery();
  const goCardLessCustomerData =
    goCardLessCustomer?.employerGetGoCardlessCustomer;

  const initialValues = {
    houseNumber: currentUser?.subscription?.billingAddress?.houseNumber || "",
    addressOne: currentUser?.subscription?.billingAddress?.addressOne || "",
    postCode: currentUser?.subscription?.billingAddress?.postCode || "",
    city: currentUser?.subscription?.billingAddress?.city || "",
    country: currentUser?.subscription?.billingAddress?.country || "",
    addressTwo: currentUser?.subscription?.billingAddress?.addressTwo || "",
    countryCode: currentUser?.subscription?.billingAddress?.countryCode || "",
  };

  const onSubmit = async (values: {
    addressOne: string;
    postCode: string;
    city: string;
    country: string;
    countryCode: any;
    addressTwo: string;
  }) => {
    if (!currentUser?.accountInfo?.companyInfo.id)
      return console.error("organisationId is required");
    await createOrganisationBillingAddress({
      variables: {
        input: {
          id: currentUser.accountInfo.companyInfo.id,
          ...values,
        },
      },
    });
  };

  const subscriptionPlusHandler = async () => {
    await subscribeGoCardlessCustomer({
      variables: {
        input: {
          plan: selectedPlan,
        },
      },
    });
    setShowSuccessModal(true);
  };

  const subscriptionClubHandler = () => {
    // TODO: Add subscription club handler with db pending
    console.error("Not implemented");
  };

  return (
    <ContentBox
      style={{
        paddingBottom: 20,
        flex: 1,
      }}
    >
      <Modal visible={showSuccessModal} transparent>
        <ModalContainer>
          <GenericModalLayout
            title="Success!"
            closeModal={() => setShowSuccessModal(false)}
            hasButtonLayout
            buttonName="View Benefits"
            onActionButtonPress={() => setShowSuccessModal(false)}
            withCancel={false}
            footerContainerStyle={{
              borderTopWidth: 0,
            }}
            actionButtonStyle={{
              paddingHorizontal: 35,
              paddingVertical: 5,
              borderRadius: 35,
            }}
          >
            <ConfirmationModalContent
              mainText={`Your plan has been upgraded to TRN Plus. We will send you the receipt
via email.`}
              secondaryText=""
              containerStyles={{
                marginBottom: 10,
              }}
            />
          </GenericModalLayout>
        </ModalContainer>
      </Modal>
      <TitleBox title="Proceed to payment" hasBottomBorder />
      <View style={styles.containerBottomBorder}>
        <View style={{ paddingHorizontal: 40 }}>
          <View style={styles.paymentMethodHeader}>
            <Text style={styles.paymentMethodTitle}>Payment Method</Text>
          </View>
          <View style={styles.rightSideContainer}>
            <View style={styles.image}>
              <GoCardLessIcon />
            </View>
            <View style={styles.paymentInfoContainer}>
              <View style={styles.paymentInfo}>
                <Text style={styles.paymentInfoTitle}>Company Name</Text>
                <Text style={styles.paymentInfoTitle}>Account Number</Text>
                <Text style={styles.paymentInfoTitle}>Postcode</Text>
              </View>
              {goCardLessCustomerData ? (
                <View>
                  <Text style={styles.paymentInfoValue}>
                    {goCardLessCustomerData?.bankDetails?.accountHolderName}
                  </Text>
                  <Text style={styles.paymentInfoValue}>
                    ••••••
                    {goCardLessCustomerData?.bankDetails?.accountNumberEnding}
                  </Text>
                  <Text style={styles.paymentInfoValue}>
                    {goCardLessCustomerData?.bankDetails?.bankName}
                  </Text>
                </View>
              ) : null}
            </View>
          </View>
        </View>
      </View>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {!billInfoEdit ? (
          <View>
            <View style={styles.containerBottomBorder}>
              <View style={{ paddingHorizontal: 40 }}>
                <View style={styles.billingDetailsContainer}>
                  <Text style={styles.paymentMethodTitle}>Billing Details</Text>
                  <Text
                    style={styles.editButton}
                    onPress={() => setBillInfoEdit(true)}
                  >
                    Edit
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    marginBottom: 20,
                  }}
                >
                  <View
                    style={{
                      marginRight: 84,
                    }}
                  >
                    <Text
                      style={[
                        styles.billingDetailsSubTitle,
                        {
                          marginBottom: 4,
                        },
                      ]}
                    >
                      Billing Details
                    </Text>
                    {goCardLessCustomerData?.subscription ? (
                      <Text style={styles.billingDetailsTitle}>
                        Next billing date -{" "}
                        <Text
                          style={[
                            styles.billingDetailsSubTitle,
                            { color: "#202223" },
                          ]}
                        >
                          {goCardLessCustomerData?.subscription?.nextChargeDate}
                        </Text>
                      </Text>
                    ) : null}
                  </View>
                  <View>
                    <Text
                      style={[
                        styles.billingDetailsSubTitle,
                        {
                          marginBottom: 4,
                        },
                      ]}
                    >
                      Billing Address
                    </Text>
                    {goCardLessCustomerData ? (
                      <View>
                        <Text style={styles.billingDetailsInfo}>
                          {currentUser?.subscription?.billingAddress
                            ? `${currentUser?.subscription?.billingAddress?.houseNumber}, ${currentUser?.subscription?.billingAddress?.addressOne}`
                            : ""}
                        </Text>
                        <Text style={styles.billingDetailsInfo}>
                          {" "}
                          {currentUser?.subscription?.billingAddress
                            ? currentUser?.subscription?.billingAddress
                                ?.postCode
                            : ""}
                        </Text>
                        <Text style={styles.billingDetailsInfo}>
                          {currentUser?.subscription?.billingAddress
                            ? `${currentUser?.subscription?.billingAddress?.city}, ${currentUser?.subscription?.billingAddress?.country}`
                            : ""}
                        </Text>
                      </View>
                    ) : null}
                  </View>
                </View>
              </View>
            </View>
          </View>
        ) : (
          <MenuContainer
            title="Billing Address"
            onPress={() => setBillInfoEdit(!billInfoEdit)}
            isMenuOpen={billInfoEdit}
          >
            <View
              style={{
                justifyContent: "space-between",
              }}
            >
              <BillingAddressEditMode />
              <ButtonLayout
                closeEditMode={() => setBillInfoEdit(false)}
                submitCloseHander={() => {
                  setBillInfoEdit(false);
                }}
                labelActionButton="Save"
                containerButtonStyle={{
                  justifyContent: "flex-start",
                  marginTop: 16,
                }}
              />
            </View>
          </MenuContainer>
        )}
      </Formik>

      <>
        {selectedPlan !== "Free" ? (
          <View style={{ paddingHorizontal: 40 }}>
            <View style={styles.yourPurchaseHeader}>
              <Text style={styles.paymentMethodTitle}>Your Purchase</Text>
            </View>
            <View style={styles.containerBottomBorder}>
              <View style={styles.yourPurchasePlanContainer}>
                <Text style={styles.yourPurchasePlanTitle}>Plan</Text>
                <Text style={styles.yourPurchasePlanValue}>{selectedPlan}</Text>
              </View>
            </View>
            <View style={styles.containerBottomBorder}>
              <View style={styles.yourPurchasePlanContainer}>
                <Text style={styles.yourPurchasePlanTitle}>Total</Text>
                <View>
                  <Text style={styles.yourPurchasePlanPrice}>
                    {selectedPlan === "Plus" ? "£249" : "£599"}
                  </Text>
                  <Text style={styles.yourPurchasePlanTitle}>
                    Billed Monthly
                  </Text>
                </View>
              </View>
            </View>
          </View>
        ) : null}
        {currentUser?.subscription?.plan === "Free" &&
        selectedPlan === currentUser?.subscription.plan ? (
          <View style={styles.paymentFooterContainer}>
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                marginTop: 40,
              }}
            >
              <Text
                style={{
                  marginBottom: 20,
                  color: "#000000",
                  fontSize: 14,
                  fontFamily: "OpenSans_600SemiBold",
                }}
              >
                Please upgrade to Club or Plus to submit payment.
              </Text>
              <TouchableOpacity
                onPress={() => navigate("/account/settings/subscription-plan")}
                style={{
                  paddingVertical: 8,
                  paddingHorizontal: 16,
                  backgroundColor: "##FFFFFF",
                  borderRadius: 4,
                  borderWidth: 1,
                  borderColor: "#BABFC3",
                }}
              >
                <Text
                  style={{
                    color: "#202223",
                    fontSize: 14,
                    fontFamily: "OpenSans_600SemiBold",
                    lineHeight: 20,
                  }}
                >
                  Subscription Plan
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        ) : (
          <View style={styles.paymentFooterContainer}>
            <View>
              <Text style={styles.paymentFooterText}>
                By placing an order at therecruitmentnetwork.com, you’re
                agreeing
              </Text>
              <View
                style={{
                  flexDirection: "row",
                }}
              >
                <Text style={styles.paymentFooterText}>to our </Text>
                <Text style={styles.editButton}>
                  Privacy Policy, Terms and Conditions and Cancellation policy.
                </Text>
              </View>
            </View>
            {selectedPlan !== currentUser?.subscription?.plan ? (
              <PayButton
                text="Confirm and Pay"
                onPress={
                  selectedPlan === "Plus"
                    ? subscriptionPlusHandler
                    : subscriptionClubHandler
                }
                disable={false}
              />
            ) : (
              <PayButton
                text={`Next Payment ${
                  goCardLessCustomerData
                    ? goCardLessCustomerData?.subscription?.nextChargeDate
                    : ""
                }`}
                disable
              />
            )}
          </View>
        )}
      </>
    </ContentBox>
  );
};

export { Payment };
