import React from "react";
import { View } from "react-native";
import Progress from "react-native-circular-progress-indicator";
import { getRoadmapProgressColor } from "@utils/misc";
import { styles } from "./style";

interface Props {
  percent: number;
  isTitleVisible?: boolean;
}

const CircularProgress = ({ percent, isTitleVisible }: Props) => {
  return (
    <View>
      <Progress
        clockwise={false}
        value={percent}
        inActiveStrokeOpacity={0.4}
        inActiveStrokeColor={getRoadmapProgressColor(percent).color}
        title={isTitleVisible ? `${percent}%` : ""}
        titleStyle={styles.title}
        activeStrokeColor={getRoadmapProgressColor(percent).fillColor}
        activeStrokeWidth={20}
        inActiveStrokeWidth={20}
        initialValue={percent}
        showProgressValue={false}
      />
    </View>
  );
};

export { CircularProgress };
