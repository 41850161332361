import React, { useEffect, useState } from "react";
import {
  Image as ReactNativeImage,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { Pill } from "@components/general/pill";
import { StarIcon } from "@components/general/icons/feed-icons";
import { styles, ids } from "./style";
import { Content, DocumentAsset } from "@gql/generated/generated";
import { Image } from "@components/general/image-from-storage";
import { Row } from "@components/general/row";
import { TooltipWithChild } from "@components/general/tooltip";

export interface Props {
  imageUrl?: string;
  asset?: DocumentAsset | null;
  title: string;
  date: string;
  time?: string;
  categories?: string[];
  visibleCategoryCount?: number;
  isRating?: boolean;
  subtitle?: string;
  onPress?: () => void;
  hasPadding?: boolean;
  query?: string;
  description?: Content["description"];
}

const SearchItem = ({
  asset,
  imageUrl, // TODO: remove this field in favour of the asset field
  title,
  date,
  time,
  categories,
  visibleCategoryCount = 2,
  isRating,
  subtitle,
  onPress,
  hasPadding,
  query,
  description,
}: Props) => {
  const [isHovered, setIsHovered] = useState(false);
  const [highlightedTitle, setHighlightedTitle] = useState<
    (JSX.Element | string)[]
  >([title]);

  const getQueryWords = () => (query || "").split(/\s+/);

  const generateHighlightedTitle = (query: string) => {
    const titleComponents: (JSX.Element | string)[] = [];

    const wordsRegEx = new RegExp(query.split(/\s+/).join("|"), "gi");
    const matches = title.matchAll(wordsRegEx);

    let index = 0;
    for (const match of matches) {
      if (match.index == null) {
        break;
      }
      const plainText = title.slice(index, match.index);
      titleComponents.push(plainText);
      index = match.index;

      const highlightedText = title.slice(index, index + match[0].length);
      titleComponents.push(
        <Text
          key={`${index}-${highlightedText}`}
          style={styles.highlightedTitle}
        >
          {highlightedText}
        </Text>
      );
      index += match[0].length;
    }
    const remainingText = title.slice(index, title.length);
    titleComponents.push(remainingText);

    return titleComponents;
  };

  const renderCategories = (
    categories: Props["categories"],
    highlights: string[]
  ) => {
    type CategoryPillRenderProps = {
      category: string;
      isMatch: boolean;
    };
    const categoryCount = categories?.length || 0;

    const isCategoryMatch = (category: string) =>
      highlights.some(
        (highlightWord) =>
          highlightWord.toLowerCase() === category.toLowerCase()
      );

    const CategoryPill = ({ category, isMatch }: CategoryPillRenderProps) => (
      <View
        key={category}
        style={styles.categoryPill}
        dataSet={{ media: ids.categoryPill }}
      >
        <Pill text={category} {...{ isMatch }} allowShrink withMarginRight />
      </View>
    );

    const CategoryPills = () => (
      <>
        {categories?.slice(0, visibleCategoryCount).map((category, index) => (
          <CategoryPill
            key={`${title}${category}${index}`}
            {...{ category, isMatch: isCategoryMatch(category) }}
          />
        ))}
      </>
    );

    const OverflowCategories = () => (
      <>
        {categoryCount > visibleCategoryCount && (
          <TooltipWithChild
            toolTipText={categories?.slice(visibleCategoryCount).join(", ")}
            width={100}
          >
            <Pill text={`+${categoryCount - visibleCategoryCount}`} />
          </TooltipWithChild>
        )}
      </>
    );

    const CategoryPillsWithOverflow = () => (
      <>
        <CategoryPills />
        <OverflowCategories />
      </>
    );

    return (
      <Row style={[styles.categoriesRow]}>
        <CategoryPillsWithOverflow />
      </Row>
    );
  };

  const renderSubtitle = () => {
    // if match in body, return subtitle
    const matchInBody = getQueryWords().some((word) =>
      description?.includes(word)
    );

    if (matchInBody) {
      return <Text style={styles.subtitle}>Appears in body content</Text>;
    }

    // Default return date / duration Text
    return (
      <Text style={styles.date}>
        {date} {time && `• ${time}`}
      </Text>
    );
  };

  useEffect(() => {
    if (!query) {
      setHighlightedTitle([title]);
      return;
    }

    setHighlightedTitle(generateHighlightedTitle(query));
  }, [query, title]);

  return (
    <TouchableOpacity
      style={[
        styles.wrap,
        isHovered && { backgroundColor: "#F6F6F7" },
        hasPadding && styles.paddingWrap,
      ]}
      onPress={onPress}
      // @ts-ignore
      onMouseEnter={() => setIsHovered(true)}
      // @ts-ignore
      onMouseLeave={() => setIsHovered(false)}
    >
      <View style={styles.leftBox}>
        {asset ? <Image path={asset.storagePath} style={styles.image} /> : null}
        {imageUrl ? (
          <ReactNativeImage source={{ uri: imageUrl }} style={styles.image} />
        ) : null}
      </View>
      <View style={styles.rightBox}>
        <Text style={styles.title} numberOfLines={1}>
          {highlightedTitle}
        </Text>
        {renderSubtitle()}
        <View style={styles.pillWrap}>
          {renderCategories(categories, getQueryWords())}
          {isRating && <StarIcon />}
          {subtitle ? (
            <Text
              style={[styles.pillWrapSubtitle, isRating && { marginLeft: 6 }]}
            >
              {subtitle}
            </Text>
          ) : null}
        </View>
      </View>
    </TouchableOpacity>
  );
};
export { SearchItem };
