import StyleSheet from "react-native-media-query";
import { mediaQuery } from "@utils/misc";

export const { ids, styles } = StyleSheet.create({
  wrapper: {
    paddingTop: 16,
    paddingBottom: 16,
    [mediaQuery.forMobile]: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  contentBox: {
    paddingTop: 40,
    paddingBottom: 40,
    paddingLeft: 80,
    paddingRight: 80,
    [mediaQuery.forMobile]: {
      paddingTop: 0,
      paddingBottom: 16,
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  titleWrap: {
    display: "flex",
    [mediaQuery.forMobile]: {
      display: "none",
    },
  },
  title: {
    fontSize: 22,
    lineHeight: 28,
    marginBottom: 35,
    fontFamily: "OpenSans_600SemiBold",
    color: "#0D1738",
  },
  eventsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    maxWidth: "100%",
  },
  loadingContainer: {
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  filterRow: {
    flexDirection: "row",
    flex: 1,
    [mediaQuery.forMobile]: {
      width: "100%",
    },
  },
  paginationRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [mediaQuery.forMobile]: {
      display: "none",
    },
  },
  flatListStyle: {
    paddingTop: 16,
    paddingBottom: 432,
  },
  paginationWrap: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  paginationButtonWrap: {
    alignSelf: "flex-start",
  },
  paginationButtonLeft: {
    height: 36,
    width: 36,
    minWidth: 36,
    padding: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  paginationButtonRight: {
    height: 36,
    width: 36,
    minWidth: 36,
    padding: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
});
