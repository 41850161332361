import { CategoryButton } from "@components/back-office/tables/category-button";
import { useQueryFilters } from "../useGridFilters";
import { useState } from "react";

export const CategoriesFilter = () => {
  const [displayCategoryModal, setDisplayCategoryModal] = useState(false);

  const { filters, setFilterValue } = useQueryFilters(["categories"]);

  const value = filters.categories || [];

  return (
    <CategoryButton
      setSelectedCat={(v) => setFilterValue("categories", v)}
      displayCategoryModal={displayCategoryModal}
      setDisplayCategoryModal={setDisplayCategoryModal}
      selectedCat={value}
      isSelected={!!filters.categories}
    />
  );
};
