import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    height: "100%",
    justifyContent: "space-between",
    paddingBottom: 40,
  },
  buttonWrap: {
    paddingLeft: 12,
    paddingRight: 12,
  },
  itemWrap: {
    borderBottomWidth: 1,
    borderBottomColor: "#EDEEEF",
  },
  radio: {
    width: "100%",
    height: 56,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 20,
    paddingRight: 20,
  },
  text: {
    color: "#0D1738",
    fontSize: 16,
    fontFamily: "OpenSans_400Regular",
  },
  radioBorder: {
    width: 20,
    height: 20,
    borderRadius: 10,
    borderWidth: 2,
    borderColor: "#8C9196",
    alignItems: "center",
    justifyContent: "center",
  },
  active: {
    borderColor: "#2C6ECB",
  },
  inner: {
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: "#2C6ECB",
  },
});
