import { Text, TouchableOpacity, View } from "react-native";
import React, { useState } from "react";
import { styles } from "./style";
import { TrainingLesson } from "./Lesson";
import { FormikInput } from "../formik-input";
import { Col } from "@components/general/col";
import { FormikTextArea } from "../formik-text-area/FormikTextArea";
import { Button, Collapsible } from "@shopify/polaris";
import { Module, useDeleteLessonMutation } from "@gql/generated/generated";
import { DeleteConfirmationModal } from "../delete-confirmation-modal";
import { useFormikContext } from "formik";
import { removeItemAtIndex } from "@utils/Array";
import { TrashIcon } from "@components/general/icons/account-icons/TrashIcon";
import { customFirestoreId } from "@utils/misc";
import { ChevronIcon } from "@components/general/icons/org-icons";

const defaultLesson = {
  isPreviewLesson: false,
  lessonContent: "",
  lessonDescription: "",
  lessonTitle: "",
  documentTitle: "",
  durationInSeconds: null,
  lessonType: null,
  userType: null,
  videoTitle: "",
};

export const TrainingModule = ({
  index,
  module,
  onDeleteModule,
  trainingId,
}: {
  index: number;
  module: Module;
  onDeleteModule: () => void;
  trainingId: string;
}) => {
  const { values, setFieldValue } = useFormikContext<any>();

  const [moduleOpen, setModuleOpen] = useState<boolean>(true);
  const [lessonToDelete, setLessonToDelete] = useState<{
    index: number;
    id: string;
  } | null>(null);

  const [deleteRemoteLesson] = useDeleteLessonMutation();

  const handleAddLessons = () => {
    setFieldValue(`modules.${index}.lessons`, [
      ...module.lessons,
      { id: customFirestoreId(), ...defaultLesson },
    ]);
  };

  const handleDeleteLesson = (index: number, id: string) => {
    setLessonToDelete({ index, id });
  };

  const deleteLesson = async () => {
    if (lessonToDelete !== null) {
      setFieldValue(
        `modules.${index}.lessons`,
        removeItemAtIndex(values.modules[index].lessons, lessonToDelete.index)
      );
      await deleteRemoteLesson({
        variables: {
          moduleId: module.id,
          trainingId,
          lessonId: lessonToDelete.id,
        },
      });
    }

    setLessonToDelete(null);
  };

  return (
    <View style={[styles.moduleContainer]}>
      <TouchableOpacity
        onPress={() => setModuleOpen(!moduleOpen)}
        style={[
          styles.flexRowModule,
          {
            backgroundColor: "#BABEC3",
            alignItems: "center",
          },
        ]}
      >
        <Text style={[styles.title]}>Module {index + 1}</Text>
        <View style={[styles.flexRowGap]}>
          <TouchableOpacity
            onPress={(e: any) => {
              e.stopPropagation();
              e.preventDefault();
              onDeleteModule();
            }}
          >
            <TrashIcon fill="#0D1738" />
          </TouchableOpacity>
          <View
            style={{ transform: [{ rotate: moduleOpen ? "180deg" : "0deg" }] }}
          >
            <ChevronIcon />
          </View>
        </View>
      </TouchableOpacity>
      <Collapsible
        open={moduleOpen}
        id="basic-collapsible"
        transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
        expandOnPrint
      >
        <Col
          style={{
            backgroundColor: "#F6F6F7",
            paddingVertical: 16,
            paddingHorizontal: 20,
          }}
        >
          <FormikInput
            fieldName={`modules[${index}]moduleTitle`}
            label="Title"
          />
          <FormikTextArea
            fieldName={`modules[${index}]moduleDescription`}
            label="Description"
          />
        </Col>

        {module.lessons.map((lesson, i) => {
          return (
            <TrainingLesson
              key={lesson.id}
              lessonIndex={i}
              moduleIndex={index}
              moduleId={module.id}
              lesson={lesson}
              onDeleteLesson={() => handleDeleteLesson(i, lesson.id)}
              trainingId={trainingId}
            />
          );
        })}

        <Button fullWidth onClick={handleAddLessons}>
          Add {!module.lessons.length ? "a" : "another"} lesson +
        </Button>
      </Collapsible>

      <DeleteConfirmationModal
        title="Are you sure you want to delete this lesson?"
        onDeleteConfirm={() => deleteLesson()}
        visible={lessonToDelete !== null}
        onClose={() => setLessonToDelete(null)}
      />
    </View>
  );
};
