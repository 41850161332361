import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const CaretUp = (props: SvgProps) => (
  <Svg width={8} height={5} fill="none" {...props}>
    <Path
      d="M.902 5h6.196c.751 0 1.172-.754.708-1.268L4.708.3C4.348-.1 3.653-.1 3.292.3L.194 3.732C-.27 4.246.151 5 .902 5Z"
      fill={props.fill ? props.fill : "#0D1738"}
    />
  </Svg>
);

export { CaretUp };
