import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  background: {
    backgroundColor: "#FFFFFF",
    paddingHorizontal: 16,
    paddingVertical: 16,
    borderRadius: 8,
  },
  headerWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  headerTitle: {
    display: "flex",
    flexDirection: "row",
  },
  createBtnWrapper: {
    display: "flex",
    flexDirection: "row",
    height: 36,
    width: 117,
    borderRadius: 4,

    backgroundColor: "#0D1738",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 26,
    lineHeight: 28,
    color: "#0D1738",
  },
  createBtn: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 14,
    lineHeight: 22,
    color: "#FFFFFF",
  },
  createText: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 15,
    lineHeight: 21,
    color: "#6E757C",
    marginLeft: 6,
    marginTop: 34,
  },
  headerSearch: {
    display: "flex",
    flexDirection: "row",
  },
  searchTitle: {
    fontFamily: "OpenSans_700Bold",
    fontSize: 12,
    lineHeight: 24,
    color: "#6E757C",
    marginTop: 32,
    marginRight: 14,
  },
  searchBar: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 12,
    lineHeight: 15,
    color: "#6E757C",
    marginTop: 29,
    marginRight: 53,
    width: 175,
    height: 33,
    paddingLeft: 32,
    paddingRight: 15,
    paddingVertical: 9,
    borderColor: "#0D1738",
    borderWidth: 1,
    borderRadius: 35,
  },
  searchIcn: {
    width: 14,
    height: 14,
    position: "absolute",
    marginTop: 39,
    marginLeft: 10.71,
  },
  headerButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 35,
  },
  buttonsWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  statusFilter: {
    width: 24,
    height: 24,
    marginLeft: 34,
    marginRight: 4.5,
  },
  btnText: {
    fontFamily: "OpenSans_500Medium",
    fontSize: 12,
    lineHeight: 24,
    color: "#6E757C",
    marginTop: 1,
  },
  dropdownFilterStatus: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#ECECEC",
    paddingVertical: 3,
    paddingHorizontal: 10,
    borderRadius: 25,
    marginLeft: 7,
    marginRight: 13,
    height: 27,
    width: 93.5,
  },
  dropdownFilterDate: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#ECECEC",
    paddingVertical: 3,
    paddingHorizontal: 10,
    borderRadius: 25,
    marginLeft: 7,
    marginRight: 13,
    height: 27,
    width: 101.45,
  },
  dropdownFilterCategory: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#ECECEC",
    paddingVertical: 3,
    paddingHorizontal: 10,
    borderRadius: 25,
    marginLeft: 7,
    marginRight: 13,
    height: 27,
    width: 93.5,
  },
  dropdownFilterCat: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#ECECEC",
    paddingVertical: 3,
    paddingHorizontal: 10,
    borderRadius: 25,
    marginLeft: 7,
    marginRight: 13,
    height: 27,
    width: 110,
  },
  dropdownFilterUserType: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#ECECEC",
    paddingVertical: 3,
    paddingHorizontal: 10,
    borderRadius: 25,
    marginLeft: 6,
    marginRight: 13,
    height: 27,
    width: 68,
  },
  dropdownFilterBulk: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#ECECEC",
    paddingVertical: 3,
    paddingHorizontal: 10,
    borderRadius: 25,
    marginLeft: 2,
    marginRight: 14,
    height: 27,
    width: 108.22,
  },
  dropdownFilterOff: {
    display: "flex",
    flexDirection: "row",
    paddingVertical: 3,
    paddingHorizontal: 10,
    borderRadius: 25,
    marginLeft: 3,
    marginRight: 13,
    height: 27,
  },
  dropdownPlaceholder: {
    fontFamily: "OpenSans_500Medium",
    fontSize: 12,
    lineHeight: 21,
    color: "#6E757C",
    maxWidth: 84,
  },
  dropdownArrowStatus: {
    position: "absolute",
    width: 8,
    height: 6,
    marginLeft: 66,
    marginTop: 9,
  },
  dropdownArrowDate: {
    position: "absolute",
    width: 8,
    height: 6,
    marginLeft: 74,
    marginTop: 9,
  },
  dropdownArrowCat: {
    position: "absolute",
    width: 8,
    height: 6,
    marginLeft: 82,
    marginTop: 9,
  },
  dropdownArrowUserType: {
    position: "absolute",
    width: 8,
    height: 6,
    marginLeft: 42,
    marginTop: 9,
  },
  dropdownArrowBulk: {
    width: 8,
    height: 6,
    marginLeft: 9,
    marginTop: 9,
  },
  dateFilter: {
    width: 18,
    height: 18,
    marginTop: 4,
    marginRight: 4.5,
  },
  categoryFilter: {
    width: 12,
    height: 12,
    marginTop: 6,
    marginRight: 5,
  },
  userTypeFilter: {
    width: 12,
    height: 12,
    marginTop: 6,
    marginRight: 5,
  },
  bulkActions: {
    width: 11.66,
    height: 7.94,
    marginTop: 8,
    marginRight: 5,
  },
  paginationWrapper: {
    display: "flex",
    flexDirection: "row",
    marginRight: 58,
  },
  switchBulk: {
    marginTop: 3,
  },
  paginationLeft: {
    fontFamily: "OpenSans_700Bold",
    fontSize: 12,
    lineHeight: 24,
    color: "#6E757C",
    backgroundColor: "#EDEDED",
    marginRight: 4,
    width: 21,
    height: 22,
    borderRadius: 5,
    textAlign: "center",
  },
  paginationCenter: {
    fontFamily: "OpenSans_700Bold",
    fontSize: 12,
    lineHeight: 24,
    color: "#6E757C",
    width: 21,
    height: 22,
    borderRadius: 5,
    paddingLeft: 7,
  },
  paginationRight: {
    fontFamily: "OpenSans_700Bold",
    fontSize: 12,
    lineHeight: 24,
    color: "#6E757C",
    backgroundColor: "#D9D9D9",
    marginLeft: 4,
    width: 21,
    height: 22,
    borderRadius: 5,
    textAlign: "center",
  },
  dropdownStatusView: {
    backgroundColor: "#EDEDED",
    width: 94,
    height: 147,
    paddingTop: 8,
    paddingLeft: 11,
    borderRadius: 15,
    position: "absolute",
    marginLeft: 106,
    marginTop: 123,
  },
  dropdownOption: {
    fontFamily: "OpenSans_500Medium",
    fontSize: 12,
    lineHeight: 26,
    color: "#6E757C",
  },
  dropdownDateView: {
    backgroundColor: "#EDEDED",
    width: 102,
    height: 94,
    paddingTop: 8,
    paddingLeft: 11,
    borderRadius: 15,
    position: "absolute",
    marginLeft: 268,
    marginTop: 123,
  },
  dropdownCategoryView: {
    backgroundColor: "#EDEDED",
    width: 150,
    height: 222,
    paddingTop: 8,
    paddingLeft: 11,
    borderRadius: 15,
    position: "absolute",
    marginLeft: 460,
    marginTop: 123,
  },
  dropdownUserTypeView: {
    backgroundColor: "#EDEDED",
    width: 68,
    height: 90,
    paddingTop: 8,
    paddingLeft: 11,
    borderRadius: 15,
    position: "absolute",
    marginLeft: 665,
    marginTop: 123,
  },
  dropdownBulkView: {
    backgroundColor: "#EDEDED",
    width: 108,
    height: 90,
    paddingTop: 8,
    paddingLeft: 11,
    borderRadius: 15,
    position: "absolute",
    marginLeft: 766,
    marginTop: 123,
  },
});
