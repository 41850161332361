import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  headerTitle: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 26,
    lineHeight: 32,
    color: "#0D1738",
  },
  title: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 16,
    lineHeight: 20,
    color: "#202223",
  },
  touchableOpacityButton: {
    borderWidth: 1,
    borderColor: "#BABFC3",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 4,
    width: 36,
    height: 36,
    marginRight: 10,
  },
  sectionTitle: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 16,
    lineHeight: 20,
    color: "#0D1738",
    marginBottom: 20,
  },
  userTextInfo: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 14,
    lineHeight: 20,
    color: "#202223",
    paddingTop: 2,
  },
  subscriptionPlanText: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 14,
    lineHeight: 20,
    color: "#202223",
    paddingTop: 2,
  },
  verificationButton: {
    width: "100%",
    borderWidth: 1,
    borderColor: "#BABFC3",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 4,
    padding: 10,
  },
});
