import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

interface Props extends SvgProps {
  color?: string;
}

const CloseIconSmall = (props: Props) => (
  <Svg width={10} height={10} fill="none" {...props}>
    <Path
      d="M1.707.293A1 1 0 0 0 .293 1.707L3.586 5 .293 8.293a1 1 0 0 0 1.414 1.414L5 6.414l3.293 3.293a1 1 0 0 0 1.414-1.414L6.414 5l3.293-3.293A1 1 0 0 0 8.293.293L5 3.586 1.707.293Z"
      fill={props.color ? props.color : "#5C5F62"}
    />
  </Svg>
);

export { CloseIconSmall };
