import { StyleProp, Text, View, ViewStyle } from "react-native";
import { BackNav } from "@components/account/back-nav";
import { styles } from "./style";

interface Props {
  title: string;
  subTitle?: string;
  backTitle?: string;
  onPressBack?: () => void;
  hasBottomBorder?: boolean;
  extraStyles?: StyleProp<ViewStyle>;
}

const TitleBox = ({
  title,
  backTitle,
  subTitle,
  onPressBack,
  hasBottomBorder,
  extraStyles,
}: Props) => (
  <View
    style={[styles.wrap, hasBottomBorder && styles.wrapBorder, extraStyles]}
  >
    {backTitle ? (
      <View style={styles.navWrap}>
        <BackNav title={backTitle} onPress={onPressBack} />
      </View>
    ) : null}
    <Text style={styles.title}>{title}</Text>
    {subTitle ? <Text style={styles.subTitle}>{subTitle}</Text> : null}
  </View>
);

export { TitleBox };
