import StyleSheet from "react-native-media-query";
import { mediaQuery } from "@utils/misc";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: "100%",
    borderBottomWidth: 1,
    borderBottomColor: "#EDEEEF",
    flexDirection: "row",
    overflowX: "scroll",
    marginTop: 20,
    [mediaQuery.forMobile]: {
      backgroundColor: "#EDEEEF",
      marginBottom: 12,
      marginTop: -4,
    },
  },
  tabLink: {
    textDecorationLine: "none",
  },
});
