import { Platform } from "react-native";
import { Profile as ProfileWeb } from "./Profile";
import { ProfileNative } from "./Profile.native";

const Profile = Platform.select({
  native: () => ProfileNative,
  default: () => ProfileWeb,
})();

export { Profile };
