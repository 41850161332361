import React, { useState } from "react";
import {
  Image,
  ImageBackground,
  Platform,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import dayjs from "dayjs";
import { useNavigate } from "react-router-native";
import { ContentBox } from "@components/general/layouts/content-box";
import { StarIcon } from "@components/general/icons/feed-icons";
import { getModuleLessons, getUniqueCategories } from "@utils/misc";
import { ids, styles } from "./style";
import { DocumentAsset, Training } from "@gql/generated/generated";
import { useGetFile } from "@hooks/useGetFileUrl";
import { convertSecondsToDisplay } from "@utils/TIme";

interface CourseTagsProps {
  title: string;
}

const CourseTags = ({ title }: CourseTagsProps) => (
  <View style={styles.courseTag}>
    <Text style={styles.courseTagText}>{title}</Text>
  </View>
);

interface CourseItemProps {
  id?: string | null;
  image?: DocumentAsset | null;
  courseTags: string[];
  title: string;
  description: string;
  displayDuration: string;
  totalLessons: number;
  startDate: string;
  instructor: string;
  jobRoles: string[];
  rating: number;
  totalReviews: number;
  totalShares: number;
  membership: string;
}

const CourseItem = ({
  id,
  image,
  courseTags,
  title,
  description,
  displayDuration,
  totalLessons,
  startDate,
  instructor,
  jobRoles,
  rating,
  totalReviews,
  totalShares,
  membership,
}: CourseItemProps) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  const { fileUrl } = useGetFile(image?.storagePath);

  return (
    <ContentBox style={isHovered ? { backgroundColor: "#F6F6F7" } : undefined}>
      <TouchableOpacity
        // @ts-ignore
        onMouseEnter={() => setIsHovered(true)}
        // @ts-ignore
        onMouseLeave={() => setIsHovered(false)}
        style={styles.courseItem}
        onPress={() => navigate(`/training/${id}`)}
      >
        <View
          style={styles.courseImageWrap}
          dataSet={{ media: ids.courseImageWrap }}
        >
          <ImageBackground
            source={{ uri: fileUrl }}
            style={styles.courseImage}
            imageStyle={styles.courseImage}
          >
            {courseTags.length
              ? courseTags.map((tag) => <CourseTags title={tag} key={tag} />)
              : null}
          </ImageBackground>
          <Image
            source={{ uri: fileUrl }}
            style={styles.courseImageMobile}
            dataSet={{ media: ids.courseImageMobile }}
          />
          {isHovered ? (
            <View style={styles.viewContainer}>
              <Text>View</Text>
            </View>
          ) : null}
        </View>
        <View style={styles.courseRight}>
          <View style={styles.courseRightInner}>
            <Text
              style={styles.courseName}
              dataSet={{ media: ids.courseName }}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {title}
            </Text>
            {Platform.OS === "web" && (
              <View style={[styles.courseDescription]}>
                <Text
                  style={styles.instructorName}
                  dataSet={{ media: ids.courseDescription }}
                  numberOfLines={2}
                  ellipsizeMode="tail"
                >
                  {description}
                </Text>
              </View>
            )}
            <Text
              style={styles.totalHoursText}
              dataSet={{ media: ids.totalHoursText }}
            >
              {displayDuration} total • {totalLessons} lessons • Starts{" "}
              {startDate}
            </Text>
            <View
              style={styles.instructorWrap}
              dataSet={{ media: ids.instructorWrap }}
            >
              <Text style={styles.instructorName} numberOfLines={1}>
                {instructor}
              </Text>
              <Text style={styles.jobRole} numberOfLines={1}>
                &nbsp;Job role: {jobRoles.toString()}
              </Text>
            </View>
            <View
              style={styles.reviewsWrap}
              dataSet={{ media: ids.reviewsWrap }}
            >
              <StarIcon />
              <Text style={styles.reviewsText}>
                {rating} ({totalReviews} reviews) • {totalShares} shares
              </Text>
              <View style={styles.enrolledWrap}>
                <Text style={styles.enrolledText}>{membership}</Text>
              </View>
            </View>
          </View>
          {/* TODO: Implement three dots menu with save etc (pending designs) */}
          {/* <View
            style={styles.threeDotsTouchWrap}
            dataSet={{ media: ids.threeDotsTouchWrap }}
          >
            <TouchableOpacity style={styles.threeDotsTouch}>
              <ThreeDots />
            </TouchableOpacity>
          </View> */}
        </View>
      </TouchableOpacity>
    </ContentBox>
  );
};

const RelatedCourses = ({ trainings }: { trainings?: Training[] }) => (
  <View style={styles.wrap}>
    <Text style={styles.title}>Related Courses</Text>
    {trainings
      ? trainings.map(
          ({
            id,
            coverImage,
            title,
            shortDescription,
            instructors,
            publishedAt,
            jobRoles,
            userType,
            averageStars,
            categories,
            shareCount,
            durationInSeconds,
            modules,
            comments,
          }) => {
            const uniqueCategories = getUniqueCategories(categories);
            return (
              <View style={{ marginBottom: 16 }} key={id}>
                <CourseItem
                  id={id}
                  image={coverImage}
                  title={title}
                  description={shortDescription}
                  displayDuration={convertSecondsToDisplay(
                    durationInSeconds ?? 0
                  )}
                  totalLessons={getModuleLessons(modules)?.length ?? 0}
                  startDate={
                    publishedAt
                      ? dayjs(new Date(publishedAt)).format("D MMM YY")
                      : ""
                  }
                  instructor={instructors?.length ? instructors[0].name : ""}
                  jobRoles={jobRoles}
                  rating={Number(averageStars) || 0}
                  totalReviews={comments ? comments.length : 0}
                  totalShares={shareCount || 0}
                  membership={userType?.join(" & ") ?? ""}
                  courseTags={uniqueCategories}
                />
              </View>
            );
          }
        )
      : null}
  </View>
);

export { RelatedCourses };
