import StyleSheet from "react-native-media-query";
import { mediaQuery } from "@utils/misc";

export const { ids, styles } = StyleSheet.create({
  separation: {
    display: "flex",
    flexDirection: "row",
    borderBottomWidth: 0.5,
    borderBottomColor: "rgba(0, 0, 0, 0.35)",
    marginBottom: 24,
    paddingBottom: 12,
    marginTop: 32,
  },
  title: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 26,
    lineHeight: 32,
    color: "#0D1738",
  },
  filters: {
    height: 52,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 24,
    marginBottom: 20,
    borderBottomColor: "#EDEEEF",
    borderBottomWidth: 1,
    // ...(Platform.OS !== "web" && { overflow: "scroll" }),
    [mediaQuery.forMobile]: {
      marginTop: 8,
      marginBottom: 16,
      overflow: "scroll",
    },
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    // ...(Platform.OS !== "web" && { overflow: "scroll" }),
    [mediaQuery.forMobile]: {
      overflow: "scroll",
    },
  },
  buttonWrapper: {
    height: 52,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonTextContainer: {
    justifyContent: "center",
    height: 49,
  },
  buttonHoverLine: {
    height: 3,
    backgroundColor: "#2C6ECB",
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
  },
  buttonText: {
    paddingHorizontal: 20,
    fontFamily: "OpenSans_400Regular",
    fontSize: 14,
    lineHeight: 20,
    color: "#6D7175",
    [mediaQuery.forMobile]: {
      borderWidth: 1,
      borderColor: "#BABFC3",
      borderRadius: 15,
      backgroundColor: "transparent",
      color: "#0D1738",
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 4,
      paddingBottom: 4,
      marginRight: 8,
    },
  },
  activeBtn: {
    height: 3,
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    backgroundColor: "#2C6ECB",
    marginTop: 12,
  },
  buttonSelected: {
    paddingHorizontal: 20,
    // marginRight: 29,
    fontFamily: "OpenSans_600SemiBold",
    color: "#0D1738",
    [mediaQuery.forMobile]: {
      borderWidth: 1,
      borderColor: "#BABFC3",
      borderRadius: 15,
      backgroundColor: "#0D1738",
      color: "#FFFFFF",
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 4,
      paddingBottom: 4,
      marginRight: 8,
    },
  },
  requestText: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 14,
    lineHeight: 20,
    color: "#2C6ECB",
    marginLeft: 6,
  },
});
