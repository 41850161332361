import React, { Dispatch, SetStateAction } from "react";
import { View } from "react-native";
import {
  IDropdownItem,
  TableDropdown,
} from "@components/back-office/tables/table-dropdown";
import { SearchBar } from "@components/back-office/tables/search-bar";
import { styles } from "./style";
import { Checkbox, Pagination } from "@shopify/polaris";
import { CategoryButton } from "../category-button";
import { pastTensify } from "@utils/misc";
import { FormikDatePickerDropdown } from "@components/back-office/editor/formik-date-picker-dropdown/FormikDatePickerDropdown";
import { SwitchButton } from "@components/marketplace/marketplace-head-menu/switch-button/SwitchButton";
import {
  ContentAccess,
  DocumentContentType,
  UserType,
} from "@gql/generated/generated";
import {
  CONTENT_TYPES,
  getLabelsForContentTypes,
} from "@components/general/filters";
import {
  filterValidPartnerTypes,
  partnerTypeFilterCheckboxList,
} from "@utils/partners";

const statusItems = [
  { id: "Publish", content: "Published" },
  { id: "Draft", content: "Drafted" },
  { id: "Archive", content: "Archived" },
];

const userStatusItems = [
  { id: "Active", content: "Active" },
  { id: "Inactive", content: "Inactive" },
  { id: "Suspend", content: "Suspended" },
  { id: "Delete", content: "Deleted" },
];

const monthItems = [
  { id: "January", content: "January" },
  { id: "February", content: "February" },
  { id: "March", content: "March" },
  { id: "April", content: "April" },
  { id: "May", content: "May" },
  { id: "June", content: "June" },
  { id: "July", content: "July" },
  { id: "August", content: "August" },
  { id: "September", content: "September" },
  { id: "October", content: "October" },
  { id: "November", content: "November" },
  { id: "December", content: "December" },
];

const postTypeItems = [
  { id: "Announcement", content: "Announcement" },
  { id: "News", content: "News" },
];

const pollStatusItems = [
  { id: "All", content: "All" },
  { id: "Open", content: "Open" },
  { id: "Closed", content: "Closed" },
];

const sortByItems = [
  { id: "Newest first", content: "Newest first" },
  { id: "Oldest first", content: "Oldest first" },
  { id: "Highest score", content: "Highest score" },
  { id: "Lowest score", content: "Lowest score" },
];

interface Props {
  isBulk?: boolean;
  setIsBulk?: (isBulk: boolean) => void;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  status?: string | null;
  setStatus?: Dispatch<SetStateAction<null | string>>;
  month?: string | null;
  setMonth?: Dispatch<SetStateAction<null | string>>;
  dateFrom?: number | null;
  setDateFrom?: Dispatch<SetStateAction<null | number>>;
  dateTo?: number | null;
  setDateTo?: Dispatch<SetStateAction<null | number>>;
  postType?: string | null;
  setPostType?: Dispatch<SetStateAction<null | string>>;
  pollStatus?: string | null;
  setPollStatus?: Dispatch<SetStateAction<null | string>>;
  userType?: UserType[];
  setUserType?: Dispatch<SetStateAction<UserType[]>>;
  partnerType?: null | string[];
  setPartnerType?: Dispatch<SetStateAction<null | string[]>>;
  eventType?: null | string[];
  setEventType?: Dispatch<SetStateAction<null | string[]>>;
  setCategories?: Dispatch<SetStateAction<string[]>>;
  access?: ContentAccess | null;
  setAccess?: Dispatch<SetStateAction<null | ContentAccess>>;
  memberSince?: number | null;
  setMemberSince?: Dispatch<SetStateAction<null | number>>;
  bulkItem?: string;
  setBulkItem?: (bulkItem: string) => void;
  searchTerm?: string | null;
  setSearchTerm?: Dispatch<SetStateAction<string | null>>;
  hasNextPage?: boolean;
  handleBulkAction?: (action: string) => void;
  specialOffer?: boolean | null;
  setSpecialOffer?: Dispatch<SetStateAction<boolean | null>>;
  sortBy?: string;
  setSortBy?: Dispatch<SetStateAction<string>>;
  userStatus?: string | null;
  setUserStatus?: Dispatch<SetStateAction<null | string>>;
  isUsersTable?: boolean;
  // For generic dropdowns:
  selectedDropdownItem?: string | null;
  setSelectedDropdownItem?: Dispatch<SetStateAction<string | null>>;
  dropdownItems?: IDropdownItem[];
  contentTypes?: string[];
  setContentTypes?: Dispatch<SetStateAction<string[] | undefined>>;
}

const TableHeader = ({
  status,
  setStatus,
  sortBy,
  setSortBy,
  month,
  setMonth,
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
  setCategories,
  postType,
  setPostType,
  pollStatus,
  setPollStatus,
  userType,
  setUserType,
  partnerType,
  setPartnerType,
  eventType,
  setEventType,
  searchTerm,
  setSearchTerm,
  page,
  setPage,
  hasNextPage,
  handleBulkAction,
  access,
  setAccess,
  memberSince,
  setMemberSince,
  specialOffer,
  setSpecialOffer,
  userStatus,
  setUserStatus,
  isUsersTable = false,
  selectedDropdownItem,
  setSelectedDropdownItem,
  dropdownItems,
  contentTypes,
  setContentTypes,
}: Props) => {
  const [displayCategoryModal, setDisplayCategoryModal] = React.useState(false);

  const bulkItems = [
    { id: "Publish", content: "Publish" },
    { id: "Draft", content: "Move to Draft" },
    { id: "Delete", content: "Move to Trash" },
    { id: "Archive", content: "Archive" },
  ];

  const usersBulkItems = [
    { id: "Active", content: "Set Active" },
    { id: "Suspend", content: "Suspend" },
    { id: "Delete", content: "Delete" },
  ];

  const eventTypeItems = [
    {
      id: "In-person",
      content: "In-person",
      prefix: (
        <Checkbox
          label="In-person"
          labelHidden
          checked={eventType?.includes("In-person")}
        />
      ),
    },
    {
      id: "Virtual",
      content: "Virtual",
      prefix: (
        <Checkbox
          label="Virtual"
          labelHidden
          checked={eventType?.includes("Virtual")}
        />
      ),
    },
    {
      id: "Hybrid",
      content: "Hybrid",
      prefix: (
        <Checkbox
          label="Hybrid"
          labelHidden
          checked={eventType?.includes("Hybrid")}
        />
      ),
    },
  ];

  const partnerTypeItems = partnerTypeFilterCheckboxList(
    filterValidPartnerTypes(partnerType || [])
  );

  const userTypeItems = [
    {
      id: UserType.ALL,
      content: UserType.ALL,
      prefix: (
        <Checkbox label="All" labelHidden checked={userType?.length === 3} />
      ),
    },
    {
      id: UserType.FREE,
      content: UserType.FREE,
      prefix: (
        <Checkbox
          label="Free"
          labelHidden
          checked={userType?.includes(UserType.FREE)}
        />
      ),
    },
    {
      id: UserType.CLUB,
      content: UserType.CLUB,
      prefix: (
        <Checkbox
          label="Club"
          labelHidden
          checked={userType?.includes(UserType.CLUB)}
        />
      ),
    },
    {
      id: UserType.PLUS,
      content: UserType.PLUS,
      prefix: (
        <Checkbox
          label="Plus"
          labelHidden
          checked={userType?.includes(UserType.PLUS)}
        />
      ),
    },
  ];

  const contentTypeItems = CONTENT_TYPES.map((contentType) => {
    return {
      id: contentType.value,
      content: contentType.label,
      prefix: (
        <Checkbox
          key={contentType.value}
          label={contentType.label}
          labelHidden
          checked={contentTypes?.includes(contentType.value)}
        />
      ),
    };
  });

  return (
    <View style={styles.wrap}>
      <View style={styles.inner}>
        {setSearchTerm ? (
          <SearchBar setSearchTerm={setSearchTerm} searchTerm={searchTerm} />
        ) : null}

        {setSortBy ? (
          <TableDropdown
            placeholder={sortBy ? sortBy : "Sort by"}
            items={sortByItems}
            onSelectItem={(id) => setSortBy(id)}
            minWidth={130}
          />
        ) : null}

        {setStatus ? (
          <TableDropdown
            placeholder={status ? pastTensify(status) : "Status"}
            items={statusItems}
            onSelectItem={(id) => setStatus(id)}
            onReset={status ? () => setStatus(null) : undefined}
          />
        ) : null}

        {setUserStatus ? (
          <TableDropdown
            placeholder={userStatus ? userStatus : "Status"}
            items={userStatusItems}
            onSelectItem={(id) => setUserStatus(id)}
            onReset={userStatus ? () => setUserStatus(null) : undefined}
          />
        ) : null}

        {setMonth ? (
          <TableDropdown
            placeholder={month || "Month"}
            items={monthItems}
            onSelectItem={(id) => setMonth(id)}
            onReset={month ? () => setMonth(null) : undefined}
          />
        ) : null}
        {/* TODO: update shape of categories to match ContentAccess type */}
        {/* {setAccess ? (
          <CategoryButton
            isEditor
            placeHolderText="Access"
            setSelectedCat={setAccess}
            displayCategoryModal={displayCategoryModal}
            setDisplayCategoryModal={setDisplayCategoryModal}
          />
        ) : null} */}

        {setDateFrom ? (
          <View>
            <FormikDatePickerDropdown
              dateValue={dateFrom}
              placeholderText="From"
              onReset={dateFrom ? () => setDateFrom(null) : undefined}
              onChange={(v) => setDateFrom(v.start.getTime())}
            />
          </View>
        ) : null}

        {setDateTo ? (
          <View>
            <FormikDatePickerDropdown
              dateValue={dateTo}
              placeholderText="To"
              onReset={dateTo ? () => setDateTo(null) : undefined}
              onChange={(v) => setDateTo(v.start.getTime())}
            />
          </View>
        ) : null}

        {setCategories ? (
          <CategoryButton
            setSelectedCat={setCategories}
            displayCategoryModal={displayCategoryModal}
            setDisplayCategoryModal={setDisplayCategoryModal}
            selectedCat={[]}
          />
        ) : null}

        {setPostType ? (
          <TableDropdown
            placeholder={postType || "Post Type"}
            items={postTypeItems}
            onSelectItem={(id) => setPostType(id)}
            onReset={postType ? () => setPostType(null) : undefined}
          />
        ) : null}

        {setPollStatus ? (
          <TableDropdown
            placeholder={pollStatus || "Poll Status"}
            items={pollStatusItems}
            onSelectItem={(id) => setPollStatus(id)}
            onReset={pollStatus ? () => setPollStatus(null) : undefined}
          />
        ) : null}

        {setUserType && userType ? (
          <TableDropdown
            placeholder={
              userType.length > 0 ? userType.join(", ") : "User Type"
            }
            items={userTypeItems}
            onSelectItem={(id) => {
              if (id === UserType.ALL && userType.length === 3) {
                setUserType([]);
              } else if (id === UserType.ALL) {
                setUserType([UserType.FREE, UserType.CLUB, UserType.PLUS]);
              } else if (userType.includes(id as UserType)) {
                setUserType(userType.filter((el) => el !== id));
              } else {
                setUserType([...userType, id as UserType]);
              }
            }}
            minWidth={120}
            onReset={userType.length > 0 ? () => setUserType([]) : undefined}
          />
        ) : null}

        {setContentTypes && contentTypes ? (
          <TableDropdown
            needFluidContent
            placeholder={
              contentTypes.length > 0
                ? getLabelsForContentTypes(
                    contentTypes as DocumentContentType[]
                  ).join(", ")
                : "Content Type"
            }
            items={contentTypeItems}
            onSelectItem={(id) => {
              if (contentTypes.includes(id)) {
                setContentTypes(contentTypes.filter((el) => el !== id));
              } else {
                setContentTypes([...contentTypes, id]);
              }
            }}
            minWidth={120}
            onReset={
              contentTypes.length > 0 ? () => setContentTypes([]) : undefined
            }
          />
        ) : null}

        {setMemberSince ? (
          <View>
            <FormikDatePickerDropdown
              dateValue={memberSince}
              placeholderText="Member Since"
              onChange={(v) => setMemberSince(v.start.getTime())}
              onReset={memberSince ? () => setMemberSince(null) : undefined}
            />
          </View>
        ) : null}

        {setPartnerType && partnerType ? (
          <TableDropdown
            needFluidContent
            placeholder={
              partnerType.length > 0 ? partnerType.join(", ") : "Tiers"
            }
            items={partnerTypeItems}
            onSelectItem={(id) => {
              if (partnerType.includes(id)) {
                setPartnerType(partnerType.filter((el) => el !== id));
              } else {
                setPartnerType([...partnerType, id]);
              }
            }}
            onReset={
              partnerType.length > 0 ? () => setPartnerType([]) : undefined
            }
          />
        ) : null}

        {setSpecialOffer ? (
          <View>
            <SwitchButton
              title="Special Offer"
              isActive={specialOffer ?? false}
              setIsActive={setSpecialOffer}
              needDisclosure={false}
            />
          </View>
        ) : null}

        {setEventType && eventType ? (
          <TableDropdown
            placeholder={
              eventType.length > 0 ? eventType.join(", ") : "Event Type"
            }
            items={eventTypeItems}
            onSelectItem={(id) => {
              if (eventType.includes(id)) {
                setEventType(eventType.filter((el) => el !== id));
              } else {
                setEventType([...eventType, id]);
              }
            }}
            minWidth={133}
            onReset={eventType.length > 0 ? () => setEventType([]) : undefined}
          />
        ) : null}

        {handleBulkAction ? (
          <TableDropdown
            placeholder="Bulk Actions"
            items={isUsersTable ? usersBulkItems : bulkItems}
            onSelectItem={(id) => handleBulkAction(id)}
          />
        ) : null}

        {setSelectedDropdownItem && dropdownItems && (
          <TableDropdown
            placeholder={getPlaceholderById(
              dropdownItems,
              selectedDropdownItem
            )}
            items={dropdownItems}
            onSelectItem={(id) => setSelectedDropdownItem(id)}
            minWidth={320}
            textLeft
          />
        )}
      </View>
      <Pagination
        hasPrevious={page > 1}
        onPrevious={() => setPage(page - 1)}
        hasNext={hasNextPage}
        onNext={() => setPage(page + 1)}
      />
    </View>
  );
};

const getPlaceholderById = (
  dropdownItems: IDropdownItem[],
  id: string | null | undefined
): string => {
  const item = dropdownItems.find((item) => item.id === id);
  return item ? item?.content ?? item.id : "";
};

export { TableHeader };
