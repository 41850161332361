import React, { useState } from "react";
import { Text, View } from "react-native";
import { ContentBox } from "@components/general/layouts/content-box";
import { TableDropdown } from "@components/back-office/tables/table-dropdown";
import { QuestionAnswerTable } from "@components/back-office/roadmap/question-answer-table";
import { StatisticCardsContainer } from "@components/back-office/roadmap/statistic-cards-container";
import { NorthStar } from "@gql/generated/generated";
import dayjs from "dayjs";
import { getQuarterDetails } from "@pages/roadmap/utils";
import { urlToImageSource } from "@utils/urlToImageSource";
import { IRoadMap } from "@components/back-office/roadmap/roadmap-models";
import { roadmapSectionToSubmodule } from "@utils/roadmapSectionToSubmodule";
import { isNotNull } from "@utils/isNotNull";

interface Props {
  northStarData: NorthStar[];
}

export const NorthStarView = ({ northStarData }: Props) => {
  // TODO: This could all live in a hook since it's shared by NorthStarView and some of PulseView
  // TODO: The year/quarter should default to the newest one, not the first in the array
  const [selectedYear, setSelectedYear] = useState(northStarData[0].year);

  const selectedNorthStarData = northStarData?.find(
    (el) => el.year === selectedYear
  );

  const lastUpdatedDate = dayjs(
    new Date(Number(selectedNorthStarData?.lastUpdated))
  ).format("DD/MM/YY");

  const oneWeekAfterLastUpdated = dayjs(
    new Date(Number(selectedNorthStarData?.lastUpdated) + 604800000)
  ).format("DD/MM/YY");

  const { nextQuarterStartDate } = getQuarterDetails();

  const percentageLeftToComplete = selectedNorthStarData?.percentageComplete
    ? 100 - Number(selectedNorthStarData?.percentageComplete)
    : 100;

  const cards = [
    {
      title: "Completion",
      mainData: selectedNorthStarData?.percentageComplete
        ? `${selectedNorthStarData?.percentageComplete}%`
        : "-",
      secondaryData: selectedNorthStarData?.lastUpdated ? lastUpdatedDate : "-",
    },
    {
      title: "Last Check",
      mainData: selectedNorthStarData?.lastUpdated ? lastUpdatedDate : "-",
      secondaryData: dayjs(nextQuarterStartDate).format("DD/MM/YY"),
    },
    {
      title: "Next reminder",
      mainData: selectedNorthStarData?.lastUpdated
        ? oneWeekAfterLastUpdated
        : "-",
      secondaryData: `${percentageLeftToComplete}%`,
    },
  ];

  const tableData: IRoadMap[] = [
    {
      id: selectedNorthStarData?.id ?? "",
      name: "North Star",
      submodules:
        selectedNorthStarData?.sections
          .map(roadmapSectionToSubmodule)
          .filter(isNotNull) ?? [],
    },
  ];

  return (
    <>
      <ContentBox style={{ padding: 16 }}>
        <StatisticCardsContainer
          items={cards}
          name={selectedNorthStarData?.lastUpdatedByUserName}
          image={urlToImageSource(
            selectedNorthStarData?.lastUpdatedByUserProfilePhoto
          )}
        />
        <ContentBox>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 20,
            }}
          >
            <Text
              style={{
                fontFamily: "OpenSans_600SemiBold",
                fontSize: 20,
                lineHeight: 28,
              }}
            >
              North Star
            </Text>
            <TableDropdown
              items={northStarData
                .filter(({ year }) => year)
                .map(({ year }) => ({
                  id: year!,
                  content: year,
                }))}
              placeholder={selectedYear}
              onSelectItem={(id) => setSelectedYear(id)}
            />
          </View>
          <QuestionAnswerTable data={tableData} />
        </ContentBox>
      </ContentBox>
    </>
  );
};
