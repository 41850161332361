import React, { useState } from "react";
import { Image, Text, TouchableOpacity, View } from "react-native";
import { ContentBox } from "@components/general/layouts/content-box";
import { CaretUp } from "@components/general/icons/account-icons/CaretUp";
import { CaretDown } from "@components/general/icons/account-icons/CaretDown";
import { styles } from "./style";
import { EngagementByRole } from "@gql/generated/generated";

interface EngagementItemProps {
  jobTitle: string;
  currentHours: number;
  lastMonthPercent: string;
  userImages: string[];
  isExpanded: boolean;
  onExpand: () => void;
}

const EngagementItem = ({
  jobTitle,
  currentHours,
  lastMonthPercent,
  userImages,
  isExpanded,
  onExpand,
}: EngagementItemProps) => (
  <View style={styles.itemWrap}>
    <TouchableOpacity
      style={[styles.itemTouch, isExpanded && { paddingBottom: 8 }]}
      onPress={onExpand}
    >
      <Text style={styles.itemTitle}>{jobTitle}</Text>
      {isExpanded ? <CaretUp /> : <CaretDown />}
    </TouchableOpacity>
    {isExpanded && (
      <View style={styles.itemContent}>
        <Text style={styles.rateText}>Engagement Rate</Text>
        <View style={styles.percentWrap}>
          <Text style={styles.percentText}>{currentHours} hours</Text>
          <CaretUp fill="#23827F" />
          <Text style={styles.greenText}>{lastMonthPercent}</Text>
          <Text style={styles.dateText}>&nbsp;vs last month</Text>
        </View>
        <View style={styles.imagesWrap}>
          {userImages.slice(0, 3).map((image) => (
            <Image style={styles.image} source={{ uri: image }} />
          ))}
          {userImages.length > 3 && (
            <View style={[styles.countWrap, styles.image]}>
              <Text style={styles.countText}>{userImages.length - 3}+</Text>
            </View>
          )}
        </View>
      </View>
    )}
  </View>
);

const AnalyticsEngagement = ({
  engagement,
}: {
  engagement?: EngagementByRole[] | null;
}) => {
  const [expandedIndex, setExpandedIndex] = useState<number>();

  const handleExpand = (index: number) => {
    setExpandedIndex(expandedIndex === index ? undefined : index);
  };

  return (
    <View>
      <Text style={styles.title}>Engagement by Job Role</Text>
      <ContentBox style={styles.contentBox}>
        {engagement?.map((item, index) => (
          <EngagementItem
            isExpanded={expandedIndex === index}
            onExpand={() => handleExpand(index)}
            jobTitle={item.jobRole}
            userImages={item.users.map((user) => user.img || "")}
            lastMonthPercent={
              item.averageTimePerMemberMinsLastMonth
                ? `${
                    (item.averageTimePerMemberMinsMTD /
                      item.averageTimePerMemberMinsLastMonth) *
                    100
                  }%`
                : "N/A"
            }
            currentHours={
              Math.round((item.averageTimePerMemberMinsMTD / 60) * 2) / 2
            }
          />
        ))}
      </ContentBox>
    </View>
  );
};

export { AnalyticsEngagement };
