import { useState } from "react";
import { NewPassword } from "./new-password";
import { ResetSuccess } from "./reset-success";

export const ResetPassword: any = () => {
  const [step, setStep] = useState(0);

  switch (step) {
    case 1:
      return <ResetSuccess />;
    default:
      return <NewPassword onDone={() => setStep(1)} />;
  }
};
