import { AuthError } from "firebase/auth";

export const parseFirebaseAuthError = (error: AuthError) => {
  if (
    !error.message.includes("Firebase: HTTP Cloud Function returned an error:")
  ) {
    return error.message;
  }

  const start = error.message.indexOf("{");
  const end = error.message.lastIndexOf("}") + 1;
  const message = error.message.slice(start, end);

  try {
    const parsed = JSON.parse(message);
    return parsed?.error?.message || error.message;
  } catch (e) {
    return error.message;
  }
};
