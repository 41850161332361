export const AccordionMenuOptions = [
  {
    id: "marketing",
    title: "Marketing",
    options: [
      { selected: false, name: "Marketing Strategy" },
      { selected: false, name: "Social Media" },
      { selected: false, name: "Brand" },
      { selected: false, name: "Content Marketing" },
      { selected: false, name: "Marketing Measuring and Reporting" },
      { selected: false, name: "Marketing Systems and Technology" },
    ],
  },
  {
    id: "newBusinessDevelopment",
    title: "New Business Development",
    options: [
      { selected: false, name: "New Business Development Strategy" },
      { selected: false, name: "Building a Sales Culture" },
      { selected: false, name: "Tenders, Proposals and Pitches" },
      { selected: false, name: "Market and Competitor Analysis" },
      { selected: false, name: "New Business Development Data and Reporting" },
      { selected: false, name: "Sales Systems and Technology" },
    ],
  },
  {
    id: "accountManagement",
    title: "Account Management",
    options: [
      { selected: false, name: "Account Management Strategy" },
      { selected: false, name: "Account Penetration" },
      { selected: false, name: "Customer Retention" },
      { selected: false, name: "The Client Experience" },
      { selected: false, name: "Sales Systems and Technology" },
    ],
  },
  {
    id: "productsServices",
    title: "Products and Services",
    options: [
      { selected: false, name: "Products and Services Strategy" },
      { selected: false, name: "Contract Recruitment" },
      { selected: false, name: "Talent Solutions and Reoccurring Revenues" },
      { selected: false, name: "Search" },
      { selected: false, name: "Pricing" },
      { selected: false, name: "Packaging and Taking to Market" },
    ],
  },
  {
    id: "candidates",
    title: "Candidates",
    options: [
      { selected: false, name: "Candidate Strategy" },
      { selected: false, name: "Candidate Marketing" },
      { selected: false, name: "Candidate Sourcing" },
      { selected: false, name: "The Candidate Experience" },
      { selected: false, name: "Candidate Engagement" },
      { selected: false, name: "Candidate Systems and Technology" },
    ],
  },
  {
    id: "people",
    title: "People",
    options: [
      { selected: false, name: "People Strategy" },
      { selected: false, name: "Personal Effectiveness" },
      {
        selected: false,
        name: "Leadership and Building a High Performance Team",
      },
      { selected: false, name: "Learning and Development" },
      { selected: false, name: "Employee Engagement" },
      { selected: false, name: "People Systems and Technology" },
    ],
  },
  {
    id: "systemsTechnology",
    title: "Systems and Technology",
    options: [
      { selected: false, name: "Systems and Technology Strategy" },
      { selected: false, name: "The Recruitment Tech Supplier Landscape" },
      { selected: false, name: "Marketing Systems and Technology" },
      { selected: false, name: "Sales Systems and Technology" },
      { selected: false, name: "People Systems and Technology" },
      { selected: false, name: "Candidate Systems and Technology" },
      { selected: false, name: "Operations Systems and Technology" },
    ],
  },
  {
    id: "operations",
    title: "Operations",
    options: [
      { selected: false, name: "Operational Strategy" },
      { selected: false, name: "Finance" },
      { selected: false, name: "Compliance, Legals and Terms" },
      { selected: false, name: "Data and Analytics" },
      { selected: false, name: "Process Management" },
      { selected: false, name: "Operations Systems and Technology" },
    ],
  },
];
