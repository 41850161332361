import { TitleBox } from "@components/account/title-box";
import { ContentBox } from "@components/general/layouts/content-box";
import { MemberViewCard } from "@components/org/members/member-view";
import { Assignment } from "@pages/org/assignment";
import { PageVariant } from "@utils/models";
import { useNavigate, useParams } from "react-router-native";
import { useUserContext } from "@context/UserContext";
import { BlockAccess } from "@components/org/members/block-access/BlockAccess";
import { Formik, FormikValues } from "formik";
import { formikInitialValues } from "./formikInitialValues";
// import { DownloadedHistory } from "@components/org/members/downloaded-history/DownloadedHistory";
import { ActivityIndicator, Text, View } from "react-native";
import { styles } from "./style";
import {
  AccountType,
  EmployerUserInput,
  User,
  useEmployerGetUserQuery,
  useEmployerUpdateUserMutation,
} from "@gql/generated/generated";

const deepRemoveTypename = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map((item) => deepRemoveTypename(item));
  } else if (typeof obj === "object" && obj !== null) {
    const newObj = {};
    for (const key in obj) {
      if (key !== "__typename") {
        // @ts-ignore
        newObj[key] = deepRemoveTypename(obj[key]);
      }
    }
    return newObj;
  }
  return obj;
};

const MemberView = () => {
  const navigate = useNavigate();
  const { currentUser, loadingCurrentUser } = useUserContext();
  const { id } = useParams() as { id: string };

  const [updateUser, { loading: updatingUser }] =
    useEmployerUpdateUserMutation();

  const { data: currentMember, loading: gettingCurrentMember, refetch: refetchEmployerGetUser } =
    useEmployerGetUserQuery({ variables: { id }, fetchPolicy: "cache-and-network" });

  const updateAccountStatus = (status: string) => {
    updateUser({
      variables: {
        input: {
          id,
          user: { accountStatus: status },
        },
      },
    });
  };

  const onSubmit = (user: FormikValues) => {
    updateUser({
      variables: {
        input: {
          id,
          user,
        },
      },
    }).catch(console.error);
  };

  const userBlockedAccess = deepRemoveTypename(
    currentMember?.employerGetUser?.blockedAccess || undefined
  );

  const userAccountStatus =
    currentMember?.employerGetUser?.status?.accountStatus;

  const modifyFormikInitialValues: EmployerUserInput = {
    ...formikInitialValues,
    firstName: currentMember?.employerGetUser.accountInfo?.firstName,
    lastName: currentMember?.employerGetUser.accountInfo?.lastName,
    email: currentMember?.employerGetUser.accountInfo?.email ?? "",
    jobPosition:
      currentMember?.employerGetUser.accountInfo?.companyInfo.jobPosition ?? "",
    phoneNumber: currentMember?.employerGetUser.accountInfo?.phoneNumber ?? "",
    country: currentMember?.employerGetUser.accountInfo?.country ?? "",
    accountType: currentMember?.employerGetUser.role?.isEmployer
      ? AccountType.EMPLOYER
      : AccountType.EMPLOYEE,
    blockedAccess: { ...userBlockedAccess },
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={modifyFormikInitialValues}
      enableReinitialize
    >
      <ContentBox
        style={{
          flex: 1,
          marginBottom: 20,
          width: 956,
        }}
      >
        <TitleBox
          title="Member Info"
          backTitle="Back to All Members"
          hasBottomBorder
          onPressBack={() => navigate(-1)}
        />
        {currentUser && !updatingUser && (
          <MemberViewCard
            variant={
              currentUser?.role?.isEmployer
                ? PageVariant.assignment
                : PageVariant.member
            }
            isEmployer={currentUser?.role?.isEmployer!}
            currentMember={currentMember?.employerGetUser}
          />
        )}
        {(loadingCurrentUser || updatingUser) && (
          <ActivityIndicator
            size="large"
            color="#213470"
            style={{ paddingVertical: 20 }}
          />
        )}

        {currentUser?.role?.isEmployer && !gettingCurrentMember && (
          <>
            <Assignment variant={PageVariant.member} member={currentMember?.employerGetUser as User} refetchMember={refetchEmployerGetUser} />
            <BlockAccess userBlockedAccess={userBlockedAccess} />
            {/* TODO restore this once tracked <DownloadedHistory /> */}
            <View style={styles.container}>
              <Text
                style={[styles.accountStatusButtonText, { color: "#2C6ECB" }]}
                onPress={() =>
                  updateAccountStatus(
                    userAccountStatus !== "Disable" &&
                      userAccountStatus !== "Deleted"
                      ? "Disable"
                      : "Active"
                  )
                }
              >
                {updatingUser
                  ? "Changing..."
                  : userAccountStatus !== "Disable" &&
                    userAccountStatus !== "Deleted" &&
                    userAccountStatus !== "Suspend"
                  ? "Disable member"
                  : "Enable member"}
              </Text>
              <Text
                style={[styles.accountStatusButtonText, { color: "#D72C0D" }]}
                onPress={() => updateAccountStatus("Deleted")}
              >
                Delete member
              </Text>
            </View>
          </>
        )}
      </ContentBox>
    </Formik>
  );
};

export { MemberView };
