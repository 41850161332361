import { Text, View } from "react-native";
import { styles } from "./style";

const dummyData = [
  {
    rank: 1,
    title: "Digital Marketing Process for Recruitment Agencies",
    type: "Content",
    category: "Marketing",
    visits: 15,
  },
  {
    rank: 2,
    title: "Digital Marketing Process for Recruitment Agencies",
    type: "Content",
    category: "Marketing",
    visits: 13,
  },
  {
    rank: 3,
    title: "3R South Coast Roundtable",
    type: "Event",
    category: "Product and Services",
    visits: 8,
  },
  {
    rank: 4,
    title: "The Ultimate Brand and Social Media Strategy",
    type: "Training",
    category: "People, Oper...",
    visits: 6,
  },
  {
    rank: 5,
    title: "Candidate sourcing",
    type: "Content",
    category: "Marketing",
    visits: 5,
  },
];

export const OrderedTable = () => {
  return (
    <View style={styles.tableWrapper}>
      <View style={styles.headersContainer}>
        <Text style={styles.headerNumber} />
        <Text style={styles.headerTitle}>Title</Text>
        <Text style={styles.headerRest}>Type</Text>
        <Text style={styles.headerRest}>Category</Text>
        <Text style={styles.headerRest}>No. of Times Visited</Text>
      </View>

      <>
        {dummyData.map(({ rank, title, type, category, visits }) => (
          <View key={rank} style={styles.rowContainer}>
            <Text style={styles.rowTitleNumber}>{rank}.</Text>
            <Text style={styles.rowTitle}>{title}</Text>
            <Text style={styles.rowRest}>{type}</Text>
            <Text style={styles.rowRest}>{category}</Text>
            <Text style={styles.rowRest}>{visits}</Text>
          </View>
        ))}
      </>
    </View>
  );
};
