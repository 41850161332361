import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(13, 23, 56, 0.87)",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    width: "80%",
    maxWidth: 620,
    backgroundColor: "#FFFFFF",
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 1.0,
    shadowColor: "rgba(0, 0, 0, 0.05)",
    borderRadius: 8,
  },
  header: {
    padding: 20,
    borderBottomColor: "#C9CCCF",
    borderBottomWidth: 0.5,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  body: {
    padding: 20,
  },
  bodyDescription: {
    color: "#0D1738",
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
  },
  footer: {
    borderTopColor: "#C9CCCF",
    borderTopWidth: 0.5,
    padding: 14,
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  buttonItem: {
    marginRight: 8,
  },
});
