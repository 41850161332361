import { Platform } from "react-native";
import { Saved as SavedWeb } from "./Saved";
import { SavedNative } from "./Saved.native";

const Saved = Platform.select({
  native: () => SavedNative,
  default: () => SavedWeb,
})();

export { Saved };
