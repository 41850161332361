import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { CheckIcon } from "@components/general/icons/account-icons/CheckIcon";
import { styles } from "./style";

interface Props {
  isSelected?: boolean;
  title?: string;
  onToggle?: () => void;
  content?: React.ReactNode;
}

const FilterCheckbox = ({ isSelected, title, onToggle, content }: Props) => (
  <TouchableOpacity onPress={onToggle} style={styles.wrap}>
    <View style={[styles.checkbox, isSelected && styles.active]}>
      <CheckIcon />
    </View>
    {content ? content : <Text style={styles.title}>{title}</Text>}
  </TouchableOpacity>
);

export { FilterCheckbox };
