import React from "react";
import { CheckboxMenuReasonToJoin } from "../../form-elements/checkbox-menu-formik/CheckboxMenuReasonToJoin";
import { ButtonLayout } from "../../button-layout/ButtonLayout";
import { useFormikContext } from "formik";

interface formProps {
  closeEditMode: () => void;
}

const ReasonsForm = ({ closeEditMode }: formProps) => {
  const { handleSubmit } = useFormikContext();

  const submitCloseHander = () => {
    handleSubmit();
    closeEditMode();
  };

  return (
    <>
      <CheckboxMenuReasonToJoin />
      <ButtonLayout
        closeEditMode={closeEditMode}
        submitCloseHander={submitCloseHander}
      />
    </>
  );
};

export { ReasonsForm };
