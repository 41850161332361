import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

interface Props extends SvgProps {
  color?: string;
}

const PlayIcon = (props: Props) => (
  <Svg width={12} height={14} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 7a.997.997 0 0 1-.485.857l-10 6a1.004 1.004 0 0 1-1.008.014A1.001 1.001 0 0 1 0 13V1A1.001 1.001 0 0 1 1.515.142l10 6c.301.18.485.505.485.857Z"
      fill={props.color ? props.color : "#0D1738"}
    />
  </Svg>
);

export { PlayIcon };
