import { useState } from "react";
import { Modal, Text, View } from "react-native";
import { ModalContainer } from "@components/org/saved/modals/ModalContainer";
import { GenericModalLayout } from "@components/org/saved/modals/generic-modal-layout/GenericModalLayout";
import { ConfirmationModalContent } from "@components/org/saved/modals/confirmation-modal/ConfirmationModalContent";
import { PayButton } from "@pages/account/payment/Payment";
import { useUserContext } from "@context/UserContext";
import {
  ClubMembershipRequestStatus,
  SubscriptionPlanType,
  useSubscribeGoCardlessCustomerMutation,
  useUpdateClubMembershipRequestStatusMutation,
} from "@gql/generated/generated";
import { styles } from "./styles";

interface ConfirmAndPaySectionProps {
  selectedNewPlan: SubscriptionPlanType;
  clubMembershipRequestStatus?: ClubMembershipRequestStatus | null;
  setSelectedNewPlan: (plan: SubscriptionPlanType | null) => void;
  setShowPaymentSuccessModal: (show: boolean) => void;
}

const ConfirmAndPaySection = ({
  selectedNewPlan,
  clubMembershipRequestStatus,
  setSelectedNewPlan,
  setShowPaymentSuccessModal,
}: ConfirmAndPaySectionProps) => {
  const [successModalText, setSuccessModalText] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const { currentUser } = useUserContext();

  const organisationId = currentUser?.accountInfo?.companyInfo?.id;

  const [subscribeGoCardlessCustomer, { loading }] =
    useSubscribeGoCardlessCustomerMutation();

  const [updateClubMembershipRequestStatus] =
    useUpdateClubMembershipRequestStatusMutation();

  const handlePressConfirmAndPay = async () => {
    if (
      organisationId &&
      selectedNewPlan === SubscriptionPlanType.CLUB &&
      clubMembershipRequestStatus !== ClubMembershipRequestStatus.APPROVED
    ) {
      await updateClubMembershipRequestStatus({
        variables: {
          orgId: organisationId,
          clubMembershipRequestStatus: ClubMembershipRequestStatus.PENDING,
        },
      });
      setSuccessModalText(
        "Your application for Club membership has been received. Your subscription will start as soon as your application has been approved."
      );
      setShowSuccessModal(true);
    } else {
      await subscribeGoCardlessCustomer({
        variables: {
          input: {
            plan: selectedNewPlan,
          },
        },
      });
      setShowPaymentSuccessModal(true);
    }
  };

  return (
    <View>
      <Modal visible={showSuccessModal} transparent>
        <ModalContainer>
          <GenericModalLayout
            title="Success!"
            closeModal={() => {
              setShowSuccessModal(false);
              setSelectedNewPlan(null);
            }}
            hasButtonLayout
            buttonName="Done"
            onActionButtonPress={() => {
              setShowSuccessModal(false);
              setSelectedNewPlan(null);
            }}
            withCancel={false}
            footerContainerStyle={{
              borderTopWidth: 0,
            }}
            actionButtonStyle={{
              paddingHorizontal: 35,
              paddingVertical: 5,
              borderRadius: 35,
            }}
          >
            <ConfirmationModalContent
              mainText={successModalText}
              secondaryText=""
              containerStyles={{
                marginBottom: 10,
              }}
            />
          </GenericModalLayout>
        </ModalContainer>
      </Modal>
      <View style={{ paddingHorizontal: 40 }}>
        <View style={styles.yourPurchaseHeader}>
          <Text style={styles.paymentMethodTitle}>Your Purchase</Text>
        </View>
        <View style={styles.containerBottomBorder}>
          <View style={styles.yourPurchasePlanContainer}>
            <Text style={styles.yourPurchasePlanTitle}>Plan</Text>
            <Text style={styles.yourPurchasePlanValue}>
              {selectedNewPlan[0] +
                selectedNewPlan.slice(1, selectedNewPlan.length).toLowerCase()}
            </Text>
          </View>
        </View>
        <View style={styles.containerBottomBorder}>
          <View style={styles.yourPurchasePlanContainer}>
            <Text style={styles.yourPurchasePlanTitle}>Total</Text>
            <View>
              <Text style={styles.yourPurchasePlanPrice}>
                {/* TODO: Get plan price from gocardless/stripe/db */}
                {selectedNewPlan === SubscriptionPlanType.PLUS
                  ? "£249"
                  : "£599"}
              </Text>
              <Text style={styles.yourPurchasePlanTitle}>Billed Monthly</Text>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.paymentFooterContainer}>
        <View>
          <Text style={styles.paymentFooterText}>
            By placing an order at therecruitmentnetwork.com, you’re agreeing
          </Text>
          <View
            style={{
              flexDirection: "row",
            }}
          >
            <Text style={styles.paymentFooterText}>to our </Text>
            <Text style={styles.editButton}>
              Privacy Policy, Terms and Conditions and Cancellation policy.
            </Text>
          </View>
        </View>
        <PayButton
          text={
            selectedNewPlan === SubscriptionPlanType.PLUS
              ? "Confirm and Pay"
              : "Approve Payment"
          }
          onPress={handlePressConfirmAndPay}
          disable={false}
          isLoading={loading}
        />
      </View>
    </View>
  );
};

export { ConfirmAndPaySection };
