import React from "react";
import { StyleProp, View, ViewStyle, Text } from "react-native";
import { FormikProps, FormikValues, useFormikContext } from "formik";
import { Dropdown } from "react-native-element-dropdown";
import { DoubleArrow } from "@components/general/icons/DoubleArrow";
import { styles } from "./styles";

interface DropMenuFormikProps {
  formikKey: string;
  placeholder: string;
  data: { label?: string; value: string }[];
  styleObj?: StyleProp<ViewStyle>;
  withoutVerticalMargin?: boolean;
  labelField?: "label" | "value";
}

const DropMenuFormik = ({
  formikKey,
  placeholder,
  data,
  styleObj,
  withoutVerticalMargin,
  labelField = "value",
}: DropMenuFormikProps) => {
  const { setFieldValue, values, errors, touched }: FormikProps<FormikValues> =
    useFormikContext();

  return (
    <View style={{ position: "relative" }}>
      <View
        style={[
          styles.dropDownContainer,
          withoutVerticalMargin && { marginBottom: 0, marginTop: 0 },
          !!errors[formikKey] &&
            !!touched[formikKey] && {
              borderColor: "rgba(197, 40, 12, 1)",
              backgroundColor: "rgba(253, 226, 221, 1)",
            },
        ]}
      >
        <Dropdown
          value={values[formikKey]}
          onChange={({ value }) => {
            setFieldValue(formikKey, value);
          }}
          data={data}
          labelField={labelField}
          valueField="value"
          placeholder={placeholder}
          placeholderStyle={{
            ...styles.dropDownPlaceholder,
          }}
          style={{
            ...styles.dropDownItem,
          }}
          renderRightIcon={() => <DoubleArrow />}
          dropdownPosition="auto"
          inverted={false}
        />
      </View>
      {errors[formikKey as keyof typeof errors] &&
        touched[formikKey as keyof typeof touched] && (
          <Text style={styles.error}>{errors[formikKey] as string}</Text>
        )}
    </View>
  );
};

export { DropMenuFormik, DropMenuFormikProps };
