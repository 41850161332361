import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { ISvgProps } from "../../../../types";

const CogIcon = ({ color = "#ffffff", ...props }: ISvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <Path
      fill={color}
      d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm7.476-1.246C14.082 9 14.082 7 15.476 6.246a1 1 0 0 0 .376-1.404l-1.067-1.733a1 1 0 0 0-1.327-.355l-.447.243c-1.329.719-2.945-.244-2.945-1.755V1a1 1 0 0 0-1-1H6.934a1 1 0 0 0-1 1v.242c0 1.511-1.616 2.474-2.945 1.755l-.447-.243a1 1 0 0 0-1.327.355L.148 4.842a1 1 0 0 0 .376 1.404C1.918 7 1.918 9 .524 9.754a1 1 0 0 0-.376 1.404l1.067 1.733a1 1 0 0 0 1.327.355l.447-.243c1.33-.719 2.945.244 2.945 1.755V15a1 1 0 0 0 1 1h2.132a1 1 0 0 0 1-1v-.242c0-1.511 1.616-2.474 2.945-1.755l.447.243a1 1 0 0 0 1.327-.355l1.067-1.733a1 1 0 0 0-.376-1.404z"
    />
  </Svg>
);

export { CogIcon };
