import React, { useState } from "react";
import { Platform, Text, TouchableOpacity, View } from "react-native";
import { useNavigate } from "react-router-native";
import RNPopover from "react-native-popover-view";
import { Tooltip } from "@shopify/polaris";
import { Wrapper } from "@components/general/layouts/wrapper/Wrapper";
import { ContentBox } from "@components/general/layouts/content-box";
import {
  ButtonGeneral,
  ButtonType,
  ButtonVariant,
} from "@components/general/button-general";
import { InfoIcon } from "@components/general/icons/roadmap";
import { ids, styles } from "./style";
import { Row } from "@components/general/row";

interface Props {
  title: string;
  totalQuestion: number;
  totalAnswered: number;
  children: React.ReactNode;
  hasSeeResultsButton?: boolean;
}

const RoadmapWrapper = ({
  title,
  totalAnswered,
  totalQuestion,
  children,
  hasSeeResultsButton = false,
}: Props) => {
  const navigate = useNavigate();
  const [showInfoMenu, setShowInfoMenu] = useState(false);
  return (
    <Wrapper style={styles.wrapper}>
      <ContentBox style={styles.contentBox} dataSetMedia={ids.contentBox}>
        <TouchableOpacity onPress={() => navigate(-1)}>
          <Text style={styles.backText}>{`< Back`}</Text>
        </TouchableOpacity>
        <View style={styles.headingWrap} dataSet={{ media: ids.headingWrap }}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '70%',
            }}
          >
            <Text style={styles.title}>{title}</Text>
            <Text style={{ width: '100%', marginVertical: 10 }}>What does SUCCESS look like for your business by the Target Date? Answer whichever questions you feel are relevant to your ambition and aspiration. Be as specific as possible</Text>
            <Text style={styles.description}>
              {totalAnswered} out of {totalQuestion} questions answered
            </Text>
          </View>
          <View>
            {totalAnswered === 0 && (
              <View style={styles.infoWrap}>
                <Text style={styles.infoPercentage}>0%</Text>
                <Text style={styles.infoComplete}>Complete</Text>
                {Platform.OS === "web" ? (
                  <Tooltip
                    content={
                      <Text style={styles.tooltipText}>
                        Complete all sections to see your suggested content and
                        training to improve your business.
                      </Text>
                    }
                  >
                    <TouchableOpacity>
                      <InfoIcon />
                    </TouchableOpacity>
                  </Tooltip>
                ) : (
                  <RNPopover
                    isVisible={showInfoMenu}
                    onRequestClose={() => setShowInfoMenu(false)}
                    from={
                      <TouchableOpacity onPress={() => setShowInfoMenu(true)}>
                        <InfoIcon />
                      </TouchableOpacity>
                    }
                  >
                    <Text style={styles.tooltipText}>
                      Complete all sections to see your suggested content and
                      training to improve your business.
                    </Text>
                  </RNPopover>
                )}
              </View>
            )}
            <Row>
              {hasSeeResultsButton ? (
                <ButtonGeneral
                  label="See Results"
                  type={ButtonType.user}
                  variant={ButtonVariant.Primary}
                  onPress={() => navigate("/roadmap/pulse/result")}
                  style={{ marginRight: 8 }}
                />
              ) : null}
              <ButtonGeneral
                label="Tutorial"
                type={ButtonType.user}
                variant={ButtonVariant.Secondary}
                onPress={() => console.error("Not implemented")}
                style={{ width: 'fit-content' }}
              />
            </Row>
          </View>
        </View>
        {children}
      </ContentBox>
    </Wrapper>
  );
};

export { RoadmapWrapper };
