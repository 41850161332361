import { VideoReadyForDisplayEvent } from "expo-av";
import { useState } from "react";

const useVideo = () => {
  const [videoRatio, setVideoRatio] = useState(300);

  const handleVideoAspectRatio = (event: VideoReadyForDisplayEvent) => {
    const size = event.naturalSize;
    if (size && size.width && size.height) {
      const newVideoRatio = size.width / size.height;
      setVideoRatio(newVideoRatio);
    }
  };

  return {
    videoRatio,
    handleVideoAspectRatio,
    paddingTop: "56.25%",
  };
};

export { useVideo };
