import StyleSheet from "react-native-media-query";
import { mediaQuery } from "@utils/misc";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: "100%",
    maxWidth: 512,
    borderRadius: 4,
    borderTopWidth: 4,
    shadowColor: "rgba(0, 0, 0, 0.15)",
    shadowRadius: 4,
    shadowOffset: { height: 0.5, width: 0 },
    padding: 20,
    justifyContent: "space-between",
    alignItems: "center",
  },
  topWrap: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
  circularWrap: {
    width: "30%",
    [mediaQuery.forMobile]: {
      width: "40%",
    },
  },
  contentWrap: {
    width: "70%",
    alignItems: "flex-end",
    [mediaQuery.forMobile]: {
      width: "60%",
    },
  },
  percentageText: {
    color: "#2C6ECB",
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 16,
    marginBottom: 8,
  },
  doneText: {
    color: "#0D1738",
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
    marginBottom: 12,
  },
  questionsText: {
    color: "#0D1738",
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
  },
  title: {
    fontSize: 20,
    fontFamily: "OpenSans_600SemiBold",
    color: "#0D1738",
  },
  btnWrap: {
    flexDirection: "row",
    alignItems: "center",
  },
  eachButtonWrap: {
    marginRight: 4,
  },
  progressWrap: {
    width: "100%",
    backgroundColor: "rgba(44, 110, 203, 0.2)",
    borderRadius: 15,
    height: 16,
    marginBottom: 8,
  },
  progressInner: {
    backgroundColor: "#2C6ECB",
    borderRadius: 15,
    height: "100%",
  },
  progressPercentText: {
    color: "#FFFFFF",
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 12,
    marginLeft: 8,
  },
});
