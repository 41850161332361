import { Text, View } from "react-native";

export function NotFound() {
  return (
    <View
      style={{
        width: '100vw',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {/* @ts-ignore */}
      <Text style={{ fontSize: '10rem' }}>404</Text>
      {/* @ts-ignore */}
      <Text style={{ fontSize: '2rem' }}>We can help you find candidates, but we couldn't find this page!</Text>
    </View>
  );
}