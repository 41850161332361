import StyleSheet from "react-native-media-query";
import { mediaQuery } from "@utils/misc";

export const { ids, styles } = StyleSheet.create({
  circle: {
    width: 120,
    height: 120,
    borderRadius: 60,
    alignItems: "center",
    justifyContent: "center",
    [mediaQuery.forMobile]: {
      width: 100,
      height: 100,
      borderRadius: 50,
    },
  },
  score: {
    fontFamily: "OpenSans_600SemiBold",
    color: "#FFFFFF",
    fontSize: 42,
    [mediaQuery.forMobile]: {
      fontSize: 32,
    },
  },
  scoreOutOfText: {
    fontSize: 21,
    [mediaQuery.forMobile]: {
      fontSize: 16,
    },
  },
  scoreText: {
    fontFamily: "OpenSans_600SemiBold",
    color: "#FFFFFF",
    fontSize: 16,
  },
});
