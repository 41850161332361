import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const LeftArrowTwo = (props: SvgProps) => (
  <Svg width={9} height={16} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.4 15.2c-.307 0-.614-.118-.848-.352l-6-6a1.199 1.199 0 0 1 0-1.696l6-6a1.199 1.199 0 1 1 1.696 1.696L3.097 8l5.151 5.152A1.199 1.199 0 0 1 7.4 15.2Z"
      fill="#0D1738"
    />
  </Svg>
);

export { LeftArrowTwo };
