import StyleSheet from "react-native-media-query";

type Resize = "none" | "both" | "horizontal" | "vertical";

export const { ids, styles } = StyleSheet.create({
  label: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 14,
    color: "#202223",
    marginTop: 20,
    marginBottom: 4,
  },
  inputText: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 12,
    color: "#0D1738",
    borderWidth: 1,
    borderRadius: 4,
    borderColor: "#C9CCCF",
    backgroundColor: "#FFFFFF",
    padding: 10,
    width: "100%",
    resize: "vertical" as Resize,
  },
});
