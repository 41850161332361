import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { ISvgProps } from "../../../../types";

const MortarboardHatIcon = ({ color = "#fff", ...props }: ISvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <Path
      fill={color}
      d="M16 4.773 8.206.99h-.412L0 4.773v9.017h1.143V6.68l2.64 1.28c-.583 1.033-.898 2.264-.903 3.527a3.253 3.253 0 0 0 0 .47v.156l.32.569 4.549 2.531h.468l4.503-2.574.32-.569v-.626c-.006-1.255-.317-2.48-.891-3.512L16 6.067V4.773zm-4.023 6.656v.1L8 13.79l-4-2.275a.23.23 0 0 1 0-.086c.006-1.096.308-2.158.857-3.015l2.949 1.422h.411l2.926-1.422c.544.859.838 1.922.834 3.015zM8 8.457l-6.263-3.03L8 2.412l6.263 3.044-6.263 3z"
    />
  </Svg>
);

export { MortarboardHatIcon };
