import React, { useEffect, useState } from "react";
import { Text, View } from "react-native";
import { ContentBox } from "@components/general/layouts/content-box";
import { TabTitle } from "@components/general/tab-title";
import { NotificationItem } from "@components/navbar/NotificationBox/notification-item";
import { styles } from "./style";
import { useNavigate } from "react-router-native";
import {
  Notification,
  useMarkNotificationAsReadMutation,
} from "@gql/generated/generated";
import {
  getNotificationBottomText,
  getPopulatedNotification,
  getNotificationTextAndColorFromNotificationType,
} from "@utils/notificationUtils";
import { useCreateApolloClient } from "../../../apolloClient";
import { TouchableOpacityLink } from "@components/general/touchable-opacity-link";

enum TabType {
  all = "All",
  unread = "Unread",
}

interface NotificationBoxProps {
  notifications?: Notification[];
  userId?: string;
  onClose?: () => void;
  refetchFn?: () => void;
  unreadFn: (unread: boolean) => void;
}

const NotificationBox = ({
  notifications,
  userId,
  onClose,
  refetchFn,
  unreadFn,
}: NotificationBoxProps) => {
  const [activeTab, setActiveTab] = useState(TabType.all);
  const [populatedNotifications, setPopulatedNotifications] = useState(
    notifications || []
  );
  const [populating, setPopulating] = useState(false);

  const navigate = useNavigate();
  const [mutation, _] = useMarkNotificationAsReadMutation();

  const notificationsToDisplay = populatedNotifications ?? [];

  const apolloClient = useCreateApolloClient();

  useEffect(() => {
    const populateNotifications = async () => {
      setPopulating(true);
      const ns = notifications || [];

      const notifs = await Promise.all(
        ns.map((notification: Notification) => {
          return getPopulatedNotification(
            apolloClient,
            notification,
            userId || ""
          );
        })
      );

      setPopulatedNotifications(notifs);
      setPopulating(false);
    };

    populateNotifications();
  }, [notifications]);

  return (
    <ContentBox>
      <View style={styles.titleWrap}>
        <Text style={styles.title}>Notifications</Text>
      </View>
      <View style={styles.tabWrap}>
        <View style={styles.tabItems}>
          <TabTitle
            title={TabType.all}
            active={activeTab === TabType.all}
            onPress={() => {
              unreadFn(false);

              return setActiveTab(TabType.all);
            }}
          />
          <TabTitle
            title={TabType.unread}
            active={activeTab === TabType.unread}
            onPress={() => {
              unreadFn(true);

              return setActiveTab(TabType.unread);
            }}
          />
        </View>
        <TouchableOpacityLink
          href="/account/all-notifications"
          onPress={() => {
            navigate(`/account/all-notifications`);

            onClose && onClose();
          }}
        >
          <Text style={styles.seeAllText}>See All</Text>
        </TouchableOpacityLink>
      </View>

      {populating || notificationsToDisplay?.length === 0 ? (
        <View
          style={{
            width: "100%",
            paddingHorizontal: 130,
            paddingVertical: 24,
          }}
        >
          <Text
            style={{
              fontFamily: "OpenSans_600SemiBold",
              fontSize: 14,
              lineHeight: 20,
            }}
          >
            {!populating ? (
              <>No new notifications</>
            ) : (
              <>Loading notifications</>
            )}
          </Text>
        </View>
      ) : null}
      {!populating &&
      Array.isArray(notificationsToDisplay) &&
      notificationsToDisplay.length > 0 ? (
        <View
          style={{
            minWidth: "100%",
            paddingHorizontal: 1,
            paddingVertical: 1,
          }}
        >
          {notificationsToDisplay?.map((notification) => {
            const { text, color } =
              getNotificationTextAndColorFromNotificationType(notification);

            return (
              <NotificationItem
                key={notification.id}
                unread={!notification.read}
                image={notification.coverImage?.storagePath}
                title={notification.title || ""}
                description={notification.details.description || ""}
                notificationStatus={text}
                notificationStatusColor={color}
                bottomText={getNotificationBottomText(notification)}
                href={notification.url || ""}
                onPress={async () => {
                  await mutation({
                    variables: {
                      userId: userId || "",
                      id: notification.id,
                    },
                  });

                  refetchFn && refetchFn();

                  navigate(notification.url || "");

                  onClose && onClose();
                }}
              />
            );
          })}
        </View>
      ) : null}
    </ContentBox>
  );
};

export { NotificationBox };
