type Valuable<T> = {
  [K in keyof T as T[K] extends null | undefined ? never : K]: T[K];
};

export function getValuable<T extends object | null | string, V = Valuable<T>>(
  obj: T
): V | null | string {
  if (obj === null) {
    return null;
  }
  if (typeof obj === "string") {
    return obj;
  }
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([, v]) =>
        !(
          (typeof v === "string" && !v.length) ||
          v === null ||
          typeof v === "undefined"
        )
    )
  ) as V;
}
