import { TableDropdown } from "@components/back-office/tables/table-dropdown";
import { Checkbox } from "@shopify/polaris";
import { useQueryFilters } from "../useGridFilters";

export const EventsTypeFilter = () => {
  const { filters, setFilterValue } = useQueryFilters(["eventTypes"]);

  const value = filters.eventTypes || [];

  const eventTypeItems = [
    {
      id: "In-person",
      content: "In-person",
      prefix: (
        <Checkbox
          label="In-person"
          labelHidden
          checked={value?.includes("In-person")}
        />
      ),
    },
    {
      id: "Virtual",
      content: "Virtual",
      prefix: (
        <Checkbox
          label="Virtual"
          labelHidden
          checked={value?.includes("Virtual")}
        />
      ),
    },
    {
      id: "Hybrid",
      content: "Hybrid",
      prefix: (
        <Checkbox
          label="Hybrid"
          labelHidden
          checked={value?.includes("Hybrid")}
        />
      ),
    },
  ];

  return (
    <TableDropdown
      placeholder={value.length > 0 ? value?.join(", ") : "Event Type"}
      items={eventTypeItems}
      onSelectItem={(id) => {
        if (value?.includes(id)) {
          setFilterValue(
            "eventTypes",
            value?.filter((el: string) => el !== id)
          );
        } else {
          setFilterValue("eventTypes", [...(value || []), id]);
        }
      }}
      minWidth={133}
      onReset={() => setFilterValue("eventTypes", undefined)}
      isSelected={!!filters.eventTypes}
    />
  );
};
