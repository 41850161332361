import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { removeTypenameFromVariables } from "@apollo/client/link/remove-typename";
import generatedIntrospection from "@gql/generated/generated";

import { REACT_APP_GRAPHQL_API_URL } from "@env";
import { setContext } from "@apollo/client/link/context";
import { useEffect, useRef } from "react";
import jwt_decode from "jwt-decode";
import { auth } from "./firebase";
import { User } from "firebase/auth";

export const useCreateApolloClient = () => {
  const idTokenRef = useRef<string | null>(null);
  const userRef = useRef<User | null>(null);

  useEffect(() => {
    return auth.onIdTokenChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken();
        idTokenRef.current = token;
        userRef.current = user;
      } else {
        userRef.current = null;
        idTokenRef.current = null;
      }
    });
  }, []);

  const apolloClientRef = useRef<ApolloClient<NormalizedCacheObject>>();

  if (apolloClientRef.current == null) {
    const getAuthToken = async () => {
      if (!idTokenRef.current || !userRef.current) return null;
      const decoded: { exp: number } = jwt_decode(idTokenRef.current);

      // Uncomment the line below to see the decoded token
      // console.log('>>> idTokenRef.current', idTokenRef.current)

      if (decoded?.exp * 1000 < Date.now()) {
        idTokenRef.current = await userRef.current.getIdToken(true);
        return idTokenRef.current;
      }

      return idTokenRef.current;
    };

    const removeTypenameLink = removeTypenameFromVariables();

    const httpLink = createHttpLink({
      uri: REACT_APP_GRAPHQL_API_URL,
    });

    const authLink = setContext(async (_, { headers }) => {
      return {
        headers: {
          ...headers,
          Authorization: await getAuthToken(),
        },
      };
    });

    apolloClientRef.current = new ApolloClient({
      link: authLink.concat(httpLink).concat(removeTypenameLink),
      cache: new InMemoryCache({
        possibleTypes: generatedIntrospection.possibleTypes,
        typePolicies: {
          FeedItems: {
            keyFields: [],
            fields: {
              items: {
                read(existing) {
                  return existing;
                },
                keyArgs: false,
                // Concatenate the incoming feed items with
                // the existing feed items.
                merge(existing = [], incoming, { readField }) {
                  const existingItems = existing.map(
                    (feedItem: { ref: string }) => readField("id", feedItem)
                  );
                  const filteredIncoming = incoming.filter(
                    (item: { ref: string }) =>
                      !existingItems.includes(readField("id", item))
                  );

                  return [...existing, ...filteredIncoming];
                },
              },
            },
          },
        },
      }),
    });
  }

  return apolloClientRef.current;
};
