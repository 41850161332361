import { View } from "react-native";
import { InputFormik } from "@pages/account/profile/form-elements/input-formik/InputFormik";
import { SelectOption } from "@components/general/form/select-option";
import { COUNTRIES_WITH_ISO } from "@pages/back-office/analytics-back-office/world";
import { useFormikContext } from "formik";

const BillingAddressEditMode = () => {
  const countries = COUNTRIES_WITH_ISO;
  const { values, touched, errors, setFieldValue } = useFormikContext<{
    houseNumber: string;
    addressOne: string;
    addressTwo: string;
    postCode: string;
    city: string;
    country: string;
    countryCode: string;
  }>();

  return (
    <View
      style={{
        marginRight: 275,
      }}
    >
      <View
        style={{
          flexDirection: "row",
        }}
      >
        <InputFormik
          placeholder="First Line Address"
          formikKey="addressOne"
          placeholderTextColor="#6D7175"
        />
      </View>
      <View
        style={{
          flexDirection: "row",
        }}
      >
        <InputFormik
          placeholder="Second Line Address (optional)"
          formikKey="addressTwo"
          placeholderTextColor="#6D7175"
        />
        <InputFormik
          placeholder="Postcode"
          formikKey="postCode"
          placeholderTextColor="#6D7175"
        />
      </View>
      <View
        style={{
          flexDirection: "row",
        }}
      >
        <View style={{ flex: 1 }}>
          <InputFormik
            placeholder="City"
            formikKey="city"
            placeholderTextColor="#6D7175"
          />
        </View>

        <View style={{ flex: 1 }}>
          <View style={{ marginRight: 8, marginBottom: 8 }}>
            <SelectOption
              placeholder="Country"
              value={values.country as string}
              onChange={(selected) => {
                setFieldValue("country", selected);
                setFieldValue(
                  "countryCode",
                  countries.find((c) => c.value === selected)?.iso
                );
              }}
              error={
                touched.country && errors.country
                  ? (errors.country as string)
                  : undefined
              }
              options={countries || []} // Added options prop
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export { BillingAddressEditMode };
