import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  wrapper: {
    width: "100%",
    maxWidth: 874,
  },
  enquiryText: {
    fontSize: 14,
    color: "#202223",
    fontFamily: "OpenSans_400Regular",
    lineHeight: 20,
    marginTop: 20,
    marginBottom: 16,
  },
  enquiryMail: {
    textDecorationLine: "underline",
  },
  rowInputWrap: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 8,
    zIndex: 1,
  },
  inputItem: {
    width: "49%",
  },
  textAreaWrap: {
    marginBottom: 16,
  },
  submitWrap: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  submitItem: {
    marginRight: 8,
  },
  sendBtn: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 10.5,
    lineHeight: 15,
    color: "#FFFFFF",
    backgroundColor: "#0D1738",
    width: 137,
    height: 25,
    textAlign: "center",
    paddingVertical: 5,
    borderRadius: 5,
    marginTop: 10,
    marginLeft: 477,
  },
});
