import { AccountStatusFilter } from "./AccountStatus";
import { CategoriesFilter } from "./Categories";
import { ContentTypeFilter } from "./ContentType";
import { DateFromFilter } from "./DateFrom";
import { DateToFilter } from "./DateTo";
import { EventsTypeFilter } from "./EventType";
import { MemberSinceFilter } from "./MemberSince";
import { PollStatusFilter } from "./PollStatus";
import { PostTypeFilter } from "./PostType";
import { SortByFilter } from "./SortBy";
import { SpecialOffersFilter } from "./SpecialOffers";
import { StatusFilter } from "./Status";
import { TiersFilter } from "./Tiers";
import { UserTypesFilter } from "./UserTypes";
import { SearchFilter } from "./Search";
import { IsHighlightedFilter } from "./IsHighlighted";
import { RoleFilter } from "./MemberType";

export const availableFilters = [
  {
    name: "sortBy",
    component: <SortByFilter />,
  },
  {
    name: "status",
    default: undefined,
    component: <StatusFilter />,
  },
  {
    name: "categories",
    component: <CategoriesFilter />,
  },
  {
    name: "userTypes",
    default: [],
    component: <UserTypesFilter />,
  },
  {
    name: "dateFrom",
    component: <DateFromFilter />,
  },
  {
    name: "dateTo",
    component: <DateToFilter />,
  },
  {
    name: "postType",
    component: <PostTypeFilter />,
  },
  {
    name: "search",
    component: <SearchFilter />,
  },
  {
    name: "eventTypes",
    component: <EventsTypeFilter />,
  },
  {
    name: "contentTypes",
    component: <ContentTypeFilter />,
  },
  {
    name: "pollStatus",
    component: <PollStatusFilter />,
  },
  {
    name: "accountStatus",
    component: <AccountStatusFilter />,
  },
  {
    name: "memberSince",
    component: <MemberSinceFilter />,
  },
  {
    name: "partnerType",
    component: <TiersFilter />,
  },
  {
    name: "specialOffer",
    component: <SpecialOffersFilter />,
  },
  {
    name: "isHighlighted",
    component: <IsHighlightedFilter />,
  },
  {
    name: "role",
    component: <RoleFilter />,
  },
];
