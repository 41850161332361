import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  dropdown: {
    borderWidth: 1,
    borderColor: "#D9DDE2",
    borderRadius: 10,
    paddingHorizontal: 15,
    height: 36,
    marginRight: 5,
    marginBottom: 7,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  selectedText: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 12,
    lineHeight: 24,
    color: "#111827",
  },
  label: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 9.4,
    lineHeight: 12,
    color: "#6B7280",
    backgroundColor: "#FFFFFF",
    position: "absolute",
    marginLeft: 13,
    marginTop: -5,
    paddingHorizontal: 5,
  },
  placeholder: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 12,
    lineHeight: 24,
    color: "#111827",
  },
});
