import React, { useEffect, useState } from "react";
import { ActivityIndicator, ScrollView, Text, View } from "react-native";
import dayjs from "dayjs";
import { useParams } from "react-router-native";
import { NonEmptyArray } from "@shopify/polaris/build/ts/latest/src/types";
import { IndexTableHeading } from "@shopify/polaris/build/ts/latest/src/components/IndexTable";

import { TableDropdown } from "@components/back-office/tables/table-dropdown";
import { ContentBox } from "@components/general/layouts/content-box";
import { GenericTable } from "@components/back-office/tables/generic-table";

import { styles } from "./style";
import { GenericTabs } from "../generic-tabs";
import { useAdminGetPulseRecommendationQuery } from "@gql/generated/generated";

const tableHeaders: NonEmptyArray<IndexTableHeading> = [
  { title: "Title" },
  { title: "Type" },
  { title: "Status" },
  { title: "Date Complete" },
];

export const ContentTracking = ({ yearAndQuarterData }: any) => {
  const [selectedYear, setSelectedYear] = useState<string | null>(null);
  const [selectedQuarter, setSelectedQuarter] = useState<string | null>(null);
  const [tabActive, setTabActive] = useState("Systems and Technology");

  const { id } = useParams();
  const latestYear = yearAndQuarterData[0];

  const selectedYearData = yearAndQuarterData?.find(
    (el: any) => el.year === selectedYear
  );

  useEffect(() => {
    if (latestYear) {
      setSelectedQuarter(latestYear?.quarters[0]);
      setSelectedYear(latestYear?.year);
    }
  }, [yearAndQuarterData]);

  const { data, loading } = useAdminGetPulseRecommendationQuery({
    variables: {
      orgId: id!,
      year: selectedYear!,
      quarter: selectedQuarter!,
    },
    skip: !selectedYear || !selectedQuarter || !id,
  });

  const progress = data?.adminGetPulseRecommendationDataWithProgress;

  const contentTrackingData = progress?.recommendationsWithProgress;

  const selectedSection = progress?.recommendationsWithProgress?.find(
    (el: any) => el.sectionName === tabActive
  );

  const sectionNames = contentTrackingData?.map((el: any) => el.sectionName);

  const handlePress = (index: string) => {
    setTabActive(index);
  };

  const rows = selectedSection?.relevantRecommendations?.map(
    ({ itemId, lastUpdated, status, title, type }: any) => {
      return {
        title,
        type: type === "content" ? "Content" : "Training",
        id: itemId,
        status,
        dateComplete:
          status === "Complete"
            ? dayjs(new Date(Number(lastUpdated))).format("DD/MM/YYYY")
            : "-",
      };
    }
  );

  if (loading) {
    return (
      <View>
        <ActivityIndicator size="large" color="#213470" />
      </View>
    );
  }

  return (
    <ContentBox>
      <View style={{ padding: 20 }}>
        <View style={[styles.row]}>
          <Text style={styles.mainTitle}>Suggested Content Tracking</Text>
          <View style={styles.row}>
            {selectedYearData?.quarters ? (
              <TableDropdown
                items={selectedYearData?.quarters.map((el: any) => ({
                  id: el,
                  content: `Q${el}`,
                }))}
                placeholder={selectedQuarter}
                onSelectItem={(id) => setSelectedQuarter(id)}
              />
            ) : null}

            <TableDropdown
              items={yearAndQuarterData?.map(({ year }: any) => ({
                id: year,
                content: year,
              }))}
              placeholder={selectedYear}
              onSelectItem={(id) => setSelectedYear(id)}
            />
          </View>
        </View>
      </View>
      <ScrollView style={{ overflow: "scroll" }}>
        {sectionNames && sectionNames.length > 0 ? (
          <GenericTabs
            tabs={sectionNames}
            onAction={handlePress}
            activeTab={tabActive}
          />
        ) : null}

        {rows ? (
          <GenericTable
            headings={tableHeaders}
            rows={rows}
            resourceName={{ singular: "Scores", plural: "Scores" }}
            withCheckbox={false}
            withButton={false}
          />
        ) : null}
      </ScrollView>
    </ContentBox>
  );
};
