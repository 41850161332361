import { ContentBox } from "@components/general/layouts/content-box";
import { Text } from "react-native";
import { QuestionAnswerTable } from "../question-answer-table";
import { IRoadMap } from "../roadmap-models";
import { styles } from "./style";

interface Props {
  data: IRoadMap[];
}

export const PulseQuestions = ({ data }: Props) => {
  return (
    <ContentBox style={{ marginVertical: 20 }}>
      <Text style={[styles.textBoldDark, { padding: 20 }]}>Pulse Answers</Text>

      <QuestionAnswerTable data={data} />
    </ContentBox>
  );
};
