import React, { useState } from "react";
import { Platform, Text, TouchableOpacity, View } from "react-native";
import { useNavigate } from "react-router-native";
import { Formik, FormikValues } from "formik";
import * as Yup from "yup";
import {
  AuthError,
  signInWithEmailAndPassword,
  UserCredential,
} from "firebase/auth";
import { Icon } from "@shopify/polaris";
import { AlertMinor } from "@shopify/polaris-icons";
import { ButtonGeneral, ButtonType } from "@components/general/button-general";
import { AuthWrapper } from "@components/sign-up/auth-wrapper";
import { Input } from "@components/general/form/input";
import { getErrorMessage } from "@utils/misc";
import { ids, styles } from "./style";
import { useUrlQuery } from "@hooks/useUrlQuery";
import { auth } from "../../firebase";
import { canSignUp } from "@utils/platform";
import { GetDomainRedirectWhitelistDocument } from "@gql/generated/generated";
import { ApolloClient, useApolloClient } from "@apollo/client";
import { useFeatureFlag } from "@hooks/useFeatureFlag";
import { FEATURE_FLAGS } from "@utils/featureFlags";

const handleRedirectWhitelist = async (
  apolloClient: ApolloClient<object>,
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  email: string
) => {
  const { data } = await apolloClient.query({
    query: GetDomainRedirectWhitelistDocument,
    variables: {
      email,
    },
    fetchPolicy: "no-cache",
  });

  const redirectToOldTRN = data.getDomainRedirectWhitelist.redirect;

  if (redirectToOldTRN) {
    console.log("Redirecting to old TRN");
    window.location.href = "https://app.trnworld.com/";
  } else {
    setErrorMessage(
      "We could not find your user account. Please click ‘register here’ to create your account on the new version of TRN World."
    );
  }
};

export const Login = ({ setIsSignedIn }: any) => {
  const navigate = useNavigate();
  const query = useUrlQuery();
  const apolloClient = useApolloClient();
  const redirect = query.get("redirect");

  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const isDomainRedirectWhitelistEnabled = useFeatureFlag(
    FEATURE_FLAGS.DOMAIN_REDIRECT_WHITELIST
  );

  const onSubmit = async (values: FormikValues) => {
    setIsLoading(true);

    try {
      const credential: UserCredential = await signInWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );

      if (!credential.user.emailVerified) {
        navigate("/verify-email", {
          state: { email: values.email },
        });
      } else {
        setIsSignedIn(true);
        navigate(redirect ? redirect : "/");
      }
    } catch (error) {
      const e = error as AuthError;
      const authUserNotFound = e.code === 'auth/user-not-found';
      if (authUserNotFound && isDomainRedirectWhitelistEnabled) {
        await handleRedirectWhitelist(apolloClient, setErrorMsg, values.email);
        return;
      }
      setErrorMsg(getErrorMessage(e));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthWrapper onPressBack={() => navigate(-1)}>
      <View>
        <Text style={styles.title} dataSet={{ media: ids.title }}>
          Log in to your account
        </Text>
        <Text style={styles.subtitle} dataSet={{ media: ids.subtitle }}>
          Enter your credentials to access your account.
        </Text>
        <Formik
          initialValues={{ email: "", password: "" }}
          onSubmit={onSubmit}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Invalid email")
              .required("This field is required"),
            password: Yup.string().required("This field is required"),
          })}
        >
          {({ values, handleChange, handleSubmit, touched, errors }) => (
            <View
              style={styles.subContainer}
              dataSet={{ media: ids.subContainer }}
            >
              <View style={styles.inputWrap}>
                {errorMsg !== "" && (
                  <View style={styles.errorTextContainer}>
                    {Platform.OS === "web" && (
                      <Icon source={AlertMinor} color="critical" />
                    )}
                    <Text style={styles.errorText}>{errorMsg}</Text>
                  </View>
                )}
                <Input
                  autoCapitalize="none"
                  keyboardType="email-address"
                  autoComplete="email"
                  textContentType="emailAddress"
                  placeholder="Email Address"
                  value={values.email}
                  onChangeText={handleChange("email")}
                  error={
                    touched.email && errors.email
                      ? (errors.email as string)
                      : undefined
                  }
                />
              </View>
              <Input
                secureTextEntry
                placeholder="Password"
                autoCapitalize="none"
                textContentType="password"
                autoComplete="password"
                value={values.password}
                onChangeText={handleChange("password")}
                error={
                  touched.password && errors.password
                    ? (errors.password as string)
                    : undefined
                }
              />
              <TouchableOpacity
                onPress={() => navigate("/forgot-password")}
                style={styles.forgotPasswordTouch}
              >
                <Text
                  style={styles.forgotPassword}
                  dataSet={{ media: ids.forgotPassword }}
                >
                  Forgot Password?
                </Text>
              </TouchableOpacity>
              <ButtonGeneral
                bold
                label="Log in"
                isLoading={isLoading}
                onPress={handleSubmit}
                type={ButtonType.user}
                style={styles.submitButton}
              />
            </View>
          )}
        </Formik>
        {canSignUp() ? (
          <View style={styles.register} dataSet={{ media: ids.register }}>
            <Text style={styles.noAccount}>Don't have an account?</Text>
            <TouchableOpacity onPress={() => navigate("/signup/1")}>
              <Text style={styles.registerLink}>&nbsp;Register here</Text>
            </TouchableOpacity>
          </View>
        ) : null}
      </View>
    </AuthWrapper>
  );
};
