import { Text, View } from "react-native";
import { Role } from "./Column";
import { styles } from "./style";

type Props = {
  role: Role;
  children: string;
};

const getRoleColorPalette = (role: Role) => {
  switch (role) {
    case "completed":
      return { color: "#33A5A1", fillColor: "#41D5B8", textColor: "#FFFFFF" };
    case "inprogress":
      return { color: "#E5EFFD", fillColor: "#4CA1DC", textColor: "#0D1738" };
    default:
      return { color: "#E4E5E7", fillColor: "#6D7175", textColor: "#0D1738" };
  }
};

export const Title = ({ role, children }: Props) => {
  const palette = getRoleColorPalette(role);
  return (
    <View style={[styles.typeBox, { backgroundColor: palette.color }]}>
      <View
        style={[
          styles.typeBoxCircle,
          {
            borderColor: palette.fillColor,
            backgroundColor:
              role === "completed" ? palette.fillColor : "transparent",
          },
        ]}
      >
        {role === "inprogress" && (
          <View
            style={[
              styles.typeBoxCircleInner,
              { backgroundColor: palette.fillColor },
            ]}
          />
        )}
      </View>
      <Text style={[styles.typeBoxText, { color: palette.textColor }]}>
        {children}
      </Text>
    </View>
  );
};
