import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    borderTopColor: "#EDEEEF",
    borderTopWidth: 1,
    width: "100%",
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 20,
    paddingBottom: 20,
  },
  titleWrap: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    fontFamily: "OpenSans_500Medium",
    fontSize: 16,
    color: "#202223",
  },
  dateWrap: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 8,
  },
  requestType: {
    color: "#2C6ECB",
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
  },
  dot: {
    width: 5,
    height: 5,
    borderRadius: 2.5,
    backgroundColor: "#6D7175",
    marginLeft: 5,
    marginRight: 5,
  },
  date: {
    color: "#6D7175",
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
  },
  description: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 14,
    color: "#202223",
    marginTop: 8,
  },
  deleteTouch: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 8,
  },
  deleteText: {
    color: "#D72C0D",
    fontFamily: "OpenSans_400Regular",
    fontSize: 14,
    marginLeft: 7,
  },
});
