import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  reason: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 16,
  },
  reasonText: {
    fontSize: 14,
    color: "#202223",
    lineHeight: 20,
    fontFamily: "OpenSans_400Regular",
    marginBottom: 2,
    marginLeft: 9,
  },
  button: {
    paddingVertical: 5,
    paddingHorizontal: 35,
    borderRadius: 35,
  },
  buttonText: {
    fontSize: 14,
    lineHeight: 20,
    fontFamily: "OpenSans_600SemiBold",
  },
  buttonContainer: {
    flexDirection: "row",
    marginBottom: 40,
    marginTop: 16,
  },
  cancelButtonStyle: {
    backgroundColor: "#fff",
    borderWidth: 0.5,
    borderColor: "#8C9196",
    marginRight: 8,
  },
  deleteButton: {
    backgroundColor: "#0D1738",
  },
  deleteButtonText: {
    color: "#fff",
  },
});
