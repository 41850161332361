import { Section } from "@gql/generated/generated";
import { View } from "react-native";
import { SelectOption } from "@components/general/form/select-option";
import { styles } from "./style";

type Props = {
  categoryData: Section | undefined;
  subcategoryId: string;
  onChange: (subcategoryId: string) => void;
};

export const SubcategoryDropdown = ({
  categoryData,
  subcategoryId,
  onChange,
}: Props): JSX.Element => {
  const subcategoryDropdownItems =
    categoryData?.questions?.map(({ id, subcategory }) => ({
      value: id,
      label: subcategory || id,
    })) || [];

  const dropdownValue =
    subcategoryDropdownItems?.find(({ value }) => value === subcategoryId)
      ?.value || "";

  return (
    <View style={styles.selectWrap}>
      <SelectOption
        value={dropdownValue}
        onChange={onChange}
        options={subcategoryDropdownItems}
      />
    </View>
  );
};
