import React from "react";
import { useNavigate } from "react-router-native";
import { Wrapper } from "@components/general/layouts/wrapper/Wrapper";
import { IndexPageHeader } from "@components/general/index-page-header";
import { FilterListItem } from "@components/filter-mobile/filter-list-item";
import { Pill, PillVariant } from "@components/general/pill";
import { PageVariant } from "@utils/models";
import { useAssignment } from "@hooks/useAssignment";

const AssignmentNative = () => {
  const navigate = useNavigate();
  const { eventItems, contentItems, trainingItems } = useAssignment();

  return (
    <Wrapper>
      <IndexPageHeader title="Assignment" onPressBack={() => navigate(-1)} />
      <FilterListItem
        title="Content"
        onPress={() =>
          navigate("/org/assignment/list", {
            state: { variant: PageVariant.content },
          })
        }
        rightContent={
          contentItems?.length ? (
            <Pill
              text={contentItems.length.toString()}
              variant={PillVariant.LightBlue}
            />
          ) : undefined
        }
      />
      <FilterListItem
        title="Event"
        onPress={() =>
          navigate("/org/assignment/list", {
            state: { variant: PageVariant.event },
          })
        }
        rightContent={
          eventItems?.length ? (
            <Pill
              text={eventItems.length.toString()}
              variant={PillVariant.LightBlue}
            />
          ) : undefined
        }
      />
      <FilterListItem
        title="Training"
        onPress={() =>
          navigate("/org/assignment/list", {
            state: { variant: PageVariant.training },
          })
        }
        rightContent={
          trainingItems?.length ? (
            <Pill
              text={trainingItems.length.toString()}
              variant={PillVariant.LightBlue}
            />
          ) : undefined
        }
      />
    </Wrapper>
  );
};

export { AssignmentNative };
