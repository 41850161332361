import React, { useState } from "react";
import { View } from "react-native";
import { useNavigate } from "react-router-native";
import { Wrapper } from "@components/general/layouts/wrapper/Wrapper";
import { IndexPageHeader } from "@components/general/index-page-header";
import { NotificationSwitch } from "@components/account/notification-switch";
import { ButtonGeneral } from "@components/general/button-general";
import { styles } from "./style";

const Training = () => {
  const navigate = useNavigate();
  const [notificationValues, setNotificationValues] = useState({
    newTraining: false,
    enrolledTraining: false,
    updatedTraining: false,
  });

  return (
    <Wrapper>
      <View style={styles.wrap}>
        <View>
          <IndexPageHeader title="Training" onPressBack={() => navigate(-1)} />
          <NotificationSwitch
            title="New training"
            isActive={notificationValues.newTraining}
            onChange={() =>
              setNotificationValues({
                ...notificationValues,
                newTraining: !notificationValues.newTraining,
              })
            }
          />
          <NotificationSwitch
            title="Enrolled training reminder"
            isActive={notificationValues.enrolledTraining}
            onChange={() =>
              setNotificationValues({
                ...notificationValues,
                enrolledTraining: !notificationValues.enrolledTraining,
              })
            }
          />
          <NotificationSwitch
            title="Updated training"
            isActive={notificationValues.updatedTraining}
            onChange={() =>
              setNotificationValues({
                ...notificationValues,
                updatedTraining: !notificationValues.updatedTraining,
              })
            }
          />
        </View>
        <View style={styles.buttonWrap}>
          <ButtonGeneral label="Save" />
        </View>
      </View>
    </Wrapper>
  );
};

export { Training };
