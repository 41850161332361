import {
  StyleProp,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";
import { styles } from "./styles";

interface ConfirmationModalProps {
  mainText: string;
  secondaryText?: string;
  handleUndo?: () => void;
  containerStyles?: StyleProp<ViewStyle>;
}

const ConfirmationModalContent = ({
  mainText,
  handleUndo,
  secondaryText = "Don’t worry, you can register again before the event starts!",
  containerStyles,
}: ConfirmationModalProps) => {
  return (
    <View
      style={[
        styles.lowerView,
        !handleUndo ? styles.lowerViewDeregister : null,
        containerStyles,
      ]}
    >
      <Text style={styles.lowerViewText}>{mainText}</Text>
      {handleUndo ? (
        <TouchableOpacity onPress={handleUndo}>
          <Text style={styles.lowerViewLink}>Undo</Text>
        </TouchableOpacity>
      ) : (
        <Text style={[styles.lowerViewText, styles.lowerViewTextDeregister]}>
          {secondaryText}
        </Text>
      )}
    </View>
  );
};

export { ConfirmationModalContent };
