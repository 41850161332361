import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: "100%",
    height: 56,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottomWidth: 1,
    borderBottomColor: "#EDEEEF",
    paddingLeft: 20,
    paddingRight: 20,
  },
  text: {
    color: "#0D1738",
    fontSize: 16,
    fontFamily: "OpenSans_600SemiBold",
  },
  regularText: {
    fontFamily: "OpenSans_400Regular",
  },
  rightTextWrap: {
    flexDirection: "row",
    alignItems: "center",
    maxWidth: "60%",
    justifyContent: "flex-end",
  },
  rightText: {
    color: "#6D7175",
    fontSize: 12,
    fontFamily: "OpenSans_400Regular",
    marginRight: 12,
    maxWidth: "90%",
  },
  arrowWrap: {
    paddingRight: 16,
  },
});
