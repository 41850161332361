import StyleSheet from "react-native-media-query";
import { Platform } from "react-native";

type Resize = "none" | "both" | "horizontal" | "vertical";

export const { ids, styles } = StyleSheet.create({
  container: {
    ...(Platform.OS === "web" && { height: "max-content" }),
    marginTop: 20,
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  flexRowGap: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
  },
  title: {
    fontFamily: "OpenSans_500Medium",
    fontSize: 15,
    lineHeight: 18,
    color: "#0D1738",
  },
  plusBtn: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 18,
    lineHeight: 18,
    color: "#4A4A4A",
    backgroundColor: "#F1F1F1",
    borderRadius: 15,
    width: 21,
    height: 21,
    paddingLeft: 5,
    paddingTop: 1,
  },
  moduleContainer: {
    ...(Platform.OS === "web" && { height: "max-content" }),
    borderWidth: 2,
    borderColor: "#ECEEF6",
  },
  flexRowModule: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 8,
    paddingHorizontal: 12,
    backgroundColor: "#D3DAE2",
  },
  arrow: {
    width: 18,
    height: 18,
  },
  trash: {
    width: 12,
    height: 18,
  },
  keyFieldText: {
    fontFamily: "OpenSans_500Medium",
    fontSize: 12,
    lineHeight: 15,
    color: "#0D1738",
    marginLeft: 15,
  },
  flexRowLesson: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#EEF2F5",
    justifyContent: "space-between",
    paddingVertical: 10,
    paddingHorizontal: 12,
  },
  flexRowTypePreview: {
    display: "flex",
    flexDirection: "row",
    marginTop: 18,
    marginBottom: 30,
    borderRightWidth: 1,
    width: 250,
    height: 81,
    borderColor: "#ECEEF6",
  },
  wrapperCheckbox: {
    display: "flex",
    flexDirection: "row",
    marginLeft: 3,
    marginTop: -2,
  },
  labelCheckbox: {
    fontFamily: "OpenSans_500Medium",
    fontSize: 12,
    lineHeight: 10.5,
    color: "#0D1738",
  },
  borderCheckbox: {
    borderColor: "#6E757C",
    borderWidth: 1,
    width: 11,
    height: 11,
    borderRadius: 20,
    marginRight: 3,
    paddingRight: 0,
    marginTop: 4,
  },
  checkbox: {
    borderRadius: 20,
    marginRight: 0,
    paddingRight: 0,
    borderColor: "white",
    width: 7,
    height: 7,
    marginLeft: 1,
    marginTop: 1,
  },
  LessonImg: {
    width: 16,
    height: 13,
    marginTop: 2,
    marginRight: 8,
  },
  typeDocument: {
    borderWidth: 2,
    borderColor: "#ECEEF6",
    borderRadius: 8,
    // width: 694,
    height: 231,
    marginLeft: 87,
    marginBottom: 26,
    paddingVertical: 29,
    paddingHorizontal: 21,
  },
  docTitle: {
    fontFamily: "OpenSans_500Medium",
    fontSize: 12,
    lineHeight: 15,
    color: "#0D1738",
  },
  subTitle: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 12,
    lineHeight: 15,
    color: "#6E757C",
    width: 133,
    height: 30,
    marginTop: 14,
    marginBottom: 25,
  },
  addMoreText: {
    fontFamily: "OpenSans_500Medium",
    fontSize: 12,
    lineHeight: 15,
    color: "#0D1738",
    marginRight: 6,
    marginTop: -30,
  },
  smallSave: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 12,
    lineHeight: 15,
    color: "#FFFFFF",
    backgroundColor: "#8497AD",
    borderRadius: 8,
    width: 92,
    height: 36,
    textAlign: "center",
    paddingTop: 11,
    marginLeft: 6,
  },
  fileUploader: {
    width: 303,
    height: 65,
    marginTop: 12,
    marginBottom: 12,
  },
  backgroundUpload: {
    width: 303,
    height: 65,
    backgroundColor: "#F2F4F8",
    borderRadius: 5,
    cursor: "pointer",
  },
  innerUpload: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    borderWidth: 1,
    borderRadius: 5,
    borderColor: "#8497AD",
    borderStyle: "dashed",
    marginHorizontal: 8,
    marginVertical: 6,
    width: 287,
    height: 54,
  },
  uploadImg: {
    width: 21,
    height: 21,
    marginTop: 15,
    marginRight: 10,
  },
  dropText: {
    fontFamily: "OpenSans_700Bold",
    fontSize: 12,
    lineHeight: 24,
    color: "#6E757C",
    marginTop: 9,
  },
  formatFile: {
    fontFamily: "OpenSans_500Medium",
    fontSize: 8.7,
    lineHeight: 24,
    color: "#A3A9B0",
    marginTop: -9,
  },
  fieldTitle: {
    fontFamily: "OpenSans_500Medium",
    fontSize: 12,
    lineHeight: 15,
    color: "#0D1738",
  },
  editor: {
    // width: 694,
    // height: 479,
    overflow: "scroll",
    borderWidth: 2,
    borderColor: "#ECEEF7",
    marginTop: 22,
    marginBottom: 10,
  },
  separation: {
    borderTopWidth: 0.5,
    borderTopColor: "#ECEEF7",
    // width: 626,
    position: "absolute",
    marginLeft: 115,
    marginTop: 476,
  },
  wordCount: {
    backgroundColor: "#FFFFFF",
    width: 698,
    height: 30,
    marginLeft: 86,
    paddingLeft: 30,
    paddingTop: 10,
    position: "absolute",
    marginTop: 486,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    borderColor: "#ECEEF7",
    borderBottomWidth: 2,
    borderLeftWidth: 2,
    borderRightWidth: 2,
  },
  smallText: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 10.5,
    lineHeight: 10.5,
    color: "#6E757C",
  },
  actionBtnText: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 12,
    lineHeight: 15,
    color: "#FFFFFF",
    textAlign: "center",
    paddingTop: 12,
    backgroundColor: "#8497AD",
    borderRadius: 8,
    width: 155,
    height: 38,
    marginBottom: 29,
  },
  addMoreLessons: {
    height: 38,
    backgroundColor: "#F6F6F7",
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: "#C9CCCF",
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 15,
    lineHeight: 18,
    color: "#0D1738",
    textAlign: "center",
    paddingTop: 10,
  },
  trashButton: {
    border: "none",
    padding: 0,
    backgroundColor: "none",
  },
  vimeoTextarea: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 12,
    color: "#0D1738",
    borderWidth: 1,
    borderRadius: 4,
    borderColor: "#C9CCCF",
    backgroundColor: "#FFFFFF",
    padding: 10,
    width: "100%",
    resize: "vertical" as Resize,
  },
});
