const useSelectOption = (
  value: string,
  onChange: (selected: string) => void,
  isMultiple?: boolean
) => {
  const handleMultiple = (selectedItem: string) => {
    const all = value.split(", ");
    const foundIndex = all.findIndex((element) => element === selectedItem);
    if (foundIndex !== -1) {
      all.splice(foundIndex, 1);
      onChange(all.join(", "));
    } else {
      onChange(value ? `${value}, ${selectedItem}` : selectedItem);
    }
  };

  const checkSelection = (choice: string) => {
    let isSelected = false;
    if (isMultiple) {
      value.split(", ").forEach((item) => {
        if (item === choice) isSelected = true;
      });
    } else {
      isSelected = choice === value;
    }
    return isSelected;
  };

  const handleSingle = (item: string) => {
    onChange(checkSelection(item) ? "" : item);
  };

  const handleSelect = (item: string) => {
    if (isMultiple) handleMultiple(item);
    else handleSingle(item);
  };

  return { checkSelection, handleSelect };
};

export { useSelectOption };
