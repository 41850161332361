import React from "react";
import {
  ActivityIndicator,
  Image,
  Platform,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { Outlet, useLocation, useNavigate } from "react-router-native";
import { Wrapper } from "@components/general/layouts/wrapper/Wrapper";
import { HelpIcon } from "@components/general/icons/account-icons/HelpIcon";
import { ContentBox } from "@components/general/layouts/content-box";
import { BackNav } from "@components/account/back-nav";
import {
  userNavItems,
  organizationNavItems,
} from "@pages/account/account/accountNavBarOptions";
import { ids, styles } from "./style";
import { useUserContext } from "@context/UserContext";
import { Row } from "@components/general/row";
import { userCompanyImage, userProfileImage } from "@utils/userProfileImage";

interface NavItemProps {
  icon?: React.ReactNode;
  title: string;
  onPress?: () => void;
  active?: boolean;
}

const NavItem = ({ icon, active, title, onPress }: NavItemProps) => (
  <TouchableOpacity
    style={[styles.navItem, active && styles.navItemActive]}
    onPress={onPress}
  >
    <Row>
      <View
        style={{
          width: 18,
          alignItems: "flex-start",
        }}
      >
        {icon}
      </View>
      <Text style={styles.navItemTitle}>{title}</Text>
    </Row>
  </TouchableOpacity>
);

interface Props {
  stylesObj?: any;
  isUserAccount: boolean;
  withHelp: boolean;
}

const Account = ({
  stylesObj,
  isUserAccount = true,
  withHelp = true,
}: Props) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { currentUser, loadingCurrentUser } = useUserContext();

  const companyName = currentUser?.accountInfo?.companyInfo?.name;

  const orgAvatarImage = userCompanyImage(currentUser);
  const userAvatarImage = userProfileImage(currentUser);

  const options = isUserAccount ? userNavItems : organizationNavItems;

  if (Platform.OS !== "web") {
    return <Outlet />;
  } else {
    return (
      <Wrapper>
        <View style={[styles.wrap, stylesObj]} dataSet={{ media: ids.wrap }}>
          <View style={styles.accountBox} dataSet={{ media: ids.accountBox }}>
            <ContentBox>
              <View style={styles.nameWrap}>
                {loadingCurrentUser ? (
                  <>
                    <ActivityIndicator size="large" color="#213470" />
                  </>
                ) : (
                  <>
                    <Image
                      source={isUserAccount ? userAvatarImage : orgAvatarImage}
                      style={styles.image}
                    />
                    <Text style={styles.name}>
                      {isUserAccount
                        ? `${currentUser?.accountInfo?.firstName} ${currentUser?.accountInfo?.lastName}`
                        : companyName}
                    </Text>
                  </>
                )}
              </View>
              <View style={styles.navWrap}>
                <Text
                  style={[
                    styles.navItemTitle,
                    { marginBottom: 8, marginTop: 16 },
                  ]}
                >
                  {isUserAccount ? "My Account" : "My Organisation"}
                </Text>
                {options
                  ?.filter((option) => {
                    return currentUser?.role?.isEmployer || !option.adminOnly;
                  })
                  .map((item) => (
                    <NavItem
                      key={item.title}
                      title={item.title}
                      icon={item.icon}
                      active={pathname.includes(item.navLink)}
                      onPress={() => navigate(item.navLink)}
                    />
                  ))}
              </View>
              {withHelp ? (
                <View style={styles.helpWrap}>
                  <NavItem
                    title="Help"
                    icon={<HelpIcon />}
                    active={pathname.includes("/account/help")}
                    onPress={() => navigate("/account/help")}
                  />
                </View>
              ) : null}
            </ContentBox>
            <View style={styles.backWrap}>
              <BackNav title="Back" onPress={() => navigate("/")} />
            </View>
          </View>
          <View style={styles.leftWrap} dataSet={{ media: ids.leftWrap }}>
            <Outlet />
          </View>
        </View>
      </Wrapper>
    );
  }
};

export { Account };
