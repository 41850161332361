import { percentageChange } from "@components/org/analytics/helpers";
import { useGetAdminTrainingStatsQuery } from "@gql/generated/generated";
import { useMemo } from "react";

export const useTrainingAnalytics = () => {
  const { data: trainingData, loading } = useGetAdminTrainingStatsQuery();

  const overview =
    trainingData?.adminGetMemberStats.training?.trainingsOverview;
  const training = trainingData?.adminGetMemberStats.training;

  const boxItems = [
    {
      title: "No. of Enrollments",
      count: overview?.numEnrollmentsMTD,
      countInfo: "times",
      percent: percentageChange(
        overview?.numEnrollmentsLastMonth,
        overview?.numEnrollmentsMTD
      ),
      percentInfo: "than last month",
    },
    {
      title: "No. of Completed Training",
      count: overview?.numCompletedTrainingMTD,
      countInfo: "times",
      percent: percentageChange(
        overview?.numCompletedTrainingLastMonth,
        overview?.numCompletedTrainingMTD
      ),
      percentInfo: "than last month",
    },
    {
      title: "Share Training",
      count: overview?.numSharesMTD,
      countInfo: "times/user",
      percent: percentageChange(
        overview?.numSharesLastMonth,
        overview?.numSharesMTD
      ),
      percentInfo: "than last month",
    },
  ];

  const dataForExport = useMemo(() => {
    return [
      {
        columns: [
          { label: "Title", value: "title" },
          { label: "Avg. time (mins/member)", value: "time" },
          { label: "Enrollment count", value: "enrollmentCount" },
          { label: "Success rate", value: "success" },
          { label: "Shares", value: "shares" },
          { label: "Rating", value: "rating" },
        ],
        data: training?.trainings?.map((item) => {
          return {
            title: item.training.title,
            time: item.avgTimeMins,
            enrollmentCount: item.enrollmentCount,
            success: item.successRate,
            shares: item.training.shareCount || 0,
            rating: item.training.averageStars || 0,
          };
        }),
        name: "Training analytics",
      },
    ];
  }, [training]);

  return {
    trainingData: training,
    trainingLoading: loading,
    boxItems,
    dataForExport,
  };
};
