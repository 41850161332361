import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const LengthIcon = (props: SvgProps) => (
  <Svg width={14} height={12} fill="none" {...props}>
    <Path
      fill="#5C5F62"
      d="M1.293.313h11.375a.812.812 0 0 1 0 1.625H1.293a.812.812 0 1 1 0-1.625Zm0 3.25h8.125a.812.812 0 1 1 0 1.624H1.293a.812.812 0 1 1 0-1.625Zm0 3.25h11.375a.812.812 0 0 1 0 1.625H1.293a.812.812 0 1 1 0-1.626Zm0 3.25h8.125a.812.812 0 1 1 0 1.624H1.293a.812.812 0 1 1 0-1.624Z"
    />
  </Svg>
);

export { LengthIcon };
