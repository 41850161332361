import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  saveItemsWrap: {
    height: "100%",
    marginTop: 20,
    marginBottom: 40,
    marginHorizontal: 40,
  },
});
