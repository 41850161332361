import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  container: {
    padding: 20,
    borderRadius: 4,
    borderColor: "#EDEEEF",
    borderWidth: 1,
    flexDirection: "row",
    marginBottom: 20,
    gap: 16,
  },
  imageContainer: {
    padding: 8,
    borderRadius: 4,
    borderColor: "#EDEEEF",
    borderWidth: 1,
  },
  image: {
    width: 160,
    height: 160,
    resizeMode: "contain",
  },
  contentContainer: {
    flex: 1,
  },
  title: {
    color: "#0D1738",
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 16,
  },
  categories: {
    color: "#0D1738",
    fontFamily: "OpenSans_400Regular",
    fontSize: 12,
  },
  summaryContainer: {
    marginBottom: 8,
  },
  summary: {
    color: "#6D7175",
    fontFamily: "OpenSans_400Regular",
    fontSize: 12,
  },
  partnerTypeContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 4,
  },
});
