import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  calendarModalTitle: {
    color: "#0D1738",
    fontSize: 16,
    fontFamily: "OpenSans_600SemiBold",
    marginBottom: 16,
  },
  calendarModalItem: {
    justifyContent: "center",
    height: 36,
    paddingLeft: 8,
    marginBottom: 10,
  },
  calendarModalItemText: {
    color: "#0D1738",
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
    marginBottom: 14,
  },
});
