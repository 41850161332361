import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  container: {
    marginRight: 408,
  },
  infoText: {
    fontSize: 14,
    fontFamily: "OpenSans_600SemiBold",
    lineHeight: 20,
    color: "#0D1738",
    marginTop: 9,
  },
  accountHolderContainer: {
    marginTop: 8,
  },
  label: {
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
    lineHeight: 20,
    color: "#202223",
    marginBottom: 4,
  },
  rightSideContainer: {
    flexDirection: "row",
    flex: 1,
  },
  image: {
    marginRight: 40,
  },
  paymentInfoContainer: {
    flexDirection: "row",
    marginTop: 2,
  },
  paymentInfo: {
    marginRight: 20,
  },
  paymentInfoTitle: {
    fontSize: 14,
    fontFamily: "OpenSans_600SemiBold",
    color: "#202223",
    lineHeight: 20,
  },
  paymentInfoValue: {
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
    color: "#202223",
    lineHeight: 20,
  },
  editButton: {
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
    lineHeight: 20,
    color: "#2C6ECB",
  },
  optionText: {
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
    lineHeight: 20,
    color: "#0D1738",
  },
  errorContainer: {
    flexDirection: "row",
  },
  errorIcon: {
    width: 20,
    height: 20,
  },
  errorText: {
    paddingLeft: 8,
    color: "#D72C0D",
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
  },
  checkboxContainer: {
    marginTop: 16,
  },
});
