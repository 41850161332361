import {
  ActivityIndicator,
  FlatList,
  Modal,
  Platform,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useEffect, useState } from "react";
import * as Yup from "yup";

import { ContentBox } from "@components/general/layouts/content-box";
import { TitleBox } from "@components/account/title-box";
import { useNavigate } from "react-router-native";
import { SubscriptionPlanInfo } from "@components/account/subscription-plan/subscription-plan-info/SubscriptionPlanInfo";
import { BillingAddress } from "@components/account/subscription-plan/billing-address/BillingAddress";
import { PaymentMethod } from "@components/account/subscription-plan/payment-method/PaymentMethod";
import { PaymentActivity } from "@components/account/subscription-plan/payment-activity/PaymentActivity";
import { Formik } from "formik";
import { ModalContainer } from "@components/org/saved/modals/ModalContainer";
import { GenericModalLayout } from "@components/org/saved/modals/generic-modal-layout/GenericModalLayout";
import { styles } from "./styles";
import { useUserContext } from "@context/UserContext";
import { ConfirmAndPaySection } from "@components/account/subscription-plan/confirm-and-pay-section";
import { COUNTRIES_WITH_ISO } from "@pages/back-office/analytics-back-office/world";
import {
  SubscriptionPlanType,
  useCreateGoCardlessCustomerBankAccountMutation,
  useCreateGoCardlessCustomerMutation,
  useCreateOrganisationBillingAddressMutation,
  useEmployerGetGoCardlessCustomerQuery,
  useEmployerGetOrganisationQuery,
} from "@gql/generated/generated";
import { Toast } from "@components/general/toast";
import { Frame } from "@shopify/polaris";
import { PaymentSuccessModal } from "@components/org/saved/modals/payment-success/PaymentSuccessModal";

interface PlansDropDownProps {
  currentPlan?: SubscriptionPlanType | null;
  selectedNewPlan?: string | null;
  setSelectedNewPlan: (plan: SubscriptionPlanType | null) => void;
}

interface WrapProps {
  children: React.ReactNode;
}

const Wrap = ({ children }: WrapProps) =>
  Platform.OS === "web" ? <Frame>{children}</Frame> : <>{children}</>;

const PlansDropDown = ({
  currentPlan,
  selectedNewPlan,
  setSelectedNewPlan,
}: PlansDropDownProps) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleOptionClick = (option: SubscriptionPlanType) => {
    setSelectedNewPlan(option);
    setShowDropdown(false);
  };

  const plans = [
    { value: SubscriptionPlanType.FREE, label: "Free" },
    { value: SubscriptionPlanType.PLUS, label: "Plus" },
    { value: SubscriptionPlanType.CLUB, label: "Club" },
  ];

  return (
    <View style={styles.container}>
      <Text style={styles.modalText}>
        Please select the plan you’d like to upgrade/downgrade:
      </Text>
      <TouchableOpacity
        style={styles.button}
        onPress={() => setShowDropdown(!showDropdown)}
      >
        <Text style={{ marginLeft: 10 }}>
          {selectedNewPlan
            ? plans.find((el) => el.value === selectedNewPlan)?.label
            : "Select a plan"}
        </Text>
      </TouchableOpacity>
      {showDropdown ? (
        <FlatList
          data={plans}
          keyExtractor={(item) => item.value}
          renderItem={({ item }) => (
            <View>
              <TouchableOpacity
                style={{ padding: 8 }}
                onPress={() => {
                  handleOptionClick(item.value);
                }}
              >
                <Text style={styles.modalText}>
                  {item.value === currentPlan
                    ? item.label + " (current)"
                    : item.label}
                </Text>
              </TouchableOpacity>
            </View>
          )}
          style={[styles.list]}
        />
      ) : null}
    </View>
  );
};

const SubscriptionPlan = () => {
  const navigate = useNavigate();
  const { currentUser, loadingCurrentUser } = useUserContext();
  const [isMenuOpenSub, setIsMenuOpenSub] = useState(false);
  const [isMenuOpenPay, setIsMenuOpenPay] = useState(false);
  const [isMenuOpenPayActivity, setIsMenuOpenPayActivity] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [showPaymentSuccessModal, setShowPaymentSuccessModal] = useState(false);

  const [selectedNewPlan, setSelectedNewPlan] =
    useState<SubscriptionPlanType | null>(null);
  const [showBillingAddressEditMode, setShowBillingAddressEditMode] =
    useState(false);
  const [showPaymentMethodEditMode, setShowPaymentMethodEditMode] =
    useState(false);
  const [createOrganisationBillingAddress] =
    useCreateOrganisationBillingAddressMutation();
  const [createGoCardLessCustomer] = useCreateGoCardlessCustomerMutation();
  const [createGoCardLessCustomerBankAccount] =
    useCreateGoCardlessCustomerBankAccountMutation();

  const organisationId = currentUser?.accountInfo?.companyInfo.id;

  useEffect(() => {
    // TODO: Scroll to bottom of page if user wants
  }, []);

  const { data: organisationData, loading: fetchingOrganisation } =
    useEmployerGetOrganisationQuery({
      skip: !organisationId,
    });

  const { organisationInfo, subscription } =
    organisationData?.employerGetOrganisation || {};

  const {
    businessField,
    recruitmentType,
    noOfEmployees,
    annualRevenue,
    geographyServiced,
    headOfficeArea,
    headOfficeCity,
    crm,
    additionalTechnologies,
    contractorManagementSupport,
    marketingResourceOrExpertise,
    ninetyDayChallenge,
    reasonsForJoining,
  } = organisationInfo || {};

  const hasCompletedBusinessProfile =
    businessField &&
    businessField.length > 0 &&
    recruitmentType &&
    recruitmentType.length > 0 &&
    noOfEmployees &&
    annualRevenue &&
    geographyServiced &&
    geographyServiced.length > 0 &&
    headOfficeArea &&
    headOfficeCity &&
    crm &&
    additionalTechnologies &&
    additionalTechnologies.length > 0 &&
    contractorManagementSupport &&
    marketingResourceOrExpertise &&
    ninetyDayChallenge &&
    reasonsForJoining &&
    reasonsForJoining.length > 0;

  const { addressOne, addressTwo, postCode, city, country } =
    subscription?.billingAddress || {};

  const { clubMembershipRequestStatus } = subscription || {};

  const initialValuesBillingAddress = {
    addressOne: addressOne ?? "",
    postCode: postCode ?? "",
    city: city ?? "",
    country: country ?? "",
    countryCode: COUNTRIES_WITH_ISO.find((c) => c.value === country)?.iso ?? "",
    addressTwo: addressTwo ?? "",
  };

  const hasCompletedBillingAddress =
    !!addressOne && !!postCode && !!city && !!country;

  const {
    data: goCardLessCustomerData,
    loading: fetchingGoCardlessCustomer,
    refetch: refetchGoCardlessCustomer,
  } = useEmployerGetGoCardlessCustomerQuery({
    skip: !organisationId || !hasCompletedBillingAddress,
  });

  const goCardlessCustomer =
    goCardLessCustomerData?.employerGetGoCardlessCustomer;

  const hasCompletedPaymentMethod =
    !!goCardlessCustomer?.bankDetails?.accountNumberEnding;

  const initialValuesPaymentMethod = {
    accountHolderName: "",
    accountNumber: "",
    branchCode: "",
    iban: "",
    postCode: "",
  };

  const onSubmitBillingAddress = async (values: {
    addressOne: string;
    postCode: string;
    city: string;
    country: string;
    countryCode: string;
    addressTwo: string;
  }) => {
    if (!organisationId) return console.error("organisationId is required");
    const billingAddressResult = await createOrganisationBillingAddress({
      variables: {
        input: {
          id: organisationId,
          ...values,
        },
      },
    });

    const goCardlessCustomerResult = await createGoCardLessCustomer();

    if (
      goCardlessCustomerResult.data?.createGoCardlessCustomer &&
      billingAddressResult.data?.createOrganisationBillingAddress
    ) {
      setToastMessage("Billing address updated successfully");
      setShowToast(true);
      setShowBillingAddressEditMode(false);
    }

    return goCardlessCustomerResult;
  };

  const onSubmitPaymentMethod = async (values: {
    onePersonCheck?: boolean;
    accountHolderName: string;
    accountNumber: string;
    branchCode: string;
    iban: string;
    postCode: string;
  }) => {
    const copy = { ...values };
    delete copy.onePersonCheck;
    const res = await createGoCardLessCustomerBankAccount({
      variables: {
        input: {
          ...copy,
        },
      },
    });

    if (res.data?.createGoCardlessCustomerBankAccount) {
      void refetchGoCardlessCustomer();
      setToastMessage("Payment method updated successfully");
      setShowToast(true);
      setShowPaymentMethodEditMode(false);
    }
  };

  return (
    <Wrap>
      <PaymentSuccessModal
        visible={showPaymentSuccessModal}
        closeModal={() => setShowPaymentSuccessModal(false)}
      />
      <ContentBox
        style={{
          paddingBottom: 20,
          width: "100%",
        }}
      >
        <Modal visible={openModal} transparent>
          <ModalContainer>
            <GenericModalLayout
              title="Select Plan"
              closeModal={() => {
                setSelectedNewPlan(null);
                setOpenModal(false);
              }}
              hasButtonLayout
              buttonName="Submit"
              onActionButtonPress={() => {
                setOpenModal(false);
              }}
              withCancel
            >
              <PlansDropDown
                currentPlan={
                  goCardlessCustomer?.subscription?.subscriptionPlanType
                }
                selectedNewPlan={selectedNewPlan}
                setSelectedNewPlan={setSelectedNewPlan}
              />
            </GenericModalLayout>
          </ModalContainer>
        </Modal>
        <TitleBox
          title="Subscription Plan"
          backTitle="Back to Settings"
          onPressBack={() => navigate("/account/settings")}
          hasBottomBorder
        />
        {/* {fetchingOrganisation ||
      fetchingGoCardlessCustomer ||
      loadingCurrentUser ? (
        <ActivityIndicator size="large" color={"#213470"} />
      ) : null} */}

        {fetchingOrganisation ||
        fetchingGoCardlessCustomer ||
        loadingCurrentUser ? (
          <View style={{ paddingVertical: 100 }}>
            <ActivityIndicator size="large" color="#213470" />
          </View>
        ) : null}

        {organisationInfo && currentUser ? (
          <>
            <SubscriptionPlanInfo
              upgradePlanAction={() => setOpenModal(true)}
              hasCompletedBusinessProfile={!!hasCompletedBusinessProfile}
              hasCompletedBillingAddress={hasCompletedBillingAddress}
              hasCompletedPaymentMethod={hasCompletedPaymentMethod}
              selectedNewPlan={selectedNewPlan}
              goCardLessCustomer={goCardlessCustomer}
              clubMembershipRequestStatus={clubMembershipRequestStatus}
            />
            <Formik
              initialValues={initialValuesBillingAddress}
              onSubmit={onSubmitBillingAddress}
              validationSchema={Yup.object().shape({
                addressOne: Yup.string().required("This field is required"),
                postCode: Yup.string().required("This field is required"),
                city: Yup.string().required("This field is required"),
                country: Yup.string().required("This field is required"),
                countryCode: Yup.string().required("This field is required"),
              })}
            >
              <BillingAddress
                isMenuOpen={isMenuOpenSub}
                setIsMenuOpen={setIsMenuOpenSub}
                billingAddress={subscription?.billingAddress}
                showEditMode={showBillingAddressEditMode}
                setShowEditMode={setShowBillingAddressEditMode}
              />
            </Formik>
            <Formik
              initialValues={initialValuesPaymentMethod}
              onSubmit={onSubmitPaymentMethod}
              validationSchema={Yup.object().shape({
                accountHolderName: Yup.string().required(
                  "This field is required"
                ),
                accountNumber: Yup.string()
                  .required("This field is required")
                  .matches(/^[0-9]+$/, "Must be only digits"),

                branchCode: Yup.string()
                  .required("This field is required")
                  .matches(/^[0-9]+$/, "Must be only digits")
                  .min(6, "Must be exactly 6 digits")
                  .max(6, "Must be exactly 6 digits"),
                onePersonCheck: Yup.bool().isFalse(),
                // TODO: IBAN is not required for ZA
                // iban: Yup.string().required("This field is required"),
                // TODO: Do we actually need postcode?
                // postCode: Yup.string().required("This field is required"),
              })}
            >
              <PaymentMethod
                showEditMode={showPaymentMethodEditMode}
                setShowEditMode={setShowPaymentMethodEditMode}
                isMenuOpen={isMenuOpenPay}
                setIsMenuOpen={setIsMenuOpenPay}
                hasCompletedBillingAddress={hasCompletedBillingAddress}
                goCardLessCustomer={goCardlessCustomer}
              />
            </Formik>
            <PaymentActivity
              payments={
                goCardlessCustomer?.subscription?.upComingPayments ?? []
              }
              isMenuOpen={isMenuOpenPayActivity}
              setIsMenuOpen={setIsMenuOpenPayActivity}
            />
          </>
        ) : null}
        {selectedNewPlan &&
        selectedNewPlan !== SubscriptionPlanType.FREE &&
        selectedNewPlan !==
          goCardlessCustomer?.subscription?.subscriptionPlanType &&
        hasCompletedBusinessProfile &&
        hasCompletedBillingAddress ? (
          <ConfirmAndPaySection
            selectedNewPlan={selectedNewPlan}
            setSelectedNewPlan={setSelectedNewPlan}
            clubMembershipRequestStatus={clubMembershipRequestStatus}
            setShowPaymentSuccessModal={setShowPaymentSuccessModal}
          />
        ) : null}
      </ContentBox>
      {Platform.OS === "web" ? (
        <Toast
          text={toastMessage}
          active={showToast}
          onDismiss={() => setShowToast(!showToast)}
        />
      ) : null}
    </Wrap>
  );
};

export { SubscriptionPlan };
