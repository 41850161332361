import { Platform } from "react-native";
import { TrainingDetail as TrainingDetailWeb } from "./TrainingDetail";
import { TrainingDetail as TrainingDetailNative } from "./TrainingDetail.native";

const TrainingDetail = Platform.select({
  native: () => TrainingDetailNative,
  default: () => TrainingDetailWeb,
})();

export { TrainingDetail };
