import React, { useState } from "react";
import { View } from "react-native";
import { ContentBox } from "@components/general/layouts/content-box";
import { StatisticCardsContainer } from "@components/back-office/roadmap/statistic-cards-container";
import {
  ContentTracking,
  PrioritySection,
  ScoresBreakdown,
} from "@components/back-office/roadmap/pulse-view";
import { GenericTabs } from "@components/back-office/roadmap/generic-tabs";
import { PulseQuestions } from "@components/back-office/roadmap/pulse-questions";
import { IRoadMap } from "@components/back-office/roadmap/roadmap-models";
import { Pulse } from "@gql/generated/generated";
import dayjs from "dayjs";
import { getQuarterDetails } from "@pages/roadmap/utils";
import { urlToImageSource } from "@utils/urlToImageSource";
import { roadmapSectionToSubmodule } from "@utils/roadmapSectionToSubmodule";
import { isNotNull } from "@utils/isNotNull";

interface Props {
  pulseData: Pulse[];
}

const tabs = ["Analytics & Progress", "Pulse Answers"];

const groupByYear = (pulse: Pulse[]) => {
  // Use reduce to create an object with years as keys and arrays of quarters as values
  const yearQuarters = pulse.reduce((acc, { year, quarter }) => {
    if (!year || !quarter) return acc;
    // If this year isn't a key in acc yet, add it with an empty array
    if (!acc[year]) {
      acc[year] = [];
    }

    // If this quarter isn't in the array for this year, add it
    if (!acc[year].includes(quarter)) {
      acc[year].push(quarter);
    }

    // Sort quarters in descending order
    acc[year].sort((a, b) => (b > a ? 1 : -1));

    return acc;
  }, {} as { [key: string]: string[] });

  // Transform the yearQuarters object into an array of objects with 'year' and 'quarters' properties
  // Also sort by year in descending order
  return Object.entries(yearQuarters)
    .map(([year, quarters]) => ({ year, quarters }))
    .sort((a, b) => b.year.localeCompare(a.year));
};

export const PulseView = ({ pulseData }: Props) => {
  const [tabActive, setTabActive] = useState("Analytics & Progress");
  const handlePress = (index: string) => {
    setTabActive(index);
  };

  const yearAndQuarterData = groupByYear(pulseData);

  // FIXME: Not implemented
  const [selectedQuarter, _setSelectedQuarter] = useState(pulseData[0].quarter);
  const [selectedYear, _setSelectedYear] = useState(pulseData[0].year);

  const selectedPulseData = pulseData?.find(
    (el) => el.year === selectedYear && el.quarter === selectedQuarter
  );

  const lastUpdatedDate = dayjs(
    new Date(Number(selectedPulseData?.lastUpdated))
  ).format("DD/MM/YY");

  const { nextQuarterStartDate } = getQuarterDetails();

  const cards = [
    {
      title: "Completion",
      mainData: selectedPulseData?.lastUpdated
        ? `${selectedPulseData?.percentageComplete}%`
        : "-",
      secondaryData: selectedPulseData?.lastUpdated ? lastUpdatedDate : "-",
    },
    {
      title: "Last Check",
      mainData: lastUpdatedDate,
      secondaryData: nextQuarterStartDate
        ? dayjs(nextQuarterStartDate).format("DD/MM/YY")
        : "-",
    },
    {
      title: "Current Scores",
      mainData: selectedPulseData?.lastUpdated
        ? `${selectedPulseData?.averageScore ?? 0}%`
        : "-",
      secondaryData: selectedPulseData?.lastUpdated ? "1.25" : "-",
    },
  ];

  const tableData: IRoadMap[] = [
    {
      id: selectedPulseData?.id ?? "",
      name: "North Star",
      submodules:
        selectedPulseData?.sections
          .filter(({ id }) => id)
          .map(roadmapSectionToSubmodule)
          .filter(isNotNull) ?? [],
    },
  ];
  return (
    <>
      <ContentBox style={{ padding: 16 }}>
        {/* tabs */}
        <GenericTabs
          tabs={tabs}
          onAction={(id) => handlePress(id)}
          activeTab={tabActive}
        />

        {tabActive === "Analytics & Progress" && (
          <View style={{ marginTop: 20 }}>
            <StatisticCardsContainer
              items={cards}
              name={selectedPulseData?.lastUpdatedByUserName}
              image={urlToImageSource(
                selectedPulseData?.lastUpdatedByUserProfilePhoto
              )}
            />
            <View style={{ flexDirection: "row", marginBottom: 20 }}>
              <PrioritySection />
              <ScoresBreakdown
                data={selectedPulseData}
                yearAndQuarterData={yearAndQuarterData}
              />
            </View>
            <ContentTracking yearAndQuarterData={yearAndQuarterData} />
          </View>
        )}
        {tabActive === "Pulse Answers" && <PulseQuestions data={tableData} />}
      </ContentBox>
    </>
  );
};
