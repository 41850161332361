import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    borderTopWidth: 1,
    borderTopColor: "#EDEEEF",
  },
  itemRow: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 40,
    paddingRight: 40,
    width: "100%",
  },
  itemCell: {
    width: "17.5%",
  },
  itemCellTwo: {
    width: "25%",
  },
  firstItemCell: {
    width: "35%",
    flexDirection: "row",
    alignItems: "center",
  },
  lastItemCell: {
    width: "5%",
  },
  userImage: {
    height: 40,
    width: 40,
    borderRadius: 20,
  },
  presenceCircle: {
    height: 12,
    width: 12,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: "#FFFFFF",
    position: "absolute",
    bottom: 0,
    left: 30,
  },
  userName: {
    fontSize: 14,
    color: "#0D1738",
    marginLeft: 16,
    marginRight: 8,
    fontFamily: "OpenSans_600SemiBold",
  },
  adminItem: {
    backgroundColor: "#D3E5FE",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 2,
    paddingBottom: 2,
    borderRadius: 15,
    marginHorizontal: 8,
  },
  adminItemText: {
    fontSize: 12,
    color: "#0D1738",
    fontFamily: "OpenSans_400Regular",
  },
  cellText: {
    fontSize: 14,
    color: "#0D1738",
    fontFamily: "OpenSans_400Regular",
    width: "100%",
  },
  touchBox: {
    borderWidth: 1,
    borderColor: "#D2D5D8",
    borderRadius: 4,
    height: 36,
    width: 36,
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  trainingWrap: {
    borderTopWidth: 1,
    borderTopColor: "#EDEEEF",
    width: "100%",
  },
  trainingInner: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 40,
    paddingRight: 40,
  },
  trainingCellOne: {
    width: "35%",
    paddingLeft: 56,
  },
  trainingCellTwo: {
    width: "42.5%",
    flexDirection: "row",
    alignItems: "center",
  },
  trainingCellThree: {
    width: "17.5%",
  },
  trainingCellFour: {
    width: "5%",
  },
  trainingTitle: {
    color: "#0D1738",
    fontSize: 14,
    fontFamily: "OpenSans_600SemiBold",
    width: "100%",
  },
  trainingPercentText: {
    color: "#0D1738",
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
    marginRight: 8,
  },
  progressWrap: {
    width: 171,
    height: 8,
    borderRadius: 4,
    backgroundColor: "#E4E5E7",
  },
  progressInner: {
    height: "100%",
    borderRadius: 4,
    backgroundColor: "#2C6ECB",
  },
  viewCourseTouch: {
    flexDirection: "row",
    alignItems: "center",
  },
  viewCourseText: {
    color: "#2C6ECB",
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
    textDecorationLine: "underline",
    marginRight: 4,
  },
  trainingInfoText: {
    color: "#0D1738",
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
    marginBottom: 20,
  },
  trainingInfoSubText: {
    color: "#6D7175",
  },
  trainingInfoWrap: {
    paddingLeft: 96,
    paddingRight: 40,
    paddingTop: 12,
    paddingBottom: 12,
  },
});
