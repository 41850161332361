import { AccessByType } from "@gql/generated/generated";
import { AllCategories, CategoryGroup } from "@utils/constants";

type Accumulator = {
  [key: string]: CategoryGroup;
};

const getUnavailableCategories = (
  allCategories: AllCategories,
  availableCategories: Partial<AllCategories>
): Partial<AllCategories> | null => {
  // Check if availableCategories only contains empty arrays and if so return null so no access is blocked
  const isEmpty = Object.values(availableCategories).every((category) =>
    Object.values(category).every((subCategories) => subCategories.length === 0)
  );

  if (isEmpty) {
    return null;
  }

  return Object.entries(allCategories).reduce(
    (acc: Accumulator, [mainKey, mainValue]) => {
      // @ts-ignore FIXME: bad type
      acc[mainKey] = Object.entries(mainValue).reduce(
        (subAcc, [subKey, subValue]) => {
          const availableSubCategories =
            // @ts-ignore
            availableCategories[mainKey] && availableCategories[mainKey][subKey]
              ? // @ts-ignore
                availableCategories[mainKey][subKey]
              : [];
          // @ts-ignore
          subAcc[subKey] = subValue.filter(
            (subCategory) => !availableSubCategories.includes(subCategory)
          );
          return subAcc;
        },
        {}
      );

      return acc;
    },
    {}
  );
};

const getAvailableCategories = (
  allCategories: AllCategories,
  unavailableCategories?: AccessByType | null
): Partial<AllCategories> => {
  return Object.entries(allCategories).reduce(
    (acc: Accumulator, [mainKey, mainValue]) => {
      // @ts-ignore FIXME: bad type
      acc[mainKey] = Object.entries(mainValue).reduce(
        (subAcc, [subKey, subValue]) => {
          const unavailableSubCategories =
            // @ts-ignore
            unavailableCategories[mainKey] &&
            // @ts-ignore
            unavailableCategories[mainKey][subKey]
              ? // @ts-ignore
                unavailableCategories[mainKey][subKey]
              : [];

          // @ts-ignore
          subAcc[subKey] = subValue.filter(
            (subCategory) => !unavailableSubCategories.includes(subCategory)
          );
          return subAcc;
        },
        {}
      );

      return acc;
    },
    {}
  );
};

const getFirstAndLastName = (
  name: string
): { firstName: string; lastName: string } => {
  const firstLast = name.split(" ");
  const firstName = firstLast.slice(0, -1).join(" ");
  const lastName = firstLast[firstLast.length - 1];
  return { firstName, lastName };
};

export {
  getAvailableCategories,
  getUnavailableCategories,
  getFirstAndLastName,
};
