import { storage } from "../firebase";
import { getDownloadURL, ref } from "firebase/storage";
import { useCallback, useEffect, useState } from "react";

export const useGetFile = (storagePath?: string | null) => {
  const [fileUrl, setFileUrl] = useState<string>();

  const getFile = useCallback(async (path: string) => {
    try {
      const downloadUrl = await getDownloadURL(ref(storage, path));
      setFileUrl(downloadUrl);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    if (!storagePath) return;
    getFile(storagePath);
  }, [getFile, storagePath]);

  return { fileUrl };
};
