import * as Sentry from "@sentry/react";
import { SENTRY_DSN } from "@env";

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [Sentry.replayIntegration()],

  // Capture Replay for 10% of all sessions,
  // replaysSessionSampleRate: 0.1,

  // Capture Replay 100% of sessions with an error
  replaysOnErrorSampleRate: 1.0,
});
