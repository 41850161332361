import StyleSheet from "react-native-media-query";
import { mediaQuery } from "@utils/misc";

export const { ids, styles } = StyleSheet.create({
  hilightBanner: {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 2,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: '100%',
    backgroundColor: "#2C6ECB",
    height: 56,
    padding: 5
  },
  hilightBannerText: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 15,
    color: "#fff",
    textAlign: "center",
    [mediaQuery.forMobile]: {
      fontSize: 11,
    }
  }
});