import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-native";
import {
  CreateAccount,
  type CreateAccountInput,
} from "@pages/sign-up/create-account";
import { CreatePassword, PasswordForm } from "@pages/sign-up/create-password";
import { MoreDetails, MoreDetailsForm } from "@pages/sign-up/more-details";
import { AccountType } from "@gql/generated/generated";

export type FormValues = CreateAccountInput & PasswordForm;

enum SignUpStage {
  CREATE_ACCOUNT = "1",
  CREATE_PASSWORD = "2",
  MORE_DETAILS = "3",
}

const signupStageSequence = [
  SignUpStage.CREATE_ACCOUNT,
  SignUpStage.CREATE_PASSWORD,
  SignUpStage.MORE_DETAILS,
];

const SignUp = () => {
  const navigate = useNavigate();
  const stage =
    useParams<{ stage: SignUpStage }>().stage || SignUpStage.CREATE_ACCOUNT;
  const [values, setValues] = useState<FormValues>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    accountType: AccountType.EMPLOYEE,
    password: "",
    confirmPassword: "",
  });

  const handleBack = () => {
    if (stage === SignUpStage.CREATE_ACCOUNT) {
      navigate("/");
      return;
    }

    navigate(
      `/signup/${signupStageSequence[signupStageSequence.indexOf(stage) - 1]}`
    );
  };

  const handleForward = (formikValues?: CreateAccountInput | PasswordForm) => {
    setValues({ ...values, ...formikValues });

    if (stage === SignUpStage.MORE_DETAILS) {
      return;
    }

    navigate(
      `/signup/${signupStageSequence[signupStageSequence.indexOf(stage) + 1]}`
    );
  };

  switch (stage) {
    case SignUpStage.CREATE_ACCOUNT:
      return (
        <CreateAccount
          values={values}
          onPressBack={handleBack}
          onPressForward={handleForward}
        />
      );
    case SignUpStage.CREATE_PASSWORD:
      return (
        <CreatePassword
          values={values}
          onPressBack={handleBack}
          onPressForward={handleForward}
        />
      );
    case SignUpStage.MORE_DETAILS:
      return <MoreDetails prevValues={values} onPressBack={handleBack} />;
    default:
      navigate("/");
      return null;
  }
};

export { SignUp };
