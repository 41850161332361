import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: "100%",
  },
  commentItem: {
    marginTop: 12,
  },
  replyItem: {
    marginTop: 12,
    paddingLeft: 40,
  },
});
