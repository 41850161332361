import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { ISvgProps } from "../../../../types";

export const ExternalSmallMinor = ({
  color = "#2C6ECB",
  ...props
}: ISvgProps) => (
  <Svg
    width={10}
    height={10}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 8v1a1 1 0 0 1-1 1H1c-.575 0-1-.484-1-1V2a1 1 0 0 1 1-1h1c1.037 0 1.04 1.5 0 1.5-.178.005-.353 0-.5 0v6h6V8C7.5 7 9 7 9 8ZM5.25.75A.75.75 0 0 1 6 0h4v4a.75.75 0 0 1-1.5 0V2.56L5.28 5.78a.75.75 0 0 1-1.06-1.06L7.44 1.5H6a.75.75 0 0 1-.75-.75Z"
      fill={color}
    />
  </Svg>
);
