import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  errorTextContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  errorTextWrap: {
    marginLeft: 8,
  },
  errorIconWrap: {
    marginTop: 5,
  },
});
