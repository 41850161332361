import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  textInput: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 12,
    lineHeight: 24,
    color: "#111827",
    borderWidth: 2,
    borderColor: "#D9DDE2",
    borderRadius: 10,
    paddingHorizontal: 15,
    paddingVertical: 10,
    marginRight: 5,
    marginBottom: 7,
  },
  label: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 9.4,
    lineHeight: 12,
    color: "#6B7280",
    backgroundColor: "#FFFFFF",
    position: "absolute",
    marginLeft: 13,
    marginTop: -5,
    paddingHorizontal: 5,
  },
});
