import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  mainContainer: {
    width: 254,
    backgroundColor: "#0D1738",
    position: "fixed",
    top: 0,
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
    zIndex: 99,
    paddingTop: 88,
  },
  menuBtn: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 25,
    textDecoration: "none",
  },
  menuBtnSelected: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 11,
    marginTop: -16,
    backgroundColor: "#FFFFFF",
    paddingBottom: 14,
    paddingTop: 14,
    textDecoration: "none",
  },
  menuBtnSelectedTop: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 11,
    backgroundColor: "#FFFFFF",
    paddingBottom: 12,
    paddingTop: 29,
    textDecoration: "none",
  },
  subMenuBtnSelected: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 11,
    marginTop: -16,
    backgroundColor: "#FFFFFF73",
    paddingBottom: 14,
    paddingTop: 14,
    textDecoration: "none",
  },
  btnTextWhite: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 15,
    lineHeight: 15,
    color: "#FFFFFF",
  },
  btnTextBlue: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 15,
    lineHeight: 15,
    color: "#0D1738",
  },
  analyticsBtn: {
    width: 18,
    height: 10,
    marginLeft: 27,
    marginRight: 10.5,
    marginTop: 3,
  },
  contentBtn: {
    width: 18,
    height: 18,
    marginLeft: 27,
    marginRight: 10,
  },
  eventsBtn: {
    width: 13,
    height: 15,
    marginLeft: 29,
    marginRight: 13,
  },
  trainingBtn: {
    width: 16,
    height: 14.22,
    marginLeft: 27,
    marginRight: 12,
  },
  postsBtn: {
    width: 20,
    height: 20,
    marginLeft: 26,
    marginRight: 9,
    marginTop: -3,
  },
  pollsBtn: {
    width: 22,
    height: 22,
    marginLeft: 25,
    marginRight: 8,
    marginTop: -4,
  },
  marketBtn: {
    width: 21,
    height: 21,
    marginLeft: 25,
    marginRight: 9,
    marginTop: -3,
  },
  usersBtn: {
    width: 15,
    height: 16,
    marginLeft: 27,
    marginRight: 13,
    marginTop: -1,
  },
  roadmapBtn: {
    width: 20,
    height: 10,
    marginLeft: 26,
    marginRight: 9,
    marginTop: 3,
  },
  footer: {
    backgroundColor: "#303956",
    padding: 24,
  },
  defaultProfile: {
    width: 32,
    height: 32,
    marginTop: 25,
    marginLeft: 30,
    marginRight: 12,
    marginBottom: 34,
  },
  name: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 16,
    lineHeight: 15,
    color: "#FFFFFF",
  },
  role: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 12,
    lineHeight: 15,
    color: "#FFFFFF",
  },
  option: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textDecoration: "none",
    marginTop: 24,
  },
  optionText: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 14,
    lineHeight: 15,
    color: "#FFFFFF",
    marginLeft: 8,
  },
  optionSelected: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#FFFFFF",
    alignItems: "center",
    height: 43,
    textDecoration: "none",
  },
  optionTextSelected: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 15,
    lineHeight: 15,
    color: "#0D1738",
  },
  settings: {
    width: 20,
    height: 20,
    marginLeft: 32,
    marginRight: 10,
    marginTop: -2,
  },
  help: {
    width: 18,
    height: 18,
    marginLeft: 32,
    marginRight: 11,
    borderRadius: 15,
    borderColor: "#FFFFFF",
    borderWidth: 1,
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 15,
    lineHeight: 15,
    color: "#FFFFFF",
    paddingLeft: 5,
    marginTop: -2,
  },
  logout: {
    width: 21,
    height: 21,
    marginLeft: 32,
    marginRight: 10,
    marginTop: -2,
  },
});
