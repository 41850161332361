import React from "react";
import { Text, View } from "react-native";
import { styles } from "./style";
import { Title } from "./Title";

export type Role = "todo" | "inprogress" | "completed";

export type TColumn = {
  id: Role;
  role: Role;
  title: string;
};

type Props = TColumn & {
  total: number;
  children?: React.ReactNode;
};

export const Column = ({ role, title, total, children }: Props) => (
  <View style={styles.wrap}>
    <View style={styles.titleWrap}>
      <Title role={role}>{title}</Title>
      <Text style={styles.countText}>{total}</Text>
    </View>
    {children}
  </View>
);
