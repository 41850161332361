import { Text, TouchableOpacity, View } from "react-native";
import React, { useState } from "react";

import { styles } from "./style";
import { textStyles } from "@styles/text";
import { ButtonGeneral, ButtonVariant } from "../../../general/button-general";
import { PersonRow } from "./person-row";
import { TrashIcon } from "@components/general/icons/account-icons/TrashIcon";
import {
  Instructor,
  Speaker,
  useGetAllInstructorsQuery,
  useGetAllSpeakersQuery,
} from "@gql/generated/generated";
import { useFormikContext } from "formik";
import { EditIcon } from "@components/general/icons/org-icons/EditIcon";
import { EditForm } from "./EditForm";
import { AddSpeakerOrInstructorProps, Context, EditPerson } from "./types";

const EditButton = ({ onPress }: { onPress?: () => void }) => (
  <TouchableOpacity
    style={{
      flexDirection: "row",
      alignItems: "center",
    }}
    onPress={onPress}
  >
    <EditIcon fill="#6D7175" />
    <Text style={[textStyles.subduedTextMedium, { marginLeft: 2 }]}>Edit</Text>
  </TouchableOpacity>
);

const DeleteButton = ({ onPress }: { onPress?: () => void }) => (
  <TouchableOpacity
    style={{
      flexDirection: "row",
      alignItems: "center",
    }}
    onPress={onPress}
  >
    <TrashIcon fill="#6D7175" />
    <Text style={[textStyles.subduedTextMedium, { marginLeft: 4 }]}>
      Delete
    </Text>
  </TouchableOpacity>
);

export const AddSpeakerOrInstructor = ({
  personType,
  formikFieldBase,
}: AddSpeakerOrInstructorProps) => {
  const { values, setFieldValue } = useFormikContext<Context>();
  const [editPerson, setEditPerson] = useState<EditPerson>();

  const { data: instructors } = useGetAllInstructorsQuery({
    skip: personType === "Speaker",
  });

  const { data: speakers } = useGetAllSpeakersQuery({
    skip: personType === "Instructor",
  });

  const addedPeople = values[formikFieldBase];

  return (
    <View style={styles.personContainer}>
      <View style={styles.headerPerson}>
        <Text style={styles.subTitlePerson}>{personType}s</Text>
        <ButtonGeneral
          variant={ButtonVariant.Primary}
          style={{ paddingVertical: 6 }}
          label={`Add ${personType.toLowerCase()}`}
          onPress={() => setFieldValue(formikFieldBase, [...addedPeople, null])}
        />
      </View>
      {[...(addedPeople || [])]?.map((person, i) => {
        return (
          <React.Fragment key={i}>
            <PersonRow
              personIndex={i}
              formikFieldBase={formikFieldBase}
              personLabel={personType}
              existingPeople={
                instructors?.getAllInstructors || speakers?.getAllSpeakers || []
              }
              handlePress={() =>
                setEditPerson({
                  person: null,
                  i,
                })
              }
            />
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: 20,
              }}
            >
              {!!person && (
                <EditButton onPress={() => setEditPerson({ person, i })} />
              )}
              <DeleteButton
                onPress={() => {
                  const newValues = [...addedPeople].filter(
                    (_person: Speaker | Instructor, j: number) => j !== i
                  );
                  setFieldValue(formikFieldBase, newValues);
                }}
              />
            </View>
          </React.Fragment>
        );
      })}
      <EditForm
        {...{
          editPerson,
          formikFieldBase,
          personType,
          setEditPerson,
          setFieldValue,
        }}
      />
    </View>
  );
};
