import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const FileIcon = (props: SvgProps) => (
  <Svg width={11} height={12} fill="none" {...props}>
    <Path
      fill="#5C5F62"
      d="M7.636 0c.142 0 .28.046.39.131l2.72 2.089a.591.591 0 0 1 .234.468V11.4c0 .331-.28.6-.625.6h-8.75a.613.613 0 0 1-.625-.6V.6c0-.331.28-.6.625-.6h6.031ZM2.23 1.2v9.6h7.5V4.2H7.543c-.487 0-.888-.357-.933-.813L6.605 3.3V1.2H2.23Zm7.5 1.777-1.875-1.44V3H9.73v-.023Z"
    />
  </Svg>
);

export { FileIcon };
