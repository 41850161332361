import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { PointerArrowLeft } from "@components/general/icons/account-icons/PointerArrowLeft";
import { ShareIcon } from "@components/general/icons";
import { PlusIcon } from "@components/general/icons/feed-icons";
import { styles } from "./style";

interface Props {
  onPressBack?: () => void;
  onSave?: () => void;
  onShare?: () => void;
  title?: string;
  rightContent?: React.ReactNode;
  isBackButtonHidden?: boolean;
}

const IndexPageHeader = ({
  onPressBack,
  onSave,
  onShare,
  title,
  rightContent,
  isBackButtonHidden,
}: Props) => (
  <View style={styles.wrap}>
    <View style={styles.flexHeader}>
      {isBackButtonHidden ? null : (
        <TouchableOpacity style={styles.backTouch} onPress={onPressBack}>
          <PointerArrowLeft />
        </TouchableOpacity>
      )}
      <Text style={[styles.title, isBackButtonHidden && styles.titleMargin]}>
        {title}
      </Text>
    </View>
    <View style={styles.flexHeader}>
      {rightContent}
      {onShare ? (
        <TouchableOpacity
          style={[styles.roundTouch, styles.roundTouchMargin]}
          onPress={onShare}
        >
          <ShareIcon />
        </TouchableOpacity>
      ) : null}
      {onSave ? (
        <TouchableOpacity style={styles.roundTouch} onPress={onSave}>
          <PlusIcon />
        </TouchableOpacity>
      ) : null}
    </View>
  </View>
);

export { IndexPageHeader };
