import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { RightArrow } from "@components/general/icons";
import { CheckIcon } from "@components/general/icons/training-icons/CheckIcon";
import { styles } from "./style";

interface Props {
  title: string;
  onPress?: () => void;
  rightText?: string;
  isTextRegular?: boolean;
  rightContent?: React.ReactNode;
}

const FilterListItem = ({
  title,
  onPress,
  rightText,
  isTextRegular,
  rightContent,
}: Props) => {
  const getRightContent = () => {
    if (rightContent) {
      return rightContent;
    } else if (rightText) {
      return (
        <View style={styles.rightTextWrap}>
          <Text style={styles.rightText} ellipsizeMode="tail" numberOfLines={1}>
            {rightText}
          </Text>
          <CheckIcon color="#2C6ECB" />
        </View>
      );
    } else {
      return (
        <View style={styles.arrowWrap}>
          <RightArrow />
        </View>
      );
    }
  };

  return (
    <TouchableOpacity style={styles.wrap} onPress={onPress}>
      <Text style={[styles.text, isTextRegular && styles.regularText]}>
        {title}
      </Text>
      {getRightContent()}
    </TouchableOpacity>
  );
};
export { FilterListItem };
