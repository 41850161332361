import React, { useMemo, useRef, useState } from "react";
import {
  ActivityIndicator,
  Platform,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { AVPlaybackStatusSuccess, ResizeMode, Video } from "expo-av";
import {
  DocumentIcon,
  LargePlayIcon,
} from "@components/general/icons/training-icons";
import { useVideo } from "@hooks/useVideo";
import { styles } from "./style";
import { DocumentAsset, EmbeddedVimeo } from "@gql/generated/generated";
import { useGetFile } from "@hooks/useGetFileUrl";
import { LessonType } from "@utils/models";
import { colors } from "@styles/colors";
import { textStyles } from "@styles/text";
import { useGetFiles } from "@hooks/useGetFiles";
import { DownloadFilesButton } from "@components/general/download-files-button";
import { HtmlParser } from "@components/general/html-parser";

interface Props {
  file?: DocumentAsset | EmbeddedVimeo | null;
  attachments?: DocumentAsset[] | null;
  content: string;
  title: string;
  loading: boolean;
  type?: string | null;
}

const LessonContent = ({
  title,
  content,
  file,
  loading,
  type,
  attachments,
}: Props) => {
  const video = useRef<Video>(null);
  const [status, setStatus] = useState<AVPlaybackStatusSuccess>();
  const { handleVideoAspectRatio, videoRatio, paddingTop } = useVideo();

  const storagePath =
    file?.__typename === "DocumentAsset" ? file.storagePath : null;

  const { fileUrl } = useGetFile(storagePath);
  const { filesWithUrl } = useGetFiles(attachments);

  const contentType = useMemo(() => {
    const allDownloadableFiles = [
      ...(filesWithUrl ?? []),
      ...(file?.__typename === "DocumentAsset" && fileUrl
        ? [{ ...file, downloadUrl: fileUrl }]
        : []),
    ];

    if (file?.__typename === "DocumentAsset" && fileUrl) {
      if (type === LessonType.video) {
        return (
          <View style={styles.videoWrap}>
            <Video
              ref={video}
              style={[
                styles.video,
                Platform.OS === "web"
                  ? {
                      paddingTop,
                    }
                  : { aspectRatio: videoRatio },
              ]}
              source={{
                uri: fileUrl,
              }}
              useNativeControls
              resizeMode={ResizeMode.CONTAIN}
              videoStyle={{
                height: "100%",
                width: "100%",
              }}
              isLooping
              onPlaybackStatusUpdate={(status) =>
                setStatus(() => status as AVPlaybackStatusSuccess)
              }
              onReadyForDisplay={handleVideoAspectRatio}
            />
            {!status?.isPlaying ? (
              <TouchableOpacity
                style={styles.playIconWrap}
                onPress={() =>
                  status?.isPlaying
                    ? video.current?.pauseAsync()
                    : video.current?.playAsync()
                }
              >
                <LargePlayIcon />
              </TouchableOpacity>
            ) : null}
          </View>
        );
      }
      if (type === LessonType.document) {
        return (
          <View
            style={{
              ...styles.document,
              backgroundColor: colors.surfaceDepressed,
            }}
          >
            <DocumentIcon />
            <Text style={{ ...textStyles.topic, marginVertical: 4 }}>
              Document Lesson
            </Text>

            {allDownloadableFiles?.length ? (
              <DownloadFilesButton
                buttonTitle="Download Attachments"
                filesWithUrl={allDownloadableFiles}
              />
            ) : null}
          </View>
        );
      }
    }

    if (file?.__typename === "EmbeddedVimeo" && file.content) {
      return <HtmlParser htmlString={file.content} />;
    }

    return null;
  }, [
    file,
    fileUrl,
    filesWithUrl,
    handleVideoAspectRatio,
    paddingTop,
    status?.isPlaying,
    type,
    videoRatio,
  ]);

  if (loading) {
    return (
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ActivityIndicator size="large" color="#213470" />
      </View>
    );
  }

  return (
    <View style={styles.wrap}>
      <Text style={styles.title}>{title}</Text>
      {contentType}
      <Text style={{ marginTop: 25 }}>{content}</Text>
    </View>
  );
};

export { LessonContent };
