import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  userTextInfoForm: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 14,
    lineHeight: 20,
    color: "#0D1738",
    paddingTop: 2,
    marginBottom: 8,
  },
  marginBottom: {
    marginBottom: 8,
  },
});
