import React from "react";
import { Image, ImageSourcePropType, View } from "react-native";
import {
  INITIALS_CIRCLE_BACKGROUND_COLORS,
  InitialsCircle,
} from "@components/general/initials-circle";
import { styles } from "./style";

interface Props {
  avatar: ImageSourcePropType;
  firstName?: string;
  lastName?: string;
  commentIndex?: number;
}

const CommentPhoto = ({ avatar, firstName, lastName, commentIndex }: Props) => (
  <View style={styles.wrap}>
    {avatar && <Image source={avatar} style={styles.photo} />}
    {!avatar && (
      <InitialsCircle
        initials={
          firstName && lastName
            ? firstName?.charAt(0) + lastName?.charAt(0)
            : ""
        }
        backgroundColor={
          commentIndex
            ? INITIALS_CIRCLE_BACKGROUND_COLORS[commentIndex % 6]
            : undefined
        }
      />
    )}
  </View>
);

export { CommentPhoto };
