import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  lowerView: {
    padding: 20,
    flexDirection: "row",
    borderBottomColor: "#E1E3E5",
    borderBottomWidth: 1,
  },
  lowerViewInputBorder: {
    borderBottomColor: "#E1E3E5",
    borderBottomWidth: 1,
    paddingHorizontal: 5,
  },
  lowerViewAssign: {
    borderBottomWidth: 0,
  },
  lowerViewIamgeWrap: {
    width: 64,
    height: 68,
    justifyContent: "center",
  },
  lowerViewImage: {
    width: 52,
    height: 52,
    borderRadius: 6,
  },
  lowerViewDeregister: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  lowerViewText: {
    color: "#0D1738",
    fontSize: 14,
    fontWeight: "600",
    lineHeight: 20,
  },

  lowerViewTextDeregister: {
    color: "#6D7175",
    fontSize: 12,
    fontWeight: "400",
    lineHeight: 16,
  },
  lowerViewTextDeregisterAltColor: {
    color: "#0D1738",
  },
});
