import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

interface Props extends SvgProps {
  color?: string;
}

const BellIcon = (props: Props) => (
  <Svg width={17} height={21} fill="none" {...props}>
    <Path
      d="M8.565.822a1 1 0 0 1 1 1v2.032l-.002.022-.002.029a6.002 6.002 0 0 1 5.004 5.917c0 3.094.625 4.312 1.598 6.21l.035.068c.17.331-.07.722-.442.722H1.374A.496.496 0 0 1 .93 16.1l.035-.068c.974-1.898 1.599-3.116 1.599-6.21a6.002 6.002 0 0 1 5.003-5.917l-.002-.04a.823.823 0 0 1-.001-.043v-2a1 1 0 0 1 1-1ZM10.565 18.822a2 2 0 0 1-4 0h4Z"
      fill={props.color || "#5C5F62"}
    />
  </Svg>
);

export { BellIcon };
