import React, { useState } from "react";
import { View } from "react-native";
import { useNavigate } from "react-router-native";
import { Wrapper } from "@components/general/layouts/wrapper/Wrapper";
import { IndexPageHeader } from "@components/general/index-page-header";
import { NotificationSwitch } from "@components/account/notification-switch";
import { ButtonGeneral } from "@components/general/button-general";
import { styles } from "./style";

const Events = () => {
  const navigate = useNavigate();
  const [notificationValues, setNotificationValues] = useState({
    newEvent: false,
    registeredEvent: false,
    updatedEvent: false,
    cancelledEvent: false,
  });

  return (
    <Wrapper>
      <View style={styles.wrap}>
        <View>
          <IndexPageHeader title="Events" onPressBack={() => navigate(-1)} />
          <NotificationSwitch
            title="New events"
            isActive={notificationValues.newEvent}
            onChange={() =>
              setNotificationValues({
                ...notificationValues,
                newEvent: !notificationValues.newEvent,
              })
            }
          />
          <NotificationSwitch
            title="Registered event reminder"
            isActive={notificationValues.registeredEvent}
            onChange={() =>
              setNotificationValues({
                ...notificationValues,
                registeredEvent: !notificationValues.registeredEvent,
              })
            }
          />
          <NotificationSwitch
            title="Updated event"
            isActive={notificationValues.updatedEvent}
            onChange={() =>
              setNotificationValues({
                ...notificationValues,
                updatedEvent: !notificationValues.updatedEvent,
              })
            }
          />
          <NotificationSwitch
            title="Cancelled events"
            isActive={notificationValues.cancelledEvent}
            onChange={() =>
              setNotificationValues({
                ...notificationValues,
                cancelledEvent: !notificationValues.cancelledEvent,
              })
            }
          />
        </View>
        <View style={styles.buttonWrap}>
          <ButtonGeneral label="Save" />
        </View>
      </View>
    </Wrapper>
  );
};

export { Events };
