import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

interface Props extends SvgProps {
  strokeColor?: string;
  fillColor?: string;
}

const ReplyIcon = (props: Props) => (
  <Svg width={16} height={16} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 8a1 1 0 1 0 2 0 1 1 0 0 0-2 0ZM7 8a1 1 0 1 0 2 0 1 1 0 0 0-2 0ZM3 8a1 1 0 1 0 2 0 1 1 0 0 0-2 0Zm5-8C3.589 0 0 3.589 0 8c0 1.504.425 2.908 1.15 4.111L.081 14.606a1 1 0 0 0 1.314 1.313l2.494-1.069A7.939 7.939 0 0 0 8 16c4.411 0 8-3.589 8-8s-3.589-8-8-8Z"
      fill={props.fillColor || "#6D7175"}
    />
  </Svg>
);

export { ReplyIcon };
