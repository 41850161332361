import { View } from "react-native";
import { styles } from "./style";
import { ContentBox } from "@components/general/layouts/content-box";
import { TitleBox } from "@components/account/title-box";
import { ListItem } from "@components/account/list-item";
import { useNavigate } from "react-router-native";

const NotificationSettings = () => {
  const navigate = useNavigate();
  return (
    <View style={styles.wrap}>
      <ContentBox>
        <TitleBox title="Notifications" />
        <ListItem
          title="Settings"
          subtitle="Manage your Notifications"
          onPress={() => navigate("/account/manage-notifications")}
          titleStyle={styles.title}
          subtitleStyle={styles.subtitle}
          containerStyles={{
            paddingBottom: 0,
          }}
        />
        <ListItem
          title="See all notifications"
          onPress={() => navigate(`/account/all-notifications`)}
          titleStyle={[styles.title]}
          containerStyles={{
            paddingBottom: 30,
          }}
        />
      </ContentBox>
    </View>
  );
};

export { NotificationSettings };
