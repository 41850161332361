import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { ISvgProps } from "../../../types";

const ProductReturnMinor = ({ color = "#5C5F62", ...rest }: ISvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    fill="none"
    {...rest}
  >
    <Path
      fill="#5C5F62"
      d="M0 11a6 6 0 006 6h9a1 1 0 000-2H6a4 4 0 110-8h6.526l-2.233 2.293a1 1 0 001.414 1.414l4-4a1 1 0 000-1.414l-4-4a1 1 0 10-1.414 1.414L12.526 5H6a6 6 0 00-6 6z"
    />
  </Svg>
);

export { ProductReturnMinor };
