import { Text, TouchableOpacity, View } from "react-native";
import { useMemo, useState } from "react";

import { ProfileSettings } from "@components/back-office/settings/ProfileSettings";
import { RoleSettings } from "@components/back-office/settings/RoleSettings";
import { PasswordSettings } from "@components/back-office/settings/PasswordSettings";
import { styles } from "./style";

export const SettingsBackOffice = ({
  setIsBackSettings,
  setIsBackPassword,
}: any) => {
  const [btnHeader, setBtnHeader] = useState(0);

  const display = useMemo(() => {
    if (btnHeader === 0) {
      return <ProfileSettings />;
    } else if (btnHeader === 1) {
      return <RoleSettings setIsBackSettings={setIsBackSettings} />;
    } else {
      return <PasswordSettings setIsBackPassword={setIsBackPassword} />;
    }
  }, [btnHeader]);

  return (
    <View style={styles.background}>
      <Text style={styles.mainTitle}>Settings</Text>
      <View style={styles.headerBtnWrap}>
        <TouchableOpacity onPress={() => setBtnHeader(0)}>
          <Text
            style={[
              styles.headerBtn,
              !btnHeader && { borderColor: "#0D1738", borderBottomWidth: 1 },
            ]}
          >
            Profile & Site
          </Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => setBtnHeader(1)}>
          <Text
            style={[
              styles.headerBtn,
              btnHeader === 1 && {
                borderColor: "#0D1738",
                borderBottomWidth: 1,
              },
            ]}
          >
            Role
          </Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => setBtnHeader(2)}>
          <Text
            style={[
              styles.headerBtn,
              btnHeader === 2 && {
                borderColor: "#0D1738",
                borderBottomWidth: 1,
              },
            ]}
          >
            Password
          </Text>
        </TouchableOpacity>
      </View>
      {display}
    </View>
  );
};
