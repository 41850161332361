import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(13, 23, 56, 0.87)",
    alignItems: "center",
    justifyContent: "center",
  },
  top: {
    justifyContent: "flex-start",
  },
  bottom: {
    justifyContent: "flex-end",
  },
  content: {
    width: "80%",
    maxWidth: 620,
    backgroundColor: "#FFFFFF",
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 1.0,
    shadowColor: "rgba(0, 0, 0, 0.05)",
    borderRadius: 8,
  },
});
