import React, {
  createContext,
  ReactNode,
  useContext,
} from "react";
import { useGetUserQuery, User } from "@gql/generated/generated";
import { getDefaultAvatar } from "@utils/misc";
import { ApolloQueryResult, OperationVariables } from "@apollo/client";
import { ImageSourcePropType } from "react-native";
import { useGetAuthTokenAndUserId } from "@hooks/useGetAuthTokenAndUserId";
import { usePostHogSession } from "@hooks/usePostHogSession";
import { useHubSpotSession } from "@hooks/useHubSpotTracking";

interface UserContextProviderProps {
  children?: ReactNode;
}

export interface ContextUser extends User {
  defaultAvatar: ImageSourcePropType;
}

const UserContext = createContext<{
  currentUser?: ContextUser;
  loadingCurrentUser: boolean;
  refetch?: (
    variables?: Partial<OperationVariables>
  ) => Promise<ApolloQueryResult<{ getUser: any }>>;
}>({
  currentUser: undefined,
  loadingCurrentUser: false,
});

const UserContextProvider = ({ children }: UserContextProviderProps) => {
  const { userId } = useGetAuthTokenAndUserId();

  const {
    data: user,
    loading: loadingCurrentUser,
    refetch,
  } = useGetUserQuery({
    skip: !userId,
    fetchPolicy: "cache-and-network"
  });

  const defaultAvatar = getDefaultAvatar(
    user?.getUser?.defaultAvatarIndex || 1
  );

  usePostHogSession(user?.getUser as User);
  useHubSpotSession(user?.getUser as User);

  const values = {
    currentUser: user?.getUser
      ? { ...user?.getUser, defaultAvatar }
      : undefined,
    loadingCurrentUser,
    refetch,
  };

  //@ts-ignore - now getting warnings about legacy dateAssigned property that is potentially missing from some user assignedItems, this can be ignored for now but needs resolving
  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};

const useUserContext = () => useContext(UserContext);

export { UserContextProvider, useUserContext };
