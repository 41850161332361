import { useFeatureFlag } from "@hooks/useFeatureFlag";
import { FeatureFlagKey } from "@utils/featureFlags";
import { Navigate} from "react-router-native";

export function RouteFeatureFlag({
  component,
  featureFlag,
}: {
  component: JSX.Element,
  featureFlag: FeatureFlagKey,
}) {
  const isFeatureFlagEnabled = useFeatureFlag(featureFlag);

  return isFeatureFlagEnabled ? component : <Navigate to="/" />;
}