import { Image } from "@components/general/image-from-storage";
import {
  INITIALS_CIRCLE_BACKGROUND_COLORS,
  InitialsCircle,
} from "@components/general/initials-circle";
import { textStyles } from "@styles/text";
import { useMemo } from "react";
import { View, Text } from "react-native";

export const AvatarWithName = ({
  imagePath,
  imageSrc,
  name,
  i,
}: {
  imagePath?: string | null;
  imageSrc?: string | null;
  name: string;
  i: number;
}) => {
  const image = useMemo(() => {
    if (!imagePath && !imageSrc) {
      const initials = name
        .split(" ")
        .map((word) => word[0]?.toUpperCase())
        .join("")
        .slice(0, 2);

      return (
        <InitialsCircle
          initials={initials}
          backgroundColor={INITIALS_CIRCLE_BACKGROUND_COLORS[i % 6]}
        />
      );
    }
    return (
      <Image
        path={imagePath}
        imageUrl={imageSrc}
        style={{ width: 40, height: 40, borderRadius: 20 }}
      />
    );
  }, [imagePath, imageSrc, name, i]);

  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 10,
      }}
    >
      {image}
      <Text style={textStyles.semiBoldSmall}>{name}</Text>
    </View>
  );
};
