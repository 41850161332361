import StyleSheet from "react-native-media-query";
import { mediaQuery } from "@utils/misc";
import { Platform } from "react-native";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    padding: 20,
    borderBottomWidth: 1,
    borderBottomColor: "#EDEEEF",
  },
  headWrap: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 24,
    [mediaQuery.forMobile]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  selectWrap: {
    width: 160,
  },
  titleWrap: {
    flexDirection: "row",
    alignItems: "center",
    [mediaQuery.forMobile]: {
      flexDirection: "column",
      alignItems: "flex-start",
      marginBottom: 8,
    },
  },
  title: {
    color: "#0D1738",
    fontSize: 26,
    fontFamily: "OpenSans_600SemiBold",
  },
  tag: {
    flexDirection: "row",
    alignItems: "center",
  },
  tagCircle: {
    height: 6,
    width: 6,
    borderRadius: 3,
    marginRight: 11,
  },
  tagText: {
    color: "#6D7175",
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
  },
  tagsWrap: {
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 27,
    ...(Platform.OS === "web" && { gap: 15 }),
    [mediaQuery.forMobile]: {
      flexDirection: "column",
      marginLeft: 0,
      marginTop: 8,
    },
  },
  contentBox: {
    padding: 40,
    [mediaQuery.forMobile]: {
      padding: 20,
    },
  },
  contentInner: {
    width: "100%",
  },
  chart: {
    flex: 1,
  },
});
