import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { ISvgProps } from "types";

const GreenCircleCheckIcon = ({ color = "#23827F", ...props }: ISvgProps) => (
  <Svg width={20} height={20} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 10c0 5.514 4.486 10 10 10s10-4.486 10-10S15.514 0 10 0 0 4.486 0 10Zm15.207-1.793a1 1 0 0 0-1.414-1.414L9 11.586 6.707 9.293a1 1 0 0 0-1.414 1.414l3 3a1 1 0 0 0 1.414 0l5.5-5.5Z"
      fill={color}
    />
  </Svg>
);

export { GreenCircleCheckIcon };
