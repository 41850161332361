import React, { useState } from "react";
import { Modal, Text, View } from "react-native";
import * as Yup from "yup";

import { TextArea } from "@components/general/form/text-area";
import {
  ButtonGeneral,
  ButtonVariant,
} from "@components/general/button-general";
import { Formik, FormikHelpers, FormikValues } from "formik";
import { SelectOption } from "@components/general/form/select-option";
import { InputError } from "@components/general/form/input-error";
import { Input } from "@components/general/form/input";

import { styles } from "./style";
import { ModalContainer } from "@components/org/saved/modals/ModalContainer";
import { GenericModalLayout } from "@components/org/saved/modals/generic-modal-layout/GenericModalLayout";
import { ConfirmationModalContent } from "@components/org/saved/modals/confirmation-modal/ConfirmationModalContent";
import { useAuth } from "@hooks/useAuth";
import {
  RequestType,
  useCreateRequestMutation,
} from "@gql/generated/generated";

interface Props {
  isRequest: boolean;
  onDone?: () => void;
}

const requestOptions = [
  { label: "Event", value: RequestType.EVENT_REQUEST },
  { label: "Content", value: RequestType.CONTENT_REQUEST },
];

const contactSupportOptions = [
  { label: "Events", value: RequestType.EVENT_ISSUE },
  { label: "Training", value: RequestType.TRAINING },
  { label: "Roadmap", value: RequestType.ROADMAP },
  { label: "Content", value: RequestType.CONTENT_ISSUE },
  { label: "My Account", value: RequestType.MY_ACCOUNT },
  { label: "My Organisation", value: RequestType.MY_ORGANISATION },
  { label: "Payment", value: RequestType.PAYMENT },
  { label: "My Membership", value: RequestType.MY_MEMBERSHIP },
  { label: "Other", value: RequestType.OTHER },
];

const NewRequest = ({ isRequest, onDone }: Props) => {
  const { navigate } = useAuth();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [createRequest, { loading }] = useCreateRequestMutation();

  const onSubmit = async (
    values: FormikValues,
    {
      resetForm,
    }: FormikHelpers<{ type: string; subject: string; description: string }>
  ) => {
    try {
      const { type, subject, description } = values;
      const input = { type, subject, description };
      await createRequest({
        variables: {
          input,
        },
      });
      if (onDone) {
        onDone();
      }
      setShowSuccessModal(true);
      resetForm();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <View style={styles.wrap}>
      {isRequest ? (
        <Text style={styles.title}>Create a New Request</Text>
      ) : (
        <Text style={styles.greyText}>
          Contact our Member Support Team using the form below. Our experts will
          get back to you within 48 hrs.
        </Text>
      )}
      <Formik
        initialValues={{
          type: "",
          subject: "",
          description: "",
        }}
        onSubmit={onSubmit}
        validationSchema={Yup.object().shape({
          type: Yup.string().required("This field is required"),
          subject: Yup.string().required("This field is required"),
          description: Yup.string().required("This field is required"),
        })}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          touched,
          errors,
          setFieldValue,
        }) => (
          <>
            <View style={styles.rowInputWrap}>
              <View style={styles.inputItem}>
                <SelectOption
                  placeholder={isRequest ? "Type" : "Category"}
                  value={values.type}
                  options={isRequest ? requestOptions : contactSupportOptions}
                  onChange={(value) => setFieldValue("type", value)}
                />
                {touched.type && errors.type && (
                  <InputError error={errors.type} />
                )}
              </View>
              <View style={styles.inputItem}>
                <Input
                  placeholder="Subject"
                  value={values.subject}
                  onChangeText={handleChange("subject")}
                  error={
                    touched.subject && errors.subject
                      ? errors.subject
                      : undefined
                  }
                />
              </View>
            </View>
            <View style={styles.textAreaWrap}>
              <TextArea
                placeholder={`Please describe your ${
                  isRequest ? "request" : "issue"
                }`}
                value={values.description}
                onChangeText={handleChange("description")}
                error={
                  touched.description && errors.description
                    ? errors.description
                    : undefined
                }
              />
            </View>
            <View style={styles.submitWrap}>
              <ButtonGeneral
                withMarginRight
                label="Cancel"
                variant={ButtonVariant.Secondary}
              />
              <ButtonGeneral
                onPress={handleSubmit}
                label="Submit"
                isLoading={loading}
              />
            </View>
          </>
        )}
      </Formik>

      <Modal visible={showSuccessModal} transparent>
        <ModalContainer>
          <GenericModalLayout
            title="Your request has been successfully submitted!"
            closeModal={() => setShowSuccessModal(false)}
            hasButtonLayout
            buttonName="Go to Feed"
            onActionButtonPress={() => navigate("/")}
            withCancel={false}
            withCustomSecondaryButton
            onActionCustomSecondaryButton={() => navigate("/account/help/")}
            secondaryButtonText="Back to Help"
          >
            <ConfirmationModalContent
              mainText="TRN Team will look into your request and get back to you within 48 working hours."
              secondaryText=""
            />
          </GenericModalLayout>
        </ModalContainer>
      </Modal>
    </View>
  );
};

export { NewRequest };
