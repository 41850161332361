import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const InfoIcon = (props: SvgProps) => (
  <Svg width={17} height={16} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.445 8A8 8 0 1 0 .444 8a8 8 0 0 0 16 0Zm-9 3a1 1 0 1 0 2 0V9a1 1 0 1 0-2 0v2Zm0-6a1 1 0 1 0 2 0 1 1 0 0 0-2 0Z"
      fill={props.fill ?? "#BABEC3"}
    />
  </Svg>
);

export { InfoIcon };
