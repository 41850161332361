import React from "react";
import { IFieldTypes } from "@utils/models";
import {
  Checkbox,
  Props as CheckboxProps,
} from "@components/general/form/checkbox";
import { Input, Props as InputProps } from "@components/general/form/input";
import {
  PhoneInput,
  Props as PhoneInputProps,
} from "@components/general/form/phone-input";
import {
  Props as SelectOptionProps,
  SelectOption,
} from "@components/general/form/select-option";
import {
  Props as TextareaProps,
  TextArea,
} from "@components/general/form/text-area";
import { FormikList } from "@components/back-office/editor/formik-list";
import {
  Props as RadioButtonProps,
  RadioButton,
} from "@components/general/form/radio-button";
import {
  DatePicker,
  Props as DatePickerProps,
} from "@components/general/form/date-picker";

interface Props {
  field: IFieldTypes;
  setFieldValue: (value: string | File[]) => void;
  fieldProps:
    | InputProps
    | TextareaProps
    | SelectOptionProps
    | CheckboxProps
    | RadioButtonProps
    | DatePickerProps;
  questionIndex?: number;
}

const FormField = ({
  field,
  fieldProps,
  setFieldValue,
  questionIndex,
}: Props) => {
  const getField = () => {
    switch (field) {
      case "text":
        return (
          <Input {...(fieldProps as InputProps)} onChangeText={setFieldValue} />
        );
      case "textarea":
        return (
          <TextArea
            {...(fieldProps as TextareaProps)}
            onChangeText={setFieldValue}
          />
        );
      case "number":
        return (
          <Input
            {...(fieldProps as InputProps)}
            keyboardType="number-pad"
            onChangeText={setFieldValue}
          />
        );
      case "phone_number":
        return (
          <PhoneInput
            {...(fieldProps as PhoneInputProps)}
            onChangeText={(value) => setFieldValue(value)}
          />
        );
      case "email":
        return (
          <Input
            {...(fieldProps as InputProps)}
            keyboardType="email-address"
            onChangeText={setFieldValue}
          />
        );
      case "select":
        return (
          <SelectOption
            {...(fieldProps as SelectOptionProps)}
            onChange={(value) => setFieldValue(value as string)}
            setFirstOptionAsDefault
          />
        );
      case "checkbox":
        return <Checkbox {...(fieldProps as CheckboxProps)} />;
      case "multiple_text": {
        const props = fieldProps as InputProps;
        const fieldName = `answers[${questionIndex}].answer`;

        return (
          <FormikList
            fieldName={fieldName}
            title={props.label || ""}
            placeholder={props.placeholder || ""}
            initialNumberOfItems={5}
          />
        );
      }
      case "date": {
        const props = fieldProps as DatePickerProps;
        return (
          <DatePicker
            {...props}
            value={props.value}
            onChange={(value) => setFieldValue(value ?? "")}
          />
        );
      }
      case "multiple_checkbox": {
        const props = fieldProps as CheckboxProps;
        return (
          <Checkbox
            {...props}
            onChange={(value) => setFieldValue(value || "")}
          />
        );
      }
      case "multiple_radio": {
        const props = fieldProps as RadioButtonProps;
        return (
          <RadioButton
            {...props}
            onChange={(value) => setFieldValue(value || "")}
          />
        );
      }
      case "multiple_select": {
        return (
          <SelectOption
            {...(fieldProps as SelectOptionProps)}
            isMultiple
            onChange={(value) => setFieldValue(value)}
          />
        );
      }
      default:
        return null;
    }
  };

  return getField();
};

export { FormField };
