import React from "react";
import { Text, View } from "react-native";
import { Formik } from "formik";
import * as Yup from "yup";
import { useAuth } from "@hooks/useAuth";
import { ButtonGeneral } from "@components/general/button-general";
import { ListModal } from "@components/general/modals/list-modal";
import { Input } from "@components/general/form/input";
import { styles } from "./style";
import { useDeleteUserMutation } from "@gql/generated/generated";

interface Props {
  isVisible: boolean;
  onClose: () => void;
  onDone: () => void;
}

const DeleteAccountModal = ({ isVisible, onClose, onDone }: Props) => {
  const { user } = useAuth();
  const [deleteUser, { loading }] = useDeleteUserMutation();

  const onSubmit = async () => {
    await deleteUser({
      variables: {
        input: {
          reason: "",
          otherReason: "",
        },
      },
    });
    onDone();
  };

  return (
    <ListModal isVisible={isVisible} onClose={onClose}>
      <Text style={styles.title}>
        Are you sure you want to delete your account?
      </Text>
      <Text style={styles.subtitle}>
        Please type your email address below and press submit to delete your
        account.
      </Text>
      <Formik
        initialValues={{ email: "" }}
        onSubmit={onSubmit}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email()
            .oneOf([user?.accountInfo?.email, null], "Emails do not match")
            .required("This field is required"),
        })}
      >
        {({ values, handleChange, handleSubmit, touched, errors }) => (
          <View>
            <View style={styles.inputWrap}>
              <Input
                placeholder="Email"
                keyboardType="email-address"
                value={values.email}
                onChangeText={handleChange("email")}
                error={touched.email && errors.email ? errors.email : undefined}
              />
            </View>
            <ButtonGeneral
              label="Submit"
              onPress={handleSubmit}
              isLoading={loading}
            />
          </View>
        )}
      </Formik>
    </ListModal>
  );
};

export { DeleteAccountModal };
