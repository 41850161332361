import React, { useState } from "react";
import { View } from "react-native";
import { useNavigate } from "react-router-native";
import { Wrapper } from "@components/general/layouts/wrapper/Wrapper";
import { IndexPageHeader } from "@components/general/index-page-header";
import { NotificationSwitch } from "@components/account/notification-switch";
import { ButtonGeneral } from "@components/general/button-general";
import { styles } from "./style";

const Feed = () => {
  const navigate = useNavigate();
  const [notificationValues, setNotificationValues] = useState({
    news: false,
    announcement: false,
    polls: false,
    pollResult: true,
  });

  return (
    <Wrapper>
      <View style={styles.wrap}>
        <View>
          <IndexPageHeader title="Feed" onPressBack={() => navigate(-1)} />
          <NotificationSwitch
            title="News"
            isActive={notificationValues.news}
            onChange={() =>
              setNotificationValues({
                ...notificationValues,
                news: !notificationValues.news,
              })
            }
          />
          <NotificationSwitch
            title="Announcement"
            isActive={notificationValues.announcement}
            onChange={() =>
              setNotificationValues({
                ...notificationValues,
                announcement: !notificationValues.announcement,
              })
            }
          />
          <NotificationSwitch
            title="Polls"
            isActive={notificationValues.polls}
            onChange={() =>
              setNotificationValues({
                ...notificationValues,
                polls: !notificationValues.polls,
              })
            }
          />
          <NotificationSwitch
            title="Poll result"
            isActive={notificationValues.pollResult}
            onChange={() =>
              setNotificationValues({
                ...notificationValues,
                pollResult: !notificationValues.pollResult,
              })
            }
          />
        </View>
        <View style={styles.buttonWrap}>
          <ButtonGeneral label="Save" />
        </View>
      </View>
    </Wrapper>
  );
};

export { Feed };
