import StyleSheet from "react-native-media-query";
import { Platform } from "react-native";
import { mediaQuery } from "@utils/misc";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    ...(Platform.OS === "web" && {
      paddingTop: 16,
      paddingBottom: 16,
    }),
  },
  inner: {
    width: "100%",
    paddingTop: 40,
    paddingBottom: 40,
    paddingLeft: 80,
    paddingRight: 80,
    [mediaQuery.forMobile]: {
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
      flexDirection: "column",
    },
    [mediaQuery.forTabletOnly]: {
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
});
