import React from "react";
import { Text, View, Image } from "react-native";
import { Pill, PillVariant } from "@components/general/pill";
import { useNavigate } from "react-router-native";
import { getUniqueCategories } from "@utils/misc";
import { ids, styles } from "./styles";
import { DocumentAsset } from "@gql/generated/generated";
import { useGetFile } from "@hooks/useGetFileUrl";
import { TouchableOpacityLink } from "@components/general/touchable-opacity-link";

interface MarketplaceItemProps {
  id: string;
  title?: string | null;
  image?: DocumentAsset | null;
  categories?: string[] | null;
  partnerType?: string | null;
  summary?: string | null;
  content?: string | null;
  needSpecialOffer: boolean;
  specialOfferDetails?: string | null;
  specialOfferLink?: string;
  links: {
    facebook?: string | null;
    instagram?: string | null;
    linkedin?: string | null;
    twitter?: string | null;
    website?: string | null;
  };
}

const MarketplaceItem = ({
  id,
  title,
  image,
  categories,
  partnerType,
  content,
  summary,
  needSpecialOffer,
  specialOfferDetails,
  specialOfferLink,
  links,
}: MarketplaceItemProps) => {
  const uniqueCategories = getUniqueCategories(categories);
  const navigate = useNavigate();

  const { fileUrl } = useGetFile(image?.storagePath);
  return (
    <TouchableOpacityLink
      style={styles.container}
      href={`/marketplace/${id}`}
      onPress={() =>
        navigate(`/marketplace/${id}`, {
          state: {
            id,
            title,
            image,
            categories,
            partnerType,
            summary,
            needSpecialOffer,
            specialOfferDetails,
            specialOfferLink,
            content,
            links,
          },
        })
      }
    >
      <View style={styles.imageContainer}>
        <Image
          source={{ uri: fileUrl || "" }}
          style={styles.image}
          dataSet={{ media: ids.image }}
          borderRadius={4}
        />
      </View>
      <View
        style={styles.contentContainer}
        dataSet={{ media: ids.contentContainer }}
      >
        <View
          style={{
            marginBottom: 8,
          }}
        >
          <Text style={styles.title}>{title}</Text>
          <Text style={styles.categories}>{uniqueCategories.join(" & ")}</Text>
        </View>
        <View style={styles.summaryContainer}>
          <Text numberOfLines={2} style={styles.summary}>
            {summary}
          </Text>
        </View>
        <View style={styles.partnerTypeContainer}>
          {/* // TODO Temp disabling partner types see https://trello.com/c/fSds74pG/311-hide-the-gold-silver-bronze-partnership-tags  */}
          {/* {partnerType && (
            <Pill
              key={partnerType}
              variant={PillVariant.DarkBlue}
              text={partnerType}
            />
          )} */}
          {needSpecialOffer ? (
            <Pill variant={PillVariant.Red} text="Special Offer" />
          ) : null}
        </View>
      </View>
    </TouchableOpacityLink>
  );
};

export { MarketplaceItem };
