// IMPORTANT: This was an unmaintained (and very old) lib which had lots of errors in it.
// It should be removed, but until then, we take on the maintenance.
// https://www.npmjs.com/package/react-native-custom-checkbox

import React, { Component } from "react";

import {
  Animated,
  Image,
  ImageSourcePropType,
  StyleProp,
  Text,
  TextStyle,
  TouchableOpacity,
  ViewStyle,
} from "react-native";

import check from "../../../assets/img/check.png";

interface State {
  value?: boolean;
}

interface Props {
  /** Required. Current state of the checkbox */
  value: boolean;
  /** Required. Callback for click on the checkbox */
  onChangeValue: Function;
  /** Set true to make whole checkbox component container clickable for changing it's value */
  isContainerClickable?: boolean;
  /** Set true for making animations smoothier */
  useNativeDriver?: boolean;
  /** Set background color to the box when it's marked */
  colorActive?: string;
  /** Set background color to the box when it's unmarked */
  colorInactive?: string;
  /** Add style to the label */
  textStyle?: StyleProp<TextStyle>;
  /** Add style to the box */
  boxStyle?: StyleProp<ViewStyle>;
  /** Add style to the container */
  containerStyle?: StyleProp<ViewStyle>;
  /** Label value */
  label?: string;
  /** Pass custom image for indication as path */
  checkImage?: ImageSourcePropType;
}

const AnimatedTouchable = Animated.createAnimatedComponent(TouchableOpacity);

export class CheckBox extends Component<Props> {
  state = {
    value: false,
    animate: false,
    animatedValue: new Animated.Value(0),
  };

  hexToRgb = (hex: string) => {
    const [r, g, b] = hex
      .replace(
        /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        (m, r, g, b) => "#" + r + r + g + g + b + b
      )
      .substring(1)
      .match(/.{2}/g)
      ?.map((x: string) => parseInt(x, 16)) ?? [0, 0, 0];
    return `rgb(${r},${g},${b})`;
  };

  static getDerivedStateFromProps(props: Props, state: State) {
    if (state.value === props.value) {
      return null;
    } else {
      if (props.value) {
        return {
          value: true,
          animate: true,
        };
      } else {
        return {
          value: false,
          animate: true,
        };
      }
    }
  }

  handleContainerClick = () => {
    if (
      this.props.isContainerClickable !== undefined &&
      this.props.isContainerClickable
    ) {
      this.props.onChangeValue();
    }
  };

  render() {
    const colorActive =
      this.props.colorActive !== undefined ? this.props.colorActive : "#239292";

    const colorInactive =
      this.props.colorInactive !== undefined
        ? this.props.colorInactive
        : "#fff";

    const defaultTextStyle: StyleProp<TextStyle> = { color: "#000" };

    const defaultBoxStyle: StyleProp<ViewStyle> = {
      justifyContent: "center",
      alignItems: "center",
      marginRight: 10,
      borderRadius: 3,
      width: 20,
      height: 20,
      backgroundColor: "#fff",
      borderWidth: 1,
      borderColor: "#ccc",
    };

    const defaultContainerStyle: StyleProp<ViewStyle> = {
      flexDirection: "row",
    };

    const interpolateCheck = this.state.animatedValue.interpolate({
      inputRange: [0, 150],
      outputRange: [
        this.hexToRgb(colorInactive).toString(),
        this.hexToRgb(colorActive).toString(),
      ],
    });

    const interpolateUncheck = this.state.animatedValue.interpolate({
      inputRange: [0, 150],
      outputRange: [
        this.hexToRgb(colorActive).toString(),
        this.hexToRgb(colorInactive).toString(),
      ],
    });

    const animatedStyle = {
      backgroundColor: !this.props.value
        ? interpolateCheck
        : interpolateUncheck,
    };

    return (
      <TouchableOpacity
        activeOpacity={
          this.props.isContainerClickable !== undefined ? 1.0 : 0.6
        }
        onPress={() => this.handleContainerClick()}
        style={[defaultContainerStyle, this.props.containerStyle]}
      >
        <AnimatedTouchable
          onPress={() => this.props.onChangeValue()}
          style={[
            defaultBoxStyle,
            this.state.animate && animatedStyle,
            this.props.boxStyle,
          ]}
        >
          <Image
            style={{ backgroundColor: "#00000000" }}
            source={
              this.props.checkImage !== undefined
                ? this.props.checkImage
                : check
            }
          />
        </AnimatedTouchable>
        <Text style={[defaultTextStyle, this.props.textStyle]}>
          {this.props.label}
        </Text>
      </TouchableOpacity>
    );
  }
}
