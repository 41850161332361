import { useState } from "react";
import { FlatList, View } from "react-native";
import { useNavigate } from "react-router-native";
import { Wrapper } from "@components/general/layouts/wrapper/Wrapper";
import { IndexPageHeader } from "@components/general/index-page-header";
import {
  FilterModal,
  IFilterData,
} from "@components/filter-mobile/filter-modal";
import { FilterOptions } from "@utils/models";
import { MarketplaceItem } from "@components/marketplace/marketplace-item/MarketplaceItem";
import {
  ButtonGeneral,
  ButtonVariant,
} from "@components/general/button-general";
import { styles } from "./style";
import { Partner, useGetAllPartnersQuery } from "@gql/generated/generated";
import { PartnerTypes } from "@utils/partners";

const filterOptions = [
  FilterOptions.category,
  FilterOptions.date,
  FilterOptions.memberType,
];

const MarketplaceNative = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  const { data, loading } = useGetAllPartnersQuery({
    variables: {
      page,
      limit: 10,
      filters: {
        partnerType: Object.keys(PartnerTypes),
      },
    },
  });
  const partnerData = data?.getAllPartners;

  const { partners, hasNextPage } = partnerData || {};

  const handleFilter = (_filterData: IFilterData) => {
    // TODO: Handle Filter
  };

  const renderItem = ({ item }: { item: Partner }) => (
    <MarketplaceItem
      id={item.id}
      title={item.title}
      image={item.logo ?? null}
      categories={item.categories}
      summary={item.summary}
      partnerType={item.partnerType}
      needSpecialOffer={item.specialOffer?.details !== ""}
      content={item.content}
      specialOfferDetails={item.specialOffer?.details ?? ""}
      specialOfferLink={item.specialOffer?.link ?? ""}
      links={{
        facebook: item.links?.facebook,
        instagram: item.links?.instagram,
        linkedin: item.links?.linkedin,
        twitter: item.links?.twitter,
        website: item.links?.website,
      }}
    />
  );

  return (
    <Wrapper>
      <IndexPageHeader title="Marketplace" onPressBack={() => navigate(-1)} />
      <View style={styles.wrapper}>
        <FilterModal filterOptions={filterOptions} onSubmit={handleFilter} />
        <FlatList
          data={partners}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={styles.flatListStyle}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
          ListFooterComponent={
            loading ? null : (
              <View style={styles.paginationWrap}>
                <View style={styles.paginationButtonWrap}>
                  <ButtonGeneral
                    label="Previous page"
                    variant={ButtonVariant.Secondary}
                    onPress={() => setPage(page - 1)}
                  />
                </View>
                <View style={styles.paginationButtonWrap}>
                  <ButtonGeneral
                    label="Next page"
                    variant={ButtonVariant.Secondary}
                    onPress={() => setPage(page + 1)}
                    disabled={!!hasNextPage}
                  />
                </View>
              </View>
            )
          }
        />
      </View>
    </Wrapper>
  );
};

export { MarketplaceNative };
