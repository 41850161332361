import StyleSheet from "react-native-media-query";
import { Platform, ViewStyle } from "react-native";

export const { ids, styles } = StyleSheet.create({
  pill: {
    borderRadius: 200,
    ...(Platform.OS === "web" ? { height: "min-content" } : {}),
    paddingVertical: 4,
    paddingHorizontal: 8,
  },
  lightGrey: { backgroundColor: "#EBEDF0" },
  greyBlue: { backgroundColor: "#828EB0" },
  darkBlue: { backgroundColor: "#0D1738" },
  container: {
    width: "100%",
    alignItems: "center",
    borderWidth: 1,
    borderRadius: 15,
    borderColor: "#D3DAE2",
  },
  hoverShadow: {
    ...(Platform.OS === "web"
      ? ({
          filter: "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.15))",
        } as ViewStyle)
      : {}),
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
    flexWrap: "wrap",
  },
  locationBox: {
    marginBottom: 10,
    borderRadius: 5,
    height: 28,
  },
  map: {
    width: 37,
    height: "100%",
    borderBottomLeftRadius: 5,
    borderTopLeftRadius: 5,
  },
  locationText: {
    paddingVertical: 2,
    paddingLeft: 4,
    color: "#6D7175",
    fontFamily: "OpenSans_400Regular",
    fontSize: 12,
  },
  titleBox: {
    justifyContent: "space-between",
    marginBottom: 6,
  },
  infoSectionTop: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  lightText: {
    color: "#FFFFFF",
  },
  image: {
    height: 158,
    padding: 18,
    justifyContent: "space-between",
  },
  roundedImage: {
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
  },
  mapImage: {
    borderBottomLeftRadius: 5,
    borderTopLeftRadius: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  infoCard: {
    backgroundColor: "#FFFFFF",
    padding: 16,
    position: "relative",
    flex: 1,
  },
  withMarginRight: {
    marginRight: 5,
  },
  title: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 16,
    lineHeight: 24,
    color: "#0D1738",
    marginRight: 5,
  },
  contentDate: {
    fontFamily: "OpenSans_400Regular",
    color: "#6E757C",
    fontSize: 12,
  },
  description: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 12,
    lineHeight: 17,
    color: "#6E757C",
    marginBottom: 15,
  },
  date: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 14,
    lineHeight: 20,
    color: "#2C6ECB",
    marginBottom: 4,
  },
  reviewsText: {
    color: "#6D7175",
    fontSize: 12,
    fontFamily: "OpenSans_400Regular",
    marginLeft: 4,
  },
  by: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 12,
    color: "#6E757C",
  },
  organizer: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 12,
    fontWeight: "500",
  },
  shareButton: {
    backgroundColor: "#0D1738",
    padding: 7,
    borderRadius: 500,
  },
  icon: {
    width: 12,
    height: 13,
  },
  tickIcon: {
    width: 12,
    height: 12,
    marginRight: 3,
  },
  dateAndLocationText: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 12,
    lineHeight: 16,
    color: "#0D1738",
    marginBottom: 4,
  },
  subduedText: {
    fontFamily: "OpenSans_400Regular",
    color: "#6D7175",
    fontSize: 12,
    lineHeight: 16,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  touchableArea: {
    width: "100%",
    borderRadius: 15,
    justifyContent: "space-between",
    ...(Platform.OS === "web" ? { height: "100%" } : {}),
  },
  bottomContainer: {
    borderTopWidth: 0.5,
    borderTopColor: "rgba(0, 0, 0, 0.35)",
    width: "100%",
    padding: 16,
    backgroundColor: "#FFFFFF",
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
  },
  statsText: {
    fontFamily: "OpenSans_400Regular",
    color: "#6D7175",
    fontSize: 12,
    lineHeight: 16,
  },
  topRow: {
    justifyContent: "space-between",
    width: "100%",
    height: 24,
    marginBottom: 4,
    alignItems: "center",
  },
  innerRow: {
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
  },
  centeredRow: {
    justifyContent: "space-between",
    alignItems: "center",
  },
  speakerText: {
    ...(Platform.OS === "web" ? { whiteSpace: "nowrap" } : {}),
    display: "flex",
    alignItems: "center",
  },
  viewContainer: {
    alignSelf: "center",
    backgroundColor: "white",
    paddingVertical: 4,
    paddingHorizontal: 16,
    borderRadius: 36,
    position: "absolute",
    top: 65,
  },
});
