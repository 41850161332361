import { View, Text } from "react-native";
import { styles } from "./style";

export enum TagStatus {
  Active,
  Inactive,
  Default,
  Critical,
}

const statusVariants = {
  [TagStatus.Active]: {
    backgroundColor: "#AEE9D1",
    color: null,
  },
  [TagStatus.Inactive]: {
    backgroundColor: "#FED3D1",
    color: null,
  },
  [TagStatus.Default]: {
    backgroundColor: "#E4E5E7",
    color: null,
  },
  [TagStatus.Critical]: {
    backgroundColor: "#D72C0D",
    color: "white",
  },
};

export const StatusTag = ({
  status,
  children,
}: {
  status: TagStatus;
  children: React.ReactNode;
}) => {
  return (
    <View
      style={{
        ...styles.tag,
        backgroundColor: statusVariants[status].backgroundColor,
      }}
    >
      <Text style={{ color: statusVariants[status]?.color ?? "default" }}>
        {children}
      </Text>
    </View>
  );
};
