import {
  Platform,
  Switch,
  Text,
  TouchableOpacity,
  View,
  ViewProps,
} from "react-native";
import React from "react";
import { FormikSaveBar } from "@components/back-office/editor/formik-save-bar";
import { Formik, FormikValues } from "formik";
import { FormikPublishTools } from "@components/back-office/editor/formik-publish-tools/FormikPublishTools";
import { FormikRichText } from "@components/back-office/editor/formik-rich-text";
import { FormikTextArea } from "@components/back-office/editor/formik-text-area/FormikTextArea";
import { FormikInput } from "@components/back-office/editor/formik-input";
import { styles } from "../../trainings-back-office/trainings-editor/style";
import { FormikTextField } from "@components/back-office/editor/formik-textfield/FormikTextField";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  TwitterIcon,
} from "@components/general/icons";
import { WebIcon } from "@components/general/icons/social-icons";
import { useNavigate, useParams } from "react-router-native";
import { customFirestoreId } from "@utils/misc";
import * as Yup from "yup";
import { Icon } from "@shopify/polaris";
import { ChevronLeftMinor } from "@shopify/polaris-icons";
import {
  CollectionName,
  PartnerInput,
  Status,
  useGetPartnerQuery,
  useUpsertPartnerMutation,
} from "@gql/generated/generated";
import { FileUploader } from "@components/back-office/editor/uploader";
import { useToast } from "@context/ToastContext";
import { PartnerTypes } from "@utils/partners";

interface FormSectionProps extends ViewProps {
  children: React.ReactNode;
}

const FormSection = ({ children, style, ...rest }: FormSectionProps) => (
  <View
    style={[
      {
        backgroundColor: "#FFFFFF",
        ...(Platform.OS === "web" && { height: "max-content" }),
        borderRadius: 8,
        paddingHorizontal: 20,
        paddingVertical: 30,
      },
      style,
    ]}
    {...rest}
  >
    {children}
  </View>
);

const PartnersEditor = () => {
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };
  const { data } = useGetPartnerQuery({
    variables: { getPartnerId: id },
    skip: !id,
    fetchPolicy: "cache-and-network",
  });

  const partner = data?.getPartner;

  const existingId = data?.getPartner.id;
  const partnerId = existingId || customFirestoreId();

  const [upsertPartner] = useUpsertPartnerMutation();

  const { addToast } = useToast();

  const onSubmit = async (values: FormikValues) => {
    // create a search-indexable content without html tags
    const searchIndexableContent = (values.content as string)
    ?.replace(/(<([^>]+)>)/ig, '' )
    .replace(/(\r\n|\r|\n)/g, ' ')
    .replace(/&nbsp;/g, '')
    .split(' ').map(s => s.trim()).filter(s => s.length > 0).join(' ');
    try {
      const {
        id,
        links,
        specialOffer,
        partnerType,
        categories,
        content,
        title,
        summary,
        status,
      } = values;

      const { website, facebook, instagram, twitter, linkedin } = links;

      const { details, link } = specialOffer;

      const input: PartnerInput = {
        id,
        partnerType,
        categories,
        content,
        searchIndexableContent,
        title,
        summary,
        status,
        facebook,
        instagram,
        twitter,
        linkedin,
        website,
        specialOfferDetails: details,
        specialOfferLink: link,
      };

      await upsertPartner({
        variables: {
          input,
        },
      });
      if (existingId) {
        addToast("success", "Partner updated");
      } else {
        addToast("success", "Partner created");
      }
      navigate("/marketplace");
    } catch (error: any) {
      addToast(
        "error",
        `There was an error with your request. Please ensure that you have completed all fields. ${
          error.message ? error.message : ""
        }`
      );
    }
  };

  return (
    <View
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Formik
        enableReinitialize={!!existingId}
        initialValues={{
          id: partnerId,
          title: partner?.title ?? "",
          content: partner?.content ?? "",
          summary: partner?.summary ?? "",
          status: partner?.status ?? Status.DRAFT,
          logo: partner?.logo ?? null,
          links: {
            website: partner?.links?.website ?? "",
            facebook: partner?.links?.facebook ?? "",
            twitter: partner?.links?.twitter ?? "",
            linkedin: partner?.links?.linkedin ?? "",
            instagram: partner?.links?.instagram ?? "",
          },
          specialOffer: {
            details: partner?.specialOffer?.details ?? "",
            link: partner?.specialOffer?.link ?? "",
            hasOffer: !!partner?.specialOffer?.hasOffer,
          },
          categories: partner?.categories ?? [],
          partnerType: partner?.partnerType ?? PartnerTypes.Bronze,
        }}
        onSubmit={onSubmit}
        validationSchema={Yup.object().shape({
          title: Yup.string().required("Title is required"),
          content: Yup.string().required("Content is required"),
          summary: Yup.string().required("Summary is required"),
          status: Yup.string().required("Status is required"),
          logo: Yup.object().when("status", {
            is: "Publish",
            then: Yup.object()
              .required("This field is required")
              .typeError("A logo is required to publish this partner"),
            otherwise: Yup.object().notRequired().nullable(),
          }),
          links: Yup.object().shape({
            website: Yup.string().matches(
              /^(https:\/\/|http:\/\/)/,
              "Web address must start with http:// or https://"
            ),
            facebook: Yup.string().matches(
              /^(https:\/\/)/,
              "Facebook link must start with https://"
            ),
            twitter: Yup.string().matches(
              /^(https:\/\/)/,
              "Twitter link must start with https://"
            ),
            linkedin: Yup.string().matches(
              /^(https:\/\/)/,
              "LinkedIn link must start with https://"
            ),
            instagram: Yup.string().matches(
              /^(https:\/\/)/,
              "Instagram link must start with https://"
            ),
          }),
          specialOffer: Yup.object().shape({
            details: Yup.string(),
            link: Yup.string().matches(
              /^(https:\/\/|http:\/\/)/,
              "Special offer link must start with http:// or https://"
            ),
          }),
          categories: Yup.array()
            .min(1, "You must add at least one category")
            .required("You must add at least one category."),
          partnerType: Yup.string().required("Partner type required"),
        })}
      >
        {({ values, setFieldValue }) => {
          return (
            <>
              <FormikSaveBar />
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 12,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    navigate(-1);
                  }}
                  style={{
                    borderWidth: 1,
                    borderColor: "#BABFC3",
                    borderRadius: 4,
                    width: 36,
                    height: 36,
                    marginRight: 8,
                  }}
                >
                  <Icon source={ChevronLeftMinor} />
                </TouchableOpacity>
                <Text style={styles.title}>
                  {id ? "Update" : "Add New"} Partner
                </Text>
              </View>
              <View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <View
                    style={{
                      flex: 1,
                    }}
                  >
                    <FormSection style={{ marginBottom: 20 }}>
                      <FormikInput
                        fieldName="title"
                        label="Title"
                        placeholder="Enter partner name"
                      />
                      <FormikRichText
                        fieldName="content"
                        title="Content"
                        placeholder="Add partner details here"
                      />
                      <View style={{ marginBottom: 16 }}>
                        <FormikTextArea
                          fieldName="summary"
                          label="Short Description"
                          maxWordCount={300}
                          placeholder="Add a short description here"
                        />
                      </View>
                    </FormSection>
                    <FormSection style={{ marginBottom: 20 }}>
                      <View>
                        <Text
                          style={[
                            styles.title,
                            {
                              fontSize: 16,
                              lineHeight: 20,
                              marginBottom: 20,
                              color: "#202223",
                            },
                          ]}
                        >
                          Partner Details
                        </Text>
                      </View>
                      <FileUploader
                        initialAsset={partner?.logo}
                        assetInstruction={{
                          instructionType: "one-to-one",
                          collectionId: CollectionName.PARTNERS,
                          documentId: partnerId,
                          key: "logo",
                        }}
                        validFileTypes={["image/jpeg", "image/png"]}
                        fileName={{
                          title: "Logo",
                          placeholder: "Add file name",
                        }}
                        formikFieldname="logo"
                      />
                      <View style={{ marginTop: 16 }}>
                        <Text style={styles.label}>Links</Text>
                        <View
                          style={{
                            flexDirection: "row",
                            marginBottom: 16,
                          }}
                        >
                          <FormikTextField
                            formikFieldBase="links"
                            Icon={FacebookIcon}
                            formikNestedField="facebook"
                            placeholder="Add Facebook link"
                            style={{
                              marginRight: 16,
                            }}
                          />
                          <FormikTextField
                            formikFieldBase="links"
                            Icon={InstagramIcon}
                            formikNestedField="instagram"
                            placeholder="Add Instagram link"
                          />
                        </View>
                        <View
                          style={{
                            flexDirection: "row",
                            marginBottom: 16,
                          }}
                        >
                          <FormikTextField
                            formikFieldBase="links"
                            Icon={TwitterIcon}
                            formikNestedField="twitter"
                            placeholder="Add Twitter link"
                            style={{
                              marginRight: 16,
                            }}
                          />
                          <FormikTextField
                            formikFieldBase="links"
                            Icon={LinkedInIcon}
                            formikNestedField="linkedin"
                            placeholder="Add LinkedIn link"
                          />
                        </View>
                        <FormikTextField
                          formikFieldBase="links"
                          Icon={WebIcon}
                          formikNestedField="website"
                          placeholder="Add Website link"
                        />
                      </View>
                    </FormSection>
                    <FormSection
                      style={[
                        { marginBottom: 20 },
                        values.specialOffer?.hasOffer
                          ? null
                          : { paddingBottom: 0 },
                      ]}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text
                          style={[
                            {
                              fontSize: 16,
                              lineHeight: 20,
                              marginBottom: 20,
                              color: "#202223",
                            },
                          ]}
                        >
                          Special Offer
                        </Text>
                        <Switch
                          value={values.specialOffer.hasOffer}
                          onValueChange={() =>
                            setFieldValue("specialOffer", {
                              hasOffer: !values.specialOffer.hasOffer,
                              details: "",
                              link: "",
                            })
                          }
                          trackColor={{
                            false: "#8B9197",
                            true: "#2C6ECB",
                          }}
                          thumbColor="#FFFFFF"
                        />
                      </View>
                      {values.specialOffer.hasOffer && (
                        <>
                          <View
                            style={{
                              marginBottom: 16,
                            }}
                          >
                            <FormikTextArea
                              fieldName="specialOffer[details]"
                              label="Offer Details"
                              maxWordCount={300}
                              placeholder="Add offer details here"
                            />
                          </View>
                          <FormikTextField
                            formikFieldBase="specialOffer"
                            Icon={WebIcon}
                            formikNestedField="link"
                            placeholder="Add Website link"
                          />
                        </>
                      )}
                    </FormSection>
                  </View>
                  <View style={{ marginLeft: 20 }}>
                    <FormikPublishTools
                      withJobRoles={false}
                      withUserType={false}
                      withPartnerType
                    />
                  </View>
                </View>
              </View>
            </>
          );
        }}
      </Formik>
    </View>
  );
};

export { PartnersEditor };
