import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: "100%",
    maxWidth: 586,
  },
  pollWrap: {
    padding: 20,
    paddingBottom: 0,
  },
});
