import StyleSheet from "react-native-media-query";
import { mediaQuery } from "@utils/misc";

export const { ids, styles } = StyleSheet.create({
  topWrap: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 20,
    paddingBottom: 40,
    borderBottomWidth: 1,
    borderBottomColor: "#EDEEEF",
    [mediaQuery.forMobile]: {
      flexDirection: "column",
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 20,
    },
  },
  activityWrap: {
    width: "60%",
    [mediaQuery.forMobile]: {
      width: "100%",
    },
  },
  engagementWrap: {
    width: "38%",
    [mediaQuery.forMobile]: {
      width: "100%",
      marginTop: 20,
    },
  },
});
