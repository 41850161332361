import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { ISvgProps } from "../../../../types";

const BarGraphIcon = ({ color = "#fff", ...props }: ISvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={15}
    fill="none"
    {...props}
  >
    <Path
      fill={color}
      d="M4.571 7.432a1.143 1.143 0 0 1 2.286 0v5.714a1.143 1.143 0 1 1-2.286 0V7.432zm9.143-5.714a1.143 1.143 0 0 1 2.286 0v11.428a1.143 1.143 0 0 1-2.286 0V1.718zM0 4.004a1.143 1.143 0 0 1 2.286 0v9.142a1.143 1.143 0 1 1-2.286 0V4.004zm9.143 1.142a1.143 1.143 0 1 1 2.286 0v8a1.143 1.143 0 0 1-2.286 0v-8z"
    />
  </Svg>
);

export { BarGraphIcon };
