import React, { useState } from "react";
import { ScrollView, View } from "react-native";
import { FilterWrapper } from "@components/filter-mobile/filter-wrapper";
import { Input } from "@components/general/form/input";
import { styles } from "./style";

interface Props {
  isVisible: boolean;
  onClose: () => void;
  onSave: (memberTypes: { min: string; max: string }) => void;
}

const Length = ({ isVisible, onClose, onSave }: Props) => {
  const [min, setMin] = useState("");
  const [max, setMax] = useState("");

  return (
    <FilterWrapper
      headerTitle="Member Type"
      isVisible={isVisible}
      onClose={onClose}
      buttonTitle="Save"
      isDoneButtonDisabled={!min && !max}
      onDone={() => min || (max && onSave({ min, max }))}
    >
      <ScrollView contentContainerStyle={styles.inputWrap}>
        <View style={styles.inputItem}>
          <Input
            label="Min"
            keyboardType="number-pad"
            onChangeText={(e) => setMin(e)}
          />
        </View>
        <View style={styles.inputItem}>
          <Input
            label="Max"
            keyboardType="number-pad"
            onChangeText={(e) => setMax(e)}
          />
        </View>
      </ScrollView>
    </FilterWrapper>
  );
};

export { Length };
