import React, { useEffect } from "react";
import { Alert, Text, View } from "react-native";
import { useLocation, useNavigate } from "react-router-native";
import { sendEmailVerification, signOut } from "firebase/auth";
import { AuthWrapper } from "@components/sign-up/auth-wrapper";
import { APP_URL } from "@env";
import { ids, styles } from "./style";
import { auth } from "../../../firebase";
import { useGetAuthTokenAndUserId } from "@hooks/useGetAuthTokenAndUserId";
import { useSendVerificationEmailMutation } from "@gql/generated/generated";

type ILocationState = {
  email: string;
};

const VerifyEmail = () => {
  const navigate = useNavigate();
  const [sendVerificationEmail, { loading }] =
    useSendVerificationEmailMutation();

  const { user } = useGetAuthTokenAndUserId();

  useEffect(() => {
    if (user?.emailVerified) {
      navigate("/");
    }
  }, [user?.emailVerified]);

  const handleResendEmailVerification = async () => {
    if (!user) return signOut(auth);
    try {
      await sendVerificationEmail({
        variables: { input: { userId: user.uid } },
      });
      Alert.alert("Success", "Verification email resent");
    } catch (e) {
      const error = e as { message: string };
      Alert.alert("Failed", error?.message || "Encountered an error");
    }
  };

  if (!user)
    return (
      <View>
        <Text>Not logged in</Text>
      </View>
    );

  const email = user.email;

  return (
    <AuthWrapper
      title="Check your email"
      subtitle={`We have sent a verification link to ${email}`}
    >
      <View>
        <Text style={styles.subtitle} dataSet={{ media: ids.subtitle }}>
          If you don’t see the email in your inbox, check your spam folder or
          <Text
            style={styles.registerLink}
            onPress={handleResendEmailVerification}
          >
            &nbsp;{loading ? "Sending" : "click to resend"}
          </Text>
        </Text>
      </View>
    </AuthWrapper>
  );
};

export { VerifyEmail };
