import { View } from "react-native";
import { AllMembersHeader } from "../all-members-list-header";

const AllMembersContainerList = () => {
  return (
    <View>
      <AllMembersHeader />
    </View>
  );
};

export { AllMembersContainerList };
