import { Image, Text, TouchableOpacity, View } from "react-native";
import { Dispatch, SetStateAction, useState } from "react";
import { ActionList, Button, Icon, Popover } from "@shopify/polaris";
import { CheckboxComponent } from "../checkbox-component";
import { Form, Formik } from "formik";
import { SubmitListener } from "@components/general/submit-listener-formik";
import CategoryIcon from "../../../../../assets/img/CategoryFilter.png";
import CategoryMinor from "../../../../../assets/img/CategoryMinor.png";
import { styles } from "./style";
import { RightArrow } from "@components/general/icons";
import {
  ButtonGeneral,
  ButtonVariant,
} from "@components/general/button-general";
import { ArrowLeftMinor } from "@shopify/polaris-icons";

const categories = [
  {
    id: 'marketing',
    name: 'Marketing',
    items: [
      "Marketing Strategy",
      "Social Media",
      "Brand",
      "Content Marketing",
      "Marketing Measuring and Reporting",
      "Marketing Systems and Technology",
    ],
  },
  {
    id: 'nbd',
    name: 'New Business Development',
    items: [
      "New Business Development Strategy",
      "Building a Sales Culture",
      "Tenders, Proposals and Pitches",
      "Market and Competitor Analysis",
      "New Business Development Data and Reporting",
      "Sales Systems and Technology",
    ],
  },
  {
    id: 'am',
    name: 'Account Management',
    items: [
      "Account Management Strategy",
      "Account Penetration",
      "Customer Retention",
      "The Client Experience",
      "Sales Systems and Technology",
    ],
  },
  {
    id: 'ps',
    name: 'Products and Services',
    items: [
      "Products and Services Strategy",
      "Contract Recruitment",
      "Talent Solutions and Reoccurring Revenues",
      "Search",
      "Pricing",
      "Packaging and Taking to Market",
    ],
  },
  {
    id: 'candidate',
    name: 'Candidates',
    items: [
      "Candidate Strategy",
      "Candidate Marketing",
      "Candidate Sourcing",
      "The Candidate Experience",
      "Candidate Engagement",
      "Candidate Systems and Technology",
    ],
  },
  {
    id: 'people',
    name: 'People',
    items: [
      "People Strategy",
      "Personal Effectiveness",
      "Leadership and Building a High Performance Team",
      "Learning and Development",
      "Employee Engagement",
      "People Systems and Technology",
    ],
  },
  {
    id: 'st',
    name: 'Systems and Technology',
    items: [
      "Systems and Technology Strategy",
      "The Recruitment Tech Supplier Landscape",
      "Marketing Systems and Technology",
      "Sales Systems and Technology",
      "People Systems and Technology",
      "Candidate Systems and Technology",
      "Operations Systems and Technology",
    ],
  },
  {
    id: 'operations',
    name: 'Operations',
    items: [
      "Operational Strategy",
      "Finance",
      "Compliance, Legals and Terms",
      "Data and Analytics",
      "Process Management",
      "Operations Systems and Technology",
    ],
  },
];

const mappedInitialValues = {
  ...categories.reduce<Record<string, string | string[]>>((acc, c) => {
    acc[`${c.id}_all`] = c.items.map((subItem) => `${c.name}: ${subItem}`);
    for (const [index, item] of c.items.entries()) {
      const key = `${c.id}_${index + 1}`;
      acc[key] = `${c.name}: ${item}`;
    }
    return acc;
  }, {}),
};

export const CategoryButton = ({
  selectedCat = [],
  setSelectedCat,
  isEditor,
  displayCategoryModal,
  setDisplayCategoryModal,
  fromUserApp,
  placeholderText = "Category",
  placeholderTextStyle,
  withSlimButton,
  isSelected,
}: {
  selectedCat: string[];
  setSelectedCat: Dispatch<SetStateAction<string[]>>;
  isEditor?: boolean;
  displayCategoryModal: boolean;
  setDisplayCategoryModal: Dispatch<SetStateAction<boolean>>;
  fromUserApp?: boolean;
  placeholderText?: string;
  placeholderTextStyle?: any;
  withSlimButton?: boolean;
  isSelected?: boolean;
}) => {
  const [subCategory, setSubCategory] = useState(0);

  const CategoryButton = (
    <View>
      <Button
        size={fromUserApp ? "large" : "medium"}
        fullWidth
        textAlign="left"
        disclosure={fromUserApp ? false : displayCategoryModal ? "up" : "down"}
        onClick={() => setDisplayCategoryModal(!displayCategoryModal)}
        monochrome={isSelected}
      >
        {/* @ts-ignore FIXME: shopify api only allows string or string[] as button child */}
        {fromUserApp ? (
          <View style={{ display: "flex", flexDirection: "row" }}>
            <Image
              source={CategoryIcon}
              style={{
                width: 14,
                height: 14,
                marginTop: 4,
                marginRight: 7,
                marginLeft: -12,
              }}
            />
            <Text style={[styles.filterText, placeholderTextStyle]}>
              {placeholderText}
            </Text>
          </View>
        ) : (
          <Text style={[styles.filterText, placeholderTextStyle]}>
            {placeholderText}
          </Text>
        )}
      </Button>
    </View>
  );
  const SlimButton = (
    <View>
      <ButtonGeneral
        style={{
          backgroundColor: selectedCat.length > 0 ? "#F2F2F2" : "#fff",
          padding: "8px, 16px, 8px, 12px",
          minWidth: 113,
        }}
        variant={ButtonVariant.Secondary}
        onPress={() => setDisplayCategoryModal(!displayCategoryModal)}
      >
        <Image
          source={CategoryMinor}
          style={{
            width: 20,
            height: 20,
          }}
        />
        <Text
          style={{
            fontSize: 14,
            color: "#202223",
            fontFamily: "OpenSans_600SemiBold",
            marginLeft: 4,
          }}
        >
          {selectedCat.length ? "Selected" : placeholderText}
        </Text>
      </ButtonGeneral>
    </View>
  );

  const getInitialValues = () => {
    const values: { [key: string]: boolean } = {};
    for (const [key, value] of Object.entries(mappedInitialValues)) {
      if (typeof value === "object") {
        values[key] = value.every((i) => selectedCat.includes(i));
      } else {
        values[key] = selectedCat.includes(value);
      }
    }
    return values;
  };

  return (
    <TouchableOpacity
      style={{
        backgroundColor: "#FFFFFF",
        height: 36,
        ...(!isEditor && {}),
        ...(placeholderText === "Access" && {}),
        ...(fromUserApp && {
          marginLeft: 0,
        }),
        ...(placeholderText === "Access" && {}),
        ...(fromUserApp && {}),
      }}
    >
      <Formik
        initialValues={getInitialValues()}
        enableReinitialize
        onSubmit={async (values, { setFieldValue }) => {
          const asArray = Object.entries(values);
          const filteredAll = asArray.filter(
            (value) => value[0].split("_")[1] === "all" && value[1] === true
          );
          const filtered = asArray.filter((value) => value[1] === true);

          filteredAll.forEach((e) => {
            const cat = e[0].split("_")[0];

            const category = categories.find(c => c.id === cat);
            category?.items.forEach((_, index) => {
              const key = `${category.id}_${index + 1}`;
              setFieldValue(key, false);
            });
          });

          // TODO: backend now expecting categories to be presented as ContentAccess type
          const selected: string[] = [];
          filtered.forEach((category) => {
            const key = category[0] as keyof typeof mappedInitialValues;
            if (typeof mappedInitialValues[key] === "string") {
              selected.push(mappedInitialValues[key] as string);
            } else {
              selected.push(...mappedInitialValues[key]);
            }
          });

          setSelectedCat(selected);
        }}
      >
        {(formik) => (
          <Popover
            fullWidth={isEditor}
            active={displayCategoryModal}
            activator={withSlimButton ? SlimButton : CategoryButton}
            onClose={() => setDisplayCategoryModal(!displayCategoryModal)}
            preferredAlignment="left"
          >
            <Form>
              <SubmitListener formik={formik} />
              {!subCategory ? (
                <ActionList
                  actionRole="menuitem"
                  items={[
                    {
                      content: "Reset",
                      onAction: () => {
                        formik.resetForm();
                        setSelectedCat([]);
                      },
                      destructive: true,
                    },
                    ...categories.map((category, index) => ({
                      content: category.name,
                      onAction: () => setSubCategory(index + 1),
                      suffix: <Icon source={RightArrow} />,
                    })),
                  ]}
                />
              ) : subCategory ? (
                <ActionList
                  actionRole="menuitem"
                  items={[
                    {
                      content: "Main Category",
                      prefix: <Icon source={ArrowLeftMinor} />,
                      onAction: () => setSubCategory(0),
                    },
                    {
                      content: 'All',
                      disabled: categories[subCategory - 1].items.some((_, index) => {
                        const category = categories[subCategory - 1];
                        const key = `${category.id}_${index + 1}`;
                        return formik.values[key];
                      }),
                      onAction: () => {
                        const key = `${categories[subCategory - 1].id}_all`;
                        formik.setFieldValue(key, !formik.values[key]);
                      },
                      prefix: <CheckboxComponent
                        name={`${categories[subCategory - 1].id}_all`}
                        value={formik.values[`${categories[subCategory - 1].id}_all`]}
                      />
                    },
                    ...categories[subCategory - 1].items.map((subItem: string | undefined, index) => {
                      const category = categories[subCategory - 1];
                      const key = `${category.id}_${index + 1}`;
                      const allKey = `${category.id}_all`;
                      return {
                        content: subItem,
                        disabled: formik.values[allKey],
                        onAction: () => {
                          formik.setFieldValue(key, !formik.values[key]);
                        },
                        prefix: (
                          <CheckboxComponent
                            name={key}
                            value={formik.values[key]}
                          />
                        ),
                      };
                    }),
                  ]}
                />
              ) : null}
            </Form>
          </Popover>
        )}
      </Formik>
    </TouchableOpacity>
  );
};
