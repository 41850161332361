import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const ErrorIcon = (props: SvgProps) => (
  <Svg width={16} height={16} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16ZM7 7a1 1 0 0 0 2 0V5a1 1 0 1 0-2 0v2Zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0Z"
      fill="#D72C0D"
    />
  </Svg>
);

export { ErrorIcon };
