import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const PointerArrowRight = (props: SvgProps) => (
  <Svg width={16} height={12} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m15.707 5.293-5-5a.999.999 0 1 0-1.414 1.414L12.586 5H1a1 1 0 1 0 0 2h11.586l-3.293 3.293a.999.999 0 1 0 1.414 1.414l5-5a.999.999 0 0 0 0-1.414Z"
      fill="#5C5F62"
    />
  </Svg>
);

export { PointerArrowRight };
