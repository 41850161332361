import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: "100%",
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 20,
  },
  label: {
    color: "#6D7175",
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
    marginBottom: 4,
  },
  value: {
    color: "#0D1738",
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 16,
  },
  touchItem: {
    borderWidth: 1,
    borderColor: "#BABFC3",
    borderRadius: 4,
    height: 36,
    paddingLeft: 12,
    paddingRight: 8,
    justifyContent: "center",
  },
  touchText: {
    fontFamily: "OpenSans_400Regular",
    color: "#0D1738",
    fontSize: 14,
  },
});
