import StyleSheet from "react-native-media-query";
import { mediaQuery } from "@utils/misc";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    [mediaQuery.forMobile]: {
      paddingTop: 80,
      paddingLeft: 24,
      paddingRight: 24,
    },
  },
  backTouchWrap: {
    marginBottom: 86,
    display: "none",
    [mediaQuery.forMobile]: {
      display: "flex",
    },
  },
  backTouch: {
    padding: 4,
    paddingLeft: 0,
  },
  contentBoxWrap: {
    width: "100%",
    alignItems: "center",
  },
  contentBox: {
    width: 528,
    borderRadius: 8,
    padding: 40,
    [mediaQuery.forMobile]: {
      width: "100%",
      backgroundColor: "transparent",
      padding: 0,
    },
  },
  title: {
    marginBottom: 20,
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 26,
    lineHeight: 32,
    color: "#0D1738",
    textAlign: "center",
    [mediaQuery.forMobile]: {
      textAlign: "left",
      width: "100%",
      marginTop: 0,
      marginBottom: 8,
    },
  },
  subtitle: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 14,
    lineHeight: 20,
    color: "#6D7175",
    marginBottom: 40,
    textAlign: "center",
    [mediaQuery.forMobile]: {
      textAlign: "left",
      width: "100%",
      color: "#0D1738",
      fontSize: 16,
    },
  },
});
