import StyleSheet from "react-native-media-query";
import { mediaQuery } from "@utils/misc";

export const { ids, styles } = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    position: "relative",
    marginBottom: 40,
    [mediaQuery.forTabletDown]: {
      flexDirection: "column",
    },
  },
  mainContentBox: {
    width: "70%",
    padding: 16,
    paddingLeft: 80,
    paddingRight: 80,
    paddingTop: 40,
    paddingBottom: 40,
    [mediaQuery.forTabletDown]: {
      width: "100%",
      flexDirection: "column",
      marginBottom: 16,
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 16,
      paddingBottom: 16,
    },
  },
  rightContainer: {
    paddingLeft: 16,
    display: "flex",
    width: "30%",
    [mediaQuery.forTabletDown]: {
      padding: 0,
      width: "100%",
    },
  },
});
