import React from "react";
import { Text, View } from "react-native";
import { useNavigate } from "react-router-native";
import { ContentBox } from "@components/general/layouts/content-box";
import { ListItem } from "@components/account/list-item";

const AnalyticsIndex = () => {
  const navigate = useNavigate();
  return (
    <ContentBox>
      <View
        style={{
          padding: 40,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Text
          style={{
            fontFamily: "OpenSans_600SemiBold",
            fontSize: 26,
            lineHeight: 32,
            color: "#0D1738",
          }}
        >
          Assignments
        </Text>
      </View>
      <ListItem
        title="Members"
        subtitle="Review performances and activities of your team members."
        onPress={() => navigate("members")}
      />
      <ListItem
        title="TRN World"
        subtitle="View activity reports from our Content Hub"
        onPress={() => navigate("trn-world")}
      />
    </ContentBox>
  );
};

export { AnalyticsIndex };
