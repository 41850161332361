import { Pulse, Recommendation } from "@gql/generated/generated";
import { calculateSectionScoreColor } from "@utils/calculateSectionScoreColor";
import { getRecommendationsAndFeedbackForPulseResult } from "@pages/roadmap/utils";
import { getRecommendationsPercentageComplete } from "@utils/getRecommendationsPercentageComplete";
import { isNotNull } from "@utils/isNotNull";
import { PulseResultBlockPlan } from "@components/roadmap/pulse-result-block-plan";
import { FilteredSection } from "./types";

type Props = { currentPulse: Pulse; recommendations: Recommendation[] };

export const CategoryBlocks = ({ currentPulse, recommendations }: Props) => {
  const categoryBlocks = currentPulse.sections
    ?.filter((section): section is FilteredSection => !!section?.id)
    .map((section) => {
      const { score, maxScore, name } = section;
      const scoreColor = calculateSectionScoreColor(section);

      const {
        recommendationsForSection,
        trainingRecommendations,
        contentRecommendations,
        feedbackForScore,
      } = getRecommendationsAndFeedbackForPulseResult(
        section.id,
        score || 0,
        recommendations
      );

      const percentageComplete = getRecommendationsPercentageComplete(
        section.id,
        recommendationsForSection?.filter(isNotNull) ?? [],
        currentPulse?.actionedRecommendations ?? []
      );

      return (
        <PulseResultBlockPlan
          key={section.id}
          sectionId={section.id}
          title={name ?? ""}
          feedbackTitle={feedbackForScore?.title}
          feedbackDescription={feedbackForScore?.text}
          score={score ?? 0}
          percent={Math.floor(percentageComplete)}
          contentPieces={contentRecommendations.length || 0}
          trainingCourses={trainingRecommendations.length || 0}
          scoreColor={scoreColor || "incomplete"}
          maxScore={maxScore || 0}
        />
      );
    });

  return <>{categoryBlocks}</>;
};
