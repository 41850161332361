import { FormikDatePickerDropdown } from "@components/back-office/editor/formik-date-picker-dropdown";
import { useQueryFilters } from "../useGridFilters";

export const DateFromFilter = () => {
  const { filters, setFilterValue } = useQueryFilters(["dateFrom"]);

  return (
    <FormikDatePickerDropdown
      dateValue={filters.dateFrom}
      placeholderText="From"
      onReset={
        filters.dateFrom ? () => setFilterValue("dateFrom", null) : undefined
      }
      onChange={(v) => setFilterValue("dateFrom", v.start.getTime())}
    />
  );
};
