import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: "100%",
    maxWidth: 334,
    borderRadius: 4,
    borderTopWidth: 4,
    shadowColor: "rgba(0, 0, 0, 0.15)",
    shadowRadius: 4,
    shadowOffset: { height: 0.5, width: 0 },
    padding: 20,
  },
  topWrap: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 30,
  },
  circularWrap: {
    width: "50%",
  },
  percentageText: {
    color: "#FD5749",
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 42,
    marginBottom: 4,
  },
  doneText: {
    color: "#0D1738",
    fontSize: 16,
    fontFamily: "OpenSans_600SemiBold",
    marginBottom: 4,
  },
  questionsText: {
    color: "#0D1738",
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
  },
  title: {
    fontSize: 20,
    fontFamily: "OpenSans_600SemiBold",
    color: "#0D1738",
  },
  playWrap: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  playTouch: {
    backgroundColor: "#0D1738",
    height: 40,
    width: 40,
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
  },
});
