/**
 * This file is using Names/Titles as selection identifiers as CategoryIDs does not match between
 * RoadmapTemplate and Categories, and Subcategories does not have an ID inside Categories.
 */
import { useEffect, useState } from "react";
import { ActivityIndicator, ScrollView, Text, View } from "react-native";
import { NavigateFunction, useNavigate } from "react-router-native";
import { IndexTableHeading } from "@shopify/polaris/build/ts/latest/src/components/IndexTable";
import { NonEmptyArray } from "@shopify/polaris/build/ts/latest/src/types";
import {
  GenericTable,
  GenericTableRowItem,
} from "@components/back-office/tables/generic-table";
import { TableHeader as TableHeaderComponent } from "@components/back-office/tables/table-header";
import { ContentBox } from "@components/general/layouts/content-box";
import { styles } from "./style";
import { RoadmapTemplatePulseSubCategory, useGetRoadmapTemplateQuery } from "@gql/generated/generated";
import { TabTitle } from "@components/general/tab-title";
import {
  getCategoriesFromRoadmapTemplate,
  getSubcategoriesFromRoadmapTemplate,
  useGetAllRecommendations,
} from "./utils";

type TableHeaderProps = {
  dropdownItems?: { id: string; content: string }[];
} & Pick<
  ReturnType<typeof useTableHeaderLocal>,
  "page" | "setPage" | "selectedDropdownItem" | "setSelectedDropdownItem"
>;

const useTableHeaderLocal = () => {
  const [page, setPage] = useState<number>(1);
  const [selectedDropdownItem, setSelectedDropdownItem] = useState<
    string | null
  >(null);
  const tableHeaderProps = {
    page,
    setPage,
    selectedDropdownItem,
    setSelectedDropdownItem,
  };
  return tableHeaderProps;
};

const TableHeader = ({
  page,
  setPage,
  selectedDropdownItem,
  setSelectedDropdownItem,
  dropdownItems,
}: TableHeaderProps) => {
  useEffect(() => {
    if (!dropdownItems) {
      return;
    }
    setSelectedDropdownItem(dropdownItems[0].id);
    // To allow manual selection after automatic first item selection.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TableHeaderComponent
      page={page}
      setPage={setPage}
      hasNextPage={false}
      selectedDropdownItem={selectedDropdownItem}
      setSelectedDropdownItem={setSelectedDropdownItem}
      dropdownItems={dropdownItems}
    />
  );
};

const Loader = () => (
  <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
    <ActivityIndicator size="large" color="#213470" />
  </View>
);

type TaskBarProps = {
  tabs: RoadmapTemplatePulseSubCategory[];
  activeTab?: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const TabBar = ({ tabs, activeTab, setActiveTab }: TaskBarProps) => {
  useEffect(() => {
    if (tabs.length === 0) {
      return;
    }

    setActiveTab(tabs[0].id);
  }, [tabs, setActiveTab]);

  return (
    <ScrollView horizontal showsHorizontalScrollIndicator={false}>
      {tabs.map(({ id, title }) => (
        <TabTitle
          key={id}
          active={id === activeTab}
          title={title ?? ''}
          onPress={() => setActiveTab(id)}
        />
      ))}
    </ScrollView>
  );
};

const Table = ({
  rows,
  handleMenuClick,
}: {
  rows: GenericTableRowItem[];
  handleMenuClick?: (id: string, optionId: string) => void;
}) => {
  const headings: NonEmptyArray<IndexTableHeading> = [
    { title: "Score" },
    { title: "No. of Content" },
    { title: "No. of Training" },
    { title: "Last Update" },
    { title: "" },
  ];

  const customMenuItems = [{ id: "Edit", content: "Edit" }];

  return (
    <GenericTable
      headings={headings}
      rows={rows}
      resourceName={{ singular: "score", plural: "scores" }}
      customMenuItems={customMenuItems}
      handleMenuClick={handleMenuClick}
      withCheckbox={false}
    />
  );
};

const getHandleMenuClick =
  ({
    navigate,
    categoryId,
    subcategoryId,
  }: {
    navigate: NavigateFunction;
    categoryId: string | null; // inherited constraints
    subcategoryId: string | undefined; // inherited constraints
  }) =>
    async (itemId: string, optionID: string) => {
      if (!categoryId || !subcategoryId) {
        return;
      }

      switch (optionID) {
        case "Edit":
          return navigate(
            `/Roadmap/score-ranking/add-edit-content/${categoryId}/${subcategoryId}/${itemId}`
          );
      }
    };

export const ScoreRanking = () => {
  const { data: roadmapTemplateQueryResults, loading: loadingRoadmapTemplate } =
    useGetRoadmapTemplateQuery();

  const roadmapTemplate = roadmapTemplateQueryResults?.getRoadmapTemplate;

  const tableHeaderProps = useTableHeaderLocal();
  const { selectedDropdownItem: categoryId } = tableHeaderProps;

  const [subcategoryId, setSubcategoryId] = useState<string | undefined>("");

  const navigate = useNavigate();

  const { recommendations, loading: loadingRecommendations } =
    useGetAllRecommendations();

  if (loadingRoadmapTemplate || loadingRecommendations || !roadmapTemplate) {
    return <Loader />;
  }

  const categories = getCategoriesFromRoadmapTemplate(roadmapTemplate);

  const subcategories = getSubcategoriesFromRoadmapTemplate(
    roadmapTemplate,
    categoryId || ""
  );

  const rows =
    roadmapTemplate.pulse
      .find(({ id }) => id === categoryId)
      ?.subcategories.find(({ id }) => id === subcategoryId)
      ?.scores.map((score) => {
        const recommendationByScorePath = `${categoryId}/${subcategoryId}/${score}`;

        const recommendationsForScore =
          recommendations.find(
            (recommendation) => recommendation.id === recommendationByScorePath
          )?.recommendations || [];

        const [trainingCount, contentCount] = [
          recommendationsForScore?.filter((el) => el?.type === "trainings")
            .length,
          recommendationsForScore?.filter((el) => el?.type === "content")
            .length,
        ];

        return {
          id: String(score),
          score,
          contentCount,
          trainingCount,
          lastUpdate: "N/A",
        };
      }) || [];

  return (
    <>
      <View style={styles.titleWrap}>
        <Text style={styles.backOfficeHeaderTitle}>Score Ranking (Pulse)</Text>
      </View>
      <ContentBox>
        <View style={{ padding: 20 }}>
          <TableHeader dropdownItems={categories} {...tableHeaderProps} />
          <TabBar
            {...{
              tabs: subcategories,
              activeTab: subcategoryId,
              setActiveTab: setSubcategoryId,
            }}
          />
        </View>

        <View style={{ padding: 20 }}>
          <Table
            {...{
              rows,
              handleMenuClick: getHandleMenuClick({
                navigate,
                categoryId,
                subcategoryId,
              }),
            }}
          />
        </View>
      </ContentBox>
    </>
  );
};
