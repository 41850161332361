import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  postStats: {
    width: "100%",
    padding: 20,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  leftBox: {
    flexDirection: "row",
  },
  yellowStarIcon: {
    marginRight: 7,
  },
  statsText: {
    fontSize: 12,
    color: "#6D7175",
    fontFamily: "OpenSans_400Regular",
  },
  dot: {
    height: 4,
    width: 4,
    borderRadius: 2,
    backgroundColor: "#6D7175",
    marginLeft: 4,
    marginRight: 4,
  },
  statsWrap: {
    flexDirection: "row",
    alignItems: "center",
  },
  actionWrap: {
    padding: 20,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderTopColor: "#EDEEEF",
    borderTopWidth: 1,
  },
  actionTouch: {
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
    borderRadius: 4,
  },
  ratedActionTouch: {
    backgroundColor: "#F6F6F7",
  },
  hoverActionTouch: {
    backgroundColor: "#F6F6F7",
  },
  actionText: {
    color: "#6D7175",
    fontSize: 12,
    fontFamily: "OpenSans_600SemiBold",
    marginLeft: 4,
  },
  hiddenBox: {
    borderRadius: 4,
    padding: 10,
    backgroundColor: "#FFFFFF",
    position: "absolute",
    top: -40,
    shadowOpacity: 1,
    elevation: 1,
    shadowColor: "rgba(0, 0, 0, 0.15)",
    shadowRadius: 4,
    shadowOffset: { height: 0.5, width: 0 },
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
});
