import { ActivityIndicator, View } from "react-native";

export const Loader = () => (
  <View
    style={{
      justifyContent: "center",
      alignItems: "center",
      flex: 1,
    }}
  >
    <ActivityIndicator size="large" color="#213470" />
  </View>
);
