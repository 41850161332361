import { Text, View } from "react-native";
import { styles } from "./style";

interface Props {
  email: string;
  phone: string;
  role?: string;
  lastLogged: string;
  memberSince: string;
}

const MemberDetails = ({
  email,
  phone,
  role,
  lastLogged,
  memberSince,
}: Props) => {
  return (
    <View style={styles.detailContainer}>
      <Text style={styles.contactTitle}>Contact Info</Text>
      <Text style={styles.contactEmailPhone}>{email}</Text>
      <Text style={styles.contactEmailPhone}>{phone}</Text>
      {/* Role, last logged, member since */}
      <View style={styles.contactRolLastMember}>
        <View style={styles.contactRolLastMemberCol}>
          <Text style={styles.contactTitle}>Role</Text>
          <Text style={styles.contactEmailPhone}>{role}</Text>
        </View>
        <View style={styles.contactRolLastMemberCol}>
          <Text style={styles.contactTitle}>Last Logged in</Text>
          <Text style={styles.contactEmailPhone}>{lastLogged}</Text>
        </View>
        <View style={styles.contactRolLastMemberCol}>
          <Text style={styles.contactTitle}>Member since</Text>
          <Text style={styles.contactEmailPhone}>{memberSince}</Text>
        </View>
      </View>
    </View>
  );
};

export { MemberDetails };
