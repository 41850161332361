import React, { useState } from "react";
import { Text, View } from "react-native";
import { styles } from "./style";
import { RoadmapDataTable } from "../data-table";
import { TableDropdown } from "@components/back-office/tables/table-dropdown";
import { ContentBox } from "@components/general/layouts/content-box";
import { Pulse } from "@gql/generated/generated";

const headers = { status: "Main/Sub Category", subcategory: "Scores" };

export const ScoresBreakdown = ({
  data,
  yearAndQuarterData,
}: {
  data?: Pulse;
  yearAndQuarterData: any;
}) => {
  const [selectedScoreYear, setSelectedScoreYear] = useState("2023");

  const scores = data?.sections.map(({ score, name, questions }) => ({
    score,
    name,
    subCategories: questions.map((q) => ({
      score: q.answer.currentScore,
      name: q.question,
    })),
  }));

  return (
    <ContentBox style={{ flex: 1, marginLeft: 20 }}>
      <View style={{ padding: 20 }}>
        <View style={[styles.row]}>
          <Text style={styles.mainTitle}>Scores Breakdown</Text>
          <View style={styles.row}>
            <TableDropdown
              items={yearAndQuarterData.map((el: any) => ({
                id: el.year,
                content: el.year,
              }))}
              placeholder={selectedScoreYear}
              onSelectItem={(id) => setSelectedScoreYear(id)}
            />
          </View>
        </View>
        <Text style={styles.regularGreyText}>
          See scores from each subcategory
        </Text>
      </View>
      {/* @ts-ignore FIXME: bad type */}
      <RoadmapDataTable headers={[headers]} categories={scores} isScoreTable />
    </ContentBox>
  );
};
