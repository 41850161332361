import * as React from "react";
import Svg, { Path } from "react-native-svg";

const RightArrow = () => (
  <Svg width={8} height={13} fill="none">
    <Path
      fill="#5C5F62"
      d="M1.5 12.861a.999.999 0 0 1-.707-1.707l4.293-4.293L.793 2.568a.999.999 0 1 1 1.414-1.414l5 5a.999.999 0 0 1 0 1.414l-5 5a.997.997 0 0 1-.707.293"
    />
  </Svg>
);

export { RightArrow };
