import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { ISvgProps } from "../../../types";

const InstagramIcon = (props: ISvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <Path
      fill={props.color || "#AEB5BC"}
      d="M12.001 3.273c-2.37 0-2.667.01-3.598.052-.93.043-1.563.19-2.118.406a4.273 4.273 0 0 0-1.546 1.006A4.284 4.284 0 0 0 3.73 6.283c-.216.555-.363 1.19-.405 2.118-.042.931-.053 1.229-.053 3.599s.011 2.667.053 3.598c.043.929.19 1.563.406 2.118.223.574.52 1.06 1.006 1.546a4.28 4.28 0 0 0 1.546 1.007c.555.216 1.19.363 2.118.405.93.043 1.228.053 3.598.053s2.667-.01 3.598-.053c.929-.042 1.564-.19 2.119-.405a4.27 4.27 0 0 0 1.545-1.007 4.284 4.284 0 0 0 1.007-1.546c.215-.555.362-1.19.405-2.118.042-.931.053-1.228.053-3.598s-.01-2.668-.053-3.599c-.043-.929-.19-1.563-.405-2.118a4.283 4.283 0 0 0-1.007-1.546 4.266 4.266 0 0 0-1.546-1.006c-.556-.216-1.19-.363-2.12-.406-.93-.042-1.227-.052-3.598-.052H12zm-.783 1.572h.783c2.33 0 2.607.009 3.527.05.85.04 1.312.182 1.62.301.407.158.698.347 1.003.653.305.305.494.596.653 1.004.12.307.262.769.3 1.62.042.92.051 1.196.051 3.525 0 2.33-.009 2.605-.05 3.525-.04.851-.182 1.313-.301 1.62a2.698 2.698 0 0 1-.653 1.003 2.699 2.699 0 0 1-1.003.653c-.307.12-.77.262-1.62.3-.92.042-1.197.051-3.527.051s-2.606-.009-3.526-.05c-.851-.04-1.313-.182-1.62-.302a2.703 2.703 0 0 1-1.004-.652 2.705 2.705 0 0 1-.653-1.003c-.12-.308-.262-.77-.301-1.62-.042-.92-.05-1.197-.05-3.527s.008-2.606.05-3.526c.039-.85.181-1.312.3-1.62.159-.407.348-.698.654-1.004a2.709 2.709 0 0 1 1.003-.653c.308-.12.77-.262 1.62-.3.806-.037 1.118-.048 2.744-.05v.002zm5.442 1.45a1.047 1.047 0 1 0 0 2.095 1.047 1.047 0 0 0 0-2.096zM12 7.518a4.482 4.482 0 1 0 .001 8.964A4.482 4.482 0 0 0 12 7.518zm0 1.573a2.91 2.91 0 1 1 0 5.818 2.91 2.91 0 0 1 0-5.818z"
    />
  </Svg>
);

export { InstagramIcon };
