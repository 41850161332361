import { PollOption } from "@gql/generated/generated";
import { useCallback, useState } from "react";

export const getHasUserVoted = (pollOptions:PollOption[], userId: string) => {
  if(!pollOptions || !userId) return false;
  return !!pollOptions.find((option) => option.votes?.includes(userId));
}

const getItemVotePercentage = (voteCount: number, totalVoteCount: number) => {
  if (!totalVoteCount) {
    return '0';
  }
  return ((voteCount / totalVoteCount) * 100).toFixed(0);
};

// used for optimistic updates after a vote is cast
export const addOrRemoveVote = (pollOptions: PollOption[], userId: string, pollOptionId: string) => {
  // update votes
  const updatedPollOptions = pollOptions.map((option) => {
    const updatedOption = { ...option };
    if (updatedOption.id !== pollOptionId) {
      // clear the user from any previous selection
      if (updatedOption.votes?.includes(userId)) {
        updatedOption.votes = updatedOption.votes.filter(
          (v: string) => v !== userId,
        );
      }
    }

    if (updatedOption.id === pollOptionId) {
      if (updatedOption.votes?.includes(userId)) {
        // clear the user from the current selection if they have already selected it
        updatedOption.votes = updatedOption.votes.filter(
          (v: string) => v !== userId,
        );
      } else {
        // add the user to the current selection
        updatedOption.votes = [...(updatedOption.votes || []), userId];
      }
    }
    return updatedOption;
  });
  // update percentage and voteCount
  const totalVoteCount = updatedPollOptions.reduce(
    (acc, o) => acc + (o.votes?.length || 0),
    0,
  );

  return updatedPollOptions.map((o) => ({
    ...o,
    voteCount: o.votes?.length || 0,
    percentage: getItemVotePercentage(o.votes?.length || 0, totalVoteCount),
  }));
}

export const usePollOptions = (pollOptions:PollOption[], userId: string, voteCount: number ) => {
  const [hasVoted, setHasVoted] = useState<boolean>(getHasUserVoted(pollOptions, userId));
  const [voteCountState, setVoteCountState] = useState<number>(voteCount);
  const [pollOptionsState, setPollOptionsState] = useState<PollOption[]>(pollOptions);

  const updatePollOptions = useCallback((pollOptions: PollOption[]) => {
    const aggregatedVoteCount = pollOptions.reduce((acc, o) => acc + o.voteCount, 0);
    setVoteCountState(aggregatedVoteCount);
    setPollOptionsState(pollOptions);
    setHasVoted(getHasUserVoted(pollOptions, userId));
  }, [userId]);

  const updatePollOptionsOptimistically = useCallback((pollOptions: PollOption[], pollOptionId: string) => {
    const updatedPollOptions = addOrRemoveVote(pollOptions, userId, pollOptionId);
    updatePollOptions(updatedPollOptions);
  }, [updatePollOptions, userId]);

  return {
    hasVoted,
    voteCountState,
    pollOptionsState,
    updatePollOptions,
    updatePollOptionsOptimistically
  }
};