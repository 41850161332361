import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  navItem: {
    borderBottomWidth: 1,
    borderBottomColor: "#EDEEEF",
    paddingTop: 18,
    paddingBottom: 18,
    paddingLeft: 20,
    paddingRight: 20,
    flexDirection: "row",
    alignItems: "center",
  },
  navItemTitle: {
    fontSize: 16,
    color: "#0D1738",
    fontFamily: "OpenSans_400Regular",
  },
  navItemTitleMargin: {
    marginLeft: 10,
  },
  navItemTitleBold: {
    fontFamily: "OpenSans_600SemiBold",
  },
  badge: {
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 12,
    paddingRight: 12,
    backgroundColor: "#2C6ECB",
    borderRadius: 15,
  },
  badgeText: {
    color: "#FFFFFF",
    fontSize: 12,
    fontFamily: "OpenSans_400Regular",
  },
  assignmentWrap: {
    marginTop: 25,
  },
  assignmentItem: {
    justifyContent: "space-between",
  },
  buttonWrap: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
  },
  userInfoWrap: {
    flexDirection: "row",
    alignItems: "center",
  },
  userImage: {
    borderWidth: 1,
    borderColor: "#DFE4EA",
    height: 60,
    width: 60,
    borderRadius: 30,
  },
  userNameWrap: {
    marginLeft: 16,
  },
  userName: {
    color: "#0D1738",
    fontSize: 20,
    fontFamily: "OpenSans_600SemiBold",
    marginBottom: 4,
  },
  profession: {
    color: "#0D1738",
    fontSize: 16,
    fontFamily: "OpenSans_400Regular",
  },
  closeTouch: {
    padding: 4,
    paddingRight: 0,
  },
});
