import React from "react";
import { useLocation } from "react-router-native";

const useUrlQuery = () => {
  const location = useLocation();
  return React.useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
};

export { useUrlQuery };
