import { useState } from "react";
import { FlatList, View } from "react-native";
import { CommentBox } from "@components/home/post/comment/comment-box";
import { PostComment } from "@components/home/post/comment/post-comment";
import { styles } from "./style";
import { useUserContext } from "@context/UserContext";
import { getDefaultAvatar } from "@utils/misc";
import { urlToImageSource } from "@utils/urlToImageSource";
import { userProfileImage } from "@utils/userProfileImage";
import {
  Comment,
  Reply,
  useCreateReplyMutation,
  useUpdateCommentLikesMutation,
} from "@gql/generated/generated";
import { formatDate } from "@components/training/reviews";

interface Props {
  comments: Comment[];
}

const EachComment = ({ comment }: { comment: Comment }) => {
  const { currentUser } = useUserContext();

  const [isReplyVisible, setIsReplyVisible] = useState(false);
  const [createReply, { loading: submittingReply }] = useCreateReplyMutation();

  const [updateCommentLikes] = useUpdateCommentLikesMutation({
    update: (store, { data }) => {
      const comment = data?.updateCommentLikes;
      store.modify({
        id: `${comment?.__typename}:${comment?.id}`,
        fields: {
          likedBy() {
            return comment?.likedBy || [];
          },
        },
      });
    },
  });

  const {
    commentedItemId,
    commentedItemType,
    id: currentCommentId,
    collectionName,
  } = comment;

  const handlePressLike = async (
    commentOrReply: Comment | Reply,
    isLiked: boolean
  ) => {
    if (commentOrReply.__typename === "Comment")
      return updateCommentLikes({
        variables: {
          input: {
            commentId: commentOrReply.id,
          },
        },
        optimisticResponse: {
          updateCommentLikes: {
            __typename: "Comment",
            ...commentOrReply,
            likedBy: isLiked
              ? commentOrReply.likedBy?.filter(
                  (item) => item !== currentUser?.id
                ) || []
              : [...(commentOrReply.likedBy || []), currentUser?.id ?? ""],
          },
        },
      });
    else if (commentOrReply.__typename === "Reply") {
      return updateCommentLikes({
        variables: {
          input: {
            commentId: commentOrReply.id,
          },
        },
        optimisticResponse: {
          updateCommentLikes: {
            __typename: "Reply",
            ...commentOrReply,
            likedBy: isLiked
              ? commentOrReply.likedBy?.filter(
                  (item) => item !== currentUser?.id
                ) || []
              : [...(commentOrReply.likedBy || []), currentUser?.id ?? ""],
          },
        },
      });
    }
  };

  const handleReply = async (commentText: string) => {
    await createReply({
      variables: {
        input: {
          commentedItemId,
          commentedItemType,
          collectionName,
          commentId: currentCommentId,
          text: commentText,
        },
      },
    });
  };

  const keyExtractor = ({ id }: Reply, _index: number) => id;

  const renderReplyItem = ({ item }: { item: Reply }) => (
    <View style={styles.commentItem}>
      <CommentBox
        avatar={
          comment?.userProfilePhotoUrl
            ? urlToImageSource(comment.userProfilePhotoUrl)
            : getDefaultAvatar(comment.userAvatarIndex)
        }
        userName={item.userName}
        userJobTitle={item.userJobTitle}
        text={item.text}
        totalLikes={item.likedBy?.length ?? 0}
        ratingDate={formatDate(comment.createdAt)}
        onPressLike={() =>
          handlePressLike(item, !!item.likedBy?.includes(currentUser?.id || ""))
        }
        likedByCurrentUser={Boolean(
          currentUser?.id && item.likedBy?.includes(currentUser?.id)
        )}
      />
    </View>
  );

  return (
    <View style={styles.commentItem}>
      <CommentBox
        isReplyVisible
        avatar={
          comment?.userProfilePhotoUrl
            ? urlToImageSource(comment.userProfilePhotoUrl)
            : getDefaultAvatar(comment.userAvatarIndex)
        }
        userName={comment.userName}
        userJobTitle={comment.userJobTitle}
        text={comment.text}
        totalLikes={comment.likedBy?.length ?? 0}
        totalReplies={comment.replies ? comment.replies.length : 0}
        onPressReply={() => setIsReplyVisible(!isReplyVisible)}
        rating={comment.rating ?? 0}
        ratingDate={formatDate(comment.createdAt)}
        onPressLike={() =>
          handlePressLike(
            comment,
            !!comment.likedBy?.includes(currentUser?.id || "")
          )
        }
        likedByCurrentUser={Boolean(
          currentUser?.id && comment.likedBy?.includes(currentUser?.id)
        )}
      />
      {isReplyVisible && currentUser && (
        <View style={styles.replyItem}>
          <PostComment
            placeholder="Add a reply.."
            firstName=""
            lastName=""
            avatar={userProfileImage(currentUser)}
            buttonTitle="Reply"
            onSubmit={(value) => handleReply(value)}
            isLoading={submittingReply}
          />
          {comment.replies ? (
            <FlatList
              data={comment.replies ?? []}
              renderItem={renderReplyItem}
              keyExtractor={keyExtractor}
            />
          ) : null}
        </View>
      )}
    </View>
  );
};

const CommentItem = ({ comments }: Props) => {
  const keyExtractor = ({ id }: Comment, _index: number) => id;

  const renderItem = ({ item }: { item: Comment }) => (
    <EachComment comment={item} />
  );

  return (
    <View style={styles.wrap}>
      <FlatList
        data={comments}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
      />
    </View>
  );
};

export { CommentItem };
