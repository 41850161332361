import { Section } from "@gql/generated/generated";

export const calculateSectionScoreColor = ({
  score,
  scoringCriteria,
}: Section): "red" | "green" | "amber" | "incomplete" | null => {
  if (!scoringCriteria) {
    return null;
  }
  if (score == null) {
    return "incomplete";
  } else if (scoringCriteria.green <= score) {
    return "green";
  } else if (scoringCriteria.amber <= score) {
    return "amber";
  } else if (score >= 0) {
    return "red";
  } else {
    return "incomplete";
  }
};
