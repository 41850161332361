import React from "react";
import { ScrollView, Text, View } from "react-native";
import { Wrapper } from "@components/general/layouts/wrapper/Wrapper";
import { IndexPageHeader } from "@components/general/index-page-header";
import { ButtonGeneral } from "@components/general/button-general";
import { useAuth } from "@hooks/useAuth";
import { styles } from "./style";

interface PersonalInfoItemProps {
  question: string;
  answer: string;
}

const PersonalInfoItem = ({ question, answer }: PersonalInfoItemProps) => (
  <View style={styles.infoItem}>
    <View style={styles.infoItemLeft}>
      <Text style={styles.infoTitle}>{question}</Text>
    </View>
    <View style={styles.infoItemRight}>
      <Text
        style={styles.infoItemAnswer}
        numberOfLines={1}
        ellipsizeMode="tail"
      >
        {answer}
      </Text>
    </View>
  </View>
);

interface QuestionItemProps {
  question: string;
  answers: string[];
}

const QuestionItem = ({ question, answers }: QuestionItemProps) => (
  <View style={styles.questionItem}>
    <Text style={[styles.infoTitle, styles.questionItem]}>{question}</Text>
    {answers.map((answer) => (
      <View style={styles.answerItem}>
        <View style={styles.answerDot} />
        <Text style={styles.answerText}>{answer}</Text>
      </View>
    ))}
  </View>
);

const ProfileNative = () => {
  const { navigate, user } = useAuth();

  return (
    <Wrapper style={styles.wrap}>
      <IndexPageHeader title="Profile" onPressBack={() => navigate(-1)} />
      <ScrollView>
        <View style={styles.infoWrap}>
          <Text style={[styles.infoTitle, styles.titleMargin]}>
            Personal Info
          </Text>
          <PersonalInfoItem
            question="Name"
            answer={
              user?.accountInfo
                ? `${user.accountInfo.firstName} ${user.accountInfo.lastName}`
                : ""
            }
          />
          <PersonalInfoItem
            question="Email"
            answer={user?.accountInfo ? user.accountInfo.email : ""}
          />
          <PersonalInfoItem
            question="Phone no."
            answer={
              user?.accountInfo?.phoneNumber ? user.accountInfo.phoneNumber : ""
            }
          />
          <PersonalInfoItem
            question="Address"
            answer={
              user?.accountInfo
                ? `${user.accountInfo.postCode || ""} ${
                    user.accountInfo.country
                  }`
                : ""
            }
          />
        </View>
        <View style={styles.infoWrap}>
          <QuestionItem
            question="What's your biggest focus in the next 90 days?"
            answers={[
              "Marketing: Social Media",
              "Marketing: Content Marketing",
            ]}
          />
          <QuestionItem
            question="What are the 3 main reasons you joined TRN?"
            answers={[
              "To access experts and advisors for input into my strategy",
              "To see what others are doing and to benchmark my business and my approach against others",
              "To access different ideas",
            ]}
          />
        </View>
      </ScrollView>
      <View style={styles.editButtonWrap}>
        <ButtonGeneral
          label="Edit"
          onPress={() => navigate("/account/settings/profile/edit")}
        />
      </View>
    </Wrapper>
  );
};

export { ProfileNative };
