import React from "react";
import { Text, View } from "react-native";
import { ContentBox } from "@components/general/layouts/content-box";
import { PointerArrowRight } from "@components/general/icons/account-icons/PointerArrowRight";
import { useNavigate } from "react-router-native";
import { DocumentAsset, Partner } from "@gql/generated/generated";
import { styles } from "./style";
import { Tooltip } from "@components/general/tooltip";
import { Image } from "@components/general/image-from-storage";
import { TouchableOpacityLink } from "@components/general/touchable-opacity-link";

interface PartnerProps {
  image?: DocumentAsset | null;
  id: string;
  name: string;
  categories: string[];
  onPress?: () => void;
}

interface RelatedPartnersProps {
  partners: Partial<Partner>[];
}

const PartnerItem = ({ image, name, categories, id }: PartnerProps) => {
  const navigate = useNavigate();

  return (
    <TouchableOpacityLink
      style={styles.partner}
      href={`/marketplace/${id}`}
      onPress={() => navigate(`marketplace/${id}`)}
    >
      <Image path={image?.storagePath} style={styles.partnerImage} />
      <View style={{ flex: 1 }}>
        <Text style={styles.partnerName} numberOfLines={1} ellipsizeMode="tail">
          {name}
        </Text>
        <Tooltip text={categories.join(", ")} />
      </View>
    </TouchableOpacityLink>
  );
};

const RelatedPartners = ({ partners }: RelatedPartnersProps) => {
  const navigate = useNavigate();
  return (
    <ContentBox>
      <View style={styles.head}>
        <Text style={styles.title}>Partners</Text>
      </View>
      {partners
        ? partners.map(({ title, logo, id, categories }) => {
            if (!id) return null;
            return (
              <PartnerItem
                key={id}
                id={id}
                name={title as string}
                categories={categories || []}
                image={logo ?? undefined}
              />
            );
          })
        : null}

      <View style={styles.viewAll}>
        <TouchableOpacityLink
          style={styles.viewAllTouch}
          href="/marketplace"
          onPress={() => navigate("/marketplace")}
        >
          <Text style={styles.viewAllText}>View all Partners</Text>
          <PointerArrowRight />
        </TouchableOpacityLink>
      </View>
    </ContentBox>
  );
};

export { RelatedPartners };
