import React, { useCallback, useState } from "react";
import { Platform, Text, TouchableOpacity, View } from "react-native";
import { DatePicker as ShopifyDatePicker } from "@shopify/polaris";
import { Label, Props as LabelProps } from "@components/general/form/label";
import DateTimePicker, {
  DateTimePickerEvent,
} from "@react-native-community/datetimepicker";
import { styles } from "./style";

const today = new Date();

export interface Props extends LabelProps {
  value?: string;
  placeholder?: string;
  onChange: (dateString?: string) => void;
}

const DatePicker = ({
  label,
  value,
  placeholder,
  onChange,
  isOptional,
}: Props) => {
  const [showDateFrom, setShowDateFrom] = React.useState(false);

  const [{ pickerMonth, pickerYear }, setDate] = useState({
    pickerMonth: today.getMonth(),
    pickerYear: today.getFullYear(),
  });
  const [selectedDates, setSelectedDates] = useState({
    start: today,
    end: today,
  });

  const handleMonthChange = useCallback(
    (pickerMonth: any, pickerYear: any) => setDate({ pickerMonth, pickerYear }),
    []
  );
  const date = value ? new Date(value) : undefined;

  return (
    <View>
      {label ? <Label label={label} isOptional={isOptional} /> : null}
      <TouchableOpacity
        style={styles.touchItem}
        onPress={() => setShowDateFrom(!showDateFrom)}
      >
        <Text style={styles.touchText}>
          {date ? date.toDateString() : placeholder}
        </Text>
      </TouchableOpacity>
      {showDateFrom && (
        <>
          {Platform.OS === "web" ? (
            <ShopifyDatePicker
              month={pickerMonth}
              year={pickerYear}
              onChange={(range) => {
                onChange(range.start.toDateString());
                setSelectedDates(range);
                setShowDateFrom(false);
              }}
              onMonthChange={handleMonthChange}
              selected={selectedDates}
            />
          ) : (
            <DateTimePicker
              value={date || new Date()}
              mode="date"
              is24Hour
              onChange={(event: DateTimePickerEvent, date?: Date) =>
                onChange(date?.toDateString())
              }
              style={{ alignSelf: "flex-start", width: "40%" }}
            />
          )}
        </>
      )}
    </View>
  );
};

export { DatePicker };
