import React, { useState } from "react";
import { FlatList } from "react-native";
import { useNavigate } from "react-router-native";
import { Wrapper } from "@components/general/layouts/wrapper/Wrapper";
import { IndexPageHeader } from "@components/general/index-page-header";
import { NotificationSwitch } from "@components/account/notification-switch";
import { FilterListItem } from "@components/filter-mobile/filter-list-item";

interface IListOption {
  title: string;
  route: string;
}

const listOptions: IListOption[] = [
  {
    title: "Content",
    route: "content",
  },
  {
    title: "Events",
    route: "events",
  },
  {
    title: "Training",
    route: "training",
  },
  {
    title: "Assignment",
    route: "assignment",
  },
  {
    title: "Feed",
    route: "feed",
  },
  {
    title: "Saved Items",
    route: "saved-items",
  },
];

const NotificationSettingsNative = () => {
  const navigate = useNavigate();
  const [isNotificationOff, setIsNotificationOff] = useState(false);

  const keyExtractor = (item: IListOption) => item.route;

  const renderItem = ({ item }: { item: IListOption }) => (
    <FilterListItem
      isTextRegular
      title={item.title}
      onPress={() => navigate(item.route)}
    />
  );
  return (
    <Wrapper>
      <IndexPageHeader title="Notifications" onPressBack={() => navigate(-1)} />
      <NotificationSwitch
        title="Turn off all notifications"
        isActive={isNotificationOff}
        onChange={() => setIsNotificationOff(!isNotificationOff)}
      />
      <FlatList
        data={listOptions}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
      />
    </Wrapper>
  );
};

export { NotificationSettingsNative };
