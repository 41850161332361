import { Text, View, Switch } from "react-native";
import { useCallback, useState } from "react";
import { useFormikContext } from "formik";
import {
  ActionList,
  Button,
  Checkbox,
  DatePicker,
  InlineError,
  LegacyStack,
  Popover,
  Tag,
} from "@shopify/polaris";
import { styles } from "./style";
import { CategoryButton } from "@components/back-office/tables/category-button";
import dayjs from "dayjs";
import { joinItemsWithCommasAndAmpersand } from "@utils/misc";
import { FormikAutocompleteSelect } from "../formik-autocomplete-select";
import { DocumentContentType, PollStatus } from "@gql/generated/generated";
import { CONTENT_TYPES } from "../../../general/filters/constants";
import { StatusTag, TagStatus } from "../status-tag";
import { partnerTypeFilterCheckboxList, PartnerTypes } from "@utils/partners";

// Return these from the backend
const jobTitles = [
  { label: "Director/Owner", value: "Director/Owner" },
  { label: "Senior Manager", value: "Senior Manager" },
  {
    label: "Billing Manager/Team Leader",
    value: "Billing Manager/Team Leader",
  },
  { label: "Account Manager", value: "Account Manager" },
  {
    label: "Marketing - Heads of/Manager/Executive",
    value: "Marketing - Heads of/Manager/Executive",
  },
  {
    label: "Finance - Heads of/Manager/Executive",
    value: "Finance - Heads of/Manager/Executive",
  },
  {
    label: "Operations - Heads of/Manager/Executive",
    value: "Operations - Heads of/Manager/Executive",
  },
  {
    label: "Talent and L&D - Heads of/Manager/Executive",
    value: "Talent and L&D - Heads of/Manager/Executive",
  },
  {
    label: "HR - Heads of/Manager/Executive",
    value: "HR - Heads of/Manager/Executive",
  },
  { label: "Consultant", value: "Consultant" },
  { label: "Resourcer", value: "Resourcer" },
];

// Save in database and fetch for single source of truth
export enum UserTypes {
  All = "All",
  Plus = "Plus",
  Club = "Club",
  Free = "Free",
}

// Use Pick type of generic shared inital values
interface FormikPublishToolsValues {
  status: string;
  date: string;
  closeDate: number;
  userType: UserTypes[];
  partnerType: PartnerTypes[];
  categories: string[];
  contentTypes: DocumentContentType[];
  jobRoles: string[];
  pollStatus?: PollStatus;
  postType?: string;
}

interface FormikPublishToolsProps {
  withClosePoll?: boolean;
  withCategories?: boolean;
  withContentType?: boolean;
  withUserType?: boolean;
  withJobRoles?: boolean;
  withPostType?: boolean;
  withPartnerType?: boolean;
  withDate?: boolean;
}

export const FormikPublishTools = ({
  withCategories = true,
  withContentType,
  withUserType = true,
  withJobRoles = true,
  withClosePoll = false,
  withPostType = false,
  withPartnerType = false,
  withDate = false,
}: FormikPublishToolsProps) => {
  const { setFieldValue, values, errors, touched } =
    useFormikContext<FormikPublishToolsValues>();

  const [displayUserTypeModal, setDisplayUserTypeModal] = useState(false);
  const [displayPartnerTypeModal, setDisplayPartnerTypeModal] = useState(false);
  const [displayCategoryModal, setDisplayCategoryModal] = useState(false);
  const [displayStateModal, setDisplayStateModal] = useState(false);
  const [displayPostTypeModal, setDisplayPostTypeModal] = useState(false);
  const [displayDateModal, setDisplayDateModal] = useState(false);

  const oneYearFromNow = new Date();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const [{ month, year }, setDate] = useState({
    month: oneYearFromNow.getMonth(),
    year: oneYearFromNow.getFullYear(),
  });
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

  const handleMonthChange = useCallback(
    (month: any, year: any) => setDate({ month, year }),
    []
  );

  const handleUpdatePollCloseDate = () => {
    setFieldValue("closeDate", Date.now());
  };

  const verticalContentMarkup =
    values.categories && values.categories?.length > 0 ? (
      <LegacyStack spacing="extraTight" alignment="center">
        {values.categories.map((option: any, index: number) => {
          return (
            // @ts-ignore: Unreachable code error
            <Tag key={index}>{option}</Tag>
          );
        })}
      </LegacyStack>
    ) : null;

  const UserTypeButton = (
    <View style={{ marginBottom: 12 }}>
      <Text style={[styles.text, { marginBottom: 4 }]}>User Type</Text>
      <Button
        fullWidth
        textAlign="left"
        disclosure={displayUserTypeModal ? "up" : "down"}
        onClick={() => setDisplayUserTypeModal(!displayUserTypeModal)}
      >
        {values.userType?.length
          ? joinItemsWithCommasAndAmpersand(values.userType)
          : "Select"}
      </Button>
    </View>
  );

  const PartnerTypeButton = (
    <View style={{ marginBottom: 12 }}>
      <Text style={[styles.text, { marginBottom: 4 }]}>Tiers</Text>
      <Button
        fullWidth
        textAlign="left"
        disclosure={displayPartnerTypeModal ? "up" : "down"}
        onClick={() => setDisplayPartnerTypeModal(!displayPartnerTypeModal)}
      >
        {values.partnerType ?? "Free"}
      </Button>
    </View>
  );

  const StateButton = (
    <View style={{ marginBottom: 12 }}>
      <Button
        fullWidth
        textAlign="left"
        disclosure={displayStateModal ? "up" : "down"}
        onClick={() => setDisplayStateModal(!displayStateModal)}
      >
        {values.status || "State"}
      </Button>
    </View>
  );

  const PollStatusButton = (
    <View style={{ marginBottom: 12 }}>
      <Text style={{ ...styles.text, marginBottom: 10 }}>Poll Status</Text>
      <StatusTag
        status={
          values.status !== "Publish"
            ? TagStatus.Default
            : values.closeDate > Date.now()
            ? TagStatus.Active
            : TagStatus.Inactive
        }
      >
        {values.status !== "Publish"
          ? "N/A"
          : values.closeDate > Date.now()
          ? "Open"
          : "Closed"}
      </StatusTag>
      {values.status === "Publish" && values.closeDate > Date.now() ? (
        <View style={{ marginTop: 12 }}>
          <Text style={{ ...styles.text, marginBottom: 10 }}>Close Poll</Text>
          <Switch
            onValueChange={handleUpdatePollCloseDate}
            value={false}
            trackColor={{
              false: "#8B9197",
              true: "#2C6ECB",
            }}
          />
        </View>
      ) : null}
    </View>
  );

  const PostTypeButton = (
    <View style={{ marginBottom: 12 }}>
      <Text style={[styles.text, { marginBottom: 4 }]}>Type</Text>
      <Button
        fullWidth
        textAlign="left"
        disclosure={displayPostTypeModal ? "up" : "down"}
        onClick={() => setDisplayPostTypeModal(!displayPostTypeModal)}
      >
        {values.postType || "Select"}
      </Button>
    </View>
  );

  const DateButton = (
    <View style={{ marginBottom: 12 }}>
      <Button
        fullWidth
        textAlign="left"
        disclosure={displayDateModal ? "up" : "down"}
        onClick={() => setDisplayDateModal(!displayDateModal)}
      >
        {values.date ? dayjs(values.date).format("D MMM YYYY") : "Date"}
      </Button>
    </View>
  );

  const handleSelectUserType = (v: UserTypes) => {
    const { userType } = values;

    const isValueAll = v === UserTypes.All;

    if (userType.includes(v)) {
      if (isValueAll) {
        setFieldValue("userType", []);
      } else {
        setFieldValue(
          "userType",
          userType.filter((t) => t !== v)
        );
      }
    } else {
      if (isValueAll) {
        setFieldValue("userType", [v]);
      } else {
        setFieldValue("userType", [...userType, v]);
      }
    }
  };

  const handleSelectPartnerType = (v: PartnerTypes) => {
    setFieldValue("partnerType", v);
  };

  const handleStatusChange = (value: string) => {
    setDisplayStateModal(!displayStateModal);
    setFieldValue("status", value);
  };

  const handlePostTypeChange = (value: string) => {
    setDisplayPostTypeModal(!displayPostTypeModal);
    setFieldValue("postType", value);
  };

  return (
    <View style={styles.publishTools}>
      <Text style={[styles.subTitle, { marginBottom: 20 }]}>Publish Tools</Text>
      <Popover
        fullWidth
        active={displayStateModal}
        activator={StateButton}
        onClose={() => setDisplayStateModal(!displayStateModal)}
      >
        <ActionList
          actionRole="menuitem"
          items={[
            {
              content: "Publish",
              onAction: () => handleStatusChange("Publish"),
            },
            {
              content: "Draft",
              onAction: () => handleStatusChange("Draft"),
            },
            {
              content: "Archive",
              onAction: () => handleStatusChange("Archive"),
            },
          ]}
        />
      </Popover>
      {withClosePoll ? PollStatusButton : null}
      {withDate ? (
        <>
          <Popover
            fullWidth
            active={displayDateModal}
            activator={DateButton}
            onClose={() => setDisplayDateModal(!displayDateModal)}
          >
            <View style={{ paddingHorizontal: 20, paddingVertical: 10 }}>
              <DatePicker
                disableDatesBefore={yesterday}
                disableDatesAfter={
                  values.status === "Publish" ? new Date() : undefined
                }
                month={month}
                year={year}
                onChange={(dates: any) => {
                  setFieldValue("date", dates.start);
                  setDisplayDateModal(!displayDateModal);
                }}
                onMonthChange={handleMonthChange}
              />
            </View>
          </Popover>
          {typeof errors?.date === "string" && touched?.date && (
            <InlineError message={errors.date} fieldID="date" />
          )}
        </>
      ) : null}
      {withCategories ? (
        <>
          <Text style={[styles.text, { marginBottom: 4 }]}>Category</Text>
          <View style={{ marginBottom: 12 }}>
            <CategoryButton
              setSelectedCat={(v) => setFieldValue("categories", v)}
              selectedCat={values.categories}
              isEditor
              placeholderText="Select"
              displayCategoryModal={displayCategoryModal}
              setDisplayCategoryModal={setDisplayCategoryModal}
            />
            {typeof errors?.categories === "string" && touched?.categories && (
              <InlineError message={errors.categories} fieldID="categories" />
            )}
            <View style={{ marginTop: 8 }}>{verticalContentMarkup}</View>
          </View>
        </>
      ) : null}

      {withUserType ? (
        <Popover
          fullWidth
          active={displayUserTypeModal}
          activator={UserTypeButton}
          onClose={() => setDisplayUserTypeModal(!displayUserTypeModal)}
        >
          <ActionList
            actionRole="menuitem"
            items={[
              {
                // @ts-ignore FIXME: expects string
                content: (
                  <Checkbox
                    label="Free"
                    disabled={values.userType?.includes(UserTypes.All)}
                    checked={values.userType?.includes(UserTypes.Free)}
                    onChange={() => handleSelectUserType(UserTypes.Free)}
                  />
                ),
              },
              {
                // @ts-ignore FIXME: expects string
                content: (
                  <Checkbox
                    label="Plus"
                    disabled={values.userType?.includes(UserTypes.All)}
                    checked={values.userType?.includes(UserTypes.Plus)}
                    onChange={() => handleSelectUserType(UserTypes.Plus)}
                  />
                ),
              },
              {
                // @ts-ignore FIXME: expects string
                content: (
                  <Checkbox
                    label="Club"
                    disabled={values.userType?.includes(UserTypes.All)}
                    checked={values.userType?.includes(UserTypes.Club)}
                    onChange={() => handleSelectUserType(UserTypes.Club)}
                  />
                ),
              },
            ]}
          />
        </Popover>
      ) : null}

      {withJobRoles ? (
        <FormikAutocompleteSelect
          options={jobTitles}
          label="Relevant Job Roles"
          fieldName="jobRoles"
          multiSelect
        />
      ) : null}

      {withContentType ? (
        <FormikAutocompleteSelect
          options={CONTENT_TYPES}
          label="Content Type"
          fieldName="contentTypes"
          multiSelect
        />
      ) : null}

      {withPartnerType ? (
        <Popover
          fullWidth
          active={displayPartnerTypeModal}
          activator={PartnerTypeButton}
          onClose={() => setDisplayPartnerTypeModal(!displayPartnerTypeModal)}
        >
          <ActionList
            actionRole="menuitem"
            items={
              partnerTypeFilterCheckboxList(
                values.partnerType,
                handleSelectPartnerType
              )
            }
          />
        </Popover>
      ) : null}

      {withPostType ? (
        <Popover
          fullWidth
          active={displayPostTypeModal}
          activator={PostTypeButton}
          onClose={() => setDisplayPostTypeModal(!displayPostTypeModal)}
        >
          <ActionList
            actionRole="menuitem"
            items={[
              {
                content: "Announcement",
                onAction: () => handlePostTypeChange("Announcement"),
              },
              {
                content: "News",
                onAction: () => handlePostTypeChange("News"),
              },
            ]}
          />
        </Popover>
      ) : null}
    </View>
  );
};
