import React from "react";
import { Text, View } from "react-native";
import { ModalWrapper } from "@components/general/modals/modal-wrapper";
import { ButtonGeneral } from "@components/general/button-general";
import { styles } from "./style";

interface Props {
  isVisible: boolean;
  onClose: () => void;
}

const DeletedAccountModal = ({ isVisible, onClose }: Props) => (
  <ModalWrapper isVisible={isVisible}>
    <View style={styles.wrapper}>
      <Text style={styles.title}>Your account has been deleted</Text>
      <Text style={styles.subtitle}>
        Please let us know if there is anything else we can do for you.
      </Text>
      <ButtonGeneral
        label="Back to home"
        onPress={onClose}
        style={styles.button}
      />
    </View>
  </ModalWrapper>
);

export { DeletedAccountModal };
