import StyleSheet from "react-native-media-query";
import { Platform } from "react-native";
import { mediaQuery } from "@utils/misc";

export const { ids, styles } = StyleSheet.create({
  wrapper: {
    paddingTop: 20,
    paddingBottom: 20,
    minHeight: Platform.OS === "web" ? "90vh" : "100%",
  },
  contentBox: {
    alignItems: "center",
    paddingTop: 60,
    paddingBottom: 60,
    paddingLeft: 80,
    paddingRight: 80,
    height: "100%",
    [mediaQuery.forTabletDown]: {
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  titleWrap: {
    alignItems: "center",
    marginBottom: 80,
  },
  title: {
    fontSize: 26,
    marginBottom: 20,
    color: "#0D1738",
    fontFamily: "OpenSans_600SemiBold",
  },
  description: {
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
    color: "#0D1738",
    textAlign: "center",
    maxWidth: "60%",
    lineHeight: 20,
  },
  blockWrap: {
    flexDirection: "row",
    flexWrap: "wrap",
    ...(Platform.OS === "web" && { gap: 8 }),
    width: "100%",
    marginBottom: 80,
    [mediaQuery.forTabletDown]: {
      justifyContent: "center",
    },
  },
  priorities: {
    borderTopWidth: 4,
    borderTopColor: 'rgb(13, 23, 56)',
    backgroundColor: 'white',
    borderRadius: 4,
    padding: 20,
    shadowColor: "rgba(0, 0, 0, 0.15)",
    shadowRadius: 4,
    shadowOffset: { height: 0.5, width: 0 },
    width: '50%',
    minWidth: 334,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 60,
  },
  prioritiesTitle: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 28,
    color: 'rgb(13, 23, 56)',
  },
  prioritiesSubtitle: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    color: 'rgb(13, 23, 56)',
  },
  prioritiesIcon: {
    backgroundColor: "#0D1738",
    height: 40,
    width: 40,
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
  }
});
