export const convertSecondsToDisplay = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);

  let str = "";

  if (hours > 0) {
    if (hours === 1) {
      str = str + `${hours} hour`;
    } else {
      str = str + `${hours} hours`;
    }
  }

  if (minutes > 0) {
    if (minutes === 1) {
      str = str + ` ${minutes} min`;
    } else {
      str = str + ` ${minutes} mins`;
    }
  }

  if (secs > 0) {
    if (secs === 1) {
      str = str + ` ${secs} sec`;
    } else {
      str = str + ` ${secs} secs`;
    }
  }

  return str.trim();
};
