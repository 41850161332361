import { User } from "firebase/auth";
import { auth } from "../firebase";
import { useEffect, useState } from "react";

const useGetAuthTokenAndUserId = () => {
  const [userId, setUserId] = useState<string>("");
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        setUser(user);
        setUserId(user.uid);
      } else {
        setUserId("");
        setUser(null);
      }
    });
  }, []);

  return {
    userId,
    user,
  };
};

export { useGetAuthTokenAndUserId };
