import React, { useEffect, useState } from "react";
import { ActivityIndicator, Text, TouchableOpacity, View } from "react-native";
import { useLocation, useNavigate, useParams } from "react-router-native";
import { SearchTab, SearchTabItem } from "@components/search/search-tab";
import { Wrapper } from "@components/general/layouts/wrapper/Wrapper";
import { ContentBox } from "@components/general/layouts/content-box";
import { useAlgoliaSearch } from "../useAlgoliaSearch";
import { SearchItemType } from "@pages/search/search-item-type";
import { styles } from "./style";

const SearchResults = () => {
  const [activeTab, setActiveTab] = useState(SearchTabItem.all);

  const [topSuggestion, setTopSuggestion] = useState("");
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { query } = useParams<{ query: string }>();

  const {
    eventsResults,
    contentResults,
    trainingsResults,
    results,
    querySuggestions,
    isLoading,
  } = useAlgoliaSearch({
    query: pathname === "/search/suggestions" ? topSuggestion : query,
    hitsPerPage: 100,
  });

  useEffect(() => {
    setTopSuggestion(querySuggestions[0]?.query);
  }, [querySuggestions]);

  const getResultsFromActiveTab = () => {
    switch (activeTab) {
      case SearchTabItem.all:
        return results;
      case SearchTabItem.content:
        return contentResults;
      case SearchTabItem.training:
        return trainingsResults;
      case SearchTabItem.events:
        return eventsResults;
      default:
        return results;
    }
  };

  return (
    <Wrapper style={styles.wrap}>
      <ContentBox>
        <View style={styles.headWrap}>
          <View style={styles.titleWrap}>
            {pathname === "/search/suggestions" ? (
              <Text style={styles.title}>Suggestions</Text>
            ) : (
              <Text style={styles.title}>
                Result for <Text style={styles.titleBold}>“{query}”</Text>
              </Text>
            )}
            <TouchableOpacity onPress={() => navigate("/")}>
              <Text style={styles.backText}>Back to Dashboard</Text>
            </TouchableOpacity>
          </View>
          <SearchTab
            active={activeTab}
            onTabPress={(tab: SearchTabItem) => setActiveTab(tab)}
            contentCount={contentResults.length}
            eventsCount={eventsResults.length}
            trainingsCount={trainingsResults.length}
          />
        </View>
        <View style={styles.tabContent}>
          {isLoading ? (
            <View style={{ padding: 12 }}>
              <ActivityIndicator size="large" color="#213470" />
            </View>
          ) : null}
          {results.length > 0
            ? getResultsFromActiveTab().map((searchResult) => {
                return (
                  <SearchItemType
                    hasPadding
                    key={searchResult.objectID}
                    query={query}
                    searchResult={searchResult}
                  />
                );
              })
            : null}
        </View>
      </ContentBox>
    </Wrapper>
  );
};

export { SearchResults };
