import React, { useState } from "react";
import { LayoutChangeEvent, Text, View } from "react-native";
import { SelectOption } from "@components/general/form/select-option";
import { ContentBox } from "@components/general/layouts/content-box";
import { LineChart } from "react-native-chart-kit";
import { ids, styles } from "./style";

export const defaultLineChartStyles = {
  propsForBackgroundLines: {
    strokeDasharray: "",
    stroke: "#EDEEEF",
  },
  useShadowColorFromDataset: true,
  fillShadowGradientOpacity: 0.5,
  backgroundColor: "#FFFFFF",
  backgroundGradientFrom: "#FFFFFF",
  backgroundGradientTo: "#FFFFFF3D",
  decimalPlaces: 0,
  color: () => "#6D7175",
  labelColor: () => "#6D7175",
  style: {
    borderRadius: 16,
  },
  propsForDots: {
    r: "0",
  },
};

interface TagItemProps {
  color: string;
  title: string;
}

interface Props {
  selected: string;
  setSelected: (val: string) => void;
  overviewData?: {
    labels?: string[];
    newMembers: number[];
    activeMembers: number[];
    averageTime: number[];
    displayLabels?: string[];
  };
  refetch: (days: number) => void;
}

const TagItem = ({ title, color }: TagItemProps) => (
  <View style={styles.tag}>
    <View style={[styles.tagCircle, { backgroundColor: color }]} />
    <Text style={styles.tagText}>{title}</Text>
  </View>
);

const AnalyticsMembersOverview = ({
  selected,
  setSelected,
  overviewData,
  refetch,
}: Props) => {
  const [chartWidth, setChartWidth] = useState(300);
  const chartData = {
    labels: overviewData?.displayLabels || [],
    datasets: [
      {
        data: overviewData?.newMembers || [],
        color: () => "#FFC96B",
        strokeWidth: 2,
      },
      {
        data: overviewData?.activeMembers || [],
        color: () => "#41D5B8",
        strokeWidth: 2,
      },
      {
        data: overviewData?.averageTime || [],
        color: () => "#1653CE",
        strokeWidth: 2,
      },
    ],
  };

  const onChartWrapLayoutChange = (event: LayoutChangeEvent) => {
    const { width } = event.nativeEvent.layout;
    setChartWidth(width);
  };

  return (
    <View style={styles.wrap} dataSet={{ media: ids.wrap }}>
      <View style={styles.headWrap} dataSet={{ media: ids.headWrap }}>
        <View style={styles.titleWrap} dataSet={{ media: ids.titleWrap }}>
          <Text style={styles.title}>Overview</Text>
          <View style={styles.tagsWrap} dataSet={{ media: ids.tagsWrap }}>
            <TagItem color="#41D5B8" title="Active Members" />
            <TagItem color="#FFC96B" title="New Members" />
            <TagItem color="#1653CE" title="Avg. Time Spent" />
          </View>
        </View>
        <View style={styles.selectWrap}>
          <SelectOption
            onChange={(value) => {
              setSelected(value);
              refetch(value === "week" ? 8 : new Date().getDate() + 1);
            }}
            value={selected}
            options={[
              { label: "This Week", value: "week" },
              { label: "This Month", value: "month" },
            ]}
          />
        </View>
      </View>
      <ContentBox style={styles.contentBox} dataSetMedia={ids.contentBox}>
        <View style={styles.contentInner} onLayout={onChartWrapLayoutChange}>
          <LineChart
            data={chartData}
            width={chartWidth}
            height={220}
            yAxisSuffix="%"
            withVerticalLines={false}
            chartConfig={defaultLineChartStyles}
          />
        </View>
      </ContentBox>
    </View>
  );
};

export { AnalyticsMembersOverview };
