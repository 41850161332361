import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  head: {
    padding: 20,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    fontSize: 20,
    color: "#0D1738",
    fontFamily: "OpenSans_600SemiBold",
  },
  selectWrap: {
    width: 160,
  },
  tableWrap: {
    overflow: "scroll",
  },
  tableInner: {
    minWidth: 800,
  },
  cellWrap: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
    justifyContent: "space-between",
  },
  itemCellTwo: {
    width: "20%",
  },
  itemCell: {
    width: "9.4%",
  },
  firstItemCell: {
    width: "3%",
  },
  middleCell: {
    width: "15%",
    paddingLeft: 4,
  },
  lastItemCell: {
    width: "5%",
    alignItems: "flex-end",
  },
  cellTitle: {
    fontSize: 12,
    fontFamily: "OpenSans_600SemiBold",
    color: "#6D7175",
  },
  itemRow: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 16,
    paddingRight: 16,
    width: "100%",
    borderTopWidth: 1,
    borderTopColor: "#EDEEEF",
    justifyContent: "space-between",
  },
  boldCellText: {
    fontSize: 14,
    color: "#0D1738",
    fontFamily: "OpenSans_600SemiBold",
    width: "95%",
  },
  cellText: {
    fontSize: 14,
    color: "#0D1738",
    fontFamily: "OpenSans_400Regular",
  },
  cellTextTwo: {
    fontSize: 14,
    color: "#6D7175",
    fontFamily: "OpenSans_400Regular",
  },
});
