import { Text, TouchableOpacity, View } from "react-native";
import { styles } from "./style";
import { FormikInput } from "../formik-input";
import { FormikTextArea } from "../formik-text-area/FormikTextArea";
import { Col } from "@components/general/col";
import { Row } from "@components/general/row";
import { textStyles } from "@styles/text";
import { LessonTypeSelect } from "./lesson-type-select/LessonTypeSelect";
import { AccessSelect } from "./access-select";
import {
  CollectionName,
  Lesson,
  useDeleteAssetMutation,
} from "@gql/generated/generated";
import {
  ChevronIcon,
  MenuIcon,
  TrashIcon,
} from "@components/general/icons/org-icons";
import { Collapsible } from "@shopify/polaris";
import { useState } from "react";
import { AddAttachments } from "../add-attachments";
import { FileUploader } from "../uploader";
import { HtmlParser } from "@components/general/html-parser";
import { useFormikContext } from "formik";
import { DeleteConfirmationModal } from "../delete-confirmation-modal";

const Tab = ({
  onPress,
  isActive,
  label,
}: {
  onPress: () => void;
  isActive: boolean;
  label: string;
}) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <View
        style={{
          paddingVertical: 6,
          paddingHorizontal: 12,
          borderRadius: 2,
          backgroundColor: isActive ? "#2C6ECB" : "#ebedf0",
        }}
      >
        <Text
          style={{
            color: isActive ? "white" : "#616a75",
          }}
        >
          {label}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export const TrainingLesson = ({
  lessonIndex,
  lesson,
  moduleIndex,
  onDeleteLesson,
  trainingId,
  moduleId,
}: {
  lessonIndex: number;
  moduleIndex: number;
  lesson: Lesson;
  onDeleteLesson: () => void;
  trainingId: string;
  moduleId: string;
}) => {
  const formikFieldBase = `modules[${moduleIndex}].lessons[${lessonIndex}]`;
  const [lessonOpen, setLessonOpen] = useState<boolean>(false);
  const [showWarningModal, setShowWarningModal] = useState(false);

  const [videoType, setVideoType] = useState<"upload" | "embed">(
    lesson.videoFile?.__typename === "EmbeddedVimeo" ? "embed" : "upload"
  );

  const embeddedVimeo =
    lesson.videoFile?.__typename === "EmbeddedVimeo" &&
    lesson.videoFile.content;

  const { setFieldValue } = useFormikContext<any>();

  const [deleteAssetMutation] = useDeleteAssetMutation();

  const handleChangeVideoType = (type: "upload" | "embed") => {
    if (type === videoType) return;
    if (
      !lesson.videoFile ||
      (lesson.videoFile.__typename === "EmbeddedVimeo" &&
        !lesson.videoFile.content)
    ) {
      setVideoType(type);
    } else {
      setShowWarningModal(true);
    }
  };

  const handleRemoveVideo = async () => {
    setVideoType(videoType === "upload" ? "embed" : "upload");
    setFieldValue(`${formikFieldBase}.videoFile`, null);
    if (lesson.videoFile?.__typename === "DocumentAsset") {
      await deleteAssetMutation({
        variables: {
          id: lesson.videoFile.id,
        },
      });
    }
    setShowWarningModal(false);
  };

  return (
    <View>
      <TouchableOpacity
        onPress={() => setLessonOpen(!lessonOpen)}
        style={{
          ...styles.flexRowLesson,
          backgroundColor: "#BABEC3",
        }}
      >
        <Row style={{ alignItems: "center" }}>
          <MenuIcon />
          <Text style={styles.title}>Lesson {lessonIndex + 1}</Text>
        </Row>
        <View style={[styles.flexRowGap]}>
          <TouchableOpacity
            onPress={(e: any) => {
              e.stopPropagation();
              e.preventDefault();
              onDeleteLesson();
            }}
          >
            <TrashIcon fill="#0D1738" />
          </TouchableOpacity>
          <View
            style={{ transform: [{ rotate: lessonOpen ? "180deg" : "0deg" }] }}
          >
            <ChevronIcon />
          </View>
        </View>
      </TouchableOpacity>
      <Collapsible
        open={lessonOpen}
        id="basic-collapsible"
        transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
        expandOnPrint
      >
        <View style={[styles.flexRow]}>
          <Col
            style={{
              backgroundColor: "#F6F6F7",
              paddingVertical: 16,
              paddingHorizontal: 20,
              width: "100%",
            }}
          >
            <FormikInput
              fieldName={`${formikFieldBase}.lessonTitle`}
              label="Title"
            />
            <FormikTextArea
              fieldName={`${formikFieldBase}.lessonDescription`}
              label="Description"
            />
            <Row
              style={{ justifyContent: "space-between", marginVertical: 16 }}
            >
              <LessonTypeSelect formikFieldBase={formikFieldBase} />

              <AccessSelect formikFieldBase={formikFieldBase} />
            </Row>
            <Collapsible
              open={lesson?.lessonType === "video"}
              id="basic-collapsible"
              transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
              expandOnPrint
            >
              <View>
                <Text style={[textStyles.semiBoldSmall]}>Add video</Text>
                <Text
                  style={[textStyles.subduedTextMedium, { marginBottom: 4 }]}
                >
                  Select a video file from your computer or embed content from
                  vimeo
                </Text>
                <View style={{ flexDirection: "row", marginTop: 12 }}>
                  <Tab
                    label="Upload video"
                    onPress={() => handleChangeVideoType("upload")}
                    isActive={videoType === "upload"}
                  />
                  <Tab
                    label="Embed video"
                    onPress={() => handleChangeVideoType("embed")}
                    isActive={videoType === "embed"}
                  />
                </View>
                <View
                  style={{
                    backgroundColor: "white",
                    padding: 8,
                    borderRadius: 4,
                  }}
                >
                  {videoType === "upload" ? (
                    <FileUploader
                      initialAsset={
                        lesson.videoFile?.__typename === "DocumentAsset"
                          ? lesson.videoFile
                          : null
                      }
                      assetInstruction={{
                        instructionType: "one-to-one",
                        collectionId: CollectionName.TRAININGS,
                        documentId: `${trainingId}/Modules/${moduleId}/Lessons/${lesson.id}`,
                        key: "videoFile",
                      }}
                      actionHint="All files should be at least 720p and less than 4.0 GB."
                      validFileTypes={["video/mp4", "video/webm"]}
                      fileName={{
                        placeholder: "Add video title",
                        title: "Title",
                      }}
                      formikFieldname={`${formikFieldBase}.videoFile`}
                    />
                  ) : null}

                  {videoType === "embed" ? (
                    <View>
                      <Text
                        style={[
                          textStyles.semiBoldSmall,
                          { marginBottom: "4px" },
                        ]}
                      >
                        Embed content from Vimeo
                      </Text>
                      <>
                        <textarea
                          id={`${formikFieldBase}.videoFile`}
                          style={styles.vimeoTextarea}
                          name={`${formikFieldBase}.videoFile`}
                          rows={4}
                          value={
                            lesson.videoFile?.__typename === "EmbeddedVimeo"
                              ? lesson.videoFile.content
                              : ""
                          }
                          onChange={(event) => {
                            const { value } = event.target;
                            setFieldValue(`${formikFieldBase}.videoFile`, {
                              __typename: "EmbeddedVimeo",
                              content: value,
                            });
                          }}
                        />

                        {embeddedVimeo ? (
                          <View style={{ marginTop: 20 }}>
                            <FormikInput
                              fieldName={`${formikFieldBase}.videoFile.duration`}
                              label="Video duration in seconds"
                              type="number"
                              min="0"
                            />
                            <View style={{ marginTop: 20 }}>
                              <Text
                                style={[
                                  textStyles.semiBoldSmall,
                                  { marginBottom: "4px" },
                                ]}
                              >
                                Video
                              </Text>
                              <HtmlParser
                                htmlString={
                                  lesson.videoFile?.__typename ===
                                  "EmbeddedVimeo"
                                    ? lesson.videoFile.content
                                    : ""
                                }
                              />
                            </View>
                          </View>
                        ) : null}
                      </>
                    </View>
                  ) : null}
                </View>
              </View>
            </Collapsible>
            <Collapsible
              open={!!lesson?.lessonType?.includes("document")}
              id="basic-collapsible"
              transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
              expandOnPrint
            >
              <View>
                <Text style={[textStyles.semiBoldSmall]}>Add document</Text>
                <Text
                  style={[textStyles.subduedTextMedium, { marginBottom: 4 }]}
                >
                  Select a document from your computer and drop it in the field.
                </Text>
                <FileUploader
                  initialAsset={lesson.documentFile}
                  assetInstruction={{
                    instructionType: "one-to-one",
                    collectionId: CollectionName.TRAININGS,
                    documentId: `${trainingId}/Modules/${moduleId}/Lessons/${lesson.id}`,
                    key: "documentFile",
                  }}
                  actionHint="All files should be less than 50 MB."
                  validFileTypes={[
                    "application/msword",
                    "application/pdf",
                    "application/vnd.ms-excel",
                    "application/vnd.ms-powerpoint",
                    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                  ]}
                  fileName={{
                    title: "Title",
                    placeholder: "Add document title",
                  }}
                  formikFieldname={`${formikFieldBase}.documentFile`}
                />
              </View>
            </Collapsible>
            <FormikTextArea
              fieldName={`${formikFieldBase}.lessonContent`}
              label="Content"
              placeholder="Add your lesson content"
            />

            <Collapsible
              open={lesson?.lessonType === "document"}
              id="basic-collapsible"
              transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
              expandOnPrint
            >
              <View style={{ marginTop: 20 }}>
                <AddAttachments
                  title="Additional attachments"
                  initialExistingFiles={lesson.downloadFiles ?? null}
                  assetInstruction={{
                    instructionType: "one-to-many",
                    collectionId: CollectionName.TRAININGS,
                    documentId: `${trainingId}/Modules/${moduleId}/Lessons/${lesson.id}`,
                    key: "downloadFiles",
                  }}
                />
              </View>
            </Collapsible>
          </Col>
        </View>
      </Collapsible>
      <DeleteConfirmationModal
        title="This action will delete your existing video"
        onDeleteConfirm={handleRemoveVideo}
        visible={showWarningModal}
        onClose={() => setShowWarningModal(false)}
      />
    </View>
  );
};
