import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { ISvgProps } from "types";

const ChevronUpIcon = ({ color = "#5C5F62", ...props }: ISvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={7}
    fill="none"
    {...props}
  >
    <Path
      fill={color}
      d="M11 7a.997.997 0 0 1-.707-.293L6 2.414 1.707 6.707A.999.999 0 1 1 .293 5.293l5-5a.999.999 0 0 1 1.414 0l5 5A.999.999 0 0 1 11 7z"
    />
  </Svg>
);

export { ChevronUpIcon };
