import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  lowerView: {
    padding: 20,
    paddingBottom: 0,
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
    marginBottom: 30,
  },
  lowerViewDeregister: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  lowerViewText: {
    color: "#0D1738",
    fontSize: 16,
    fontFamily: "OpenSans_400Regular",
  },
  lowerViewTextDeregister: {
    lineHeight: 0,
  },
  lowerViewLink: {
    color: "blue",
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
    textDecorationLine: "underline",
  },
});
