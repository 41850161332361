import StyleSheet from "react-native-media-query";
import { mediaQuery } from "@utils/misc";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: "100%",
    paddingTop: 40,
    [mediaQuery.forMobile]: {
      paddingTop: 20,
    },
  },
  title: {
    color: "#0D1738",
    fontSize: 20,
    fontFamily: "OpenSans_600SemiBold",
    marginBottom: 20,
  },
  description: {
    color: "#0D1738",
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
  },
  video: {
    width: "100%",
    marginBottom: 18,
    position: "relative",
  },
  playIconWrap: {
    display: "none",
    height: 45,
    width: 45,
    borderRadius: 22.5,
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderColor: "#B5B5B5",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    left: "50%",
    top: "40%",
  },
  videoWrap: {
    alignItems: "center",
    justifyContent: "center",
  },
  document: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: 290,
  },
});
