import React from "react";
import { Image, Text, View } from "react-native";
import { useNavigate } from "react-router-native";
import { ContentBox } from "@components/general/layouts/content-box";
import { HelpIcon } from "@components/general/icons/account-icons/HelpIcon";
import { PlusCircleIcon } from "@components/general/icons/feed-icons/PlusCircleIcon";
import { styles } from "./style";
import { useUserContext } from "@context/UserContext";
import { ItemType } from "@gql/generated/generated";
import { userProfileImage } from "@utils/userProfileImage";
import { Assignments } from "@components/general/icons/account-icons/Assignment";
import { TouchableOpacityLink } from "@components/general/touchable-opacity-link";

interface LinkTextProps {
  title: string;
  onPress?: () => void;
  underline?: boolean;
  isBold?: boolean;
  href?: string;
}

const LinkText = ({
  title,
  onPress,
  href,
  underline,
  isBold,
}: LinkTextProps) => (
  <TouchableOpacityLink onPress={onPress} href={href}>
    <Text
      style={[
        isBold ? styles.boldLink : styles.normalText,
        styles.linkText,
        underline && styles.underlineLink,
      ]}
    >
      {title}
    </Text>
  </TouchableOpacityLink>
);

interface MenuItemProps {
  title: string;
  hasSubItems?: boolean;
  linkText?: string;
  href?: string;
  onPressLink?: () => void;
  content?: React.ReactNode;
  subtitle?: string;
  hasBoldLink?: boolean;
  onPress?: () => void;
}

const MenuItem = ({
  title,
  linkText,
  href,
  onPressLink,
  content,
  hasSubItems = false,
  subtitle,
  hasBoldLink,
  onPress,
}: MenuItemProps) => {
  const children = (
    <>
    <View style={styles.menuTitleWrap}>
      <Text style={styles.boldText}>{title}</Text>
      {linkText && (
        <LinkText
          title={linkText}
          href={href}
          onPress={onPressLink}
          isBold={hasBoldLink}
        />
      )}
    </View>
    {subtitle && <Text style={styles.normalText}>{subtitle}</Text>}
    {content}
    </>
  );

  return hasSubItems ? (
    <View style={styles.menuItemWrap}>{children}</View>
  ) : (
    <TouchableOpacityLink
      style={styles.menuItemWrap}
      onPress={() => !hasSubItems && onPress && onPress()}
      href={href}
    >
      {children}
  </TouchableOpacityLink>
);
};

interface NavItemProps {
  title: string;
  icon?: React.ReactNode;
  href?: string;
  onPress?: () => void;
}
const NavItem = ({ title, icon, onPress, href }: NavItemProps) => (
  <TouchableOpacityLink onPress={onPress} href={href} style={styles.navItem}>
    {icon}
    <Text style={styles.navItemTitle}>{title}</Text>
  </TouchableOpacityLink>
);

const SideProfile = () => {
  const navigate = useNavigate();
  const { currentUser } = useUserContext();

  const { accountInfo, recentlyInteractedItems, subscriptionDetails, role } =
    currentUser || {};
  const { firstName, lastName, companyInfo } = accountInfo || {};
  const { jobPosition } = companyInfo || {};
  const { subscriptionPlanAsText } = subscriptionDetails || {};
  const { isEmployer } = role || {};

  return (
    <ContentBox>
      <View style={styles.head}>
        <Image source={userProfileImage(currentUser)} style={styles.image} />
        <View style={styles.memberBox}>
          <Text style={[styles.normalText, styles.memberText]}>
            {subscriptionPlanAsText?.toUpperCase()}
          </Text>
        </View>
        {isEmployer && (
          <Text style={[styles.normalText, { textAlign: "center" }]}>
            Admin
          </Text>
        )}
        <Text style={[styles.boldText, styles.name]}>
          {firstName + " " + lastName}
        </Text>
        <Text style={[styles.normalText, { textAlign: "center", marginLeft: 8, marginRight: 8 }]}>
          {jobPosition}
        </Text>
      </View>
      <MenuItem
        title="Account"
        subtitle="Manage your account"
        href="/account/settings"
        onPress={() => navigate("/account/settings")}
      />
      <MenuItem
        title="Organisation"
        subtitle="Manage my organisation"
        hasBoldLink
        href="/org/assignment"
        onPress={() => navigate("/org/assignment")}
      />
      {recentlyInteractedItems && recentlyInteractedItems.length > 0 ? (
        <MenuItem
          title="Recently Interacted"
          hasSubItems
          content={
            <>
              {recentlyInteractedItems.map(
                ({ itemTitle, itemId, itemType }) => {
                  const linkPath = `${
                    itemType === ItemType.TRAININGS
                      ? "training"
                      : itemType.toLowerCase()
                  }/${itemId}`;
                  return (
                    <LinkText
                      key={itemId}
                      underline
                      title={itemTitle}
                      href={linkPath}
                      onPress={() => navigate(linkPath)}
                    />
                  );
                }
              )}
            </>
          }
        />
      ) : null}

      <NavItem
        title="Saved Items"
        icon={<PlusCircleIcon />}
        href="/org/saved"
        onPress={() => navigate("/org/saved")}
      />
      <NavItem
        title="Assignments"
        icon={<Assignments style={styles.assignmentIcon} />}
        href="/org/assignment"
        onPress={() => navigate("/org/assignment")}
      />
      <NavItem
        title="Help"
        icon={<HelpIcon />}
        href="/account/help"
        onPress={() => navigate("/account/help")}
      />
    </ContentBox>
  );
};

export { SideProfile };
