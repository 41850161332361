import { useState } from "react";
import { Linking, Text, View } from "react-native";
import { styles } from "./styles";
import { MenuContainer } from "../menu-container/MenuContainer";
import { GoCardLessEdit } from "./go-card-less/go-card-less-edit/GoCardLessEdit";
import { ButtonLayout } from "@pages/account/profile/button-layout/ButtonLayout";
import { FormikValues, useFormikContext } from "formik";
import { GoCardLessInfo } from "./go-card-less/go-card-less-info/GoCardLessInfo";
import { GoCardlessCustomer } from "@gql/generated/generated";
import { DirectDebitGuaranteeModal } from "@components/org/saved/modals/direct-debit-guarantee/DirectDebitGuaranteeModal";
import { PaymentConfirmationModal } from "@components/org/saved/modals/payment-confirmation/PaymentConfirmationModal";

interface PaymentMethodProps {
  isMenuOpen: boolean;
  setIsMenuOpen: (isMenuOpen: boolean) => void;
  showEditMode: boolean;
  setShowEditMode: (showEditMode: boolean) => void;
  hasCompletedBillingAddress: boolean;
  goCardLessCustomer?: GoCardlessCustomer;
}

const PaymentMethod = ({
  setIsMenuOpen,
  isMenuOpen,
  showEditMode,
  setShowEditMode,
  hasCompletedBillingAddress,
  goCardLessCustomer,
}: PaymentMethodProps) => {
  const { handleSubmit, isSubmitting, values, validateForm, setTouched } =
    useFormikContext<FormikValues>();
  const [showDirectDebitGuaranteeModal, setShowDirectDebitGuaranteeModal] =
    useState(false);
  const [showPaymentConfirmationModal, setShowPaymentConfirmationModal] =
    useState(false);

  return (
    <>
      <DirectDebitGuaranteeModal
        visible={showDirectDebitGuaranteeModal}
        closeModal={() => setShowDirectDebitGuaranteeModal(false)}
      />
      <PaymentConfirmationModal
        visible={showPaymentConfirmationModal}
        closeModal={() => setShowPaymentConfirmationModal(false)}
        onActionButtonPress={() => {
          setShowPaymentConfirmationModal(false);
          handleSubmit();
        }}
        account={{
          name: values.accountHolderName ?? "",
          number: values.accountNumber ?? "",
          sortCode: values.branchCode ?? "",
        }}
      />
      <MenuContainer
        title="Payment Method"
        onPress={() => setIsMenuOpen(!isMenuOpen)}
        isMenuOpen={isMenuOpen}
        hasCompletedBillingAddress={hasCompletedBillingAddress}
      >
        {!showEditMode ? (
          <View style={styles.container}>
            <GoCardLessInfo goCardLessCustomer={goCardLessCustomer!} />
            <Text
              onPress={() => setShowEditMode(true)}
              style={styles.editButton}
            >
              {goCardLessCustomer?.bankDetails
                ? "Add New Bank Account"
                : "Add Bank Account"}
            </Text>
          </View>
        ) : (
          <>
            <GoCardLessEdit />
            <ButtonLayout
              closeEditMode={() => setShowEditMode(false)}
              labelActionButton="Save"
              containerButtonStyle={{
                justifyContent: "flex-start",
                marginTop: 16,
                marginBottom: 16,
              }}
              isSubmitting={isSubmitting}
              submitCloseHander={() => {
                setTouched(
                  {
                    accountHolderName: true,
                    accountNumber: true,
                    branchCode: true,
                  },
                  true
                );
                validateForm().then((errors) => {
                  if (Object.keys(errors).length === 0) {
                    setShowPaymentConfirmationModal(true);
                  }
                });
              }}
            />
            <Text>
              Payments by GoCardless. Read the GoCardless{" "}
              <Text
                onPress={() =>
                  Linking.openURL("https://gocardless.com/privacy")
                }
                style={{
                  color: "#2C6ECB",
                  textDecorationLine: "underline",
                }}
              >
                privacy notice
              </Text>{" "}
              and the{" "}
              <Text
                onPress={() => setShowDirectDebitGuaranteeModal(true)}
                style={{
                  color: "#2C6ECB",
                  textDecorationLine: "underline",
                }}
              >
                Direct Debit Guarantee
              </Text>
              .
            </Text>
          </>
        )}
      </MenuContainer>
    </>
  );
};

export { PaymentMethod };
