import StyleSheet from "react-native-media-query";
import { Platform } from "react-native";
import { mediaQuery } from "@utils/misc";

export const { ids, styles } = StyleSheet.create({
  register: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 16,
    [mediaQuery.forMobile]: {
      marginTop: 12,
    },
  },
  noAccount: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 14,
    lineHeight: 20,
    color: "#202223",
  },
  registerLink: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 14,
    color: "#2C6ECB",
  },
  question: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 14,
    lineHeight: 20,
    color: "#0D1738",
    marginBottom: 12,
  },
  areaCode: {
    flex: 1,
    marginRight: 8,
  },
  role: {
    flex: 1,
  },
  buttonRole: {
    flex: 1,
    textAlign: "center",
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderRadius: 4,
    borderColor: "#BABFC3",
    paddingVertical: 8,
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 14,
    lineHeight: 20,
    color: "#202223",
    marginRight: 6,
  },
  buttonRoleSelected: {
    backgroundColor: "#F1F2F3",
  },
  inputWrap: {
    marginBottom: 16,
  },
  roleButtonWrap: {
    flexDirection: "row",
    alignItems: "center",
  },
  submitButton: {
    ...(Platform.OS === "web" && { height: 36 }),
  },
});
