import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { ISvgProps } from "../../../../types";

const LogOutIcon = ({ color = "#fff", ...props }: ISvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <Path
      fill={color}
      d="M16 8c0 4.411-3.589 8-8 8s-8-3.589-8-8 3.589-8 8-8 8 3.589 8 8zm-6.731.8L8.234 9.834a.8.8 0 0 0 1.132 1.132l2.4-2.4a.799.799 0 0 0 0-1.132l-2.4-2.4a.8.8 0 0 0-1.132 1.132L9.27 7.2H4.8a.8.8 0 0 0 0 1.6h4.469z"
    />
  </Svg>
);

export { LogOutIcon };
