import React, { useState } from "react";
import {
  ActivityIndicator,
  FlatList,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { ContentBox } from "@components/general/layouts/content-box";
import { TitleBox } from "@components/account/title-box";
import { RequestItem } from "@components/account/request-item";
import { NewRequest } from "@components/account/new-request/NewRequest";
import { useAuth } from "@hooks/useAuth";
import { styles } from "./style";
import {
  Request,
  RequestType,
  useDeleteRequestMutation,
  useGetUserRequestsQuery,
} from "@gql/generated/generated";

const getTextFromType = (type?: RequestType | "ALL") => {
  switch (type) {
    case "ALL":
      return "All";
    case RequestType.CONTENT_ISSUE:
      return "Content";
    case RequestType.CONTENT_REQUEST:
      return "Content";
    case RequestType.EVENT_ISSUE:
      return "Event";
    case RequestType.EVENT_REQUEST:
      return "Event";
    case RequestType.MY_ACCOUNT:
      return "My Account";
    case RequestType.MY_MEMBERSHIP:
      return "My Membership";
    case RequestType.MY_ORGANISATION:
      return "My Organisation";
    case RequestType.OTHER:
      return "Other";
    case RequestType.PAYMENT:
      return "Payment";
    case RequestType.ROADMAP:
      return "Roadmap";
    case RequestType.TRAINING:
      return "Training";
    default:
      return "";
  }
};

interface TabItemProps {
  title: string;
  active: boolean;
  onPress: () => void;
}

const TabItem = ({ title, active, onPress }: TabItemProps) => (
  <TouchableOpacity onPress={onPress}>
    <View style={styles.tabItem}>
      <Text style={[styles.tabTitle, active && styles.tabTitleSelected]}>
        {title}
      </Text>
    </View>
    <View
      style={[styles.bottomBorder, active && styles.selectedBottomBorder]}
    />
  </TouchableOpacity>
);

const HelpRequest = () => {
  const [activeTab, setActiveTab] = useState<RequestType | "ALL">("ALL");
  const { user, navigate } = useAuth();
  const [deleteRequest, { loading: isDeleteRequestLoading }] =
    useDeleteRequestMutation();

  const { data, refetch, loading } = useGetUserRequestsQuery({
    skip: !user?.id,
  });

  const groupRequestsByType = (requests: Request[]) => {
    return requests.reduce(
      (groupedRequests, request) => {
        const { type } = request;

        const requestWithText = {
          ...request,
          typeText: getTextFromType(type),
        };

        if (!groupedRequests[type]) {
          groupedRequests[type] = [];
        }
        groupedRequests[type].push(requestWithText);
        groupedRequests["ALL"].push(requestWithText);
        return groupedRequests;
      },
      {
        ALL: [] as Request[],
        [RequestType.CONTENT_ISSUE]: [] as Request[],
        [RequestType.EVENT_ISSUE]: [] as Request[],
        [RequestType.CONTENT_REQUEST]: [] as Request[],
        [RequestType.EVENT_REQUEST]: [] as Request[],
        [RequestType.MY_ACCOUNT]: [] as Request[],
        [RequestType.MY_MEMBERSHIP]: [] as Request[],
        [RequestType.MY_ORGANISATION]: [] as Request[],
        [RequestType.OTHER]: [] as Request[],
        [RequestType.PAYMENT]: [] as Request[],
        [RequestType.ROADMAP]: [] as Request[],
        [RequestType.TRAINING]: [] as Request[],
      }
    );
  };

  const requestsByType =
    data?.getUserRequests?.requests &&
    groupRequestsByType(data?.getUserRequests?.requests);

  const tabs: (RequestType | "ALL")[] = [
    "ALL",
    RequestType.CONTENT_ISSUE,
    RequestType.EVENT_ISSUE,
    RequestType.CONTENT_REQUEST,
    RequestType.EVENT_REQUEST,
    RequestType.MY_ACCOUNT,
    RequestType.MY_MEMBERSHIP,
    RequestType.MY_ORGANISATION,
    RequestType.OTHER,
    RequestType.PAYMENT,
    RequestType.ROADMAP,
    RequestType.TRAINING,
  ];

  const filteredTabs = tabs.filter(
    (el) => requestsByType?.[el] && requestsByType?.[el].length > 0
  );

  const selectedRequests = requestsByType?.[activeTab];

  const isLoading = isDeleteRequestLoading || loading;

  const handleTabSelect = (tab: RequestType | "ALL") => {
    setActiveTab(tab);
  };

  const handleDelete = async (id: string) => {
    await deleteRequest({
      variables: {
        id,
      },
    });
    await refetch();
  };

  const keyExtractor = (_item: Request, index: number) => index.toString();

  const renderItem = ({ item }: { item: Request }) => {
    return (
      <RequestItem
        subject={item.subject}
        type={item.type}
        createdAt={new Date(Number(item.createdAt)).toDateString()}
        description={item.description}
        onDelete={() => handleDelete(item.id)}
      />
    );
  };

  return (
    <View style={styles.wrap}>
      <ContentBox>
        <TitleBox
          hasBottomBorder
          title="Requests"
          backTitle="Back to Help"
          onPressBack={() => navigate(-1)}
        />
        <View style={styles.titleWrap}>
          <Text style={styles.title}>Request History</Text>
          {requestsByType && requestsByType.ALL.length > 0 ? (
            <View style={styles.tabSelection}>
              {filteredTabs.map((el: RequestType | "ALL") => {
                return (
                  <TabItem
                    key={el}
                    title={getTextFromType(el)}
                    active={activeTab === el}
                    onPress={() => handleTabSelect(el)}
                  />
                );
              })}
            </View>
          ) : null}
          {data?.getUserRequests?.requests &&
          data?.getUserRequests?.requests.length === 0 ? (
            <View style={{ paddingVertical: 20 }}>
              <Text
                style={{
                  fontFamily: "OpenSans_400Regular",
                  fontSize: 14,
                  lineHeight: 20,
                }}
              >
                No requests found
              </Text>
            </View>
          ) : null}
        </View>
        {isLoading ? (
          <View style={{ paddingVertical: 30 }}>
            <ActivityIndicator size="large" color="#213470" />
          </View>
        ) : null}
        {selectedRequests ? (
          <FlatList
            data={selectedRequests}
            renderItem={renderItem}
            keyExtractor={keyExtractor}
          />
        ) : null}
        <NewRequest isRequest onDone={() => refetch()} />
      </ContentBox>
    </View>
  );
};

export { HelpRequest };
