import React, { useState } from "react";
import { LayoutChangeEvent, Text, View } from "react-native";
import { LineChart } from "react-native-chart-kit";
import { SelectOption } from "@components/general/form/select-option";
import { ContentBox } from "@components/general/layouts/content-box";
import { ids, styles } from "./style";
import { Unit } from "@gql/generated/generated";
import { defaultLineChartStyles } from "../analytics-members-overview";

const optionTypes = [
  { label: "Content", value: "content" },
  { label: "Training", value: "training" },
  { label: "Events", value: "events" },
];

const yearOptions = [
  { label: "This week", value: Unit.DAY },
  { label: "This month", value: Unit.MONTH },
  { label: "This year", value: Unit.YEAR },
];

interface Props {
  unit: Unit;
  setUnit: (unit: Unit) => void;
  setType: (type: "training" | "content" | "events") => void;
  type: "training" | "content" | "events";
  refetchActivity: (value: Unit) => void;
  chartData: {
    labels: string[];
    datasets: {
      data: number[];
      color: () => string;
      strokeWidth: number;
    }[];
  };
}

const AnalyticsActivityReport = ({
  unit,
  setUnit,
  setType,
  type,
  refetchActivity,
  chartData,
}: Props) => {
  const [chartWidth, setChartWidth] = useState(300);

  const onChartWrapLayoutChange = (event: LayoutChangeEvent) => {
    const { width } = event.nativeEvent.layout;
    setChartWidth(width);
  };

  return (
    <View>
      <View style={styles.head} dataSet={{ media: ids.head }}>
        <Text style={styles.title} dataSet={{ media: ids.title }}>
          Activity Report
        </Text>
        <View style={styles.selectWrap} dataSet={{ media: ids.selectWrap }}>
          <View style={styles.selectItem} dataSet={{ media: ids.selectItem }}>
            <SelectOption
              onChange={(value) =>
                setType(value as "training" | "content" | "events")
              }
              value={type}
              options={optionTypes}
            />
          </View>
          <View style={styles.selectItem} dataSet={{ media: ids.selectItem }}>
            <SelectOption
              onChange={(value) => {
                setUnit(value as Unit);
                refetchActivity(value as Unit);
              }}
              value={unit}
              options={yearOptions}
            />
          </View>
        </View>
      </View>
      <ContentBox style={styles.contentBox}>
        <View style={styles.contentInner} onLayout={onChartWrapLayoutChange}>
          <LineChart
            bezier
            data={chartData}
            width={chartWidth}
            height={220}
            yAxisSuffix="mins"
            withVerticalLines={false}
            chartConfig={defaultLineChartStyles}
          />
        </View>
      </ContentBox>
    </View>
  );
};

export { AnalyticsActivityReport };
