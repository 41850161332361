import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  textBoldDark: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 14,
    lineHeight: 20,
    color: "#0D1738",
  },
  textRegularDark: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 14,
    lineHeight: 20,
    color: "#0D1738",
  },
  textRegularWarning: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 14,
    lineHeight: 20,
    color: "#D72C0D",
  },
  textRegularGraySmall: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 12,
    lineHeight: 16,
    color: "#6D7175",
  },
  textBoldDarkSmall: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 12,
    lineHeight: 16,
    color: "#0D1738",
  },
  textBoldDarkSmallBlue: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 14,
    lineHeight: 16,
    color: "#2C6ECB",
  },
  textBoldDarkSmallWhite: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 14,
    lineHeight: 16,
    color: "#FFFFFF",
  },
  textTag: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 12,
    lineHeight: 16,
    color: "#0D1738",
    paddingVertical: 2,
    paddingHorizontal: 8,
    borderRadius: 15,
    marginRight: 5,
    backgroundColor: "#E4E5E7",
  },
  cardContainer: {
    height: 177,
    flexDirection: "row",
    flex: 1,
    backgroundColor: "#FFFFFF",
    borderRadius: 8,
    shadowColor: "rgba(0, 0, 0, 0.15)",
    shadowRadius: 4,
    shadowOffset: { height: 0.5, width: 0 },
  },
  imageContainer: {
    position: "relative",
    marginRight: 20,
    width: "35%",
  },
  image: {
    width: "100%",
    height: 177,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  imageTagContainer: {
    position: "absolute",
    top: 12,
    left: 12,
    flexDirection: "row",
  },
  contentContainer: {
    flexDirection: "row",
    width: "65%",
    paddingVertical: 20,
  },
  contentContainerInfo: {
    marginRight: 40,
    justifyContent: "space-between",
    width: "80%",
  },
  jobInfoContainer: {
    flexDirection: "row",
    height: 24,
    alignItems: "center",
  },
  initialsContainer: {
    width: 24,
    height: 24,
    borderRadius: 100,
    backgroundColor: "#E4E5E7",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 4,
  },
  statisticContainer: {
    flexDirection: "row",
    marginTop: 4,
    alignItems: "center",
    height: 20,
  },
  // search box styles
  showSearch: {
    width: "100%",
    padding: 20,
    position: "absolute",
    top: 64,
    maxHeight: 306,
  },
  hidden: {
    display: "none",
  },
  modalWrapper: {
    padding: 20,
    maxWidth: 580,
  },
  modalImage: {
    height: 52,
    width: 52,
    borderRadius: 6,
  },
});
