import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  inner: {
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    flex: 1,
    gap: 10,
  },
  bulkText: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 12,
    lineHeight: 16,
    color: "#6D7175",
    marginLeft: 32,
  },
  switchBulk: {
    marginTop: 3,
  },
  resetButton: {
    marginBottom: -14,
  },
});
