import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  mainTitle: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 14,
    lineHeight: 20,
    color: "#6D7175",
  },
  statisticText: {
    marginVertical: 8,
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 26,
    lineHeight: 32,
    color: "#0D1738",
  },
  footerTextCard: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 14,
    lineHeight: 20,
    color: "#0D1738",
  },
  textGreenBold: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 14,
    lineHeight: 20,
    color: "#23827F",
    marginLeft: 10,
    marginRight: 4,
  },
  textRegularGrey: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 14,
    lineHeight: 20,
    color: "#6D7175",
  },
});
