import React from "react";
import { Image, Text, View } from "react-native";
import RecruitmentNetworkLogo from "../../../../../assets/img/RecruitmentNetworkLogo.png";
import { styles } from "./style";

export interface Props {
  avatar?: string;
  name: string;
  date: string;
  postType: string;
}

const PostHead = ({ avatar, name, date, postType }: Props) => {
  return (
    <View style={styles.wrap}>
      <View style={styles.headLeft}>
        <Image
          style={styles.headLogo}
          source={avatar ? { uri: avatar } : RecruitmentNetworkLogo}
        />
        <View>
          <Text style={styles.postName}>{name || ""}</Text>
          <Text style={styles.postDate}>{date || ""}</Text>
          <Text style={styles.postType}>{postType || ""}</Text>
        </View>
      </View>
    </View>
  );
};

export { PostHead };
