import StyleSheet from "react-native-media-query";
import { mediaQuery } from "@utils/misc";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: "100%",
    maxWidth: 334,
    borderRadius: 4,
    borderTopWidth: 4,
    shadowColor: "rgba(0, 0, 0, 0.15)",
    shadowRadius: 4,
    shadowOffset: { height: 0.5, width: 0 },
    padding: 20,
    justifyContent: "space-between",
    alignItems: "center",
  },
  topWrap: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
  circularWrap: {
    width: "48%",
    [mediaQuery.forMobile]: {
      width: "40%",
    },
  },
  contentWrap: {
    width: "149px",
    alignItems: "flex-start",
    [mediaQuery.forMobile]: {
      width: "60%",
    },
  },
  bottomWrap: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    width: "100%",
    paddingTop: 20,
    minWidth: 0,
  },
  percentageText: {
    color: "#2C6ECB",
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 42,
  },
  doneText: {
    color: "#0D1738",
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
    marginBottom: 12,
  },
  questionsText: {
    color: "#0D1738",
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
  },
  bold: {
    fontWeight: "bold",
  },
  title: {
    fontSize: 20,
    lineHeight: 28,
    fontFamily: "OpenSans_600SemiBold",
    color: "#0D1738",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  titleWrap: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "auto",
  },
  btnWrap: {
    flexDirection: "row",
    alignItems: "center",
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: "auto",
  },
  eachButtonWrap: {
    marginLeft: 10,
  },
  progressWrap: {
    width: "100%",
    backgroundColor: "rgba(44, 110, 203, 0.2)",
    borderRadius: 15,
    height: 16,
    marginBottom: 8,
  },
  progressInner: {
    backgroundColor: "#2C6ECB",
    borderRadius: 15,
    height: "100%",
  },
  progressPercentText: {
    color: "#FFFFFF",
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 12,
    marginLeft: 8,
  },
});
