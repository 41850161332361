import React from "react";
import { View } from "react-native";
import { ContentBox } from "@components/general/layouts/content-box";
import { AnalyticsHeader } from "@components/org/analytics/analytics-header";
import { AnalyticsActivityReport } from "@components/org/analytics/analytics-activity-report";
import { AnalyticsEngagement } from "@components/org/analytics/analytics-engagement";
import { ids, styles } from "./style";
import { AnalyticsContentHub } from "@components/org/analytics/analytics-content-hub";
import { useTRNWorld } from "@components/org/analytics/useTRNWorld";

const AnalyticsTrnWorld = () => {
  const {
    unit,
    setUnit,
    setType,
    type,
    refetchActivity,
    chartData,
    dataForExport,
    pageStats,
    activeTab,
    setActiveTab,
    refetchContentHub,
    selectedDays,
    setSelectedDays,
    engagementData,
  } = useTRNWorld();

  return (
    <ContentBox>
      <AnalyticsHeader title="TRN World" excelData={dataForExport} />
      <View style={styles.topWrap} dataSet={{ media: ids.topWrap }}>
        <View style={styles.activityWrap} dataSet={{ media: ids.activityWrap }}>
          <AnalyticsActivityReport
            unit={unit}
            setUnit={setUnit}
            setType={setType}
            type={type}
            chartData={chartData}
            refetchActivity={refetchActivity}
          />
        </View>
        <View
          style={styles.engagementWrap}
          dataSet={{ media: ids.engagementWrap }}
        >
          <AnalyticsEngagement
            engagement={
              engagementData?.employerGetMemberStats.trnWorld?.engagementByRole
            }
          />
        </View>
      </View>
      <AnalyticsContentHub
        content={pageStats}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        refetch={refetchContentHub}
        selectedDays={selectedDays}
        setSelectedDays={setSelectedDays}
      />
    </ContentBox>
  );
};

export { AnalyticsTrnWorld };
