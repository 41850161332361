import StyleSheet from "react-native-media-query";
import { mediaQuery } from "@utils/misc";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    paddingTop: 20,
    paddingBottom: 20,
    flexDirection: "row",
    [mediaQuery.forTabletDown]: {
      flexDirection: "column",
      justifyContent: "center",
      padding: 20,
    },
  },
  accountBox: {
    width: "30%",
    maxWidth: 228,
    [mediaQuery.forTabletDown]: {
      width: "100%",
      maxWidth: "100%",
    },
  },
  nameWrap: {
    padding: 20,
  },
  image: {
    width: 78,
    height: 78,
    borderRadius: 39,
    marginBottom: 10,
    backgroundColor: "#f8f8f8",
  },
  name: {
    color: "#0D1738",
    fontSize: 20,
    fontFamily: "OpenSans_400Regular",
  },
  navWrap: {
    borderBottomWidth: 1,
    borderBottomColor: "#EDEEEF",
    borderTopWidth: 1,
    borderTopColor: "#EDEEEF",
    padding: 8,
  },
  navItem: {
    borderRadius: 4,
    padding: 18,
    height: 44,
    flexDirection: "row",
    alignItems: "center",
  },
  navItemActive: {
    backgroundColor: "#E5EFFD",
  },
  navItemTitle: {
    color: "#0D1738",
    fontSize: 16,
    fontFamily: "OpenSans_600SemiBold",
    marginLeft: 18,
  },
  helpWrap: {
    padding: 8,
  },
  leftWrap: {
    width: "100%",
    maxWidth: 956,
    paddingLeft: 20,
    [mediaQuery.forTabletDown]: {
      width: "100%",
      maxWidth: "100%",
      paddingLeft: 0,
      marginTop: 20,
    },
  },
  backWrap: {
    width: "100%",
    marginTop: 16,
  },
});
