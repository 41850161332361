import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  error: {
    fontSize: 10,
    fontFamily: "OpenSans_400Regular",
    color: "#D72C0D",
    marginTop: 5,
  },
});
