import React, { useState } from "react";
import { View } from "react-native";
import { useNavigate } from "react-router-native";
import { Wrapper } from "@components/general/layouts/wrapper/Wrapper";
import { IndexPageHeader } from "@components/general/index-page-header";
import { NotificationSwitch } from "@components/account/notification-switch";
import { ButtonGeneral } from "@components/general/button-general";
import { styles } from "./style";

const Content = () => {
  const navigate = useNavigate();
  const [notificationValues, setNotificationValues] = useState({
    newContent: false,
    updateContent: false,
  });

  return (
    <Wrapper>
      <View style={styles.wrap}>
        <View>
          <IndexPageHeader title="Content" onPressBack={() => navigate(-1)} />
          <NotificationSwitch
            title="New content"
            isActive={notificationValues.newContent}
            onChange={() =>
              setNotificationValues({
                ...notificationValues,
                newContent: !notificationValues.newContent,
              })
            }
          />
          <NotificationSwitch
            title="Update content"
            isActive={notificationValues.updateContent}
            onChange={() =>
              setNotificationValues({
                ...notificationValues,
                updateContent: !notificationValues.updateContent,
              })
            }
          />
        </View>
        <View style={styles.buttonWrap}>
          <ButtonGeneral label="Save" />
        </View>
      </View>
    </Wrapper>
  );
};

export { Content };
