import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const ClockIcon = (props: SvgProps) => (
  <Svg width={16} height={17} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16.063c-4.411 0-8-3.59-8-8 0-4.412 3.589-8 8-8s8 3.588 8 8c0 4.41-3.589 8-8 8Zm2.293-4.707a.997.997 0 0 1-.707-.293L7.293 8.77A.997.997 0 0 1 7 8.063v-4a1 1 0 1 1 2 0v3.586l2 2a.999.999 0 0 1-.707 1.707Z"
      fill="#5C5F62"
    />
  </Svg>
);

export { ClockIcon };
