import React from "react";
import { Pill } from "../pill/Pill";
import { Tooltip } from "@shopify/polaris";
import { View } from "react-native";
import { Row } from "../row";
import { uniq } from "lodash";
import { TooltipWithChild } from "../tooltip";

interface PillRowWithTooltipProps {
  items: string[];
  shortenedItems: string[];
}

const PillRowWithTooltip = ({
  items,
  shortenedItems,
}: PillRowWithTooltipProps) => {
  return (
    <Row>
      <TooltipWithChild toolTipText={`${items?.join(", ")}`}>
        {uniq(shortenedItems).map((el, i) => (
          <View style={{ marginBottom: 4, width: "min-content" }}>
            <Pill text={el} withMarginRight={i != shortenedItems.length - 1} />
          </View>
        ))}
      </TooltipWithChild>
    </Row>
  );
};

export { PillRowWithTooltip };
