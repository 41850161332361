import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: "100%",
    maxWidth: 334,
    borderRadius: 4,
    borderTopWidth: 4,
    borderTopColor: "#0D1738",
    shadowColor: "rgba(0, 0, 0, 0.15)",
    shadowRadius: 4,
    shadowOffset: { height: 0.5, width: 0 },
    padding: 20,
  },
  titleWrap: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 4,
  },
  title: {
    fontSize: 28,
    fontFamily: "OpenSans_600SemiBold",
    color: "#0D1738",
  },
  subtitle: {
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
    color: "#0D1738",
    marginBottom: 24,
  },
  progressWrap: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 32,
  },
  progressBox: {
    width: "50%",
  },
  progressText: {
    fontSize: 16,
    color: "#0D1738",
    fontFamily: "OpenSans_400Regular",
  },
  progressTextBold: {
    fontFamily: "OpenSans_400Regular",
  },
  questionAnsweredText: {
    fontSize: 14,
    color: "#0D1738",
    fontFamily: "OpenSans_400Regular",
    marginBottom: 4,
  },
  playWrap: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  playProgressWrap: {
    width: "80%",
    backgroundColor: "#BABEC3",
    borderRadius: 24,
    height: 32,
  },
  playProgressInner: {
    justifyContent: "center",
    paddingLeft: 12,
    paddingRight: 12,
    height: "100%",
    backgroundColor: "#0D1738",
    borderRadius: 24,
    minWidth: 36,
  },
  playProgressCount: {
    fontSize: 20,
    fontFamily: "OpenSans_600SemiBold",
    color: "#FFFFFF",
  },
  playTouch: {
    backgroundColor: "#0D1738",
    height: 40,
    width: 40,
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
  },
});
