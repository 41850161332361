import { Formik } from "formik";
import { Text, TouchableOpacity, View } from "react-native";
import { ContentBox } from "@components/general/layouts/content-box";
import { TitleBox } from "@components/account/title-box";
import { useNavigate } from "react-router-native";
import { MemberInfoEdit } from "@components/org/members/member-view/member-info/MemberInfoEdit";
import { styles } from "./styles";
import { AccountType, useAddEmployeeMutation } from "@gql/generated/generated";
import { useState } from "react";
import { ErrorBox } from "@components/sign-up/error-box";
import { newMembervalidationSchema } from "@utils/validators";

const AddNewMember = () => {
  const navigate = useNavigate();
  const [addEmployee] = useAddEmployeeMutation();
  const [error, setError] = useState<string | undefined>();
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    country: "",
    jobPosition: "",
    accountType: AccountType.EMPLOYEE,
    profilePhotoUrl: "",
  };

  const onSubmit = async (values: typeof initialValues) => {
    const resp = await addEmployee({
      variables: {
        input: {
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          accountType: values.accountType,
          jobPosition: values.jobPosition,
          phoneNumber: values.phoneNumber,
          profilePhotoUrl: values.profilePhotoUrl,
        },
      },
    });

    if (resp.data?.addEmployee.__typename === "Success") {
      // TODO Posthog tracking here for user creation

      navigate(-1);
      setError(undefined);
    }

    if (resp.data?.addEmployee.__typename === "ValidationError") {
      setError(resp.data?.addEmployee.message);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={newMembervalidationSchema}
    >
      {({ handleSubmit }) => (
        <ContentBox>
          <TitleBox
            title="Add a New Member"
            backTitle="Back to All Members"
            onPressBack={() => navigate(-1)}
          />
          {error && <ErrorBox error={error} />}
          <MemberInfoEdit needButtonLayout={false} />
          <TouchableOpacity
            onPress={() => handleSubmit()}
            style={styles.buttonContainer}
          >
            <View style={styles.button}>
              <Text style={styles.buttonText}>Send Invitation</Text>
            </View>
          </TouchableOpacity>
        </ContentBox>
      )}
    </Formik>
  );
};

export { AddNewMember };
