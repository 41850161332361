import { useFormikContext } from "formik";
import { Text, View } from "react-native";

import dayjs from "dayjs";
import { FormikInput } from "@components/back-office/editor/formik-input";
import { textStyles } from "@styles/text";
import { editPageStyles } from "@styles/BackOffice/editPageStyles";
import { styles } from "./styles";
import { DropdownCountryList } from "@components/general/dropdown-country-list/DropdownCountryList";

export const UserPersonalInfoForm = () => {
  const { values, setFieldValue, errors, touched } = useFormikContext<any>();

  return (
    <>
      <View style={{ marginBottom: 20 }}>
        <Text style={styles.userTextInfoForm}>Personal details</Text>
        <View style={styles.marginBottom}>
          <FormikInput fieldName="name" placeholder="Name" />
        </View>
        <View style={styles.marginBottom}>
          <FormikInput fieldName="email" placeholder="Email" />
        </View>
        <View style={styles.marginBottom}>
          <FormikInput fieldName="phoneNumber" placeholder="Phone Number" />
        </View>
      </View>
      <View style={{ marginBottom: 20 }}>
        <Text style={[editPageStyles.userTextInfoTitle, { marginBottom: 16 }]}>
          Address
        </Text>
        <View style={styles.marginBottom}>
          <FormikInput
            fieldName="addressOne"
            placeholder="House No. and Streetname"
          />
        </View>
        <View style={styles.marginBottom}>
          <FormikInput
            fieldName="addressTwo"
            placeholder="Address (Optional)"
          />
        </View>
        <View style={styles.marginBottom}>
          <FormikInput fieldName="postCode" placeholder="Postcode" />
        </View>
        <View style={styles.marginBottom}>
          <FormikInput fieldName="city" placeholder="City" />
        </View>
        <View style={styles.marginBottom}>
          <DropdownCountryList
            onChange={(country) => {
              setFieldValue("country", country);
            }}
            placeholder="Country"
            value={values.country}
          />
        </View>
        <View>
          <Text style={[editPageStyles.userTextInfoTitle, styles.marginBottom]}>
            Member Since
          </Text>
          <Text style={editPageStyles.userTextInfo}>
            {dayjs(values.registrationDate).format("DD/MM/YYYY")}
          </Text>
          {errors["registrationDate"] && touched["registrationDate"] ? (
            <Text style={textStyles.error}>
              {errors["registrationDate"]?.toString()}
            </Text>
          ) : null}
        </View>
      </View>
    </>
  );
};
