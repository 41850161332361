import StyleSheet from "react-native-media-query";
import { Dimensions } from "react-native";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: "100%",
  },
  selectBox: {
    borderColor: "#BABFC3",
    borderWidth: 1,
    borderRadius: 4,
    height: 36,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 12,
    paddingRight: 12,
    width: "100%",
  },
  selectedText: {
    color: "#0D1738",
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
    maxWidth: "90%",
  },
  placeholder: {
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
    color: "#6D7175",
    maxWidth: "90%",
  },
  options: {
    borderRadius: 8,
    backgroundColor: "#FFFFFF",
    paddingTop: 8,
    paddingBottom: 8,
    paddingRight: 8,
    shadowColor: "rgba(0, 0, 0, 0.15)",
    shadowRadius: 4,
    shadowOffset: { height: 4, width: 0 },
    shadowOpacity: 0.5,
    width: "100%",
    maxHeight: Dimensions.get("window").width / 1.2,
  },
  item: {
    backgroundColor: "#FFFFFF",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: 36,
    borderRadius: 8,
  },
  leftBorder: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    marginLeft: 5,
    height: "100%",
    width: 3,
  },
  selectedLeftBorder: {
    backgroundColor: "#2C6ECB",
  },
  itemContent: {
    height: "100%",
    borderRadius: 4,
    width: "97%",
    justifyContent: "center",
    paddingLeft: 8,
  },
  itemSelected: {
    backgroundColor: "#F2F7FE",
  },
  itemText: {
    fontSize: 14,
    color: "#202223",
    width: "100%",
  },
});
