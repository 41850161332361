import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { ISvgProps } from "../../../types";

const CrossIcon = (props: ISvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={15}
    fill="none"
    {...props}
  >
    <Path
      fill="#0D1738"
      d="m1.43 2.057 11.137 11.137L1.43 2.057zm10.34-.797a1.125 1.125 0 0 1 1.591 1.591L8.59 7.625l4.773 4.773a1.125 1.125 0 1 1-1.59 1.591L6.997 9.216l-4.773 4.773a1.125 1.125 0 0 1-1.493.087l-.098-.087a1.125 1.125 0 0 1 0-1.59l4.773-4.774L.634 2.852a1.125 1.125 0 0 1 1.591-1.59l4.773 4.772L11.77 1.26z"
    />
  </Svg>
);

export { CrossIcon };
