import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const DoubleArrow = (props: SvgProps) => (
  <Svg width={20} height={20} fill="none" {...props}>
    <Path
      fill="#5C5F62"
      d="M12.324 9H7.676c-.563 0-.878-.603-.53-1.014L9.468 5.24a.708.708 0 0 1 1.062 0l2.323 2.746c.349.411.033 1.014-.53 1.014zm-4.648 2h4.648c.563 0 .879.603.53 1.014l-2.323 2.746a.708.708 0 0 1-1.062 0l-2.324-2.746C6.798 11.603 7.113 11 7.676 11z"
    />
  </Svg>
);

export { DoubleArrow };
