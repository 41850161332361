import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const CloseIcon = (props: SvgProps) => (
  <Svg width={16} height={16} fill="none" {...props}>
    <Path
      d="m9.414 8 6.293-6.293A1 1 0 0 0 14.293.293L8 6.586 1.707.293A1 1 0 0 0 .293 1.707L6.586 8 .293 14.293a1 1 0 0 0 1.414 1.414L8 9.414l6.293 6.293A.998.998 0 0 0 16 15a.997.997 0 0 0-.293-.707L9.414 8Z"
      fill="#5C5F62"
    />
  </Svg>
);

export { CloseIcon };
