import { useNavigate, useParams } from "react-router-native";
import { Wrapper } from "@components/general/layouts/wrapper/Wrapper";
import { ContentBox } from "@components/general/layouts/content-box";
import { LessonWrapper } from "@components/training/lesson/lesson-wrapper";
import { LessonContent } from "@components/training/lesson/lesson-content";
import { ids, styles } from "./style";
import { IndexPageHeader } from "@components/general/index-page-header";
import { ActivityIndicator, Platform, ScrollView, View } from "react-native";
import { useGetLessonQuery } from "@gql/generated/generated";
import { useTrainingDetail } from "../training-detail/useTrainingDetail";
import { useToast } from "@context/ToastContext";

const Lesson = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();

  const { id, moduleId, lessonId } = useParams() as {
    id: string;
    moduleId: string;
    lessonId: string;
  };
  const { data, loading, error } = useGetLessonQuery({
    variables: {
      input: {
        trainingId: id,
        moduleId,
        lessonId,
      },
    },
  });

  const lesson = data?.getLesson;

  if(error && error.message === "No access for user"){
    addToast('error', 'Please upgrade to access this lesson');
    navigate(`/training/${id}`);
  }
  
  const {
    handlePressEnroll,
  } = useTrainingDetail();

  return (
    <Wrapper style={styles.wrap}>
      {Platform.OS !== "web" && (
        <IndexPageHeader
          title={lesson?.lessonTitle || ""}
          onPressBack={() => navigate(-1)}
        />
      )}
      <ScrollView contentContainerStyle={{ paddingBottom: 200 }}>
        <ContentBox style={styles.inner} dataSetMedia={ids.inner}>
          {loading ? (
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                minHeight: 500,
              }}
            >
              <ActivityIndicator size="large" color="#213470" />
            </View>
          ) : null}
          {lesson && id ? (
            <LessonWrapper lesson={lesson} trainingId={id} moduleId={moduleId} handlePressEnroll={handlePressEnroll}>
              <LessonContent
                title={lesson.lessonTitle}
                content={lesson.lessonContent}
                file={lesson.videoFile ?? lesson.documentFile}
                loading={loading}
                type={lesson.lessonType}
                attachments={lesson.downloadFiles}
              />
            </LessonWrapper>
          ) : null}
        </ContentBox>
      </ScrollView>
    </Wrapper>
  );
};

export { Lesson };
