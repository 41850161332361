import { useQueryFilters } from "../useGridFilters";
import { Checkbox } from "@shopify/polaris";
import {
  CONTENT_TYPES,
  getLabelsForContentTypes,
} from "@components/general/filters";
import { TableDropdown } from "@components/back-office/tables/table-dropdown";
import { DocumentContentType } from "@gql/generated/generated";

export const ContentTypeFilter = () => {
  const { filters, setFilterValue } = useQueryFilters(["contentTypes"]);

  const value = filters.contentTypes || [];

  const contentTypeItems = CONTENT_TYPES.map((contentType) => {
    return {
      id: contentType.value,
      content: contentType.label,
      prefix: (
        <Checkbox
          key={contentType.value}
          label={contentType.label}
          labelHidden
          checked={value?.includes(contentType.value)}
        />
      ),
    };
  });

  return (
    <TableDropdown
      needFluidContent
      placeholder={
        value?.length > 0
          ? getLabelsForContentTypes(value as DocumentContentType[]).join(", ")
          : "Content Type"
      }
      items={contentTypeItems}
      onSelectItem={(id) => {
        if (value?.includes(id as DocumentContentType)) {
          setFilterValue(
            "contentTypes",
            value?.filter((el: string) => el !== id)
          );
        } else {
          setFilterValue("contentTypes", [...(value || []), id]);
        }
      }}
      minWidth={120}
      onReset={() => setFilterValue("contentTypes", [])}
      isSelected={!!filters.contentTypes}
    />
  );
};
