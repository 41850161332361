import { ActivityIndicator, View } from "react-native";
import { useMemo, useState } from "react";
import { ContentBox } from "@components/general/layouts/content-box";
import { TitleBox } from "@components/account/title-box";
import { Wrapper } from "@components/general/layouts/wrapper/Wrapper";
import { MarketplaceItem } from "@components/marketplace/marketplace-item/MarketplaceItem";
import { MarketplaceHeadMenu } from "@components/marketplace/marketplace-head-menu/MarketplaceHeadMenu";
import { HorizontalGrid, Pagination } from "@shopify/polaris";
import { useGetAllPartnersQuery } from "@gql/generated/generated";
import { PartnerTypes } from "@utils/partners";

const Marketplace = () => {
  const [page, setPage] = useState(1);
  const [specialOffer, setSpecialOffer] = useState(false);
  const [partnerType, setPartnerType] = useState<string[]>([]);
  const [categories, setCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const { data, loading: loadingPartners } = useGetAllPartnersQuery({
    variables: {
      page,
      limit: 10,
      filters: {
        search: searchTerm,
        categories,
        partnerType: partnerType.includes("All")
          ? Object.keys(PartnerTypes)
          : partnerType,
        specialOffer,
      },
    },
  });

  const partnerData = data?.getAllPartners;

  const { partners, hasNextPage } = partnerData || {};

  const partnerItems = useMemo(() => {
    if (!partnerData?.partners?.length) return null;
    return partnerData.partners.map((item) => {
      return (
        <MarketplaceItem
          id={item.id}
          key={item.id}
          title={item.title}
          image={item.logo}
          categories={item.categories}
          summary={item.summary}
          partnerType={item.partnerType}
          needSpecialOffer={item.specialOffer?.details !== ""}
          content={item.content}
          specialOfferDetails={item.specialOffer?.details ?? ""}
          specialOfferLink={item.specialOffer?.link ?? ""}
          links={{
            facebook: item.links?.facebook,
            instagram: item.links?.instagram,
            linkedin: item.links?.linkedin,
            twitter: item.links?.twitter,
            website: item.links?.website,
          }}
        />
      );
    });
  }, [partnerData?.partners]);

  return (
    <Wrapper style={{ marginTop: 20 }}>
      <ContentBox style={{ marginBottom: 20 }}>
        <TitleBox title="Partners" extraStyles={{ marginLeft: 50 }} />
        <View style={{ paddingHorizontal: 80 }}>
          <MarketplaceHeadMenu
            hasNextPage={!!hasNextPage}
            page={page}
            setPage={setPage}
            specialOffer={specialOffer}
            setSpecialOffer={setSpecialOffer}
            setCategories={setCategories}
            partnerType={partnerType}
            setPartnerType={setPartnerType}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
          {partners ? (
            <HorizontalGrid gap="5" columns={{ xs: 1, sm: 1, md: 1, lg: 2 }}>
              {partnerItems}
            </HorizontalGrid>
          ) : null}
          {loadingPartners ? (
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ActivityIndicator size="large" color="#213470" />
            </View>
          ) : null}
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-end",
              marginBottom: 40,
            }}
          >
            <Pagination
              hasPrevious={page > 1}
              onPrevious={() => setPage(page - 1)}
              hasNext={!!hasNextPage}
              onNext={() => setPage(page + 1)}
            />
          </View>
        </View>
      </ContentBox>
    </Wrapper>
  );
};

export { Marketplace };
