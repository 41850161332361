import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: "100%",
    padding: 20,
    borderWidth: 1,
    borderColor: "#EDEEEF",
    borderRadius: 8,
  },
  container: {
    width: "100%",
    maxWidth: 586,
  },
  title: {
    marginBottom: 8,
  },
  boldText: {
    color: "#202223",
    fontSize: 14,
    fontFamily: "OpenSans_600SemiBold",
  },
  normalText: {
    color: "#202223",
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
  },
  pollWrap: {
    padding: 20,
    paddingBottom: 0,
  },
  pollItem: {
    width: "100%",
    borderWidth: 1,
    borderColor: "#C9CCCF",
    height: 32,
    borderRadius: 4,
    marginTop: 8,
  },
  pollItemActive: {
    borderWidth: 0,
    backgroundColor: "#F6F6F7",
  },
  pollTextWrap: {
    position: "absolute",
    left: 16,
    right: 16,
    top: 0,
    bottom: 0,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  pollTextWrapActive: {
    justifyContent: "space-between",
  },
  pollText: {
    color: "#0D1738",
    fontSize: 14,
    fontFamily: "OpenSans_600SemiBold",
  },
  pollPercentText: {
    color: "#0D1738",
    fontSize: 14,
    fontFamily: "OpenSans_600SemiBold",
  },
  pollActive: {
    height: "100%",
    backgroundColor: "#D3E5FE",
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  pollActiveFull: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
  voteTextWrap: {
    marginTop: 8,
    flexDirection: "row",
    alignItems: "center",
  },
  dot: {
    height: 4,
    width: 4,
    borderRadius: 2,
    backgroundColor: "#202223",
    marginRight: 4,
    marginLeft: 4,
  },
});
