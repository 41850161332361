import { DocumentContentType } from "../../../gql/generated/generated";

/**
 * Human-readable labels for the document content types.
 */
export const CONTENT_TYPES = [
  {
    label: "Article",
    value: DocumentContentType.ARTICLE,
  },
  {
    label: "Excel Document",
    value: DocumentContentType.EXCEL_DOCUMENT,
  },
  {
    label: "PDF Document",
    value: DocumentContentType.PDF_DOCUMENT,
  },
  {
    label: "Podcast",
    value: DocumentContentType.PODCAST,
  },
  {
    label: "PowerPoint Document",
    value: DocumentContentType.POWER_POINT_DOCUMENT,
  },
  {
    label: "Powerplay",
    value: DocumentContentType.POWERPLAY,
  },
  {
    label: "Template",
    value: DocumentContentType.TEMPLATE,
  },
  {
    label: "Tool",
    value: DocumentContentType.TOOL,
  },
  {
    label: "TRN Partner Content",
    value: DocumentContentType.TRN_PARTNER_CONTENT,
  },
  {
    label: "Video",
    value: DocumentContentType.VIDEO,
  },
  {
    label: "Webinar/Roundtable",
    value: DocumentContentType.WEBINAR_ROUNDTABLE,
  },
  {
    label: "Word Document",
    value: DocumentContentType.WORD_DOCUMENT,
  },
];
