import React, { useState } from "react";
import { TextInput, TextInputProps, View } from "react-native";
import {
  InputError,
  Props as InputErrorProps,
} from "@components/general/form/input-error";
import { Label, Props as LabelProps } from "@components/general/form/label";
import { SelectOption } from "@components/general/form/select-option";
import { countryCodes } from "./country-codes";
import { styles } from "./style";

export interface Props extends LabelProps, InputErrorProps, TextInputProps {
  onChangeText: (value: string) => void;
}

const PhoneInput = (props: Props) => {
  const { label, error, isOptional, onChangeText } = props;
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+44");

  const defaultCountryCode = countryCodes.findIndex(
    (item) => item.code === "+44"
  );
  const [countryCodeValue, setCountryCodeValue] = useState(
    `+44_${defaultCountryCode}`
  );

  const codes = countryCodes.map(({ name, code }, index) => ({
    label: name + " (" + code + ")",
    value: `${code}_${index}`,
  }));

  const handleChange = (value: string, type: "code" | "number") => {
    if (type === "code") {
      setCountryCode(value);
      onChangeText(`${value}${phoneNumber}`);
    } else {
      setPhoneNumber(value);
      onChangeText(`${countryCode}${value}`);
    }
  };

  const handleCodeChange = (value: string) => {
    setCountryCodeValue(value);
    const newValue = value.split("_");
    handleChange(newValue[0], "code");
  };

  return (
    <View>
      {label && <Label label={label} isOptional={isOptional} />}
      <View style={styles.inputWrap}>
        <View style={styles.countryCodeWrap}>
          <SelectOption
            options={codes}
            onChange={(selected) => handleCodeChange(selected)}
            value={countryCodeValue}
          />
        </View>
        <View style={styles.numberWrap}>
          <TextInput
            {...props}
            keyboardType="phone-pad"
            style={[styles.input, error ? styles.inputError : null]}
            placeholderTextColor="#6D7175"
            underlineColorAndroid="transparent"
            onChangeText={(value: string) => handleChange(value, "number")}
          />
        </View>
      </View>
      {error && <InputError error={error} />}
    </View>
  );
};

export { PhoneInput };
