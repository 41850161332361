import React from "react";
import { FlatList, Text, View } from "react-native";
import { CommentBox } from "@components/home/post/comment/comment-box";
import { PostComment } from "@components/home/post/comment/post-comment";
import dayjs from "dayjs";
import { useUserContext } from "@context/UserContext";
import { styles } from "./style";
import { getDefaultAvatar } from "@utils/misc";
import { userProfileImage } from "@utils/userProfileImage";
import {
  Comment,
  Reply,
  useUpdateCommentLikesMutation,
} from "@gql/generated/generated";

interface ReviewsProps {
  commentData: Comment[];
  handleClickReply: (id: string) => void;
  withReviewSummary?: boolean;
  withInputBox?: boolean;
  refetch?: () => void;
}

export const formatDate = (date: number): string =>
  dayjs(new Date(date)).format("MMM D, YYYY");

const EachComment = ({
  comment,
  commentIndex,
  handleClickReply,
  withInputBox,
  refetch,
}: {
  comment: Comment;
  commentIndex: number;
  handleClickReply: (id: string) => void;
  withInputBox: boolean;
  refetch?: () => void;
}) => {
  const [updateCommentLikes] = useUpdateCommentLikesMutation();

  const { currentUser } = useUserContext();

  const handlePressLike = async (commentId: string) => {
    await updateCommentLikes({
      variables: {
        input: {
          commentId,
        },
      },
    });
    refetch && refetch();
  };

  const keyExtractor = ({ id }: Reply) => id;

  const renderReplyItem = ({ item }: { item: Reply }) => (
    <View style={styles.commentItem}>
      <CommentBox
        avatar={
          item.userProfilePhotoUrl
            ? { uri: item.userProfilePhotoUrl }
            : getDefaultAvatar(item.userAvatarIndex)
        }
        userName={item.userName}
        userJobTitle={item.userJobTitle}
        text={item.text}
        totalLikes={item.likedBy?.length ?? 0}
        ratingDate={formatDate(item.createdAt)}
        onPressLike={() => handlePressLike(item.id)}
        likedByCurrentUser={Boolean(
          currentUser?.id && item.likedBy?.includes(currentUser?.id)
        )}
      />
    </View>
  );

  return (
    <View style={styles.commentItem}>
      <CommentBox
        isReplyVisible
        avatar={
          comment.userProfilePhotoUrl
            ? { uri: comment.userProfilePhotoUrl }
            : getDefaultAvatar(comment.userAvatarIndex)
        }
        userName={comment.userName}
        userJobTitle={comment.userJobTitle}
        text={comment.text}
        totalLikes={comment.likedBy?.length ?? 0}
        totalReplies={comment.replies ? comment.replies.length : 0}
        onPressReply={() => handleClickReply(comment.id)}
        rating={comment.rating ?? 0}
        ratingDate={formatDate(comment.createdAt)}
        commentIndex={commentIndex}
        onPressLike={() => handlePressLike(comment.id)}
        likedByCurrentUser={Boolean(
          currentUser?.id && comment.likedBy?.includes(currentUser?.id)
        )}
      />
      {comment.replies ? (
        <View style={styles.replyItem}>
          {withInputBox ? (
            <PostComment
              placeholder="Add a comment.."
              firstName="John"
              lastName="Doe"
              buttonTitle="Post"
              avatar={
                comment.userProfilePhotoUrl
                  ? { uri: comment.userProfilePhotoUrl }
                  : getDefaultAvatar(comment.userAvatarIndex)
              }
            />
          ) : null}
          {comment.replies ? (
            <FlatList
              data={comment.replies}
              renderItem={renderReplyItem}
              keyExtractor={keyExtractor}
            />
          ) : null}
        </View>
      ) : null}
    </View>
  );
};

const Reviews = ({
  commentData,
  handleClickReply,
  withReviewSummary,
  withInputBox,
  refetch,
}: ReviewsProps) => {
  const { currentUser } = useUserContext();
  const keyExtractor = ({ id }: Comment, _index: number) => id;

  const renderItem = ({ item, index }: { item: Comment; index: number }) => {
    return (
      <EachComment
        comment={item}
        commentIndex={index}
        handleClickReply={handleClickReply}
        withInputBox={!!withInputBox}
        refetch={refetch}
      />
    );
  };
  return (
    <View style={styles.wrap}>
      {withReviewSummary ? (
        <Text style={styles.ratingTotalText}>
          4.2 ratings • {commentData.length} reviews
        </Text>
      ) : null}
      {withInputBox ? (
        <PostComment
          avatar={userProfileImage(currentUser)}
          placeholder="Add a comment.."
          firstName="John"
          lastName="Doe"
          buttonTitle="Post"
        />
      ) : null}
      <FlatList
        data={commentData}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
      />
    </View>
  );
};

export { Reviews };
