import React, { useCallback, useRef, useState } from "react";

import { useFormikContext } from "formik";
import { DropZone, DropZoneProps, Spinner, Thumbnail } from "@shopify/polaris";
import { Col } from "@components/general/col";
import { StyleProp, TouchableOpacity, View, ViewStyle } from "react-native";
import { uploadFile } from "@utils/firebase";
import { uuidv4 } from "@firebase/util";
import { Row } from "@components/general/row";
import { styles } from "./style";
import { CrossIcon } from "@components/general/icons";

interface FormikDropzoneProps extends DropZoneProps {
  fieldName: string;
  style?: StyleProp<ViewStyle>;
  validFileTypes: string[];
  storageBaseUrl: string;
  actionHint?: string;
  currentValue?: { src: string; duration: number } | string | null;
  isVideo?: boolean;
}

const FormikDropzone = ({
  fieldName,
  style,
  actionHint,
  validFileTypes,
  currentValue,
  storageBaseUrl,
  isVideo,
  ...rest
}: FormikDropzoneProps) => {
  const [isUploading, setIsUploading] = useState(false);
  const { setFieldValue, values } = useFormikContext<any>();

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const handleDropZoneDrop = useCallback(
    async (_dropFiles: any, acceptedFiles: any, _rejectedFiles: any) => {
      setIsUploading(true);
      try {
        const uploadUrl = await uploadFile(
          acceptedFiles[0],
          `${storageBaseUrl}/${
            acceptedFiles[0]?.type ? acceptedFiles[0]?.type : ""
          }${uuidv4()}`
        );

        if (typeof currentValue === "string") {
          setFieldValue(fieldName, uploadUrl);
          return;
        }

        setFieldValue(fieldName, [uploadUrl]);
      } catch (error) {
        // TODO: Handle error
        console.log({ error });
      } finally {
        setIsUploading(false);
      }
    },
    [currentValue, fieldName, setFieldValue, storageBaseUrl]
  );

  const fileUpload = !currentValue ? (
    <DropZone.FileUpload actionHint={actionHint} />
  ) : null;

  const handleLoadedMetadata = () => {
    if (!isVideo) return;
    const video = videoRef.current;
    const currentValueWithDuration =
      typeof currentValue !== "string"
        ? {
            src: currentValue?.src,
            duration: Number((video?.duration || 0).toFixed(0)),
          }
        : null;

    if (currentValueWithDuration) {
      setFieldValue(fieldName, currentValueWithDuration);
    }
  };

  const uploadedFiles = currentValue ? (
    typeof currentValue === "string" ? (
      currentValue?.includes("application%2Fpdf") ? (
        <object
          data={currentValue}
          type="application/pdf"
          width="100%"
          height="100%"
        >
          <p>
            <a href={currentValue}>Link to the PDF!</a>
          </p>
        </object>
      ) : (
        <View style={{ minHeight: 100 }}>
          <Row style={styles.innerContainer}>
            <Thumbnail size="large" alt="File upload" source={currentValue} />
          </Row>
        </View>
      )
    ) : (
      <View style={{ minHeight: 300 }}>
        <Row style={styles.innerContainer}>
          {isVideo ? (
            <video
              src={currentValue.src}
              ref={videoRef}
              onLoadedMetadata={handleLoadedMetadata}
              style={{ maxWidth: 400 }}
            />
          ) : null}
        </Row>
      </View>
    )
  ) : null;

  return (
    <Col style={style}>
      <DropZone onDrop={handleDropZoneDrop} variableHeight>
        {uploadedFiles ? (
          <TouchableOpacity
            onPress={() => setFieldValue(fieldName, null)}
            style={styles.crossButton}
          >
            <CrossIcon />
          </TouchableOpacity>
        ) : null}
        {uploadedFiles}
        {isUploading ? (
          <Row style={styles.innerContainer}>
            <Spinner accessibilityLabel="Uploading spinner" size="large" />
          </Row>
        ) : (
          fileUpload
        )}
      </DropZone>
    </Col>
  );
};

export { FormikDropzone };
