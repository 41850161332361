import { Image, Text, TouchableOpacity, View } from "react-native";
import { styles } from "./style";
import Unlock from "../../../assets/img/Unlock.png";

export const LogoutPopupBack = ({ logout, setLogout }: any) => {
  return (
    <>
      <TouchableOpacity
        onPress={() => setLogout(false)}
        style={[
          styles.popupBackGround,
          {
            ...(logout && { overflow: "hidden" }),
          },
        ]}
      />
      <View style={[styles.popup, { alignItems: "center" }]}>
        <Image
          source={Unlock}
          style={{
            width: 30,
            height: 30,
            marginTop: 41,
            marginBottom: 23,
          }}
        />
        <Text
          style={[
            styles.title,
            {
              marginTop: 0,
              width: 320,
              height: 92,
            },
          ]}
        >
          You are attempting to log out of TRN Site. Do you really want to log
          out?
        </Text>
        <TouchableOpacity
          style={{ marginTop: 19 }}
          onPress={() => setLogout(false)}
        >
          <Text style={styles.closeBtn}>Yes</Text>
        </TouchableOpacity>
      </View>
    </>
  );
};
