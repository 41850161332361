import React from "react";
import { View } from "react-native";
import { ContentBox } from "@components/general/layouts/content-box";
import { AnalyticsCountBoxes } from "@components/back-office/analytics/analytics-count-boxes";
import { AnalyticsAveragePageTime } from "@components/back-office/analytics/analytics-average-page-time";
import { AnalyticsMostVisitedPage } from "@components/back-office/analytics/analytics-most-visited-page";
import { ids, styles } from "./style";
import { useAdminAnalyticsGeneral } from "../hooks/use-general-analytics";
import { AnalyticsHeader } from "@components/back-office/analytics/analytics-header";

const AnalyticsGeneral = () => {
  const {
    boxItems,
    averagePageTime,
    refetchPageTime,
    contentData,
    refetchContentHub,
    dataForExport,
    loadingPageTime,
  } = useAdminAnalyticsGeneral();

  return (
    <>
      <AnalyticsHeader excelData={dataForExport} path="general" />
      <ContentBox>
        <View style={styles.countBoxesWrap}>
          <AnalyticsCountBoxes items={boxItems} />
        </View>
        <View style={styles.split} dataSet={{ media: ids.split }}>
          <View style={styles.leftItem}>
            <AnalyticsAveragePageTime
              {...averagePageTime}
              refetch={refetchPageTime}
              loading={loadingPageTime}
            />
            <AnalyticsMostVisitedPage
              data={contentData}
              refetch={refetchContentHub}
            />
          </View>
        </View>
      </ContentBox>
    </>
  );
};

export { AnalyticsGeneral };
