import { Text, TouchableOpacity, View } from "react-native";
import React, { useEffect, useMemo, useState } from "react";
import { PageVariant } from "@utils/models";
import { ids, styles } from "./style";
import { FilterAction } from "@hooks/useFilters";
import { useLocation, useNavigate } from "react-router-native";
import { SortBy, TrainingSortBy } from "@gql/generated/generated";

interface SortByTabsProps {
  variant: PageVariant;
  setFilters: any;
}

const Buttons: { label: string; value: string }[] = [
  { label: "Recommended", value: SortBy.RECOMMENDED },
  { label: "Most Popular", value: SortBy.POPULARITY },
  { label: "Most Recent", value: SortBy.MOST_RECENT },
];

const EventButtons: { label: string; value: string }[] = [
  { label: "Upcoming Events", value: SortBy.MOST_RECENT },
  { label: "Recommended", value: SortBy.RECOMMENDED },
];

const TrainingButtons = [
  { label: "Recommended", value: TrainingSortBy.RECOMMENDED },
  { label: "Most Popular", value: TrainingSortBy.POPULARITY },
  { label: "Most Recent", value: TrainingSortBy.MOST_RECENT },
  { label: "Enrolled", value: TrainingSortBy.ENROLLED },
];

const SortByTabs = ({ variant, setFilters }: SortByTabsProps) => {
  const [buttonFilterOn, setButtonFilterOn] = useState(0);
  const tabs = useMemo(() => {
    switch (variant) {
      case PageVariant.training:
        return TrainingButtons;
      case PageVariant.event:
        return EventButtons;
      case PageVariant.content:
      default:
        return Buttons;
    }
  }, [variant]);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setButtonFilterOn(0);
    setFilters({
      type: FilterAction.SET_SORT_BY,
      payload: [tabs[0].value],
    });
  }, [location, setFilters, tabs]);

  return (
    <View style={styles.filters} dataSet={{ media: ids.filters }}>
      <View style={styles.buttons}>
        {tabs.map(({ label, value }, index: number) => {
          return (
            <View style={styles.buttonWrapper} key={label}>
              <TouchableOpacity
                onPress={() => {
                  setButtonFilterOn(index);
                  setFilters({
                    type: FilterAction.SET_SORT_BY,
                    payload: [value],
                  });
                }}
                key={label}
                style={styles.buttonTextContainer}
              >
                <Text
                  style={
                    buttonFilterOn === index
                      ? styles.buttonSelected
                      : styles.buttonText
                  }
                  dataSet={{
                    media:
                      buttonFilterOn === index
                        ? ids.buttonSelected
                        : ids.buttonText,
                  }}
                >
                  {label}
                </Text>
              </TouchableOpacity>
              {buttonFilterOn === index && (
                <View style={styles.buttonHoverLine} />
              )}
            </View>
          );
        })}
      </View>
      {variant === PageVariant.training ? null : (
        <TouchableOpacity onPress={() => navigate("/account/help/request")}>
          <Text style={styles.requestText}>Request {variant}</Text>
        </TouchableOpacity>
      )}
    </View>
  );
};

export { SortByTabs };
