import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  wrapper: {
    width: "100%",
    paddingBottom: 16,
  },
  titleWrap: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 16,
    paddingBottom: 16,
  },
  wrapperBorder: {
    borderBottomWidth: 1,
    borderBottomColor: "#EDEEEF",
  },
  title: {
    color: "#0D1738",
    fontSize: 16,
    fontFamily: "OpenSans_600SemiBold",
  },
  seeAllWrap: {
    paddingTop: 16,
    paddingRight: 20,
    paddingBottom: 16,
    paddingLeft: 20,
  },
  seeAllText: {
    color: "#2C6ECB",
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
  },
  seeAllTextBold: {
    fontFamily: "OpenSans_700Bold",
  },
});
