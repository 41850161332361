import { useEffect, useState } from "react";
import { ActivityIndicator, Modal, View } from "react-native";
import { ContentBox } from "@components/general/layouts/content-box";
import { TitleBox } from "@components/account/title-box";
import { TabModule } from "@components/org/saved/tabs/TabModule";
import { Tab } from "@components/org/saved/tabs/Tab";
import { SavedItemInfo } from "@components/org/saved/saved-item/saved-item-info";
import { SavedItemContainer } from "@components/org/saved/saved-item/saved-item-container";
import { LeftSidePanel } from "@components/home/left-side-panel";
import { ids, styles } from "@pages/home/style";
import { ModalContainer } from "@components/org/saved/modals/ModalContainer";
import { ConfirmationModalContent } from "@components/org/saved/modals/confirmation-modal/ConfirmationModalContent";
import { GenericModalLayout } from "@components/org/saved/modals/generic-modal-layout/GenericModalLayout";
import { PageVariant } from "@utils/models";
import {
  ListType,
  SavedItemType,
  SavedListAction,
  User,
  UserAssignedItemsInput,
  useEmployerGetUserAssignmentsQuery,
  useGetUserAssignmentsQuery,
  useGetUserSavedItemsQuery,
  useUpdateSavedListMutation,
  useEmployerUpdateAssignedListMutation,
} from "@gql/generated/generated";
import { tabs } from "@components/org/saved/mockData";

interface Props {
  variant: PageVariant.saved | PageVariant.assignment | PageVariant.member;
  title: string;
  member?: User;
  refetchMember?: () => Promise<any>;
}

const SavedOrAssignedContent = ({
  variant,
  title,
  member,
  refetchMember,
}: Props) => {
  const [tabs, setTabs] = useState([
    {
      title: "All",
      active: true,
      notification: 0,
      type: SavedItemType.ALL,
    },
    {
      title: "Content",
      active: false,
      notification: 0,
      type: SavedItemType.CONTENT,
    },
    {
      title: "Events",
      active: false,
      notification: 0,
      type: SavedItemType.EVENTS,
    },
    {
      title: "Training",
      active: false,
      notification: 0,
      type: SavedItemType.TRAININGS,
    },
  ]);

  const [pageSelected, setPageSelected] = useState(0);
  const [updateSavedList] = useUpdateSavedListMutation();
  const [employerUpdateAssignedList] = useEmployerUpdateAssignedListMutation();
  // fetch user saved items, skip if variant is not saved
  const {
    data: savedItems,
    loading: loadingSavedItems,
    refetch: refetchSavedItems,
  } = useGetUserSavedItemsQuery({
    variables: {
      input: {
        page: pageSelected,
        limit: 9,
        type: tabs.filter((tab) => tab.active)[0].type,
      },
    },
    skip: variant !== PageVariant.saved,
    initialFetchPolicy: "network-only",
  });

  // fetch user assignment items, skip if variant is not assignment
  const {
    data: assignedItems,
    loading: loadingAssignedItems,
    refetch: refetchAssignedItems,
  } = useGetUserAssignmentsQuery({
    variables: {
      input: {
        page: pageSelected,
        limit: 9,
        type: tabs.filter((tab) => tab.active)[0].type,
      },
    },
    skip: variant !== PageVariant.assignment,
    initialFetchPolicy: "network-only",
  });

  const userId = member?.id || "";
  const {
    data: memberAssignedItems,
    loading: loadingMemberAssignedItems,
    refetch: refetchMemberAssignedItems,
  } = useEmployerGetUserAssignmentsQuery({
    variables: {
      input: {
        page: pageSelected,
        limit: 9,
        type: tabs.filter((tab) => tab.active)[0].type,
      },
      userId,
    },
    skip: variant !== PageVariant.member,
    fetchPolicy: "no-cache",
  });

  const data =
    savedItems?.getUserSavedItems ||
    assignedItems?.getUserAssignments ||
    memberAssignedItems?.employerGetUserAssignments;

  useEffect(() => {
    if (typeof data !== "undefined") {
      const currentTabs = [...tabs].map((tab) => {
        if (tab.title === "Training")
          return {
            ...tab,
            notification: data?.trainingsTotal ?? 0,
          };
        if (tab.title === "Content")
          return {
            ...tab,
            notification: data?.contentTotal ?? 0,
          };
        if (tab.title === "Events")
          return {
            ...tab,
            notification: data?.eventsTotal ?? 0,
          };
        return {
          ...tab,
          notification: data?.allTotal ?? 0,
        };
      });
      setTabs(currentTabs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data?.allTotal,
    data?.contentTotal,
    data?.eventsTotal,
    data?.trainingsTotal,
  ]);

  const currentUserSavedItems =
    savedItems?.getUserSavedItems?.savedItems ||
    assignedItems?.getUserAssignments?.assignedItems ||
    memberAssignedItems?.employerGetUserAssignments?.assignedItems ||
    [];

  const [modalData, setModalData] = useState<{
    id: string;
    type: string;
    listType: ListType;
    createdAt: number;
    isCompleted: boolean;
  } | null>(null);

  const cancelModalHandler = () => {
    setModalData(null);
  };

  const handleUndo = async () => {
    if (!modalData) return;
    const { id, type, createdAt, listType, isCompleted } = modalData;
    await updateSavedList({
      variables: {
        input: {
          action: SavedListAction.RESTORE_TO_USER,
          item: {
            id,
            type,
            createdAt,
            isCompleted,
          },
          listType,
        },
      },
    });
    if (variant === PageVariant.saved) {
      await refetchSavedItems();
    }
    if (variant === PageVariant.assignment) {
      await refetchAssignedItems();
    }
    if (variant === PageVariant.member) {
      await refetchMemberAssignedItems();
    }

    const tabsToUpdate = [...tabs].map((tab) => {
      if (tab.type.includes(type)) {
        return { ...tab, notification: tab.notification + 1 };
      }
      return tab;
    });
    setTabs(tabsToUpdate);
    setModalData(null);
  };

  const handleDelete = async ({
    id,
    type,
    listType,
    __typename,
    createdAt,
    isCompleted,
  }: {
    id: string;
    type: string;
    listType: ListType;
    __typename: string;
    createdAt: number;
    isCompleted: boolean;
  }) => {
    setModalData({ id, type, listType, createdAt, isCompleted });

    const updateParams = {
      variables: {
        input: {
          action: SavedListAction.REMOVE,
          item: {
            id,
            type,
          },
          listType,
        },
        userId,
      },
      // @ts-ignore - normally this would be inlined in the hook call, but we are making tow types of update call dependning on the variant
      update: (store, _result, options) => {
        const id = options.variables?.input.item.id;
        if (id) {
          const normalizedId = store.identify({
            id,
            __typename,
          });
          store.evict({ id: normalizedId });
          store.gc();
        }
      },
    };

    if (variant === PageVariant.member) {
      await employerUpdateAssignedList({
        variables: updateParams.variables,

        update: updateParams.update,
      });
      // also need to refetch the member assigned items data to then update the assignments
      await (refetchMember || (() => Promise.resolve()))();
    } else {
      await updateSavedList(updateParams);
    }

    const tabsToUpdate = [...tabs].map((tab) => {
      if (tab.type.includes(type)) {
        return { ...tab, notification: tab.notification - 1 };
      }
      return tab;
    });
    setTabs(tabsToUpdate);
  };

  return (
    <>
      <Modal visible={!!modalData} transparent>
        <ModalContainer>
          <GenericModalLayout
            title="The content has been removed from your list"
            closeModal={cancelModalHandler}
          >
            <ConfirmationModalContent
              mainText="Change your mind?"
              handleUndo={handleUndo}
            />
          </GenericModalLayout>
        </ModalContainer>
      </Modal>
      <ContentBox style={{ height: "100%", width: "100%", maxWidth: 956 }}>
        <TitleBox title={title} />
        <TabModule withTimeDropDown={false}>
          {tabs.map((tab, i) => (
            <Tab
              key={tab.title}
              title={tab.title}
              notification={tab.notification ?? 0}
              active={tab.active}
              onPress={() => {
                const newTabs = [...tabs].map((tab, j) => {
                  if (i === j) {
                    return {
                      ...tab,
                      active: true,
                    };
                  }
                  return {
                    ...tab,
                    active: false,
                  };
                });
                setTabs(newTabs);
              }}
            />
          ))}
        </TabModule>
        {loadingSavedItems ||
        loadingAssignedItems ||
        loadingMemberAssignedItems ? (
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ActivityIndicator size="large" color="#213470" />
          </View>
        ) : (
          <SavedItemContainer
            total={tabs.filter((tab) => tab.active)[0].notification}
            pageSelected={pageSelected}
            setPageSelected={setPageSelected}
          >
            {currentUserSavedItems?.map((item) => {
              return (
                <SavedItemInfo
                  key={item.id}
                  item={item}
                  handleDelete={handleDelete}
                  variant={variant}
                />
              );
            })}
          </SavedItemContainer>
        )}
      </ContentBox>
    </>
  );
};

const Saved = () => {
  return (
    <>
      <View style={[styles.container, { marginTop: 104 }]}>
        <View style={styles.leftWrap} dataSet={{ media: ids.leftWrap }}>
          <LeftSidePanel />
        </View>
        <SavedOrAssignedContent
          title="Saved Items"
          variant={PageVariant.saved}
        />
      </View>
    </>
  );
};

export { Saved, SavedOrAssignedContent };
