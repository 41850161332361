import { ActivityIndicator, View } from "react-native";
import { RoadmapWrapper } from "@components/roadmap/roadmap-wrapper";
import { PlanBlock } from "@components/roadmap/plan-block";
import { FormModal } from "@pages/roadmap/form-modal";
import { useUserContext } from "@context/UserContext";
import { useRoadmapSubmodules } from "@pages/roadmap/hooks/useRoadmapSubmodules";
import { ids, styles } from "./style";
import {
  getCurrentRoadmapItemsFromData,
  getNonEmptyAnswersPercentage,
  getQuestionAndAnswerCount,
} from "../../utils";
import { useEmployerGetRoadmapQuery } from "@gql/generated/generated";
import { roadmapSectionToSubmodule } from "@utils/roadmapSectionToSubmodule";

const GoalIndex = () => {
  const {
    submodule,
    isVisible,
    handlePlay,
    setIsVisible,
  } = useRoadmapSubmodules();

  const { currentUser, loadingCurrentUser } = useUserContext();

  const orgId = currentUser?.accountInfo?.companyInfo?.id;
  const { data, loading } = useEmployerGetRoadmapQuery({
    skip: !orgId,
  });

  const { goalSections } =
    (data?.employerGetRoadmap &&
      getCurrentRoadmapItemsFromData(data?.employerGetRoadmap)) ||
    {};

  const goalSectionsData =
    goalSections && getQuestionAndAnswerCount(goalSections);

  if (loadingCurrentUser || loading) {
    return (
      <View
        style={{
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ActivityIndicator size="large" color="#213470" />
      </View>
    );
  }

  return (
    <RoadmapWrapper
      title="Goal"
      totalAnswered={goalSectionsData?.answerCount ?? 0}
      totalQuestion={goalSectionsData?.questionCount ?? 0}
    >
      <View style={styles.wrap} dataSet={{ media: ids.wrap }}>
        {goalSections &&
          goalSections.map((section) => (
            <PlanBlock
              key={section.id}
              title={section.name ?? ""}
              percent={Number(getNonEmptyAnswersPercentage(section.questions))}
              totalQuestion={section.questions.length}
              onPlay={() => {
                const submodule = roadmapSectionToSubmodule(section);
                if (submodule) {
                  handlePlay(submodule);
                }
              }}
            />
          ))}
      </View>
      {submodule && data?.employerGetRoadmap?.id ? (
        <FormModal
          isVisible={isVisible}
          onClose={() => setIsVisible(false)}
          submodule={submodule}
          roadmapSection="goal"
        />
      ) : null}
    </RoadmapWrapper>
  );
};

export { GoalIndex };
