import { Platform } from "react-native";
import StyleSheet from "react-native-media-query";
import { mediaQuery } from "@utils/misc";

export const { ids, styles } = StyleSheet.create({
  boardWrap: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    ...(Platform.OS === "web" && { gap: 8 }),
    // overflow: "scroll",
    [mediaQuery.forMobile]: {
      flexDirection: "column",
    },
  },
});
