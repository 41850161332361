import StyleSheet from "react-native-media-query";
import { mediaQuery } from "@utils/misc";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    marginTop: 100,
    width: "100%",
    alignItems: "center",
    [mediaQuery.forMobile]: {
      marginTop: 0,
    },
  },
  inner: {
    width: "100%",
    maxWidth: 1204,
    "@media (min-width: 768px)": {
      maxWidth: 600,
    },
  },
});
