import { useQueryFilters } from "../useGridFilters";
import { TableDropdown } from "@components/back-office/tables/table-dropdown";

const sortByItems = [
  { id: "Admin", content: "Admin" },
  { id: "Employer", content: "Employer" },
  { id: "Employee", content: "Employee" },
];

export const RoleFilter = () => {
  const { filters, setFilterValue } = useQueryFilters(["role"]);
  return (
    <TableDropdown
      placeholder={filters?.role ? filters?.role : "Role"}
      items={sortByItems}
      onSelectItem={(id) => setFilterValue("role", id)}
      minWidth={130}
      isSelected={!!filters.role}
      onReset={() => setFilterValue("role", undefined)}
    />
  );
};
