import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const Assignments = (props: SvgProps) => (
  <Svg width={20} height={20} fill="none" {...props}>
    <Path
      fill="#0D1738"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 3.333C2 2.597 2.597 2 3.333 2h13.334C17.403 2 18 2.597 18 3.333v13.334c0 .736-.597 1.333-1.333 1.333H3.333A1.333 1.333 0 0 1 2 16.667V3.333zm6.222 2.223h7.111v1.777h-7.11V5.556zm7.111 3.555h-7.11v1.778h7.11V9.11zm-7.11 3.556h7.11v1.777h-7.11v-1.777zM5.555 7.333a.889.889 0 1 0 0-1.777.889.889 0 0 0 0 1.777zM6.444 10a.889.889 0 1 1-1.777 0 .889.889 0 0 1 1.777 0zm-.888 4.444a.889.889 0 1 0 0-1.777.889.889 0 0 0 0 1.777z"
    />
  </Svg>
);

export { Assignments };
