import React from "react";
import { Image, Linking, Text, TouchableOpacity, View } from "react-native";
import { Checkbox } from "@shopify/polaris";
import { styles } from "./style";
import { ExternalSmallMinor } from "@components/general/icons/back-office-icons/ExternalSmallMinor";
import { StarIcon } from "@components/general/icons/feed-icons";
import { APP_URL } from "@env";

interface Props {
  title: string;
  dateDuration: string;
  tags?: string[];
  statistic?: string;
  id?: string;
  type?: string;
  isChecked?: boolean;
  coverImage?: string;
  setIsChecked?: (value: boolean) => void;
}

export const ResultSearchCard = ({
  title,
  dateDuration,
  tags,
  statistic,
  id,
  type,
  coverImage,
  isChecked,
  setIsChecked,
}: Props) => {
  return (
    <View
      style={{
        paddingVertical: 8,
        paddingHorizontal: 20,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <View style={{ marginRight: 8 }}>
        <Checkbox
          label="save"
          labelHidden
          checked={isChecked}
          onChange={setIsChecked}
        />
      </View>
      <View
        style={{
          flexDirection: "row",
          flex: 1,
          alignItems: "center",
        }}
      >
        <Image
          source={
            coverImage ? coverImage : require("/assets/img/SaveItemIamge3.png")
          }
          style={{ width: 56, height: 56, borderRadius: 4, marginRight: 8 }}
        />
        <View>
          <Text style={styles.textBoldDark}>{title}</Text>
          <Text style={[styles.textBoldDarkSmallBlue, { marginTop: 4 }]}>
            {dateDuration}
          </Text>

          <View style={{ flexDirection: "row", marginTop: 4 }}>
            {tags ? (
              tags?.map((tag, index) => (
                <Text style={styles.textTag} key={index}>
                  {tag}
                </Text>
              ))
            ) : (
              <View style={[styles.statisticContainer, { marginTop: 0 }]}>
                <StarIcon />
                <Text style={styles.textRegularGraySmall}>{statistic}</Text>
              </View>
            )}
          </View>
        </View>
      </View>
      <TouchableOpacity
        style={{
          flexDirection: "row",
          alignItems: "center",
        }}
        onPress={() => Linking.openURL(`${APP_URL}/${type}/${id}`)}
      >
        <Text
          style={[
            styles.textBoldDarkSmallBlue,
            { textDecorationLine: "underline", marginRight: 4 },
          ]}
        >
          View page
        </Text>
        <ExternalSmallMinor />
      </TouchableOpacity>
    </View>
  );
};
