import { useNavigate } from "react-router-native";
import { signOut } from "firebase/auth";
import { useUserContext } from "@context/UserContext";
import { auth } from "../firebase";

const useAuth = () => {
  const navigate = useNavigate();
  const { currentUser, refetch } = useUserContext();

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        navigate("/");
      })
      .catch((): void => {
        console.log('A sign-out error occurred');
      });
  };

  return {
    refetch,
    navigate,
    user: currentUser,
    handleSignOut,
  };
};

export { useAuth };
