import { Text, View } from "react-native";
import { Pill } from "../pill/Pill";
import { styles } from "./style";
import { Row } from "../row";
import { TooltipWithChild } from "../tooltip";

interface JobRolesWithTooltipProps {
  jobRoles?: string[] | null;
  noMargin?: boolean;
}

const JobRolesWithTooltip = ({
  jobRoles,
  noMargin,
}: JobRolesWithTooltipProps) => {
  const tidiedJobRoles = jobRoles?.map((role: string) => {
    let shortenedRole = role;
    if (role.includes(" - ")) {
      shortenedRole = role.split(" - ")[0];
    } else if (role.includes("/")) {
      shortenedRole = role.split("/")[0];
    }
    return shortenedRole;
  });

  return (
    <Row
      style={[
        styles.jobRolesRow,
        noMargin && { marginTop: 0, marginBottom: 0 },
      ]}
    >
      <Text style={styles.jobRoleText} numberOfLines={1}>
        Job role:
      </Text>
      {tidiedJobRoles
        ? tidiedJobRoles.slice(0, 2).map((role, i) => {
            if (role.length < 13) {
              return (
                <Pill
                  allowShrink
                  withMarginRight
                  key={`${role}${i}`}
                  text={role}
                />
              );
            }
            return (
              <View
                style={{ flex: 1, maxWidth: "fit-content" }}
                key={`${role}${i}`}
              >
                <TooltipWithChild toolTipText={role} width={100}>
                  <Pill text={role.slice(0, 10) + "..."} />
                </TooltipWithChild>
              </View>
            );
          })
        : null}

      {jobRoles && jobRoles?.length > 2 ? (
        <TooltipWithChild
          toolTipText={tidiedJobRoles?.slice(2).join(", ")}
          width={100}
        >
          <Pill text={`+${jobRoles.length - 2}`} />
        </TooltipWithChild>
      ) : null}
    </Row>
  );
};

export { JobRolesWithTooltip };
