import React, { useState } from "react";
import { Platform, Text, TouchableOpacity, View } from "react-native";
import { ActionList, ActionListSection, Popover } from "@shopify/polaris";
import { ContentBox } from "@components/general/layouts/content-box";
import { ThreeDots } from "@components/general/icons/training-icons/ThreeDots";
import { styles } from "./style";
import { RoadmapQuestion } from "@gql/generated/generated";

// const getPillColor = (percent: number) => {
//   if (percent > 6.2) {
//     return "#AEE9D1";
//   } else if (percent > 2.3) {
//     return "#FFD79D";
//   } else {
//     return "#FED3D1";
//   }
// };

// interface PriorityPillProps {
//   percent: number;
//   isDefault?: boolean;
// }

// const PriorityPill = ({ percent, isDefault }: PriorityPillProps) => (
//   <View
//     style={[
//       styles.pill,
//       !isDefault && { backgroundColor: getPillColor(percent) },
//     ]}
//   >
//     <Text style={styles.pillText}>{isDefault ? `${percent}%` : percent}</Text>
//   </View>
// );

interface PriorityItemProps {
  // percent: number;
  title: string;
}

const PriorityItem = ({ title }: PriorityItemProps) => (
  <View style={styles.expandedItem}>
    {/* <PriorityPill percent={percent} isDefault /> */}
    <Text style={styles.expandedItemText}>{title}</Text>
  </View>
);

interface Props {
  title: string;
  // percent: number;
  // point: number;
  items: RoadmapQuestion[];
  // onChange: (destinationBoard: RoadmapPriorityBoardName) => void;
  // currentBoard: RoadmapPriorityBoardName;
  dropdownSections?: ActionListSection[];
}

export const Card = ({
  title,
  // percent,
  // point,
  items,
  dropdownSections,
}: Props) => {
  // const [isExpanded, setIsExpanded] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  return (
    <ContentBox style={styles.contentBox}>
      <View style={[styles.flexSpace, styles.topWrap]}>
        <Text style={styles.title}>{title}</Text>
        {Platform.OS === "web" && !!dropdownSections?.length && (
          <Popover
            active={isDropdownVisible}
            activator={
              <TouchableOpacity
                onPress={() => setIsDropdownVisible(true)}
                style={styles.threeDotsTouch}
              >
                <ThreeDots />
              </TouchableOpacity>
            }
            onClose={() => setIsDropdownVisible(false)}
            preferredAlignment="left"
          >
            <ActionList actionRole="menuitem" sections={dropdownSections} />
          </Popover>
        )}
      </View>
      <View style={styles.flexSpace}>
        {/* <View style={styles.pillWrap}>
          <PriorityPill percent={point} />
          <Text style={styles.percentCompleteText}>{percent}% Complete</Text>
        </View> */}
        {/* <TouchableOpacity onPress={() => setIsExpanded(!isExpanded)}>
          <Text style={styles.showMoreText}>
            {isExpanded ? "Show Less" : "Show More"}
          </Text>
        </TouchableOpacity> */}
      </View>
      {
        // isExpanded &&
        <View style={styles.expandedContent}>
          {items.map(({ subcategory }) => (
            <PriorityItem
              key={subcategory}
              // percent={item.percent}
              title={subcategory ?? ''}
            />
          ))}
        </View>
      }
    </ContentBox>
  );
};
