import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import {
  EventNotificationDetails,
  GetContentDocument,
  GetEventDocument,
  GetNotificationDocument,
  GetTrainingDocument,
  Notification,
  NotificationType,
  NotifyRepullNotificationDocument,
  TrainingNotificationDetails,
} from "@gql/generated/generated"
import dayjs from 'dayjs';
import { fromSecondsToHours } from '@utils/trainings';

export const getNotificationPollInterval = () => 300000;

const extractIdFromUrl = (notification: Notification): string | undefined => {
  switch (notification.type) {
    case NotificationType.CONTENT:
      return notification.url?.replace("/content/", "");
    case NotificationType.TRAINING:
      return notification.url?.replace("/training", "");
    case NotificationType.EVENT:
      return notification.url?.replace("/events/", "");
    default:
      return undefined;
  }
};

/**
 * Tells the backend to update the notification from the source.
 *
 * We do not supply the date, coverImage etc here because frontend should not be trusted
 * to supply these things for the database. Instead the backend will calculate it.
 */
const notifyRepullNotification = async (apolloClient: ApolloClient<NormalizedCacheObject>, notification: Notification, userId: string) => {
  return await apolloClient.mutate({
    mutation: NotifyRepullNotificationDocument,
    variables: {
      userId,
      id: notification.id
    }
  });
};

const getNotification = async (apolloClient: ApolloClient<NormalizedCacheObject>, notification: Notification, userId: string): Promise<Notification> => {
  const { data } = await apolloClient.query({
    query: GetNotificationDocument,
    variables: {
      userId,
      id: notification.id
    }
  });

  const notif = data?.getNotification?.__typename === 'Notification' ? data.getNotification : notification;

  return notif as Notification;
}

const getPopulatedContentNotification = async (apolloClient: ApolloClient<NormalizedCacheObject>, notification: any, userId: string): Promise<any>  => {
  // If we already have a cover image, do nothing.
  if (notification.coverImage) {
    return notification;
  }

  const id = extractIdFromUrl(notification);

  // This should never happen but just saves trying to fetch a URL that doesnt exist.
  if (!id) {
    return notification;
  }

  try {
    const { data } = await apolloClient.query({
      query: GetContentDocument,
      variables: { getContentId: id },
    });

    const content = data?.getContent.__typename === 'Content' ? data.getContent : null;

    // If the content either doesn't exist or doesn't have a cover image, there's nothing to update.
    if (!content || !content.coverImage) {
      console.log(content);
      return notification;
    }

    // Tell the notification to update.
    notifyRepullNotification(apolloClient, notification as Notification, userId);

    // Re-fetch the notification & return it.
    return await getNotification(apolloClient, notification, userId);
  } catch (error) {
    return notification;
  }
};

const getPopulatedEventNotification = async (apolloClient: ApolloClient<NormalizedCacheObject>, notification: Notification, userId: string): Promise<Notification> => {
  const details = notification.details as EventNotificationDetails;

  // If we already have all expected fields, do nothing.
  if (!!notification.coverImage && !!details?.eventLocation && !!details?.eventDate) {
    return notification;
  }

  const id = extractIdFromUrl(notification);

  // This should never happen but just saves trying to fetch a URL that doesnt exist.
  if (!id) {
    return notification;
  }

  try {
    const { data } = await  apolloClient.query({
      query: GetEventDocument,
      variables: { id }
    });

    const event = data?.getEvent?.__typename === 'Event' ? data.getEvent : null;

    // If the event either doesn't exist or has NONE of the data we need, there's nothing to update
    if (!event || ((!event.coverImage || !!notification.coverImage) && (!event.dateFrom || !!details?.eventDate) && (!event.location || !!details?.eventLocation))) {
      return notification as Notification;
    }

    // Tell the notification to update.
    notifyRepullNotification(apolloClient, notification as Notification, userId);

    // Re-fetch the notification & return it.
    return await getNotification(apolloClient, notification, userId);
  } catch (error) {
    return notification;
  }
};

const getPopulatedTrainingNotification = async (apolloClient: ApolloClient<NormalizedCacheObject>, notification: Notification, userId: string): Promise<Notification> => {
  const details = notification.details as TrainingNotificationDetails;

  // If we already have all expected fields, do nothing.
  if (!!notification.coverImage && !!details?.lessons && !!details?.durationInSeconds) {
    return notification;
  }

  const id = extractIdFromUrl(notification);

  // This should never happen but just saves trying to fetch a URL that doesnt exist.
  if (!id) {
    return notification;
  }

  try {
    const { data } = await apolloClient.query({
      query: GetTrainingDocument,
      variables: { id }
    });
    const training = data?.getTraining?.__typename === 'Training' ? data.getTraining : null;

    // If the training either doesn't exist or has NONE of the data we need, there's nothing to update.
    if (!training || ((!training.coverImage || !!notification.coverImage) && (!training.numLessons || !!details?.lessons) && (!training.durationInSeconds || !!details?.durationInSeconds))) {
      return notification;
    }

    // Tell the notification to update.
    notifyRepullNotification(apolloClient, notification as Notification, userId);

    // Re-fetch the notification & return it.
    return await getNotification(apolloClient, notification, userId);
  } catch (error) {
    // Probably the source was deleted.
    return notification;
  }
};

/**
 * This takes a Notification and attempts to update the attributes of the notification from
 * the backend source Content, Event or Training record.
 */
export const getPopulatedNotification = async (apolloClient: ApolloClient<NormalizedCacheObject>, notification: Notification, userId: string): Promise<Notification> => {
  switch (notification.type) {
    case NotificationType.CONTENT:
      return await getPopulatedContentNotification(apolloClient, notification, userId);
    case NotificationType.EVENT:
      return await getPopulatedEventNotification(apolloClient, notification, userId);
    case NotificationType.TRAINING:
      return await getPopulatedTrainingNotification(apolloClient, notification, userId);
    default:
      return notification as Notification;
  }
};

const getPostDate = (notification: Notification) => {
  if (!notification.createdAt) {
    return "";
  }

  const date = dayjs(new Date(notification.createdAt)).format("DD-MM-YYYY HH:MM");

  return `Published: ${date}`;
};

const getContentSummary = (notification: Notification) => {
  return getPostDate(notification);
};

const getEmployerSummary = (notification: Notification) => {
  return getPostDate(notification);
};

const getEventDate = (notificationDetails: EventNotificationDetails) => {
  return notificationDetails.eventDate
    ? `Date: ${dayjs(new Date(notificationDetails.eventDate)).format("DD-MM-YYYY HH:MM")} • `
    : '';
};

const getEventLocation = (notificationDetails: EventNotificationDetails) => {
  return notificationDetails.eventLocation
    ? `Location: ${notificationDetails.eventLocation} • `
    : "Virtual Event • ";
};

const getEventSummary = (notification: Notification) => {
    const details = notification.details as EventNotificationDetails;

    return `${getEventDate(details)}${getEventLocation(details)}${getPostDate(notification)}`
};

const getLessonsQuantity = (notificationDetails: TrainingNotificationDetails) => {
    return `Lessons: ${notificationDetails.lessons || 'Unknown'} • `;
};

const getLessonsLength = (notificationDetails: TrainingNotificationDetails) => {
  if (!notificationDetails.durationInSeconds) {
    return '';
  }

  const durationInHours = fromSecondsToHours(notificationDetails.durationInSeconds);
  const unit = durationInHours === 1 ? 'hour' : 'hours';

  return `Duration: ${durationInHours} ${unit} • `;
};

const getTrainingSummary = (notification: Notification) => {
  const details = notification.details as TrainingNotificationDetails;

  return `${getLessonsQuantity(details)}${getLessonsLength(details)}${getPostDate(notification)}`;
};

export const getNotificationBottomText = (notification: Notification) => {
  switch (notification.type) {
    case NotificationType.CONTENT:
      return `Content • ${getContentSummary(notification)}`;
    case NotificationType.EMPLOYER:
      return `Employer • ${getEmployerSummary(notification)}`;
    case NotificationType.EVENT:
      return `Event • ${getEventSummary(notification)}`;
    case NotificationType.TRAINING:
      return `Training • ${getTrainingSummary(notification)}`;
    default:
      return "";
  }
};

export const getNotificationTextAndColorFromNotificationType = (notification: Notification) => {
  // All notifications are currently of type CREATED.
  return { text: "New", color: "#2C6ECB" };
};

