import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  flexContainer: { padding: 20, flexDirection: "row", gap: 12 },
  imageContainer: {
    width: 52,
    height: 52,
    borderRadius: 6,
    overflow: "hidden",
  },
  title: {
    color: "#0D1738",
    fontSize: 14,
    fontWeight: "600",
    lineHeight: 20,
  },
  subtitle: {
    color: "#0D1738",
    fontSize: 12,
    fontWeight: "400",
    lineHeight: 16,
  },
  details: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 12,
    lineHeight: 16,
    color: "#6D7175",
  },
  image: {
    width: "100%",
    height: "100%",
  },
});
