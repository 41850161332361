import React, { useEffect } from "react";
import { ActivityIndicator, Platform, View } from "react-native";
import { useNavigate, useSearchParams } from "react-router-native";
import { ButtonGeneral, ButtonType } from "@components/general/button-general";
import { AuthWrapper } from "@components/sign-up/auth-wrapper";
import { useVerifyUserEmailMutation } from "@gql/generated/generated";
import { auth } from "../../../firebase";

const EmailVerified = () => {
  const navigation = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [verifyUser, { loading }] = useVerifyUserEmailMutation();

  useEffect(() => {
    if (id) {
      verifyUser({ variables: { verifyUserEmailId: id } }).catch(console.error);
      if (auth?.currentUser) {
        auth?.currentUser.reload();
      }
    }
  }, [id]);

  if (loading) {
    return (
      <View
        style={{
          height: Platform.OS === "web" ? "100vh" : "100%",
          width: Platform.OS === "web" ? "100vw" : "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ActivityIndicator size="large" color="#213470" />
      </View>
    );
  }
  return (
    <AuthWrapper
      title="Email verified"
      subtitle="Your account has been set, please click the button below to start growing your business."
    >
      <View>
        <ButtonGeneral
          label="Get started"
          onPress={() => navigation("/")}
          type={ButtonType.user}
        />
      </View>
    </AuthWrapper>
  );
};

export { EmailVerified };
