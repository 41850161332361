import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { ISvgProps } from "../../../types";

interface DownArrowProps extends ISvgProps {
  color?: string;
}

const DownArrow = ({ color = "#fff", ...props }: DownArrowProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <Path
      fill={color}
      d="M13.098 8H6.902c-.751 0-1.172.754-.708 1.268L9.292 12.7c.36.399 1.055.399 1.416 0l3.098-3.433C14.27 8.754 13.849 8 13.098 8z"
    />
  </Svg>
);

export { DownArrow };
