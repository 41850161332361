import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  titleWrap: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 20,
  },
  title: {
    color: "#0D1738",
    fontSize: 16,
    fontFamily: "OpenSans_600SemiBold",
    marginLeft: 12,
  },
  description: {
    color: "#0D1738",
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
  },
});
