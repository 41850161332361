import React, { useState } from "react";
import { DownArrow } from "@components/general/icons";
import { Text, TouchableOpacity, View } from "react-native";
import { styles } from "./style";
import { Category } from "@components/back-office/roadmap/data-table/types";

interface Props {
  isScoreTable?: boolean;
  category: Category;
}

export const RoadmapDataTableRow = ({ category }: Props) => {
  const [expand, setExpand] = useState(false);

  return (
    <TouchableOpacity
      style={styles.borderBottomGray}
      onPress={() => setExpand(!expand)}
    >
      <View style={styles.rowCenter}>
        <View style={[styles.rowItem, { flex: 1 }]}>
          <Text style={styles.boldDarkText}>{category.name}</Text>
        </View>
        <View style={[styles.rowItem, { flexBasis: 140 }]}>
          <Text style={styles.boldDarkText}>{category.score}</Text>
        </View>

        <TouchableOpacity
          onPress={() => setExpand(!expand)}
          style={{ flexBasis: 60 }}
        >
          {expand ? (
            <DownArrow
              style={{
                transform: [{ rotate: "180deg" }],
              }}
              color="black"
            />
          ) : (
            <DownArrow color="black" />
          )}
        </TouchableOpacity>
      </View>

      {expand &&
        category.subCategories?.map((subcategory, index) => (
          <View
            key={index}
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <View style={[styles.subcategoryItem, { flex: 1 }]}>
              <Text style={styles.rowText}>{subcategory.name}</Text>
            </View>
            <View style={[styles.subcategoryItem, { flexBasis: 140 }]}>
              <Text style={styles.rowText}>{subcategory.score}</Text>
            </View>
            <Text style={{ flexBasis: 60 }} />
          </View>
        ))}
    </TouchableOpacity>
  );
};
