import React, { useState } from "react";
import { Text, View } from "react-native";
import { FilterWrapper } from "@components/filter-mobile/filter-wrapper";
import { FilterRadioButton } from "@components/filter-mobile/filter-radio-button";
import { StarIcon } from "@components/general/icons/feed-icons";
import { Rating } from "@components/general/rating";
import { styles } from "./style";

interface RatingBoxProps {
  rating: number;
}

const RateBox = ({ rating }: RatingBoxProps) => (
  <View style={styles.rateWrap}>
    <StarIcon
      strokeColor={rating >= 1 ? "#FFC132" : "#BABEC3"}
      fillColor={rating >= 1 ? "#FFC132" : "#BABEC3"}
    />
    <StarIcon
      strokeColor={rating >= 1 ? "#FFC132" : "#BABEC3"}
      fillColor={rating >= 1 ? "#FFC132" : "#BABEC3"}
    />
    <StarIcon
      strokeColor={rating >= 1 ? "#FFC132" : "#BABEC3"}
      fillColor={rating >= 1 ? "#FFC132" : "#BABEC3"}
    />
    <StarIcon
      strokeColor={rating >= 1 ? "#FFC132" : "#BABEC3"}
      fillColor={rating >= 1 ? "#FFC132" : "#BABEC3"}
    />
    <StarIcon
      strokeColor={rating >= 1 ? "#FFC132" : "#BABEC3"}
      fillColor={rating >= 1 ? "#FFC132" : "#BABEC3"}
    />
  </View>
);

interface Props {
  isVisible: boolean;
  onClose: () => void;
  onSave: (popularity: number) => void;
}

const Popularity = ({ isVisible, onClose, onSave }: Props) => {
  const [popularity, setPopularity] = useState<number>();
  return (
    <FilterWrapper
      headerTitle="Popularity"
      isVisible={isVisible}
      onClose={onClose}
      buttonTitle="Save"
      isDoneButtonDisabled={!popularity}
      onDone={() => popularity && onSave(popularity)}
    >
      <FilterRadioButton
        content={
          <View style={styles.radioContent} pointerEvents="none">
            <View style={styles.rateWrap}>
              <Rating rating={4} />
            </View>
            <Text style={styles.radioTitle}>& up</Text>
          </View>
        }
        isSelected={popularity === 4}
        onSelect={() => setPopularity(4)}
      />
      <FilterRadioButton
        content={
          <View style={styles.radioContent} pointerEvents="none">
            <View style={styles.rateWrap}>
              <Rating rating={3} />
            </View>
            <Text style={styles.radioTitle}>& up</Text>
          </View>
        }
        isSelected={popularity === 3}
        onSelect={() => setPopularity(3)}
      />
      <FilterRadioButton
        content={
          <View style={styles.radioContent} pointerEvents="none">
            <View style={styles.rateWrap}>
              <Rating rating={2} />
            </View>
            <Text style={styles.radioTitle}>& up</Text>
          </View>
        }
        isSelected={popularity === 2}
        onSelect={() => setPopularity(2)}
      />
      <FilterRadioButton
        content={
          <View style={styles.radioContent} pointerEvents="none">
            <View style={styles.rateWrap}>
              <Rating rating={1} />
            </View>
            <Text style={styles.radioTitle}>& up</Text>
          </View>
        }
        isSelected={popularity === 1}
        onSelect={() => setPopularity(1)}
      />
    </FilterWrapper>
  );
};

export { Popularity };
