import { useState } from "react";
import { ImageSourcePropType, TextInput, View } from "react-native";
import { CommentPhoto } from "@components/home/post/comment/comment-photo";
import { ButtonGeneral } from "@components/general/button-general";
import { styles } from "./style";

interface Props {
  placeholder?: string;
  buttonTitle?: string;
  onSubmit?: (comment: string) => Promise<void>;
  firstName?: string;
  lastName?: string;
  avatar: ImageSourcePropType;
  isLoading?: boolean;
}

const PostComment = ({
  placeholder,
  buttonTitle,
  onSubmit,
  firstName,
  lastName,
  avatar,
  isLoading,
}: Props) => {
  const [value, setValue] = useState("");
  return (
    <View style={styles.wrap}>
      <View style={styles.photoWrap}>
        <CommentPhoto
          firstName={firstName}
          lastName={lastName}
          avatar={avatar}
        />
      </View>
      <TextInput
        value={value}
        onChangeText={(text) => setValue(text)}
        style={styles.commentInput}
        placeholder={placeholder}
      />
      <ButtonGeneral
        label={buttonTitle}
        onPress={async () => {
          value && onSubmit && (await onSubmit(value));
          setValue("");
        }}
        isLoading={isLoading}
        disabled={isLoading}
      />
    </View>
  );
};

export { PostComment };
