import StyleSheet from "react-native-media-query";
import { Platform } from "react-native";

export const { ids, styles } = StyleSheet.create({
  container: {
    backgroundColor: "#FFFFFF",
    ...(Platform.OS === "web" && { height: "max-content" }),
    borderRadius: 8,
    paddingHorizontal: 20,
    paddingVertical: 30,
    marginBottom: 20,
  },
});
