import { StyleProp, Text, TextStyle, View, ViewStyle } from "react-native";
import { styles } from "./styles";
import { InputFormikContainer } from "../../form-elements/input-formik/InputFormikContainer";
import { ButtonLayout } from "../../button-layout/ButtonLayout";
import { useFormikContext } from "formik";
import { useUserContext } from "@context/UserContext";
import { ProfileInterface } from "@pages/account/settings/initialValues";
import { FormikDropzone } from "@components/back-office/editor/formik-dropzone";

interface PhotoFormProps {
  closeEditMode?: () => void;
  hasButtonsLayout?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  labelStyle?: StyleProp<TextStyle>;
  isCurrentUsersProfile?: boolean;
  fieldName: string;
}

const Photoform = ({
  closeEditMode,
  hasButtonsLayout = true,
  containerStyle,
  labelStyle,
  isCurrentUsersProfile = true,
  fieldName,
}: PhotoFormProps) => {
  const { handleSubmit, values } = useFormikContext<ProfileInterface>();
  const { currentUser } = useUserContext();

  const closeSubmitHandler = () => {
    handleSubmit();
    closeEditMode && closeEditMode();
  };

  return (
    <InputFormikContainer
      label="Photo"
      styleContainer={containerStyle}
      styleLabel={labelStyle}
    >
      <View style={styles.profilePhoto}>
        <View
          style={{
            flex: 1,
          }}
        >
          <FormikDropzone
            fieldName={fieldName}
            // @ts-ignore FIXME: bad type
            currentValue={values[fieldName]}
            actionHint="Accepts jpeg and png"
            validFileTypes={["image/jpeg", "image/png"]}
            style={{ marginTop: 8, marginBottom: 16 }}
            storageBaseUrl={`Users/${currentUser?.id}/profilePhoto`}
          />
        </View>
        <View
          style={{
            flex: 1,
            marginLeft: 32,
          }}
        >
          <Text
            style={{
              marginBottom: 8,
              color: "#0D1738",
              fontSize: 14,
              fontFamily: "OpenSans_400Regular",
            }}
          >
            {isCurrentUsersProfile ? `Your` : `The user's`} photo appears on
            {isCurrentUsersProfile ? ` your` : ` their`} profile page across
            TRN.
          </Text>
          <Text
            style={{
              color: "#6D7175",
              fontSize: 12,
              fontFamily: "OpenSans_400Regular",
            }}
          >
            {`Recommended size: Square, at least 1000 pixels per side.
File type: JPG, PNG or GIF.`}
          </Text>
        </View>
      </View>
      {hasButtonsLayout ? (
        <ButtonLayout
          closeEditMode={closeEditMode}
          submitCloseHander={closeSubmitHandler}
        />
      ) : null}
    </InputFormikContainer>
  );
};

export { Photoform };
