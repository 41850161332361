import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { ISvgProps } from "../../../../types";

const InstagramIcon = ({ color = "#0D1738", ...props }: ISvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <Path
      fill={color}
      d="M8 .727c-1.974 0-2.222.008-2.998.044-.774.035-1.303.158-1.765.337a3.561 3.561 0 0 0-1.288.839 3.57 3.57 0 0 0-.84 1.288C.93 3.698.806 4.226.771 5 .737 5.776.728 6.024.728 8c0 1.974.008 2.222.043 2.997.036.775.159 1.303.338 1.765.186.479.435.885.84 1.289.403.404.809.653 1.287.84.463.179.991.302 1.765.337.776.035 1.024.044 2.999.044s2.222-.009 2.998-.044c.774-.035 1.303-.158 1.766-.338a3.56 3.56 0 0 0 1.287-.84 3.57 3.57 0 0 0 .84-1.287c.178-.463.301-.992.338-1.765.034-.776.043-1.024.043-2.999s-.009-2.223-.043-2.998c-.037-.775-.16-1.303-.338-1.766a3.57 3.57 0 0 0-.84-1.288 3.554 3.554 0 0 0-1.288-.839c-.463-.18-.992-.302-1.766-.337C10.22.735 9.974.727 7.998.727h.003zm-.652 1.31h.653c1.941 0 2.172.007 2.938.042.71.032 1.094.15 1.35.25.34.132.582.29.836.544.255.255.412.497.545.837.1.256.218.64.25 1.35.035.766.042.997.042 2.938 0 1.94-.007 2.17-.042 2.937-.032.71-.15 1.094-.25 1.35-.132.34-.29.582-.545.836a2.25 2.25 0 0 1-.835.544c-.256.1-.642.218-1.35.25-.767.035-.998.043-2.94.043-1.941 0-2.171-.008-2.938-.043-.71-.032-1.094-.15-1.35-.25a2.253 2.253 0 0 1-.837-.544 2.254 2.254 0 0 1-.544-.836c-.1-.256-.218-.641-.25-1.35-.035-.767-.042-.997-.042-2.94 0-1.941.007-2.17.042-2.937.032-.71.15-1.094.25-1.35.132-.34.29-.582.544-.837.255-.254.497-.412.837-.544.256-.1.64-.218 1.35-.25.67-.031.93-.04 2.286-.042v.002zm4.535 1.208a.873.873 0 1 0 0 1.746.873.873 0 0 0 0-1.747zM8 4.265a3.735 3.735 0 1 0 0 7.47 3.735 3.735 0 0 0 0-7.47zm0 1.31a2.424 2.424 0 1 1 0 4.848 2.424 2.424 0 0 1 0-4.848z"
    />
  </Svg>
);

export { InstagramIcon };
