import { styles } from "./style";

import { Text, View } from "react-native";
import { countryCodes } from "@components/general/form/phone-input/country-codes";
import { Dropdown } from "react-native-element-dropdown";

interface Props {
  onChange: (value: string) => void;
  value?: string;
  size?: number;
  label?: string;
  heightContainer?: number;
  placeholder?: string;
}

export const DropdownCountryList = ({
  onChange,
  value,
  size,
  label,
  heightContainer,
  placeholder,
}: Props) => {
  const countries = countryCodes.map(({ name: country }) => {
    return { label: country, value: country };
  });

  return (
    <View>
      <Dropdown
        style={[styles.dropdown, { width: size }]}
        dropdownPosition="bottom"
        containerStyle={{ ...(heightContainer && { height: heightContainer }) }}
        selectedTextStyle={[
          styles.selectedText,
          size ? { width: size - 48 } : undefined,
        ]}
        placeholderStyle={[
          styles.placeholder,
          size ? { width: size - 48 } : undefined,
        ]}
        // I have no idea why TS thinks this is an error, this *is* correct and
        // just passing in a string breaks this on the function.
        // @ts-ignore
        data={countries}
        labelField="label"
        valueField="value"
        placeholder={placeholder || ""}
        onChange={onChange}
        value={value}
      />
      {label ? <Text style={styles.label}>{label}</Text> : null}
    </View>
  );
};
