import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  container: {
    paddingBottom: 19,
    paddingTop: 12,
    paddingHorizontal: 40,
    borderBottomColor: "#EDEEEF",
    borderBottomWidth: 1,
  },
  titleContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
    color: "#202223",
  },
  titleButton: {
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
    color: "#2C6ECB",
    textDecorationLine: "underline",
  },
  upgradeButtonContainer: {
    paddingHorizontal: 16,
    paddingVertical: 8,
    backgroundColor: "#FFFFFF",
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#D2D5D8",
  },
  upgradeButtonContainerDisabled: {
    backgroundColor: "#FAFBFB",
    borderColor: "#D2D5D8",
  },
  upgradeButton: {
    fontSize: 14,
    fontFamily: "OpenSans_600SemiBold",
    color: "#0D1738",
    lineHeight: 20,
  },
  upgradeButtonDisabled: {
    color: "#8C9196",
  },
  currentPlanContainer: {
    marginTop: 20,
  },
  currentPlanTitle: {
    fontSize: 14,
    fontFamily: "OpenSans_600SemiBold",
    color: "#0D1738",
    lineHeight: 20,
  },
  currentPlan: {
    fontSize: 16,
    fontFamily: "OpenSans_400Regular",
    color: "#202223",
    lineHeight: 20,
    marginTop: 8,
  },
  currentPlanPrice: {
    fontSize: 16,
    fontFamily: "OpenSans_600SemiBold",
    color: "#202223",
    lineHeight: 20,
    marginTop: 8,
    marginLeft: 8,
  },
  renewDateContainer: {
    marginTop: 20,
  },
  renewDateTitle: {
    fontSize: 14,
    fontFamily: "OpenSans_600SemiBold",
    color: "#0D1738",
    lineHeight: 20,
  },
  renewDate: {
    fontSize: 16,
    fontFamily: "OpenSans_400Regular",
    color: "#202223",
    lineHeight: 20,
    marginTop: 8,
  },
  cancelPlanButton: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    paddingHorizontal: 16,
    paddingVertical: 8,
    backgroundColor: "#D72C0D",
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#D2D5D8",
    marginRight: "80%",
  },
  cancelPlanButtonText: {
    fontSize: 14,
    fontFamily: "OpenSans_600SemiBold",
    color: "#ffffff",
    lineHeight: 20,
  },
  link: {
    color: "#2C6ECB",
    textDecorationLine: "underline",
  },
});
