import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { ISvgProps } from "../../../types";

const TwitterIconDark = (props: ISvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={17}
    fill="none"
    {...props}
  >
    <Path
      fill="#4A4A4A"
      d="M19.167 2.372a8.068 8.068 0 0 1-2.253.631A4.022 4.022 0 0 0 18.638.795a7.678 7.678 0 0 1-2.491.967A3.903 3.903 0 0 0 13.277.5c-2.174 0-3.928 1.79-3.928 3.999 0 .315.03.62.1.906-3.27-.163-6.16-1.76-8.103-4.172a4.002 4.002 0 0 0-.529 2.004 4.03 4.03 0 0 0 1.745 3.328 3.944 3.944 0 0 1-1.785-.499v.051c0 1.933 1.356 3.551 3.15 3.918a3.866 3.866 0 0 1-1.774.07c.498 1.588 1.953 2.748 3.668 2.779a7.826 7.826 0 0 1-4.884 1.71c-.319 0-.628-.021-.937-.062a10.962 10.962 0 0 0 6.02 1.801c7.226 0 11.183-6.095 11.183-11.376 0-.173 0-.346-.01-.519a7.865 7.865 0 0 0 1.974-2.066z"
    />
  </Svg>
);

export { TwitterIconDark };
