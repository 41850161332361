import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  countBoxesWrap: {
    padding: 16,
    paddingBottom: 0,
  },
  wrap: {
    padding: 16,
  },
});
