import { useState } from "react";
import { Text, View } from "react-native";
import { NonEmptyArray } from "@shopify/polaris/build/ts/latest/src/types";
import { IndexTableHeading } from "@shopify/polaris/build/ts/latest/src/components/IndexTable";
import { pastTensify } from "@utils/misc";
import dayjs from "dayjs";
import { useNavigate } from "react-router-native";
import {
  ContentType,
  Status,
  useAdminGetAllPostsQuery,
  useDeletePostMutation,
  useUpdatePostMutation,
} from "@gql/generated/generated";
import { DeleteConfirmationModal } from "../../../components/back-office/editor/delete-confirmation-modal";
import { textStyles } from "@styles/text";
import { GridPage } from "@components/back-office/grid/GridPage";
import { useQueryFilters } from "@components/back-office/grid/useGridFilters";

const activeFilters = ["search", "status", "dateFrom", "dateTo", "postType"];

export const PostsBackOffice = () => {
  const navigate = useNavigate();
  const { page, filters } = useQueryFilters(activeFilters);

  const [postIdToBeDeleted, setPostIdToBeDeleted] = useState<
    string | undefined
  >();

  const { data, loading, refetch } = useAdminGetAllPostsQuery({
    variables: {
      page,
      limit: 10,
      filters,
    },
  });

  const { posts, hasNextPage, total } = data?.adminGetAllPosts || {};

  const [deletePost] = useDeletePostMutation({
    update: (store, _result, options) => {
      const id = options?.variables?.id;
      if (id) {
        const normalizedId = store.identify({
          id,
          __typename: `${ContentType.POST}`,
        });
        store.evict({ id: normalizedId });
        store.gc();
      }
    },
  });
  const [archivePost] = useUpdatePostMutation({
    update: (store, _result, options) => {
      const itemId = options?.variables?.input?.id;
      if (itemId) {
        store.modify({
          id: `${ContentType.POST}:${itemId}`,
          fields: {
            status() {
              return Status.ARCHIVE;
            },
          },
        });
      }
    },
  });

  const tableHeadings: NonEmptyArray<IndexTableHeading> = [
    { title: "Title" },
    { title: "Author" },
    { title: "Post Type" },
    { title: "Last modified" },
    { title: "Status" },
    { title: "" },
  ];

  const rows =
    posts &&
    posts.map(
      ({
        id,
        authorName,
        title,
        type,
        createdAt,
        updatedAt,
        status,
        content,
      }) => ({
        id,
        title: (
          <View style={{ width: 350, paddingVertical: "8px" }}>
            <Text style={textStyles.semiBoldSmall}>
              {title || content?.replace(/(<([^>]+)>)/gi, "")}
            </Text>
          </View>
        ),
        author: authorName,
        type,
        date: dayjs(updatedAt ?? createdAt).format("DD/MM/YYYY"),
        status: pastTensify(status),
      })
    );

  const handleMenuClick = async (itemId: string, optionID: string) => {
    switch (optionID) {
      case "Edit":
        return navigate(`/post-editor/${itemId}`);
      case "Archive":
        await archivePost({
          variables: {
            input: {
              id: itemId,
              status: Status.ARCHIVE,
            },
          },
        });
        break;
      case "Delete":
        setPostIdToBeDeleted(itemId);
        break;
      default:
        break;
    }
  };

  const handlePressDelete = async () => {
    if (postIdToBeDeleted) {
      await deletePost({ variables: { id: postIdToBeDeleted } });
    }
  };

  return (
    <>
      <DeleteConfirmationModal
        title="Are you sure you want to delete this post?"
        onClose={() => setPostIdToBeDeleted(undefined)}
        onDeleteConfirm={async () => {
          await handlePressDelete();
          setPostIdToBeDeleted(undefined);
        }}
        visible={!!postIdToBeDeleted}
      />
      <GridPage
        title="Posts"
        menuItems={[
          { id: "Edit", content: "Edit" },
          { id: "Archive", content: "Archive" },
          { id: "Delete", content: "Delete" },
        ]}
        newLink={{
          url: "/post-editor",
          title: "Add New Post",
          label: "Add Post",
        }}
        activeFilters={activeFilters}
        rows={rows || []}
        loading={loading}
        tableHeadings={tableHeadings}
        onBulkAction={() => refetch()}
        onMenuItem={handleMenuClick}
        pagination={{
          perPage: 10,
          page,
          totalItems: total,
          hasNextPage,
        }}
      />
    </>
  );
};
