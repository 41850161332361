import StyleSheet from "react-native-media-query";

const { ids: textIds, styles: textStyles } = StyleSheet.create({
  topic: {
    fontFamily: "OpenSans_400Regular",
    color: "#0D1738",
    fontSize: 12,
  },
  subduedTextSmall: {
    fontFamily: "OpenSans_400Regular",
    color: "#6D7175",
    fontSize: 12,
  },
  subduedTextMedium: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 14,
    lineHeight: 20,
    color: "#6D7175",
  },
  semiBoldSmall: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 14,
    lineHeight: 20,
    color: "#202223",
  },
  semiBoldMedium: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 16,
    lineHeight: 20,
    color: "#202223",
  },
  error: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 14,
    lineHeight: 20,
    marginVertical: 4,
    color: "red",
  },
});

export { textIds, textStyles };
