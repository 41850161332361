import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const CaretDown = (props: SvgProps) => (
  <Svg width={8} height={6} fill="none" {...props}>
    <Path
      d="M7.098.11H.902C.15.11-.27.862.194 1.376L3.292 4.81c.36.4 1.055.4 1.416 0l3.098-3.433C8.27.863 7.849.109 7.098.109Z"
      fill={props.fill || "#0D1738"}
    />
  </Svg>
);

export { CaretDown };
