import { Text, View } from "react-native";
import { InputFormikContainer } from "@pages/account/profile/form-elements/input-formik/InputFormikContainer";
import { Color, RadioButton } from "@components/general/radio-button";
import { ButtonLayout } from "@pages/account/profile/button-layout/ButtonLayout";
import { InputFormik } from "@pages/account/profile/form-elements/input-formik/InputFormik";
import { useFormikContext } from "formik";

interface formProps {
  closeEditMode: () => void;
  selected: { generatePassword: boolean; setManually: boolean };
  setSelected: (val: {
    generatePassword: boolean;
    setManually: boolean;
  }) => void;
}

const PasswordForm = ({ closeEditMode, selected, setSelected }: formProps) => {
  const { handleSubmit, isSubmitting } = useFormikContext();

  const submitCloseHander = () => {
    handleSubmit();
  };

  return (
    <InputFormikContainer>
      <Text
        style={{
          fontSize: 14,
          color: "#6D7175",
          fontFamily: "Opensans_400Regular",
        }}
      >
        It's a good idea to use a strong password that you don't use elsewhere
      </Text>
      <InputFormik
        formikKey="currentPassword"
        placeholder="Current Password"
        styleInput={{
          marginTop: 16,
        }}
      />
      <View
        style={{
          flexDirection: "row",
          marginTop: 16,
          alignItems: "center",
        }}
      >
        <RadioButton
          label="Use generated password"
          selected={selected.generatePassword}
          onPress={() => {
            setSelected({ setManually: false, generatePassword: true });
          }}
          color={Color.LightBlue}
        />
        {selected.generatePassword ? (
          <InputFormik
            formikKey="generated"
            placeholder="*********"
            styleInput={{
              marginLeft: 8,
            }}
          />
        ) : null}
      </View>
      <RadioButton
        label="Set password manually"
        selected={selected.setManually}
        onPress={() => {
          setSelected({ setManually: true, generatePassword: false });
        }}
        color={Color.LightBlue}
      />
      {selected.setManually ? (
        <>
          <InputFormik
            formikKey="manualPassword"
            placeholder="New Password"
            styleInput={{
              marginLeft: 8,
            }}
          />
          <InputFormik
            formikKey="confirmManualPassword"
            placeholder="Confirm New Password"
            styleInput={{
              marginLeft: 8,
            }}
          />
        </>
      ) : null}

      <ButtonLayout
        containerButtonStyle={{
          justifyContent: "flex-start",
          marginTop: 16,
        }}
        closeEditMode={closeEditMode}
        submitCloseHander={submitCloseHander}
        isSubmitting={isSubmitting}
      />
    </InputFormikContainer>
  );
};

export { PasswordForm };
