import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const CategoryFilterIcon = (props: SvgProps) => (
  <Svg width={13} height={12} fill="none" {...props}>
    <Path
      stroke="#5C5F62"
      strokeWidth={1.5}
      d="M1.73 2.667a1.917 1.917 0 1 1 3.834 0 1.917 1.917 0 0 1-3.834 0Zm0 6.666a1.917 1.917 0 1 1 3.834 0 1.917 1.917 0 0 1-3.834 0Zm6.667-6.666a1.917 1.917 0 1 1 3.833 0 1.917 1.917 0 0 1-3.833 0Zm0 6.666a1.917 1.917 0 1 1 3.833 0 1.917 1.917 0 0 1-3.833 0Z"
    />
  </Svg>
);

export { CategoryFilterIcon };
