import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: "100%",
    height: "100%",
    justifyContent: "space-between",
  },
  buttonWrap: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 40,
  },
});
