import { AdminUserInput } from "@gql/generated/generated";
import { AllCategories } from "@utils/constants";

export const categoriesAvailable: Partial<AllCategories> = {
  training: {
    candidates: [],
    operations: [],
    productsServices: [],
    systemsTechnology: [],
    people: [],
    accountManagement: [],
    newBusinessDevelopment: [],
    marketing: [],
  },
  content: {
    candidates: [],
    operations: [],
    productsServices: [],
    systemsTechnology: [],
    people: [],
    marketing: [],
    newBusinessDevelopment: [],
    accountManagement: [],
  },
  events: {
    candidates: [],
    operations: [],
    accountManagement: [],
    systemsTechnology: [],
    people: [],
    newBusinessDevelopment: [],
    marketing: [],
    productsServices: [],
  },
};

type overrides = {
  id?: string;
  registrationDate: Date;
  name: string;
  categoriesAvailable: typeof categoriesAvailable;
  withSuspensionEndDate: boolean;
};

export type InitialValuesProps = Omit<AdminUserInput, keyof overrides> &
  overrides;
