import { FormikDatePickerDropdown } from "@components/back-office/editor/formik-date-picker-dropdown";
import { useQueryFilters } from "../useGridFilters";

export const MemberSinceFilter = () => {
  const { filters, setFilterValue } = useQueryFilters(["memberSince"]);

  return (
    <FormikDatePickerDropdown
      dateValue={filters.memberSince}
      placeholderText="Member Since"
      onChange={(v) => setFilterValue("memberSince", v.start.getTime())}
      onReset={
        filters.memberSince
          ? () => setFilterValue("memberSince", null)
          : undefined
      }
    />
  );
};
