import { Image, Text, View } from "react-native";
import { styles } from "./styles";
import { GoCardLessIcon } from "@components/general/icons/account-icons/GoCardLessIcon";
import { InputFormik } from "@pages/account/profile/form-elements/input-formik/InputFormik";
import { useState } from "react";
import { Checkbox } from "@shopify/polaris";
import { FormikValues, useFormikContext } from "formik";
import AlertMinor from "../../../../../../../assets/minor/alert_minor.png";

const GoCardLessEdit = () => {
  const [useIBAN, setUseIBAN] = useState(false);
  const { values, setFieldValue }: FormikValues = useFormikContext();
  const isChecked = values.onePersonCheck;

  return (
    <>
      <View style={styles.image}>
        <GoCardLessIcon />{" "}
      </View>
      <View style={styles.container}>
        <Text style={styles.infoText}>Set up a Direct Debit with TRN</Text>
        <View style={styles.checkboxContainer}>
          <Checkbox
            label="More than one person is required to authorise Direct Debits"
            checked={isChecked}
            onChange={(checked) => setFieldValue("onePersonCheck", checked)}
          />
          {isChecked ? (
            <View style={styles.errorContainer}>
              <Image source={AlertMinor} style={styles.errorIcon} />
              <Text style={styles.errorText}>
                Direct Debits with more than one authoriser are not supported
              </Text>
            </View>
          ) : null}
        </View>
        <View style={styles.accountHolderContainer}>
          <Text style={styles.label}>Account Holder Name</Text>
          <InputFormik placeholder="" formikKey="accountHolderName" />
        </View>
        {!useIBAN ? (
          <>
            {" "}
            <View
              style={{
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  flex: 1,
                }}
              >
                <Text style={styles.label}>Sort Code</Text>
                <InputFormik
                  placeholder="e.g. 102030"
                  formikKey="branchCode"
                  placeholderTextColor="#6D7175"
                  maxLength={6}
                />
              </View>
              <View
                style={{
                  flex: 1,
                }}
              >
                <Text style={styles.label}>Account Number</Text>
                <InputFormik
                  placeholder="e.g. 12345678"
                  formikKey="accountNumber"
                  placeholderTextColor="#6D7175"
                />
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
              }}
            >
              <Text style={styles.optionText}>or</Text>
              <Text
                style={styles.editButton}
                onPress={() => {
                  setUseIBAN(true);
                  setFieldValue("branchCode", "");
                  setFieldValue("accountNumber", "");
                }}
              >
                {" "}
                Enter IBAN
              </Text>
            </View>
          </>
        ) : (
          <>
            <View
              style={{
                flex: 1,
              }}
            >
              <Text style={styles.label}>IBAN</Text>
              <InputFormik
                placeholder="GB 12 ABCD 102030 12345678"
                formikKey="iban"
                placeholderTextColor="#6D7175"
              />
            </View>
            <View
              style={{
                flexDirection: "row",
              }}
            >
              <Text style={styles.optionText}>or</Text>
              <Text
                style={styles.editButton}
                onPress={() => {
                  setUseIBAN(false);
                  setFieldValue("iban", "");
                }}
              >
                {" "}
                Sort Code and Account Number
              </Text>
            </View>
          </>
        )}
        <View
          style={{
            marginTop: 8,
          }}
        >
          <Text style={styles.label}>Postcode</Text>
          <InputFormik placeholder="" formikKey="postCode" />
        </View>
      </View>
    </>
  );
};

export { GoCardLessEdit };
