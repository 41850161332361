import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  container: {
    paddingTop: 20,
    paddingBottom: 19,
    paddingHorizontal: 40,
    borderBottomColor: "#EDEEEF",
    borderBottomWidth: 1,
  },
  innerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  title: {
    fontSize: 17,
    fontFamily: "OpenSans_600SemiBold",
    color: "#202223",
    lineHeight: 20,
  },
});
