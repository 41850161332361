import React, { useCallback, useEffect, useState } from "react";
import { OptionList, Popover, TextField } from "@shopify/polaris";
import { useGooglePlacesAutocomplete } from "@hooks/useGooglePlacesAutocomplete";

interface LocationAutocompleteProps {
  location: string;
  type: string;
  setLocation: (arg: string) => void;
  placeholder?: string;
}

const LocationAutocomplete = ({
  location,
  setLocation,
  type,
  placeholder,
}: LocationAutocompleteProps) => {
  const [popoverActive, setPopoverActive] = useState(false);
  const [incompleteLocationString, setIncompleteLocationString] = useState("");
  const predictions = useGooglePlacesAutocomplete({
    query: incompleteLocationString,
    type,
  });
  const suggestedLocations = predictions?.autocompleteLocation.map(
    ({ description, postalCode }: any) => {
      const suggestion = `${description}${postalCode ? `, ${postalCode}` : ""}`;

      return {
        value: suggestion,
        label: suggestion,
      };
    }
  );

  useEffect(() => {
    if (suggestedLocations?.length > 0 && !location) {
      setPopoverActive(true);
    }
  }, [suggestedLocations]);

  const handleChangeTextField = useCallback(
    (value: React.SetStateAction<string>) => {
      setLocation("");
      if (suggestedLocations > 0) {
        setPopoverActive(true);
      }

      setIncompleteLocationString(value);
    },
    []
  );

  const handleSelectLocationFromDropdown = (selected: string[]) => {
    setLocation(selected[0].toString());
    setPopoverActive(false);
  };

  const activator = (
    <TextField
      label="Location"
      autoComplete="off"
      labelHidden
      value={location || incompleteLocationString}
      onChange={handleChangeTextField}
      placeholder={placeholder}
    />
  );

  return (
    <div>
      <Popover
        active={popoverActive}
        activator={activator}
        onClose={() => {}}
        preferredAlignment="center"
        preferredPosition="below"
        autofocusTarget="none"
      >
        <OptionList
          title="Location"
          onChange={handleSelectLocationFromDropdown}
          options={suggestedLocations}
          selected={[location]}
          allowMultiple={false}
        />
      </Popover>
    </div>
  );
};

export { LocationAutocomplete };
