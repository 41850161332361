import { ActionList, ActionListItemDescriptor } from "@shopify/polaris";
import { FormikProps } from "formik";
import { Dispatch, SetStateAction } from "react";

type Props = {
  formik: FormikProps<any>;
  items: ActionListItemDescriptor[];
  setSelectedSubcategories: Dispatch<string[]>;
};

export const Categories = ({
  formik,
  items,
  setSelectedSubcategories,
}: Props) => {
  const categoryItems = [
    {
      content: "Reset",
      onAction: () => {
        formik.resetForm();
        setSelectedSubcategories([]);
      },
      destructive: true,
    },
    ...items,
  ];

  return <ActionList actionRole="menuitem" items={categoryItems} />;
};
