import {
  createContext,
  MutableRefObject,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";
import { Dimensions, LayoutChangeEvent, View } from "react-native";

type Layout = LayoutChangeEvent["nativeEvent"]["layout"];
type Observer = (layout: Layout) => void;
type Unsubscribe = () => void;
type SearchBarLayoutContextProps = {
  searchBarRef: MutableRefObject<View | null>;
  onSearchBarLayout: (event: LayoutChangeEvent) => void;
  addSearchBarLayoutObserver: (observer: Observer) => Unsubscribe;
};

const SearchBarLayoutContext = createContext<SearchBarLayoutContextProps>({
  onSearchBarLayout: () => {},
  addSearchBarLayoutObserver: () => () => {},
  searchBarRef: { current: null },
});

export const SearchBarLayoutContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const searchBarLayoutRef = useRef<Layout | null>(null);
  const searchBarRef = useRef<View | null>(null);
  const observersRef = useRef<Observer[]>([]);

  const onLayoutChange = useCallback(() => {
    if (!searchBarRef.current) return;
    searchBarRef.current.measureInWindow((x, y, width, height) => {
      searchBarLayoutRef.current = { x, y, width, height };
      observersRef.current.forEach((observer) =>
        observer({ x, y, width, height })
      );
    });
  }, []);
  const onSearchBarLayout = useCallback(() => onLayoutChange(), []);
  const addSearchBarLayoutObserver = useCallback((observer: Observer) => {
    observersRef.current.push(observer);
    if (searchBarLayoutRef.current) {
      observer(searchBarLayoutRef.current);
    }
    return () => {
      const index = observersRef.current.indexOf(observer);
      observersRef.current.splice(index, 1);
    };
  }, []);

  useEffect(() => {
    const subscription = Dimensions.addEventListener("change", onLayoutChange);
    return () => subscription.remove();
  }, []);

  return (
    <SearchBarLayoutContext.Provider
      value={{
        onSearchBarLayout,
        addSearchBarLayoutObserver,
        searchBarRef,
      }}
    >
      {children}
    </SearchBarLayoutContext.Provider>
  );
};

export const useSearchBarLayoutContext = () =>
  useContext(SearchBarLayoutContext);
