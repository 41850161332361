import React, { useState } from "react";
import { FlatList } from "react-native";
import { FilterWrapper } from "@components/filter-mobile/filter-wrapper";
import { FilterCheckbox } from "@components/filter-mobile/filter-checkbox";

export enum MemberOptions {
  all = "All",
  plus = "Plus",
  club = "Club",
  free = "Free",
}

const memberOptions = [
  MemberOptions.all,
  MemberOptions.free,
  MemberOptions.plus,
  MemberOptions.club,
];

interface Props {
  isVisible: boolean;
  onClose: () => void;
  onSave: (memberTypes: MemberOptions[]) => void;
}

const MemberType = ({ isVisible, onClose, onSave }: Props) => {
  const [memberTypes, setMemberTypes] = useState<MemberOptions[]>([]);

  const handleSelect = (memberType: MemberOptions) => {
    if (memberTypes.includes(memberType)) {
      const index = memberTypes.indexOf(memberType);
      const memberTypesCopy: MemberOptions[] = [...memberTypes];
      memberTypesCopy.splice(index, 1);
      setMemberTypes(memberTypesCopy);
    } else {
      setMemberTypes([...memberTypes, memberType]);
    }
  };

  const renderItem = ({ item }: { item: MemberOptions }) => (
    <FilterCheckbox
      title={item}
      isSelected={memberTypes.includes(item)}
      onToggle={() => handleSelect(item)}
    />
  );

  return (
    <FilterWrapper
      headerTitle="Member Type"
      isVisible={isVisible}
      onClose={onClose}
      buttonTitle="Save"
      isDoneButtonDisabled={!memberTypes.length}
      onDone={() => memberTypes.length && onSave(memberTypes)}
    >
      <FlatList
        data={memberOptions}
        renderItem={renderItem}
        keyExtractor={(item) => item}
      />
    </FilterWrapper>
  );
};

export { MemberType };
