import { useQueryFilters } from "../useGridFilters";
import { SwitchButton } from "@components/marketplace/marketplace-head-menu/switch-button/SwitchButton";

export const SpecialOffersFilter = () => {
  const { filters, setFilterValue } = useQueryFilters(["specialOffer"]);

  return (
    <SwitchButton
      title="Special Offer"
      isActive={filters.specialOffer}
      setIsActive={(val: boolean) => setFilterValue("specialOffer", val)}
      needDisclosure={false}
    />
  );
};
