import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { ISvgProps } from "../../../../types";

const ContentIcon = ({ color = "#fff", ...props }: ISvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={15}
    fill="none"
    {...props}
  >
    <Path
      fill={color}
      d="M1 .994h14a1 1 0 1 1 0 2H1a1 1 0 0 1 0-2zm0 4h10a1 1 0 1 1 0 2H1a1 1 0 0 1 0-2zm0 4h14a1 1 0 1 1 0 2H1a1 1 0 1 1 0-2zm0 4h10a1 1 0 0 1 0 2H1a1 1 0 1 1 0-2z"
    />
  </Svg>
);

export { ContentIcon };
