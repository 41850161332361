import React, { ReactNode } from "react";
import { View } from "react-native";
import { ContentBox } from "@components/general/layouts/content-box";
import { ids, styles } from "./style";

interface MainBoxWithSidepanelProps {
  mainContent?: ReactNode;
  sidePanelContent?: ReactNode;
}

const MainBoxWithSidepanel = ({
  mainContent,
  sidePanelContent,
}: MainBoxWithSidepanelProps) => {
  return (
    <View style={styles.container} dataSet={{ media: ids.container }}>
      <ContentBox
        style={styles.mainContentBox}
        dataSetMedia={ids.mainContentBox}
      >
        {mainContent}
      </ContentBox>
      <View
        style={styles.rightContainer}
        dataSet={{ media: ids.rightContainer }}
      >
        {sidePanelContent}
      </View>
    </View>
  );
};

export { MainBoxWithSidepanel };
