export const formikInitialValues = {
  blockedAccess: {
    content: {
      newBusinessDevelopment: [],
      accountManagement: [],
      productsServices: [],
      candidates: [],
      people: [],
      systemsTechnology: [],
      finance: [],
      operations: [],
      marketing: [],
    },
    training: {
      newBusinessDevelopment: [],
      accountManagement: [],
      productsServices: [],
      candidates: [],
      people: [],
      systemsTechnology: [],
      finance: [],
      operations: [],
      marketing: [],
    },
    events: {
      newBusinessDevelopment: [],
      accountManagement: [],
      productsServices: [],
      candidates: [],
      people: [],
      systemsTechnology: [],
      finance: [],
      operations: [],
      marketing: [],
    },
  },
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  country: "",
  jobPosition: "",
  accountType: "",
  profilePhotoUrl: "",
};
