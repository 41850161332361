import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  text: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 14,
    lineHeight: 20,
    color: "#202223",
    marginBottom: 20,
  },
  container: {
    width: 315,
    borderColor: "#ECEEF6",
    borderRadius: 8,
    borderWidth: 2,
    backgroundColor: "#FFFFFF",
    marginBottom: 20,
    padding: 20,
  },
  subTitle: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 14,
    lineHeight: 20,
    color: "#202223",
    marginBottom: 4,
  },
});
