import React from "react";
import { useNavigate } from "react-router-native";
import dayjs from "dayjs";
import { SearchItem } from "@components/search/search-item";
import { AlgoliaProps } from "../useAlgoliaSearch";
import { Content, Training, Event } from "@gql/generated/generated";
import { formatSubtitle, getLessonCount, roundToHours } from "../shared/SearchItemHelpers";

const SearchItemType = ({
  hasPadding,
  query,
  searchResult,
}: {
  hasPadding?: boolean;
  query?: string;
  searchResult:
  | (Event & AlgoliaProps)
  | (Training & AlgoliaProps)
  | (Content & AlgoliaProps);
}) => {
  const navigate = useNavigate();

  const handlePressSearchItem = (url: string) => {
    navigate(url);
  };

  const { path } = searchResult;

  if (path?.includes("Training")) {
    const {
      coverImage,
      title,
      durationInSeconds,
      averageStars,
      comments,
      shareCount,
      objectID,
      modules,
    } = (searchResult as Training & AlgoliaProps) || {};

    return (
      <SearchItem
        hasPadding={hasPadding}
        asset={coverImage}
        title={title || ""}
        date={`${roundToHours(durationInSeconds)} hrs total • ${getLessonCount(modules)} lessons`}
        isRating
        subtitle={formatSubtitle({ averageStars, comments, shareCount })}
        onPress={() => handlePressSearchItem(`/training/${objectID}`)}
        query={query}
      />
    );
  }

  if (path?.includes("Content")) {
    const { coverImage, title, categories, objectID, publishedAt } =
      (searchResult as Content & AlgoliaProps) || {};

    const formattedDate = dayjs(publishedAt).format("DD MMM YY");
    return (
      <SearchItem
        hasPadding={hasPadding}
        asset={coverImage}
        title={title || ""}
        date={formattedDate}
        categories={categories?.map((cat: string) => cat.split(":")[0])}
        visibleCategoryCount={5}
        onPress={() => handlePressSearchItem(`/content/${objectID}`)}
        query={query}
      />
    );
  }

  if (path?.includes("Events")) {
    const { coverImage, title, dateFrom, objectID, type } =
      (searchResult as Event & AlgoliaProps) || {};

    const formattedDate = dayjs(dateFrom).format("DD MMM YY • HH:mm");

    return (
      <SearchItem
        hasPadding={hasPadding}
        asset={coverImage}
        title={title || ""}
        date={formattedDate}
        subtitle={type || ""}
        onPress={() => handlePressSearchItem(`/events/${objectID}`)}
        query={query}
      />
    );
  }

  return <></>;
};

export { SearchItemType };
